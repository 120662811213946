import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { setForumFilterProfile } from '../../forum/questions-list/forumQuestionsSlice';
import { RootState } from '../../../app/store';

export default function ForumQuestions() {

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          dispatch(setForumFilterProfile(currentlyLoadedProfileID || null));
          history.push('/forum');
        }}
        className="inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none "
      >
        <QuestionMarkCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>Forum Posts</span>
      </button>
    </>
  );
}
