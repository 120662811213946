import React from 'react';
import { Switch } from '@headlessui/react';

import { useSelector } from 'react-redux';
import { classNames } from '../../../helpers/styling';
import { RootState } from '../../../app/store';
import { useAppDispatch } from '../../../app/hooks';
import { setOnlyMyPosts } from '../feedSlice';

export default function FilterPosts() {

  const {
    onlyMyPosts,
  } = useSelector((state: RootState) => state.feed.list);

  const dispatch = useAppDispatch();

  return (
    <div>
      <Switch.Group as="div" className="flex justify-end py-6">
        <Switch.Label as="span" className="mr-4">
          <span className="text-xs font-semibold text-neutral-800 cursor-pointer">Show only my posts</span>
        </Switch.Label>
        <Switch
          checked={onlyMyPosts}
          onChange={(v) => dispatch(setOnlyMyPosts(v))}
          className={classNames(
            onlyMyPosts ? 'bg-main-600' : 'bg-neutral-200',
            'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-500',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              onlyMyPosts ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}
