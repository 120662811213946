import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import {
  useHistory,
} from 'react-router-dom';
import { classNames } from '../../helpers/styling';
import { useAppDispatch } from '../../app/hooks';
import { createAccount } from '../../app/account';
import Button from '../../elements/Button';

interface ChooseProfileProps {
  setNextStep: () => void,
  onCancel: () => void,
}

const settings = [
  {
    type: 'individual',
    accountType: 1,
    name: 'I am an individual',
    description: 'A description about what an individual means. We might search for a better concept for individual.',
  },
  {
    type: 'corporate',
    accountType: 2,
    name: 'I represent a corporation',
    description: 'A description about what an corporation means. We might search for a better concept for it.',
  },
];

export default function ChooseProfile(props: ChooseProfileProps) {
  const { setNextStep, onCancel } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(settings[0]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="bg-white shadow-sm p-6 rounded-md">

      <h3 className="p-4 font-bold text-xl">
        Choose your profile
      </h3>

      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="bg-white rounded-md -space-y-px">
          {settings.map((setting, settingIdx) => (
            <RadioGroup.Option
              key={setting.type}
              value={setting}
              style={{ maxWidth: 500 }}
              className={({ checked }) => classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-main-50 border-main-200 z-10' : 'border-neutral-200',
                'relative border p-4 flex cursor-pointer focus:outline-none p-6',
              )}
            >
              {({ checked }) => (
                <>
                  <span
                    style={{ minWidth: 16 }}
                    className={classNames(
                      checked ? 'bg-main-600 border-transparent' : 'bg-white border-neutral-300',
                      // active ? 'ring-2 ring-offset-2 ring-main-500' : '',
                      'h-4 w-4 mt-1.5 mr-6 cursor-pointer rounded-full border flex items-center justify-center',
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-main-900' : 'text-neutral-900', 'block text-xl font-bold')}
                    >
                      {setting.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-main-700' : 'text-neutral-500', 'block text-sm')}
                    >
                      {setting.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="pt-6 flex justify-end w-full grid-cols-2 gap-4">
        <Button
          onClick={() => onCancel}
          size="big"
          description="Cancel"
          disabled={isLoading}
          inverted
        />
        <Button
          isLoading={isLoading}
          onClick={async () => {
            // TODO: post to account

            if (selected.type === 'individual') {
              setIsLoading(true);

              try {
                const { payload } = await dispatch(createAccount({ accountType: 1 }));
                setIsLoading(false);

                if (payload && payload.payload) { history.push(`/profile/${payload.payload.individualProfilePageId}`); }
              } catch (e) {
                setIsLoading(false);
              }

              return;
            }

            setNextStep();
          }}
          size="big"
          description="Select"
        />
      </div>
    </div>
  );
}
