import { RuleTypes, RulesForFieldsType, ValidationError } from '../../../helpers/validation';

export type ValidationKeys = 'text';

export const rulesFeedPost: RulesForFieldsType<ValidationKeys> = {
  text: {
    userFriendlyFieldName: 'text',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 0,
    maxLength: 500,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  text: { errorMessage: '' },
};
