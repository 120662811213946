import React from 'react';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import ModuleLayout from '../../components/ModuleLayout';
import FocusModuleView from './FocusModuleView';
import FocusModuleEdit from './FocusModuleEdit';

interface FocusModuleProps extends ModuleLayoutBaseProps {

}

export default function FocusModule(props: FocusModuleProps) {
  const { dragHandleProps, moduleMetadata, allowEditMetadataModule } = props;

  return (
    <ModuleLayout
      header="Focus"
      onReset={() => {}}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      viewComponent={<FocusModuleView />}
      editComponent={<FocusModuleEdit />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
