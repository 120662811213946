import React from 'react';
import { useAccountUpdateNewsletterMutation, useGetAccountNewsletterStatusQuery } from '../../newsletter/newsletterAPI';
import Switch from '../../../elements/Switch';
import ReceiveNewsletterToggle from './ReceiveNewsletterToggle';

export default function Newsletter() {

  const { data, isLoading } = useGetAccountNewsletterStatusQuery();

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        Newsletter
      </h2>
      <div>
        <div className="flex flex-row items-center pt-4">
          {
              isLoading && (
                <div className="flex animate-pulse flex-row h-full space-x-5">
                  <div className="w-full flex flex-col space-y-3">
                    <div className="w-72 bg-neutral-300 h-6 rounded-md " />
                  </div>
                </div>
              )
          }
          {
              data && <ReceiveNewsletterToggle data={data} />
          }
        </div>
      </div>
    </div>
  );
}
