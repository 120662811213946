import React from 'react';
import { find } from 'lodash';
import AppSelect from '../../elements/AppSelect';
import { PeriodSortFilters } from './statistics-types';

interface PeriodSelectionProps {
  setSorting: (newValue: PeriodSortFilters) => void,
  sorting: PeriodSortFilters,
  selectionChangedCallback: (selectedPeriod: PeriodSortFilters) => void,
}

const PERIODS_SORTING_OPTIONS = [
  {
    value: PeriodSortFilters.past7Days,
    label: 'Past 7 days',
  },
  {
    value: PeriodSortFilters.pastMonth,
    label: 'Past month',
  },  
  {
    value: PeriodSortFilters.pastYear,
    label: 'Past year',
  },
  {
    value: PeriodSortFilters.allTime,
    label: 'All Time',
  },  
];

export default function PeriodSelection(props: PeriodSelectionProps) {

  const { setSorting, sorting, selectionChangedCallback } = props;

  const value = find(PERIODS_SORTING_OPTIONS, { value: sorting });

  return (
    <div className="mt-4" style={{ width: 200 }}>
      <AppSelect
        placeholder="Sorting options"
        value={value}
        onChange={(newValue) => {
          if (! newValue) return;
          setSorting(newValue.value);
          selectionChangedCallback(newValue.value);
        }}
        options={PERIODS_SORTING_OPTIONS}
      />
    </div>
  );
}
