/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback } from 'react';
import { DocumentPlusIcon, DocumentArrowDownIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useDropzone } from 'react-dropzone';
import { classNames } from '../../helpers/styling';
import { formatBytesToString } from '../../features/post/create-post/helpers';
import { AboutModuleFile } from '../../features/profile-page/modules/about-module/aboutModuleTypes';

interface FileUploadProps {
  file: AboutModuleFile,
  onFileRemoval: () => void,
  onFileDrop: (objectURL: string, localFile: File) => void,
  showDeleteButton?: boolean,
}

export default function FileUpload(props: FileUploadProps) {

  const {
    file, onFileRemoval, onFileDrop, showDeleteButton = true,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    const localFile = acceptedFiles[0];

    try {
      const objectURL = URL.createObjectURL(localFile);

      onFileDrop(objectURL, localFile);

    } catch (e) {
      // console.log('Upload error');
    }

  }, [onFileDrop]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject,
  } = useDropzone({ accept: 'application/pdf', onDrop });

  if (! file || ! file.fileSizeBytes) {
    return (
      <div className={
        classNames(isDragActive ? 'border-gray-500' : 'border-gray-300',
          'container mt-2 border bg-white p-5 border-dashed')
      }
      >

        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <div className="flex items-center ">
            <DocumentPlusIcon className="text-neutral-400 w-5 h-5" />
            <p className="text-xs pl-2">

              Drag and drop the file or
              <span className="text-main-500"> click here </span>
              to upload
              <span className="text-neutral-400"> (up to 10MB)</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-between items-center shadow-md rounded-lg mt-1 p-2 bg-white">

      <div className="flex flex-row">
        <div
          className="height-fit-content shrink-0 bg-transparent mr-4 text-gray-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none m-2 "
        >
          <span className="sr-only">File</span>
          <DocumentArrowDownIcon className="h-6 w-6 text-main-600 bg-main-25" />
        </div>
        <div>
          <p className="text-main-600 text-xs">
            File
            {' '}
            {file.description}
            {' '}
            (
            <span>{formatBytesToString(file.fileSizeBytes)}</span>
            )
          </p>
          <p className="text-base font-semibold">{file.originalFilename}</p>
        </div>
      </div>

      {
        showDeleteButton && (
          <div className="">
            <button
              type="button"
              onClick={() => {

                if (onFileRemoval) onFileRemoval();
              }}
              className="shrink-0 bg-transparent mr-4 rounded-full ring-8 ring-offset-0 ring-error-25"
            >
              <span className="sr-only">Remove file</span>
              <TrashIcon className="h-4 w-4 text-error-600 bg-error-25" aria-hidden="true" />
            </button>
          </div>
        )
      }

    </div>
  );
}
