import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { setShowRegisterModalFlag } from '../../app/account';
import RegisterPromo from '../register-promo/RegisterPromo';
import FullscreenPopup from '../../elements/FullscreenPopup';
import { RootState } from '../../app/store';
import { FEATURE_REGISTER_ENABLED } from '../../config/features';
import { useAppDispatch } from '../../app/hooks';

export default function RegisterPopover() {
  const { showRegisterModal } = useSelector((state: RootState) => state.account);

  const dispatch = useAppDispatch();

  if (! FEATURE_REGISTER_ENABLED) return null;

  return (
    <FullscreenPopup
      open={showRegisterModal}
      setOpen={(flag) => dispatch(setShowRegisterModalFlag(flag))}
    >
      <div className="inline-block align-bottom bg-white overflow-x-hidden overflow-y-scroll transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">
        <div className="block absolute top-0 left-0 pt-12 pl-12">
          <button
            type="button"
            className="text-neutral-800 hover:text-neutral-600 focus:outline-none"
            onClick={() => dispatch(setShowRegisterModalFlag(false))}
          >
            <span className="sr-only">Close</span>
            <div className="flex flex-row items-center">
              <ArrowLeftIcon className="h-6 w-6 " aria-hidden="true" />
              <span className="pl-3 font-bold text-xs">
                Back
              </span>
            </div>
          </button>
        </div>

        <div className="mt-12">
          <RegisterPromo closeModal={() => dispatch(setShowRegisterModalFlag(false))} />
        </div>

      </div>
    </FullscreenPopup>
  );
}
