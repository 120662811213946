import React from 'react';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid';
import { VoteOperation } from '../forum-types';

interface ForumVotingProps {
  disabled: boolean, // user can't vote for it`s own questions or answers
  currentVote: VoteOperation | null,
  votesCounter: number,
  onUpVote: () => void,
  onDownVote: () => void,
}

// todo: transition + hover
// todo: lading state
export default function ForumVoting(props: ForumVotingProps) {

  const {
    votesCounter, onDownVote, onUpVote, currentVote, disabled,
  } = props;

  let baseColor = currentVote ? 'text-neutral-300' : 'text-neutral-700';

  // todo: animate hover etc
  let upvoteClass = 'text-success-500';
  let downVoteClass = 'text-warning-500';

  if (disabled) {
    baseColor = 'text-neutral-200';
    upvoteClass = 'text-neutral-200';
    downVoteClass = 'text-neutral-200';
  }

  return (
    <div className="flex flex-col items-center">
      <button
        type="button"
        disabled={disabled}
        onClick={onUpVote}
      >
        <ArrowUpCircleIcon className={`w-10 h-10 ${currentVote === VoteOperation.upVote ? upvoteClass : baseColor}`} />
      </button>

      <span className="text-neutral-900 font-bold">
        {votesCounter > 0 ? '+' : ''}
        { votesCounter }
      </span>

      <button
        type="button"
        disabled={disabled}
        onClick={onDownVote}
      >
        <ArrowDownCircleIcon className={`w-10 h-10 ${currentVote === VoteOperation.downVote ? downVoteClass : baseColor}`} />
      </button>
    </div>
  );
}
