import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Bars4Icon } from '@heroicons/react/24/solid';
import { ArrowUpOnSquareIcon, PencilIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';

interface PostMenuProps {
  setDeleteMode: (flag: boolean) => void,
  setRepostMode: (flag: boolean) => void,
  repostAllowed: boolean,
}

// TODO: display only if owner of the post
// TODO: allow also if user is platform admin per edit and per delete separately
export default function PostMenu(props: PostMenuProps) {

  const { setDeleteMode, setRepostMode, repostAllowed } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className={`rounded-full hover:bg-neutral-500 p-1.5 group transition-colors ${open && 'bg-neutral-200'}`}>
              <Bars4Icon className="text-gray-600 h-5 w-5 group-hover:text-white transition-colors" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-20 right-0 w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
              <div className="p-2.5 flex gap-1 flex-col">
                {
                  repostAllowed && (
                    <Menu.Item>
                      {({ active }) => (
                        <Button
                          onClick={() => setRepostMode(true)}
                          size="small"
                          iconLeft={<ArrowUpOnSquareIcon className="w-4 h-4 mr-2" />}
                          description="Repost"
                          inverted
                        />
                      )}
                    </Menu.Item>
                  )
                }
                {/* <Menu.Item> */}
                {/*  {({ active }) => ( */}
                {/*    <Button */}
                {/*      onClick={() => {}} */}
                {/*      size="small" */}
                {/*      iconLeft={<TrashIcon className="w-4 h-4 mr-2" />} */}
                {/*      description="Edit post" */}
                {/*      inverted */}
                {/*      roundedFull */}
                {/*    /> */}
                {/*  )} */}
                {/* </Menu.Item> */}
                <Menu.Item>
                  {({ active }) => (
                    <Button
                      onClick={() => setDeleteMode(true)}
                      size="small"
                      iconLeft={<PencilIcon className="w-4 h-4 mr-2" />}
                      description="Delete post"
                      redText
                      inverted
                    />
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
