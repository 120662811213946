import React from 'react';
import { useSelector } from 'react-redux';
import AboutModule from '../modules/about-module/AboutModule';
import TeamModule from '../modules/team-module/TeamModule';
import ServicesModule from '../modules/services-module/ServicesModule';
// import InterestedInModule from '../modules/interested-in/InterestedInModule';
// import NotInterestedInModule from '../modules/not-interested-in-module/NotInterestedInModule';
// import DosageFormModule from '../modules/dosage-form-module/DosageFormModule';
// import IngredientsModule from '../modules/ingridients-module/IngredientsModule';
import BusinessMarketsModule from '../modules/business-markets-module/BusinessMarketsModule';
import { RootState } from '../../../app/store';
import AreaOfInterestModule from '../modules/area-of-interest-module/AreaOfInterestModule';
import ProductsModule from '../modules/products-module/ProductsModule';
import FocusModule from '../modules/focus-module/FocusModule';

export const useGetComponentsToRender = () => {

  const { modulesMetadata } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);
  const { fullShowcaseEnabled } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);
  const { relatedCorporationProfilePageSummary } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);
  const { name } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);

  let items = {};

  if (fullShowcaseEnabled) {
    items = {
      moduleAbout: <AboutModule allowEditMetadataModule={fullShowcaseEnabled} relatedCorporationProfilePageSummary={relatedCorporationProfilePageSummary} />,
      moduleBusinessMarkets: <BusinessMarketsModule allowEditMetadataModule={fullShowcaseEnabled} />,
      moduleCorporationTeam: <TeamModule allowEditMetadataModule={fullShowcaseEnabled} corporationName={name} />,
      moduleInterest: <AreaOfInterestModule allowEditMetadataModule={fullShowcaseEnabled} />,
      moduleProduct: <ProductsModule allowEditMetadataModule={fullShowcaseEnabled} />,
      moduleService: <ServicesModule allowEditMetadataModule={fullShowcaseEnabled} />,
      moduleFocusCategory: <FocusModule allowEditMetadataModule={fullShowcaseEnabled} />,
      // unused:
      //   <NotInterestedInModule/>
      //   <DosageFormModule/>
      //   <IngredientsModule/>
    
    };
  } else {
    items = {
      moduleAbout: <AboutModule allowEditMetadataModule={fullShowcaseEnabled} relatedCorporationProfilePageSummary={relatedCorporationProfilePageSummary} />,
      moduleCorporationTeam: <TeamModule allowEditMetadataModule={fullShowcaseEnabled} corporationName={name} />,
    };
  }

  // IDs already filtered
  if (modulesMetadata.IDs.length === 0) {
    return [];
  }

  return modulesMetadata.IDs.map((id, index) => ({
    key: index,
    id,
    // @ts-ignore
    displayModule: modulesMetadata[id].displayModule,
    // @ts-ignore
    content: items[id],
  }));
};
