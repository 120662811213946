import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-google-charts';
import { RootState } from '../../app/store';
import { useDocumentTitle } from '../../helpers/hooks';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import { useGetCorporationStatisticsQuery } from './statisticsAPI';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setCorporationId, setPeriod } from './statisticsSlice';
import { PeriodSortFilters } from './statistics-types';
import PeriodSelection from './PeriodSelection';
import StatisticsLoader from './StatisticsLoader';
import { getCanAccessGlobalStatistics } from '../../app/permissionsSchema';
import CorporationSelection from './CorporationSelection';
import { ProfilePageType } from '../../app/account';
import { chartOptions } from './chart-config';

export default function StatisticsPage() {

  useDocumentTitle('Statistics');

  const dispatch = useAppDispatch();

  const canAccessGlobalStats = useAppSelector(getCanAccessGlobalStatistics);
  const { corporationDetails } = useSelector((state: RootState) => state.account);
  const { currentProfile: { profileID, profilePageType } } = useSelector((state: RootState) => state.account);
  const [chartData, setChartData] = useState<any[][]>([]);
  const [sorting, setSorting] = useState<PeriodSortFilters>(PeriodSortFilters.past7Days);
  const [sortingCorporations, setSortingCorporations] = useState<number>(0);

  const {
    period,
    corporationId,
  } = useSelector((state: RootState) => state.corporationStatistics);

  if (!canAccessGlobalStats && corporationDetails) {
    dispatch(setCorporationId(corporationDetails?.corporationId));
  }

  const { data: corporationStatistics, isFetching, isLoading } = useGetCorporationStatisticsQuery({
    corporationID: corporationId,
    requesterProfilePageId: profileID,
    period,
  });

  useEffect(() => {
    if (corporationStatistics) {
      setChartData(corporationStatistics);
    }
  }, [corporationStatistics]);

  const periodSelectionChanged = (selectedPeriod: PeriodSortFilters) => {
    if (selectedPeriod === PeriodSortFilters.past7Days) {
      dispatch(setPeriod(PeriodSortFilters.past7Days));

    } else if (selectedPeriod === PeriodSortFilters.pastMonth) {
      dispatch(setPeriod(PeriodSortFilters.pastMonth));

    } else if (selectedPeriod === PeriodSortFilters.pastYear) {
      dispatch(setPeriod(PeriodSortFilters.pastYear));

    } else if (selectedPeriod === PeriodSortFilters.allTime) {
      dispatch(setPeriod(PeriodSortFilters.allTime));
    }
  };

  const corporationSelectionChanged = (selectedCorporation: number) => {
    if (selectedCorporation === 0) {
      dispatch(setCorporationId(undefined));
    } else {
      dispatch(setCorporationId(selectedCorporation));
    }
  };

  const getStatisticsTitle = () => {
    let pageTitle = 'User Activity';
    if (!canAccessGlobalStats) {
      pageTitle = pageTitle.concat(` of ${corporationDetails?.name} members`);
    }
    return (<span>{ pageTitle }</span>);
  };

  const content = (
    <>
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        { getStatisticsTitle() }
      </h2>

      <div className="flex">
        <PeriodSelection
          // todo: check why need both setSorting() & selectionChangedCallback(); should be able to use only single value
          setSorting={setSorting}
          sorting={sorting}
          selectionChangedCallback={periodSelectionChanged}
        />
        {
          canAccessGlobalStats && (
            <div className="ml-4">
              <CorporationSelection
                setSorting={setSortingCorporations}
                sorting={sortingCorporations}
                selectionChangedCallback={corporationSelectionChanged}
              />
            </div>
          )
        }
      </div>

      {
        isLoading || isFetching ? (
          <div className="h-96 pt-32">
            <StatisticsLoader />
          </div>
        ) : (
          <div className="mt-1">
            <Chart
              chartType="ComboChart"
              data={chartData}
              width="100%"
              height="384px"
              options={chartOptions}
            />
          </div>
        )
      }
    </>
  );

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white shadow-sm rounded-md">
          <h2 className="text-3xl font-bold p-6 text-neutral-800">
            Statistics
          </h2>
        </div>
        <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
          {
            canAccessGlobalStats || profilePageType === ProfilePageType.corporation ? (
              content
            ) : (
              <div className="leading-6 text-center font-semibold text-neutral-800">
                There are no statistics to show. If you think this is an error, please contact an Administrator.
              </div>
            )
          }
        </div>
      </div>
    </DashboardLayout>
  );
}
