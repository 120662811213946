/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useEffect, useState } from 'react';
import { DocumentPlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useDropzone } from 'react-dropzone';
import { classNames } from '../../../../helpers/styling';

export interface IPostImage {
  id: string,
  guid?: string,
  blobURL: string,
  inEditMode: boolean,
  file?: File,
}

interface PostImageProps extends IPostImage {
  onDelete: () => void,
  onEdit: (post: IPostImage) => void,
}

export default function PostImage(props: PostImageProps) {

  const [localBlobURL, setLocalBlobURL] = useState('');
  const [uploadedImage, setUploadedImage] = useState<File | undefined>(undefined);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const {
    id, inEditMode, onDelete, onEdit, // blobURL
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    try {
      const objectURL = URL.createObjectURL(file);
      setLocalBlobURL(objectURL);
      setUploadedImage(file);
    } catch (e) {
      // console.log('Error', e);
    }

  }, []);

  useEffect(() => {

    if (! uploadedImage) return;

    const isInEditMode = ! localBlobURL;

    onEdit({
      id,
      inEditMode: isInEditMode,
      blobURL: localBlobURL,
      file: uploadedImage,
    });

    // eslint-disable-next-line
  }, [localBlobURL, uploadedImage]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  if (inEditMode) {
    return (
      <div className="bg-neutral-75 mt-2 rounded-lg flex flex-row">
        <div className="w-full">
          <div className="p-4 flex flex-row justify-between">
            <span className="text-xs font-semibold">
              Add an Image
            </span>
            <XMarkIcon onClick={onDelete} className="w-4 h-4 text-neutral-500 cursor-pointer" />
          </div>

          <div className="p-9 pt-0 w-full flex flex-col">

            {
              (! localBlobURL) && (
                <div className={
                  classNames(isDragActive ? 'border-gray-500' : 'border-gray-300',
                    'container mt-2 border bg-white p-5 border-dashed')
                }
                >

                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    <div className="flex items-center ">
                      <DocumentPlusIcon className="text-neutral-400 w-5 h-5" />
                      <p className="text-xs pl-2">

                        Drag and drop the file or
                        <span className="text-main-500"> click here </span>
                        to upload
                        <span className="text-neutral-400"> (up to 10MB)</span>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative pt-2">
      <div className="relative">
        <img alt="Preview" src={localBlobURL} className="max-h-96 object-contain w-full rounded-md" />
        <button
          type="button"
          onClick={() => setDeleteConfirmation(true)}
          className="shrink-0 absolute top-6 right-2 cursor-pointer bg-transparent mr-4 text-white rounded-full ring-4 ring-offset-0 ring-neutral-900 focus:outline-none"
        >
          <span className="sr-only">Remove file</span>
          <XMarkIcon className="h-4 w-4 text-white bg-neutral-900" />
        </button>
      </div>

      {
        deleteConfirmation && (
          <div className="absolute flex grid grid-rows-6 bg-white w-full h-full top-0 left-0 rounded rounded-lg border-4 border-error-400">
            <div className="flex w-full row-span-4 items-center justify-center">
              <span style={{ maxWidth: 300 }} className="font-extrabold text-2xl text-center">
                Are you sure you want to delete this image?
              </span>
            </div>

            <div className="w-full row-span-2 p-6 pt-0 flex justify-end items-end">
              <button
                type="button"
                onClick={() => setDeleteConfirmation(false)}
                className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => {
                  setDeleteConfirmation(false);
                  onDelete();
                }}
                className="inline-flex items-center px-5 py-1 border border-error-600 text-xs font-semibold rounded-3xl text-white bg-error-600 hover:border-error-700 hover:bg-error-700 focus:outline-none transition-colors duration-300"
              >
                Yes, delete
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
}
