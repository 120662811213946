import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import LandingNavigation from '../LandingNavigation';
import mainScreenBg from './assets/logoPart.svg';
import nucleoName from './assets/nucleoName.svg';
import Footer from '../Footer';
import FeatureSearch from './FeatureSearch';
import FeaturePages from './FeaturePages';
import '../react-image-zoom-styles.scss';
import FeatureMessaging from './FeatureMessaging';
import FeatureSecurity from './FeatureSecurity';
import { useDocumentTitle } from '../../../helpers/hooks';
import FaqPageSection from './faq/FaqPageSection';

export default function WhatIsNucleoPage() {
  useDocumentTitle('What is Nucleo?');

  return (
    <main className="what-is-nucleo-page bg-neutral-50 relative bg-white overflow-hidden">
      <section className="main-screen-container overflow-hidden md:overflow-visible relative flex justify-center pb-12 md:pb-0">
        <LandingNavigation />

        <img
          alt="Background"
          src={mainScreenBg}
          className="absolute top-0 -right-44 md:right-0 transform 2xl:scale-150"
        />

        {/* left section */}
        <Fade left>
          <div className="mt-24 xl:mt-0 z-10 w-full flex main-screen-container items-center max-w-6xl w-full px-4 md:px-6 xl:px-0">
            <div className="backdrop-blur-md w-full bg-white/90 md:w-auto px-8 py-12 rounded-md xl:p-0 xl:bg-transparent xl:backdrop-filter-none">
              {/* <span className="text-neutral-700 font-bold text-base">This is</span> */}
              <img
                alt="Nucleo"
                src={nucleoName}
              />
              {/* <p className="mt-6 text-neutral-700 font-bold text-base"> */}
              {/*  A better way to connect, share and promote products and */}
              {/*  <br /> */}
              {/*  services you are offering or seeking. */}
              {/* </p> */}
              <p className="mt-10 text-main-900 font-extrabold text-4xl sm:text-5xl md:text-5.5xl">
                Connecting real life
                <br />
                science professionals
              </p>
            </div>
          </div>
        </Fade>
      </section>

      <section className="overflow-hidden relative pb-12 md:pb-0">
        <div className="flex justify-center mt-2">
          <FeatureSearch />
        </div>
        <div className="flex justify-center mt-2">
          <FeaturePages />
        </div>
        <div className="flex justify-center mt-2">
          <FeatureMessaging />
        </div>
        <div className="flex justify-center mt-2">
          <FeatureSecurity />
        </div>
      </section>

      <FaqPageSection />

      <div className="relative overflow-hidden">
        <Footer />
      </div>
    </main>
  );
}
