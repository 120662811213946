import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import MembersManagement from './members-management/MembersManagement';
import { RootState } from '../../app/store';
import CorporateDetails from './corporate-details/CorporateDetails';
import { useDocumentTitle } from '../../helpers/hooks';
import { useAppSelector } from '../../app/hooks';
import { getCanEditCorporateSettings } from '../../app/permissionsSchema';
import InfoMessage from '../../elements/InfoMessage';
import { useGetCorporationInfoQuery } from './settingsAPI';
import CorporationDelete from './corporation-delete/CorporationDelete';
import MembersInvitations from './members-management/MembersInvitations';

export default function AdminCorporationsSettingsPage() {

  const canEditCorporateSettings = useAppSelector(getCanEditCorporateSettings);

  const history  = useHistory();
  // @ts-ignore
  let { corporationID } = useParams();
  corporationID = parseInt(corporationID, 10);

  const {
    data: corporationInfo,
    // isLoading: loadingCorpInfo,
    // isFetching: fetchingCorpInfo,
  } = useGetCorporationInfoQuery({ corporationID }, { skip: !corporationID });

  useDocumentTitle(`Admin Corporation Settings ${corporationInfo?.name}`);

  useEffect(() => {
    if (! canEditCorporateSettings) history.push('/page404');
  }, [canEditCorporateSettings, history]);

  let renderContent = null;
  if (! Number.isNaN(corporationID)) {
    renderContent = (
      <>
        {
          corporationInfo && (
            <>
              <CorporateDetails
                corporationID={corporationID}
                corporationNameParam={corporationInfo.name}
              />
              <MembersManagement corporationID={corporationID} corporationName={corporationInfo?.name} />
              <MembersInvitations corporationID={corporationID} corporationName={corporationInfo?.name} />
              <CorporationDelete corporationID={corporationID} />
            </>
          )
        }
      </>
    );
  } else {
    renderContent = (
      <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
        <InfoMessage
          message="Invalid corpoation ID"
        />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white shadow-sm rounded-md p-6">
          <h2 className="text-2xl font-bold text-neutral-800">
            Admin Corporation Settings:
            {' '}
            <span className="text-3xl">{corporationInfo?.name}</span>
          </h2>
        </div>

        { renderContent }
      </div>
    </DashboardLayout>

  );
}
