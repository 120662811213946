import React from 'react';
import { Link } from 'react-router-dom';
import InfoMessage from '../../../elements/InfoMessage';

export default function ModuleInfoSupportMessage() {
  return (
    <div className="w-3/5 mb-4 mt-2">
      <InfoMessage
        message={(
          <div>
            Please let us know by clicking
            {' '}
            <Link to="/contact-us" className="font-semibold underline">here</Link>
            , if you have different requirements for your services.
          </div>
        )}
      />
    </div>
  );
}
