import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../app/store';
import { useGetCorporationMembersInvitationsQuery, useGetCorporationMembersQuery } from '../settingsAPI';
import { useAppDispatch } from '../../../app/hooks';
import { setPage } from './membersInvitationsSlice';
import ModuleLoader from '../../profile-page/components/ModuleLoader';
import MemberCard from './MemberCard';
import Pagination from '../../../elements/pagination/Pagination';
import MemberInvitation from './MemberInvitation';
import InfoMessage from '../../../elements/InfoMessage';

interface MembersInvitationsListProps {
  corporationID: number,
  scrollOffset: number | null,
  corporationName: string,
}

export default function MembersInvitationsList(props: MembersInvitationsListProps) {

  const { corporationID, scrollOffset, corporationName } = props;

  const dispatch = useAppDispatch();

  const { getMembersInvitationsListRequest } = useSelector((state: RootState) => state.corporationMembersInvitations);

  const { data: membersInvitationsResponse, isFetching, isLoading } = useGetCorporationMembersInvitationsQuery({
    ...getMembersInvitationsListRequest,
    corporationID,
  }, { pollingInterval: 60 * 1000 });

  if (isLoading) {
    return (
      <ModuleLoader />
    );
  }

  if (! membersInvitationsResponse) {
    return (
      <div className="flex w-full justify-center mt-8">
        <span className="font-semibold text-neutral-600">
          Something went wrong. Please try again.
        </span>
      </div>
    );
  }

  return (
    <>
      {
          isFetching && (
          <div className="flex justify-center pb-8 pt-4">
            <ArrowPathIcon className="w-6 h-6 animate-spin text-neutral-600" />
          </div>
          )
      }
      {
        (!isFetching) && membersInvitationsResponse.data.length > 0 && (
          <div className="mb-6">
            <InfoMessage
              message={`List of Sent Invitations for New Members of ${corporationName}`}
            />
          </div>
        )
      }
      {
        membersInvitationsResponse.data.map((memberInvitation) => (
          <MemberInvitation
            firstname={memberInvitation.firstName}
            lastname={memberInvitation.lastName}
            email={memberInvitation.email}
            invitationID={memberInvitation.invitationId}
            corporationID={corporationID}
          />
        ))
      }
      {
        membersInvitationsResponse.data.length > 0 && (
        <div className="border-t py-4 mt-4 ">
          <Pagination
            pageSize={membersInvitationsResponse.pageSize}
            currentPage={membersInvitationsResponse.pageNumber}
            totalPages={membersInvitationsResponse.totalPages}
            totalRecords={membersInvitationsResponse.totalRecords}
            onPageChange={(pageNumber) => {
              dispatch(setPage(pageNumber));

              /*
              TODO: Set scroll reset on page change:

              if (! scrollOffset || Number.isNaN(scrollOffset)) {
                return;
              }

              window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: scrollOffset,
              });
              */
            }}
            label="Invitations"
          />
        </div>
        )
      }
    </>
  );
}
