import { RuleTypes, RulesForFieldsType, ValidationError } from '../../../../helpers/validation';

export type ValidationKeys = 'linkedin' | 'description';

export const aboutModuleValidationRules: RulesForFieldsType<ValidationKeys> = {
  linkedin: {
    userFriendlyFieldName: 'Linkedin',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 5,
    maxLength: 255,
  },
  description: {
    userFriendlyFieldName: 'Description',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 5,
    maxLength: 2500,
  },      
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  linkedin: { errorMessage: '' },
  description: { errorMessage: '' },
};
