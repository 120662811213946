import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetForumQuestionsListSearchQuery } from '../forumAPI';
import ForumQuestionListRender from './ForumQuestionListRender';
import { RootState } from '../../../app/store';

// todo: UI: disable filters when user searching (or show somehow that they are not applied)
export default function ForumQuestionsListWithSearch() {

  // todo: extract to the store
  const [page, setPage] = useState<number>(1);

  const { getListWithSearchRequest } = useSelector((state: RootState) => state.forum.questionsList);

  // todo: calculate values correctly and test (???)
  // todo: onPress set page (that's it) (???)
  const { data, isFetching, isLoading } = useGetForumQuestionsListSearchQuery({
    ...getListWithSearchRequest,
    pageNumber: page,
  });

  return (
    <ForumQuestionListRender
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
      setPage={setPage}
    />
  );
}
