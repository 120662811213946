import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodSortFilters } from './statistics-types';

interface StatisticsSlice {
  period: PeriodSortFilters,
  corporationId: number | undefined,
}

export const initialState: StatisticsSlice = {
  period: PeriodSortFilters.past7Days,
  corporationId: undefined,
};

export const statisticsSlice = createSlice({
  name: 'corporationStatistics',
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<PeriodSortFilters>) => {
      state.period = action.payload;
    },
    setCorporationId: (state, action: PayloadAction<number | undefined>) => {
      state.corporationId = action.payload;
    },
  },
});

export const {
  setPeriod,
  setCorporationId,
} = statisticsSlice.actions;

export default statisticsSlice;
