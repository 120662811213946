import React from 'react';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon, StarIcon } from '@heroicons/react/20/solid';
import { findIndex } from 'lodash';
import { useGetSavedSearchesQuery, usePostSavedSearchMutation } from '../../searchAPI';

interface SaveSearchProps {
  searchString: string,

}
export default function SaveSearch(props: SaveSearchProps) {

  const { searchString } = props;

  const [saveSearch] = usePostSavedSearchMutation();

  const { data } = useGetSavedSearchesQuery(null);

  let doesAlreadyExist = false;
  if (data) {
    doesAlreadyExist = findIndex(data, { input: searchString }) !== -1;
  }

  if (! searchString) {
    return (
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-main-700" aria-hidden="true" />
      </div>
    );
  }

  if (doesAlreadyExist) {
    return (
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <StarIcon className="h-5 w-5 text-main-700" aria-hidden="true" />
      </div>
    );
  }

  return (
    <button
      type="button"
      onClick={() => saveSearch(searchString)}
      className="absolute inset-y-0 left-0 pl-3 flex items-center"
    >
      <StarOutlineIcon className="h-5 w-5 text-main-700 transform hover:scale-110 transition-transform" />
    </button>
  );
}
