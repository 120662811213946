import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FlashMessages from '../flash-messages/FlashMessages';
import { useAcceptOwnershipInvitationMutation, useGetOwnershipByInviteQuery } from './settingsAPI';
import Button from '../../elements/Button';
import { useCupidoLogin, useDocumentTitle } from '../../helpers/hooks';
import { RootState } from '../../app/store';
import Logo from '../../elements/Logo';

export default function AcceptOwnershipInvitation() {

  const [successState, setSuccessState] = useState(false);

  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);

  // @ts-ignore
  const { invitationID } = useParams();
  const history = useHistory();

  const { instance, handleLogin } = useCupidoLogin();

  const [acceptOwnershipInvitationQuery] = useAcceptOwnershipInvitationMutation();

  const { data, isLoading } = useGetOwnershipByInviteQuery({ inviteID: invitationID }, { skip: ! invitationID });

  useDocumentTitle('Accept Invitation');

  useEffect(() => { // if user is authenticated -> go to profile page
    if (! profileID) return;

    history.push(`/profile/${profileID}`);
  }, [profileID, history]);

  const onAcceptOwnershipInvitation = async () => {
    const result = await acceptOwnershipInvitationQuery({ invitationCode: invitationID });

    // @ts-ignore
    if (! result.error) {
      setSuccessState(true);
    }
  };

  let content = null;
  if (! successState && data) { // todo: 1) if no corp details found -> show "invite is not valid blabla"
    content = (

      <>
        <h3 className="p-4 font-bold text-xl">
          You have been invited to take ownership of 
          {' '}
          { data.corporationProfilePageSummary.name }
          {' '}
          company. Do you wish to accept invitation?
        </h3>

        <div className="p-6 border border-neutral-300 rounded-md flex flex-row">
          {
            data.corporationProfilePageSummary.profileImageUrl ? (
              <img
                className="inline-block h-14 avatar-min w-14 min-w-full border border-neutral-200 rounded-md"
                src={data.corporationProfilePageSummary.profileImageUrl}
                alt="Avatar"
              />
            ) : (
              <div className="h-14 w-14 border border-neutral-300 bg-neutral-25 rounded-md flex justify-center items-center">
                <span className="font-extrabold text-2xl text-main-600">
                  {data.corporationProfilePageSummary.name[0]}
                </span>
              </div>
            )
          }

          <div className="pl-4">
            <p className="font-bold text-xl">
              { data.corporationProfilePageSummary.name }
            </p>
            <p className="pt-1 text-base text-neutral-500">
              { data.corporationProfilePageSummary.followersCount }
              {' '}
              followers
            </p>
          </div>
        </div>

        <div className="pt-6 flex justify-end w-full grid-cols-2 gap-4">
          <button
            type="button"
            onClick={() => history.push('/')}
            className="w-full text-base text-main-600 font-semibold p-4 rounded-md border border-neutral-200"
          >
            No
          </button>
          <button
            type="button"
            onClick={onAcceptOwnershipInvitation}
            className="w-full text-base font-semibold p-4 rounded-md border border-main-600 bg-main-600 text-white"
          >
            Yes
          </button>
        </div>
      </>
    );
  }

  if (successState) {
    content = (
      <div>
        <p className="p-4 font-bold text-xl">
          Success! Invite has been accepted. Please check your email for credentials and log in into an application.
        </p>
        <div className="pt-6 flex justify-end w-full">
          <Button
            onClick={() => handleLogin(instance)}
            size="big"
            description="Log in"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="inline-block align-bottom bg-white overflow-hidden transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">

      <div className="mt-12">
        <div className="flex flex-col justify-center items-center">
          <div className="h-20 mb-16">
            <Logo />
          </div>

          <div className="bg-white shadow-sm p-6 rounded-md">
            <div style={{ maxWidth: 496 }}>
              {content}
            </div>
          </div>
        </div>
      </div>

      <FlashMessages />
    </div>
  );
}
