import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clone } from 'lodash';
import { EMPTY_FILE } from './helpers';
import { generateRandomID } from '../../../../helpers/functions';
import { AboutModuleExternalLink, AboutModuleFile, GetAboutModuleResponse } from './aboutModuleTypes';
import { FlattenTerritory } from '../business-markets-module/business-markets-module-types';
import { flattenTerritories } from '../business-markets-module/helpers';

const EMPTY_DYNAMIC_FIELD = {
  id: 1,
  name: '',
  value: '',
  fieldOrder: 999,
};

export const EMPTY_LINK: AboutModuleExternalLink = { // 1 entry must always exist
  id: generateRandomID(),
  description: '',
  link: '',
  embedded: true,
};

export interface AboutModuleState {
  description: string,
  moduleAboutDynamicFields: Array<AboutModuleDynamicField>,
  fileAttachments: AboutModuleFile[],
  externalLinks: Array<AboutModuleExternalLink>,
  staticModuleAboutFields: boolean,
  showModuleAboutExtraFields: boolean,
  linkedin: string, // available only when showModuleAboutExtraFields == true
  territory: FlattenTerritory | null, // available only when showModuleAboutExtraFields == true
}

export interface AboutModuleDynamicField {
  id: number,
  name: string,
  value: string,
  fieldOrder: number
}

const initialState: AboutModuleState = {
  description: '',
  moduleAboutDynamicFields: [],
  fileAttachments: [
    EMPTY_FILE,
  ],
  externalLinks: [
    EMPTY_LINK,
  ],
  staticModuleAboutFields: false,
  showModuleAboutExtraFields: false,

  linkedin: '',
  territory: null,
};

export const aboutModuleSlice = createSlice({
  name: 'aboutModule',
  initialState,
  reducers: {
    initiateModule: (state, action: PayloadAction<GetAboutModuleResponse>) => {
      const { payload } = action;

      state.description = payload.description ? payload.description : '';
      state.moduleAboutDynamicFields = payload.moduleAboutDynamicFields;
      state.staticModuleAboutFields = payload.staticModuleAboutFields;
      state.showModuleAboutExtraFields = payload.showModuleAboutExtraFields;

      if (payload.extraFields) {
        state.territory = payload.extraFields.territory ? flattenTerritories([payload.extraFields.territory])[0] : null;
        state.linkedin = payload.extraFields.linkedInAccount || '';
      }

      if (payload.externalLinks && payload.externalLinks.length > 0) {
        state.externalLinks = payload.externalLinks;
      } else {
        state.externalLinks = [EMPTY_LINK]; // todo: check if random ID won't cause any issues
      }

      if (payload.fileAttachments && payload.fileAttachments.length > 0) {
        state.fileAttachments = payload.fileAttachments;
      } else {
        state.fileAttachments = [EMPTY_FILE];
      }
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    updateLinkedin: (state, action) => {
      state.linkedin = action.payload;
    },
    updateTerritory: (state, action: PayloadAction<FlattenTerritory>) => {
      state.territory = action.payload;
    },
    reorder: (state, action) => {

      const { startIndex, endIndex } = action.payload;

      let result = Array.from(state.moduleAboutDynamicFields);
      const [removed] = result.splice(startIndex, 1);

      const copy = removed;
      copy.fieldOrder = endIndex + 1;

      // ordered array
      result.splice(endIndex, 0, copy);

      // update counter for every field
      result = result.map((r, index) => {
        const clonedRes = clone(r);
        clonedRes.fieldOrder = index + 1;
        return clonedRes;
      });

      // console.log('result', result);
      state.moduleAboutDynamicFields = result;
    },

    /* Link and File */
    updateLink: (state, action: PayloadAction<AboutModuleExternalLink>) => {
      state.externalLinks = [action.payload];
    },
    updateFile: (state, action: PayloadAction<AboutModuleFile>) => {
      state.fileAttachments = [action.payload];
    },

    /* Dynamic fields */
    addDynamicField: (state) => {
      state.moduleAboutDynamicFields = [
        ...state.moduleAboutDynamicFields,
        {
          ...EMPTY_DYNAMIC_FIELD,
          id: generateRandomID(), // potential key duplicate
        },
      ];
    },
    updateDynamicField: (state, action) => {
      state.moduleAboutDynamicFields = state.moduleAboutDynamicFields.map((field) => {
        if (action.payload.id !== field.id) {
          return field;
        }

        return action.payload;
      });
    },
    deleteDynamicField: (state, action) => {
      state.moduleAboutDynamicFields = state.moduleAboutDynamicFields.filter(({ id }) => id !== action.payload);
    },
  },
});

export const {
  updateDescription,
  updateTerritory,
  updateLinkedin,
  reorder,
  initiateModule,

  updateLink,
  updateFile,

  addDynamicField,
  updateDynamicField,
  deleteDynamicField,
} = aboutModuleSlice.actions;

export default aboutModuleSlice.reducer;
