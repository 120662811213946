import { emptySplitApi } from '../../app/api';
import { GetFeedModerationListRequest, GetFeedModerationListResponse } from './types';
import { filterNullParams } from './helpers';

export const feedModerationAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getFeedList: builder.query<GetFeedModerationListResponse, GetFeedModerationListRequest>({
      query: ({
        pageSize, pageNumber, creatorProfilePageId, startDateUtc, endDateUtc,
      }) => {
        const params = filterNullParams({
          PageNumber: pageNumber,
          PageSize: pageSize,
          CreatorProfilePageId: creatorProfilePageId,
          StartDateUtc: startDateUtc ? new Date(startDateUtc).toISOString() : startDateUtc,
          EndDateUtc: endDateUtc ? new Date(endDateUtc).toISOString() : endDateUtc,
        });

        return {
          url: '/ActivityFeed/posts',
          params,
        };
      },

      providesTags: (result, error, arg) => [
        { type: 'FeedModerationList', ...arg },
      ],
    }),
  }),
});

export const {
  useGetFeedListQuery,
} = feedModerationAPI;
