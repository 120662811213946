import React, { ReactElement, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { FaqQuestionEntry } from './contact-us-types';
import FaqQuestion from './FaqQuestion';

interface FaqSectionProps {
  title: string,
  questions: FaqQuestionEntry[],
  icon?: ReactElement,
}

export default function FaqSection(props: FaqSectionProps) {
  const { title, questions, icon } = props;
  const [isVisible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <div className="mb-4 border border-neutral-200 rounded-md p-4">
      <button onClick={toggleVisibility} type="button">
        <div className="flex justify-between items-center">
          {
            icon && (
              <div className="mr-2">
                { icon }
              </div>
            )
          }
          <h3 className="text-xl font-bold text-neutral-800">{title}</h3>
          {isVisible ? (
            <ChevronUpIcon className="ml-2 h-6 w-6 text-gray-500 cursor-pointer" />
          ) : (
            <ChevronDownIcon className="ml-2 h-6 w-6 text-gray-500 cursor-pointer" />
          )}
        </div>
      </button>
      <div className="">
        {isVisible && (
          <div className="mt-8">
            {questions.map((question) => (
              <FaqQuestion
                question={question}
                key={`${title}-section-${question.questionTitle}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
