import React from 'react';
import { TrophyIcon } from '@heroicons/react/24/solid';
import { BadgeType, ProfilePageBadge } from '../features/profile-page/profileTypes';

interface BadgeProps {
  badges: ProfilePageBadge[],
  small?: boolean,
}

export default function Badge(props: BadgeProps) {

  const { badges, small = false } = props;

  if (! badges || badges.length === 0) return null;

  return (
    <div className="ml-1">
      {
        badges && badges.map((badge) => {

          if (! badge) return null;

          if (badge.badgeType === BadgeType.firstHundredCorporations) {
            return (
              <TrophyIcon
                key={badge.badgeType}
                className={`text-warning-400 ${small ? 'w-4 h-4' : 'w-5 h-5'}`}
              />
            );
          }

          return null;
        })
      }
    </div>
  );
}
