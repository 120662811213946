/* eslint-disable react/require-default-props */
import React, { ChangeEvent, FocusEvent, useState } from 'react';

import './styles.scss';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { isEqual } from 'lodash';

interface InputProps {
  value: string | number,
  id?: string,
  type?: 'text' | 'password' | 'datetime-local',
  label?: string,
  placeholder: string,
  options?: Array<{ label: string, value: any }>,
  onOptionsChange?: (e: ChangeEvent<HTMLSelectElement>) => void,
  optionsValue?: any,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
  disabled?: boolean,
  validationMessage?: string,
}

// IF no ID or label - render without label
export default function Input(props: InputProps) {

  const [showPassword, setShowPassword] = useState(false);

  const {
    id, value, label, placeholder, onChange, onBlur, type = 'text', options, onOptionsChange, optionsValue, disabled, validationMessage,
  } = props;

  // eslint-disable-next-line no-nested-ternary
  let inputType = type === 'password' ? (! showPassword ? 'password' : 'text') : type;
  inputType = type === 'datetime-local' ? 'datetime-local' : type;

  return (
    <div className={`
    relative bg-white border border-neutral-300 rounded-md px-3 py-2
    ${disabled ? ' bg-neutral-75 ' : ' '}
     focus-within:z-10 focus-within:ring-1 focus-within:ring-main-600
      focus-within:border-main-600
      `}
    >
      {
        (id && label) && (
          <label htmlFor={id} className="block w-full text-xs font-medium text-neutral-700">
            { label }
          </label>
        )
      }
      <input
        onKeyDown={(e) => {
          if (e.nativeEvent.code === 'Space') { // for products module header
            e.stopPropagation();
          }
        }}
        onClick={(e) => e.stopPropagation()} // for products module header
        type={inputType}
        onBlur={onBlur}
        onChange={onChange}
        name={id}
        value={value}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        className="ds-input bg-transparent block w-full border-0 p-0 font-semibold text-base text-neutral-900 placeholder-neutral-500 focus:ring-0 sm:text-sm"
      />

      {
        type === 'password' && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {
              showPassword ? (
                <EyeSlashIcon
                  onClick={() => setShowPassword(false)}
                  className="h-5 w-5 text-main-500 cursor-pointer"
                />
              ) : (
                <EyeIcon
                  onClick={() => setShowPassword(true)}
                  className="h-5 w-5 text-main-500 cursor-pointer"
                />
              )
            }
          </div>
        )
      }

      {
        options && onOptionsChange && options.length > 0 && (
          <div className="absolute inset-y-0 right-0 border-l">
            <select
              disabled={disabled}
              onChange={(e) => onOptionsChange(e)}
              id={`${id}-options`}
              name={`${id}-options`}
              className="h-full py-0 pl-2 pr-7 border-transparent bg-transparent font-semibold text-sm text-neutral-500 outline-none rounded-md"
            >
              {
                options.map((option) => (
                  <option value={option.value} selected={isEqual(option.value, optionsValue)}>{option.label}</option>
                ))
              }
            </select>
          </div>
        )
      }

      {
        validationMessage && (
          <div className="bg-error-50 p-1 mt-1 rounded-sm">
            <p className="text-xs text-error-600">
              {validationMessage}
            </p>
          </div>
        )
      }
    </div>
  );
}
