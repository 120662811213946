/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import {
  DocumentArrowDownIcon, TrashIcon, XMarkIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '../../../../helpers/styling';
import { formatBytesToString } from '../helpers';
import FileUpload from '../../../../elements/files/FileUpload';
import { useAppDispatch } from '../../../../app/hooks';
import { deletePDF, editPDF } from '../createPostSlice';
import { AboutModuleFile } from '../../../profile-page/modules/about-module/aboutModuleTypes';
import { ValidationKeys, rulesPostPdf, validationErrorState } from './postPDFValidationRules';
import { useFormValidation } from '../../../../helpers/validation';
import Input from '../../../../elements/Input';
// import { updateFile } from '../createPostSlice';

export interface IPostPDF {
  id: string,
  title: string,
  inEditMode: boolean,
  file: AboutModuleFile
}

interface PostPDFProps extends IPostPDF {
  setFile: (f: File) => void,
}

export default function PostPDF(props: PostPDFProps) {

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useAppDispatch();

  const {
    id, file, title, inEditMode, setFile,
  } = props;

  const attachDisabled = ! file || ! title;

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesPostPdf,
    validationErrorState,
    {
      title,
    },
  );

  if (inEditMode) {
    return (
      <div className="bg-neutral-75 mt-2 rounded-lg flex flex-row">
        <div className="w-full">
          <div className="p-4 flex flex-row justify-between">
            <span className="text-xs font-semibold">
              Attach a PDF
            </span>
            <XMarkIcon
              onClick={() => {
                dispatch(deletePDF(id));
              }}
              className="w-4 h-4 text-neutral-500 cursor-pointer"
            />
          </div>

          <div className="p-9 pt-0 w-full flex flex-col">
            <div>              
              <Input
                type="text"
                id="name"
                value={title}
                onChange={(e) => {
                  dispatch(editPDF({
                    id,
                    inEditMode,
                    title: e.target.value,
                    file: {
                      ...file,
                      description: e.target.value,
                    },
                  }));
                }}
                label="Title of the PDF"
                placeholder="Title..."
                validationMessage={validationState.title.errorMessage}
              />
              {/* File was just added */}
              <FileUpload
                file={file}
                onFileRemoval={() => {}}
                onFileDrop={(objectURL, localFile) => {
                  setFile(localFile);

                  dispatch(editPDF({
                    id,
                    title,
                    inEditMode,
                    file: {
                      ...file,
                      description: title,
                      fileUrl: objectURL,
                      originalFilename: localFile.name,
                      fileSizeBytes: localFile.size,
                    },
                  }));
                }}
                showDeleteButton={false}
              />

            </div>

            <div className="w-full pt-4 flex justify-end items-end">
              <button
                type="button"
                onClick={() => dispatch(deletePDF(id))}
                className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={attachDisabled}
                onClick={() => {

                  const val = validate();
                  if (! val.allValid) return;

                  dispatch(editPDF({
                    id,
                    title,
                    inEditMode: false,
                    file,
                  }));
                }}
                className={
                  classNames(
                    attachDisabled ? 'opacity-50 pointer-events-none' : '',
                    'inline-flex items-center px-5 py-1 border border-neutral-800 text-xs text-white font-semibold rounded-3xl bg-neutral-800 hover:border-main-600 hover:bg-main-600 focus:outline-none transition-colors duration-300',
                  )
                }
              >
                Attach
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* State for delete confirmation */
  if (deleteConfirmation) {
    return (
      <div className="flex flex-row justify-between items-center border-4 border-error-400 border-color rounded-2xl mt-1 p-2">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="">
            <p className="text-sm font-extrabold">Are you sure do you want to delete it?</p>
            <p className="text-xs font-semibold text-neutral-500">{title}</p>
          </div>

          {/* Buttons */}
          <div className="flex justify-end items-end">
            <button
              type="button"
              onClick={() => setDeleteConfirmation(false)}
              className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch(deletePDF(id));
              }}
              className="inline-flex items-center px-5 py-1 border border-error-600 text-xs text-white font-semibold rounded-3xl bg-error-600 hover:border-error-800 hover:bg-error-800 focus:outline-none transition-colors duration-300"
            >
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  /* State after file was added */
  return (

    <div className="flex flex-row justify-between items-center shadow-md rounded-lg mt-1 p-2">

      <div className="flex flex-row">
        <button
          type="button"
          className="height-fit-content shrink-0 bg-transparent mr-4 text-gray-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none m-2 "
        >
          <span className="sr-only">{title}</span>
          <DocumentArrowDownIcon className="h-6 w-6 text-main-600 bg-main-25" />
        </button>
        <div>
          <p className="text-main-600 text-xs">
            {title}
            {' '}
            {formatBytesToString(file.fileSizeBytes)}
          </p>
          <p className="text-base font-semibold">{file.originalFilename}</p>
        </div>
      </div>

      <div className="">
        <button
          type="button"
          onClick={() => setDeleteConfirmation(true)}
          className="shrink-0 bg-transparent mr-4 rounded-full ring-8 ring-offset-0 ring-error-25"
        >
          <span className="sr-only">Remove file</span>
          <TrashIcon className="h-4 w-4 text-error-600 bg-error-25" aria-hidden="true" />
        </button>
      </div>
    </div>

  );
}
