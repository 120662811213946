export const MAX_IMAGE_SIZE = process.env.REACT_APP_MAX_IMAGE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE, 10)
  : 0;

export const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10)
  : 0;

export const TERRITORY_IMAGE_URL = process.env.REACT_APP_TERRITORY_IMAGE_URL
  ? process.env.REACT_APP_TERRITORY_IMAGE_URL
  : '';
