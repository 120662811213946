import React, { useEffect } from 'react';
import { html } from './Nucleo/CookiePolicy_gonucleo_EN_221108';
import { useDocumentTitle } from '../../../helpers/hooks';
import { BRANDING } from '../../../helpers/branding';
import { cookiePolicyIpls } from './IPLS/CookiePolicy_IPLS_EN';

export default function CookiesPolicyPage() {
  useDocumentTitle('Cookies Policy');

  useEffect(() => {
    if (BRANDING === 'IPLS') {
      window.location.href = 'https://ipls.online/cookie-policy';
    }
  }, []);

  if (BRANDING === 'NUCLEO') {
    return <iframe title="Privacy Policy" style={{ width: '100%', height: '100vh' }} srcDoc={html} />;
  }
  
  return null;
}
