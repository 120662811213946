import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { GroupWithoutProducts, Product } from '../products-module.types';
import ProductView from './ProductView';
import GroupViewHeader from './GroupViewHeader';

interface GroupViewProps {
  group: GroupWithoutProducts,
  products: Product[],
  inEditMode: boolean,
}

export default function GroupView(props: GroupViewProps) {

  const { group, products, inEditMode } = props;
  const [groupOpen, setGroupOpen] = useState(false);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full border-b">
            <div
              role="none"
              className="w-full flex flex-row justify-between items-center"
              onClick={() => setGroupOpen(! open)}
            >
              <GroupViewHeader
                groupName={group.groupName}
                groupID={group.groupId}
                open={open}
                inEditMode={inEditMode}
                productIDs={products.map((p) => p.id)}
              />
            </div>
          </Disclosure.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              {
                products.map((product) => (
                  <ProductView key={product.id} product={product} inEditMode={inEditMode} />
                ))
              }
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
