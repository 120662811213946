import React from 'react';
import { TagIcon } from '@heroicons/react/20/solid';
import TerritoryFlag from '../../business-markets-module/elements/TerritoryFlag';
import FileDownloadPreview from '../../../../../elements/FileDownloadPreview';
import ExternalLinkView from '../../../../../elements/ExternalLinkView';
import { IFocus } from '../focus-types';

interface FocusDetailedViewProps {
  focus: IFocus,
}

export default function FocusDetailedView(props: FocusDetailedViewProps) {

  const { focus } = props;

  return (
    <div className="pl-10 py-8 pr-12">
      {
        focus.territories.length > 0 && (
          <div className="mt-6">
            <p className="text-xs text-neutral-700 py-2">
              Territories
            </p>
            <div className="flex flex-row flex-wrap gap-2 mt-1 mb-2">
              {
              focus.territories.map((territory) => (
                <div key={territory.territoryName} className="flex flex-row items-center px-3 py-2 border border-neutral-200 rounded-md">
                  <div className="flex flex-row">
                    <TerritoryFlag small territoryName={territory.territoryName} isoAlpha3Code={territory.isoAlpha3Code} />
                    <span className="ml-1.5 font-semibold text-xs">{ territory.territoryName }</span>
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        )
      }
      {
        ((focus.fileAttachments && focus.fileAttachments.length > 0) || (focus.externalLinks && focus.externalLinks.length > 0)) && (
          <div className="mt-6 grid grid-cols-2 gap-4">
            {
              focus.fileAttachments && focus.fileAttachments.map((file) => (
                <FileDownloadPreview
                  key={file.id}
                  file={{
                    ...file,
                    title: file.description,
                  }}
                />
              ))
            }
            {
              focus.externalLinks && focus.externalLinks.map((link) => (
                <ExternalLinkView key={link.id} link={link.link} description={link.description} />
              ))
            }
          </div>
        )
      }
      {
        focus.coreTags.length > 0 && (
          <div className="pt-4">
            <p className="text-xs text-neutral-700 py-2">
              Tags
            </p>
            <div className="pt-1 flex flex-row gap-1">
              {
                focus.coreTags.map((tag) => (
                  <div key={tag.name} className="text-xs py-1 px-2 rounded-md border border-main-200 bg-main-25 flex flex-row items-center">
                    <TagIcon className="w-3 h-3 text-main-300 mr-1" />
                    {tag.name}
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
