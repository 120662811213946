/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Bars3Icon, TrashIcon } from '@heroicons/react/20/solid';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Input from '../../../../elements/Input';
import Button from '../../../../elements/Button';
import {
  AboutModuleDynamicField as AboutModuleDynamicFieldType,
  deleteDynamicField, updateDynamicField,
} from './aboutModuleSlice';
import { useAppDispatch } from '../../../../app/hooks';

interface AboutModuleDynamicFieldProps {
  field: AboutModuleDynamicFieldType,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  isStatic: boolean,
}

export default function AboutModuleDynamicField(props: AboutModuleDynamicFieldProps) {

  const { dragHandleProps, field, isStatic } = props;

  const [deleteMode, setDeleteMode] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className="p-2 bg-neutral-25 border rounded-md">
      {
        !isStatic && (
          <Input
            disabled={isStatic}
            value={field.name}
            id="df-key-1"
            label="Title"
            placeholder="Start typing..."
            onChange={(e) => {
              dispatch(updateDynamicField({
                ...field,
                ...{ name: e.target.value },
              }));
            }}
          />
        )
      }
      <div className="pt-2">
        <Input          
          value={field.value}
          id="df-key-2"
          label={isStatic ? field.name : 'website, email, phone number, or text'}
          placeholder="Start typing..."
          onChange={(e) => {
            dispatch(updateDynamicField({
              ...field,
              ...{ value: e.target.value },
            }));
          }}
        />
      </div>
      <div className="pt-2 flex flex-row justify-between">
        <div className="flex flex-row">
          {
            !isStatic && (
              <div className="max-w-max mr-2">
                <Button
                  onClick={() => setDeleteMode(true)}
                  size="small"
                  description=""
                  roundedFull
                  inverted={! deleteMode}
                  fullRed
                  iconLeft={<TrashIcon className="w-4 h-4" />}
                />
              </div>
            )
          }
          {
            deleteMode && (
              <>
                <Button
                  onClick={() => setDeleteMode(false)}
                  size="small"
                  description="Cancel"
                  roundedFull
                  fullRed
                />
                <div className="ml-2">
                  <Button
                    onClick={() => {
                      setDeleteMode(false);
                      dispatch(deleteDynamicField(field.id));
                    }}
                    size="small"
                    description="Delete"
                    redText
                    roundedFull
                    inverted
                  />
                </div>
              </>
            )
          }
        </div>

        <div
          tabIndex={0}
          onKeyDown={() => {}}
          role="button"
          onClick={() => {}}
          className="font-semibold border flex flex-row items-center justify-center rounded-full border-neutral-300 text-main-600 bg-white px-4 p-2 text-xs"
          {...dragHandleProps}
        >
          Organise
          <div className="pl-1">
            <Bars3Icon className="w-4 h-4 text-neutral-800" />
          </div>
        </div>

      </div>
    </div>
  );
}
