import { clone, orderBy } from 'lodash';
import { emptySplitApi } from '../../../../app/api';
import { CorporationMemberType, TeamModuleGet } from './team-module-types';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';

export const teamModuleAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamModule: builder.query<TeamModuleGet, { id?: number }>({
      query: ({ id }) => ({
        url: `/modules/CorporationTeam/profile-page/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'TeamModule', profileID: arg.id }],
      transformResponse: (response: TeamModuleGet) => {
        const r = clone(response);

        r.moduleCorporationTeamMembers = orderBy(r.moduleCorporationTeamMembers, ['memberOrder']);

        return r;
      },
    }),
    updateTeamModule: builder.mutation<any, { profileID?: number, body: { description: string, moduleCorporationTeamMembers: CorporationMemberType[] } }>({

      query(data) {
        const { profileID, body } = data;

        return {
          url: `/modules/CorporationTeam/profile-page/${profileID}`,
          method: 'PUT',
          body,
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Team module was successfully updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Update went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [{ type: 'TeamModule', profileID: arg.profileID }],
    }),
  }),
});

export const {
  useGetTeamModuleQuery,
  useUpdateTeamModuleMutation,
} = teamModuleAPI;
