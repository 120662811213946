import React from 'react';
import { MultiValue } from 'react-select';
import { find } from 'lodash';
import { ProductCountry, RegStatusInfoEnum, RegStatusInformation } from '../../products-module.types';
import {
  FlattenTerritory,
} from '../../../business-markets-module/business-markets-module-types';
import MarketsSelection from '../../../business-markets-module/elements/MarketsSelection';
import { useAppDispatch } from '../../../../../../app/hooks';
import { updateCreateProductRegStatusInfo } from '../../productsModuleSlice';

interface RegulatoryStatusInformationProps {
  productID: number,
  statusInfo: RegStatusInformation[]
}

export default function RegulatoryStatusInformation(props: RegulatoryStatusInformationProps) {
  const { productID, statusInfo } = props;

  const dispatch = useAppDispatch();

  // todo: SET values form statusInfo
  const setInputValue = (status: RegStatusInfoEnum) => {
    const foundValues = find(statusInfo, { statusInfoType: status });

    if (! foundValues) return [];

    return foundValues.countries.map((c: ProductCountry) => ({
      id: c.coreTerritory.id,
      territoryName: c.coreTerritory.name,
      isoAlpha3Code: c.coreTerritory.isoAlpha3Code || '',
      nestingLevel: 0, // unknown

      value: c.coreTerritory.id, // duplicate id
      label: c.coreTerritory.name, // duplicate territoryName
      childTerritories: null,
    }));

  };

  const onTerritorySelection = (selectedTerritories: MultiValue<FlattenTerritory>, status: RegStatusInfoEnum) => {
    dispatch(updateCreateProductRegStatusInfo({
      productID,
      value: {
        selectedTerritories,
        regStatusInfo: status,
      },
    }));
  };

  return (
    <div className="border rounded-md bg-neutral-25 p-2">
      <p className="p-2 text-neutral-700 text-xs">
        Regulatory Status Information
      </p>

      <div className="border rounded-md p-2 mt-2 bg-neutral-50">
        <p className="pb-2 text-neutral-700 text-xs font-semibold">
          Under development
        </p>
        <MarketsSelection
          onChange={(t: MultiValue<FlattenTerritory>) => onTerritorySelection(t, RegStatusInfoEnum.underDevelopment)}
          value={setInputValue(RegStatusInfoEnum.underDevelopment)}
        />
      </div>

      <div className="border rounded-md p-2 mt-2 bg-neutral-50">
        <p className="pb-2 text-neutral-700 text-xs font-semibold">
          Under registration
        </p>
        <MarketsSelection
          onChange={(t: MultiValue<FlattenTerritory>) => onTerritorySelection(t, RegStatusInfoEnum.underRegistration)}
          value={setInputValue(RegStatusInfoEnum.underRegistration)}
        />
      </div>

      <div className="border rounded-md p-2 mt-2 bg-neutral-50">
        <p className="pb-2 text-neutral-700 text-xs font-semibold">
          Approved
        </p>
        <MarketsSelection
          onChange={(t: MultiValue<FlattenTerritory>) => onTerritorySelection(t, RegStatusInfoEnum.approved)}
          value={setInputValue(RegStatusInfoEnum.approved)}
        />
      </div>

      <div className="border rounded-md p-2 mt-2 bg-neutral-50">
        <p className="pb-2 text-neutral-700 text-xs font-semibold">
          Marketed
        </p>
        <MarketsSelection
          onChange={(t: MultiValue<FlattenTerritory>) => onTerritorySelection(t, RegStatusInfoEnum.marketed)}
          value={setInputValue(RegStatusInfoEnum.marketed)}
        />
      </div>

    </div>
  );
}
