import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionMeta, OnChangeValue } from 'react-select';
import { findIndex } from 'lodash';
import { RootState } from '../../../../../../app/store';
import { useGetProductGroupsQuery } from '../../productsModuleAPI';
import AppSelect from '../../../../../../elements/AppSelect';
import { useAppDispatch } from '../../../../../../app/hooks';
import { updateCreateProductGroup } from '../../productsModuleSlice';
import InputLoader from '../../../../../../elements/InputLoader';

interface GroupSelectionProps {
  productID: number,
  value: string, // group name
}

export default function GroupSelection(props: GroupSelectionProps) {

  const { productID, value } = props;

  const [options, setOptions] = useState<{ value: string, label: string }[]>([]);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const { data: groups, isLoading, isFetching } = useGetProductGroupsQuery(
    { profileID: currentlyLoadedProfileID, requesterID: profileID },
    { skip: !currentlyLoadedProfileID },
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (! groups) return;

    setOptions(groups.map((group) => ({
      value: group.productGroupName, label: group.productGroupName,
    })));

  }, [groups]);

  const onChange = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
    const groupsForMerge = groups || [];

    if (actionMeta.action === 'create-option') { // add new option to the list
      setOptions(
        [
          newValue,
          ...groupsForMerge.map((group) => ({
            value: group.productGroupName, label: group.productGroupName,
          })),
        ],
      );
    }

    let groupID = null;
    if (newValue) {

      if (groups && groups.length > 0) { // find group ID
        const foundGroupIndex = findIndex(groups, { productGroupName: newValue.value });

        if (foundGroupIndex !== -1) {
          groupID = groups[foundGroupIndex].id;
        }
      }
    }

    dispatch(updateCreateProductGroup({
      productID,
      value: {
        groupName: newValue ? newValue.value : null,
        groupID,
      },
    }));
  };

  if (isLoading || isFetching) {
    return <InputLoader label="Groups" />;
  }

  return (
    <AppSelect
      isClearable
      isCreatable
      placeholder="Start typing or select a group"
      // defaultValue={defaultValues}
      value={value && {
        value,
        label: value,
      }}
      onChange={onChange}
      options={options}
    />
  );
}
