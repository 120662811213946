import React, { useState } from 'react';
import { createFilter, OnChangeValue } from 'react-select';
import { AtcGroup } from '../../products-module.types';
import { useGetAtcCodeQuery } from '../../productsModuleAPI';
import { useAppDispatch } from '../../../../../../app/hooks';
import InputLoader from '../../../../../../elements/InputLoader';
import {
  updateCreateProductAtcGroup,
} from '../../productsModuleSlice';
import AppSelect from '../../../../../../elements/AppSelect';
import { searchAtcGroup } from './helpers';

interface ATCGroupProps {
  productID: number,
  atcGroupValue?: AtcGroup
}

// todo: (important) check relation between atc code and group
export default function ATCGroup(props: ATCGroupProps) {

  const { productID, atcGroupValue } = props;

  const { data, isLoading, isFetching } = useGetAtcCodeQuery(null);
  const [searchString, setSearchString] = useState('');

  const dispatch = useAppDispatch();

  let content = null;
  if (! data || isLoading || isFetching) {
    content = (
      <InputLoader />
    );
  }

  const setValue = (): AtcGroup & { value: number, label: string } | undefined => {
    if (! atcGroupValue) return atcGroupValue;

    return {
      ...atcGroupValue,
      value: atcGroupValue.id,
      label: atcGroupValue.atcCodeName,
    };
  };

  const onChange = (newValue: OnChangeValue<AtcGroup, false>) => {
    dispatch(updateCreateProductAtcGroup({
      productID,
      value: newValue,
    }));
  };

  if (data && data.length > 0) {
    content = (
      <AppSelect
        onInputChange={(input) => setSearchString(input)}
        // defaultValue={defaultValues}
        value={setValue()}
        onChange={onChange}
        filterOption={createFilter({ ignoreAccents: false })} // optimization
        options={searchAtcGroup(data, atcGroupValue, searchString)}
        placeholder="Start typing..."
      />
    );
  }

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        ATC Group
      </p>

      <div className="my-2.5">
        { content }
      </div>
    </div>
  );
}
