import React, { Fragment, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon, HomeIcon, UsersIcon, AcademicCapIcon, ScaleIcon, TagIcon,
} from '@heroicons/react/24/outline';

import {
  ChevronDownIcon,
  CogIcon,
  ArrowRightOnRectangleIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  ChartBarIcon,
} from '@heroicons/react/20/solid';

import { useSelector } from 'react-redux';
import { classNames } from '../../helpers/styling';
import ApplicationSearch from '../search-results/application-search/ApplicationSearch';

import MobileNavigation from './MobileNavigation';
import { AppDispatch, RootState } from '../../app/store';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ProfilePageType, swapProfiles } from '../../app/account';

import UserAvatar from '../../elements/UserAvatar';
import { emptySplitApi } from '../../app/api';
import { resetFeedList } from '../feed/feedSlice';
import { getCanAccessGlobalStatistics, getCanManageTags, getCanModerateFeed } from '../../app/permissionsSchema';
import Logo from '../../elements/Logo';
import AdministrationSection from './elements/profile/AdministrationSection';
import { NavElement } from './types';
import { resetCurrentChatWindow } from '../messages/messagesSlice';
import { resetForumFilters, resetForumSearch } from '../forum/questions-list/forumQuestionsSlice';
import ErrorModal from '../../elements/ErrorModal';
import SwitchProfileModal from './SwitchProfileModal';

const menuOptions = [
  {
    name: 'Feed',
    link: '/feed',
    icon: HomeIcon,
    onClick: (dispatch: AppDispatch) => { // reset on page render
      if (window.location.href.includes('feed')) return; // do not execute if already on a feed page

      dispatch(emptySplitApi.util.invalidateTags(['FeedPosts']));
      dispatch(resetFeedList());
    },
  },
  {
    name: 'Contacts',
    link: '/contacts',
    icon: UsersIcon,
  },
  {
    name: 'Forum',
    link: '/forum',
    icon: AcademicCapIcon,
    onClick: (dispatch: AppDispatch) => { // reset on page render
      if (window.location.href.includes('forum')) return; // do not execute if already on a forum page

      dispatch(resetForumFilters());
      dispatch(resetForumSearch());
    },
  },
];

// todo: refactor component
export default function Navbar() {

  function handleLogout(instance: any) {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  const dispatch = useAppDispatch();
  const history  = useHistory();

  const {
    swapProfile, currentProfile,
  } = useSelector((state: RootState) => state.account);
  const { currentProfile: { profilePageType } } = useSelector((state: RootState) => state.account);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { instance } = useMsal();

  const canAccessGlobalStats = useAppSelector(getCanAccessGlobalStatistics);
  const canModerateFeed = useAppSelector(getCanModerateFeed);
  const canManageTags = useAppSelector(getCanManageTags);

  const profileNav = (profileID: number) => {
    const navItems: NavElement[] = [
      {
        link: `/profile/${profileID}`,
        name: 'Your profile',
        icon: (className: string) => <UserIcon className={className} />,
      },
      {
        link: '/settings',
        name: 'Settings',
        icon: (className: string) => <CogIcon className={className} />,
      },
    ];

    navItems.push({
      link: '/contact-us',
      name: 'Contact us / Help',
      icon: (className: string) => <QuestionMarkCircleIcon className={className} />,
    });

    return navItems;
  };

  const administrationNavGroup: NavElement[] = [];
  if (profilePageType === ProfilePageType.corporation || canAccessGlobalStats) {
    administrationNavGroup.push({
      link: '/statistics',
      name: 'Statistics',
      icon: (className: string) => <ChartBarIcon className={className} />,
    });
  }
  if (canModerateFeed) {
    administrationNavGroup.push({
      link: '/moderate-feed',
      name: 'Feed Moderation',
      icon: (className: string) => <ScaleIcon className={className} />,
    });
  }
  if (canManageTags) {
    administrationNavGroup.push({
      link: '/tags-management',
      name: 'Tags Management',
      icon: (className: string) => <TagIcon className={className} />,
    });
  }

  const [showChangeProfileModal, setShowChangeProfileModal] = useState(false);

  const goToProfile = () => {
    if (swapProfile) {
      history.push(`/profile/${swapProfile.profileID}`);
    }
  };

  const switchProfile = () => {
    setShowChangeProfileModal(false);
    dispatch(async () => {
      await dispatch(swapProfiles());
      goToProfile();
      emptySplitApi.util.invalidateTags(['ChatThreadsList']);
      dispatch(resetFeedList());
      dispatch(resetCurrentChatWindow());
    });
  };

  return (
    <div className="bg-white shadow">
      <div className="mx-auto px-2 sm:px-4 xl:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 xl:px-0">
            <div className="shrink-0 flex items-center">
              <div className="block h-8" style={{ maxWidth: 95 }}>
                <Link
                  to={`/profile/${currentProfile.profileID}`}
                >
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="relative flex items-center xl:hidden">
              {/* Mobile menu button */}
              <button
                type="button"
                className="ml-4 inline-flex items-center justify-center p-2 rounded-md text-neutral-400 hover:text-neutral-500 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main-500"
                onClick={() => setMobileNavOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden xl:ml-6 xl:flex xl:space-x-8 items-center">
              {/* Current: "border-main-500 text-neutral-900",
                  Default: "border-transparent text-neutral-500
                  hover:border-neutral-300 hover:text-neutral-700" */
              }
              {
                menuOptions.map((option) => (
                  <NavLink
                    to={option.link}
                    key={option.name}
                    onClick={() => {
                      if (option.onClick) option.onClick(dispatch);
                    }}
                    activeClassName="border-main-500 text-neutral-900"
                    className={classNames('border-transparent text-neutral-500',
                      'hover:border-neutral-300 hover:text-neutral-700',
                      'inline-flex items-center px-4 pb-1 height-fit-content  border-b-2 text-xs font-bold')}
                  >
                    { option.name }
                  </NavLink>
                ))
              }
            </div>
          </div>

          <ApplicationSearch />

          <div className="ml-4 flex items-center">
            {/* <button */}
            {/*  type="button" */}
            {/*  className="shrink-0 bg-transparent mr-4 text-neutral-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none " */}
            {/* > */}
            {/*  <span className="sr-only">View notifications</span> */}
            {/*  <BellIcon className="h-6 w-6 text-main-500 bg-main-50" aria-hidden="true" /> */}
            {/* </button> */}

            {/* Profile dropdown */}
            <Menu as="div" className="ml-4 relative shrink-0">
              <div>
                <Menu.Button className="bg-white text-sm focus:outline-none">
                  {({ open }) => (
                    <div className={`${open && 'bg-main-25'} flex items-center p-1 px-4 rounded-md`}>
                      <span className="sr-only">Open user menu</span>
                      <div className="flex flex-col items-end">
                        <span className="font-bold text-base pr-4 truncate" style={{ maxWidth: 230 }}>
                          {currentProfile.name}
                        </span>
                        {/* <Link */}
                        {/*  className="text-xs pr-4 text-main-500 font-semibold" */}
                        {/*  to={`/profile/${currentProfile.profileID}`} */}
                        {/* > */}
                        {/*  Public page */}
                        {/* </Link> */}
                        <span className="text-xs pr-4 text-main-500 font-semibold">Public page</span>
                      </div>

                      <UserAvatar profilePageType={currentProfile.profilePageType} profileImageUrl={currentProfile.profileImageUrl} />

                      <ChevronDownIcon
                        className={`w-5 h-5 ml-2 -mr-1 text-main-700 hover:text-main-800 transition-transform transform ${open && '-rotate-180'}`}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div>
                  <Menu.Items style={{ zIndex: 100, minWidth: 320 }} className="overflow-hidden origin-top-right absolute z-index-50 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {
                      profileNav(currentProfile.profileID).map((item) => (
                        <Menu.Item key={item.link}>
                          {({ active }) => (
                            <Link
                              to={item.link}
                              className={classNames(active ? 'bg-main-50' : '', 'block px-4 py-3 text-sm text-neutral-700')}
                            >
                              <div className="flex flex-row items-center">
                                { item.icon(`h-4 w-4 mr-4 text-neutral-800 ${active && 'text-main-500'}`) }
                                <span className={`font-semibold text-sm ${active && 'text-main-600'}`}>
                                  { item.name }
                                </span>
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      ))
                    }

                    <AdministrationSection navGroup={administrationNavGroup} />

                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => handleLogout(instance)}
                          className={classNames(active ? 'bg-main-50' : '', 'w-full block px-4 py-3 text-sm text-neutral-700 cursor-pointer')}
                        >
                          <div className="flex flex-row items-center">
                            <ArrowRightOnRectangleIcon className={`h-4 w-4 mr-4 text-neutral-800 ${active && 'text-main-500'}`} />

                            <span className={`font-semibold text-sm ${active && 'text-main-600'}`}>
                              Log out
                            </span>
                          </div>
                        </button>
                      )}
                    </Menu.Item>

                    {
                      swapProfile && (
                        <div className="py-4 px-2 bg-neutral-75 font-semibold">
                          <p className="uppercase text-3xs  text-neutral-500 pb-4">
                            Change profile
                          </p>

                          <div className="flex flex-row">
                            <Menu.Item>
                              <button
                                type="button"
                                onClick={() => setShowChangeProfileModal(true)}
                                className="hover:bg-main-25 w-full bg-white cursor-pointer flex justify-end items-center p-1 px-4 rounded-md"
                              >
                                {/* <button */}
                                {/*  type="button" */}
                                {/*  className="shrink-0 bg-transparent ml-2 rounded-full ring-8 ring-offset-0 ring-main-500 focus:outline-none" */}
                                {/* > */}
                                {/*  <span className="sr-only">View notifications</span> */}
                                {/*  <BellIcon className="h-6 w-6 text-white bg-main-500" aria-hidden="true" /> */}
                                {/* </button> */}

                                <div className="flex items-center py-2 w-full">
                                  <div className="flex flex-col items-end w-full">
                                    <span
                                      style={{ maxWidth: 230 }}
                                      className="text-right font-bold text-base pr-4 line-clamp-2"
                                    >
                                      {swapProfile.name}
                                    </span>
                                    <span className="text-xs pr-4 text-main-500 font-semibold">Public page</span>
                                  </div>

                                  <UserAvatar profilePageType={swapProfile.profilePageType} profileImageUrl={swapProfile.profileImageUrl} />
                                </div>
                              </button>
                            </Menu.Item>
                          </div>
                        </div>
                      )
                    }
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <MobileNavigation
        setOpen={setMobileNavOpen}
        isOpen={mobileNavOpen}
        menuOptions={menuOptions}
      />

      <SwitchProfileModal
        show={showChangeProfileModal}
        // timeout for animation to pass
        setOpen={setShowChangeProfileModal}
        transparentBackground
        onActionClick={switchProfile}
        swapProfileName={swapProfile?.name}
        currentProfileName={currentProfile?.name}
      />
    </div>
  );
}
