import React from 'react';
import TerritoryFlag from './elements/TerritoryFlag';
import { BusinessMarketsModuleTerritory } from './business-markets-module-types';

interface TerritoryRowProps {
  country: BusinessMarketsModuleTerritory,
}
export default function TerritoryRow(props: TerritoryRowProps) {
  const { country } = props;
    
  return (
    <div key={country.territoryName} className="w-auto hover:shadow-sm transition-shadow bg-white pl-3 py-3 rounded-md">
      <div className="flex flex-row">
        <TerritoryFlag territoryName={country.territoryName} isoAlpha3Code={country.isoAlpha3Code} />
        <span className="pl-4 text-sm">{ country.territoryName }</span>
      </div>
    </div>
  );
}
