import React, { useState } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/20/solid';
import { useCupidoLogin } from '../../helpers/hooks';
import Logo from '../../elements/Logo';

interface RegisterPromoProps {
  closeModal: () => void
}

export default function RegisterPromo(props: RegisterPromoProps) {

  const { closeModal } = props;
  const { instance, handleLogin } = useCupidoLogin();

  const scrollToBottom = () => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="flex flex-col justify-center items-center z-50">
      {/* Logo */}
      <div className="h-20 mb-16">
        <Logo />
      </div>
      <div style={{ maxWidth: 1024 }} className="flex flex-col justify-center items-center">
        <h3 style={{ maxWidth: 900 }} className="font-extrabold text-4xl">
          Join and get full access to all the content, services, and features!
        </h3>

        <span className="pt-4 text-neutral-500 text-lg">
          You will experience the full potential for 3 months!
        </span>

        {/* Stats */}
        <div className="mt-11 w-full">
          <div className="relative px-8">
            <div className="relative max-w-4xl mx-auto">
              <div className="max-w-4xl mx-auto">
                <dl className="rounded-lg bg-white shadow-md sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 py-6 px-2 md:p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Companies</dt>
                    <dd className="order-1 text-5xl font-extrabold text-main-600">150</dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 py-6 px-2 md:p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Professionals </dt>
                    <dd className="order-1 text-5xl font-extrabold text-main-600">450</dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 py-6 px-2 md:p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Conversations daily</dt>
                    <dd className="order-1 text-5xl font-extrabold text-main-600">5600</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <p className="pt-2 text-main-600 pr-8 text-base w-full font-bold text-right">
          and growing daily!
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full p-4 px-12 pt-10 ">
          {
            [0, 1, 2, 3].map((item) => (
              <div key={item} className="flex flex-row items-start">
                <ShieldCheckIcon className="w-7 h-7 text-main-600" />
                <div className="flex flex-col items-start pl-2">
                  <span className="text-base font-extrabold text-left">
                    Security, privacy, and confidentiality first!
                  </span>
                  <span className="font-semibold text-sm text-neutral-400 text-left">
                    Complementary text description
                  </span>
                </div>
              </div>
            ))
          }
        </div>

        <div className="flex flex-row mt-12">
          {/* <Button */}
          {/*  onClick={() => { */}
          {/*    closeModal(); */}
          {/*  }} */}
          {/*  size="big" */}
          {/*  inverted */}
          {/*  description="Contact us" */}
          {/* /> */}
          <button
            type="button"
            onClick={() => {
              closeModal();
              scrollToBottom();
            }}
            className="p-4 border bg-white border-neutral-300 rounded-md text-base font-semibold text-main-600"
          >
            Contact us
          </button>
          <button
            type="button"
            onClick={() => {
              handleLogin(instance);
            }}
            className="ml-8 p-4 border bg-white border-main-600 rounded-md bg-main-600 text-base font-semibold text-white"
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
}
