import { clone } from 'lodash';
import { DateTime } from 'luxon';
import { emptySplitApi } from '../../app/api';
import { ISavedSearch, ISearchRequest, ISearchResults } from './search-types';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';

export const searchAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedSearches: builder.query<ISavedSearch[], null>({
      query: () => ({
        url: '/Search/saved',
      }),
      transformResponse: (response: ISavedSearch[]) => response.map((s) => {

        const search = clone(s);

        // todo: remove after backend will implement a timezone CWA-355
        search.searchCreationDateUtc = DateTime.fromISO(`${s.searchCreationDateUtc}+00:00`).toRelative() || '-';

        return search;
      }),
      providesTags: ['SavedSearches'],

    }),
    postSavedSearch: builder.mutation<any, string>({

      query(data) {

        return {
          url: '/search/saved',
          method: 'POST',
          body: {
            input: data,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Search was saved'));
        } catch {
          dispatch(addFlashMessage('error', 'Saving search was unsuccessful. Please try again.'));
        }
      },

      invalidatesTags: ['SavedSearches'],
    }),
    deleteSavedSearch: builder.mutation<null, { id: number }>({

      query(data) {

        const { id } = data;

        return {
          url: `/search/saved/${id}`,
          method: 'DELETE',
          body: {},
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Search was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Deleting search was unsuccessful. Please try again.'));
        }
      },

      invalidatesTags: ['SavedSearches'],
    }),
    getSearchResults: builder.query<ISearchResults, ISearchRequest>({
      // todo: pagination https://redux-toolkit.js.org/rtk-query/usage/pagination
      query: (body) => ({
        url: '/Search/all',
        method: 'POST',
        body: {
          paginationFilter: {
            pageNumber: body.PageNumber,
            pageSize: body.PageSize,
          },
          selectedFilters: body.appliedFilters,
          profilePageTypeResults: body.profilePageTypeResults,
          searchIdentifier: body.searchIdentifier,
          searchInput: body.searchInput,
        },
      }),
      keepUnusedDataFor: 150, // 2.5 min
      providesTags: (result) => (result ? [{ type: 'SearchResults', id: result.id }] : []),
    }),
  }),
});

export const {
  useGetSavedSearchesQuery,
  useGetSearchResultsQuery,
  usePostSavedSearchMutation,
  useDeleteSavedSearchMutation,
} = searchAPI;
