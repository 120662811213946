import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { MAX_FILE_SIZE   } from '../helpers/config';
import { addFlashMessage } from '../features/flash-messages/flashMessagesSlice';
import { API_URL } from '../authConfig';
import { AboutModuleFile } from '../features/profile-page/modules/about-module/aboutModuleTypes';

export enum UploadBlob {
  profilePageAvatar = 'profilePageAvatar',
  profilePageCover = 'profilePageCover',
  moduleTeamMemberAvatar = 'moduleTeamMemberAvatar',
  moduleAbout = 'ModuleAbout',
  activityFeed = 'activityFeed',
  moduleService = 'moduleService',
  moduleFocus = 'ModuleFocusCategory',
}
interface UploadFileParams {
  blobContainer: UploadBlob
  file?: File;
  description?: string,
  blobFile?: AboutModuleFile, // string for a blob url obj
  profilePageID: number,
  requesterID: number,
  // imageType: 'cover' | 'profile'
}

export interface UploadFileResponse {
  contentType: string, // todo: add values
  description?: string,
  fileSizeBytes: number,
  fileUrl: string,
  id: string,
  isPrivate: boolean,
  metadata: any,
  originalFilename: string,
}

// todo: move to API ?
export const uploadFileToAzure = createAsyncThunk(
  'files/uploadFile',
  async ({
    file, blobFile, description, blobContainer, profilePageID, requesterID,
  }: UploadFileParams, { dispatch }) => {

    try {

      let localFile = file;
      if (blobFile && blobFile.fileUrl) { // todo: fix, doesn't work now
        localFile = new File([blobFile.fileUrl], blobFile.originalFilename); // possible memory leak ? better to store original file somehow
      }

      if (! localFile) return;

      if (localFile.size > MAX_FILE_SIZE) {
        dispatch<any>(addFlashMessage('error', 'Maximum allowed file size is 5Mb'));
        return;
      }

      const formData = new FormData();
      formData.append('AttachmentFile', localFile);
      formData.append('blobContainer', blobContainer);

      if (description) {
        formData.append('Description', description);
      }

      const postImageURL = `${API_URL}/AzureStorage/profile-page/${profilePageID}/requester-profile-page/${requesterID}/attachment/upload`;
      const response: { data: UploadFileResponse } = await axios.post(postImageURL, formData);

      // dispatch<any>(addFlashMessage('success', 'Profile image was uploaded'));

      // eslint-disable-next-line consistent-return
      return response;

    } catch (e) {
      dispatch<any>(addFlashMessage('error', 'File upload has failed'));
      throw e;
    }
  },
);
