import React, { useEffect, useState } from 'react';

import Input from '../../../elements/Input';
import Button from '../../../elements/Button';
import {
  useUpdateCorporateDetailsMutation,
} from '../settingsAPI';
import { useAppDispatch } from '../../../app/hooks';
import { updateCorpName } from '../../../app/account';
import {
  RulesForFieldsType, RuleTypes, useFormValidation, ValidationError,
} from '../../../helpers/validation';
import { useBrandingLocale } from '../../../helpers/hooks';

type ValidationKeys = 'corporationName';

const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  corporationName: {
    userFriendlyFieldName: 'Corporation Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 1,
    maxLength: 100,
  },
};

const validationErrorState: ValidationError<ValidationKeys> = {
  corporationName: { errorMessage: '' },
};

interface CorporateDetailsProps {
  corporationID: number,
  corporationNameParam: string,
}

export default function CorporateDetails(props: CorporateDetailsProps) {

  const {
    corporationNameParam, corporationID,
  } = props;

  const [corporationName, setCorporationName] = useState('');

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      corporationName,
    },
  );

  const { t, tk } = useBrandingLocale();

  useEffect(() => {
    if (! corporationNameParam) return;

    // setCorporationName(corporationDetails.name);
    setCorporationName(corporationNameParam);

  }, [corporationNameParam]);

  const [updateDetailsQuery] = useUpdateCorporateDetailsMutation();

  const dispatch = useAppDispatch();

  const onUpdate = async () => {

    const val = validate();

    if (! val.allValid) { return; }

    if (! val.allValid) {
      return;
    }

    if (! corporationID) return;

    await updateDetailsQuery({ corporationID, name: corporationName });
    dispatch(updateCorpName(corporationName));
  };

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        {`${t('Corporation')} Details`}
      </h2>

      <div>
        <Input
          value={corporationName}
          onChange={(e) => {
            setCorporationName(e.target.value);
            onValidationInputChange('corporationName');
          }}
          validationMessage={validationState.corporationName.errorMessage}
          id="settings-corp-name"
          label={`${t('Corporation')} Name`}
          placeholder="Name"
        />
      </div>

      <div className="flex justify-end mt-4">
        <Button
          size="big"
          onClick={onUpdate}
          description="Update"
        />
      </div>

    </div>
  );
}
