import { createSlice } from '@reduxjs/toolkit';
import { CreatePostState } from '../post/create-post/createPostSlice';

interface Post extends CreatePostState {

}

export interface FeedState {
  feedListItems: Array<any>; // todo: map typings
  onlyMyPosts: boolean,

  // todo: reset pagination on filter change
  pagination: {
    pageNumber: number,
    pageSize: number,
    hasMore: boolean,
  }
}

const initialState: FeedState = {
  feedListItems: [],
  onlyMyPosts: false,

  pagination: {
    pageNumber: 1,
    pageSize: 10,
    hasMore: false,
  },

};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {

    resetFeedList: (state) => {
      state.feedListItems = initialState.feedListItems;
      // state.onlyMyPosts = initialState.onlyMyPosts; todo: check if should be reset
      state.pagination = initialState.pagination;
    },

    setFeedListPage: (state, action) => {
      state.pagination = {
        ...state.pagination,
        pageNumber: action.payload,
      };
    },

    setHasMoreItems: (state, action) => {
      state.pagination = {
        ...state.pagination,
        hasMore: action.payload,
      };
    },

    addFeedListItems: (state, { payload }) => {
      if (payload.pageNumber === 1) {
        state.feedListItems = payload.data;
        return;
      }

      state.feedListItems = [...state.feedListItems, ...payload.data];
    },

    setOnlyMyPosts: (state, action) => {
      state.onlyMyPosts = action.payload;
      state.pagination = {
        ...state.pagination,
        pageNumber: 1,
      };

      state.feedListItems = [];
    },
  },
  extraReducers: () => {

  },
});

export const {
  resetFeedList,
  addFeedListItems,
  setHasMoreItems,
  setFeedListPage,
  setOnlyMyPosts,
} = feedSlice.actions;

export default feedSlice.reducer;
