import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { EMPTY_LINK } from '../../about-module/aboutModuleSlice';
import Input from '../../../../../elements/Input';
import Button from '../../../../../elements/Button';
import { AboutModuleExternalLink } from '../../about-module/aboutModuleTypes';

interface ServiceLinkFieldProps {
  value: AboutModuleExternalLink[] | null,
  onChange: (link: AboutModuleExternalLink) => void,
}

export default function ServiceLinkField(props: ServiceLinkFieldProps) {

  const { value, onChange } = props;

  const link: AboutModuleExternalLink = value && value.length > 0 ? value[0] : {
    ...EMPTY_LINK,
    id: null,
  };

  const [deleteModeLink, setDeleteModeLink] = useState(false);

  return (
    <div className="p-2 mt-2 bg-neutral-25 border rounded-md">
      <p className="p-2 text-xs text-neutral-700">Add a link</p>

      <div className="mt-2">
        <Input
          value={link.description}
          id="df-key-1"
          label="Title of the link"
          placeholder="Title"
          onChange={(e) => onChange({ ...link, description: e.target.value })}
        />
      </div>
      <div className="mt-2">
        <Input
          value={link.link}
          id="df-key-2"
          label="URL"
          placeholder="https://..."
          onChange={(e) => onChange({ ...link, link: e.target.value })}
        />
      </div>

      <div className="flex flex-row mt-2">
        <div className="max-w-max mr-2">
          <Button
            onClick={() => setDeleteModeLink(true)}
            size="small"
            description=""
            roundedFull
            inverted={! deleteModeLink}
            fullRed
            iconLeft={<TrashIcon className="w-4 h-4" />}
          />
        </div>

        {
          deleteModeLink && (
            <>
              <Button
                onClick={() => setDeleteModeLink(false)}
                size="small"
                description="Cancel"
                roundedFull
                fullRed
              />
              <div className="ml-2">
                <Button
                  onClick={() => {
                    setDeleteModeLink(false);
                    onChange(EMPTY_LINK);
                  }}
                  size="small"
                  description="Delete"
                  redText
                  roundedFull
                  inverted
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
}
