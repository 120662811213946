import React, { useState } from 'react';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import Button from '../../../elements/Button';
import Input from '../../../elements/Input';
import { useInviteMemberMutation } from '../settingsAPI';
import { useFormValidation } from '../../../helpers/validation';
import { rulesAddCorporationMember, validationErrorState, ValidationKeys } from './validationRules';

interface AddMemberProps {
  corporationID: number,
}

export default function AddMember(props: AddMemberProps) {

  const [addMemberMode, setAddMemberMode] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { corporationID } = props;

  const [inviteMemberQuery] = useInviteMemberMutation();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesAddCorporationMember,
    validationErrorState,
    {
      name,
      surname,
      email,
    },
  );

  const onCancel = () => {
    setAddMemberMode(false);
    setEmail('');
    setName('');
    setSurname('');
  };
  
  const onAddMember = async () => {

    const val = validate();

    if (! val.allValid) return;

    setIsLoading(true);

    const result = await inviteMemberQuery({
      corporationID,
      name,
      email,
      surname,
    });

    setIsLoading(false);

    if (! Object.prototype.hasOwnProperty.call(result, 'error')) {
      onCancel();
    }
  };

  return (
    <>
      {
        (! addMemberMode) && (
          <div className="flex flex-row justify-end mt-4">
            {/* DO NOT DELETE THIS CODE */}
            {/* <div className="text-right mr-6 flex flex-col justify-center"> */}
            {/*  <p className="text-sm font-semibold"> */}
            {/*    You can add up to 3 more members */}
            {/*  </p> */}
            {/*  <p className="text-xs text-neutral-500"> */}
            {/*    Would you like to add more members? &nbsp; */}
            {/*    <span className="text-main-600"> */}
            {/*      Upgrade your account */}
            {/*    </span> */}
            {/*  </p> */}
            {/* </div> */}
            <Button
              onClick={() => setAddMemberMode(true)}
              size="medium"
              iconLeft={<PlusSmallIcon className="w-4 h-4 mr-2" />}
              // disabled
              description="Add a member"
            />
          </div>
        )
      }
      {
        addMemberMode && (
          <div className="border rounded-md p-6 mt-4">
            <h4 className="py-3 text-xl font-bold">Add a member</h4>
            <div className="mt-4">
              <Input
                value={name}
                id="members-name"
                label="Name"
                placeholder="Name"
                onChange={(e) => {
                  setName(e.target.value);
                  onValidationInputChange('name');
                }}
                validationMessage={validationState.name.errorMessage}
              />
            </div>
            <div className="mt-4">
              <Input
                value={surname}
                id="members-surname"
                label="Surname"
                placeholder="Surname"
                onChange={(e) => {
                  setSurname(e.target.value);
                  onValidationInputChange('surname');
                }}
                validationMessage={validationState.surname.errorMessage}
              />
            </div>
            <div className="mt-4">
              <Input
                value={email}
                id="members-email"
                label="Email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  onValidationInputChange('email');
                }}
                validationMessage={validationState.email.errorMessage}
              />
            </div>
            <div className="flex flex-row justify-end mt-4">
              <div className="mr-4">
                <Button
                  onClick={onCancel}
                  disabled={isLoading}
                  size="medium"
                  inverted
                  description="Cancel"
                />
              </div>
              <Button
                onClick={onAddMember}
                isLoading={isLoading}
                size="big"
                description="Add member"
              />
            </div>
          </div>
        )
      }
    </>
  );
}
