import React, { useState } from 'react';
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Button from '../../../elements/Button';
import { classNames } from '../../../helpers/styling';
import { useDeleteCorporationMemberInvitationMutation, useResendCorporationMemberInvitationMutation } from '../settingsAPI';

interface MemberInvitationProps {
  firstname: string,
  lastname: string,
  email: string,
  invitationID: number,
  corporationID: number,
}

export default function MemberInvitation(props: MemberInvitationProps) {

  const {
    firstname, lastname, email, corporationID, invitationID,
  } = props;

  const [isDeleteState, setIsDeleteState] = useState(false);

  const [deleteInvitationQuery, { isLoading: deletionInProgress }] = useDeleteCorporationMemberInvitationMutation();
  const [resendInvitationQuery, { isLoading: resendInProgress }] = useResendCorporationMemberInvitationMutation();
  
  const onClickDeleteInvitation = async () => {
    setIsDeleteState(true);
  };

  const onClickResendInvitation = async () => {
    await resendInvitationQuery({ corporationID, invitationID });
  };

  return (
    <div className={classNames(
      `${isDeleteState ? 'bg-error-600' : ''}`,
      'p-4 flex flex-row items-center border mb-2 rounded-md justify-between',
    )}
    >
      <div className="flex flex-col">
        <span className={`font-semibold text-xl" ${isDeleteState && 'text-white'}`}>
          {`${firstname} ${lastname}`}
        </span>
        <p className={`font-semibold text-sm" ${isDeleteState && 'text-white'}`}>
          {email}
        </p>
      </div>
      {
        isDeleteState && (
          <div className="bg-white p-4 rounded-md shadow-md">
            <div className="py-2 flex flex-row items-center">
              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-error-25">
                {
                  deletionInProgress
                    ? <ArrowPathIcon className="h-4 w-4 text-error-600 animate-spin" />
                    : <ExclamationTriangleIcon className="h-4 w-4 text-error-600" aria-hidden="true" />
                }
              </div>
              <span className="pl-4 font-bold">Cancel Invitation?</span>
            </div>
            <p style={{ maxWidth: 400 }} className="font-semibold text-sm">
              Once canceled, the invitation will no longer be valid.
            </p>
            <div className="flex flex-row justify-end mt-2">
              <Button
                onClick={() => setIsDeleteState(false)}
                roundedFull
                size="small"
                inverted
                description="Cancel"
                disabled={deletionInProgress}
              />
              <div className="pl-2">
                <Button
                  onClick={async () => {
                    if (! corporationID) return;

                    await deleteInvitationQuery({ corporationID, invitationID });
                    setIsDeleteState(false);
                  }}
                  size="small"
                  roundedFull
                  fullRed
                  inverted
                  isLoading={deletionInProgress}
                  description="Yes, Cancel Invitation"
                />
              </div>
            </div>
          </div>
        )
      }
      {
        !isDeleteState && (
          <div className="pt-4 flex flex-row space-x-4">
            <Button
              onClick={() => onClickResendInvitation()}
              size="small"
              inverted
              roundedFull
              description="Resend Email"
              isLoading={resendInProgress}
              disabled={resendInProgress || deletionInProgress}
            />
            <Button
              onClick={() => onClickDeleteInvitation()}
              size="small"
              redText
              inverted
              roundedFull
              description="Cancel Invitation"
              disabled={resendInProgress || deletionInProgress}
            />
          </div>
        )
      }
    </div>
  );
}
