import React, { useState } from 'react';
import { createFilter, MultiValue } from 'react-select';
import { CoreInnProduct } from '../../products-module.types';
import { useGetActiveIngredientsQuery } from '../../productsModuleAPI';
import InputLoader from '../../../../../../elements/InputLoader';
import AppSelect from '../../../../../../elements/AppSelect';
import { searchActiveIngredients } from './helpers';
import { useAppDispatch } from '../../../../../../app/hooks';
import { updateCreateProductActiveIngredients } from '../../productsModuleSlice';

interface ActiveIngredientsProps {
  productID: number,
  activeIngredients?: CoreInnProduct[],
}

// coreInnProducts
export default function ActiveIngredients(props: ActiveIngredientsProps) {

  const { productID, activeIngredients } = props;

  const { data, isLoading, isFetching } = useGetActiveIngredientsQuery(null);
  const [searchString, setSearchString] = useState('');

  const dispatch = useAppDispatch();

  let content = null;
  if (! data || isLoading || isFetching) {
    content = (
      <InputLoader />
    );
  }

  const setValues = (): CoreInnProduct[] => {
    if (! activeIngredients) return [];

    return activeIngredients.map((i: CoreInnProduct) => ({
      ...i,
      value: i.coreInnProduct.id,
      label: i.coreInnProduct.name,
    }));
  };

  const onChange = (newValue: MultiValue<CoreInnProduct>) => {
    dispatch(updateCreateProductActiveIngredients({
      productID,
      value: newValue,
    }));
  };

  if (data && data.length > 0) {
    content = (
      <AppSelect
        isMulti
        onInputChange={(input) => setSearchString(input)}
        // defaultValue={defaultValues}
        value={setValues()}
        onChange={onChange}
        filterOption={createFilter({ ignoreAccents: false })} // optimization
        options={searchActiveIngredients(data, activeIngredients, searchString)}
        placeholder="Start typing..."
      />
    );
  }

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        Active ingredients
      </p>

      <div className="my-2.5">
        { content }
      </div>
    </div>
  );
}
