import React, { useEffect, useRef, useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import { useDocumentTitle } from '../../helpers/hooks';
import { getCanManageTags } from '../../app/permissionsSchema';
import { useAppSelector } from '../../app/hooks';
import { useGetTagsQuery } from './tagAPI';
import TagEditor from './TagEditor';
import { CoreTag } from './tag-types';

export default function TagsManagementPage() {

  useDocumentTitle('Tags Management');

  const canManageTags = useAppSelector(getCanManageTags);

  const { data: tags, isFetching, isLoading } = useGetTagsQuery(null);

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white shadow-sm rounded-md">
          <h2 className="text-3xl font-bold p-6 text-neutral-800">
            Tags Management
          </h2>
        </div>
        <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
          {
            canManageTags && (
              isLoading ? (
                <div className="mt-5 flex w-full justify-center items-center animate-pulse">
                  <ArrowPathIcon className="h-4 w-4 text-neutral-600 mr-2.5 animate-spin" />
                  <span className="font-semibold text-neutral-600">Loading...</span>
                </div>
              ) : (
                <>
                  <h2 className="text-xl font-semibold">Add new Tag</h2>
                  <TagEditor
                    tagInitialValue=""
                  />
                  {
                    tags && tags.length > 0 && (
                      <div className="mt-6">
                        <h2 className="text-xl font-semibold">Edit existing Tags</h2>
                        {tags.map((tag, index) => (
                          <TagEditor
                            key={tag.id}
                            tagId={tag.id}
                            tagInitialValue={tag.name}
                          />
                        ))}
                      </div>
                    )
                  }
                </>
              )
            )
          }
        </div>
      </div>
    </DashboardLayout>
  );
}
