import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetFeedModerationListRequest } from './types';

interface FeedModerationSlice {
  getListRequest: GetFeedModerationListRequest,
}

export const initialState: FeedModerationSlice = {
  getListRequest: {
    pageNumber: 1,
    pageSize: 10,
    creatorProfilePageId: null,
    startDateUtc: null,
    endDateUtc: null,
  },
};

export const feedModerationSlice = createSlice({
  name: 'feedModeration',
  initialState,
  reducers: {
    resetFeedModerationFilters: (state) => {
      state.getListRequest = initialState.getListRequest;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.getListRequest.pageNumber = action.payload;
    },

    setCreatorProfilePageID: (state, action: PayloadAction<string>) => {
      let value: number | null = parseInt(action.payload, 10);
      if (Number.isNaN(value)) value = null;

      state.getListRequest.creatorProfilePageId = value;
    },

    setStartDate: (state, action: PayloadAction<string>) => {
      state.getListRequest.startDateUtc = action.payload;
    },

    setEndDate: (state, action: PayloadAction<string>) => {
      state.getListRequest.endDateUtc = action.payload;
    },
  },
});

export const {
  resetFeedModerationFilters,
  setPage,
  setCreatorProfilePageID,
  setStartDate,
  setEndDate,
} = feedModerationSlice.actions;

export default feedModerationSlice;
