import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetMembersRequest } from '../setings-types';

interface MembersManagementSlice {
  getMembersListRequest: GetMembersRequest,
}

export const initialState: MembersManagementSlice = {
  getMembersListRequest: {
    pageNumber: 1,
    pageSize: 5,
    searchInput: '',
    corporationID: 0,
  },
};

export const membersManagementSlice = createSlice({
  name: 'corporationMembers',
  initialState,
  reducers: {
    resetMembersSearch: (state) => {
      state.getMembersListRequest = initialState.getMembersListRequest;
    },

    setSearchString: (state, action: PayloadAction<string>) => {
      state.getMembersListRequest.searchInput = action.payload;
      state.getMembersListRequest.pageNumber = 1; // reset page for every new search
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.getMembersListRequest.pageNumber = action.payload;
    },
  },
});

export const {
  setSearchString,
  resetMembersSearch,
  setPage,
} = membersManagementSlice.actions;

export default membersManagementSlice;
