import React, { useEffect, useMemo, useState } from 'react';
import { ChatClient } from '@azure/communication-chat';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { SenderProfilePageSummary } from '../contact-list/contact-list.types';
import UserAvatar from '../../elements/UserAvatar';
import { setCurrentChatWindow, setLastMessage, setThreadUnreadCounter } from './messagesSlice';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { useSubscribeToChatMessageReceived } from './hooks';
import { CommunicationThreadMembersStatus } from './messages-types';

interface ChatPreviewRowProps {
  chatClient: ChatClient,
  threadID: string,
  currentProfileAzureCommunicationID: string,
  receiverProfileAzureCommunicationID: string,
  profilePageSummary: SenderProfilePageSummary,
  lastMessageDateUtc?: string, // received from backend, used for initial render
  threadMemberStatus: CommunicationThreadMembersStatus,
}

export default function ChatPreviewRow(props: ChatPreviewRowProps) {

  const {
    profilePageSummary, chatClient, threadID,
    currentProfileAzureCommunicationID, receiverProfileAzureCommunicationID,
    lastMessageDateUtc, threadMemberStatus,
  } = props;

  const dispatch = useAppDispatch();

  // const [lastMessageIsLoading, setLastMessageIsLoading] = useState(false);
  const [lastMessageIsLoaded, setLastMessageIsLoaded] = useState(false);

  const {
    threads, disclosureIsOpen,
    chatMessageReceivedObservable,
  } = useSelector((state: RootState) => state.chatMessages);

  useSubscribeToChatMessageReceived((e) => {
    if (! e) return;

    // @ts-ignore
    if (e.threadId !== threadID) return;

    dispatch(setLastMessage({
      threadID,
      message: e,
    }));

  }, chatMessageReceivedObservable);

  const lastMessage = threads[threadID] ? threads[threadID].lastMessage : null;
  const threadUnreadCount = threads[threadID] ? threads[threadID].unreadCount : 0;

  const chatThreadClient = useMemo(() => {
    if (! threadID) return null;

    return chatClient.getChatThreadClient(threadID);
  }, [threadID, chatClient]);

  // find last message for the first time
  useEffect(() => {
    (async () => {
      if (! threads[threadID] || ! chatThreadClient) return;

      if (lastMessage) return;

      // will be called 2 times: one for messagesWidget and second one for MessagesWindow
      if (lastMessageIsLoaded) return; // fix to reduce unnecessary requests
      setLastMessageIsLoaded(true);

      const lastMessageIterator = chatThreadClient.listMessages({ maxPageSize: 1 });
      
      try {
        const lastMessageGet = await lastMessageIterator.next();

        if (lastMessageGet.value) {
          // console.log('set last message called', lastMessageGet.value);
          dispatch(setLastMessage({
            threadID,
            message: {
              ...lastMessageGet.value,
              createdOn: DateTime.fromJSDate(lastMessageGet.value.createdOn).toISO(),
            },
          }));
        }

        // todo: extract
        // set read receipt counter
        const listReadReceipts = chatThreadClient.listReadReceipts({ maxPageSize: 50 });

        // find first read receipt of current user
        let lastReadMessageID = null;
        // eslint-disable-next-line no-restricted-syntax
        for await (const listReadReceipt of listReadReceipts) {

          // @ts-ignore - Azure types bug
          if (listReadReceipt.sender.communicationUserId === currentProfileAzureCommunicationID) {
            lastReadMessageID = listReadReceipt.chatMessageId;
            break;
          }
        }

        let lastReadMessage = null;
        if (lastReadMessageID) {
          lastReadMessage = await chatThreadClient.getMessage(lastReadMessageID);
        }

        // calculate unread count
        let unreadCount = 0;
        if (lastReadMessage) {
          unreadCount = parseInt(lastMessageGet.value.sequenceId, 10) - parseInt(lastReadMessage.sequenceId, 10);
        } else {
          unreadCount = parseInt(lastMessageGet.value.sequenceId, 10);
        }

        dispatch(setThreadUnreadCounter({ threadID, unreadCount }));
      } catch (e) {
        // todo: handle error!
        // console.info('Loading last message error', e);
      }

    })();
  }, [
    threads, chatThreadClient, threadID, dispatch, lastMessage,
    currentProfileAzureCommunicationID, lastMessageIsLoaded,
  ]);

  // todo: consider extract
  let lastMessageDateStr = '-';
  if (lastMessageDateUtc) lastMessageDateStr = DateTime.fromISO(lastMessageDateUtc).toRelative() || lastMessageDateStr;
  if (lastMessage) lastMessageDateStr = DateTime.fromISO(lastMessage.createdOn).toRelative() || lastMessageDateStr;

  return (
    <div
      role="none"
      onClick={() => { // make same changes in ContactListChatButton
        dispatch(setCurrentChatWindow({
          threadId: threadID,
          profilePageSummary,
          currentProfileAzureCommunicationID,
          userIsTyping: {
            flag: false,
            nanoid: '',
          },
          threadMemberStatus,
        }));

        dispatch(setThreadUnreadCounter({ threadID, unreadCount: 0 })); // reset as user opened chat

        if (! disclosureIsOpen) { // trigger opening disclosure
          // @ts-ignore
          document.querySelector('.id-chat-messages-disclosure')?.click();
        }
      }}
      className={`flex flex-row py-2.5 px-4 ${threadUnreadCount > 0 && 'bg-main-50'} hover:bg-main-75 cursor-pointer transition-colors`}
    >
      <div className="relative w-10 h-10">
        <UserAvatar
          profilePageType={profilePageSummary.profilePageAccountType}
          profileImageUrl={profilePageSummary.profileImageUrl}
        />
        {
          threadUnreadCount > 0 && (
            <div className="absolute flex flex-row items-center justify-center bg-error-500 rounded-full w-5 h-5 bottom-0 right-0">
              <span className="font-bold text-2xs text-white">
                {threadUnreadCount > 99 ? 99 : threadUnreadCount}
              </span>
            </div>
          )
        }
      </div>
      <div className="ml-2">
        <p className="font-semibold text-2xs text-neutral-600">
          {lastMessageDateStr}
        </p>
        <p className="font-bold text-main-600 text-2xs">
          {profilePageSummary.name}
        </p>
        <p className="mt-1 text-xs line-clamp-2 max-w-xs">
          { lastMessage && lastMessage.content && lastMessage.content.message ? lastMessage.content.message : <span>&nbsp;</span> }
        </p>
      </div>
    </div>
  );
}
