import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import ModuleLoader from '../../components/ModuleLoader';
import { useGetFocusModuleQuery } from './focusAPI';
import FocusView from './components/FocusView';

interface FocusModuleViewProps {
  // eslint-disable-next-line react/no-unused-prop-types
  setEditMode?: () => void,
  inEditMode?: boolean,
}

export default function FocusModuleView(props: FocusModuleViewProps) {

  const { inEditMode = false } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const { data: focusResponse, isLoading, isFetching } = useGetFocusModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (!currentlyLoadedProfileID) || (!requesterID) },
  );

  if (isLoading || isFetching || (! focusResponse)) {
    return (
      <ModuleLoader />
    );
  }

  return (
    <>
      {
         focusResponse.coreFocuses.map((focus) => (
           <FocusView key={focus.id} inEditMode={inEditMode} focus={focus} />
         ))
       }

      <div className="w-full pt-4 border-t" />
    </>
  );
}
