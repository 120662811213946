import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';
import { useDocumentTitle } from '../../helpers/hooks';
import Button from '../../elements/Button';
import FlashMessages from '../flash-messages/FlashMessages';
import { useGetNewsletterStatusQuery, useUpdateNewsletterStatusMutation } from './newsletterAPI';
import Logo from '../../elements/Logo';
import { EMAIL_SUPPORT } from '../../helpers/branding';
import Switch from '../../elements/Switch';

export default function Unsubsribe() {

  useDocumentTitle('Newsletter');

  const [successState, setSuccessState] = useState(false);

  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetNewsletterStatusQuery({ id });
  const [contactSwitch, setContactSwitch] = useState<boolean>(false);
  const [unreadSwitch, setUnreadSwitch] = useState<boolean>(false);
  const [newsletterSwitch, setNewsletterSwitch] = useState<boolean>(false);
  const [forumPostReplySwitch, setForumPostReplySwitch] = useState<boolean>(false);
  const [forumNewsSwitch, setForumNewsSwitch] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setContactSwitch(data.contactAndMessagingRequestsSubscribed);
      setUnreadSwitch(data.unreadMessagingRequestsSubscribed);
      setNewsletterSwitch(data.newsletterSubscribed);
      setForumPostReplySwitch(data.forumPostReplySubscribed);
      setForumNewsSwitch(data.forumNewsSubscribed);
    }
  }, [data]);

  const handleContactSwitchChange = (checked: boolean) => {
    setContactSwitch((prevState) => !prevState);
  };

  const handleUnreadSwitchChange = (checked: boolean) => {
    setUnreadSwitch((prevState) => !prevState);
  };

  const handleNewsletterSwitchChange = (checked: boolean) => {
    setNewsletterSwitch((prevState) => !prevState);
  };

  const handleForumPostReplySwitchChange = (checked: boolean) => {
    setForumPostReplySwitch((prevState) => !prevState);
  };

  const handleForumNewsSwitchChange = (checked: boolean) => {
    setForumNewsSwitch((prevState) => !prevState);
  };

  const [unsubscribeFromNewsletterQuery, { isLoading: unsubscribeInProgress }] = useUpdateNewsletterStatusMutation();

  const mailtoLink = `mailto:${EMAIL_SUPPORT}`;

  const onAcceptInvitation = async () => {
    await unsubscribeFromNewsletterQuery({
      id,
      newsletterSubscribed: newsletterSwitch,
      contactAndMessagingRequestsSubscribed: contactSwitch,
      unreadMessagesSubscribed: unreadSwitch,
      forumNewsSubscribed: forumNewsSwitch,
      forumPostReplySubscribed: forumPostReplySwitch,
    });
    setSuccessState(true);
  };

  let content = null;

  if (isLoading) {
    // TODO: Use a better loader animation
    content = (
      <div className="w-full h-24 mx-auto mt-8">
        <div className="flex animate-pulse flex-row h-full space-x-5">
          <div className="w-full flex flex-col space-y-3">
            <div className="w-72 bg-neutral-500 h-4 rounded-md" />
            <div className="bg-neutral-300 h-4 rounded-md" />
            <div className="bg-neutral-300 h-4 rounded-md" />
            <div className="w-1/2 bg-neutral-300 h-4 rounded-md" />
          </div>
        </div>
      </div>
    );
  }

  if (!isLoading && !data) {
    content = (
      <div>
        <h2 className="p-4 font-bold text-xl">
          Something went wrong. If you are still receiving emails please contact us at
          {' '}
          <a href={mailtoLink} className="pr-2.5 lg:pr-12 font-semibold text-main-400 underline">
            { EMAIL_SUPPORT }
          </a>
        </h2>
      </div>
    );
  }

  if (!isLoading && !successState && data) {
    content = (
      <div>
        <h3 className="p-4 font-bold text-xl">
          Manage your Email Notification preferences
        </h3>
        <div className="flex flex-col gap-6 mt-6">
          <div className="p-4 flex flex-col gap-4 w-full items-start">
            <div>
              <Switch
                onChange={handleNewsletterSwitchChange}
                checked={newsletterSwitch}
                label="Newsletter"
              />
            </div>
            <div>
              <Switch
                onChange={handleContactSwitchChange}
                checked={contactSwitch}
                label="Contact and Messaging requests"
              />
            </div>
            <div>
              <Switch
                checked={unreadSwitch}
                onChange={handleUnreadSwitchChange}
                label="Unread Messages"
              />
            </div>
            <div>
              <Switch
                checked={forumNewsSwitch}
                onChange={handleForumNewsSwitchChange}
                label="Trending Forum posts updates"
              />
            </div>
            <div>
              <Switch
                checked={forumPostReplySwitch}
                onChange={handleForumPostReplySwitchChange}
                label="Replies to my Forum posts"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              size="medium"
              onClick={onAcceptInvitation}
              description="Save Changes"
              isLoading={unsubscribeInProgress}
            />
          </div>
        </div>
      </div>
    );
  }

  if (successState) {
    content = (
      <div>
        <h3 className="p-4 font-bold text-xl">
          Your email notification preferences have been successfully updated
        </h3>
        <div className="pt-6 flex justify-center w-full">
          <Link
            to="/"
            className="inline-flex items-center text-base font-semibold p-4 rounded-md border border-main-600 bg-main-600 text-white"
          >
            <HomeIcon className="w-4 h-4 mr-1" />
            {' '}
            Go to home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="inline-block align-bottom overflow-hidden transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">

      <div className="mt-12">
        <div className="flex flex-col justify-center items-center">
          <div className="h-20 mb-16">
            <Logo />
          </div>

          <div className="bg-white shadow-sm p-6 rounded-md">
            <div style={{ maxWidth: 496 }}>
              {content}
            </div>
          </div>
        </div>
      </div>

      <FlashMessages />
    </div>
  );
}
