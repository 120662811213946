import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import AppSelect from '../../elements/AppSelect';
import { PeriodSortFilters } from './statistics-types';
import { useGetAllCorporationsQuery } from './statisticsAPI';

interface CorporationSelectionProps {
  setSorting: (newValue: number) => void,
  sorting: number,
  selectionChangedCallback: (selectedCorporation: number) => void,
}

export default function CorporationSelection(props: CorporationSelectionProps) {
  
  let options = [{ value: 0, label: 'All Users' }];
  const { data: allCorps, isLoading, isError } = useGetAllCorporationsQuery();
  
  if (!isLoading && !isError && allCorps) {
    options = options.concat(
      allCorps.map((corp) => ({
        value: corp.id,
        label: `${corp.name}`,
      })),
    );
  }

  const { setSorting, sorting, selectionChangedCallback } = props;
  const value = find(options, { value: sorting });

  return (
    <div className="mt-4" style={{ width: 200 }}>
      <AppSelect
        placeholder="Sorting options"
        value={value}
        onChange={(newValue) => {
          if (! newValue) return;
          setSorting(newValue.value);
          selectionChangedCallback(newValue.value);
        }}
        options={options}
      />
    </div>
  );
}
