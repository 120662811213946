import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clone } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../helpers/styling';
import SearchLoader from './SearchLoader';
import SearchResultRow from './SearchResultRow';
import { useGetSearchResultsQuery } from './searchAPI';
import { RootState } from '../../app/store';
import { ProfilePageTypeResults } from './search-enums';
import { ISearchRecord } from './search-types';
import SearchFilters from './SearchFilters';
import { setLocalFilters } from './searchSlice';
import { useAppDispatch } from '../../app/hooks';
import { FEATURE_SEARCH_FILTER_CORPORATES_ENABLED } from '../../config/features';

// todo: normalize and move to slice
let tabsData = [
  {
    name: 'Individuals',
    profilePageTypeResult: ProfilePageTypeResults.individual,
    current: true,
  },
];

if (FEATURE_SEARCH_FILTER_CORPORATES_ENABLED) {
  tabsData = [
    {
      name: 'Corporates',
      profilePageTypeResult: ProfilePageTypeResults.corporation,
      current: true,
    },
    {
      name: 'Individuals',
      profilePageTypeResult: ProfilePageTypeResults.individual,
      current: false,
    },
  ];
}

// todo: show somehow if filters are applied / changed / not applied
export default function SearchResults() {

  const {
    currentSearchString, currentSearchCacheID, localFilters, appliedFilters,
  } = useSelector((state: RootState) => state.search);

  const [tabs, setTabs] = useState(tabsData);

  const [foundItems,  setFoundItems] = useState<ISearchRecord[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useAppDispatch();

  const appliedFiltersCacheTriggerString = JSON.stringify(appliedFilters);

  const { data, isLoading, isFetching } = useGetSearchResultsQuery({
    PageNumber: currentPage,
    PageSize: 10,
    searchInput: currentSearchString,
    appliedFilters,
    appliedFiltersCacheTriggerString,
    profilePageTypeResults: tabs.filter((tab) => tab.current)[0].profilePageTypeResult, // todo: store in slice
    searchIdentifier: currentSearchCacheID,
  }, { skip: ! currentSearchString });

  let content = null;
  if (isLoading || isFetching) {
    content = (
      <div className="mt-12">
        <SearchLoader />
      </div>
    );
  }

  let totalResultsCount = 0;
  if (FEATURE_SEARCH_FILTER_CORPORATES_ENABLED && data) {
    totalResultsCount = data.totalRecordsCorporations + data.totalRecordsIndividuals;
  } else if (data) {
    totalResultsCount = data.totalRecordsIndividuals;
  } 

  const reset = useCallback(() => {
    setFoundItems([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => { // reset on search change
    reset();
  }, [currentSearchString, reset, appliedFiltersCacheTriggerString]);

  // const records = data ? data.records.length : null;
  useEffect(() => {
    if (! data || data.records.length === 0) {
      return;
    }

    if (data.pageNumber === 1) {
      setFoundItems(data.records);
      return;
    }

    setFoundItems([
      ...foundItems,
      ...data.records,
    ]);

  // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (! data) {
      return;
    }

    dispatch(setLocalFilters(data.filters));

    // eslint-disable-next-line
  }, [data]);

  const currentTab = tabs.filter((tab) => tab.current)[0];

  if (foundItems.length > 0) {
    let hasMore = true;

    if (data && currentTab.name === 'Corporates') {
      if (data.totalRecordsCorporations <= currentPage * 10) { // PER_PAGE
        hasMore = false;
      }
    }

    if (data && currentTab.name === 'Individuals') {
      if (data.totalRecordsIndividuals <= currentPage * 10) { // PER_PAGE
        hasMore = false;
      }
    }

    content = (
      <InfiniteScroll
        dataLength={foundItems.length}
        // height={400}
        next={() => setCurrentPage(currentPage + 1)}
        hasMore={hasMore}
        loader={(
          <div className="mt-4">
            <SearchLoader />
          </div>
        )}
      >
        {
          foundItems.map((record) => (
            <SearchResultRow
              key={record.id}
              isIndividual={currentTab.profilePageTypeResult === ProfilePageTypeResults.individual}
              record={record}
            />
          ))
        }
      </InfiniteScroll>
    );
  }

  if (foundItems.length === 0 && (! isLoading) && (! isFetching)) {
    content = (
      <span className="text-sm text-neutral-400">
        Sorry, we have no results at the moment - your request has been added to our wishlist
      </span>
    );
  }

  return (
    <div className="w-full bg-white mt-10 shadow pl-12 pr-16 py-6 pb-12 max-w-5xl mx-auto rounded-md">

      { /* RESULTS LABEL */ }
      <h3 className="font-semibold text-2xl pb-1">Results</h3>
      {
        !isFetching && !isLoading && currentSearchString && totalResultsCount > 0 && (
          <span className="font-semibold text-sm text-neutral-400">
            {totalResultsCount} 
            {' '}
            search results
          </span>
        )
      }

      { /* FILTERS */ }
      {
        currentSearchString && !isFetching && !isLoading && totalResultsCount > 0 && (
          <SearchFilters
            key={JSON.stringify(localFilters)}
            showFilters={Boolean(data)}
            filters={localFilters}
          />
        )
      }

      { /* NO RESULTS LABEL */ }
      {
        ! currentSearchString && (
          <div className="text-sm text-neutral-400 flex flex-row items-center mt-12">
            <MagnifyingGlassIcon className="mr-2 w-4 h-4" />
            <span>
              Nothing to search
            </span>
          </div>
        )
      }

      { /* RESULT RECORDS */ }
      {
        currentSearchString && (
          <>
            {
              !isFetching && !isLoading && totalResultsCount > 0 && (
                <div>
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">Select a tab</label>
                  </div>
                  <div className={`mt-12 hidden ${tabsData.length === 1 ? '' : 'sm:block'}`}>
                    <div className="border-b border-neutral-200">
                      <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <div
                            role="none"
                          // aria-current={tab.current ? 'page' : undefined}
                            onClick={() => {
                              reset();
    
                              setTabs(tabs.map((changeTab) => {
                                const copyTab = clone(changeTab);
                                copyTab.current = changeTab.profilePageTypeResult === tab.profilePageTypeResult;
    
                                return copyTab;
                              }));
                            }}
                            key={tab.name}
                            // href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-main-500 text-main-600 text-semibold'
                                : 'border-transparent text-neutral-800 hover:text-neutral-700 hover:border-neutral-300 cursor-pointer',
                              'w-1/2 pt-4 pb-2 px-1 pl-6 text-left border-b-2 font-medium text-sm',
                            )}
                          >
                            {tab.name}
                            {
                            data && ` (${tab.name === 'Corporates' ? data.totalRecordsCorporations : data.totalRecordsIndividuals})`
                          }
                          </div>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              )
            }
            {/* Content */}

            <div className="mt-6">
              {content}
            </div>
          </>
        )
      }

    </div>
  );
}
