import React, { useEffect, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import Input from '../../../elements/Input';
import PostTextarea from '../../post/create-post/PostTextarea';
import ForumTagsInput from '../post-question/ForumTagsInput';
import PostOnBehalfMessage from '../../post/create-post/PostOnBehalfMessage';
import Button from '../../../elements/Button';
import { ForumTagResponse, GetQuestionResponse } from '../forum-types';
import { useEditQuestionMutation } from '../forumAPI';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { useFormValidation } from '../../../helpers/validation';
import { rulesForumQuestionForm, validationErrorState, ValidationKeys } from '../questionValidationRules';
import { getCanEditForumQuestions } from '../../../app/permissionsSchema';

interface EditQuestionFormProps {
  data: GetQuestionResponse,
  setViewState: () => void;
}

export default function EditQuestionForm(props: EditQuestionFormProps) {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState<ForumTagResponse[]>([]);

  const { setViewState, data } = props;

  const { currentProfile } = useSelector((state: RootState) => state.account);

  const [editQuestionRequest] = useEditQuestionMutation();
  const canEditForumQuestions = useAppSelector(getCanEditForumQuestions);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForumQuestionForm,
    validationErrorState,
    {
      title,
      description,
      tags,
    },
  );

  const onSubmit = async () => {
    // TODO verify: "&& ! canEditForumQuestions" looks like potential bug that won't allow users to update answers
    if (! currentProfile || (! currentProfile.profileID && ! canEditForumQuestions)) return;

    const val = validate();

    if (! val.allValid) return;

    setIsSubmitting(true);

    const response = await editQuestionRequest({
      questionID: data.id,
      requesterID: currentProfile.profileID,
      form: { description, tags, title },
    });

    setIsSubmitting(false);

    // @ts-ignore
    if (response.data && response.data.id) {
      setViewState();
    }
  };

  useEffect(() => {
    setTitle(data.title);
    setDescription(data.content);
    setTags(data.tags.map((tag) => ({
      ...tag,
      value: tag.id,
      label: tag.name,
    })));
  }, [data]);

  return (
    <div className="w-full mb-4">

      <Input
        value={title}
        placeholder="e.g. Is there a chemical N to produce X?"
        onChange={(e) => {
          setTitle(e.target.value);
          onValidationInputChange('title');
        }}
        label="Title"
        id="ask-new-forum-question"
        validationMessage={validationState.title.errorMessage}
      />

      <div className="mt-4">
        <PostTextarea
          text={description}
          onChange={(e) => {
            setDescription(e.target.value);
            onValidationInputChange('description');
          }}
          placeholder="Describe your question"
          minHeight="h-32"
          validationMessage={validationState.description.errorMessage}
        />
      </div>

      <div className="mt-4">
        <ForumTagsInput
          values={tags}
          onChange={(newTagsMV) => {
            const newTags = newTagsMV.map((mv) => mv);
            onValidationInputChange('tags');
            setTags(newTags);
          }}
          validationMessage={validationState.tags.errorMessage}
        />
      </div>

      <PostOnBehalfMessage />

      <div className="flex justify-end gap-4 mt-6">
        <Button
          onClick={onSubmit}
          size="x-medium"
          description="Save changes"
          disabled={isSubmitting}
          iconLeft={<PencilSquareIcon className="w-4 h-4 mr-2" />}
        />
      </div>
    </div>
  );
}
