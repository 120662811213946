import React, {
  useState, Fragment,
} from 'react';
import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import Button from '../../../elements/Button';
import { RootState } from '../../../app/store';
import { useDownloadProfileMutation } from '../profilePageAPI';

export default function DownloadProfileButton() {

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    profileOfCurrentUser: { name },
    currentlyLoadedProfileID,
  } = useSelector((state: RootState) => state.profilePage);

  const { email } = useSelector((state: RootState) => state.account);

  const [downloadProfileQuery] = useDownloadProfileMutation();

  const onDownload = async () => {
    if (! currentlyLoadedProfileID) return;

    setIsLoading(true);

    try {
      await downloadProfileQuery({ profilePageID: currentlyLoadedProfileID });
      setIsLoading(false);
      setOpen(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={onOpenModal}
        className="inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none "
      >
        <CloudArrowDownIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>Request profile (pdf)</span>
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <p className="font-semibold text-lg text-left pb-4">
                  {name}
                  &apos;s profile page data extract
                </p>

                <div className="flex items-center justify-center my-6">
                  <p className="text-base">
                    Profile Page summary of
                    {' '}
                    {name}
                    {' '}
                    will be extracted and send to
                    {' '}
                    <span className="font-semibold">{email}</span>
                  </p>
                </div>

                <div className="pt-4 flex justify-end gap-4">
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    inverted
                    description="Close"
                  />
                  <Button
                    isLoading={isLoading}
                    onClick={onDownload}
                    size="medium"
                    iconLeft={<CloudArrowDownIcon className="w-4 h-4 mr-3" />}
                    description="Send PDF"
                  />
                </div>
              </div>

            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
