import { cloneDeep } from 'lodash';
import { ContactList, ContactListEntry } from './contact-list.types';

// todo: filter on the backend
export const filterContacts = (
  data: ContactList | undefined,
  searchString: string,
  contactsOnly: boolean,
  followersOnly: boolean,
  followingOnly: boolean,
)
: ContactList | undefined => {

  const initialData = cloneDeep(data);

  // filter if any of the flags is set
  const shouldBeFiltered = Boolean(searchString) || contactsOnly || followersOnly || followingOnly;

  if (! shouldBeFiltered || ! initialData) return initialData;

  let totalRecords = 0;

  // todo: update letterGroupKeys
  initialData.letterGroupKeys.forEach((letter) => {
    // @ts-ignore
    initialData.letterGroups[letter].contacts = initialData.letterGroups[letter].contacts.filter((contact: ContactListEntry) => {

      const { profilePageSummary } = contact;
      let searchPass = true;
      if (searchString) {
        // search by these fields
        let searchBy = `${profilePageSummary.name} ${profilePageSummary.relatedCorporationProfilePageSummary ? profilePageSummary.relatedCorporationProfilePageSummary.name : ''}`;

        searchBy = searchBy.toLowerCase();

        searchPass = searchBy.includes(searchString.toLowerCase());
      }

      let contactsPass = true;
      if (contactsOnly) {
        contactsPass = contact.isContact;
      }

      let followersPass = true;
      if (followersOnly) {
        followersPass = contact.isFollower;
      }

      let followingPass = true;
      if (followingOnly) {
        followingPass = contact.isFollowing;
      }

      const goodResult = searchPass && contactsPass && followersPass && followingPass; // logic AND

      if (goodResult) {
        totalRecords += 1;
      }

      return goodResult;
    });
  });

  initialData.totalRecords =  totalRecords;

  // filter empty letters
  initialData.letterGroupKeys = initialData.letterGroupKeys.filter(
    // @ts-ignore
    (letter) => initialData.letterGroups[letter].contacts.length > 0,
  );

  return initialData;
};
