import React from 'react';
import { Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

export default function PageLoader() {

  const {
    showLoader,
  } = useSelector((state: RootState) => state.account);

  return (
    <>
      <Transition
        appear
      // as={Fragment}
      // show
        show={showLoader}
        enter="ease-in-out duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div />
      </Transition>

      {/* TODO: use Transition; pointer events none may cause problems in older browsers */}
      <div
        style={{ opacity: showLoader ? '100' : '0' }}
        className=" pointer-events-none transition-opacity duration-1000 z-50 fixed inset-y-0 right-0 w-full max-w-full"
      >
        <div className="page-loader">
          <div className="spinner" />
          {/* <div className="txt">Time 2 Pharma</div> */}
        </div>
      </div>
    </>
  );
}
