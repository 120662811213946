import React from 'react';

export default function ModuleLoader() {
  return (
    <>
      <div className="p-4">
        <div className="w-full h-24 mx-auto mt-4">
          <div className="flex animate-pulse flex-row h-full space-x-5">

            <div className="w-full flex flex-col space-y-3">
              <div className="w-72 bg-neutral-500 h-4 rounded-md " />
              <div className="w-full bg-neutral-300 h-4 rounded-md " />
              <div className="w-1/2 bg-neutral-300 h-4 rounded-md " />
            </div>
          </div>
        </div>

        <div className="w-full h-px bg-neutral-300" />

        <div className="w-full h-24 mx-auto mt-8">
          <div className="flex animate-pulse flex-row h-full space-x-5">

            <div className="w-full flex flex-col space-y-3">
              <div className="w-72 bg-neutral-500 h-4 rounded-md " />
              <div className="w-full bg-neutral-300 h-4 rounded-md " />
              <div className="w-1/2 bg-neutral-300 h-4 rounded-md " />
            </div>
          </div>
        </div>

        <div className="w-full h-px bg-neutral-300" />

        <div className="w-full h-24 mx-auto mt-8">
          <div className="flex animate-pulse flex-row h-full space-x-5">

            <div className="w-full flex flex-col space-y-3">
              <div className="w-72 bg-neutral-500 h-4 rounded-md " />
              <div className="w-full bg-neutral-300 h-4 rounded-md " />
              <div className="w-1/2 bg-neutral-300 h-4 rounded-md " />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 border-t" />
    </>
  );
}
