import React from 'react';
import AboutModuleView from './AboutModuleView';
import AboutModuleEdit from './AboutModuleEdit';
import ModuleLayout from '../../components/ModuleLayout';
import { useRefetchAboutModuleMutation } from './aboutModuleAPI';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import { RelatedCorporationSummary } from '../../../../app/account';

interface AboutModuleProps extends ModuleLayoutBaseProps {
  relatedCorporationProfilePageSummary?: RelatedCorporationSummary,
}

export default function AboutModule(props: AboutModuleProps) {
  // const editModeFlag = useSelector((state: RootState) => state.a);

  const [refetchModuleData] = useRefetchAboutModuleMutation();

  const { 
    dragHandleProps, 
    moduleMetadata, 
    allowEditMetadataModule, 
    relatedCorporationProfilePageSummary,
  } = props;

  return (
    <ModuleLayout
      header="About"
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      onReset={() => refetchModuleData()}
      editComponent={<AboutModuleEdit />}
      viewComponent={<AboutModuleView relatedCorporationProfilePageSummary={relatedCorporationProfilePageSummary} />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
