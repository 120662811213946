import React from 'react';
import { TagIcon } from '@heroicons/react/20/solid';
import { Product, RegStatusInfoEnum } from '../products-module.types';
import TerritoryFlag from '../../business-markets-module/elements/TerritoryFlag';

interface ProductDetailsViewProps {
  product: Product,
}

export default function ProductDetailsView(props: ProductDetailsViewProps) {

  const {
    product: {
      activeIngredients,
      description,
      presentations,
      regStatusInformation,
      holderOwners,
      licences,
      productCategories,
      atcGroup,
      projectName,
      coreTags,
    },
  } = props;

  // todo: extract string formatting
  const ingredientsString = (activeIngredients && activeIngredients.length > 0)
    ? activeIngredients
      .reduce((acc, ingredient) => `${acc}${ingredient.coreInnProduct.name} + `, '')
      .trim()
      .replace(/\+$/, '') // remove last "+"
    : '';

  return (
    <div className="pl-10 py-8 pr-12">
      {
        activeIngredients && activeIngredients.length > 0 && (
          <div>
            <p className="text-xs text-neutral-700 py-2">
              Active ingredient
              { activeIngredients.length > 1 ? 's' : '' }
            </p>
            <p className="text-sm font-semibold text-neutral-800">
              { ingredientsString }
            </p>
            <div className="w-full my-2 border-t border-neutral-300" />
          </div>
        )
      }
      {
        description && (
          <div>
            <p className="text-xs text-neutral-700 py-2">
              Description
            </p>
            <p className="text-sm font-semibold text-neutral-800 whitespace-pre-line">
              {description}
            </p>
            <div className="w-full my-2 border-t border-neutral-300" />
          </div>
        )
      }
      {
        presentations && presentations.length > 0 && (
          presentations.map((presentation, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="pt-2" key={idx}>
              <div className="grid grid-cols-2">
                {/* Dosage & strength */}
                {
                  presentation.dossages.length > 0 && (
                    <>
                      <div>
                        <p className="text-xs text-neutral-700">
                          Dosage form
                        </p>
                        {
                          presentation.dossages.map((dosage, i) => (
                            // Because dosage may repeat
                            // eslint-disable-next-line react/no-array-index-key
                            <p className="text-sm font-semibold text-neutral-800" key={dosage.coreDosageForm.id + i}>
                              {dosage.coreDosageForm.name}
                            </p>
                          ))
                        }
                      </div>
                      {/*  */}
                      <div>
                        <p className="text-xs text-neutral-700">
                          Strength
                        </p>
                        {
                          presentation.dossages.map((dosage, i) => {
                            // todo: extract
                            const strength = dosage.moduleProductInnStrengths
                              .reduce((acc, ds) => `${acc}${ds.strength || '0'}${dosage.coreStrengthUnit ? dosage.coreStrengthUnit.name : ''} + `, '')
                              .trim()
                              .replace(/\+$/, '');

                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <p key={dosage.coreDosageForm.id + i} className="text-sm font-semibold text-neutral-800">
                                { strength }
                              </p>
                            );
                          })
                        }
                      </div>
                    </>
                  )
                }
              </div>
              <p className="text-xs text-neutral-700 py-2 mt-2">
                Presentation
              </p>
              <p className="text-sm font-semibold text-neutral-800 whitespace-pre-line">
                {presentation.presentation}
              </p>
              <div className="w-full my-2 border-t border-neutral-300" />
            </div>
          ))
        )
      }
      {
        regStatusInformation && regStatusInformation.length > 0 && (
          <div className="pt-2 mt-2">
            <p className="text-xs text-neutral-700 py-2">
              Regulatory Status Information
            </p>
            {
              regStatusInformation.map((info) => (
                <div key={info.statusInfoType}>
                  {
                    info.countries.length > 0 ? (
                      <div>
                        <p className="font-semibold text-xs mt-3 mb-2">
                          {info.statusInfoType === RegStatusInfoEnum.approved ? 'Approved:' : ''}
                          {info.statusInfoType === RegStatusInfoEnum.underDevelopment ? 'Under development:' : ''}
                          {info.statusInfoType === RegStatusInfoEnum.underRegistration ? 'Under registration:' : ''}
                          {info.statusInfoType === RegStatusInfoEnum.marketed ? 'Marketed:' : ''}
                        </p>
                        <div className="flex flex-row flex-wrap gap-2 mt-1 mb-2">
                          {
                            info.countries.map((country) => (
                              <div key={country.coreTerritory.id} className="flex flex-row items-center px-3 py-2 border border-neutral-200 rounded-md">
                                {
                                  country.coreTerritory.isoAlpha3Code && (
                                    <TerritoryFlag small territoryName={country.coreTerritory.name} isoAlpha3Code={country.coreTerritory.isoAlpha3Code} />
                                  )
                                }
                                <span className="ml-1.5 font-semibold text-xs">
                                  {country.coreTerritory.name}
                                </span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              ))
            }

            <div className="w-full mb-2 mt-6 border-t border-neutral-300" />
          </div>
        )
      }
      {
        holderOwners && holderOwners.length > 0 && (
          <div className="pt-2 mt-2">
            <p className="text-xs text-neutral-700 py-2">
              Marketing Authorisation Holder / Product Owner
            </p>
            <p className="text-sm font-semibold text-neutral-800 whitespace-pre-line">
              {
                holderOwners.map((holder, idx) => {
                  if (idx < holderOwners?.length - 1) return `${holder.authHolderProdOwner}, `;
                  return holder.authHolderProdOwner;
                })
              }
            </p>

            <div className="w-full my-2 border-t border-neutral-300" />
          </div>
        )
      }
      <div className="grid grid-cols-2 mt-4 gap-x-6">
        {
          licences && licences.length > 0 && (
            <div className="">
              <p className="text-xs text-neutral-700 py-2">
                License number
              </p>
              {
                licences.map((licence) => (
                  <p key={licence.licenceNumber} className="text-sm font-semibold text-neutral-800">
                    {licence.licenceNumber}
                  </p>
                ))
              }
              <div className="w-full my-2 border-t border-neutral-300" />
            </div>
          )
        }
        {
          productCategories && productCategories.length > 0 && (
            <div className="">
              <p className="text-xs text-neutral-700 py-2">
                Product category
              </p>
              {
                productCategories.map((category) => (
                  <p key={category.coreProductCategory.id} className="text-sm font-semibold text-neutral-800">
                    {category.coreProductCategory.name}
                  </p>
                ))
              }
              <div className="w-full my-2 border-t border-neutral-300" />
            </div>
          )
        }
        {
          atcGroup && atcGroup.atcCodeName && (
            <div className="">
              <p className="text-xs text-neutral-700 py-2">
                ATC Group
              </p>
              <p className="text-sm font-semibold text-neutral-800">
                {atcGroup.atcCodeName}
              </p>
              <div className="w-full my-2 border-t border-neutral-300" />
            </div>
          )
        }
        {
          projectName && (
            <div className="">
              <p className="text-xs text-neutral-700 py-2">
                Project name
              </p>
              <p className="text-sm font-semibold text-neutral-800">
                {projectName}
              </p>
              <div className="w-full my-2 border-t border-neutral-300" />
            </div>
          )
        }
      </div>
      {
        coreTags && coreTags.length > 0 && (
          <div className="mb-4">
            <p className="text-xs text-neutral-700 py-2">
              Tags
            </p>
            <div className="flex flex-wrap gap-1">
              {
                coreTags.map((tag) => (
                  <div key={tag.coreTag.id} className="text-xs py-1 px-2 rounded-md border border-main-200 bg-main-25 flex flex-row items-center">
                    <TagIcon className="w-3 h-3 text-main-300 mr-1" />
                    {tag.coreTag.name}
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
