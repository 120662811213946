import { createSlice } from '@reduxjs/toolkit';

import { BusinessMarketsModuleTerritory, FlattenTerritory } from './business-markets-module-types';

export interface BusinessMarketsModuleSlice {
  description: string,
  territories: FlattenTerritory[],
}

const initialState: BusinessMarketsModuleSlice = {
  description: '',
  territories: [],
};

export const businessMarketsModuleSlice = createSlice({
  name: 'businessMarketsModule',
  initialState,
  reducers: {
    initiateModule: (state, { payload }) => {
      state.description = payload.description ? payload.description : '';
      state.territories = payload.territories ? payload.territories.map((territory: BusinessMarketsModuleTerritory) => ({
        ...territory,
        value: territory.id,
        label: territory.territoryName,
        nestingLevel: 0,
      })) : [];
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    updateTerritories: (state, action) => {
      state.territories = action.payload;
    },
  },
});

export const {
  updateDescription,
  updateTerritories,
  initiateModule,

} = businessMarketsModuleSlice.actions;

export default businessMarketsModuleSlice.reducer;
