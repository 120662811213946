import { clone } from 'lodash';
import { BusinessMarketsModuleTerritory, FlattenTerritory } from './business-markets-module-types';

// eslint-disable-next-line
export const flattenTerritories = (territories: BusinessMarketsModuleTerritory[], nestingLevel = 1) => {
  const flattenTree: FlattenTerritory[] = [];

  if (! territories || territories.length === 0) {
    return flattenTree;
  }

  territories.forEach((t) => {
    const territory: FlattenTerritory = {
      ...clone(t),
      value: t.id,
      label: t.territoryName,
      nestingLevel,
    };

    const children = t.childTerritories;
    territory.childTerritories = null;

    territory.value = territory.id;

    flattenTree.push(territory);

    if (children && children.length > 0) {
      const flattenChildren = flattenTerritories(children, nestingLevel + 1);
      flattenTree.push(...flattenChildren);
    }
  });

  return flattenTree;
};
