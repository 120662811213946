import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import Zoom from 'react-medium-image-zoom';
import searchImg from './assets/search-img.png';

export default function FeatureSearch() {

  return (
    <div className="z-10 w-full flex flex-col xl:flex-row max-w-6xl w-full px-4 md:px-6 xl:px-0 -mt-2">
      <Fade left>
        <div
          className="min-w-fit pr-0 md:pr-10 pl-6 xl:pl-0 my-6 xl:my-0 pt-0 xl:pt-20"
        >
          <h3 className="text-main-900 text-5.5xl font-extrabold">
            Search
          </h3>
          <p className="text-neutral-700 text-base font-medium mt-6" style={{ maxWidth: 530 }}>
            A clean simple search bar tool; no drop-down boxes, or pop-up ads.
            Filter searches to find relevant products and services being offered and sought,
            whilst relying on Nucleo’s real time and accurate data to take you to the right
            person in the right organisation.
          </p>
        </div>
      </Fade>

      <Fade right>
        <Zoom>
          <div className="w-full h-full flex items-center justify-center">
            <img
              className="feature-image"
              src={searchImg}
              alt="Search example"
            />
          </div>
        </Zoom>
      </Fade>
    </div>
  );
}
