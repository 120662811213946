import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';

interface PreRegistrationRequest {
  email: string,
  name: string,
  surname: string,

  // extended version
  phoneNumber?: string,
  jobTitle?: string,
  companyName?: string,
  country?: string,
  focusAreas?: string[],
  prizeConsent?: boolean,
  policyConsent?: boolean,
  subscribedToNewsletter?: boolean,
  origin?: string,
}

export const contactUsAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    preRegistration: builder.mutation<null, PreRegistrationRequest>({
      query(data) {
        return {
          url: '/Administration/account/pre-register',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Registration request completed'));
        } catch (e) {
          const { data } = e.error;
          let message = 'Something went wrong. Please try again.';

          if (data.uiMessage) {
            message = e.error.data.uiMessage;
          }

          dispatch(addFlashMessage('error', message, true));
        }
      },
    }),
    contactForm: builder.mutation<null, { email: string, name: string, message: string }>({
      query(data) {
        return {
          url: '/Administration/contact/message',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Message has been sent'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),

    cancelPreRegister: builder.mutation<null, { email: string }>({
      query(data) {
        return {
          url: '/Administration/account/pre-register',
          method: 'DELETE',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Successfully unsubscribed'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),
  }),
});

export const {
  usePreRegistrationMutation,
  useContactFormMutation,
  useCancelPreRegisterMutation,
} = contactUsAPI;
