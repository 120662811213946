import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import {
  CorporateInfo,
  CorporationOwnershipInvitation,
  GetMembersResponse,
  GetMembersRequest,
  CorporationMemberRole,
  EmailNotificationsSettingsResponse,
  GetMembersInvitationsRequest,
  GetMembersInvitationsResponse,
  InvitationDetailsResponse,
  AcceptMemberInvitationResponse,
} from './setings-types';
import { RelatedCorporationSummary } from '../../app/account';

export const settingsAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporationMembers: builder.query<GetMembersResponse, GetMembersRequest>({
      query: (data) => ({
        url: `/Corporation/${data.corporationID}/members`,
        params: {
          PageNumber: data.pageNumber || 1,
          PageSize: data.pageSize || 10_000,
          searchInput: data.searchInput || '',
        },
      }),
      providesTags: (result, error, arg) => [{
        type: 'CorporationMembersList',
        pageNumber: arg.pageNumber || 1,
        pageSize: arg.pageSize || 10_000,
        searchInput: arg.searchInput || '',
      }],

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'Unable to load corporation members. Please try again.'));
        }
      },
    }),

    getCorporationMembersInvitations: builder.query<GetMembersInvitationsResponse, GetMembersInvitationsRequest>({
      query: (data) => ({
        url: `/Corporation/${data.corporationID}/members/invitations`,
        params: {
          PageNumber: data.pageNumber || 1,
          PageSize: data.pageSize || 10_000,
        },
      }),
      providesTags: (result, error, arg) => [{
        type: 'CorporationMemberInvitationsList',
        pageNumber: arg.pageNumber || 1,
        pageSize: arg.pageSize || 10_000,
      }],

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'Unable to load corporation member invitations. Please try again.'));
        }
      },
    }),

    deleteCorporationMemberInvitation: builder.mutation<null, { corporationID: number, invitationID: number }>({

      query(data) {

        const { corporationID, invitationID } = data;

        return {
          url: `/Corporation/${corporationID}/members/invitation/${invitationID}`,
          method: 'DELETE',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Invitation has been deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
      invalidatesTags: ['CorporationMemberInvitationsList'],
    }),

    resendCorporationMemberInvitation: builder.mutation<null, { corporationID: number, invitationID: number }>({

      query(data) {

        const { corporationID, invitationID } = data;

        return {
          url: `/Corporation/${corporationID}/members/invitation/${invitationID}/resend`,
          method: 'POST',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Invitation Email has been Resend'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),

    updateCorporateDetails: builder.mutation<any, { corporationID: number, name: string }>({

      query(data) {

        const { corporationID, name } = data;

        return {
          url: `/Corporation/${corporationID}/settings/details`,
          method: 'POST',
          body: {
            name,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Details were updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
      invalidatesTags: (result, error, arg) => [
        // profileID != corporationID, looks strange. TODO: investigate
        { type: 'ProfilePage', profileID: arg.corporationID, requesterID: arg.corporationID },
        { type: 'CorporationInfo', corporationID: arg.corporationID },
      ],
    }),

    inviteMember: builder.mutation<any, { corporationID: number, name: string, surname: string, email: string }>({

      query(data) {

        const {
          corporationID, name, email, surname,
        } = data;

        return {
          url: `/Corporation/${corporationID}/member/invite`,
          method: 'POST',
          body: {
            firstName: name,
            surname,
            email,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Invite has been sent.'));
        } catch (e) {
          let message = 'Something went wrong. Please try again.';
          if (e.error.data?.type === 'AccountAlreadyExistsException') {
            message = 'Account already exists';
          }

          dispatch(addFlashMessage('error', message));
        }
      },
      invalidatesTags: ['CorporationMemberInvitationsList'],
    }),
    acceptMemberInvitation: builder.mutation<AcceptMemberInvitationResponse, { invitationCode: string }>({

      query(data) {

        const { invitationCode } = data;

        return {
          url: `/Corporation/member/invite/${invitationCode}/accept`,
          method: 'POST',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
      },
    }),
    resetMemberPassword: builder.mutation<null, { corporationID: number, memberAccountID: number }>({

      query(data) {

        const { corporationID, memberAccountID } = data;

        return {
          url: `/Corporation/${corporationID}/member/${memberAccountID}/settings/password`,
          method: 'POST',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'New password has been sent to email'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),
    deleteMemberAccount: builder.mutation<null, { corporationID: number, memberAccountID: number }>({

      query(data) {

        const { corporationID, memberAccountID } = data;

        return {
          url: `/Corporation/${corporationID}/member/${memberAccountID}`,
          method: 'DELETE',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Member has been deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
      invalidatesTags: ['CorporationMembersList'],
    }),
    assignCorporationMemberRole: builder.mutation<null, { corporationID: number, memberAccountID: number, role: CorporationMemberRole }>({

      query(data) {

        const { corporationID, memberAccountID, role } = data;

        return {
          url: `/Corporation/${corporationID}/member/${memberAccountID}/role/${role}`,
          method: 'PUT',
          body: {},
        };
      },
    }),
    acceptOwnershipInvitation: builder.mutation<any, { invitationCode: string }>({

      query(data) {

        const { invitationCode } = data;

        return {
          url: `application-administration/DataManagement/corporation-owner/invitation/${invitationCode}/accepted`,
          method: 'POST',
          body: {},
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Invite has been accepted!'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),
    getOwnershipByInvite: builder.query<CorporationOwnershipInvitation, { inviteID?: number }>({
      query: ({ inviteID }) => ({
        url: `application-administration/DataManagement/corporation-owner/invitation/${inviteID}`,
      }),
    }),

    getCorporationInfo: builder.query<CorporateInfo, { corporationID: number }>({
      query: ({ corporationID }) => ({
        url: `/Corporation/${corporationID}`,
      }),
      // todo: after update - invalidate this tag
      providesTags: (result, error, arg) => [{
        type: 'CorporationInfo',
        corporationID: arg.corporationID,
      }],

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'Unable to load corporation info. Please try again.'));
        }
      },
    }),

    getAccountEmailNotificationsSettings: builder.query<EmailNotificationsSettingsResponse, void>({
      query: () => ({
        url: '/Account/settings/notifications/email',
      }),
      providesTags: ['EmailNotificationsStatus'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while fetching email notification settings'));
        }
      },
    }),
    
    updateAccountEmailNotifications: builder.mutation<any, { 
      contactRequest?: boolean, 
      messagingRequest?: boolean, 
      unreadMessages?: boolean,
      forumNews?: boolean,
      forumPostReply?: boolean,
    }>({
      query(data) {
        return {
          url: '/Account/settings/notifications/email',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => ['EmailNotificationsStatus'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(
          settingsAPI.util.updateQueryData('getAccountEmailNotificationsSettings', undefined, (draft) => ({
            ...draft,
            ...args,
          })),
        );

        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Email Notifications successfully updated'));
        } catch {
          dispatch(settingsAPI.util.invalidateTags(['EmailNotificationsStatus']));
          dispatch(addFlashMessage('error', 'There was an error while updating Email Notifications Status'));
        }
      },
    }),
  }),
});

export const {
  useGetCorporationMembersQuery,
  useGetCorporationMembersInvitationsQuery,
  useInviteMemberMutation,
  useUpdateCorporateDetailsMutation,
  useAcceptMemberInvitationMutation,
  useResetMemberPasswordMutation,
  useDeleteMemberAccountMutation,
  useAssignCorporationMemberRoleMutation,
  useAcceptOwnershipInvitationMutation,
  useGetOwnershipByInviteQuery,
  useGetCorporationInfoQuery,
  useGetAccountEmailNotificationsSettingsQuery,
  useUpdateAccountEmailNotificationsMutation,
  useDeleteCorporationMemberInvitationMutation,
  useResendCorporationMemberInvitationMutation,
} = settingsAPI;
