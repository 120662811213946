import { 
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterIcon,
  ClipboardDocumentListIcon,
  CogIcon,
  FlagIcon,
  LockClosedIcon,
  MagnifyingGlassIcon,
  SpeakerWaveIcon, 
  UserCircleIcon, 
  UserIcon, 
  UsersIcon,
} from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FaqSection from './FaqSection';
import useFaqEntries from './contact-us-data';
import { RootState } from '../../app/store';
import { AccountType } from '../../app/account';
import { useBrandingLocale } from '../../helpers/hooks';

export default function FaqMain() {

  const {
    currentProfile, corporationDetails,
  } = useSelector((state: RootState) => state.account);

  const { t } = useBrandingLocale();

  const { 
    faqAccountEntries, 
    faqProfileEntries, 
    faqUserInteractionEntries,
    faqActivityFeedEntries,
    faqForumEntries,
    faqMessagingEntries,
    faqSearchEntries,
    faqPrivacyEntries,
    faqContentReportingEntries,
    faqCorporationAdministrationEntries,
    faqGlobalAdministrationEntries,
  } = useFaqEntries();

  return (
    <div className="p-12 mt-2 bg-white rounded-md shadow-sm">
      <h2 className="text-3xl font-bold text-neutral-800 mb-4">FAQ</h2>

      { /* ALL USERS SECTIONS */ }

      <FaqSection
        title="Account Management"
        questions={faqAccountEntries}
        icon={<UserCircleIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Profile"
        questions={faqProfileEntries}
        icon={<UserIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="User Interaction"
        questions={faqUserInteractionEntries}
        icon={<UsersIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Activity Feed"
        questions={faqActivityFeedEntries}
        icon={<SpeakerWaveIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Forum"
        questions={faqForumEntries}
        icon={<ClipboardDocumentListIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Messaging"
        questions={faqMessagingEntries}
        icon={<ChatBubbleBottomCenterIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Search"
        questions={faqSearchEntries}
        icon={<MagnifyingGlassIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Privacy"
        questions={faqPrivacyEntries}
        icon={<LockClosedIcon className="w-4 h-4 mr-2" />}
      />
      <FaqSection
        title="Content Reporting"
        questions={faqContentReportingEntries}
        icon={<FlagIcon className="w-4 h-4 mr-2" />}
      />

      { /* CORPORATION ADMINISTRATOR SECTIONS */ }

      {
        ((currentProfile.accountType === AccountType.corporation && corporationDetails?.memberRole === 'admin')
        || currentProfile.accountType === AccountType.applicationAdministrator) && (
          <FaqSection
            title={`${t('Corporation')} Administrator`}
            questions={faqCorporationAdministrationEntries}
            icon={<CogIcon className="w-4 h-4 mr-2" />}
          />
        )
      }

      { /* SUPER ADMIN SECTIONS */ }

      {
        currentProfile.accountType === AccountType.applicationAdministrator && (
          <FaqSection
            title="Global Administrator"
            questions={faqGlobalAdministrationEntries}
            icon={<AdjustmentsVerticalIcon className="w-4 h-4 mr-2" />}
          />
        )
      }
    </div>
  );
}
