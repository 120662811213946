import { filter } from 'lodash';
import { AtcGroup, CoreInnProduct } from '../../products-module.types';

export function searchActiveIngredients(
  availableIngredients?: CoreInnProduct[],
  currentValues?: CoreInnProduct[],
  searchString = '',
): CoreInnProduct[] {

  const MAX_ENTRIES = 50;

  if (! availableIngredients) return [];

  let subValues: CoreInnProduct[] = filter(
    availableIngredients,
    (i: CoreInnProduct) => i.coreInnProduct.name.toLowerCase().includes(searchString.toLowerCase()),
  );

  if (subValues.length > MAX_ENTRIES + 1) {
    subValues =  subValues.slice(0, MAX_ENTRIES);
  }

  if (! currentValues) return subValues;

  // seems removing duplicates is not necessary

  return [
    ...subValues,
    ...currentValues.map((cv) => ({
      ...cv,
      value: cv.coreInnProduct.id,
      label: cv.coreInnProduct.name,
    })),
  ];
}

export function searchAtcGroup(
  availableGroups?: Array<AtcGroup & { value: number, label: string }>,
  currentValue?: AtcGroup,
  searchString = '',
): Array<AtcGroup & { value: number, label: string }> {

  const MAX_ENTRIES = 50;

  if (! availableGroups) return [];

  let subValues: Array<AtcGroup & { value: number, label: string }> = filter(
    availableGroups,
    (i: AtcGroup) => i.atcCodeName.toLowerCase().includes(searchString.toLowerCase()),
  );

  if (subValues.length > MAX_ENTRIES + 1) {
    subValues =  subValues.slice(0, MAX_ENTRIES);
  }

  if (! currentValue) return subValues;

  // seems removing duplicates is not necessary

  return [
    ...subValues,
    {
      ...currentValue,
      value: currentValue.id,
      label: currentValue.atcCodeName,
    },
  ];
}
