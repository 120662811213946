/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { cloneElement, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clone } from 'lodash';
import { useGetProfileByIdQuery, useUpdateProfileMetadataMutation } from './profilePageAPI';
import {
  setAllowPageEdit,
  setCurrentlyLoadedProfileID, setProfileOCurrentUser,
  updateModulesOrder,
} from './profilePageSlice';
import { useAppDispatch } from '../../app/hooks';

import DashboardLayout from '../dashboard-layout/DashboardLayout';
import ProfileHeader from './ProfileHeader';

import { RootState } from '../../app/store';
import ProfilePageLoading from './ProfilePageLoading';
import { useGetComponentsToRender } from './hooks/useGetComponentsToRender';
import { reorderModules } from './helpers';
import { useDocumentTitle } from '../../helpers/hooks';
import { COMPANY_NAME } from '../../helpers/branding';

export default function ProfilePage() {
  const list = useGetComponentsToRender();
  const dispatch = useAppDispatch();
  const history = useHistory();
  
  const { id: idFromURL } = useParams<{ id: string }>();
  const id = parseInt(idFromURL, 10);

  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);
  const { modulesMetadata, name, requesterHasEditRights } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);

  const [updateMetadata] = useUpdateProfileMetadataMutation();

  const allowPageEdit = requesterHasEditRights;

  const { data, isLoading } = useGetProfileByIdQuery({ id, requesterID: profileID }, {
    skip: !profileID || !id,
  });

  useDocumentTitle(`Profile of ${name || ''} | ${COMPANY_NAME}`);

  // todo: do not make request on load !
  // todo: extract to "prepare payload"
  useEffect(() => {
    if (! allowPageEdit) {
      return;
    }

    const { IDs, ...body } = modulesMetadata;

    // remove moduleID key
    const cleanKeys = {};
    Object.keys(body).forEach((key) => {
      // @ts-ignore
      const copy = clone(body[key]);

      if (! copy) return; // if metadata is null

      // @ts-ignore
      delete copy.moduleID;
      // @ts-ignore
      cleanKeys[key] = copy;
    });

    if (Object.keys(cleanKeys).length === 0) {
      return;
    }

    updateMetadata({
      id,
      requesterID: profileID,
      ...cleanKeys,
    });
  }, [updateMetadata, id, profileID, modulesMetadata, allowPageEdit]);

  useEffect(() => {
    dispatch(setAllowPageEdit(allowPageEdit));
  }, [allowPageEdit, dispatch]);

  useEffect(() => {
    if (id) dispatch(setCurrentlyLoadedProfileID(id));

    if (data) {
      dispatch(setProfileOCurrentUser(data));
    }
  }, [id, dispatch, data]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any = reorderModules(
      list,
      result.source.index,
      result.destination.index,
    );

    dispatch(updateModulesOrder(items.map((item: any) => item.id)));
  };

  let contentToRender = null;

  if (isLoading) {
    contentToRender = <ProfilePageLoading  />;
  }

  if (!isLoading && data == null) {
    history.push('/page404');
  }

  // todo: prepare list of Modules here
  // console.log(data.modulesMetadata);

  if (! contentToRender && ! allowPageEdit) {
    contentToRender = list
      .filter((item) => item.displayModule)
      .map((item) => (
        <div key={item.id}>
          {item.content}
        </div>
      ));
  } else if (! contentToRender) {
    // draggable
    contentToRender = (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list.map((item, index) => (
                item.content && (
                  <Draggable key={item.id} draggableId={item.id} index={item.key}>
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {cloneElement(item.content, {
                          dragHandleProps: provided.dragHandleProps,
                          // @ts-ignore
                          moduleMetadata: modulesMetadata[item.id],
                        })}
                      </div>
                    )}
                  </Draggable>
                )
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <DashboardLayout>
      <div>
        <ProfileHeader />

        { contentToRender }
      </div>
    </DashboardLayout>
  );
}
