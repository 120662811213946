import React, { useEffect, useRef, useState } from 'react';
import InfoMessage from '../../../elements/InfoMessage';
import MembersManagementSearch from './MembersManagementSearch';
import MembersManagementList from './MembersManagementList';
import AddMember from './AddMember';
import MembersInvitationsList from './MembersInvitationsList';

interface MembersInvitationsProps {
  corporationID: number,
  corporationName: string,
}

export default function MembersInvitations(props: MembersInvitationsProps) {

  const { corporationID, corporationName } = props;

  const scrollAnchor = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(null);

  useEffect(() => {
    if (scrollAnchor && scrollAnchor.current) {
      // @ts-ignore
      setScrollOffset(scrollAnchor.current.offsetTop + 4 * 10);
    }
  }, []);

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 ref={scrollAnchor} className="text-2xl font-semibold py-3 text-neutral-800">
        Add New Members
      </h2>
      <div className="mt-2 mb-4">
        <MembersInvitationsList 
          corporationID={corporationID} 
          scrollOffset={scrollOffset} 
          corporationName={corporationName}
        />
        <AddMember corporationID={corporationID} />
      </div>
    </div>
  );
}
