import React from 'react';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import ModuleLayout from '../../components/ModuleLayout';
import ProductsModuleView from './ProductsModuleView';
import ProductModuleEdit from './ProductModuleEdit';

interface ProductsModuleProps extends ModuleLayoutBaseProps {

}

export default function ProductsModule(props: ProductsModuleProps) {
  const { dragHandleProps, moduleMetadata, allowEditMetadataModule } = props;

  return (
    <ModuleLayout
      header="Products"
      onReset={() => {}}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      viewComponent={<ProductsModuleView />}
      editComponent={<ProductModuleEdit />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
