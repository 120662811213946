import { generateRandomID } from '../../../../helpers/functions';

export const EMPTY_FILE = {
  title: '',

  id: generateRandomID(),
  fileUrl: '',
  description: '',
  contentType: '',
  fileSizeBytes: 0,
  originalFilename: '',
  isPrivate: false,
  fileUuid: '',
};
