import React from 'react';
import { useSelector } from 'react-redux';
import { useGetForumQuestionsListQuery } from '../forumAPI';
import ForumQuestionListRender from './ForumQuestionListRender';
import { RootState } from '../../../app/store';
import { useAppDispatch } from '../../../app/hooks';
import { setListWithoutSearchPageRequest } from './forumQuestionsSlice';

export default function ForumQuestionsList() {

  const { getListRequest } = useSelector((state: RootState) => state.forum.questionsList);

  const dispatch = useAppDispatch();

  // todo: calculate values correctly and test
  // todo: onPress set page (that's it)
  // do not pass unnecessary props
  const { data, isFetching, isLoading } = useGetForumQuestionsListQuery({
    pageSize: getListRequest.pageSize,
    pageNumber: getListRequest.pageNumber,
    filters: {
      filter: {
        tagIds: getListRequest.filters.filter.tagIds,
        creatorProfilePageId: getListRequest.filters.filter.creatorProfilePageId,
      },
      sorting: getListRequest.filters.sorting,
    },
  });

  return (
    <ForumQuestionListRender
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
      setPage={(page) => dispatch(setListWithoutSearchPageRequest(page))}
    />
  );
}
