import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ForumQuestionCreate, ForumTagResponse } from '../forum-types';

interface PostForumQuestionState {
  createQuestionForm: ForumQuestionCreate,
}

const initialCreateQuestionForm = {
  tags: [],
  title: '',
  description: '',
};

const initialState: PostForumQuestionState = {
  createQuestionForm: initialCreateQuestionForm,
};

export const postQuestionSlice = createSlice({
  name: 'postQuestion',
  initialState,
  reducers: {

    setCreateQuestionTitle: (state, action: PayloadAction<string>) => {
      state.createQuestionForm.title = action.payload;
    },
    setCreateQuestionDescription: (state, action: PayloadAction<string>) => {
      state.createQuestionForm.description = action.payload;
    },
    setCreateQuestionTags: (state, action: PayloadAction<ForumTagResponse[]>) => {
      state.createQuestionForm.tags = action.payload;
    },
    resetCreateQuestionForm: (state) => {
      state.createQuestionForm = initialCreateQuestionForm;
    },
  },
});

export const {
  setCreateQuestionTitle,
  setCreateQuestionDescription,
  setCreateQuestionTags,
  resetCreateQuestionForm,

} = postQuestionSlice.actions;

export default postQuestionSlice;
