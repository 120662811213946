import {
  createSlice, PayloadAction, nanoid,
} from '@reduxjs/toolkit';
import {
  ChatWindowType, MessageSliceThread, SliceChatMessage, SliceChatMessageReceivedEvent, UserThreads,
} from './messages-types';
import { messagesAPI } from './messagesAPI';

const initChatWindow: ChatWindowType = {
  threadId: '',
  profilePageSummary: null,
  currentProfileAzureCommunicationID: '',
  userIsTyping: {
    flag: false,
    nanoid: '', 
  },
  threadMemberStatus: null,
};

export interface MessagesSliceState {
  disclosureIsOpen: boolean,
  currentProfileAzureCommunicationID: string,
  messageRequestsCount: number,

  threadIDs: string[],
  threads: {
    [threadID: string]: MessageSliceThread
  },
  currentChatWindow: ChatWindowType, // if thread set - window is opened

  chatMessageReceivedObservable: SliceChatMessage | null,
}

const initialState: MessagesSliceState = {
  disclosureIsOpen: false, // required to open window from different areas
  currentProfileAzureCommunicationID: '',
  messageRequestsCount: 0,

  threadIDs: [],
  threads: {},
  currentChatWindow: initChatWindow,

  chatMessageReceivedObservable: null,
};

export const messagesSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    setDisclosureIsOpen: (state, action: PayloadAction<boolean>) => {
      state.disclosureIsOpen = action.payload;
    },
    setThreads: (state, action: PayloadAction<UserThreads>) => {
      state.currentProfileAzureCommunicationID = action.payload.azureCommunicationIdentity || initialState.currentProfileAzureCommunicationID;
      state.currentChatWindow.currentProfileAzureCommunicationID = action.payload.azureCommunicationIdentity || initialState.currentChatWindow.currentProfileAzureCommunicationID;

      state.threadIDs = action.payload.chats.map((chat) => chat.azureThreadId);

      const threadsObj: { [threadID: string]: MessageSliceThread } = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const chat of action.payload.chats) {
        threadsObj[chat.azureThreadId] = {
          threadID: chat.azureThreadId,
          lastMessage: null,
          unreadCount: 0,
          chatMember: chat.chatMember,
          chatType: chat.chatType,
          lastMessageReceivedOnUtc: chat.lastMessageReceivedOnUtc,
          threadMemberStatus: chat.threadMemberStatus,
        };
      }

      state.threads = threadsObj;
    },
    setCurrentChatWindow: (state, action: PayloadAction<ChatWindowType>) => {
      state.currentChatWindow = action.payload;
    },
    resetCurrentChatWindow: (state) => {
      state.currentChatWindow = initChatWindow;
    },
    setLastMessage: (state, action: PayloadAction<{ threadID: string, message: SliceChatMessage }>) => {
      state.threads[action.payload.threadID] = {
        ...state.threads[action.payload.threadID],
        lastMessage: action.payload.message,
      };
    },
    // todo: must same event
    setUserIsTyping: (state, action: PayloadAction<{ threadID: string, senderID: string }>) => {
      if (state.currentChatWindow.threadId !== action.payload.threadID) return;

      // do not trigger typing if sender is receiver
      if (state.currentChatWindow.currentProfileAzureCommunicationID === action.payload.senderID) return;

      state.currentChatWindow.userIsTyping = {
        flag: true,
        nanoid: nanoid(),
      };
    },
    setUserIsNotTyping: (state, action: PayloadAction<{ threadID: string }>) => {
      if (state.currentChatWindow.threadId !== action.payload.threadID) return;

      state.currentChatWindow.userIsTyping = {
        flag: false,
        nanoid: '',
      };
    },

    // todo: filter events that should not be handled in any case here
    setChatMessageReceivedObservable: (state, action: PayloadAction<SliceChatMessageReceivedEvent>) => {
      state.chatMessageReceivedObservable = {
        ...action.payload,
        // @ts-ignore - bug on Azure side
        type: action.payload.type.toLowerCase(),
        content: {
          message: action.payload.message,
        },

      };

      // -> increment unread counter
      // @ts-ignore Azure types bug
      const { sender: { communicationUserId }, threadId } = action.payload;

      if (communicationUserId !== state.currentProfileAzureCommunicationID) {
        state.threads[threadId].unreadCount += 1;
      }

    },

    setThreadUnreadCounter: (state, action: PayloadAction<{ threadID: string, unreadCount: number }>) => {
      state.threads[action.payload.threadID].unreadCount = action.payload.unreadCount;
    },

  },
  extraReducers: (builder) => {
    builder
      .addMatcher(messagesAPI.endpoints.getChatInvitations.matchFulfilled,
        (state, { payload }) => {
          state.messageRequestsCount = payload.length > 0 ? 1 : 0; // max = 1
        });
  },
});

export const {
  setDisclosureIsOpen,
  setThreads,

  resetCurrentChatWindow,
  setCurrentChatWindow,

  setLastMessage,
  setUserIsTyping,
  setUserIsNotTyping,

  setChatMessageReceivedObservable,
  setThreadUnreadCounter,
} = messagesSlice.actions;

export default messagesSlice;
