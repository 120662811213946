import React from 'react';
import { BuildingOfficeIcon, UserIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { ProfilePageType } from '../app/account';

interface UserAvatarProps {
  profileImageUrl: string | null | undefined,
  profilePageType: ProfilePageType,
  size?: 'small' | 'medium' | 'bigger' | 'big', // tailwind numbers
  profileID?: number,
}
export default function UserAvatar(props: UserAvatarProps) {

  const {
    profileImageUrl, profilePageType: accountType, size = 'small', profileID,
  } = props;

  let widthHeight = 'w-8 h-8';
  let iconWidthHeight = 'w-8 h-8';

  if (size === 'medium') {
    widthHeight = 'w-12 h-12';
    iconWidthHeight = 'w-8 h-8';
  }

  if (size === 'bigger') {
    widthHeight = 'w-20 h-20';
    iconWidthHeight = 'w-12 h-12';
  }

  if (size === 'big') {
    widthHeight = 'w-32 h-32';
    iconWidthHeight = 'w-20 h-20';
  }

  const avatarImage = (
    <>
      {
        profileImageUrl ? (
          <img
            className={`${widthHeight} max-w-none object-cover rounded-${accountType === 'individual' ? 'full' : 'md'}`}
            src={profileImageUrl}
            alt="Avatar"
          />
        ) : (
          <div className={`${widthHeight} ${size === 'small' && 'p-5'} bg-main-75 rounded-${accountType === 'individual' ? 'full' : 'md'} flex justify-center items-center`}>
            <div className="text-main-600">
              {
                accountType === 'individual'
                  ? <UserIcon className={`${iconWidthHeight}`} />
                  : <BuildingOfficeIcon className={`${iconWidthHeight}`} />
              }
            </div>
          </div>
        )
      }
    </>
  );

  if (profileID) {
    return (
      <Link to={`/profile/${profileID}`}>
        {avatarImage}
      </Link>
    );
  }

  return avatarImage;
}
