import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';

export const contactUsAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    sendSupportMessage: builder.mutation<null, { topic: string, message: string }>({
      query(data) {
        return {
          url: '/Administration/support/message',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Message has been sent'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),
  }),
});

export const {
  useSendSupportMessageMutation,
} = contactUsAPI;
