import { emptySplitApi } from '../../app/api';
import {
  ForumQuestionCreate,
  ForumTag,
  ForumTagResponse,
  GetQuestionResponse,
  GetQuestionsRequest,
  GetQuestionsResponse, GetQuestionsSearchRequest, QuestionEntry,
} from './forum-types';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { resetCreateQuestionForm } from './post-question/postQuestionSlice';

export const forumAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // todo: check if all params are
    getForumQuestionsList: builder.query<GetQuestionsResponse, GetQuestionsRequest>({
      query: (data) => ({
        url: `/forum/questions?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`,
        method: 'POST',
        body: data.filters,
      }),
      providesTags: (result, error, arg) => [{
        type: 'ForumQuestionsList',
        pageNumber: arg.pageNumber,
        pageSize: arg.pageSize,
        filters: JSON.stringify(arg.filters),
      }],
    }),

    getForumQuestionsListSearch: builder.query<GetQuestionsResponse, GetQuestionsSearchRequest>({
      query: (data) => ({
        url: `/forum/search?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&searchInput=${data.searchInput}&searchIdentifier=${data.searchIdentifier}`,
        method: 'GET',
      }),
      transformResponse: (response: Omit<GetQuestionsResponse, 'data'> & { results: QuestionEntry[] }) => {
        const { results, ...res } = response;

        return {
          ...res,
          data: response.results,
        };
      },
      providesTags: (result, error, arg) => [{
        type: 'ForumQuestionsListSearch',
        pageNumber: arg.pageNumber,
        pageSize: arg.pageSize,
        searchInput: arg.searchInput,
      }],
    }),

    getForumQuestion: builder.query<GetQuestionResponse, { questionID: number, requesterProfilePageId: number }>({      
      query: ({ questionID, requesterProfilePageId }) => ({
        url: `/forum/question/${questionID}/requester-profile-page/${requesterProfilePageId}`,
      }),
      providesTags: (result, error, arg) => [{
        type: 'ForumQuestion',
        questionID: arg.questionID,
        requesterProfilePageId: arg.requesterProfilePageId,
      }],
    }),

    getForumTags: builder.query<Array<ForumTagResponse>, null>({
      query: () => ({
        url: '/forum/tags',
      }),
      providesTags: ['ForumTags'],
      // includes Atc Group also
      transformResponse: (response: ForumTag[]) => response.map((tag) => ({
        id: tag.id,
        value: tag.id, // duplicate for dropdown
        label: tag.name,
        usage: tag.usage,
      })),
    }),

    // todo: type response
    postQuestion: builder.mutation<any, { creatorProfileID?: number, form: ForumQuestionCreate }>({
      query(data) {
        const { creatorProfileID, form } = data;

        return {
          url: `/Forum/question/creator-profile-page/${creatorProfileID}`,
          method: 'POST',
          body: {
            title: form.title,
            content: form.description,
            tagIds: form.tags.map((tag) => tag.id),
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Question is posted successfully'));
          dispatch(resetCreateQuestionForm());
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      // TODO: invalidate correct tags!
      invalidatesTags: () => ['ForumQuestionsList'],
    }),

    // todo: type response
    editQuestion: builder.mutation<any, { questionID: number, requesterID?: number, form: ForumQuestionCreate }>({
      query(data) {
        const { requesterID, questionID, form } = data;

        return {
          url: `/Forum/question/${questionID}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: {
            title: form.title,
            content: form.description,
            tagIds: form.tags.map((tag) => tag.id),
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Question is updated successfully'));
          // dispatch(resetCreateQuestionForm());
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [
        { type: 'ForumQuestionsList' },
        {
          type: 'ForumQuestion',
          questionID: arg.questionID,
        },
      ],
    }),

    deleteQuestion: builder.mutation<any, { questionID: number, requesterID?: number }>({
      query: (data) => ({
        url: `/Forum/question/${data.questionID}/requester-profile-page/${data.requesterID}`,
        method: 'DELETE',
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Question is deleted successfully'));
          // dispatch(resetCreateQuestionForm());
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [
        { type: 'ForumQuestionsList' },
        // { type: 'ForumQuestion' },
      ],
    }),
  }),
});

export const {
  useGetForumQuestionsListQuery,
  useGetForumQuestionsListSearchQuery,
  useGetForumQuestionQuery,
  useGetForumTagsQuery,
  usePostQuestionMutation,
  useEditQuestionMutation,
  useDeleteQuestionMutation,
} = forumAPI;
