import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import Zoom from 'react-medium-image-zoom';
import corpPagesImg from './assets/corp-page-img.png';

export default function FeaturePages() {
  return (
    <div className="z-10 w-full flex flex-col xl:flex-row xl:flex-row-reverse max-w-6xl w-full px-4 md:px-6 xl:px-0 -mt-2">
      <Fade right>
        <div
          className="min-w-fit pr-0 md:pr-10 pl-6 xl:pl-0 my-6 xl:my-0 pt-0 xl:pt-20 xl:ml-8"
        >
          <h3 className="text-main-900 text-5.5xl font-extrabold" style={{ maxWidth: 530 }}>
            Personal and corporate pages
          </h3>
          <p className="text-neutral-700 text-base font-medium mt-6" style={{ maxWidth: 530 }}>
            Tailored personal and corporate Nucleo pages that showcase your business,
            the products and services you are offering, as well as those you seek.
          </p>
        </div>
      </Fade>

      <Fade left>
        <Zoom>
          <img
            className="feature-image"
            src={corpPagesImg}
            alt="Pages example"
          />
        </Zoom>
      </Fade>
    </div>
  );
}
