import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { ContactListEntry as ContactListEntryType } from '../contact-list.types';
import Button from '../../../elements/Button';
import { classNames } from '../../../helpers/styling';

interface ContactListEntryMenuProps {
  contact: ContactListEntryType
}

export default function ContactListEntryMenu(props: ContactListEntryMenuProps) {
  const { contact } = props;

  return (
    <Menu as="div" className="relative shrink-0">
      <Menu.Button as="div" className="">
        {() => ( // { open }
          <Button
            onClick={() => {}}
            size="medium"
            roundedFull
            inverted
            iconLeft={<EllipsisVerticalIcon className="w-4 h-4 text-neutral-800" />}
            description=""
          />
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items style={{ zIndex: 100, minWidth: 220 }} className="overflow-hidden origin-top-right absolute z-index-50 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => {}}
                className={classNames(active ? 'bg-main-50' : '', 'w-full block px-4 py-3 text-sm text-neutral-700 cursor-pointer')}
              >
                <Link
                  to={`/profile/${contact.profilePageSummary.profilePageId}`}
                >
                  <div className="flex flex-row items-center">
                    <ArrowTopRightOnSquareIcon className={`h-4 w-4 mr-4 text-neutral-800 ${active && 'text-main-500'}`} />

                    <span className="font-semibold text-sm">
                      Open profile
                    </span>
                  </div>
                </Link>
              </button>
            )}
          </Menu.Item>

        </Menu.Items>
      </Transition>
    </Menu>
  );
}
