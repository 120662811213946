import { groupBy } from 'lodash';
import { emptySplitApi } from '../../../../app/api';
import {
  AtcGroup,
  CoreInnProduct,
  CoreProductCategory,
  Dosage,
  GroupedProducts,
  Product,
  StrengthUnitFromQuery,
} from './products-module.types';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';
import prepareCreateEditPayload from './helpers';

export const productsModuleAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductsModule: builder.query<GroupedProducts, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/products/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'ProductsModule', profileID: arg.id }],
      transformResponse: (response: any) => {
        const groupedProducts: any = groupBy(response.products, 'productGroupId');

        const groupsWithProducts: any = {
          'noGroupProducts': {
            groupName: '',
            groupID: 0,
            products: [],
          },
          groupIDs: [],
          groups: [], // will be used or not ?
        };
        Object.keys(groupedProducts).forEach((key) => {
          if (key === 'null') {
            groupsWithProducts.noGroupProducts.products = groupedProducts[key];
            return;
          }

          groupsWithProducts.groupIDs.push(key);
          groupsWithProducts.groups.push({
            groupName: groupedProducts[key][0].productGroup.productGroupName, // unsafe accessing
            groupId: groupedProducts[key][0].productGroupId,
          });

          groupsWithProducts[key] = {
            groupName: groupedProducts[key][0].productGroup.productGroupName, // unsafe accessing
            groupId: groupedProducts[key][0].productGroupId,
            products: groupedProducts[key],
          };
        });

        return groupsWithProducts;
      },
    }),

    /* getProduct */
    getProduct: builder.query<Product, { profileID?: number, productID: number }>({
      query: ({
        profileID,
        productID,
      }) => ({
        url: `/modules/products/profile-page/${profileID}/product-id/${productID}`,
      }),
      providesTags: (result, error, arg) => [{
        type: 'ProductsModuleProduct',
        // profileID: arg.profileID,
        productID: arg.productID,
      }],
    }),

    /* deleteProduct */
    deleteProduct: builder.mutation<any, { profileID?: number, requesterID: number, productID: number }>({

      query(data) {

        const { profileID, requesterID, productID } = data;

        return {
          url: `/modules/products/profile-page/${profileID}/requester-profile-page/${requesterID}/product-id/${productID}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Product was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Product cannot be deleted. Please try again.'));
        }
      },

      invalidatesTags: () => ['ProductsModule'],
    }),

    /* createProduct */
    createProduct: builder.mutation<any, { profileID?: number, requesterID: number, createProductFormData: Product }>({

      query(data) {

        const { profileID, requesterID, createProductFormData } = data;

        const payload = prepareCreateEditPayload(createProductFormData);

        return {
          url: `/modules/Products/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'POST',
          body: payload,
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Product is created successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Product cannot be created. Please try again.'));
        }
      },

      invalidatesTags: () => ['ProductsModule', 'ProductGroups'], // todo: add other entities like ProductGroups
    }),
    /* updateProduct */
    updateProduct: builder.mutation<any, { profileID?: number, requesterID: number, createProductFormData: Product }>({

      query(data) {

        const { profileID, requesterID, createProductFormData } = data;

        const payload = prepareCreateEditPayload(createProductFormData);

        return {
          url: `/modules/Products/profile-page/${profileID}/requester-profile-page/${requesterID}/product-id/${payload.id}`,
          method: 'PUT',
          body: payload,
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Product is updated successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Product cannot be updated. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [{
        type: 'ProductsModuleProduct',
        // profileID: arg.profileID,
        productID: arg.createProductFormData.id,
      }, 'ProductsModule', 'ProductGroups'], // todo: add other entities like ProductGroups
    }),

    /* GROUPS */
    /* getProductGroups */
    getProductGroups: builder.query<{ id: number, productGroupName: string, productsCount: number }[], { profileID?: number, requesterID: number }>({
      query: ({ profileID, requesterID }) => ({
        url: `/productGroup/profile-page/${profileID}/requester-profile-page/${requesterID}`,
      }),
      providesTags: ['ProductGroups'],
    }),

    /* updateGroupName */
    updateGroupName: builder.mutation<any, { profileID?: number, groupID: number, productGroupName: string, requesterID: number }>({

      query(data) {

        const { 
          profileID, 
          groupID, 
          productGroupName, 
          requesterID,
        } = data;

        return {
          url: `/ProductGroup/${groupID}/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: {
            productGroupName,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Group name is updated successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Update went wrong. Please try again.'));
        }
      },

      invalidatesTags: () => ['ProductGroups'],
    }),

    /* deleteProductGroup */
    deleteProductGroup: builder.mutation<any, { profileID?: number, groupID: number, includeProducts: boolean, requesterID: number }>({

      query(data) {

        const { 
          profileID, 
          groupID,
          includeProducts, 
          requesterID,
        } = data;

        return {
          url: `/ProductGroup/${groupID}/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'DELETE',
          body: {
            includeProducts,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Group was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Group cannot be deleted. Please try again.'));
        }
      },

      invalidatesTags: () => ['ProductGroups', 'ProductsModule'],
    }),
    /* GROUPS */

    /* Get product fields values */
    getActiveIngredients: builder.query<CoreInnProduct[], null>({
      query: () => ({
        url: '/lists/CoreInnProduct',
      }),
      providesTags: ['ActiveIngredients'],
      transformResponse: (response: { innProductName: string, id: number }[]) => response.map((innProduct) => ({
        coreInnProduct: {
          id: innProduct.id,
          name: innProduct.innProductName,
        },
        value: innProduct.id, // duplicate for dropdown
        label: innProduct.innProductName,

        coreInnProductId: innProduct.id, // comes from backend
      })),
    }),

    getDosageForms: builder.query<Dosage[] | any[], null>({
      query: () => ({
        url: '/lists/CoreDosageForm',
      }),
      providesTags: ['DosageForm'],
      transformResponse: (response: { dosageFormName: string, id: number }[]) => response.map((dosageForm) => ({
        coreDosageForm: {
          ...dosageForm, // all other unused fields

          id: dosageForm.id,
          name: dosageForm.dosageFormName,
        },
        value: dosageForm.id, // duplicate for dropdown
        label: dosageForm.dosageFormName,

        coreDossageFormId: dosageForm.id, // comes from backend
      })),
    }),
    getStrengthUnits: builder.query<Array<StrengthUnitFromQuery>, null>({
      query: () => ({
        url: '/lists/CoreStrengthUnit',
      }),
      providesTags: ['StrengthUnit'],
      transformResponse: (response: { strengthUnitName: string, id: number }[]) => response.map((strengthUnit) => ({

        value: strengthUnit.id, // for dropdown
        label: strengthUnit.strengthUnitName,

        valueForStore: {
          coreStrengthUnit: {
            id: strengthUnit.id,
            name: strengthUnit.strengthUnitName,
          },

          coreStrengthUnitId: strengthUnit.id, // comes from backend
        }, // duplicate for dropdown
      })),
    }),

    getAtcCode: builder.query<Array<AtcGroup & { value: number, label: string }>, null>({
      query: () => ({
        url: '/lists/CoreAtcCode',
      }),
      providesTags: ['CoreAtcCode'],
      // includes Atc Group also
      transformResponse: (response: { atcCodeName: string, atcProductGroup: any, atcProductGroupId: number, id: number }[]) => response.map((atcCode) => ({
        atcCodeName: atcCode.atcCodeName,
        // atcProductGroup: atcCode.atcProductGroup, // will be null, only id comes
        id: atcCode.id,

        value: atcCode.id, // duplicate for dropdown
        label: atcCode.atcCodeName,
        atcProductGroup: {
          id: 10,
          name: 'N: Nervous system',
        },

        coreAtcCodeId: atcCode.id, // comes from backend
      })),
    }),

    getProductCategories: builder.query<CoreProductCategory[], null>({
      query: () => ({
        url: '/lists/CoreProductCategory',
      }),
      providesTags: ['CoreProductCategories'],
      transformResponse: (response: { productCategoryName: string, id: number }[]) => response.map((category) => ({
        coreProductCategory: {
          id: category.id,
          name: category.productCategoryName,
        },
        value: category.id, // duplicate for dropdown
        label: category.productCategoryName,

        coreProductCategoryId: category.id, // comes from backend
      })),
    }),
  }),
});

export const {
  useGetProductsModuleQuery,

  useLazyGetProductQuery,
  useDeleteProductMutation,
  useCreateProductMutation,
  useUpdateProductMutation,

  useGetProductGroupsQuery,
  useUpdateGroupNameMutation,
  useDeleteProductGroupMutation,

  useGetDosageFormsQuery,
  useGetStrengthUnitsQuery,
  useGetActiveIngredientsQuery,
  useGetAtcCodeQuery,
  useGetProductCategoriesQuery,
} = productsModuleAPI;
