import React, { useEffect, useRef } from 'react';
import {
  Switch,
  Route, Redirect, useHistory, useLocation,
} from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAppDispatch } from './app/hooks';

import { loginRequest } from './authConfig';
import { setToken, fetchAccount } from './app/account';

// import logo from './logo.svg';
// import Counter from './features/counter/Counter';
import LandingPage from './features/landing-page/LandingPage';
import ProfilePage from './features/profile-page/ProfilePage';
import FeedPage from './features/feed/FeedPage';
import Registration from './features/registration/Registration';
import SettingsPage from './features/settings/SettingsPage';
import ContactUsPage from './features/contact-us/ContactUsPage';

import GuardedRoute from './helpers/GuardedRoute/GuardedRoute';

import Page404 from './features/page404/Page404';

import './App.css';
import './tailwind-extend.scss';
import { RootState } from './app/store';
import PageLoader from './helpers/PageLoader';
import SearchResultsPage from './features/search-results/SearchResultsPage';
import ContactListPage from './features/contact-list/ContactListPage';
import AcceptInvitation from './features/settings/AcceptInvitation';
import AcceptOwnershipInvitation from './features/settings/AcceptOwnershipInvitation';
import WhatIsNucleoPage from './features/landing-page/what-is-nucleo/WhatIsNucleoPage';
import AboutNucleo from './features/landing-page/about-nucleo/AboutNucleo';
import ScrollToTopRouteWrap from './helpers/ScrollToTopRouteWrap';
import CancelPreRegister from './features/landing-page/CancelPreRegister';
import QuizPage from './features/landing-page/quiz-page/QuizPage';
import PrivacyPolicyPage from './features/landing-page/legal-pages/PrivacyPolicyPage';
import TermsOfUsePage from './features/landing-page/legal-pages/TermsOfUsePage';
import ContestRulesPage from './features/landing-page/legal-pages/ContestRulesPage';
import CookiesPolicyPage from './features/landing-page/legal-pages/CookiesPolicyPage';
import Forum from './features/forum/Forum';
import PostQuestion from './features/forum/post-question/PostQuestion';
import QuestionPage from './features/forum/question-page/QuestionPage';
import AdminCorporationsSettingsPage from './features/settings/AdminCorporationsSettingsPage';
import StatisticsPage from './features/statistics/StatisticsPage';
import FeedModeration from './features/feed-moderation/FeedModeration';
import Unsubsribe from './features/newsletter/Unsubscribe';
import TagsManagementPage from './features/tag/TagsManagementPage';
import { BRANDING } from './helpers/branding';
import IplsLandingPage from './features/landing-page/IplsLandingPage';
import AcceptRequestPage from './features/requests/AcceptRequestPage';

export default function Routes() {

  const {
    token,
  } = useSelector((state: RootState) => state.account);

  const history  = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const inProgressRef = useRef('none');
  const { inProgress, accounts, instance } = useMsal();

  // todo: find better way
  useEffect(() => {

    (async () => {

      // handle after redirect
      if (inProgressRef.current === 'handleRedirect' && inProgress === 'none') {

        // user logged in
        if (accounts.length > 0) {

          const accessToken = await instance.acquireTokenSilent({
            account: accounts[0],
            scopes: loginRequest.scopes,
          }).then((response) => {

            // InteractionRequiredAuthError https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4152
            if (!response.accessToken || response.accessToken === '') {
              throw new InteractionRequiredAuthError();
            }

            return response.accessToken;

            // return response.idToken ? response.idToken : null;
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              localStorage.setItem('AUTH_REDIRECT_URI', location.pathname);

              instance.acquireTokenRedirect(loginRequest).then((response) => {
                history.push(location.pathname); // reload
                return response;
              }).catch((err) => {
                // console.log(error);
              });
            }
          });

          dispatch(setToken(accessToken));
        }
      }

      inProgressRef.current = inProgress;
    })();

    // eslint-disable-next-line
  }, [inProgress, accounts]);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          const { payload: { isRegistered, individualProfilePageId } } = await dispatch(fetchAccount());

          const currentPath = location.pathname;

          let redirectURI = `/profile/${individualProfilePageId}`;
          const storedURI = localStorage.getItem('AUTH_REDIRECT_URI');
          if (storedURI) {
            redirectURI = storedURI;
            localStorage.removeItem('AUTH_REDIRECT_URI');
          }

          if (currentPath === '' || currentPath === '/') {
            const path = isRegistered ? redirectURI : '/registration';

            history.push(path);
          }

        } catch (e) {
          // todo: log
        }
      }
    })();

  // eslint-disable-next-line
  }, [token]);

  return (
    <>
      <PageLoader />
      <Switch>

        <Route exact path="/invitation/:invitationID">
          <AcceptInvitation />
        </Route>

        <Route exact path="/newsletter/:id">
          <Unsubsribe />
        </Route>

        <Route exact path="/corporation-ownership-invitation/:invitationID">
          <AcceptOwnershipInvitation />
        </Route>

        <Route exact path="/pre-registration/cancel">
          <CancelPreRegister />
        </Route>

        <GuardedRoute path="/profile/:id">
          <ProfilePage />
        </GuardedRoute>
        <GuardedRoute exact path="/feed">
          <FeedPage />
        </GuardedRoute>
        <GuardedRoute path="/feed/profile/:profileID">
          <FeedPage />
        </GuardedRoute>
        <GuardedRoute path="/moderate-feed">
          <FeedModeration />
        </GuardedRoute>
        <GuardedRoute path="/contacts">
          <ContactListPage />
        </GuardedRoute>
        <GuardedRoute path="/request/:requestType/:senderProfileID/:receiverProfileID/accept">
          <AcceptRequestPage />
        </GuardedRoute>
        <GuardedRoute exact path="/forum">
          <Forum />
        </GuardedRoute>
        <GuardedRoute path="/forum/post-question">
          <PostQuestion />
        </GuardedRoute>
        <GuardedRoute path="/forum/question/:id">
          <QuestionPage />
        </GuardedRoute>

        <GuardedRoute path="/registration">
          <Registration />
        </GuardedRoute>
        <GuardedRoute path="/settings">
          <SettingsPage />
        </GuardedRoute>
        <GuardedRoute path="/statistics">
          <StatisticsPage />
        </GuardedRoute>
        <GuardedRoute path="/tags-management">
          <TagsManagementPage />
        </GuardedRoute>

        <GuardedRoute path="/corporation-settings/:corporationID">
          <AdminCorporationsSettingsPage />
        </GuardedRoute>

        <GuardedRoute path="/contact-us">
          <ContactUsPage />
        </GuardedRoute>
        <GuardedRoute path="/search-results">
          <SearchResultsPage />
        </GuardedRoute>

        <Route path="/page404">
          <Page404 />
        </Route>

        <Route exact path="/">
          {
            BRANDING === 'NUCLEO' ? (
              <ScrollToTopRouteWrap>
                <LandingPage />
              </ScrollToTopRouteWrap>
            ) : <IplsLandingPage />
          }
        </Route>

        {
          BRANDING === 'NUCLEO' && (
          <>
            <Route exact path="/what-is-nucleo">
              <ScrollToTopRouteWrap>
                <WhatIsNucleoPage />
              </ScrollToTopRouteWrap>
            </Route>
            <Route exact path="/about-nucleo">
              <ScrollToTopRouteWrap>
                <AboutNucleo />
              </ScrollToTopRouteWrap>
            </Route>
            <Route exact path="/cphi-registration">
              <ScrollToTopRouteWrap>
                <QuizPage />
              </ScrollToTopRouteWrap>
            </Route>
          </>
          )
        }

        <Route exact path="/privacy-policy">
          <PrivacyPolicyPage />
        </Route>
        <Route exact path="/cookie-policy">
          <CookiesPolicyPage />
        </Route>
        <Route exact path="/terms-of-use">
          <TermsOfUsePage />
        </Route>
        <Route exact path="/contest-terms">
          <ContestRulesPage />
        </Route>

        <Redirect to="/page404" />
      </Switch>
    </>
  );

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <Counter />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //
  //     </header>
  //   </div>
  // );
}
