import { emptySplitApi } from '../../../../app/api';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';
import { ICoreFocus, IFocus } from './focus-types';
import { prepareFocusPayload } from './helpers';

export const focusAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getFocusModule: builder.query<{ coreFocusIDs: number[], coreFocuses: IFocus[] }, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/FocusCategory/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'FocusModule', profileID: arg.id }],
      transformResponse: (response: IFocus[]) => ({
        coreFocusIDs: response.map((s) => s.coreFocusCategoryId),
        coreFocuses: response,
      }),
    }),

    getCoreFocusesList: builder.query<ICoreFocus[], null>({
      query: () => ({
        url: '/CoreFocusCategory',
      }),
      providesTags: ['CoreFocusCategoriesList'],

    }),

    /* create focus */
    createFocus: builder.mutation<any, { profileID?: number, requesterID:  number, focus: IFocus }>({

      query(data) {

        const { profileID, requesterID, focus } = data;

        return {
          url: `/modules/FocusCategory/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'POST',
          body: prepareFocusPayload(focus, true),
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Focus is added successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Focus cannot be added. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{ type: 'FocusModule', profileID: arg.profileID }],
    }),

    /* update focus */
    updateFocus: builder.mutation<any, { profileID?: number, requesterID: number, focus: IFocus }>({

      query(data) {

        const { profileID, requesterID, focus } = data;

        return {
          url: `/modules/FocusCategory/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: prepareFocusPayload(focus, false),
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Focus is updated successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Focus cannot be updated. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{ type: 'FocusModule', profileID: arg.profileID }],
    }),

    /* delete focus */
    deleteFocus: builder.mutation<any, { profileID?: number, requesterID: number, focusID: number }>({

      query(data) {

        const { profileID, requesterID, focusID } = data;

        return {
          url: `/modules/FocusCategory/profile-page/${profileID}/requester-profile-page/${requesterID}/focus-category/${focusID}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Focus was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Focus cannot be deleted. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{ type: 'FocusModule', profileID: arg.profileID }],
    }),
  }),
});

export const {
  useGetFocusModuleQuery,
  useGetCoreFocusesListQuery,

  useCreateFocusMutation,
  useUpdateFocusMutation,
  useDeleteFocusMutation,
} = focusAPI;
