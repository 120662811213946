import React, { useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { RootState } from '../../../app/store';
import ErrorModal from '../../../elements/ErrorModal';
import { useAppDispatch } from '../../../app/hooks';
import { deleteAccount } from '../../../app/account';
import Button from '../../../elements/Button';

export default function AccountDelete() {

  const [openModal, setOpenModal] = useState(false);
  const [openAdminWarningModal, setOpenAdminWarningModal] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const { corporationDetails } = useSelector((state: RootState) => state.account);

  function handleLogout(instance: any) {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  const { instance } = useMsal();

  const dispatch = useAppDispatch();

  const isUniqueCorporationAdministrator = corporationDetails === null ? false : corporationDetails?.memberIsUniqueAdministrator;
  let accountRemovalWarningMessage = '';
  if (isUniqueCorporationAdministrator) {
    accountRemovalWarningMessage = `Oops! It looks like you are the only administrator of ${corporationDetails?.name}, and we appreciate your leadership! To ensure a smooth transition, please assign the administrator rights to another user before removing your account. This ensures that ${corporationDetails?.name} continues to thrive with a dedicated administrator. If you need assistance or have any questions, feel free to reach out to our support team. Thank you for your cooperation!`;
  }

  const openDeleteAccountModal: () => void = () => {
    if (isUniqueCorporationAdministrator) {
      setOpenAdminWarningModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const removeAccountMessage = 'Removing your account is an irreversible action. Once your account is deleted, all associated information will be permanently erased, without any possibility of retrieval.';

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        Account
      </h2>

      <div className="mt-4 font-semibold text-sm">
        <p>
          {removeAccountMessage}
        </p>
      </div>

      <div className="flex justify-end mt-4">
        <Button
          onClick={() => openDeleteAccountModal()}
          size="medium"          
          inverted
          iconLeft={<ExclamationTriangleIcon className="h-4 w-4 text-error-600 mr-2.5" />}
          description="Delete account"
          styles="py-4 px-6 font-semibold text-error-600"          
        />
      </div>

      { /* REMOVE ACCOUNT ADMIN WARNING MODAL */ }
      <ErrorModal
        show={openAdminWarningModal}
        setOpen={setOpenAdminWarningModal}
        actionButtonText="Delete account"
        title="Delete account"
        isLoading={deletionInProgress}
        description={accountRemovalWarningMessage}
      />

      { /* REMOVE ACCOUNT CONFIRMATION MODAL */ }
      <ErrorModal
        show={openModal}
        setOpen={setOpenModal}
        actionButtonText="Delete account"
        title="Delete account"
        isLoading={deletionInProgress}
        onActionClick={async () => {
          setDeletionInProgress(true);

          const resp = await dispatch(deleteAccount());

          setDeletionInProgress(false);

          if (resp.type.includes('rejected')) { // error
            return;
          }

          setOpenModal(false);
          handleLogout(instance);
        }}
        description={removeAccountMessage}
      />
    </div>
  );
}
