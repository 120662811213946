import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { setShowRegisterModalFlag } from '../../app/account';

export default function RegisterButton() {

  const dispatch = useAppDispatch();

  return (
    <button
      type="button"
      onClick={() => dispatch(setShowRegisterModalFlag(true))}
      className="inline-flex justify-center items-center text-main-900 font-semibold px-4 py-2 bg-white rounded-md"
    >
      Register
    </button>
  );
}
