import React from 'react';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import ModuleLayout from '../../components/ModuleLayout';
import BusinessMarketsModuleView from './BusinessMarketsModuleView';
import BusinessMarketsModuleEdit from './BusinessMarketsModuleEdit';

interface BusinessMarketsModuleProps extends ModuleLayoutBaseProps {

}

export default function BusinessMarketsModule(props: BusinessMarketsModuleProps) {
  const { dragHandleProps, moduleMetadata, allowEditMetadataModule } = props;

  return (
    <ModuleLayout
      header="Business Markets"
      onReset={() => {}}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      viewComponent={<BusinessMarketsModuleView />}
      editComponent={<BusinessMarketsModuleEdit />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
