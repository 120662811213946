import React from 'react';
import { htmlContestRules } from './Nucleo/ContestRules_CPHIFrankfurt_gonucleo.docx';
import { useDocumentTitle } from '../../../helpers/hooks';

export default function ContestRulesPage() {
  useDocumentTitle('Contest Rules');

  return (
    <iframe title="Terms of Use" style={{ width: '100%', height: '100vh' }} srcDoc={htmlContestRules} />
  );
}
