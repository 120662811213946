import * as React from 'react';

export default function EnterIcon(props: React.ComponentProps<'svg'>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.70712 16.7071C8.09765 16.3166 8.09765 15.6834 7.70712 15.2929L5.41422 13H11C14.866 13 18 9.86599 18 6V4C18 3.44771 17.5523 3 17 3C16.4478 3 16 3.44771 16 4V6C16 8.76142 13.7615 11 11 11H5.41422L7.70712 8.70711C8.09765 8.31658 8.09765 7.68342 7.70712 7.29289C7.3166 6.90237 6.68343 6.90237 6.29291 7.29289L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071L6.29291 16.7071C6.68343 17.0976 7.3166 17.0976 7.70712 16.7071Z" fill="currentColor" />
    </svg>
  );
}
