import React from 'react';
import { ArrowPathIcon, ArrowUpOnSquareIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import Button from '../../elements/Button';
import { useRePostMutation } from '../feed/feedAPI';
import { RootState } from '../../app/store';

interface RePostOverlayProps {
  postID: number,
  isRePosteMode: boolean,
  setRePostMode: (flag: boolean) => void,
}
export default function RePostOverlay(props: RePostOverlayProps) {

  const { postID, isRePosteMode, setRePostMode } = props;

  const { getListRequest } = useSelector((state: RootState) => state.feedModeration);

  const [rePost, { isLoading: isReposting }] = useRePostMutation();

  return (
    <Transition
      show={isRePosteMode}
    >
      <div className="absolute z-30 w-full h-full flex items-center justify-center border border-main-600 border-4 rounded rounded-lg">

        <Transition.Child
          enter="transition-opacity duration-125"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-125"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-white p-4 rounded-md shadow-lg relative z-10">
            <div className="py-2 flex flex-row items-center">
              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-error-25">
                <ArrowUpOnSquareIcon className="h-4 w-4 text-main-600" aria-hidden="true" />
              </div>
              <span className="pl-4 font-bold">Repost?</span>
            </div>
            <p style={{ maxWidth: 400 }} className="font-semibold text-sm mt-1">
              Reposting will update the post date and move it to the top of the feed.
            </p>
            <div className="flex flex-row justify-end mt-6">
              <Button
                onClick={() => setRePostMode(false)}
                roundedFull
                size="small"
                inverted
                description="Cancel"
                disabled={isReposting}
              />
              <div className="pl-2">
                <Button
                  onClick={async () => {
                    await rePost({ postID, moderationListRequest: getListRequest });
                    window.scrollTo(0, 0);
                  }}
                  iconLeft={
                    isReposting ? <ArrowPathIcon className="h-4 w-4 mr-2.5 animate-spin" /> : undefined
                  }
                  isLoading={isReposting}
                  size="small"
                  roundedFull
                  description="Yes, repost"
                />
              </div>
            </div>
          </div>
        </Transition.Child>

        <div className="bg-white/20 absolute w-full h-full backdrop-blur-sm" />
      </div>
    </Transition>
  );
}
