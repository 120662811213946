import { combineReducers } from '@reduxjs/toolkit';
import postQuestionSlice from './post-question/postQuestionSlice';
import answersSlice from './question-page/answers/answersSlice';
import forumQuestionSlice from './questions-list/forumQuestionsSlice';

export default combineReducers({
  postQuestion: postQuestionSlice.reducer,
  answers: answersSlice.reducer,
  questionsList: forumQuestionSlice.reducer,
});
