/* eslint-disable import/no-cycle */
import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';

// https://stackoverflow.com/questions/63923025/how-to-fix-circular-dependencies-of-slices-with-the-rootstate
import accountReducer from './account';

import profilePageReducer from '../features/profile-page/profilePageSlice';
import  { emptySplitApi } from './api';
// import { useReduxEffectReducer } from './use-redux-effect-reducer';

import flashMessagesReducer from '../features/flash-messages/flashMessagesSlice';
import profileModulesReducer from '../features/profile-page/modules/profileModulesReducer';
import feedReducer from '../features/feed/feedSlice';
import registrationReducer from '../features/registration/registrationSlice';
import createPostReducer from '../features/post/create-post/createPostSlice';
import searchSlice from '../features/search-results/searchSlice';
import messagesSlice from '../features/messages/messagesSlice';
import forumReducer from '../features/forum/forumReducer';
import feedModerationSlice from '../features/feed-moderation/feedModerationSlice';
import statisticsSlice from '../features/statistics/statisticsSlice';
import membersManagementSlice from '../features/settings/members-management/membersManagementSlice';
import membersInvitationsSlice from '../features/settings/members-management/membersInvitationsSlice';
// import { createPostApi } from '../features/post/create-post/createPostAPI';

export const store = configureStore({
  reducer: {
    // observeStore: useReduxEffectReducer,

    [emptySplitApi.reducerPath]: emptySplitApi.reducer,

    account: accountReducer,

    profilePage: profilePageReducer,

    profileModules: profileModulesReducer,
    flashMessages: flashMessagesReducer,
    // @ts-ignore TODO: check why
    feed: combineReducers({
      list: feedReducer,
      createPost: createPostReducer,
    }),
    registration: registrationReducer,

    [searchSlice.name]: searchSlice.reducer,
    [messagesSlice.name]: messagesSlice.reducer,
    [feedModerationSlice.name]: feedModerationSlice.reducer,
    forum: forumReducer,
    [membersManagementSlice.name]: membersManagementSlice.reducer,
    [membersInvitationsSlice.name]: membersInvitationsSlice.reducer,
    [statisticsSlice.name]: statisticsSlice.reducer,

    // API - TODO: try to move to feed
    // [createPostApi.reducerPath]: createPostApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> =
    ThunkAction<ReturnType, RootState, unknown, Action<string> >;
