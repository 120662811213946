import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryApp } from './baseQueryApp';

// initialize an empty api service
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQueryApp,
  reducerPath: 'baseApi',

  tagTypes: [
    'ProfilePage',

    'FeedPosts',
    'FeedModerationList',

    'ServicesModule',
    'CoreServicesList',

    'AboutModule',
    'AreaOfInterest',
    'BusinessMarketsModule',
    'TeamModule',

    'ProductsModule',
    'ProductsModuleProduct',
    'ProductGroups',
    'ActiveIngredients',
    'CoreProductCategories',
    'CoreAtcCode',
    'DosageForm',
    'StrengthUnit',

    'FocusModule',
    'CoreFocusCategoriesList',

    'SavedSearches',
    'SearchResults',

    'ContactList',
    'ContactListRequests',

    'ChatThreadsList',
    'ChatRequestsList',

    // settings
    'CorporationMembersList',
    'CorporationInfo',
    'NewsletterStatus',
    'EmailNotificationsStatus',
    'CorporationMemberInvitationsList',

    'ForumQuestionsList',
    'ForumQuestionsListSearch',
    'ForumQuestion',
    'ForumTags',
    'ForumQuestionAnswers',

    'CorporationStatistics',

    'CoreTags',
  ],
  endpoints: () => ({}),
});
