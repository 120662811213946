import { emptySplitApi } from '../../../../app/api';
import { AreaOfInterestResponse } from './area-of-interest-types';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';

export const areaOfInterestAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getInterestModule: builder.query<AreaOfInterestResponse, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/Interest/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),

      providesTags: (result, error, arg) => [{
        type: 'AreaOfInterest',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
    }),

    updateAreaOfInterestMarketsModule: builder.mutation<any, { id: number, requesterID: number, description: string, coreTagIds: number[] }>({

      query(data) {
        const { id, requesterID, ...body } = data;

        return {
          url: `/modules/Interest/profile-page/${id}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Area of interest module details are successfully updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Update went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [{
        type: 'AreaOfInterest',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
    }),
  }),
});

export const {
  useGetInterestModuleQuery,
  useUpdateAreaOfInterestMarketsModuleMutation,
} = areaOfInterestAPI;
