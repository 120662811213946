import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useGetCorporationMembersQuery } from '../../../settings/settingsAPI';
import { RootState } from '../../../../app/store';
import {
  updateDescription, initiateTeamModule, reorder, addMemberCard,
} from './teamModuleSlice';
import Textarea from '../../../../elements/Textarea';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetTeamModuleQuery, useUpdateTeamModuleMutation } from './teamModuleAPI';
import ModuleLoader from '../../components/ModuleLoader';
import Button from '../../../../elements/Button';
import ModuleInfoSupportMessage from '../../components/ModuleInfoSupportMessage';
import MemberEditCard from './MemberEditCard';

interface TeamModuleEditProps {
  setViewMode?: () => void
}

export default function TeamModuleEdit(props: TeamModuleEditProps) {

  const { setViewMode } = props;

  const dispatch = useAppDispatch();

  const { corporationDetails } = useSelector((state: RootState) => state.account);
  const {
    currentlyLoadedProfileID,
    profileOfCurrentUser: { corporationId },
  } = useSelector((state: RootState) => state.profilePage);

  const {
    description, teamMembersList,
  } = useSelector((state: RootState) => state.profileModules.teamModule);

  const {
    data: teamModuleData,
    // isLoading: teamModuleDataLoading,
    // isFetching: teamModuleDataLoadingFetching,
  } = useGetTeamModuleQuery({ id: currentlyLoadedProfileID }, { skip: ! currentlyLoadedProfileID });

  // for dropdown
  const { data: listOfMembers } = useGetCorporationMembersQuery(
    {
      corporationID: corporationId || 0,
    },
    { skip: !corporationId },
  );

  useEffect(() => {
    if (! listOfMembers || ! teamModuleData) return;
    if (! listOfMembers.data) return;

    dispatch(initiateTeamModule({ teamModuleData, listOfMembers: listOfMembers.data }));
  }, [currentlyLoadedProfileID, dispatch, teamModuleData, listOfMembers]);

  const [updateTeamModuleQuery, { isLoading: isUpdatingTeamModule }] = useUpdateTeamModuleMutation();

  const onSaveChanges = async () => {

    await updateTeamModuleQuery({
      profileID: currentlyLoadedProfileID,
      body: {
        description,
        moduleCorporationTeamMembers: teamMembersList,
      },
    });

    if (setViewMode) setViewMode();
  };

  if (! listOfMembers || ! teamModuleData) {
    return (
      <ModuleLoader />
    );
  }

  const onDragEnd = (result: any) => {
    if (! result.destination) {
      return;
    }

    dispatch(reorder({
      startIndex: result.source.index,
      endIndex: result.destination.index,
    }));
  };

  return (
    <div className="mt-4 pb-2 flex flex-col items-center">

      {/* Description */}
      <div className="w-3/5">
        <Textarea
          value={description}
          placeholder="Start typing..."
          onChange={(e) => dispatch(updateDescription(e.target.value))}
        />
      </div>

      <div className="w-3/5">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {[...teamMembersList].map((teamMember, index) => {
                  let draggableId = teamMember.id ? teamMember.id.toString() : teamMember.localID;
                  draggableId = draggableId || index.toString();

                  return (
                    <Draggable
                      key={teamMember.id || teamMember.localID}
                      draggableId={draggableId}
                      index={index}
                    >
                      {(providedInner) => (
                        <div
                          className="mt-2"
                          ref={providedInner.innerRef}
                          {...providedInner.draggableProps}
                        >
                          <MemberEditCard
                            teamMember={teamMember}
                            dragHandleProps={providedInner.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="flex flex-row mt-2 justify-end bg-main-50 w-3/5 rounded-md px-2 py-4">
        {/* <div className="text-right mr-6 flex flex-col justify-center"> */}
        {/*  <p className="text-sm font-semibold"> */}
        {/*    You can add up to 3 more members */}
        {/*  </p> */}
        {/*  <p className="text-xs text-neutral-500"> */}
        {/*    Would you like to add more members? &nbsp; */}
        {/*    <span className="text-main-600"> */}
        {/*      Upgrade your account */}
        {/*    </span> */}
        {/*  </p> */}
        {/* </div> */}
        <Button
          onClick={() => dispatch(addMemberCard())}
          size="small"
          roundedFull
          inverted
          iconLeft={<PlusSmallIcon className="w-4 h-4 mr-2" />}
          // disabled
          description="Add a member"
        />
      </div>

      {/* Action buttons */}
      <div className="w-3/5 mt-6 mb-4 flex justify-end">
        <div className="pr-2">
          <Button
            onClick={() => setViewMode && setViewMode()}
            size="medium"
            inverted
            description="Cancel"
            isLoading={isUpdatingTeamModule}
          />
        </div>
        <Button
          onClick={onSaveChanges}
          size="medium"
          description="Save changes"
          isLoading={isUpdatingTeamModule}
        />
      </div>

      <ModuleInfoSupportMessage />
    </div>
  );
}
