import React from 'react';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import ContactUsForm from './ContactUsForm';
import { useDocumentTitle } from '../../helpers/hooks';
import FaqMain from './FaqMain';
import { BRANDING } from '../../helpers/branding';

export default function ContactUsPage() {
  useDocumentTitle('Contact Us');

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <FaqMain />
        <ContactUsForm />
      </div>
    </DashboardLayout>
  );
}
