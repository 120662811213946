import React from 'react';
import { useHistory } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import Button from '../../elements/Button';
import ForumSearch from './ForumSearch';
import ForumQuestionsList from './questions-list/ForumQuestionsList';
import SectionHeader from '../../elements/SectionHeader';
import { useDocumentTitle } from '../../helpers/hooks';
import { RootState } from '../../app/store';
import ForumQuestionsListWithSearch from './questions-list/ForumQuestionsListWithSearch';

export default function Forum() {

  useDocumentTitle('Forum');

  const history  = useHistory();

  const { searchInput } = useSelector((state: RootState) => state.forum.questionsList.getListWithSearchRequest);

  return (
    <DashboardLayout>
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <SectionHeader title="All Questions">
          <Button
            onClick={() => history.push('/forum/post-question')}
            size="small"
            description="Ask Question"
            iconLeft={<PencilSquareIcon className="w-4 h-4 mr-2" />}
          />
        </SectionHeader>

        <ForumSearch />

        { searchInput ? <ForumQuestionsListWithSearch /> : <ForumQuestionsList />}
      </main>
    </DashboardLayout>
  );
}
