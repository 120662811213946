import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import FeatureItem from './FeatureItem';

export default function FeaturesPromoSection() {
  return (
    <div className="pt-12 max-w-6xl w-full px-4 md:px-6 md:mt-20 lg:mt-0 xl:px-0">
      <Fade top>
        <h1 className="text-main-900 font-extrabold text-4xl md:text-5xl lg:text-5.5xl max-w-2xl pb-6 lg:pb-12">
          The online workplace for the life sciences industry connecting people, products and services
        </h1>
      </Fade>

      <div className="max-w-2xl mb-24">
        <FeatureItem
          title="Network, connect and interact with the ‘right’ individuals"
          bullets={[
            'Match with the individuals most relevant to your business;',
            'Build a genuine and quality network by connecting with individuals with a mutual interest in the products or services you are seeking or offering;',
            'Share information and news of relevance with your network.',
          ]}
        />

        <FeatureItem
          title="Be efficient in your business development"
          bullets={[
            'Find the product or service you are looking for in a few clicks and connect directly with a real individual;',
            'Showcase your products or services to the broad life science community 24/7 at a fraction of the cost of exhibiting at a conference or industry fair;',
            'Attend conferences not to find potential contacts but to meet contacts you have already made on Nucleo.',
          ]}
        />

        <FeatureItem
          title="One single source of the truth"
          bullets={[
            'Real-time and accurate data directly from the provider as opposed to outdated, repackaged information from the public domain.',
          ]}
        />

        <FeatureItem
          title="Designed by the industry for the industry"
          bullets={[
            'Designed by industry professionals – not by event organisers or database administrators;',
            'The future of Nucleo to be determined by the industry and, most importantly, by you.',
          ]}
        />

        <FeatureItem
          title="No brokerage fee or commission"
          bullets={[
            'Cost-effective and accessible with a subscription-based model – we don’t take a cut of your deals;',
            'Free 3-month introductory period for all subscribers.',
          ]}
        />

        <FeatureItem
          title="Additional features"
          bullets={[
            'Messaging, interest groups, tailored news feeds and more to come.',
          ]}
        />
      </div>
    </div>
  );
}
