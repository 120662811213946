import React from 'react';
import { OnChangeValue, PropsValue } from 'react-select/dist/declarations/src/types';
import {
  ActionMeta, components, createFilter, OptionProps,
} from 'react-select';
import { useGetTerritoriesQuery } from '../businessMarketsModuleAPI';
import { flattenTerritories } from '../helpers';
import { FlattenTerritory } from '../business-markets-module-types';
import TerritoryFlag from './TerritoryFlag';
import AppSelect from '../../../../../elements/AppSelect';

import './markets-selection.scss';

interface MarketsSelectionProps {
  // todo: do not use any in future fix for multiple & single
  onChange: (newValue: OnChangeValue<FlattenTerritory, boolean> | any, actionMeta: ActionMeta<FlattenTerritory>) => void,
  defaultValues?: FlattenTerritory[],
  value?: PropsValue<FlattenTerritory>,
  isMulti?: boolean,
  validationMessage?: string,
  isClearable?: boolean,
  placeholder?: string | null,
}

export default function MarketsSelection(componentProps: MarketsSelectionProps) {

  const {
    onChange, isClearable, defaultValues, value, isMulti = true, validationMessage, placeholder,
  } = componentProps;

  const dropDownPlaceolder = placeholder || 'Select a market...';

  const { data: territories } = useGetTerritoriesQuery(null);

  const flatten = flattenTerritories(territories); // todo: memo or store in redux (create separate slice)

  // @ts-ignore
  const Option = ({ children, ...props }: OptionProps & { data: FlattenTerritory }) => {

    // optimization
    // eslint-disable-next-line no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    const { data } = newProps;

    return (
      <>
        <components.Option
          {...newProps}
          className={`react-select-option weight nesting-${data.nestingLevel}`}
        >
          <TerritoryFlag territoryName={data.label} isoAlpha3Code={data.isoAlpha3Code} />
          {' '}
          <span className="pl-2">
            {data.label}
          </span>

        </components.Option>
      </>
    );
  };

  return (
    <div>
      <AppSelect
        isMulti={isMulti}
        placeholder={dropDownPlaceolder}
        defaultValue={defaultValues}
        value={value}
        onChange={onChange}
        filterOption={createFilter({ ignoreAccents: false })} // optimization
        options={flatten}
        // @ts-ignore
        components={{ Option }}
        isClearable={isClearable}
      />
      {
        validationMessage && (
          <div className="bg-error-50 p-1 mt-2 mx-1">
            <p className="text-xs text-error-600">
              {validationMessage}
            </p>
          </div>
        )
      }
    </div>
  );
}
