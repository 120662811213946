import React from 'react';
import { useSelector } from 'react-redux';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../../app/store';
import { useAppDispatch } from '../../../../app/hooks';
import { useDeleteServiceMutation } from './servicesAPI';
import { displayCreateServiceForm, setDeleteServiceModalData } from './servicesModuleSlice';
import ServicesModuleView from './ServicesModuleView';
import ErrorModal from '../../../../elements/ErrorModal';
import Button from '../../../../elements/Button';
import ServiceCreateEditForm from './components/ServiceCreateEditForm';

interface ServiceModuleEditProps {
  setEditMode?: () => void,
}

export default function ServiceModuleEdit(props: ServiceModuleEditProps) {
  const { setEditMode } = props;

  const {
    deleteServiceModalData, createServiceViewDisplay,
  } = useSelector((state: RootState) => state.profileModules.servicesModule);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const [deleteServiceRequest, { isLoading: isDeletingService }] = useDeleteServiceMutation();

  const dispatch = useAppDispatch();

  const onServiceDeleteModalClose = () => {
    dispatch(setDeleteServiceModalData({
      service: null,
      showModal: false,
    }));
  };

  const onServiceDelete = async () => {
    if (! deleteServiceModalData.service || ! deleteServiceModalData.service.id) return;

    await deleteServiceRequest({
      requesterID,
      profileID: currentlyLoadedProfileID,
      serviceID: deleteServiceModalData.service.id,
    });

    onServiceDeleteModalClose();
  };

  return (
    <>
      <div className={`px-2 py-2 flex-row items-center ${createServiceViewDisplay ? '' : 'bg-neutral-25'}`}>
        {
          createServiceViewDisplay ? (
            <ServiceCreateEditForm formType="create" serviceID={0} />
          ) : (
            <div className="w-max flex flex-row gap-2">
              <Button
                onClick={() => dispatch(displayCreateServiceForm({
                  showForm: true,
                }))}
                size="small"
                description="Add a service"
                inverted
                roundedFull
                iconLeft={<PlusSmallIcon className="w-4 h-4" />}
              />
            </div>
          )
        }
      </div>
      <div className="w-full border-t" />

      <ServicesModuleView setEditMode={setEditMode} inEditMode />

      <ErrorModal
        withErrorBorder={false}
        show={deleteServiceModalData.showModal}
        setOpen={() => onServiceDeleteModalClose()}
        transparentBackground
        onActionClick={onServiceDelete}
        actionButtonText="Delete"
        // imageSrc={avatarUrl}
        title="Delete service?"
        description={`Deleting "${deleteServiceModalData.service?.coreServiceName}" service is an action that can’t be undone.`}
        isLoading={isDeletingService}
      />
    </>
  );
}
