import React, { ReactElement } from 'react';
import { CheckIcon, ExclamationTriangleIcon, LightBulbIcon } from '@heroicons/react/20/solid';

/*
 * Decide background color of a flash message by type
 */
export function colorByType(type: string): string {
  switch (type) {
    case 'success': return 'success-400';
    case 'warning': return 'warning-400';
    case 'neutral': return 'neutral-500';
    case 'error':   return 'error-400';
    case 'info':    return 'main-500';

    default: return 'neutral-500';
  }
}

export function iconByType(type: string, classNames: string = ''): ReactElement {
  switch (type) {
    case 'success': return <CheckIcon className={classNames} />;
    case 'warning': return <ExclamationTriangleIcon className={classNames} />;
    case 'neutral': return <LightBulbIcon className={classNames} />;
    case 'error':   return <ExclamationTriangleIcon className={classNames} />;
    case 'info':    return <LightBulbIcon className={classNames} />;

    default: return <LightBulbIcon className={classNames} />;
  }
}
