import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { 
  ChartItem, 
  Corporation, 
  Statistics, 
  GetCorporationStatisticsRequest,
} from './statistics-types';

function transformData(corporationStats: Statistics): any[] {
  const headerRow = ['not_used', corporationStats.totalUsersLabel, corporationStats.activeUsersLabel];
  const dataRows = corporationStats.values.map((chartItem: ChartItem) => [
    chartItem.dateLabel,
    chartItem.totalUsersCount,
    parseInt(chartItem.activeUsersCount, 10),
  ]);

  return [headerRow, ...dataRows];
}

export const settingsAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporationStatistics: builder.query<any[], GetCorporationStatisticsRequest>({

      query: ({ corporationID, requesterProfilePageId, period }) => ({
        url: `/Statistics/requester-profile-page-id/${requesterProfilePageId}/user-activity`,
        params: { 
          'period': period,
          'corporationId': corporationID,
        },
      }),

      providesTags: (result, error, arg) => [{
        type: 'CorporationStatistics',
        period: arg.period,
      }],
      
      transformResponse: (response: Statistics) => transformData(response),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        
        if (args.corporationID === undefined) {
          return;
        }

        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'Unable to load statistics. Please try again.'));
        }
      },
    }),

    getAllCorporations: builder.query<Corporation[], void>({

      query: () => ({
        url: '/Corporation/all',
      }),
      
      async onQueryStarted(args, { dispatch, queryFulfilled }) {        
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'Unable to fetch all corporations. Please try again.'));
        }
      },
    }),
  }),
});

export const {
  useGetCorporationStatisticsQuery,
  useGetAllCorporationsQuery,
} = settingsAPI;
