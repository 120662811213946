import { emptySplitApi } from '../../../../app/api';
import { AboutModuleState } from './aboutModuleSlice';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';
import { GetAboutModuleResponse } from './aboutModuleTypes';

export const aboutModuleAPI = emptySplitApi.injectEndpoints({

  endpoints: (builder) => ({
    getAboutModule: builder.query<GetAboutModuleResponse, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/about/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),

      providesTags: (result, error, arg) => [{
        type: 'AboutModule',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
    }),

    /*
     * TODO:
     *  check validation here with queryFn
     *  validate on save (middleware) -> onQueryStarted
     *  validate when input has changed after validation was run
     *  + check redux middlewares
     *  Assumption: validation factory (accepts key to validate specific field) -> return true/false
     *    if no param -> validate all. Object with functions ?
     */
    updateAboutModule: builder.mutation<any, { id: number, requesterID: number, body: Partial<AboutModuleState> }>({

      query(data) {
        const { id, requesterID, body } = data;

        const { fileAttachments, ...rest } = body;

        return {
          url: `/modules/about/profile-page/${id}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: {
            fileAttachments: fileAttachments ? fileAttachments.map(((f) => f.fileUuid)).filter((f) => Boolean(f)) : [],
            ...rest,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'About details are successfully updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Update went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [{
        type: 'AboutModule',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
    }),

    refetchAboutModule: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['AboutModule'],
    }),

  }),
});

export const {
  useGetAboutModuleQuery,
  useUpdateAboutModuleMutation,
  useRefetchAboutModuleMutation,
}  = aboutModuleAPI;
