import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PostAnswer from './PostAnswer';
import { AnswersSortFilters, GetQuestionResponse } from '../../forum-types';
import { useDeleteAnswerMutation, useGetQuestionAnswersQuery } from './answersAPI';
import { RootState } from '../../../../app/store';
import ModuleLoader from '../../../profile-page/components/ModuleLoader';
import AnswerEntry from './answer-entry/AnswerEntry';
import Pagination from '../../../../elements/pagination/Pagination';
import AnswersSorting from './AnswersSorting';
import ErrorModal from '../../../../elements/ErrorModal';
import { useAppDispatch } from '../../../../app/hooks';
import { resetDeleteAnswerModalData } from './answersSlice';

interface AnswersListProps {
  question: GetQuestionResponse,
}

export default function AnswersList(props: AnswersListProps) {

  const { question } = props;

  // todo: extract to the store
  // todo: reset page etc on every page load (if extracted)
  const [page, setPage] = useState<number>(1);
  const [sorting, setSorting] = useState<AnswersSortFilters>(AnswersSortFilters.mostVoted);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);
  const { deleteAnswerModalData } = useSelector((state: RootState) => state.forum.answers);

  const answersCacheKeys = {
    questionID: question.id,
    profileID,
    pageNumber: page,
    pageSize: 10,
    sorting,
  };

  const { data: answersList, isLoading, isFetching } = useGetQuestionAnswersQuery(answersCacheKeys, {
    skip: (! profileID) || (! profileID),
  });

  const [deleteAnswerRequest] = useDeleteAnswerMutation();

  const dispatch = useAppDispatch();

  const onAnswerDelete = async () => {
    await deleteAnswerRequest({
      profileID,
      answerID: deleteAnswerModalData.answerID,
    });

    dispatch(resetDeleteAnswerModalData());
  };

  let content = null;
  if (isLoading || isFetching) {
    content = (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-4 p-6">
        <ModuleLoader />
      </div>
    );
  }

  if (answersList) {
    content = (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-8">
        <div className="flex flex-row justify-between items-center px-6 py-4 border-b">
          <h6 className="font-bold text-lg pr-4 md:pr-0">Answers</h6>
          <AnswersSorting setSorting={setSorting} sorting={sorting} />
        </div>

        {
          answersList.totalRecords === 0 ? (
            <div className="flex w-full justify-center p-8">
              <span className="font-semibold text-neutral-600">No answers yet...</span>
            </div>
          ) : (
            <div>
              <div className="p-6">
                {
                  answersList.data.map((answer, idx) => (
                    <AnswerEntry
                      key={answer.id}
                      answersCacheKeys={answersCacheKeys}
                      answer={answer}
                      first={idx === 0}
                      last={idx === answersList.data.length - 1}
                    />
                  ))
                }
              </div>

              <div className="border-t p-6">
                <Pagination
                  pageSize={answersList.pageSize}
                  currentPage={answersList.pageNumber}
                  totalPages={answersList.totalPages}
                  totalRecords={answersList.totalRecords}
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber);
                    // todo: scroll to correct place
                    // window.scrollTo({
                    //   left: 0,
                    //   top: 0,
                    //   behavior: 'smooth',
                    // });
                  }}
                  label="Answers"
                />
              </div>
            </div>
          )
        }

      </div>
    );
  }

  return (
    <>
      <div className="mt-8">
        <PostAnswer question={question} />

        { content }
      </div>
      <ErrorModal
        withErrorBorder={false}
        show={deleteAnswerModalData.showModal}
        // timeout for animation to pass
        setOpen={() => setTimeout(() => dispatch(resetDeleteAnswerModalData()), 300)}
        transparentBackground
        onActionClick={onAnswerDelete}
        actionButtonText="Delete"
        title="Delete answer?"
        description={`Your answer "${deleteAnswerModalData.answerText}" will be deleted and this action cannot be undone.`}
      />
    </>

  );
}
