import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../helpers/styling';
import { DOTS } from './usePagination';

interface PagesNavProps {
  currentPage: number,
  paginationRange: (number | string)[],
  onPageChange: (page: number) => void,
}

export default function PagesNav(props: PagesNavProps) {

  const {
    currentPage, paginationRange, onPageChange,
  } = props;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
      {/* Left button */}
      <button
        type="button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={classNames(
          'relative inline-flex items-center rounded-l-md px-2 py-2',
          'text-neutral-400 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 focus:z-20 focus:outline-offset-0',
          currentPage === 1 ? 'bg-neutral-100' : '',
        )}
      >
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      {
        paginationRange.map((pageItem) => {
          if (pageItem === DOTS) {
            return (
              <span
                key={pageItem}
                className={classNames(
                  'relative inline-flex items-center px-4 py-2 text-sm font-semibold',
                  'text-neutral-700 ring-1 ring-inset ring-neutral-300 focus:outline-offset-0',
                )}
              >
                ...
              </span>
            );
          }

          return (
            <button
              key={pageItem}
              type="button"
              onClick={() => onPageChange(pageItem as number)}
              className={classNames(
                'relative inline-flex items-center px-4 py-2 text-sm font-semibold',
                '',
                pageItem === currentPage
                  ? 'bg-main-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600'
                  : 'text-neutral-900 hover:bg-neutral-50 focus:z-20 focus:outline-offset-0 ring-1 ring-inset ring-neutral-300',
              )}
            >
              {pageItem}
            </button>
          );
        })
      }

      {/* Right button */}
      <button
        type="button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === lastPage}
        className={classNames(
          'relative inline-flex items-center rounded-r-md px-2 py-2',
          'text-neutral-400 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 focus:z-20 focus:outline-offset-0',
          currentPage === lastPage ? 'bg-neutral-100' : '',
        )}
      >
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </nav>
  );
}
