import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EnterIcon from '../../../elements/icons/EnterIcon';

import { classNames } from '../../../helpers/styling';
import SavedSearchesItem from './SavedSearchesItem';
import { useGetSavedSearchesQuery } from '../searchAPI';
import { useAppDispatch } from '../../../app/hooks';
import { clearAllFilters, setSearchString } from '../searchSlice';
import { RootState } from '../../../app/store';
import SaveSearch from './elements/SaveSearch';

import './index.scss';

// todo: add search string to URL
export default function ApplicationSearch() {

  const dispatch = useAppDispatch();
  const history  = useHistory();
  const location = useLocation();

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => {};

  const {
    currentSearchString,
  } = useSelector((state: RootState) => state.search);

  const [searchString, setLocalSearchString] = useState(currentSearchString);

  const { data, isLoading } = useGetSavedSearchesQuery(null);

  const submitSearch = (input: string) => { // same in SavedSearchesWidget

    // Clean up filters before submitting a new search
    dispatch(clearAllFilters());
    
    setLocalSearchString(input);
    dispatch(setSearchString(input));
    setFocused(false);

    if (! location.pathname.includes('search-results')) {
      history.push('/search-results');
    }
  };

  const onSearchSubmit = (event: KeyboardEvent) => {
    if (event.key !== 'Enter' || searchString === '') return;

    submitSearch(searchString);
  };

  useEffect(() => {
    if (focused) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [focused]);

  let savedSearches = null;
  if (data && data.length > 0) {
    savedSearches = data.map((search) => (
      <SavedSearchesItem
        key={search.id}
        id={search.id}
        date={search.searchCreationDateUtc}
        // date={search.timestamp}
        searchString={search.input}
        saved
        onClick={() => submitSearch(search.input)}
      />
    ));
  }

  if (! data || data.length === 0) {
    savedSearches = (
      <div className="pl-4">
        <span className="text-sm text-neutral-400">
          No saved searches yet
        </span>
      </div>
    );
  }

  return (
    <>
      {/* Overlay */}
      <div
        role="none"
        onClick={() => {
          setFocused(false);
          dispatch(setSearchString(searchString));
        }}
        className={classNames(
          'application-search-overlay z-40 fixed bg-white h-screen w-full top-0 left-0 transition-opacity',
          focused ? 'opacity-80' : 'opacity-0 pointer-events-none',
        )}
      />

      {/* Search input */}
      <div className={classNames(
        'application-search flex-1 flex items-center px-2 ml-6 absolute',
        focused ? 'focused bg-white shadow-2xl h-screen z-50 flex flex-col md:max-w-none px-24 top-0 pt-2.5' : 'md:max-width-md top-2.5',
      )}
      >
        {/* Search Input */}
        <div className="w-full">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">

            <SaveSearch searchString={searchString} />

            <input
              id="search"
              name="search"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-main-500 focus:border-main-500 sm:text-sm"
              placeholder="Search"
              onChange={(e) => setLocalSearchString(e.target.value)}
              value={searchString}
              onFocus={onFocus}
              onKeyUp={onSearchSubmit}
              onBlur={onBlur}
            />

            {
              focused && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <EnterIcon className="h-5 w-5 text-main-700" aria-hidden="true" />
                </div>
              )
            }

            {/* TODO: finish */}
            {/* { */}
            {/*  // clear input */}
            {/*  (! focused && searchString) && ( */}
            {/*    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-40"> */}
            {/*      <Button */}
            {/*        onClick={() => setLocalSearchString('')} */}
            {/*        size="small" */}
            {/*        description="" */}
            {/*        iconLeft={<XCircleIcon className="text-white" />} */}
            {/*        roundedFull */}
            {/*        inverted */}
            {/*      /> */}
            {/*    </div> */}
            {/*  ) */}
            {/* } */}
          </div>
        </div>

        {
          focused && (
            <div className="w-full pt-12">
              <h3 className="text-sm font-bold px-4 pt-3 pb-2 text-neutral-800 text-left">
                Saved searches
              </h3>
              {savedSearches}
            </div>
          )
        }
      </div>
    </>
  );
}
