import React from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import PersonalDetails from './personal-details/PersonalDetails';
import AccountDelete from './account-delete/AccountDelete';
import MembersManagement from './members-management/MembersManagement';
import { RootState } from '../../app/store';
import CorporateDetails from './corporate-details/CorporateDetails';
import { useDocumentTitle } from '../../helpers/hooks';
import Newsletter from './newsletter/Newsletter';
import CorporationDelete from './corporation-delete/CorporationDelete';
import EmailNotifications from './email-notifications/EmailNotifications';
import MembersInvitations from './members-management/MembersInvitations';

export default function SettingsPage() {

  const {
    currentProfile, corporationDetails,
  } = useSelector((state: RootState) => state.account);

  useDocumentTitle('Settings');

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white shadow-sm rounded-md">
          <h2 className="text-3xl font-bold p-6 text-neutral-800">
            Settings
          </h2>
        </div>        
        {          
          currentProfile.profilePageType === 'corporation' && corporationDetails ? (
            <>
              <CorporateDetails corporationID={corporationDetails.corporationId} corporationNameParam={corporationDetails.name} />
              <MembersManagement corporationID={corporationDetails.corporationId} corporationName={corporationDetails.name} />
              <MembersInvitations corporationID={corporationDetails.corporationId} corporationName={corporationDetails.name} />
              <CorporationDelete corporationID={corporationDetails.corporationId} />
            </>
          ) : (
            <>
              <PersonalDetails />
              <EmailNotifications />
              <Newsletter />
              <AccountDelete />              
            </>
          )
        }
      </div>
    </DashboardLayout>

  );
}
