import React from 'react';
import { useSelector } from 'react-redux';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useGetInterestModuleQuery } from './areaOfInterestAPI';
import { RootState } from '../../../../app/store';
import ModuleLoader from '../../components/ModuleLoader';
import Button from '../../../../elements/Button';
import Tag from '../../../../elements/Tag';

interface AreaOfInterestModuleViewProps {
  setEditMode?: () => void
}

export default function AreaOfInterestModuleView(props: AreaOfInterestModuleViewProps) {

  const { setEditMode } = props;

  // TODO: extract into hook???!!
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);
  const { currentlyLoadedProfileID, allowPageEdit } = useSelector((state: RootState) => state.profilePage);

  const { data, isLoading, isFetching } = useGetInterestModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (!currentlyLoadedProfileID) || (!requesterID) },
  );

  if (isLoading || isFetching || (! data)) {
    return (
      <ModuleLoader />
    );
  }

  const noDataFlag = allowPageEdit
    && (! data
      || (data && (! data.description && data.coreTags.length === 0))
    );

  if (noDataFlag) { // empty state
    return (
      <>
        <div className="px-2 py-2 bg-neutral-25 flex-row items-center">
          <div className="w-max">
            <Button
              onClick={() => {
                if (! setEditMode) return;
                setEditMode();
              }}
              size="small"
              description="Add the area of interested module"
              inverted
              roundedFull
              iconLeft={<PlusSmallIcon className="w-4 h-4" />}
            />
          </div>
        </div>
        <div className="w-full pt-4 border-t" />
      </>
    );
  }

  return (
    <div className="pt-6 pb-8 px-10">
      <div className="font-semibold text-sm whitespace-pre-line">
        {data.description}
      </div>

      {
        data.coreTags.length > 0 ? (
          <p className="pt-4 text-xs">
            Tags
          </p>
        ) : null
      }

      <div className="pt-1 flex flex-wrap gap-1">
        {
          data.coreTags.map((tag) => (
            <Tag key={tag.name} name={tag.name} />
          ))
        }
      </div>
    </div>
  );
}
