import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../../authConfig';
import { addFlashMessage } from '../../flash-messages/flashMessagesSlice';

export const deleteCorporation = createAsyncThunk(
  'corporation/delete',
  async ({ corporationID }: { corporationID: number | undefined }, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`${API_URL}/Corporation/${corporationID}`);
    } catch (e) {
      // @ts-ignore
      dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
      return rejectWithValue({
        message: 'Something went wrong. Please try again.',
      });
    }
    return undefined;
  },
);
