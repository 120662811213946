import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import { RootState } from '../../../../../app/store';
import { useAppDispatch } from '../../../../../app/hooks';
import { hideDeleteGroupModalData, resetDeleteGroupModalData } from '../productsModuleSlice';
import { useDeleteProductGroupMutation } from '../productsModuleAPI';
import Button from '../../../../../elements/Button';

// copy of ErrorModal
export default function GroupDeleteModal() {

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const {
    deleteGroupModalData,
  } = useSelector((state: RootState) => state.profileModules.productsModule);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const [deleteProducts, setDeleteProducts] = useState(false);

  const dispatch = useAppDispatch();

  const [deleteGroup, { isLoading: isDeletingGroup }] = useDeleteProductGroupMutation();

  const onClose = () => {

    dispatch(hideDeleteGroupModalData());

    setTimeout(() => {
      dispatch(resetDeleteGroupModalData());
      setDeleteProducts(false);
    }, 300); // animation duration
  };

  const onDelete = async () => {

    await deleteGroup({
      profileID: currentlyLoadedProfileID,
      groupID: deleteGroupModalData.groupID,
      includeProducts: deleteProducts,
      requesterID: profileID,
    });

    onClose();
  };

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={deleteGroupModalData.showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-600 opacity-80" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg p-12 text-left overflow-hidden shadow-lg transform transition-all sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto shrink-0 flex items-center justify-center h-24 w-24 rounded-full bg-error-25">
                  <ExclamationTriangleIcon className="h-12 w-12 text-error-600" aria-hidden="true" />
                </div>
                <div className="mt-3 ml-10 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" style={{ maxWidth: 450 }} className="text-xl leading-6 py-3 font-bold text-neutral-800">
                    Delete &quot;
                    {deleteGroupModalData.groupName}
                    &quot; Group?
                  </Dialog.Title>
                  <div className="mt-2 font-semibold text-sm max-w-md">
                    Do you want to delete the group and its products, or only the group?
                    If you choose to delete only the group, products will remain ungrouped.
                  </div>
                  <div className="mt-6 p-4 border border-error-500 rounded-md flex items-center">
                    {/* Toggle */}
                    {/* TODO: use design systtem switch */}

                    <Switch
                      checked={deleteProducts}
                      onChange={setDeleteProducts}
                      className={`${
                        deleteProducts ? 'bg-error-500' : 'bg-neutral-200'
                      } relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
                    >
                      <span className="sr-only">Delete related products</span>
                      <span
                        className={`${
                          deleteProducts ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transition-transform transform bg-white rounded-full`}
                      />
                    </Switch>
                    <span className="font-semibold text-neutral-800 pl-3">
                      Delete also its products
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <Button
                  onClick={onClose}
                  size="medium"
                  inverted
                  description="Cancel"
                  disabled={isDeletingGroup}
                />
                <Button
                  onClick={onDelete}
                  size="medium"
                  fullRed
                  iconLeft={<ExclamationTriangleIcon className="h-4 w-4 text-white mr-2.5" />}
                  description="Delete"
                  styles="ml-3"
                  isLoading={isDeletingGroup}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
