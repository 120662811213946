import { RuleTypes, RulesForFieldsType, ValidationError } from '../../helpers/validation';

export type ValidationKeys = 'inputValue';

export const rulesTagName: RulesForFieldsType<ValidationKeys> = {
  inputValue: {
    userFriendlyFieldName: 'Tag Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 50,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  inputValue: { errorMessage: '' },
};
