import { clone, isArray } from 'lodash';
import {
  HolderOwner, Licence, Presentation, Product,
} from './products-module.types';

export default function prepareCreateEditPayload(createProductFormData: Product) {

  const payload: any = clone(createProductFormData);
  delete payload.createdAtUtc;
  delete payload.updatedAtUtc;

  Object.keys(payload).forEach((key) => {
    if ((! payload[key]) && payload[key] !== 0) { // clear empty values
      delete payload[key];
    }

    if (isArray(payload[key]) && payload[key].length === 0) { // clear empty arrays
      delete payload[key];
    }

    if (key === 'licences') { // remove empty licences
      payload[key] = payload[key].filter((licence: Licence) => licence.licenceNumber);
    }

    if (key === 'presentations') { // remove empty presentations
      payload[key] = payload[key]
        .filter((presentation: Presentation) => presentation.presentation
          || (presentation.dossages && presentation.dossages.length > 0))
        .map((presentation: Presentation) => {  // map dosages to the payload form
          console.log('');

          return {
            ...presentation,
            dossages: presentation.dossages.map((dosage) => {
              let strengthID = dosage.coreStrengthUnit && dosage.coreStrengthUnit.coreStrengthUnitId
                ? dosage.coreStrengthUnit.coreStrengthUnitId
                // @ts-ignore
                : dosage.coreStrengthUnitId;

              if (! strengthID) {
                strengthID = dosage.coreStrengthUnit?.id;
              }

              return {
                coreDossageFormId: dosage.coreDosageForm.id, // todo: or dosage?
                coreStrengthUnitId: strengthID,

                moduleProductInnStrengths: dosage.moduleProductInnStrengths,
              };
            }),
          };
        });
    }

    if (key === 'holderOwners') { // remove empty holder fields
      payload[key] = payload[key].filter((holderOwner: HolderOwner) => holderOwner.authHolderProdOwner);
    }

  });

  if (payload.productGroupId) { // fix for the backend bug
    delete payload.productGroup;
  }

  return payload;
}
