import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  PhotoIcon, UserIcon, BuildingOfficeIcon,
} from '@heroicons/react/20/solid';
import { useParams, Link } from 'react-router-dom';
import { RootState } from '../../app/store';
import { useGetProfileByIdQuery } from './profilePageAPI';
import { useAppDispatch } from '../../app/hooks';
import { uploadProfileImage, ProfilePageImageType } from './profilePageSlice';
import ShowQRButton from './components/ShowQRButton';
import ProfileContactButton from './components/ProfileContactButton';
import ProfileFollowButton from './components/ProfileFollowButton';
import MessageButton from './components/MessageButton';
import DownloadProfileButton from './components/DownloadProfileButton';
import Badge from '../../elements/Badge';
import ForumQuestions from './components/ForumQuestions';
import AdminManageCorporation from './components/AdminManageCorporation';
import ImageUploadModal from './elements/ImageUploadModal';
import FeedPosts from './components/FeedPosts';

const fileToDataUri = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    if (! event.target) {
      reject(Error);
    }

    resolve(event.target);
  };
  reader.readAsDataURL(file);
});

export default function ProfileHeader() {

  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);

  const { allowPageEdit, currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  const dispatch = useAppDispatch();

  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showCoverImageUploadModal, setShowCoverImageUploadModal] = useState(false);

  // todo: reuse code somehow
  // @ts-ignore
  let { id } = useParams();
  id = parseInt(id, 10);

  const { data } = useGetProfileByIdQuery({ id: currentlyLoadedProfileID || 0, requesterID: profileID || 0 }, {
    skip: (! currentlyLoadedProfileID) || (! profileID),
  });

  const [profileImageURI, setProfileImageURI] = useState('');

  const [coverImageURI, setCoverImageURI] = useState('');

  const handleImageUploadModalClose = (imageData: string, imageType: ProfilePageImageType) => {
    if (imageType === ProfilePageImageType.profile) {
      setProfileImageURI(imageData);
    } else {
      setCoverImageURI(imageData);
    }
  };

  // todo: if no image render default placeholder
  useEffect(() => {
    if (! data) return;
    setProfileImageURI(data.profileImageUrl);
    setCoverImageURI(data.coverImageUrl);
  }, [data]);

  return (
    <div>
      <div className="relative flex justify-center items-end">
        {
          coverImageURI ? (
            <img className="rounded-b-md lg:rounded-b-lg max-w-5xl h-36 md:h-48 w-full object-cover lg:h-72" src={coverImageURI} alt="Profile cover" />
          ) : (
            <div className="rounded-b-md lg:rounded-b-lg max-w-5xl h-36 md:h-48 w-full bg-neutral-200 lg:h-72" />
          )
        }

        <div className="absolute w-full flex justify-end max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
          {
            allowPageEdit && (
              <button
                type="button"
                onClick={() => {
                  setShowCoverImageUploadModal(true);
                }}
                className="absolute bottom-5 right-8 border border-neutral-200 inline-flex items-center px-4 py-2 text-sm rounded-md bg-opacity-90  bg-white hover:bg-opacity-95 transition"
              >
                <PhotoIcon className="-ml-1 mr-2 h-5 w-5 text-main-600" aria-hidden="true" />
                <span className="text-sm text-main-600 font-bold">Edit header</span>
              </button>
            )
          }
        </div>

      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 md:-mt-16 md:flex md:items-end md:space-x-5">
          <div className="flex relative justify-center">
            {
              profileImageURI ? (
                <img
                  className={`h-24 w-24 rounded-${data && (data.accountType === 'corporation') ? 'lg' : 'full'} object-cover ring-4 ring-white sm:h-36 sm:w-36`}
                  src={profileImageURI}
                  alt="User's avatar"
                />
              ) : (
                <div>
                  {
                    // render corporate vs individual placeholder
                    (data && (data.accountType === 'corporation')) ? (
                      <div className="flex h-24 w-24 rounded-lg object-cover ring-4 ring-white sm:h-36 sm:w-36 bg-main-75 p-4">
                        <BuildingOfficeIcon className="text-main-400" />
                      </div>
                    ) : (
                      <div className="flex h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-36 sm:w-36 bg-main-75 p-4">
                        <UserIcon className="text-main-400" />
                      </div>
                    )
                  }
                </div>
              )
            }

            {
              allowPageEdit && (
                <button
                  type="button"
                  onClick={() => {
                    setShowImageUploadModal(true);
                  }}
                  className="absolute bottom-1 border border-neutral-200 inline-flex items-center px-4 py-2 text-sm rounded-md bg-opacity-90  bg-white hover:bg-opacity-95 transition"
                >
                  <PhotoIcon className="-ml-1 mr-2 h-5 w-5 text-main-600" aria-hidden="true" />
                  <span className="text-sm text-main-600 font-bold">Edit image</span>
                </button>
              )
            }
          </div>
          <div className="mt-6 md:flex-1 md:min-w-0 md:flex md:items-center md:justify-end md:space-x-6 md:pb-1">
            <div className="mt-12 min-w-0 flex-1">

              <div className="flex flex-row items-center">
                <h1 className="text-2xl font-bold text-neutral-700">{data && data.name}</h1>

                {
                  data && <Badge badges={data.badges} />
                }
              </div>
              { /* show company set in dynamic fields */ }
              {
                data && data.showCompany && data.company && (
                  <p className="text-sm font-semibold text-neutral-600 table-cell">                    
                    {data.company}
                  </p>
                )
              }
              { /* show job position set in dynamic fields if company is also set */ }
              {
                data && data.showCompany && data.company && data.showJobPosition && data.jobPosition && (
                  <p className="text-xs font-semibold text-neutral-600">                    
                    {data.jobPosition}
                  </p>
                )
              }
              { /* show related corporation name */ }
              {
                data && !data.showCompany && data.relatedCorporationProfilePageSummary && (
                  <Link to={`/profile/${data.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                    <p className="text-xs font-semibold text-neutral-600 table-cell">
                      {data.relatedCorporationProfilePageSummary.name}
                    </p>
                  </Link>
                )
              }
              {
                data && data.parentAssociationName && (
                  <p className="text-xs font-semibold text-neutral-600">
                    Member of the
                    {' '}
                    {data.parentAssociationName}
                    {' '}
                    corporation
                  </p>
                )
              }
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 md:flex-row md:space-y-0 md:max-w-[70%] md:flex-wrap md:justify-end md:gap-4 z-10">

              { /* HEADER BUTTONS */ }
              <AdminManageCorporation />
              <ForumQuestions />
              <FeedPosts />
              <ProfileContactButton />
              <MessageButton />
              <ProfileFollowButton />
              <ShowQRButton />
              {
                data && data.showRequestPdfProfileButton && (
                  <DownloadProfileButton />
                )
              }

              { /* PROFILE PAGE IMAGE MODAL */ }
              <ImageUploadModal
                show={showImageUploadModal}
                setOpen={setShowImageUploadModal}
                profilePageId={id}
                requesterProfilePageId={profileID}
                onClose={handleImageUploadModalClose}
                imageType={ProfilePageImageType.profile}
                currentUsedImage={profileImageURI}
                profilePageType={data && data.accountType}
              />

              { /* COVER IMAGE MODAL */ }
              <ImageUploadModal
                show={showCoverImageUploadModal}
                setOpen={setShowCoverImageUploadModal}
                profilePageId={id}
                requesterProfilePageId={profileID}
                onClose={handleImageUploadModalClose}
                imageType={ProfilePageImageType.cover}
                currentUsedImage={coverImageURI}
                profilePageType={data && data.accountType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
