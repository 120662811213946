import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import ModuleLoader from '../../../components/ModuleLoader';
import {
  displayCreateFocusForm,
  onFocusTerritorySelection,
  resetCreateFocusForm,
  updateCreateFocusCoreFocusID,
  updateCreateFocusFile,
  updateCreateFocusLink,
  updateCreateFocusTags,
} from '../focusModuleSlice';
import ModuleInfoSupportMessage from '../../../components/ModuleInfoSupportMessage';
import Button from '../../../../../elements/Button';
import DuplicateForm from '../../products-module/components/ProductCreateEditForm/DuplicateForm';
import TagSelection from '../../../components/TagSelection';
import MarketsSelection from '../../business-markets-module/elements/MarketsSelection';
import { FlattenTerritory } from '../../business-markets-module/business-markets-module-types';
import ServiceLinkField from '../../services-module/components/ServiceLinkField';
import ServiceFileField from '../../services-module/components/ServiceFileField';
import { UploadBlob, uploadFileToAzure } from '../../../../../app/filesSlice';
import FocusNameField from './FocusNameField';
import { useCreateFocusMutation, useUpdateFocusMutation } from '../focusAPI';

interface FocusCreateEditFormProps {
  formType: 'edit' | 'create',
  focusID: number,
  setFocusViewInViewMode?: () => void,
}

export default function FocusCreateEditForm(props: FocusCreateEditFormProps) {
  const { focusID, formType, setFocusViewInViewMode } = props;

  const [duplicateForm, setDuplicateForm] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();

  const { createFocusFormData } = useSelector((state: RootState) => state.profileModules.focusModule);
  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const currentFocus = createFocusFormData[focusID];

  const [createFocus, { isLoading: isCreatingInProgress }] = useCreateFocusMutation();
  const [updateFocusRequest, { isLoading: isUpdatingInProgress }] = useUpdateFocusMutation();

  if (! currentFocus) {
    return (
      <ModuleLoader />
    );
  }

  const onCancelForm = () => {
    if (formType === 'create') {
      dispatch(displayCreateFocusForm({
        showForm: false,
      }));
    }

    if (formType === 'edit') {
      dispatch(resetCreateFocusForm(focusID));
      if (setFocusViewInViewMode) { setFocusViewInViewMode(); }
    }
  };

  const onAddFocus = async () => {

    // upload file if wasn't uploaded
    const currentFile = currentFocus.fileAttachments && currentFocus.fileAttachments.length > 0
      ? cloneDeep(currentFocus.fileAttachments[0])
      : null;

    if (currentFile && (! currentFile.fileUuid) && uploadedFile) {
      const file = await dispatch(uploadFileToAzure({
        file: uploadedFile,
        description: currentFile.description,
        blobContainer: UploadBlob.moduleFocus,
        profilePageID: currentlyLoadedProfileID!,
        requesterID,
      }));

      if (! file) {
        // eslint-disable-next-line no-console
        console.log('Module focus upload - no file uuid returned');
        return;
      }

      // @ts-ignore
      const { id } = file.payload.data;

      currentFile.fileUuid = id;

      // update to set file UUID for duplicated form
      dispatch(updateCreateFocusFile({ focusID, value: currentFile }));
    }

    const payload = {
      profileID: currentlyLoadedProfileID,
      requesterID,
      focus: {
        ...currentFocus,
        fileAttachments: currentFile ? [currentFile] : [],
      },
    };

    if (formType === 'create') {
      await createFocus(payload);
    }

    if (formType === 'edit') {
      await updateFocusRequest(payload);

      if (setFocusViewInViewMode) { setFocusViewInViewMode(); }
    }

    // duplicate form
    dispatch(displayCreateFocusForm({
      showForm: duplicateForm,
      focus: currentFocus, // set data in new form
    }));
  };

  const preparedTags = currentFocus.coreTags
    ? currentFocus.coreTags
    : [];

  return (
    <div className="mt-4 flex flex-col items-center">

      {/* Focus name */}
      <div className="w-3/5 mb-4">
        <FocusNameField
          value={currentFocus.coreFocusCategoryId}
          onChange={(v) => dispatch(updateCreateFocusCoreFocusID({ focusID, value: v ? v.id : 1 }))}
        />
      </div>

      {/* Territory */}
      <div className="w-3/5 mb-4">
        <div className="border rounded-md p-2 mt-2 bg-neutral-25">
          <p className="pb-2 text-neutral-700 text-xs font-semibold">
            Territories
          </p>
          <MarketsSelection
            onChange={(t: MultiValue<FlattenTerritory>) => dispatch(onFocusTerritorySelection({ focusID, value: t }))}
            value={currentFocus.territories.map((t) => ({
              ...t,
              nestingLevel: 0, // will be set in the component
              value: t.id, // duplicate id
              label: t.territoryName, // duplicate territoryName
            }))}
          />
        </div>
      </div>

      {/* Link */}
      <div className="w-3/5 mb-4">
        <ServiceLinkField
          value={currentFocus.externalLinks}
          onChange={(link) => dispatch(updateCreateFocusLink({ focusID, value: link }))}
        />
      </div>

      <div className="w-3/5 mb-4">
        <ServiceFileField
          value={currentFocus.fileAttachments}
          onChange={(file) => dispatch(updateCreateFocusFile({ focusID, value: file }))}
          setLocalFile={(file) => setUploadedFile(file)}
        />
      </div>

      {/* Tags */}
      <div className="w-3/5 mb-4">
        <div className="border rounded-md p-2 bg-neutral-25">
          <p className="pb-2 text-neutral-700 text-xs font-semibold">
            Tags
          </p>
          <TagSelection
            placeholder="Tags"
            value={preparedTags}
            onChange={(t) => dispatch(updateCreateFocusTags({ value: t, focusID }))}
          />
        </div>
      </div>

      <div className="w-3/5 mt-6">
        <DuplicateForm
          duplicateFormFlag={duplicateForm}
          productID={focusID}
          setDuplicateForm={(flag) => setDuplicateForm(flag)}
        />
      </div>

      {/* Action buttons */}
      <div className="w-3/5 mt-4 mb-6 flex justify-end">
        <div className="pr-2">
          <Button
            onClick={onCancelForm}
            size="medium"
            inverted
            description="Cancel"
            disabled={isCreatingInProgress || isUpdatingInProgress}
          />
        </div>
        <Button
          onClick={onAddFocus}
          size="medium"
          description={formType === 'create' ? 'Add a new Focus' : 'Save changes'}
          isLoading={isCreatingInProgress || isUpdatingInProgress}
        />
      </div>
      <ModuleInfoSupportMessage />
    </div>
  );
}
