import React from 'react';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import FileDownloadPreview from '../../../../elements/FileDownloadPreview';
import LinkedinPreview from '../../../../elements/LinkedinPreview';
import Button from '../../../../elements/Button';
import { useGetAboutModuleQuery } from './aboutModuleAPI';
import ModuleLoader from '../../components/ModuleLoader';
import ExternalLinkView from '../../../../elements/ExternalLinkView';
import { AboutModuleExternalLink, AboutModuleFile } from './aboutModuleTypes';
import TerritoryRow from '../business-markets-module/TerritoryRow';
import { RelatedCorporationSummary } from '../../../../app/account';
import { useBrandingLocale } from '../../../../helpers/hooks';

interface AboutModuleViewInterface {
  setEditMode?: () => void
  relatedCorporationProfilePageSummary?: RelatedCorporationSummary,
}

// todo: empty state
// todo: fetch data
export default function AboutModuleView(props: AboutModuleViewInterface) {

  const { setEditMode, relatedCorporationProfilePageSummary } = props;

  const { allowPageEdit, currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);

  const { t } = useBrandingLocale();

  const {
    data, isFetching, isLoading,
  } = useGetAboutModuleQuery({
    id: currentlyLoadedProfileID,
    requesterID: profileID,
  }, { skip: (! currentlyLoadedProfileID) || (! profileID) });

  if (isLoading || isFetching || (! data)) {
    return (
      <ModuleLoader />
    );
  }

  const noDataFlag = allowPageEdit
    && (! data
      || (data && (
        ! data.description
        && data.moduleAboutDynamicFields && data.moduleAboutDynamicFields.length === 0
        && data.fileAttachments && data.fileAttachments.length === 0
        && data.externalLinks && data.externalLinks.length === 0)
      )
    );

  if (noDataFlag) { // empty state
    return (
      <>
        <div className="px-2 py-2 bg-neutral-25 flex-row items-center">
          <div className="w-max">
            <Button
              onClick={() => {
                if (! setEditMode) return;
                setEditMode();
              }}
              size="small"
              description="Edit this module"
              inverted
              roundedFull
              iconLeft={<PlusSmallIcon className="w-4 h-4" />}
            />
          </div>
        </div>
        <div className="w-full pt-4 border-t" />
      </>
    );
  }

  const link: AboutModuleExternalLink | undefined = data.externalLinks && data.externalLinks.length > 0 ? data.externalLinks[0] : undefined;
  const file: AboutModuleFile | undefined = data.fileAttachments && data.fileAttachments.length > 0 ? data.fileAttachments[0] : undefined;

  return (
    <>
      <div className="px-4 pb-6 grid grid-cols-1 md:grid-cols-12 gap-12">
        {/* Column left */}
        <div className="pt-6 col-span-7">

          <div className="ml-4 pt-2">
            <span className="whitespace-pre-line text-sm">
              { data.description }
            </span>
          </div>

          {/* Attachments */}
          <div className="pt-6">
            {/* Linkedin */}
            {
              data.extraFields?.linkedInAccount && (
                <LinkedinPreview link={data.extraFields?.linkedInAccount} />
              )
            }

            {/* Attachment */}
            {
              file && (
                <FileDownloadPreview file={file} />
              )
            }

            {/* Link */}
            {
              link && link.link && (
                <ExternalLinkView link={link.link} description={link.description} />
              )
            }
          </div>
        </div>

        {/* Column right */}
        <div className="pt-6 col-span-5">
          {
            data.extraFields?.territory && (
              <div className="pt-2">
                <p className="font-semibold text-sm">Country</p>
                <TerritoryRow country={data.extraFields.territory} />
              </div>
            )
          }
          {
            orderBy(data.moduleAboutDynamicFields, ['fieldOrder']).map((field) => (
              <div key={field.id} className="pt-2">
                <p className="font-semibold text-sm">{field.name}</p>
                <p className="text-main-600 text-sm">{field.value}</p>
              </div>
            ))
          }
          {
            data.displayCorporation && relatedCorporationProfilePageSummary && (
              <div className="pt-2">
                <p className="font-semibold text-sm">{t('Corporation')}</p>
                <Link to={`/profile/${relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                  <p className="text-sm font-semibold text-neutral-600 table-cell">
                    {relatedCorporationProfilePageSummary.name}
                  </p>
                </Link>
              </div>
            )
          }
        </div>
      </div>
      <div className="w-full pt-4 border-t" />
    </>
  );
}
