import React, { useEffect, useState } from 'react';

import { TrashIcon, ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../helpers/styling';
import { ValidationKeys, rulesFeedPostLink, validationErrorState } from './postLinkValidationRules';
import { useFormValidation } from '../../../helpers/validation';
import Input from '../../../elements/Input';
import { getLinkRootDomain } from '../../../helpers/functions';

export interface Link {
  id: string | number,
  linkURL: string,
  title: string,
  inEditMode: boolean
}

interface PostLinkProps extends Link {
  onDelete: () => void,
  onEdit: (link: Link) => void,
  allowEdit: boolean
}

export default function PostLink(props: PostLinkProps) {

  const [localTitle,   setLocalTitle]   = useState('');
  const [localLinkURL, setLocalLinkURL] = useState('');

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const {
    id, linkURL, title, inEditMode, onDelete, onEdit, allowEdit,
  } = props;

  useEffect(() => {
    onEdit({
      id,
      inEditMode,
      title: localTitle,
      linkURL: localLinkURL,
    });

    // eslint-disable-next-line
  }, [localTitle, localLinkURL]);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesFeedPostLink,
    validationErrorState,
    {
      localTitle,
    },
  );

  function formatURL(link: string) {
    const url = link.match(/^http[s]?:\/\//) ? link : `http://${link}`;    
    return getLinkRootDomain(link);
  }

  const attachDisabled = ! id || ! linkURL || ! title;

  if (inEditMode && allowEdit) {
    return (
      <div className="bg-neutral-75 mt-2 rounded-lg flex flex-row">
        <div className="w-full">
          <div className="p-4 flex flex-row justify-between">
            <span className="text-xs font-semibold">
              Add a link
            </span>
            <XMarkIcon onClick={onDelete} className="w-4 h-4 text-neutral-500 cursor-pointer" />
          </div>

          <div className="p-9 pt-0 w-full flex flex-col">
            <div>
              <Input
                type="text"
                id="name"
                value={localTitle}
                onChange={(e) => {
                  setLocalTitle(e.target.value);
                  onValidationInputChange('localTitle');
                }}
                label="Link Title"
                placeholder="Title..."
                validationMessage={validationState.localTitle.errorMessage}
              />
              <div className="relative border border-t-0 border-gray-300 bg-white rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-main-600 focus-within:border-main-600">
                <label htmlFor="name" className="block text-xs font-medium text-gray-700">
                  Link URL
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setLocalLinkURL(e.target.value)}
                  value={localLinkURL}
                  className="w-full block border-0 p-0 text-main-600 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  placeholder="https://"
                />
              </div>
            </div>

            <div className="w-full pt-4 flex justify-end items-end">
              <button
                type="button"
                onClick={onDelete}
                className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={attachDisabled}
                onClick={() => {

                  const val = validate();
                  if (! val.allValid) return;

                  onEdit({
                    id,
                    inEditMode: false,
                    title: localTitle,
                    linkURL: localLinkURL,
                  });
                }}
                className={
                classNames(
                  attachDisabled ? 'opacity-50 pointer-events-none' : '',
                  'inline-flex items-center px-5 py-1 border border-neutral-800 text-xs text-white font-semibold rounded-3xl bg-neutral-800 hover:border-main-600 hover:bg-main-600 focus:outline-none transition-colors duration-300',
                )
              }
              >
                Attach
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* State for delete confirmation */
  if (deleteConfirmation) {
    return (
      <div className="flex flex-row justify-between items-center border-4 border-error-400 border-color rounded-2xl mt-1 p-2">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="">
            <p className="text-sm font-extrabold">Are you sure do you want to delete it?</p>
            <p className="text-xs font-semibold text-neutral-500">{title}</p>
          </div>

          {/* Buttons */}
          <div className="flex justify-end items-end">
            <button
              type="button"
              onClick={() => setDeleteConfirmation(false)}
              className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
            >
              Close
            </button>
            <button
              type="button"
              onClick={onDelete}
              className="inline-flex items-center px-5 py-1 border border-error-600 text-xs text-white font-semibold rounded-3xl bg-error-600 hover:border-error-800 hover:bg-error-800 focus:outline-none transition-colors duration-300"
            >
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  /* State after data was added */
  return (

    <div className="flex flex-row justify-between items-center shadow-md rounded-lg mt-1 p-2">

      <a href={linkURL} target="_blank" rel="noreferrer" className="">
        <div className="flex flex-row">
          <button
            type="button"
            className="shrink-0 bg-transparent mr-4 text-gray-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none m-2 "
          >
            <span className="sr-only">{title}</span>
            <ArrowTopRightOnSquareIcon className="h-6 w-6 text-main-600 bg-main-25" />
          </button>
          <div>
            <p className="text-main-600 text-xs">{formatURL(linkURL)}</p>
            <p className="text-base font-semibold">{title}</p>
          </div>
        </div>
      </a>

      {
        allowEdit && (
          <div className="">
            <button
              type="button"
              onClick={() => setDeleteConfirmation(true)}
              className="shrink-0 bg-transparent mr-4 rounded-full ring-8 ring-offset-0 ring-error-25"
            >
              <span className="sr-only">Delete link</span>
              <TrashIcon className="h-4 w-4 text-error-600 bg-error-25" aria-hidden="true" />
            </button>
          </div>
        )
      }
    </div>

  );
}
