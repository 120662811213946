import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Textarea from '../../../../elements/Textarea';
import ModuleInfoSupportMessage from '../../components/ModuleInfoSupportMessage';
import Button from '../../../../elements/Button';
import { RootState } from '../../../../app/store';
import TagSelection from '../../components/TagSelection';
import { initiateModule, updateTags, updateDescription } from './areaOfInterestsModuleSlice';
import { useAppDispatch } from '../../../../app/hooks';
import {
  useGetInterestModuleQuery,
  useUpdateAreaOfInterestMarketsModuleMutation,
} from './areaOfInterestAPI';

interface AreaOfInterestModuleEditProps {
  setViewMode?: () => void,
}

export default function AreaOfInterestModuleEdit(props: AreaOfInterestModuleEditProps) {

  const { setViewMode } = props;

  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);
  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  const { description, tags } = useSelector((state: RootState) => state.profileModules.areaOfInterestModule);

  const { data } = useGetInterestModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (! currentlyLoadedProfileID) || (! requesterID) },
  );

  const [updateModule, { isLoading: isSavingInProgress }] = useUpdateAreaOfInterestMarketsModuleMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (! data) return;

    dispatch(initiateModule(data));
  }, [currentlyLoadedProfileID, dispatch, data]);

  const onSave = async () => {
    if (! currentlyLoadedProfileID) return;

    await updateModule({
      id: currentlyLoadedProfileID,
      requesterID,
      description,
      coreTagIds: tags.map((t) => t.id),
    });

    if (setViewMode) setViewMode();
  };

  return (
    <>
      <div className="p-4 flex flex-col items-center justify-center">
        <div className="w-3/5">
          <Textarea
            // todo debounce 300-500ms
            value={description}
            placeholder="Start typing..."
            onChange={(e) => dispatch(updateDescription(e.target.value))}
          />
        </div>
        <div className="w-3/5 py-2">
          <TagSelection value={tags} onChange={(t) => dispatch(updateTags(t))} />
        </div>

        <div className="w-3/5 mt-8 mb-6 flex justify-end">
          <div className="pr-2">
            <Button
              onClick={() => setViewMode && setViewMode()}
              size="medium"
              inverted
              description="Cancel"
              isLoading={isSavingInProgress}
            />
          </div>
          <Button
            onClick={onSave}
            size="medium"
            description="Save changes"
            disabled={isSavingInProgress}
          />
        </div>

        <ModuleInfoSupportMessage />
      </div>
      <div className="w-full pt-4 border-t" />
    </>
  );
}
