import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import Navbar from '../navbar/Navbar';
import FlashMessages from '../flash-messages/FlashMessages';
import { communicationServiceEndpointURL } from '../../azureCommunicationService-config';
import { useGetUserAccessTokenQuery } from '../messages/messagesAPI';
import { RootState } from '../../app/store';

import './index.scss';
import MessagesWrap from '../messages/MessagesWrap';
import { COMPANY_NAME } from '../../helpers/branding';

interface DashboardLayoutProps {
  children: React.ReactNode
}

// TODO: use redux / context
const footerLinks = [
  {
    name: 'Privacy Policy',
    link: '/privacy-policy',

  },
  {
    name: 'Terms of Use',
    link: '/terms-of-use',

  },
  {
    name: 'Cookie Policy',
    link: '/cookie-policy',
  },
];

// to pass chat client across whole application
export const ChatClientContext = React.createContext<ChatClient | undefined>(undefined);

export default function DashboardLayout(props: DashboardLayoutProps) {

  const { children } = props;

  const [localChatTokenData, setLocalChatTokenData] = useState<any>(undefined);

  const {
    currentProfile: { profileID },
  } = useSelector((state: RootState) => state.account);

  const { data } = useGetUserAccessTokenQuery({ profileID }, {
    skip: !profileID,
    pollingInterval: 60 * 60 * 1000, // in ms = 1hr
  });

  // delay setting chats -> chats spam too much requests that block rendering other parts of the app
  useEffect(() => {
    function delaySettingChatClient() {
      return new Promise((resolve) => {
        if (! data || ! data.token) {
          resolve(undefined);
          return;
        }

        setTimeout(() => {
          setLocalChatTokenData(data);
          resolve(true);
        }, 0); // causing troubles when switching accounts
      });
    }

    (async () => {
      await delaySettingChatClient();
    })();
  }, [data]);

  // use memo & re-initiate on token change only
  const chatClient: ChatClient | undefined = useMemo(() => {
    if (! localChatTokenData || ! localChatTokenData.token) return undefined;

    console.log('RE-/Initialize chatClient', localChatTokenData);

    // problem: chat client is updated too much & triggers rerender
    return new ChatClient(communicationServiceEndpointURL, new AzureCommunicationTokenCredential(localChatTokenData.token));
  }, [localChatTokenData]);

  return (
    <ChatClientContext.Provider value={chatClient}>
      <div className="dashboard-layout mx-auto w-full bg-neutral-50 min-h-screen pb-20">

        <Navbar />

        <div className="z-10 relative">
          { children }
        </div>

        {/* TODO: translate */}
        <footer className="fixed z-0 bottom-0 p-2 sm:p-4 hidden xl:block">
          <ul className="flex direction-row">
            {
          footerLinks.map((link) => (
            <li key={link.name}>
              <a
                href={link.link}
                target="_blank"
                rel="noreferrer"
                className="text-2xs text-neutral-600 p-2 opacity-50"
              >
                {link.name}
              </a>
            </li>
          ))
        }
          </ul>
          <span className="text-2xs text-neutral-900 p-2 opacity-50">
            {COMPANY_NAME}
            {' '}
            ©
            {new Date().getFullYear()}
          </span>
        </footer>

        <MessagesWrap chatClient={chatClient} />
        <FlashMessages />
      </div>
    </ChatClientContext.Provider>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element,
};

DashboardLayout.defaultProps = {
  children: null,
};
