import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { useDeleteSavedSearchMutation } from '../searchAPI';

export interface SavedSearchesItemProps {
  onClick: () => void,
  id: number,
  date: string,
  searchString: string,
  saved: boolean,
}

export default function SavedSearchesItem(props: SavedSearchesItemProps) {

  const {
    saved, searchString, date, onClick, id,
  } = props;

  const [deleteSearch] = useDeleteSavedSearchMutation();

  return (
    <button
      type="button"
      onClick={(e) => {
        if (e.target.toString().includes('SVGPathElement')) { // click on a star
          return;
        }

        onClick();
      }}
      className="mt-1 w-full shadow-sm p-4 flex rounded-md flex-row cursor-pointer hover:shadow-lg transition-shadow"
    >
      <div className="pt-2">
        {
          saved ? (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();

                deleteSearch({ id });
              }}
            >
              <StarIcon
                className="w-4 h-4 text-main-600 hover:text-error-600 transform hover:scale-150 transition-transform"
              />
            </button>
          ) : (
            <StarIconOutline className="w-4 h-4 text-main-600" />
          )
        }
      </div>

      <div className="pl-2 flex flex-col justify-center h-full">
        <p className="text-2xs text-left text-neutral-400">{date}</p>
        <p className="text-xs text-left">{searchString}</p>
      </div>
    </button>
  );
}
