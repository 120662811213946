import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import LandingNavigation from '../LandingNavigation';
import Footer from '../Footer';
import QuizForm from './QuizForm';
import mainScreenBg from '../what-is-nucleo/assets/logoPart.svg';
import { useDocumentTitle } from '../../../helpers/hooks';

export default function QuizPage() {
  useDocumentTitle('Quiz Form');

  return (
    <main className="what-is-nucleo-page bg-neutral-50 relative bg-white">
      <section style={{ minHeight: 1000 }} className="relative flex justify-center pb-12 md:pb-0 2xl:pb-60 overflow-hidden">
        <LandingNavigation />

        <img
          alt="Background"
          src={mainScreenBg}
          className="absolute top-0 -right-44 md:right-0 transform 2xl:scale-150"
        />

        {/* left section */}
        <Fade left>
          <div className="mt-24 z-10 w-full flex main-screen-container items-center max-w-6xl w-full px-4 md:px-6 xl:px-0">
            <div className="mb-32 backdrop-blur-md w-full bg-white/90 md:w-auto px-8 py-12 rounded-md xl:p-0 xl:bg-transparent xl:backdrop-filter-none">
              {/* <h1 className="text-main-900 text-5.5xl font-extrabold"> */}
              {/*  Register for cphi quiz */}
              {/* </h1> */}

              <div className="mt-6">
                <QuizForm />
              </div>
            </div>
          </div>
        </Fade>
      </section>

      <div className="relative overflow-hidden">
        <Footer />
      </div>
    </main>
  );
}
