import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import FeedRightSidebar from '../feed/right-sidebar/FeedRightSidebar';
import FilterInput from './components/FilterInput';
// import ContactsSearchInput from './components/ContactsSearchInput';
import ConnectRequest from './components/ConnectRequest';
import ContactList from './components/ContactList';
import { useGetContactsListRequestsQuery } from './contactListAPI';
import { RootState } from '../../app/store';
import { ContactRequest } from './contact-list.types';
import { useDocumentTitle } from '../../helpers/hooks';

export default function ContactListPage() {

  const [searchString, setSearchString] = useState('');
  const [contactsOnly, setContactsOnly] = useState(false);
  const [followersOnly, setFollowersOnly] = useState(false);
  const [followingOnly, setFollowingOnly] = useState(false);

  useDocumentTitle('Contact List');

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const { data } = useGetContactsListRequestsQuery({ profilePageID: profileID }, {
    skip: !profileID,
    pollingInterval: 60 * 1000, // every min
  });

  return (
    <DashboardLayout>
      <div className="min-h-screen">
        <div className="pt-4">
          <div className="feed-wrapper mx-auto grid grid-cols-12 gap-4">
            <main className="col-span-12 md:col-span-9">
              {/* Filter records */}
              <div className="bg-white p-4 shadow shadow-md rounded rounded-md">

                {/* <ContactsSearchInput /> */}
                <div className="relative">
                  <input
                    className="text-xs p-3.5 pl-10 bg-neutral-50 w-full rounded-full focus:outline-none border border-neutral-300"
                    id="search-input"
                    placeholder="Search contacts, followers, following"
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                  <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center">
                    <MagnifyingGlassIcon className="w-4 h-4 text-main-600" />
                  </div>
                </div>

                {/* Filters */}
                <div className="flex flex-row justify-end items-center">

                  <span className="font-semibold text-xs pr-4">Show</span>

                  <FilterInput onChange={(v) => setContactsOnly(v)} inputID="filter-contacts" labelName="Contacts" />
                  <FilterInput onChange={(v) => setFollowersOnly(v)} inputID="filter-followers" labelName="Followers" />
                  <FilterInput onChange={(v) => setFollowingOnly(v)} inputID="filter-following" labelName="Following" />
                </div>
              </div>

              <div className="mt-6">

                {
                  data && data.data && data.data.length > 0 && data.data.map((request: ContactRequest) => (
                    <ConnectRequest
                      key={request.senderProfilePageSummary.profilePageId}
                      request={request}
                    />
                  ))
                }
              </div>

              {/* List records */}
              <ContactList
                searchString={searchString}
                contactsOnly={contactsOnly}
                followersOnly={followersOnly}
                followingOnly={followingOnly}
              />
            </main>
            <aside className="hidden md:block md:col-span-3">
              <FeedRightSidebar />
            </aside>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
