import { ChatMessage, ChatMessageReceivedEvent } from '@azure/communication-chat';
import { SenderProfilePageSummary } from '../contact-list/contact-list.types';

export enum ChatTypes {
  oneToOneChat = 'oneToOneChat',
}

export enum ChatRequestStatus {
  none = 'none',
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  cancelled = 'cancelled',
  received = 'received',
}

// IMPORTANT sequenceId is missing in the fired event I'm subscribed to
export interface SliceChatMessage extends Omit<ChatMessage, 'createdOn' | 'sequenceId'> {
  createdOn: string,
}
export interface SliceChatMessageReceivedEvent extends Omit<ChatMessageReceivedEvent, 'createdOn'> {
  createdOn: string,
}

export interface UserThreads {
  azureCommunicationIdentity?: string,
  chats: ChatThreadEntry[],
}

export enum CommunicationThreadMembersStatus {
  member = 'member',
  blocker = 'blocker',
}

export interface ChatThreadEntry {
  azureThreadId: string,
  chatMember: {
    azureCommunicationIdentity: string,
    profilePageSummary: SenderProfilePageSummary
  },
  chatType: ChatTypes,
  lastMessageReceivedOnUtc?: string,
  threadMemberStatus: CommunicationThreadMembersStatus,
}

export interface MessageSliceThread extends Omit<ChatThreadEntry, 'azureThreadId'>{
  threadID: string,
  lastMessage: SliceChatMessage | null,
  unreadCount: number,
}

export interface UserIsTyping {
  flag: boolean,
  nanoid: string,
}

export interface ChatWindowType {
  threadId: string,
  currentProfileAzureCommunicationID: string,
  profilePageSummary: SenderProfilePageSummary | null,
  userIsTyping: UserIsTyping,
  threadMemberStatus: CommunicationThreadMembersStatus | null,
}

export interface ChatInvitation {
  chatType: ChatTypes,
  senderProfilePageSummary: SenderProfilePageSummary,
  createdDateUtc: string,
  message: string,
}
