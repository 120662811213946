import { emptySplitApi } from '../../app/api';
import { Article, GetArticlePreview } from './blog-types';

export const blogAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewsFeed: builder.query<GetArticlePreview, { pageNumber: number, pageSize: number }>({
      query: (body) => ({
        url: `/Administration/homepage/news-feed?PageNumber=${body.pageNumber}&PageSize=${body.pageSize}`,
      }),
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     await queryFulfilled;
      //     dispatch(addFlashMessage('success', 'Message has been sent'));
      //   } catch {
      //     dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
      //   }
      // },
    }),
    getArticle: builder.query<Article, { articleID: number }>({
      query: (body) => ({
        url: `/Administration/homepage/news/${body.articleID}`,
      }),
    }),
  }),
});

export const {
  useGetNewsFeedQuery,
  useGetArticleQuery,
} = blogAPI;
