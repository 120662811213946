import React, { useEffect } from 'react';
import {
  find, findIndex, forEach, isEqual,
} from 'lodash';
import { useSelector } from 'react-redux';
import Input from '../../../../../../../elements/Input';
import { editDosageForm } from '../../../productsModuleSlice';
import { Dosage, DosageStrength } from '../../../products-module.types';
import { RootState } from '../../../../../../../app/store';
import { useAppDispatch } from '../../../../../../../app/hooks';

interface StrengthByIngredientProps {
  dosage: Dosage,
  dosageIdx: number,
  productID: number,
  presentationIdx: number,
}

export default function StrengthByIngredient(props: StrengthByIngredientProps) {

  const {
    presentationIdx, dosageIdx, dosage, productID,
  } = props;

  const { createProductFormData } = useSelector((state: RootState) => state.profileModules.productsModule);
  const { activeIngredients } = createProductFormData[productID];

  const dispatch = useAppDispatch();

  // delete strength on active ingredients change
  // note: can be optimized if calculated only on activeIngredients list change
  useEffect(() => {
    if (! activeIngredients || activeIngredients.length === 0) {
      dispatch(editDosageForm({
        productID,
        presentationIdx,
        dosageIdx,
        newValue: {
          ...dosage,
          moduleProductInnStrengths: [],
        },
      }));

      return;
    }

    const idsToKeep = activeIngredients.map(({ coreInnProductId }) => coreInnProductId);
    const currentDosageIds = dosage.moduleProductInnStrengths.map(({ coreInnProductId }) => coreInnProductId);

    if (isEqual(idsToKeep, currentDosageIds)) return;

    const idsToRemove = currentDosageIds
      .map((cid) => (idsToKeep.includes(cid) ? null : cid))
      .filter((v) => v !== null);

    if (idsToRemove.length === 0) return;

    const newDosage: Dosage = JSON.parse(JSON.stringify(dosage)); // instead of clone as object is still frozen

    // @ts-ignore
    const newStrengthArray: DosageStrength[] = newDosage.moduleProductInnStrengths.map((strength) => {
      if (idsToRemove.includes(strength.coreInnProductId)) return null;
      return strength;
    }).filter((v) => v !== null);

    newDosage.moduleProductInnStrengths = newStrengthArray;

    dispatch(editDosageForm({
      productID,
      presentationIdx,
      dosageIdx,
      newValue: newDosage,
    }));

  }, [activeIngredients, productID, presentationIdx, dosageIdx, dispatch, dosage]);

  const onChangeStrength = (value: string | undefined, coreInnProductID: number, focusLost = false) => {
    const newDosage: Dosage = JSON.parse(JSON.stringify(dosage)); // instead of clone as object is still frozen

    const dosageToChangeIndex = findIndex(dosage.moduleProductInnStrengths, { coreInnProductId: coreInnProductID });

    // only numbers
    let newValue: string | number = value
      // eslint-disable-next-line
      ? value
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*?)\..*/g, '$1')
      : '';

    // if (newValue) newValue = parseFloat(newValue);

    // change existing value on input focus lost
    if (focusLost && (dosageToChangeIndex !== -1)) {
      newValue = parseFloat(newDosage.moduleProductInnStrengths[dosageToChangeIndex].strength.toString());
    }

    const valueObject = {
      strength: newValue,
      coreInnProductId: coreInnProductID,
    };

    if (dosageToChangeIndex !== -1) { // item already exists in array
      newDosage.moduleProductInnStrengths[dosageToChangeIndex] = valueObject;
    } else {
      newDosage.moduleProductInnStrengths.push(valueObject);
    }

    dispatch(editDosageForm({
      productID,
      presentationIdx,
      dosageIdx,
      newValue: newDosage,
    }));
  };

  if (! activeIngredients || activeIngredients.length === 0) {
    return (
      <div className="flex justify-center mt-4 mb-2">
        <p className="text-sm font-semibold text-neutral-500">Please select at least one active ingredient</p>
      </div>
    );
  }

  const getStrengthByIngredient = (coreInnProductID: number) => {
    const strength = find(dosage.moduleProductInnStrengths, { coreInnProductId: coreInnProductID });
    if (! strength) return '';

    return strength.strength;
  };

  return (
    <>
      {
        activeIngredients.map((activeIngredient) => (
          <div className="mt-2" key={activeIngredient.coreInnProductId}>
            <Input
              label={`Dosage of ${activeIngredient.coreInnProduct.name} (${dosage.coreStrengthUnit?.name})`}
              id={`DosagesGroupField-${presentationIdx}-${dosageIdx}`}
              value={getStrengthByIngredient(activeIngredient.coreInnProductId)}
              placeholder="Dosage strength"
              onBlur={(e) => onChangeStrength(e.target.value, activeIngredient.coreInnProductId, true)}
              onChange={(e) => onChangeStrength(e.target.value, activeIngredient.coreInnProductId)}
            />
          </div>
        ))
      }
    </>
  );
}
