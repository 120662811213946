import React from 'react';
import ModuleLoader from '../../profile-page/components/ModuleLoader';
import QuestionPreview from './QuestionPreview';
import Pagination from '../../../elements/pagination/Pagination';
import { GetQuestionsResponse } from '../forum-types';

interface ForumQuestionListRenderProps {
  data?: GetQuestionsResponse,
  isFetching: boolean,
  isLoading: boolean,
  setPage: (page: number) => void,
}

export default function ForumQuestionListRender(props: ForumQuestionListRenderProps) {

  const {
    data, isFetching, isLoading, setPage,
  } = props;

  if (isLoading) {
    return (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-4 p-6">
        <ModuleLoader />
      </div>
    );
  }

  if (! data || data.data.length === 0) {
    return (
      <div className="flex w-full justify-center mt-8">
        <span className="font-semibold text-neutral-600">No questions yet...</span>
      </div>
    );
  }

  return (
    <div className="relative bg-white shadow shadow-md rounded rounded-md mt-4 p-6 overflow-hidden">
      {
        isFetching && (
          <div className="absolute top-0 left-0 z-50 w-full h-full p-6 pb-20 pointer-events-none">
            <div className="bg-white w-full h-full animate-pulse" />
          </div>
        )
      }
      {
        data.data.map((question, idx) => (
          <QuestionPreview
            key={question.id}
            question={question}
            first={idx === 0}
            last={idx === data.data.length - 1}
          />
        ))
      }

      <div className="border-t py-3 pt-8">
        <Pagination
          pageSize={data.pageSize}
          currentPage={data.pageNumber}
          totalPages={data.totalPages}
          totalRecords={data.totalRecords}
          onPageChange={(pageNumber) => {
            setPage(pageNumber);
            window.scrollTo({
              left: 0,
              top: 0,
              behavior: 'smooth',
            });
          }}
          label="Questions"
        />
      </div>
    </div>
  );
}
