import React, { useEffect } from 'react';
import { MultiValue } from 'react-select';
import { useSelector } from 'react-redux';
import MarketsSelection from './elements/MarketsSelection';
import { FlattenTerritory } from './business-markets-module-types';
import { RootState } from '../../../../app/store';
import {
  useGetBusinessMarketsModuleQuery,
  useUpdateBusinessMarketsModuleMutation,
} from './businessMarketsModuleAPI';
import Textarea from '../../../../elements/Textarea';
import { useAppDispatch } from '../../../../app/hooks';
import Button from '../../../../elements/Button';
import { initiateModule, updateDescription, updateTerritories } from './businessMarketsModuleSlice';
import ModuleInfoSupportMessage from '../../components/ModuleInfoSupportMessage';

interface BusinessMarketsModuleEditProps {
  setViewMode?: () => void,
}

// todo: extract dropdown with all data related stuff !
export default function BusinessMarketsModuleEdit(props: BusinessMarketsModuleEditProps) {

  const { setViewMode } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);
  const { description, territories } = useSelector((state: RootState) => state.profileModules.businessMarketsModule);

  const { data } = useGetBusinessMarketsModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (!currentlyLoadedProfileID) || (!requesterID) },
  );

  const [updateModule, { isLoading: isUpdatingInProgress }] = useUpdateBusinessMarketsModuleMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (! data) return;

    dispatch(initiateModule(data));
  }, [currentlyLoadedProfileID, dispatch, data]);

  const onMarketSelection = (selectedTerritories: MultiValue<FlattenTerritory>) => {
    dispatch(updateTerritories(selectedTerritories));
  };

  const onSave = async () => {
    if (! currentlyLoadedProfileID) return;

    await updateModule({
      requesterID,
      id: currentlyLoadedProfileID,
      description,
      territoryIds: territories.map((t) => t.id),
    });

    if (setViewMode) setViewMode();
  };

  return (
    <>
      <div className="p-4 flex flex-col items-center justify-center">
        <div className="w-3/5">
          <Textarea
            // todo debounce 300-500ms
            value={description}
            placeholder="Start typing..."
            onChange={(e) => dispatch(updateDescription(e.target.value))}
          />
        </div>

        <div className="w-3/5 mt-2">
          <MarketsSelection onChange={onMarketSelection} value={territories} />
        </div>

        <div className="w-3/5 mt-8 mb-6 flex justify-end">
          <div className="pr-2">
            <Button
              onClick={() => setViewMode && setViewMode()}
              size="medium"
              inverted
              description="Cancel"
              disabled={isUpdatingInProgress}
            />
          </div>
          <Button
            onClick={onSave}
            size="medium"
            description="Save changes"
            isLoading={isUpdatingInProgress}
          />
        </div>

        <ModuleInfoSupportMessage />
      </div>
      <div className="w-full pt-4 border-t" />
    </>
  );
}
