import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ForumTagResponse,
  GetQuestionsRequest,
  GetQuestionsSearchRequest,
  QuestionSortFilters,
} from '../forum-types';

interface ForumQuestionSlice {
  getListRequest: GetQuestionsRequest & { filters: { filter: { tags: ForumTagResponse[] } } },
  getListWithSearchRequest: GetQuestionsSearchRequest,
}

const initialFilters = {
  filter: {
    tagIds: [],
    tags: [],
    creatorProfilePageId: null,
  },
  sorting: QuestionSortFilters.newestFirst,
};

export const initialState: ForumQuestionSlice = {
  getListRequest: {
    pageNumber: 1,
    pageSize: 10,
    filters: initialFilters,
  },

  getListWithSearchRequest: {
    pageNumber: 1,
    pageSize: 10,
    searchInput: '',
    searchIdentifier: '',
  },
};

export const forumQuestionSlice = createSlice({
  name: 'forumQuestion',
  initialState,
  reducers: {

    setSearchString: (state, action: PayloadAction<string>) => {
      state.getListWithSearchRequest.searchInput = action.payload;
      state.getListWithSearchRequest.pageNumber = 1;
    },

    resetForumFilters: (state) => {
      state.getListRequest = initialState.getListRequest;
    },
    resetForumSearch: (state) => {
      state.getListWithSearchRequest = initialState.getListWithSearchRequest;
    },

    setListWithoutSearchPageRequest: (state, action: PayloadAction<number>) => {
      state.getListRequest.pageNumber = action.payload;
    },

    setForumFilterProfile: (state, action: PayloadAction<number | null>) => {
      state.getListRequest.filters.filter.creatorProfilePageId = action.payload;
      state.getListRequest.pageNumber = 1;
    },

    setForumFiltersTags: (state, action: PayloadAction<ForumTagResponse[]>) => {
      state.getListRequest.filters.filter.tags = action.payload;
      state.getListRequest.filters.filter.tagIds = action.payload.map((t) => t.id);
      state.getListRequest.pageNumber = 1;
    },

    setForumSearch: (state, action: PayloadAction<QuestionSortFilters>) => {
      state.getListRequest.filters.sorting = action.payload;
      state.getListRequest.pageNumber = 1;
    },
  },
});

export const {
  setSearchString,
  resetForumFilters,
  resetForumSearch,
  setForumFiltersTags,
  setForumFilterProfile,
  setForumSearch,
  setListWithoutSearchPageRequest,
} = forumQuestionSlice.actions;

export default forumQuestionSlice;
