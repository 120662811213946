import React from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { ContactListEntry as ContactListEntryType } from '../contact-list.types';
import UserAvatar from '../../../elements/UserAvatar';
import Button from '../../../elements/Button';
import ContactListEntryMenu from './ContactListEntryMenu';
import { useAddContactMutation } from '../../profile-page/profilePageAPI';
import { RootState } from '../../../app/store';
import ContactListStartChatButton from './ContactListStartChatButton';

interface ContactListEntryProps {
  contact: ContactListEntryType
}

export default function ContactListEntry(props: ContactListEntryProps) {

  const { contact, contact: { profilePageSummary } } = props;

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const [addContactQuery] = useAddContactMutation();

  return (
    <div className="bg-neutral-25 flex flex-row justify-between items-center border-b border-neutral-75 p-4">
      <div className="flex flex-row ">
        <UserAvatar
          profilePageType={profilePageSummary.profilePageAccountType}
          profileImageUrl={profilePageSummary.profileImageUrl}
          size="medium"
          profileID={profilePageSummary.profilePageId}
        />
        <div className="flex flex-col pl-4 justify-center">
          <Link to={`/profile/${profilePageSummary.profilePageId}`} className="max-w-fit">
            <p className="font-bold text-base text-neutral-800">{`${contact.profilePageSummary.name}`}</p>
          </Link>
          { /* show company set in dynamic fields */ }
          {
            profilePageSummary.showCompany && profilePageSummary.company && (
              <p className="text-sm font-semibold text-neutral-600 table-cell">
                {profilePageSummary.company}
              </p>
            )
          }
          { /* show job position set in dynamic fields if company is also set */ }
          {
            profilePageSummary.showCompany && profilePageSummary.company 
              && profilePageSummary.showJobPosition && profilePageSummary.jobPosition && (
              <p className="text-xs font-semibold text-neutral-600">                    
                {profilePageSummary.jobPosition}
              </p>
            )
          }
          { /* show related corporation name */ }          
          {
            !profilePageSummary.showCompany && profilePageSummary.relatedCorporationProfilePageSummary && (
              <Link to={`/profile/${profilePageSummary.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                <p className="font-semibold text-xs text-neutral-800">{profilePageSummary.relatedCorporationProfilePageSummary.name}</p>
              </Link>
            )
          }
        </div>
      </div>

      <div className="pr-0.5 flex flex-row">

        {
          ((contact.isFollowing || contact.isFollower) && ! contact.isContact) && (
            <div className="mr-4 flex flex-row justify-center items-center">
              <span className={`uppercase text-3xs font-semibold text-${contact.isFollowing ? 'main-500' : 'neutral-400'}`}>
                {contact.isFollower ? 'is following you' : 'you are FOLLOWING'}
              </span>
            </div>
          )
        }

        {
          ! contact.isContact && (
            <Button
              onClick={() => {
                addContactQuery({
                  receiverProfilePageId: profilePageSummary.profilePageId,
                  senderProfilePageId: profileID,
                });
              }}
              size="medium"
              roundedFull
              inverted
              iconLeft={<PlusIcon className="w-4 h-4" />}
              description=""
            />
          )
        }

        {
          contact.isContact && (
            <ContactListStartChatButton contact={contact} />
          )
        }

        <div className="pl-2">
          <ContactListEntryMenu contact={contact} />
        </div>
      </div>
    </div>
  );
}
