import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'topic' | 'message';

export const rulesContactUsForm: RulesForFieldsType<ValidationKeys> = {
  topic: {
    userFriendlyFieldName: 'Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 100,
  },
  message: {
    userFriendlyFieldName: 'Message',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 10,
    maxLength: 1000,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  topic: { errorMessage: '' },
  message: { errorMessage: '' },
};
