import React from 'react';
import Switch from '../../../elements/Switch';
import { useAccountUpdateNewsletterMutation } from '../../newsletter/newsletterAPI';
import { NewsletterStatus } from '../../newsletter/newsletter-types';

export default function ReceiveNewsletterToggle(props: { data: NewsletterStatus }) {
  const { data } = props;

  const [updateNewsletterQuery] = useAccountUpdateNewsletterMutation();

  const onUpdateNewsletter = async () => {
    await updateNewsletterQuery({ newsletterSubscribed: !data.newsletterSubscribed });
  };

  return (
    <div className="w-full border border-neutral-200 rounded-md p-4">
      <div className="mt-2">
        <Switch
          checked={data.newsletterSubscribed}
          onChange={onUpdateNewsletter}
          label="Receive Newsletter"
        />
      </div>
    </div>
  );
}
