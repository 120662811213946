import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

// todo: use SuccessBlock ?
export default function SuccessfulRegistration() {

  const history = useHistory();

  const {
    isRegistrationSuccessful,
    profileIdToRedirect,
    wasCorporationClaimed,
  } = useSelector((state: RootState) => state.registration);

  let headerMessage = 'Something went wrong';
  let detailsMessage = 'Please try again';

  if (isRegistrationSuccessful) {

    if (wasCorporationClaimed) {
      headerMessage = 'Page claimed';
      detailsMessage = 'We will contact you as soon as possible and inform you the next steps. For now please proceed with an individual account';
    } else {
      headerMessage = 'Corporation was created';
      detailsMessage = 'We\'ve se up your personal account and corporation profile. Please continue using an application!';
    }
  }

  return (
    <div className="bg-white shadow-sm p-6 pb-12 rounded-md flex flex-col items-center">
      <div className={`h-36 w-36 rounded-full flex items-center justify-center bg-${isRegistrationSuccessful ? 'success' : 'error'}-25`}>
        {
          isRegistrationSuccessful
            ? <CheckIcon className="text-success-500 h-18 w-18" />
            : <ExclamationTriangleIcon className="text-error-600 h-20 w-20" />
        }
      </div>
      <span className="mt-6 font-bold text-3xl">
        {
          headerMessage
        }

      </span>
      <span className="mt-6 text-sm text-neutral-800">
        {
          detailsMessage
         }
      </span>

      <button
        type="button"
        onClick={() => {
          if (isRegistrationSuccessful) {
            history.push(`/profile/${profileIdToRedirect}`);
            return;
          }

          window.location.reload(); // reload
        }}
        className="mt-8 text-base text-main-600 font-semibold p-4 py-3 rounded-md border border-neutral-200"
      >
        Close
      </button>
    </div>
  );
}
