import React from 'react';
import { Link } from 'react-router-dom';
import { SenderProfilePageSummary } from '../../contact-list/contact-list.types';
import UserAvatar from '../../../elements/UserAvatar';
import { ProfilePageType } from '../../../app/account';

interface ProfileLinkProps {
  creator: SenderProfilePageSummary | null,
}

export default function ProfileLink(props: ProfileLinkProps) {

  const { creator } = props;

  return (
    <div>
      <div className="col-span-2 flex justify-end items-center">
        <div className="mr-3 text-right">
          {
            creator ? (
              <Link
                to={`/profile/${creator.profilePageId}`}
                className="text-lg font-bold text-main-600 mt-1 text-center"
              >
                { creator.name }
              </Link>
            ) : <span className="text-lg font-bold text-main-600 text-center">DELETED</span>
          }

          { /* show company set in dynamic fields */ }
          {
            creator?.showCompany && creator.company && (
              <p className="text-sm font-semibold text-neutral-600">
                {creator.company}
              </p>
            )
          }
          { /* show job position set in dynamic fields if company is also set */ }
          {
            creator?.showCompany && creator.company && creator.showJobPosition && creator.jobPosition && (
              <p className="text-xs font-semibold text-neutral-600">                    
                {creator.jobPosition}
              </p>
            )
          }          
          { /* show related corporation name */ }
          {
            !creator?.showCompany && creator?.relatedCorporationProfilePageSummary && (
              <p className="text-xs text-neutral-500">
                Corporation:
                {' '}
                <Link
                  className="underline hover:text-neutral-900 transition-colors"
                  to={`/profile/${creator.relatedCorporationProfilePageSummary.profilePageId}`}
                >
                  { creator.relatedCorporationProfilePageSummary.name }
                </Link>
              </p>
            )
          }

          {
            creator && (
              <p className="text-xs text-neutral-500 pt-0.5">
                { creator.followersCount }
                {' '}
                followers
              </p>
            )
          }
        </div>

        <UserAvatar
          profileID={creator?.profilePageId}
          profileImageUrl={creator?.profileImageUrl}
          profilePageType={creator?.profilePageAccountType || ProfilePageType.individual}
          size="medium"
        />
      </div>
    </div>
  );
}
