import React from 'react';
import { Tab } from '@headlessui/react';
import { ProfilePageType } from '../../../app/account';
import EwanLivesey from './assets/EwanLivesey.jpg';
import JohnJacques from './assets/JohnJacques.jpg';
import PaulLeahy from './assets/PaulLeahy.jpg';
import StefanoBalestrini from './assets/StefanoBalestrini.jpg';

import JohnBrooks from './assets/JohnBrooks.jpg';
import GuyClark from './assets/GuyClark.jpg';
import GrahamFraser from './assets/GrahamFraser-Pye.jpg';
import MarkHersee from './assets/MarkHersee.jpg';
import RichardLearwood from './assets/RichardLearwood.jpg';
import SusanMacdonald from './assets/SusanMacdonald.jpeg';
import LinkedInIcon from './assets/linkedIn-icon.svg';
import UserAvatar from '../../../elements/UserAvatar';

export default function MeetTheTeamTab() {
  return (
    <Tab.Panel style={{ maxWidth: 662 }}>
      <h2 className="mt-12 text-3xl text-main-900 font-extrabold">Meet the Team</h2>

      {/* CEO Ewan Livesey */}
      <div className="bg-white p-6 flex flex-col sm:flex-row gap-4 rounded-md mt-6 shadow-md">
        <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={EwanLivesey} size="big" />

        <div>
          <p className="text-main-900 font-bold text-3xl">
            Ewan Livesey
          </p>
          <p className="text-neutral-700 text-xl mt-2">
            Co-Founder/CEO
          </p>
          <p className="text-neutral-900 text-sm mt-4">
            A qualified lawyer and business development executive with 25+ years’ experience in pharmaceuticals,
            with corporate roles from European Legal Counsel to Chief Business Officer
            for global pharmaceutical companies.
          </p>
          <p className="text-neutral-900 text-sm mt-2">
            An international business leader with a successful track record in driving
            and executing strategy through M&A, licensing and strategic alliances as well as greenfield operations.
          </p>
        </div>
      </div>

      {/* John Jacques */}
      <div className="bg-white p-6 flex flex-col sm:flex-row sm:flex-row-reverse gap-4 rounded-md mt-6 shadow-md">
        <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={JohnJacques} size="big" />

        <div>
          <p className="text-main-900 font-bold text-3xl">
            John Jacques
          </p>
          <p className="text-neutral-700 text-xl mt-2">
            Co-Founder
          </p>
          <p className="text-neutral-900 text-sm mt-4">
            A pharma executive with 35 years’ experience in companies ranging from
            SMEs to BigPharma. Comprehensive knowledge of international directives,
            regulations and guidelines encompassing clinical, CMC, biologics and regulatory affairs.
          </p>
          <p className="text-neutral-900 text-sm mt-2">
            Extensive experience in global market transition, technology transfer and life-cycle
            management for branded products, and with regulatory, pharmacovigilance and
            medical support to speciality/orphan drug products, including promotional compliance.
          </p>
        </div>
      </div>

      {/* Paul Leahy */}
      <div className="bg-white p-6 flex flex-col sm:flex-row gap-4 rounded-md mt-6 shadow-md">
        <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={PaulLeahy} size="big" />

        <div>
          <p className="text-main-900 font-bold text-3xl">
            Paul Leahy
          </p>
          <p className="text-neutral-700 text-xl mt-2">
            Co-Founder/CIO
          </p>
          <p className="text-neutral-900 text-sm mt-4">
            A founding partner and CTO in a start-up B2B telecommunications company (200+ FTEs and annual
            turnover of €15+ million) with several years’ experience working for a global CX outsourcing company.
          </p>
        </div>
      </div>

      {/* Stefano Balestrini */}
      <div className="bg-white p-6 flex flex-col sm:flex-row sm:flex-row-reverse gap-4 rounded-md mt-6 shadow-md">
        <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={StefanoBalestrini} size="big" />

        <div>
          <p className="text-main-900 font-bold text-3xl">
            Stefano Balestrini
          </p>
          <p className="text-neutral-700 text-xl mt-2">
            Co-Founder
          </p>
          <p className="text-neutral-900 text-sm mt-4">
            A pharma professional with 12 years’ experience working in business development
            and licensing, alliance management, sales and distribution.
          </p>
          <p className="text-neutral-900 text-sm mt-2">
            Stefano has lived and worked in six different countries and acquired
            international know-how and exposure of companies both in Europe CIS and the US markets.
          </p>
        </div>
      </div>

      <h2 className="mt-12 text-3xl text-main-900 font-extrabold">Our Advisory Board</h2>

      <div className="mt-6 flex flex-col gap-6">
        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={JohnBrooks} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              John Brooks
            </p>
            <p className="text-sm pb-2">
              Sales & Marketing Expert and Entrepreneur
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/telecomms/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/telecomms/
              </a>
            </div>
          </div>
        </div>

        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={GuyClark} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              Guy Clark
            </p>
            <p className="text-sm pb-2">
              CEO, CNX Therapeutics
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/guyhclark/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/guyhclark/
              </a>
            </div>
          </div>
        </div>

        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={GrahamFraser} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              Graham Fraser-Pye
            </p>
            <p className="text-sm pb-2">
              Chairman, Aspire Pharma Ltd
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/graham-fraser-pye-54a79a1/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/graham-fraser-pye-54a79a1/
              </a>
            </div>
          </div>
        </div>

        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={MarkHersee} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              Mark Hersee
            </p>
            <p className="text-sm pb-2">
              Partner, BC Partners
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/mark-hersee-17681014b/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/mark-hersee-17681014b/
              </a>
            </div>
          </div>
        </div>

        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={RichardLearwood} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              Richard Learwood
            </p>
            <p className="text-sm pb-2">
              Former Vice President, Consumer Healthcare P&G
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/richard-learwood-11813a2/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/richard-learwood-11813a2/
              </a>
            </div>
          </div>
        </div>

        {/* LinkedIn entry */}
        <div className="relative overflow-hidden bg-white p-6 flex flex-row gap-4 rounded-md shadow-md">
          <div className="w-20">
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={SusanMacdonald} size="bigger" />
          </div>

          <div className="flex flex-col justify-center">
            <p className="text-main-900 font-bold text-2xl">
              Susan Macdonald
            </p>
            <p className="text-sm pb-2">
              Director, Macdonald Lewis Associates Ltd
            </p>
            <div className="flex flex-row">
              <img
                src={LinkedInIcon}
                alt="LinkedIn Icon"
                className="mr-2"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/susan-macdonald-0727901/"
                className="flex flex-row mt-3 text-main-900 text-sm mt-2 truncate"
              >
                https://www.linkedin.com/in/susan-macdonald-0727901/
              </a>
            </div>
          </div>
        </div>
      </div>
    </Tab.Panel>
  );
}
