import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormCreateHeader from './FormCreateHeader';
import ModuleInfoSupportMessage from '../../../../components/ModuleInfoSupportMessage';
import Input from '../../../../../../elements/Input';
import { RootState } from '../../../../../../app/store';
import Button from '../../../../../../elements/Button';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
  displayCreateProductForm, resetCreateProductForm,
  updateCreateProductDescription,
  updateCreateProductName, updateCreateProductProjectName, updateCreateProductTags,
} from '../../productsModuleSlice';
import Textarea from '../../../../../../elements/Textarea';
import GroupSelection from './GroupSelection';
import TagSelection from '../../../../components/TagSelection';
import { useCreateProductMutation, useUpdateProductMutation } from '../../productsModuleAPI';
import ModuleLoader from '../../../../components/ModuleLoader';
import DuplicateForm from './DuplicateForm';
import { ProductTypes } from '../../products-module.types';
import RegulatoryStatusInformation from './RegulatoryStatusInformation';
import ActiveIngredients from './ActiveIngredients';
import LicenceNumber from './LicenceNumber';
import ProductCategories from './ProductCategories';
import ATCGroup from './ATCGroup';
import MarketingHolders from './MarketingHolders';
import Presentation from './Presentation';

interface ProductCreateEditFormProps {
  formType: 'edit' | 'create',
  productID: number,
  setProductViewInViewMode?: () => void,
}

export default function ProductCreateEditForm(props: ProductCreateEditFormProps) {

  const { formType, productID, setProductViewInViewMode } = props;

  const [duplicateForm, setDuplicateForm] = useState(false);

  const dispatch = useAppDispatch();

  const {
    createProductFormData: formData,
  } = useSelector((state: RootState) => state.profileModules.productsModule);
  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const createProductFormData = formData[productID];

  const [createProduct, { isLoading: isCreatingProduct }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdatingProduct }] = useUpdateProductMutation();

  if (! createProductFormData) {
    return (
      <ModuleLoader />
    );
  }

  // todo: cancel edit somehow
  const onCancelForm = () => {
    if (formType === 'create') {
      dispatch(displayCreateProductForm({
        showForm: false,
      }));
    }

    if (formType === 'edit') {
      dispatch(resetCreateProductForm(productID));
      if (setProductViewInViewMode) { setProductViewInViewMode(); }
    }
  };

  const onAddProduct = async () => {

    if (formType === 'create') {
      await createProduct({
        requesterID,
        profileID: currentlyLoadedProfileID,
        createProductFormData,
      });
    }

    if (formType === 'edit') {
      await updateProduct({
        requesterID,
        profileID: currentlyLoadedProfileID,
        createProductFormData,
      });

      if (setProductViewInViewMode) { setProductViewInViewMode(); }
    }

    // duplicate form
    dispatch(displayCreateProductForm({
      showForm: duplicateForm,
      product: formType === 'edit' ? createProductFormData : null, // set data in new form
    }));
  };

  const preparedTags = createProductFormData.coreTags
    ? createProductFormData.coreTags.map((tag) => tag.coreTag)
    : [];

  return (
    <div>
      {
        // block header
        formType === 'create' && <FormCreateHeader productID={productID} />
      }
      <div className="mt-4 flex flex-col items-center">
        {/* Product name */}
        <div className="w-3/5 mb-4">
          <Input
            id={`${formType}-productName`}
            label="Product name"
            value={createProductFormData.productName}
            placeholder="Start typing..."
            onChange={(e) => dispatch(updateCreateProductName({ value: e.target.value, productID }))}
          />
        </div>
        {/* Group */}
        <div className="w-3/5 mb-4">
          <GroupSelection
            productID={productID}
            value={createProductFormData.productGroup ? createProductFormData.productGroup.productGroupName : ''}
          />
        </div>
        {/* Description */}
        <div className="w-3/5 mb-4">
          <Textarea
            // todo debounce 300-500ms
            value={createProductFormData.description ? createProductFormData.description : ''}
            placeholder="Start typing..."
            onChange={(e) => dispatch(updateCreateProductDescription({ value: e.target.value, productID }))}
          />
        </div>

        {
          createProductFormData.productType === ProductTypes.standardProduct && (
            <>
              {/* Active Ingredients */}
              <div className="w-3/5 mb-4">
                <ActiveIngredients
                  productID={productID}
                  activeIngredients={createProductFormData.activeIngredients}
                />
              </div>

              {/* Dosage form / Strength / Presentation */}
              <div className="w-3/5 mb-4">
                <Presentation
                  productID={productID}
                  presentations={createProductFormData.presentations}
                />
              </div>

              {/* Regulatory status information */}
              <div className="w-3/5 mb-4">
                <RegulatoryStatusInformation
                  productID={productID}
                  statusInfo={createProductFormData.regStatusInformation}
                />
              </div>

              {/* Marketing Authorisation Holder / Product Owner */}
              <div className="w-3/5 mb-4">
                <MarketingHolders
                  productID={productID}
                  holders={createProductFormData.holderOwners}
                />
              </div>

              {/* Licence numbers */}
              <div className="w-3/5 mb-4">
                <LicenceNumber
                  productID={productID}
                  licences={createProductFormData.licences}
                />
              </div>

              {/* Product Categories */}
              <div className="w-3/5 mb-4">
                <ProductCategories
                  productID={productID}
                  productCategories={createProductFormData.productCategories}
                />
              </div>

              {/* ATC Group */}
              <div className="w-3/5 mb-4">
                <ATCGroup
                  productID={productID}
                  atcGroupValue={createProductFormData.atcGroup}
                />
              </div>

              {/* Project name */}
              <div className="w-3/5 mb-4">
                <Input
                  id={`${formType}-productName`}
                  label="Project Name (optional)"
                  value={createProductFormData.projectName ? createProductFormData.projectName : ''} // or backend must return empty string instead of null
                  placeholder="Project"
                  onChange={(e) => dispatch(updateCreateProductProjectName({ value: e.target.value, productID }))}
                />
              </div>
            </>
          )
        }

        {/* Tags */}
        <div className="w-3/5">
          <TagSelection
            placeholder="Tags"
            value={preparedTags}
            onChange={(t) => dispatch(updateCreateProductTags({ value: t, productID }))}
          />
        </div>

        <div className="w-3/5 mt-6">
          <DuplicateForm
            duplicateFormFlag={duplicateForm}
            productID={productID}
            setDuplicateForm={(flag) => setDuplicateForm(flag)}
          />
        </div>

        {/* Action buttons */}
        <div className="w-3/5 mt-4 mb-6 flex justify-end">
          <div className="pr-2">
            <Button
              onClick={onCancelForm}
              size="medium"
              inverted
              description="Cancel"
              disabled={isCreatingProduct || isUpdatingProduct}
            />
          </div>
          <Button
            onClick={onAddProduct}
            size="medium"
            description={formType === 'create' ? 'Add Product' : 'Save changes'}
            isLoading={isCreatingProduct || isUpdatingProduct}
          />
        </div>
        <ModuleInfoSupportMessage />
      </div>
    </div>
  );
}
