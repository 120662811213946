import React, { useEffect, useRef, useState } from 'react';
import YoutubeVideo from './youtube-video/YoutubeVideo';
import FileDownloadPreview from '../../elements/FileDownloadPreview';
import PostWrap from './PostWrap';
import { CreatePostState } from './create-post/createPostSlice';
import PostLink from './post-link/PostLink';
import { ProfilePageType } from '../../app/account';
import PostImage from './PostImage';
import { IFeedFileResponse } from '../feed/feed-types';

export interface PostProps extends Omit<CreatePostState, 'user'> {
  postID?: number,
  youtubeURL?: string,
  fileAttachmentsFromBackend?: IFeedFileResponse[], // PDFs are local (on UI), fileAttachments
  user: {
    isCorporateUser: boolean,
    name: string,
    avatarURL: string,
    profilePageId: number,
    relatedCorporationName: string | null | undefined,
    relatedCorporationProfilePageId: number | null | undefined,
    showCompany: boolean,
    company: string,
    showJobPosition: boolean,
    jobPosition: string,
  }
}

export default function Post(props: PostProps) {

  const {
    postID, user, timestamp, text, links, images, youtubeURL, fileAttachmentsFromBackend, PDFs,
  } = props;

  const clampedText = useRef(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [textWideOpen, setTextWideOpen] = useState(false);

  useEffect(() => {
    if (! clampedText || ! clampedText.current) {
      return;
    }

    // @ts-ignore
    if (clampedText.current.scrollHeight > clampedText.current.clientHeight) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, []);

  return (
    <PostWrap
      postID={postID}
      timestamp={timestamp}
      accountType={user.isCorporateUser ? ProfilePageType.corporation : ProfilePageType.individual}
      userName={user.name}
      avatarURL={user.avatarURL}
      profilePageId={user.profilePageId}
      relatedCorporationName={user.relatedCorporationName}
      relatedCorporationProfilePageId={user.relatedCorporationProfilePageId}
      showCompany={user.showCompany}
      company={user.company}
      showJobPosition={user.showJobPosition}
      jobPosition={user.jobPosition}
    >
      <>
        { /* CONTENT */ }
        <div className="mb-2 inline relative">
          <span ref={clampedText} className={`${textWideOpen ? '' : 'line-clamp-5'} whitespace-pre-line`}>
            { text }
          </span>
          <div className="flex justify-end">
            {
              (showMoreButton && ! textWideOpen)
                ? (
                  <span
                    tabIndex={0}
                    role="button"
                    className="text-sm font-bold text-main-600 cursor-pointer"
                    onKeyDown={() => setTextWideOpen(true)}
                    onClick={() => setTextWideOpen(true)}
                  >
                    more
                  </span>
                ) : null
            }
          </div>
        </div>

        { /* IMAGES */ }
        <div className="mt-6">
          {
            images.map((image) => (
              <PostImage key={image.id} id={image.id} blobURL={image.blobURL} inEditMode={false} guid={image.guid} />
            ))
          }
        </div>

        { /* LINKS */ }
        <div className="mt-6">
          {
            links.map((link) => (
              <PostLink
                key={link.id}
                onDelete={() => {}}
                onEdit={() => {}}
                id={link.id}
                linkURL={link.linkURL}
                title={link.title}
                inEditMode={false}
                allowEdit={false}
              />
            ))
          }
        </div>

        { /* ATTACHMENTS (PDF's) */ }
        <div className="mt-6">
          {
            PDFs.map((file) => (
              <FileDownloadPreview
                key={file.id}
                file={file.file}
              />
            ))
          }
          {
            fileAttachmentsFromBackend?.map((file) => (
              <FileDownloadPreview
                key={file.id}
                file={{
                  ...file,
                  title: file.description,
                }}
              />
            ))
          }
        </div>

        {
          youtubeURL && <YoutubeVideo url={youtubeURL} />
        }
      </>
    </PostWrap>
  );
}

Post.defaultProps = {
  youtubeURL: '',
};
