import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import Tag from '../../../elements/Tag';
import { classNames } from '../../../helpers/styling';
import { QuestionEntry } from '../forum-types';
import ClumpedContent from '../question-page/ClumpedContent';
import QuestionPreviewProfile from './QuestionPreviewProfile';
import { setForumFiltersTags } from './forumQuestionsSlice';
import { useAppDispatch } from '../../../app/hooks';

interface QuestionPreviewProps {
  question: QuestionEntry,
  first?: boolean,
  last?: boolean,
  showCreatorProfile?: boolean,
}

export default function QuestionPreview(props: QuestionPreviewProps) {

  const { 
    first, 
    last, 
    question, 
    showCreatorProfile = true, 
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <div className={
      classNames(
        'grid grid-cols-12 pt-4 pb-8',
        first ? '' : 'mt-4',
        last ? '' : 'border-b border-neutral-200',
      )
    }
    >
      {
        showCreatorProfile && (
          <div className="col-span-2">
            <QuestionPreviewProfile creatorProfile={question.creatorProfilePageSummary} />
          </div>        
        )
      }

      <div className={` ${showCreatorProfile ? 'px-4 col-span-10' : 'col-span-12'}`}>
        <Link
          to={`/forum/question/${question.id}`}
          className="text-lg font-semibold text-main-600 max-w-[90%]"
        >
          { question.title }
        </Link>

        <div className="mb-2">
          <span className="text-xs text-neutral-700">
            {question.totalAnswers}
            {' '}
            answers
          </span>
          <span className="text-xs text-neutral-700 ml-4">
            {question.votesCounter}
            {' '}
            votes
          </span>
          <span className="text-xs text-neutral-700 ml-4">
            {question.views}
            {' '}
            views
          </span>
        </div>

        <div className={`my-4 text-sm ${showCreatorProfile ? 'max-w-[90%]' : ''}`}>
          <ClumpedContent text={question.content} lineClamp="line-clamp-3" />
        </div>

        <div className="flex flex-wrap gap-2 pt-4">
          {
            question.tags && question.tags.map((tag) => (
              <Tag
                key={tag.id}
                name={tag.name}
                onClick={() => {
                  dispatch(setForumFiltersTags([{
                    ...tag,
                    value: tag.id,
                    label: tag.name,
                  }]));

                  if (history.location.pathname === '/forum') return;

                  history.push('/forum');
                }}
              />
            ))
          }
        </div>

        <div className="mt-2">
          <span className="text-xs text-neutral-900">
            <time dateTime={`${question.createdAtUtc}+00:00`}>
              <span className="text-neutral-500">Created</span>
              {' '}
              { DateTime.fromISO(`${question.createdAtUtc}+00:00`).toRelative() }
            </time>
          </span>

          {
            question.edited && (
              <span className="text-xs text-neutral-700 ml-4">
                <time className="text-neutral-900" dateTime={`${question.updatedAtUtc}+00:00`}>
                  <span className="text-neutral-500">Updated</span>
                  {' '}
                  { DateTime.fromISO(`${question.updatedAtUtc}+00:00`).toRelative() }
                </time>
              </span>
            )
          }
        </div>
      </div>
    </div>
  );
}
