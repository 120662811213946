import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { clone } from 'lodash';
import { ISearchFilter, ISearchRequestFilter } from './search-types';

export interface SearchSlice {
  currentSearchString: string,
  currentSearchCacheID: string,

  filtersHaveChanged: boolean,

  localFilters: ISearchFilter[],

  appliedFilters: ISearchRequestFilter[],

  searchCacheIDs: {
    [key: string]: string, // search string - guid
  }
}

const initialState: SearchSlice = {
  currentSearchString: '',
  currentSearchCacheID: '',
  filtersHaveChanged: false,
  localFilters: [],
  appliedFilters: [],
  searchCacheIDs: {},
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,

  reducers: {
    setSearchString: (state, action) => {
      const uuid = state.searchCacheIDs[action.payload] || uuidv4();

      state.searchCacheIDs[action.payload] = uuid;

      state.currentSearchCacheID = uuid;
      state.currentSearchString = action.payload;

      state.appliedFilters = initialState.appliedFilters; // reset
    },

    setLocalFilters: (state, action: PayloadAction<ISearchFilter[]>) => {
      state.localFilters = action.payload;
    },

    clearAllFilters: (state) => {
      state.filtersHaveChanged = true;

      state.localFilters = state.localFilters.map((lf) => ({
        ...lf,
        items: lf.items.map((i) => {
          const itemCopy = clone(i);

          itemCopy.isSelected = false;

          return itemCopy;
        }),
      }));
    },

    // note: can be optimized (performance)
    setFilterSelected: (state, action: PayloadAction<{ type: string, filterNames: string[] }>) => {
      state.filtersHaveChanged = true; // flag comparison can be more complex and precise (now if state returned back it will be still true)

      state.localFilters = state.localFilters.map((lf) => {
        if (lf.filterType !== action.payload.type) return lf;

        return {
          ...lf,
          items: lf.items.map((i) => {
            const itemCopy = clone(i);

            itemCopy.isSelected = action.payload.filterNames.includes(i.name);

            return itemCopy;
          }),
        };
      });
    },

    applyFilters: (state) => {
      state.filtersHaveChanged = false;

      const filters = state.localFilters.map((localFilter) => localFilter.items.filter((i) => i.isSelected).map((item) => ({
        filterType: localFilter.filterType,
        itemName: item.name,
      })));

      state.appliedFilters = filters.reduce((acc, filter) => [...acc, ...filter], []);
    },
  },
  extraReducers: () => {

  },
});

export const {
  setSearchString,
  setLocalFilters,
  setFilterSelected,
  clearAllFilters,
  applyFilters,
} = searchSlice.actions;

export default searchSlice;
