import React from 'react';
import Button from '../Button';
import { usePagination } from './usePagination';
import ShowingPagesText from './ShowingPagesText';
import PagesNav from './PagesNav';

interface PaginationProps {
  currentPage: number,
  pageSize: number,
  totalRecords: number,
  totalPages: number,
  siblingCount?: number,
  onPageChange: (page: number) => void,
  label?: string,
}

// todo: on pagination scroll to top
export default function Pagination(props: PaginationProps) {

  const {
    totalPages, 
    totalRecords, 
    pageSize, 
    currentPage, 
    onPageChange, 
    siblingCount = 1, 
    label,
  } = props;

  const showingStartCount = (currentPage - 1) * pageSize + 1;
  const showingEndCount = currentPage * pageSize > totalRecords ? totalRecords : currentPage * pageSize;

  const localSetNextPage = (page: number) => {
    onPageChange(page);
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount: totalRecords,
    siblingCount,
    pageSize,
  });

  return (
    <div className="flex items-center justify-between border-neutral-200 bg-white">
      {
        (currentPage === 0 || paginationRange.length < 2) ? (
          <div className="sm:hidden">
            <ShowingPagesText
              totalRecords={totalRecords}
              showingStartCount={totalRecords === 0 ? totalRecords : showingStartCount}
              showingEndCount={showingEndCount}
              label={label}
            />
          </div>
        ) : (
          <div className="flex flex-1 justify-between sm:hidden">
            <Button
              onClick={() => localSetNextPage(currentPage - 1)}
              size="small"
              inverted
              description="Previous"
            />
            <Button
              onClick={() => localSetNextPage(currentPage + 1)}
              size="small"
              inverted
              description="Next"
            />
          </div>
        )
      }
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <ShowingPagesText
          totalRecords={totalRecords}
          showingEndCount={showingEndCount}
          showingStartCount={totalRecords === 0 ? totalRecords : showingStartCount}
          label={label}
        />

        <PagesNav
          currentPage={currentPage}
          paginationRange={paginationRange}
          onPageChange={localSetNextPage}
        />
      </div>
    </div>
  );
}
