import React, { useState } from 'react';
import { PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';
import { useAddOrUpdateTagMutation, useDeleteTagMutation } from './tagAPI';
import { useFormValidation } from '../../helpers/validation';
import { ValidationKeys, rulesTagName, validationErrorState } from './tagEditorValidationRules';
import Input from '../../elements/Input';

interface TagEditorProps {
  tagId?: number,
  tagInitialValue: string,
}

export default function TagEditor(props: TagEditorProps) {  
  
  const { tagId, tagInitialValue } = props;
  const [inputValue, setInputValue] = useState(tagInitialValue);
  const [valueHasChanged, setValueHasChanged] = useState(false); 
  const [addOrUpdateRequest, { isLoading: isAddingOrUpdatingTag }] = useAddOrUpdateTagMutation();
  const [deleteTagRequest, { isLoading: isDeletingTag }] = useDeleteTagMutation();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesTagName,
    validationErrorState,
    {
      inputValue,
    },
  );

  const onAddOrEditTag = async () => {   

    const val = validate();
    if (! val.allValid) return;

    await addOrUpdateRequest({
      tagId,
      tagName: inputValue,
    });

    if (!tagId) {
      setInputValue('');
    }
    setValueHasChanged(false);
  };

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    if (newValue !== tagInitialValue) { 
      setValueHasChanged(true); 
    } else {
      setValueHasChanged(false); 
    }
    setInputValue(newValue);
  };

  const onRemoveTag = async () => {
    await deleteTagRequest({
      tagId,
    });
  };

  return (
    <div className="py-2.5 flex w-full flex-row">
      <div className="w-3/5">
        <Input
          placeholder=""
          type="text"
          // defaultValue={tagInitialValue}
          onChange={(event) => {
            handleInputChange(event);
            onValidationInputChange('inputValue');
          }}
          value={inputValue}
          // className="block flex-grow pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:placeholder-neutral-400 focus:ring-1 focus:ring-main-500 focus:border-main-500"
          validationMessage={validationState.inputValue.errorMessage}
        />        
      </div>
      <div className="ml-2 flex">
        <Button
          onClick={onAddOrEditTag}
          size="x-medium"
          description={tagId ? 'Update Tag' : 'Add Tag'}
          disabled={!valueHasChanged && tagId != null}
          isLoading={isAddingOrUpdatingTag}
          iconLeft={tagId ? <PencilSquareIcon className="w-4 h-4 mr-2" /> : <PlusIcon className="w-4 h-4 mr-2" />} 
          styles={`items-start ${!tagId && 'px-6'}`}
        />
        {
          tagId && (
            <div className="ml-2">
              <Button
                onClick={onRemoveTag}
                size="x-medium"
                description="Remove Tag"
                inverted
                styles="text-error-600"
                isLoading={isDeletingTag}
                iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
              />               
            </div>
          )
        }
      </div>
    </div>
  );
}
