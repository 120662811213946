import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import ModuleLoader from '../../components/ModuleLoader';
import { useGetProductsModuleQuery } from './productsModuleAPI';
import GroupView from './components/GroupView';
import ProductView from './components/ProductView';

interface ProductsModuleViewProps {
  // eslint-disable-next-line react/no-unused-prop-types
  setEditMode?: () => void,
  inEditMode?: boolean,
}

export default function ProductsModuleView(props: ProductsModuleViewProps) {

  const { inEditMode = false } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const { data: groupedProducts, isLoading } = useGetProductsModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: !currentlyLoadedProfileID },
  );

  if (isLoading || (! groupedProducts)) {
    return (
      <ModuleLoader />
    );
  }

  return (
    <>
      {
        groupedProducts.noGroupProducts.products.map((product) => (
          <ProductView key={product.id} product={product} inEditMode={inEditMode} />
        ))
      }

      {
        groupedProducts.groups.map((group) => (
          <GroupView
            key={group.groupId}
            group={group}
            inEditMode={inEditMode}
            // @ts-ignore
            products={groupedProducts[group.groupId].products}
          />
        ))
      }
      <div className="w-full pt-4 border-t" />
    </>
  );
}
