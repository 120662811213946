export interface GetCorporationStatisticsRequest {
  corporationID: number | undefined, 
  requesterProfilePageId: number, 
  period: string,
}

export enum PeriodSortFilters {
  past7Days = 'past7Days',
  pastMonth = 'pastMonth',
  pastYear = 'pastYear',
  allTime = 'allTime',
}

export interface Statistics {
  totalUsersLabel: string,
  activeUsersLabel: string,
  values: ChartItem[],
}

export interface ChartItem {
  dateLabel: string,
  activeUsersCount: string,
  totalUsersCount: number,
}

export interface Corporation {
  id: number,
  name: string,
}
