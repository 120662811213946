import React from 'react';

import MessagesWidget from '../../messages/MessagesWidget';
import SavedSearchesWidget from '../../search-results/SavedSearchesWidget';

export default function FeedRightSidebar() {
  return (
    <div className="sticky top-4 flex flex-col">
      <MessagesWidget />
      <SavedSearchesWidget />
    </div>
  );
}
