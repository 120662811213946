import { emptySplitApi } from '../../app/api';
import {
  ContactList, ContactListRaw, ContactRequest,
} from './contact-list.types';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { resetFeedList } from '../feed/feedSlice';
import { UpdateRequestResponse } from '../requests/request-types';

export const contactListAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactsList: builder.query<ContactList, { profilePageID?: number }>({
      query({ profilePageID }) {
        return {
          url: `/Contact/profile-page/${profilePageID}/merged`,
          method: 'GET',
        };
      },
      transformResponse: (response: ContactListRaw) => {
        const transformed: any = {
          totalRecords: response.totalRecords,
        };

        const list = response.data ? response.data : [];

        transformed.letterGroupKeys = list.map((contact) => contact.alphabetLetter);

        Object.keys(list).forEach((value: string) => {
          const key = parseInt(value, 10);

          if (! transformed.letterGroups) {
            transformed.letterGroups = {};
          }

          transformed.letterGroups[list[key].alphabetLetter] = {
            // contactListEntryKeys: list[key].contacts.map((contactEntry: ContactListEntry) => contactEntry.contactProfilePageId),
            contacts: list[key].contacts,
          };
        });

        return transformed;
      },
      providesTags: (result, error, arg) => [{ type: 'ContactList', profileID: arg.profilePageID }],
    }),
    acceptContactRequest: builder.mutation<UpdateRequestResponse, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Contact/request/accepted',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact request was accepted.'));
          dispatch(resetFeedList());
        } catch {
          dispatch(addFlashMessage('error', 'Contact request cannot be accepted. Please try again.'));
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'ContactList', profileID: arg.receiverProfilePageId },
        { type: 'ProfilePage', profileID: arg.senderProfilePageId, requesterID: arg.receiverProfilePageId },
        { type: 'ContactListRequests', profileID: arg.receiverProfilePageId },
        'ChatThreadsList',
        'FeedPosts', // used for feed reload
      ],
    }),
    rejectContactRequest: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Contact/request/rejected',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact request was rejected.'));
          dispatch(resetFeedList());
        } catch {
          dispatch(addFlashMessage('error', 'Contact request cannot be rejected. Please try again.'));
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'ContactListRequests', profileID: arg.receiverProfilePageId },
        'FeedPosts',  // used for feed reload
      ],
    }),
    getContactsListRequests: builder.query<{ data: ContactRequest[] }, { profilePageID?: number }>({
      query({ profilePageID }) {
        return {
          url: `/Contact/request/profile-page/${profilePageID}?PageSize=3&PageNumber=1`,
          method: 'GET',
        };
      },

      providesTags: (result, error, arg) => [
        { type: 'ContactListRequests', profileID: arg.profilePageID },
      ],
    }),
  }),
});

export const {
  useGetContactsListQuery,
  useGetContactsListRequestsQuery,

  useAcceptContactRequestMutation,
  useRejectContactRequestMutation,
} = contactListAPI;
