import { emptySplitApi } from '../../../app/api';
import { VoteOperation } from '../forum-types';
import { addFlashMessage } from '../../flash-messages/flashMessagesSlice';

export const questionAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    voteForQuestion: builder.mutation<any, { questionID: number, requesterID?: number, voteOperation: VoteOperation }>({
      query: (data) => ({
        url: `/Forum/question/${data.questionID}/vote/requester-profile-page/${data.requesterID}`,
        method: 'PUT',
        body: {
          voteOperation: data.voteOperation,
        },
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Vote is updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [
        { type: 'ForumQuestionsList' },
        {
          type: 'ForumQuestion',
          questionID: arg.questionID,
        },
      ],
    }),

  }),
});

export const {
  useVoteForQuestionMutation,
} = questionAPI;
