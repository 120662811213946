import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import logoMark from './assets/images/logoMark.svg';

interface FeatureItemProps {
  title: string,
  bullets: string[],
}

export default function FeatureItem(props: FeatureItemProps) {

  const { title, bullets } = props;

  return (
    <Fade top>
      <div className="flex flex-row mt-12 items-start">
        <img src={logoMark} alt="Nucleo Logo Mark" />

        <div className="ml-4">
          <h3 className="font-bold text-main-900 text-2xl">{title}</h3>

          <div className="mt-2">
            <ul className="list-disc">
              {
                bullets.map((bullet) => (
                  <li className="text-neutral-800 ml-4 text-base" key={bullet}>{bullet}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </Fade>
  );
}
