import React from 'react';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Button from './Button';

interface SuccessBlockProps {
  onClick: () => void,
  title: string,
  description: string,
  successful: boolean,
  buttonToRight?: boolean,
  buttonSize?: 'medium' | 'big' | 'small',
  inverted?: boolean,
}

export default function SuccessBlock(props: SuccessBlockProps) {

  const {
    onClick, title, description, successful, buttonToRight, buttonSize = 'big', inverted,
  } = props;

  return (
    <div className="rounded-md flex flex-col items-center">
      <div className={`h-36 w-36 rounded-full ${successful ? 'bg-success-25' : 'bg-error-25'} flex justify-center items-center`}>
        {
          successful ? (
            <CheckIcon className={`${inverted ? 'text-success-300' : 'text-success-500'} h-28 w-28`} />
          ) : (
            <ExclamationTriangleIcon className="text-error-600 h-28 w-28" />
          )
        }
      </div>
      <span className={`mt-6 font-bold text-3xl ${inverted && 'text-white'}`}>
        { title }
      </span>
      <span className={`mt-6 text-sm text-center ${inverted ? 'text-white' : 'text-neutral-800'}`}>
        { description }
      </span>

      <div className={`mt-10 flex w-full ${buttonToRight ? 'justify-end' : 'justify-center'}`}>
        <Button
          size={buttonSize}
          inverted
          onClick={onClick}
          description="Close"
        />
      </div>
    </div>
  );
}
