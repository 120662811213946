import React from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { classNames } from '../../../../helpers/styling';
import { NavElement } from '../../types';

interface AdministrationSectionProps {
  navGroup: NavElement[]
}

export default function AdministrationSection(props: AdministrationSectionProps) {

  const { navGroup } = props;

  if (navGroup.length === 0) return null;

  return (
    <Menu.Items className="bg-main-25 rounded-md">
      <span className="pl-4 text-2xs font-semibold text-gray-500">Administration</span>
      {
          navGroup.map((navItem) => (
            <Menu.Item key={navItem.link}>
              {({ active }) => (
                <Link
                  to={navItem.link}
                  className={classNames(active ? 'bg-main-50' : '', 'block pr-4 py-3 pl-8 text-sm text-neutral-700')}
                >
                  <div className="flex flex-row items-center">
                    { navItem.icon(`h-4 w-4 mr-4 text-neutral-800 ${active && 'text-main-500'}`) }
                    <span className={`font-semibold text-sm ${active && 'text-main-600'}`}>
                      { navItem.name }
                    </span>
                  </div>
                </Link>
              )}
            </Menu.Item>
          ))
            }
    </Menu.Items>
  );
}
