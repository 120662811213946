import React from 'react';
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import Button from '../../elements/Button';
import { useDeletePostMutation } from '../feed/feedAPI';
import { RootState } from '../../app/store';

interface PostDeleteOverlayProps {
  postID: number,
  isDeleteMode: boolean,
  setDeleteMode: (flag: boolean) => void,
}
export default function PostDeleteOverlay(props: PostDeleteOverlayProps) {

  const { postID, isDeleteMode, setDeleteMode } = props;

  const { getListRequest } = useSelector((state: RootState) => state.feedModeration);

  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();

  return (
    <Transition
      show={isDeleteMode}
    >
      <div className="absolute z-30 w-full h-full flex items-center justify-center border border-error-600 border-4 rounded rounded-lg">

        <Transition.Child
          enter="transition-opacity duration-125"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-125"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-white p-4 rounded-md shadow-lg relative z-10">
            <div className="py-2 flex flex-row items-center">
              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-error-25">
                <ExclamationTriangleIcon className="h-4 w-4 text-error-600" aria-hidden="true" />
              </div>
              <span className="pl-4 font-bold">Delete post?</span>
            </div>
            <p style={{ maxWidth: 400 }} className="font-semibold text-sm mt-1">
              Deleting a post is an action that cannot be undone.
            </p>
            <div className="flex flex-row justify-end mt-6">
              <Button
                onClick={() => setDeleteMode(false)}
                roundedFull
                size="small"
                inverted
                description="Cancel"
                disabled={isDeleting}
              />
              <div className="pl-2">
                <Button
                  onClick={async () => {
                    await deletePost({ postID, moderationListRequest: getListRequest });
                  }}
                  iconLeft={
                    isDeleting ? <ArrowPathIcon className="h-4 w-4 text-error-600 mr-2.5 animate-spin" /> : undefined
                  }
                  isLoading={isDeleting}
                  size="small"
                  roundedFull
                  fullRed
                  inverted
                  description="Yes, delete post"
                />
              </div>
            </div>
          </div>
        </Transition.Child>

        <div className="bg-white/20 absolute w-full h-full backdrop-blur-sm" />
      </div>
    </Transition>
  );
}
