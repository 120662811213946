import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import FlashMessages from '../flash-messages/FlashMessages';
import { useAcceptMemberInvitationMutation } from './settingsAPI';
import Button from '../../elements/Button';
import { useBrandingLocale, useCupidoLogin, useDocumentTitle } from '../../helpers/hooks';
import { RootState } from '../../app/store';
import Logo from '../../elements/Logo';
import { AcceptMemberInvitationResponseResultEnum, CorporationOwnershipInvitationStatus } from './setings-types';
import { BRANDING, EMAIL_SUPPORT } from '../../helpers/branding';

export default function AcceptInvitation() {

  const [successState, setSuccessState] = useState(false);

  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);

  // @ts-ignore
  const { invitationID } = useParams();
  const history = useHistory();

  const { instance, handleLogin } = useCupidoLogin();

  const [acceptInvitationQuery, { isLoading: isAcceptingInProgress, data: response }] = useAcceptMemberInvitationMutation();

  useDocumentTitle('Accept Invitation');

  const { t } = useBrandingLocale();

  useEffect(() => {
    if (profileID) {
      history.push(`/profile/${profileID}`);
      return;
    }

    acceptInvitationQuery({ invitationCode: invitationID });
  }, [acceptInvitationQuery, invitationID, profileID, history]);

  const mailtoLink = `mailto:${EMAIL_SUPPORT}`;

  let content = null;
  
  if (isAcceptingInProgress || profileID) {
    content = (
      <div className="w-full h-60 mx-auto mt-8">
        <div className="flex animate-pulse flex-row h-full space-x-5">
          <div className="w-full flex flex-col space-y-3">
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
            <div className="w-[470px] bg-neutral-500 h-4 rounded-md " />
          </div>
        </div>
      </div>
    );
  }
  
  if (!isAcceptingInProgress 
      && response 
      && response.corporationProfilePageSummary != null 
      && response.result === AcceptMemberInvitationResponseResultEnum.success) {
    content = (
      <div>
        <p className="p-4 font-bold text-xl">
          Success! Invite has been accepted. Please check your email for credentials and log in into an application.
        </p>
        <div className="pt-6 flex justify-end w-full">
          <Button
            onClick={() => handleLogin(instance)}
            size="big"
            description="Log in"
          />
        </div>
      </div>
    );
  }

  if (!isAcceptingInProgress 
      && response 
      && response.result === AcceptMemberInvitationResponseResultEnum.invalidInvitation) {
    content  = (
      <>
        <div className="flex justify-center items-center mb-4">
          <ExclamationTriangleIcon className="h-4 w-4 text-error-600 mr-2" aria-hidden="true" />
          <h3 className="text-center font-bold text-xl">
            The invitation is not valid
          </h3>
        </div>
        <p className="text-md">
          If you think this is an error, please contact us by email to:&nbsp;
          <a href={mailtoLink} className="font-semibold text-main-600">
            { EMAIL_SUPPORT }
          </a>
          . We will be happy to reply as soon as possible.
        </p>
      </>
    );
  }

  if (!isAcceptingInProgress 
    && response 
    && response.result === AcceptMemberInvitationResponseResultEnum.alreadyAccepted) {
    content  = (
      <>
        <div className="flex justify-center items-center mb-4">
          <ExclamationTriangleIcon className="h-4 w-4 text-error-600 mr-2" aria-hidden="true" />
          <h3 className="text-center font-bold text-xl">
            The invitation has already been accepted
          </h3>
        </div>
        <p className="text-md">
          It looks like you have already accepted this invitation. Please check your email for your login credentials.
        </p>
        <p className="text-md mt-4">
          If you cannot find the email, make sure to check your spam or junk folder.
        </p>
        <p className="text-md mt-8">
          If you need further assistance, please contact us by email to:&nbsp;
          <a href={mailtoLink} className="font-semibold text-main-600">
            { EMAIL_SUPPORT }
          </a>
          . We will be happy to reply as soon as possible.
        </p>
      </>
    );
  }

  return (
    <div className="inline-block align-bottom bg-white overflow-hidden transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">

      <div className="mt-12">
        <div className="flex flex-col justify-center items-center">
          <div className="h-20 mb-16">
            <Logo />
          </div>

          <div className="bg-white shadow-sm p-6 rounded-md">
            <div style={{ maxWidth: 496 }}>
              {content}
            </div>
          </div>
        </div>
      </div>

      <FlashMessages />
    </div>
  );
}
