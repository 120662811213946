import { emptySplitApi } from '../../app/api';
import { ChatInvitation, ChatRequestStatus, UserThreads } from './messages-types';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { resetFeedList } from '../feed/feedSlice';
import { UpdateRequestResponse } from '../requests/request-types';

export const messagesAPI = emptySplitApi.injectEndpoints({

  endpoints: (builder) => ({
    getUserAccessToken: builder.query<{ expiresOn: string, token: string }, { profileID: number }>({
      query: ({ profileID }) => ({
        url: `/Communication/chats/profile-page-id/${profileID}/access-token`,
      }),
    }),
    getUserThreads: builder.query<UserThreads, { profileID: number }>({
      query: ({ profileID }) => ({
        url: `/Communication/chats/profile-page-id/${profileID}`,
      }),
      providesTags: ['ChatThreadsList'],
      // add timezone to response
      transformResponse: (response: UserThreads) => ({
        ...response,
        chats: response.chats.map((chat) => {
          if (! chat.lastMessageReceivedOnUtc) return chat;

          return {
            ...chat,
            lastMessageReceivedOnUtc: `${chat.lastMessageReceivedOnUtc}Z`,
          };
        }),
      })
      ,
    }),

    getChatInvitations: builder.query<ChatInvitation[], { profileID: number }>({
      query: ({ profileID }) => ({
        url: `/Communication/chats/invitations/profile-page-id/${profileID}`,
      }),
      providesTags: ['ChatRequestsList'],
    }),

    sendChatRequest: builder.mutation<null, { senderProfilePageId: number, recipientProfilePageId: number, message: string }>({
      query(data) {
        return {
          url: '/Communication/chats/invitations/one-to-one',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Message request was sent'));
        } catch {
          dispatch(addFlashMessage('error', 'Message request cannot be sent. Please try again.'));
        }
      },
    }),

    cancelMessageRequest: builder.mutation<null, { senderProfilePageId: number, recipientProfilePageId: number }>({
      query(data) {
        return {
          url: `/Communication/chats/invitations/one-to-one/${ChatRequestStatus.cancelled}`,
          method: 'POST',
          body: {
            senderProfilePageId: data.senderProfilePageId,
            recipientProfilePageId: data.recipientProfilePageId,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Message request was cancelled'));
        } catch {
          dispatch(addFlashMessage('error', 'Message request cannot be cancelled. Please try again.'));
        }
      },
    }),

    acceptMessageRequest: builder.mutation<UpdateRequestResponse, { senderProfilePageId: number, recipientProfilePageId: number }>({
      query(data) {
        return {
          url: `/Communication/chats/invitations/one-to-one/${ChatRequestStatus.accepted}`,
          method: 'POST',
          body: {
            senderProfilePageId: data.senderProfilePageId,
            recipientProfilePageId: data.recipientProfilePageId,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(resetFeedList());
          dispatch(addFlashMessage('success', 'Message request was accepted.'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
      invalidatesTags: () => ['ChatThreadsList', 'ChatRequestsList', 'FeedPosts'],
    }),

    rejectMessageRequest: builder.mutation<null, { senderProfilePageId: number, recipientProfilePageId: number }>({
      query(data) {
        return {
          url: `/Communication/chats/invitations/one-to-one/${ChatRequestStatus.rejected}`,
          method: 'POST',
          body: {
            senderProfilePageId: data.senderProfilePageId,
            recipientProfilePageId: data.recipientProfilePageId,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(resetFeedList());
          dispatch(addFlashMessage('success', 'Message request was dismissed.'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
      invalidatesTags: () => ['ChatRequestsList', 'FeedPosts'],
    }),

    blockProfile: builder.mutation<null, { blockerProfilePageId: number, blockedProfilePageId?: number }>({
      query(data) {
        return {
          url: '/Communication/chats/block',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact has been blocked'));
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while blocking the contact. Please try again.'));
        }
      },
      invalidatesTags: () => ['ChatThreadsList', 'ChatRequestsList', 'FeedPosts'],
    }),

    unblockProfile: builder.mutation<null, { blockerProfilePageId: number, blockedProfilePageId?: number }>({
      query(data) {
        return {
          url: '/Communication/chats/unblock',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact has been Unblocked'));
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while Unblocking the contact. Please try again.'));
        }
      },
      invalidatesTags: () => ['ChatThreadsList', 'ChatRequestsList', 'FeedPosts'],
    }),

  }),
});

export const {
  useGetUserAccessTokenQuery,
  useGetUserThreadsQuery,
  useGetChatInvitationsQuery,

  useSendChatRequestMutation,
  useAcceptMessageRequestMutation,
  useRejectMessageRequestMutation,
  useCancelMessageRequestMutation,
  useBlockProfileMutation,
  useUnblockProfileMutation,
} = messagesAPI;
