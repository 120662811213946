import { SenderProfilePageSummary } from '../contact-list/contact-list.types';
import { QuestionEntry } from '../forum/forum-types';

export enum ActivityFeedEventTypeEnum {
  activityFeedPost = 'activityFeedPost',
  contactRequest = 'contactRequest',
  messagingRequest = 'messagingRequest',
  contactRequestAccepted = 'contactRequestAccepted',
  memberRemovedFromCorporation = 'memberRemovedFromCorporation',
  followedProfilePage = 'followedProfilePage',
  profilePageModulesUpdated = 'profilePageModulesUpdated',
  corporationRemovedNotifyMember = 'corporationRemovedNotifyMember',
  newCreatedForumQuestion = 'newCreatedForumQuestion',
}

export interface MemberRemovedData {
  corporationName: string,
  corporationProfileImageUrl?: string,
  corporationProfilePageId: number,
}

export interface GetFeedRequest {
  profileID: number,
  PageNumber: number,
  PageSize: number,
  onlyMyPosts: boolean,
  userIdToLoad: string,
}

export interface GetFeedListResponse {
  data: Array<IContactRequestAccepted | IFeedContactRequest | IFeedMessageRequest | IFeedPostResponse | IFeedFollowedProfilePageResponse | IFeedProfilePageModulesUpdated>,
  pageNumber: number,
  pageSize: number,
  totalPages: number,
  totalRecords: number,
}

export interface IContactRequestAccepted {
  data: {
    contactRequestAccepterProfilePageSummary: SenderProfilePageSummary
  }
  eventId: number,
  createdAtUtc: string,
  isOwnCreatedContent: false
  type: ActivityFeedEventTypeEnum.contactRequestAccepted,
}

export interface NewForumPostData {
  data: {
    forumQuestion: QuestionEntry,
  }
  eventId: number,
  createdAtUtc: string,
  isOwnCreatedContent: false
  type: ActivityFeedEventTypeEnum.newCreatedForumQuestion,
}

export interface IFeedContactRequest {
  createdAtUtc: string,
  eventId: number,
  data: {
    contactRequestSenderProfilePageSummary: SenderProfilePageSummary,
    message: string,
  }
  isOwnCreatedContent: false
  type: ActivityFeedEventTypeEnum.contactRequest
}

export interface IFeedMessageRequest {
  createdAtUtc: string,
  eventId: number,
  data: {
    messagingRequestSenderProfilePageSummary: SenderProfilePageSummary,
    message: string,
  }
  isOwnCreatedContent: false
  type: ActivityFeedEventTypeEnum.messagingRequest
}

export interface IFeedPostResponse {
  createdAtUtc: string,
  eventId: number,
  data: {
    id: 6,
    content: string
    creator: SenderProfilePageSummary,

    externalLinks?: IFeedExternalLinkResponse[]
    fileAttachments?: IFeedFileResponse[]
    imageAttachments?: IFeedImageAttachmentResponse[]
  }
  isOwnCreatedContent: boolean,
  type: ActivityFeedEventTypeEnum.activityFeedPost,
}

export interface IFeedFollowedProfilePageResponse {
  createdAtUtc: string,
  eventId: number,
  data: {
    followerProfilePageSummary: SenderProfilePageSummary,
  }
  isOwnCreatedContent: false,
  type: ActivityFeedEventTypeEnum.followedProfilePage
}

export interface IFeedImageAttachmentResponse {
  fileUuid: string,
  description: string,
}

export interface IFeedExternalLinkResponse {
  description: string,
  embedded: boolean,
  id: number,
  link: string,
}

export interface IFeedFileResponse {
  contentType: string,
  description: string,
  fileSizeBytes: number,
  fileUrl: string | null,
  fileUuid: string,
  id: number,
  isPrivate: boolean,
  originalFilename: string,
}

export interface IFeedProfilePageModulesUpdated {
  data: {
    updatedProfilePageSummary: SenderProfilePageSummary
  }
  eventId: number,
  createdAtUtc: string,
  isOwnCreatedContent: false
  type: ActivityFeedEventTypeEnum.profilePageModulesUpdated,
}
