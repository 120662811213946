import { combineReducers } from '@reduxjs/toolkit';

import aboutModuleReducer from './about-module/aboutModuleSlice';
import businessMarketsModuleReducer from './business-markets-module/businessMarketsModuleSlice';
import areaOfInterestModuleReducer from './area-of-interest-module/areaOfInterestsModuleSlice';
import productsModuleReducer from './products-module/productsModuleSlice';
import servicesModuleReducer from './services-module/servicesModuleSlice';
import focusModuleReducer from './focus-module/focusModuleSlice';
import teamModuleReducer from './team-module/teamModuleSlice';

export default combineReducers({
  aboutModule: aboutModuleReducer,
  businessMarketsModule: businessMarketsModuleReducer,
  areaOfInterestModule: areaOfInterestModuleReducer,
  productsModule: productsModuleReducer,
  servicesModule: servicesModuleReducer,
  focusModule: focusModuleReducer,
  teamModule: teamModuleReducer,
});
