import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IPostImage } from './create-post/post-image/PostImage';
import { API_URL } from '../../authConfig';

export enum ImageCompressionLevelEnum {
  original = 'original',
  thumbnail = 'thumbnail',
}

export default function PostImage(props: IPostImage) {

  const { blobURL, id, guid } = props;

  const [imageURL, setImageURL] = useState('');

  // const [getFile] = useGetFileFromAzureMutation();

  useEffect(() => {
    (async () => {
      if (blobURL) {
        setImageURL(blobURL);
        return;
      }

      if (! guid) return;

      const response = await axios({ // todo: extract
        url: `${API_URL}/AzureStorage/attachment/${guid}`,
        method: 'GET',
        responseType: 'blob', // important
        params: {
          imageCompressionLevel: ImageCompressionLevelEnum.thumbnail,
        },
      });

      const url = URL.createObjectURL(new Blob([response.data]));
      setImageURL(url);

      setTimeout(() => { // cleanup memory
        URL.revokeObjectURL(url);
      }, 500);
    })();
  }, [guid, blobURL]);

  return (
    <img
      alt="preview"
      src={imageURL}
      className="group-hover:opacity-75 object-contain rounded-md w-full"
    />
  );
}
