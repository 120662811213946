import { Link } from '../post-link/PostLink';
import { IPostPDF } from './post-pdf/PostPDF';
import { IPostImage } from './post-image/PostImage';
import { EMPTY_FILE } from '../../profile-page/modules/about-module/helpers';

export const emptyLink: Link = {
  id: '',
  linkURL: '',
  title: '',
  inEditMode: true,
};

export const emptyPDF: IPostPDF = {
  id: '',
  title: '',
  inEditMode: true,
  file: EMPTY_FILE,
};

export const emptyImage: IPostImage = {
  id: '',
  blobURL: '',
  inEditMode: true,
};

export const formatBytesToString = (a: number, b?: any) => {
  if (a === 0) return '0 Bytes';

  const c = 1024;
  const d = b || 2;
  const e = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const f = Math.floor(Math.log(a) / Math.log(c));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
};
