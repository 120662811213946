import React, { useMemo } from 'react';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../app/store';
import { useGetUserThreadsQuery } from '../../messages/messagesAPI';
import { ContactListEntry as ContactListEntryType } from '../contact-list.types';
import Button from '../../../elements/Button';
import { useAppDispatch } from '../../../app/hooks';
import { setCurrentChatWindow, setThreadUnreadCounter } from '../../messages/messagesSlice';

interface ContactListStartChatButtonProps {
  contact: ContactListEntryType,
}

export default function ContactListStartChatButton(props: ContactListStartChatButtonProps) {

  const { contact } = props;

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const dispatch = useAppDispatch();

  const { data } = useGetUserThreadsQuery({ profileID });

  const { disclosureIsOpen } = useSelector((state: RootState) => state.chatMessages);

  const threadData = useMemo(() => {
    if (! data) return null;

    return find(data.chats, { chatMember: { profilePageSummary: { profilePageId: contact.profilePageSummary.profilePageId } } });
  }, [data, contact.profilePageSummary.profilePageId]);

  if (! data || (! data.azureCommunicationIdentity)) return null;

  if (! threadData) return null;

  return (
    <Button
      onClick={() => { // same in MessageButton and ChatPreviewRow
        dispatch(setCurrentChatWindow({
          threadId: threadData.azureThreadId,
          profilePageSummary: threadData.chatMember.profilePageSummary,
          // @ts-ignore
          currentProfileAzureCommunicationID: data.azureCommunicationIdentity,
          userIsTyping: {
            flag: false,
            nanoid: '',
          },
          threadMemberStatus: threadData.threadMemberStatus,
        }));

        dispatch(setThreadUnreadCounter({ threadID: threadData.azureThreadId, unreadCount: 0 })); // reset as user opened chat

        if (! disclosureIsOpen) { // trigger opening disclosure
          // @ts-ignore
          document.querySelector('.id-chat-messages-disclosure')?.click();
        }
      }}
      size="medium"
      roundedFull
      inverted
      iconLeft={<ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />}
      description=""
    />
  );
}
