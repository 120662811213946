import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import {
  useFollowProfileMutation,
  useUnfollowProfileMutation,
} from '../profilePageAPI';
import { FollowStatus } from '../profileTypes';
import { classNames } from '../../../helpers/styling';

export default function ProfileFollowButton() {

  const {
    allowPageEdit,
    profileOfCurrentUser: { requesterFollowStatus },
    currentlyLoadedProfileID,
  } = useSelector((state: RootState) => state.profilePage);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const [followQuery] = useFollowProfileMutation();
  const [unfollowQuery] = useUnfollowProfileMutation();

  const onFollow = () => {
    if (! currentlyLoadedProfileID || ! profileID) return;

    if (requesterFollowStatus === FollowStatus.notFollowing) {
      followQuery({
        receiverProfilePageId: currentlyLoadedProfileID,
        senderProfilePageId: profileID,
      });

      return;
    }

    if (requesterFollowStatus === FollowStatus.following) {
      unfollowQuery({
        receiverProfilePageId: currentlyLoadedProfileID,
        senderProfilePageId: profileID,
      });
    }
  };

  if (allowPageEdit) {
    return null;
  }

  let text = 'Follow';
  if (requesterFollowStatus === FollowStatus.following) {
    text = 'Unfollow';
  }

  return (
    <button
      type="button"
      onClick={() => onFollow()}
      className={classNames(
        'inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white focus:outline-none',
      )}
    >
      {
        requesterFollowStatus === FollowStatus.notFollowing ? (
          <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        ) : (
          <MinusIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        )
      }
      <span>{text}</span>
    </button>
  );
}
