import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import FaqEntry from './FaqEntry';

export default function FaqPageSection() {
  return (
    <section className="bg-white main-screen-container overflow-hidden md:overflow-visible relative flex justify-center pb-12 md:pb-0 mt-8 px-4 md:px-6">
      <div className="max-w-6xl w-full px-4 md:px-6 my-12 md:my-20">
        <Fade top>
          <h2 className="text-main-900 text-5xl font-extrabold">Frequently Asked Questions</h2>
        </Fade>

        <Fade top>
          <h3 className="mt-12 text-3xl text-main-900 font-extrabold mb-4">General questions</h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="When will Nucleo be fully ‘Live’?"
            content={`
          Nucleo’s platform will be available with a fully functioning ‘Live’ environment from 31st January 2023,
           with early adopters as the registered users. Please note that after this date the user database
            build will still be ongoing as the platform reach and capability is further extended and
             expanded globally. Technical improvements will be
              targeted primarily to feedback from user experience.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="What areas of Life Sciences will Nucleo serve?"
            content={[
              `Nucleo  aims  to  provide  a  wide  encompassing  platform  covering  all  supply  of 
             both products and related services for all sectors within Life Sciences, a branch of
              science (as biology and medicine) that deals with living organisms and life processes.`,

              `For  example  we  aim  to  attract  both  suppliers  and  users  for  the  following
             type  of products:  Innovative  Brands,  Over-the-Counter  (OTC  or  non-prescription)
              products, Generic products,, Specialty Pharma and Rare Disease products, Biopharmaceuticals,
               Academic Medicine as well as the products from Ancillary sectors including Medical Devices, 
               Diagnostics, Veterinary, Nutraceuticals, Cosmeceuticals and Phytopharmaceuticals.`,

              `For  Services  we  aim  to  provide  a  networking  platform  for  all  providers  to 
             offer  their services     within     the     Life     Sciences     industry,
              including     for     Regulatory, Pharmacovigilance, 
               Legal,  Quality  &  MAH,  Contract  Manufacturing  and
                Packaging, Logistics, Distribution and Representation, Brokerage,
                 Commercial and Promotional and many other related services.`,
            ]}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="How will I know if my industry area will be served?"
            content={`
           Nucleo aims to serve all industry areas within Life Sciences. If you feel your
            industry area is under represented please contact info@go-nucleo.com.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="How many companies are currently using Nucleo?"
            content={`
            At  the  Nucleo  ‘Go  Live’  date  in  early  2023  a  limited  but 
             representative  number  of companies will be using the platform as
              early adopting advocates. By the end of 2023 we  aim  to  serve  in  excess 
               of  1,000  companies  and  10,000  individual  subscribers.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Will Nucleo be available in my country or region?"
            content={`
            Nucleo is planning on availability in all global regions, although please note that 
            it may take  some  time  for  a  comprehensive  offering  in  all  countries  
            and  regions  as  the platform is being evolved.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Will Nucleo be available in my language?"
            content={`
            Nucleo  is  planning  on  availability  in  all  global  languages.
              If  you  experience  any difficulty accessing information in your
               language please send your questions direct, in your local language to info@go-nucleo.com.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Are there any hidden costs with my subscription?"
            content={`
            There are no hidden costs with your subscription.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Does Nucleo have access to the content of my messaging with my contacts or companies?"
            content="No."
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="What if I have question not covered by this FAQ?"
            content={`
            If you have any questions not covered by this FAQ please send your question directto info@go-nucleo.com.
          `}
            showBorder={false}
          />
        </Fade>

        <Fade top>
          <h3 className="mt-6 text-3xl text-main-900 font-extrabold mb-4">
            Medicinal products and product availability
          </h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="Should  I  search  the  Nucleo  database  by  brand  tradename  or  by  International Non Proprietary Name (I.N.N.)?"
            content={`
            It  is  recommended  to  search  the  Nucleo  database  by  I.N.N.  as  this  will 
             ensure  the most comprehensive search. However, branded or tradenames may
              also be inputted by a client and can show up in your search results.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="How  do  I  know  if  a  listed  product  is  available  for  licensing  in  my  country  or region?"
            content="You can sort your searches by clicking on the relevant country or region filter."
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Does Nucleo provide information on safety, efficacy and side effects associated with use of medicinal products on its platform?"
            content={`
            Nucleo  does  not  hold  any  formal  authorisations  to  engage  in  direct  procurement  or supply  of  medicinal  products.
             We  are  simply  a  connecting  platform.  Users  should contact each company directly for 
             any queries related to the supply, usage and safety of their products.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Can I find availability of products for rare diseases within the database?"
            content={`
            Nucleo’s  database  of  users  will  contain  manufacturers  and  suppliers  of  
            specialty products,  including  products  used  in  the  underserved  and  
            rare  disease  space.  We encourage users to either contact these companies 
            direct via the platform or to post your question direct to info@go-nucleo.com.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Can Nucleo help me with sourcing of unlicensed products for use in my country or region under a named-patient or compassionate use program?"
            content={`
            Nucleo  does  not  involve  itself  directly  with  product  supply  matters. 
             However,  our platform is open to all types of queries so you may be able to 
             connect with another user who can directly assist you with your query.
              Please send your question direct to info@go-nucleo.com.
          `}
            showBorder={false}
          />
        </Fade>

        <Fade top>
          <h3 className="mt-6 text-3xl text-main-900 font-extrabold mb-4">
            Licencing activity
          </h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="I have a large database of products I wish to upload on the Nucleo database, is administrative help available for this?"
            content={`
            Yes, once a company has agreed to subscribe Nucleo will be happy to accept from the company 
            their latest product list and to upload onto the platform. The final upload requires
             initial  and  periodic  verification  by  the  product  owner  to  ensure
              compliance with our guiding principle ‘one source of the truth’ for any such product offering.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="Can I contact Nucleo direct if I want to source a product or service?"
            content="Yes you can. Please send your question direct to info@go-nucleo.com."
            showBorder={false}
          />
        </Fade>

        <Fade top>
          <h3 className="mt-6 text-3xl text-main-900 font-extrabold mb-4">
            Services
          </h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="What range of services are covered by users of Nucleo?"
            content={[
              `
              For  Services  we  aim  to  provide  a  networking  platform  for  all  providers  to 
               offer  their services     within     the     Life     Sciences     industry,  
                  including     for     Regulatory,  Pharmacovigilance,  Legal,  Quality  &  MAH,  
                  Contract  Manufacturing  and  Packaging, Logistics, Distribution and Representation,
                   Brokerage, Commercial and Promotional and many other related services.
            `,
              `
              A user interested in any of these services can search the platform and 
                initiate direct contact with their preferred service provider.
            `,
            ]}
            showBorder={false}
          />
        </Fade>

        <Fade top>
          <h3 className="mt-6 text-3xl text-main-900 font-extrabold mb-4">
            Academia
          </h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="I work within academia on research projects and would ideally like to know of suitable commercial contacts for my work, can Nucleo help with this?"
            content={`
            Nucleo’s platform will provide a means to showcase any such offerings and potential
             collaborations to all our users.
          `}
            showBorder={false}
          />
        </Fade>

        <Fade top>
          <h3 className="mt-6 text-3xl text-main-900 font-extrabold mb-4">
            My subscription
          </h3>
        </Fade>

        <Fade top>
          <FaqEntry
            title="How long is my free period?"
            content={`
            The standard period for free usage of the platform is 3 months from your registration
             or ‘Go Live’ date, whichever is later.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="What access level will I have during my free period?"
            content={`
            You will have access to full features of the platform during your initial free period.
             If you are an individual user you will get an Individual Pro package.
              Companies will get access to the Corporate Pro package.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="What is the difference between Free and Freemium?"
            content={`
            Freemium is a low cost subscription type that grants users free but limited functionality indefinitely. 
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="When will my payments be taken?"
            content={`
            At the end of your free period you will be contacted by Nucleo to finalise your initial paid subscription package.
          `}
          />
        </Fade>

        <Fade top>
          <FaqEntry
            title="How do I renew my subscription?"
            content="Subscriptions will auto renew at the end of each subscription period."
            showBorder={false}
          />
        </Fade>

      </div>
    </section>
  );
}
