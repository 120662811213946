import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useGetNewsFeedQuery } from './blogAPI';
import ModuleLoader from '../profile-page/components/ModuleLoader';
import Article from './Article';

// todo: pagination
export default function BlogWidget() {

  const [currentArticleID, setCurrentArticleID] = useState<number>(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data, isLoading } = useGetNewsFeedQuery({ pageNumber: 1, pageSize: 4 });

  let content = null;
  if (! data) {
    content = (
      <div className="flex flex-row items-center pb-4">
        <span className="text-neutral-700 text-sm font-semibold mt-8">
          No articles published yet
        </span>
      </div>
    );
  }

  if (isLoading) {
    content = (
      <ModuleLoader />
    );
  }

  if (data && data.data) {
    content = (
      <div className="mt-6 grid gap-16 pt-10 md:grid-cols-2 md:gap-x-5 md:gap-y-12">
        {data.data.map((article) => {
          const date = `${article.createdAtUtc}+00:00`;

          return (
            <div key={article.id}>
              <p className="text-sm text-gray-500">
                <time dateTime={date}>{ DateTime.fromISO(date).toRelative() }</time>
              </p>
              <button
                type="button"
                className="mt-2 text-left"
                onClick={() => {
                  setCurrentArticleID(article.id);
                  setModalIsOpen(true);
                }}
              >
                <p className="text-xl font-semibold text-gray-900">{article.title}</p>
                <p className="mt-3 text-neutral-500 text-base font-medium mt-6">{article.preview}</p>
              </button>
              <div className="mt-3">
                <button
                  type="button"
                  onClick={() => {
                    setCurrentArticleID(article.id);
                    setModalIsOpen(true);
                  }}
                  className="text-base font-semibold text-main-600 hover:text-main-900"
                >
                  Read full story
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <div className="max-w-6xl w-full px-4 md:px-6 my-12 md:my-20">
        <div className="relative mx-auto divide-y-2 divide-gray-200">
          <div>
            <h2 className="text-main-900 text-5.5xl font-extrabold">Press</h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
              <p className="text-xl text-gray-500">Get recent updates from Nucleo</p>
            </div>
          </div>

          { content }
        </div>
      </div>

      <Article modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} articleID={currentArticleID} />
    </>
  );
}
