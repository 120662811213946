import { clone } from 'lodash';
import {
  ICoreFocus, ICoreFocusFlatten, IFocus, IFocusPost,
} from './focus-types';

// copy of prepareServicePayload()
export function prepareFocusPayload(focus: IFocus, isCreate = false): IFocusPost {
  const payload: IFocusPost = {
    id: focus.id,
    coreFocusCategoryId: focus.coreFocusCategoryId,
    coreTagIds: focus.coreTags.map((t) => t.id),
    externalLinks: focus.externalLinks ? focus.externalLinks : [],

    fileAttachments: focus.fileAttachments
      ? focus.fileAttachments
        .map((f) => f.fileUuid)
        .filter((f) => Boolean(f))
      : [],

    territoryIds: focus.territories.map((t) => t.id),
  };

  if (isCreate) {
    delete payload.id;
  }

  return payload;
}

// copy of flattenCoreServicesList()
export function flattenCoreFocusesList(focuses?: ICoreFocus[], nestingLevel = 1, parent?: ICoreFocusFlatten) {

  const flattenTree: ICoreFocusFlatten[] = [];

  if (! focuses || focuses.length === 0) {
    return flattenTree;
  }

  focuses.forEach((s) => {
    const focus: ICoreFocusFlatten = {
      ...clone(s),
      value: s.id,
      label: s.name,
      parentNames: parent ? [...parent.parentNames, parent.name] : [],
      nestingLevel,
    };

    const children = s.childCoreFocusCategories;
    focus.childCoreFocusCategories = null;

    focus.value = focus.id;

    flattenTree.push(focus);

    if (children && children.length > 0) {
      const flattenChildren = flattenCoreFocusesList(children, nestingLevel + 1, focus);
      flattenTree.push(...flattenChildren);
    }
  });

  return flattenTree;
}
