import { RelatedCorporationSummary } from '../../app/account';

export enum CorporationMemberRoleEnum {
  creator = 'creator',
  admin = 'admin',
  member = 'member',
}

export interface CorporationMember {
  accountId: number,
  firstname?: string,
  email: string,
  individualProfilePageId: number,
  profileImageUrl?: string,
  profileImageUuid?: string,
  role: CorporationMemberRoleEnum,
  surname?: string,
  isUniqueAdministrator: boolean,
}

export enum CorporationOwnershipInvitationStatus {
  None = 'none',
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
}

export interface CorporationOwnershipInvitation {
  corporationProfilePageSummary: RelatedCorporationSummary,
  requestStatus: CorporationOwnershipInvitationStatus,
}

export interface CorporateInfo {
  id: number,
  name: string,
  ownedByAppAdmin: boolean,

  accountInvitations: Array<any>,
  corporationMembers:  Array<any>,
  corporationOwnershipInvitations: Array<any>,
  corporationProfilePages:  Array<any>,

  createdAtUtc: string,
  updatedAtUtc: string,
}

export interface GetMembersRequest {
  pageNumber?: number,
  pageSize?: number,
  searchInput?: string,
  corporationID: number,
}

export interface GetMembersResponse {
  id?: string, // search ID
  pageNumber: number,
  pageSize: number,
  totalRecords: number,
  totalPages: number,
  data: CorporationMember[],
}

export interface GetMembersInvitationsRequest {
  pageNumber?: number,
  pageSize?: number,
  corporationID: number,
}

export interface GetMembersInvitationsResponse {
  pageNumber: number,
  pageSize: number,
  totalRecords: number,
  totalPages: number,
  data: CorporationMemberInvitation[],
}

export interface CorporationMemberInvitation {
  invitationId: number,
  firstName: string,
  lastName: string,
  email: string,
}

export enum CorporationMemberRole {
  member = 'member',
  admin = 'admin',
}

export interface EmailNotificationsSettingsResponse {
  contactRequest: boolean,
  messagingRequest: boolean,
  unreadMessages: boolean,
  forumPostReply: boolean,
  forumNews: boolean,
}

export interface InvitationDetailsResponse {
  corporationProfilePageSummary: RelatedCorporationSummary,
  invitationStatus: CorporationOwnershipInvitationStatus,
}

export enum AcceptMemberInvitationResponseResultEnum {
  invalidInvitation = 'invalidInvitation',
  success = 'success',
  alreadyAccepted = 'alreadyAccepted',
}

export interface AcceptMemberInvitationResponse {
  corporationProfilePageSummary: RelatedCorporationSummary,
  result: AcceptMemberInvitationResponseResultEnum,
}
