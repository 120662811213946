import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import UserAvatar from '../../elements/UserAvatar';
import ClumpedContent from '../forum/question-page/ClumpedContent';
import QuestionPreview from '../forum/questions-list/QuestionPreview';
import { QuestionEntry } from '../forum/forum-types';

interface NewForumQuestionCreatedProps {
  timestamp: string,
  data: QuestionEntry,
}
  
export default function NewForumQuestionCreated(props: NewForumQuestionCreatedProps) {

  const { timestamp, data } = props;

  return (
    (data && data.creatorProfilePageSummary) && (
      <div className="relative overflow-hidden mb-2">
        <div className="bg-white pt-12 pl-9 pb-5 pr-8 shadow shadow-lg rounded rounded-lg">
          <div className="w-full">
            <UserAvatar
              profilePageType={data.creatorProfilePageSummary.profilePageAccountType}
              profileImageUrl={data.creatorProfilePageSummary.profileImageUrl}
              profileID={data.creatorProfilePageSummary.profilePageId}
            />
            <div className="flex flex-col pl-2 w-full">
              {/* USER AVATAR */}
              <div className="flex justify-between w-full">
                <div>
                  <span className="text-xs text-neutral-400 pb-1">{DateTime.fromISO(timestamp).toRelative()}</span>
                  <div className="mb-0.5">
                    <Link to={`/profile/${data.creatorProfilePageSummary.profilePageId}`} className="max-w-fit">
                      <span className="text-sm font-bold hover:underline">{data.creatorProfilePageSummary.name}</span>
                    </Link>
                  </div>
                  {/* show company set in dynamic fields */}
                  {
                    data.creatorProfilePageSummary.showCompany && data.creatorProfilePageSummary.company && (
                      <p className="text-sm font-semibold text-neutral-600 table-cell">
                        {data.creatorProfilePageSummary.company}
                      </p>
                    )
                  }
                  {/* show job position set in dynamic fields if company is also set */}
                  {
                    data.creatorProfilePageSummary.showCompany && data.creatorProfilePageSummary.company && data.creatorProfilePageSummary.showJobPosition && data.creatorProfilePageSummary.jobPosition && (
                      <p className="text-xs font-semibold text-neutral-600">
                        {data.creatorProfilePageSummary.jobPosition}
                      </p>
                    )
                  }
                  {/* show related corporation name */}
                  {
                    !data.creatorProfilePageSummary.showCompany && data.creatorProfilePageSummary.relatedCorporationProfilePageSummary && (
                      <Link to={`/profile/${data.creatorProfilePageSummary.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                        <p className="text-xs text-neutral-500">{data.creatorProfilePageSummary.relatedCorporationProfilePageSummary.name}</p>
                      </Link>
                    )
                  }
                </div>
              </div>
              {/* CONTENT */}
              <div className="w-full">
                <div className="mt-5">
                  <p className="text-main-500 flex flex-row items-center">
                    <QuestionMarkCircleIcon className="w-4 h-4" />
                    <span className="pl-2.5 text-sm font-semibold">
                      New Question Posted
                    </span>
                  </p>
                </div>
                <div className="mt-5 pt-2 border-t border-neutral-200">
                  <QuestionPreview
                    showCreatorProfile={false}
                    question={data}
                    last={false}
                    first
                  />
                </div>
                <div className="mt-5 mb-4 flex justify-end">
                  <Link
                    to={`/forum/question/${data.id}`}
                    className="px-5 py-2 border border-neutral-800 text-xs font-semibold rounded-3xl text-white bg-neutral-800 hover:border-main-700 hover:bg-main-700 hover:text-white focus:outline-none transition-colors duration-300"
                  >
                    View Question
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
  
}
