import React, { useEffect, useMemo } from 'react';
import {
  ActionMeta, components, createFilter, SingleValue, OptionProps, SingleValueProps,
} from 'react-select';
import { find } from 'lodash';
import AppSelect from '../../../../../elements/AppSelect';
import { useGetCoreServicesListQuery } from '../servicesAPI';
import InputLoader from '../../../../../elements/InputLoader';
import { flattenCoreServicesList } from '../helpers';
import { ICoreServiceFlatten } from '../services-types';

interface ServiceNameFieldProps {
  onChange: (newValue: SingleValue<ICoreServiceFlatten>, actionMeta?: ActionMeta<ICoreServiceFlatten>) => void,
  value: number,
}

// on change change ID, maybe name
export default function ServiceNameField(componentProps: ServiceNameFieldProps) {

  const { value, onChange } = componentProps;

  const { data: listOfServices, isLoading, isFetching } = useGetCoreServicesListQuery(null);

  const flatten = useMemo(() => flattenCoreServicesList(listOfServices), [listOfServices]);

  useEffect(() => { // set default value
    if (flatten.length === 0 || value) return;

    onChange(flatten[0]);
  // eslint-disable-next-line
  }, [flatten, value]);

  if (! listOfServices || isLoading || isFetching) {
    return <InputLoader />;
  }

  let dropdownValue = find(flatten, { id: value });
  dropdownValue = dropdownValue || flatten[0];

  // @ts-ignore
  const Option = ({ children, ...props }: OptionProps & { data: ICoreServiceFlatten }) => {

    // optimization
    // eslint-disable-next-line no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    const { data } = newProps;

    return (
      <>
        <components.Option
          {...newProps}
          className={`react-select-option weight nesting-${data.nestingLevel} flex-row items-center`}
        >

          <div className="flex flex-row justify-center items-center">

            <div className="w-4 h-4 bg-main-75 font-semibold text-2xs text-main-600 rounded-full flex justify-center items-center">
              <span>{ data.label[0] }</span>
            </div>

            <div className="flex-col pl-2">
              <p className="text-2xs text-neutral-600">
                {
                  data.parentNames.reduce((name, acc) => (acc ? `${acc} -> ${name}` : name), '')
                }
              </p>
              {' '}
              <p className="">
                {data.label}
              </p>
            </div>
          </div>

        </components.Option>
      </>
    );
  };

  const SingleValueComponent = ({
    children,
    ...props
  }: SingleValueProps<ICoreServiceFlatten>) => (
    <components.SingleValue {...props}>
      <div className="flex-col p-2">
        <p className="text-2xs text-neutral-600">
          { props.data.parentNames.reduce((name, acc) => (acc ? `${acc} -> ${name}` : name), '')}
        </p>
        <p>
          {children}
        </p>
      </div>
    </components.SingleValue>
  );

  return (
    <AppSelect
      placeholder="Select a service"
      value={dropdownValue}
      // @ts-ignore
      onChange={onChange}
      filterOption={createFilter({ ignoreAccents: false })} // optimization
      options={flatten}
      // @ts-ignore
      components={{ Option, SingleValue: SingleValueComponent }}
    />
  );
}
