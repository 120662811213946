import { appColors } from '../../tailwind-config-copy';

export const chartOptions = {
  seriesType: 'bars',
  series: { 1: { type: 'line' } },
  legend: {
    textStyle: {
      fontSize: 12,
      color: appColors.neutral[600],
      bold: true,
    },
    position: 'right',
  },
  colors: [appColors.neutral[300], appColors.main[700]],
  hAxis: {
    textStyle: {
      fontSize: 12,
      color: appColors.neutral[600],
      bold: true,
    },
  },
  vAxis: {
    textStyle: {
      fontSize: 12,
      color: appColors.neutral[600],
      bold: true,
    },
  },
};
