/**
 * Reason for this duplicate is that app cannot be compiled with import from tailwind.config.js
 * Extracting "common" config also not possible without ejecting CRA.
 */
import { BRANDING } from './helpers/branding';

// todo: update this if used
const main = BRANDING === 'NUCLEO' ? {
  25: '#F7F9FF',
  50: '#F0F3FF',
  75: '#E8EDFF',
  100: '#DBEAFE',
  200: '#BFDBFE',
  300: '#93C5FD',
  400: '#60A5FA',
  500: '#3B82F6',
  600: '#2563EB',
  700: '#1D4ED8',
  800: '#1E40AF',
  900: '#1E3A8A',
} : { // ipls colors. should be in this file otherwise doesn't compile
  25: '#f4f9e9',
  50: '#e9f2d2',
  75: '#dfecbc',
  100: '#d4e6a5',
  200: '#c9e08f', // -50
  300: '#bed979', // -40
  400: '#b3d362', // -30
  500: '#9ec635', // -10
  600: '#93C01F', // BASE
  700: '#84ad1c', //
  800: '#769a19', //
  900: '#678616',
};

const success = BRANDING === 'NUCLEO' ? {
  25: '#F4FEF9',
  50: '#ECFDF5',
  75: '#DDFBEB',
  100: '#D1FAE5',
  200: '#A7F3D0',
  300: '#6EE7B7',
  400: '#34D399',
  500: '#10B981',
  600: '#059669',
  700: '#047857',
  800: '#065F46',
  900: '#064E3B',
}  : {
  25: '#fff',
  50: '#e6f6ed',
  75: '#cceddc',
  100: '#b3e5ca',
  200: '#99dcb8',
  300: '#66ca95',
  400: '#4dc183',
  500: '#33b971',
  600: '#00A74E',
  700: '#047857',
  800: '#065F46',
  900: '#064E3B',
};

export const appColors = {
  neutral: {
    25: '#FCFDFD',
    50: '#F9FAFB',
    75: '#F6F7F8',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  warning: {
    25: '#FFFCF1',
    50: '#FFF9E3',
    75: '#FEF6D5',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },
  success,
  main,
  error: {
    25: '#FFF8F9',
    50: '#FFF1F2',
    75: '#FFE4E6',
    100: '#FFE4E6',
    200: '#FECDD3',
    300: '#FDA4AF',
    400: '#FB7185',
    500: '#F43F5E',
    600: '#E11D48',
    700: '#BE123C',
    800: '#9F1239',
    900: '#881337',
  },
};
