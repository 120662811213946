import React from 'react';
import ModuleLayout from '../../components/ModuleLayout';
import TeamModuleView from './TeamModuleView';
import TeamModuleEdit from './TeamModuleEdit';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import { useBrandingLocale } from '../../../../helpers/hooks';

interface TeamModuleProps extends ModuleLayoutBaseProps {
  corporationName: string | undefined,
}

export default function TeamModule(props: TeamModuleProps) {
  const { 
    dragHandleProps, 
    moduleMetadata, 
    allowEditMetadataModule, 
    corporationName,
  } = props;

  const { t } = useBrandingLocale();

  return (
    <ModuleLayout
      header={`${corporationName} ${t('Module_Corporate_Team_Title')}`}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      onReset={() => {}}
      editComponent={<TeamModuleEdit />}
      viewComponent={<TeamModuleView />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
