import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link, NavLink } from 'react-router-dom';
import LogoutButton from '../log-in/LogoutButton';
import LoginButton from '../log-in/LoginButton';

import fullLogo from './assets/images/fullLogo.svg';
import { FEATURE_LOGIN_ENABLED, FEATURE_REGISTER_ENABLED } from '../../config/features';
import RegisterButton from '../log-in/RegisterButton';
import RegisterPopover from '../log-in/RegisterPopover';

export const landingNavigation = [
  { name: 'Main', href: '/' },
  { name: 'What is Nucleo?', href: '/what-is-nucleo' },
  { name: 'About Us', href: '/about-nucleo' },
];

export default function LandingNavigation() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <RegisterPopover />

      <div
        style={{ height: 80, opacity: 0.1 }}
        className="absolute z-20 bg-gray-900 w-full hidden md:block"
      />

      <Popover as={Fragment}>
        {({ open }) => (
          <>
            <nav
              className="absolute z-20 flex items-center justify-center max-w-6xl w-full px-4 md:px-6 xl:px-0"
              aria-label="Global"
            >
              <div className="flex items-center flex-1 pt-4 pb-4 max-w-6xl">
                <div className="flex items-center justify-between w-full">
                  <Link
                    to="/"
                    className="block"
                  >
                    <span className="sr-only">Nucleo</span>
                    <img
                      className="h-10 w-auto md:h-12"
                      src={fullLogo}
                      alt="Nucleo Logo"
                    />
                  </Link>
                  <div className="flex items-center md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main-900 focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:space-x-6 flex flex-row">
                {landingNavigation.map((item) => (
                  <NavLink
                    exact
                    key={item.href}
                    to={item.href}
                    className={(isActive) => `font-semibold text-sm text-white ${isActive && 'border-2 border-white py-2 px-4 rounded-md'}`}
                  >
                    {item.name}
                  </NavLink>
                ))}

                {
                  FEATURE_LOGIN_ENABLED && ( // todo: encapsulate such logic in in a separate component
                    <>
                      { isAuthenticated
                        ? <LogoutButton />
                        : (
                          <>
                            <LoginButton />
                            { FEATURE_REGISTER_ENABLED ? <RegisterButton /> : null }
                          </>
                        )}
                    </>
                  )
                }

              </div>
            </nav>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="z-40 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img
                        className="h-10 w-auto"
                        src={fullLogo}
                        alt="Nucleo Logo"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main-900 focus:outline-none">
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {landingNavigation.map((item) => (
                      <NavLink
                        exact
                        key={item.href}
                        to={item.href}
                        className={(isActive) => `block px-3 py-2 rounded-md text-base text-neutral-800 ${isActive ? 'font-bold' : 'font-medium'}`}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                  <div className="px-1 pb-2">
                    { isAuthenticated
                      ? <LogoutButton />
                      : (
                        <>
                          <LoginButton />
                          { FEATURE_REGISTER_ENABLED ? <RegisterButton /> : null }
                        </>
                      )}

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
