import React from 'react';
import { EnvelopeIcon } from '@heroicons/react/20/solid';

interface MailButtonProps {
  email: string | null,
  displayEmail: boolean,
}

export default function MailButton(props: MailButtonProps) {

  const { email, displayEmail } = props;

  if (! email || ! displayEmail) {
    return (
      <span className="text-neutral-400 hover:text-neutral-500 p-3 mr-1">
        <span className="sr-only">Mail team member</span>
        <EnvelopeIcon className="h-5 w-5 text-neutral-300" />
      </span>
    );
  }

  return (
    <a href={`mailto:${email}`} className="text-neutral-400 hover:text-neutral-500 p-3 mr-1">
      <span className="sr-only">Mail team member</span>
      <EnvelopeIcon className={`h-5 w-5 ${email ? 'text-main-600' : 'text-neutral-300'}`} />
    </a>
  );
}
