import React, { ReactElement } from 'react';
import { Switch as BaseSwitch } from '@headlessui/react';

interface SwitchProps {
  checked: boolean,
  onChange: (flag: boolean) => void,
  size?: 'md' | 'sm',
  label?: string,
  labelHTML?: ReactElement,
  validationMessage?: string,
}

export default function Switch(props: SwitchProps) {
  const {
    checked,
    onChange,
    label,
    validationMessage,
    labelHTML,
    size = 'md',
  } = props;

  return (
    <div>
      <div className="flex items-center">
        <BaseSwitch
          style={{ minWidth: size === 'sm' ? 32 : 44 }}
          checked={checked}
          onChange={onChange}
          className={`${
            checked ? 'bg-main-700' : 'bg-neutral-200'
          } ${
            size === 'sm' ? 'h-5 w-9' : 'h-6 w-11'
          } relative inline-flex items-center rounded-full transition-colors`}
        >
          <span className="sr-only">{label}</span>
          <span
            className={`${
              // TODO: rewrite without nested ternary
              // eslint-disable-next-line no-nested-ternary
              checked ? size === 'sm' ? 'translate-x-5' : 'translate-x-6' : 'translate-x-1'
            } ${
              size === 'sm' ? 'w-3 h-3' : 'w-4 h-4'
            } inline-block transition-transform transform bg-white rounded-full`}
          />
        </BaseSwitch>
        {
          label && (
            <span className={`font-semibold text-neutral-800 pl-3 ${size === 'sm' && 'text-xs'}`}>
              {label}
            </span>
          )
        }
        {
          labelHTML || null
        }

      </div>

      <div className="mx-1 mt-2">
        {
          validationMessage && (
            <div className="bg-error-50 p-1">
              <p className="text-xs text-error-600">
                {validationMessage}
              </p>
            </div>
          )
        }
      </div>
    </div>
  );
}
