import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import ModuleLoader from '../../../components/ModuleLoader';
import {
  displayCreateServiceForm,
  onServiceTerritorySelection,
  resetCreateServiceForm,
  updateCreateServiceCoreServiceID,
  updateCreateServiceDescription, updateCreateServiceFile,
  updateCreateServiceLink,
  updateCreateServiceTags,
} from '../servicesModuleSlice';
import ModuleInfoSupportMessage from '../../../components/ModuleInfoSupportMessage';
import Button from '../../../../../elements/Button';
import DuplicateForm from '../../products-module/components/ProductCreateEditForm/DuplicateForm';
import TagSelection from '../../../components/TagSelection';
import { useCreateServiceMutation, useUpdateServiceMutation } from '../servicesAPI';
import Textarea from '../../../../../elements/Textarea';
import MarketsSelection from '../../business-markets-module/elements/MarketsSelection';
import { FlattenTerritory } from '../../business-markets-module/business-markets-module-types';
import ServiceNameField from './ServiceNameField';
import ServiceLinkField from './ServiceLinkField';
import ServiceFileField from './ServiceFileField';
import { UploadBlob, uploadFileToAzure } from '../../../../../app/filesSlice';

interface ServiceCreateEditFormProps {
  formType: 'edit' | 'create',
  serviceID: number,
  setServiceViewInViewMode?: () => void,
}

// todo (API): accept full file (fileUuid and description at least) payload,
//  so if only file description is updated, backend will update it, without changing a file
export default function ServiceCreateEditForm(props: ServiceCreateEditFormProps) {
  const { serviceID, formType, setServiceViewInViewMode } = props;

  const [duplicateForm, setDuplicateForm] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();

  const {
    createServiceFormData,
  } = useSelector((state: RootState) => state.profileModules.servicesModule);
  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const currentService = createServiceFormData[serviceID];

  const [createService, { isLoading: isCreatingService }] = useCreateServiceMutation();
  const [updateServiceRequest, { isLoading: isUpdatingService }] = useUpdateServiceMutation();

  if (! currentService) {
    return (
      <ModuleLoader />
    );
  }

  const onCancelForm = () => {
    if (formType === 'create') {
      dispatch(displayCreateServiceForm({
        showForm: false,
      }));
    }

    if (formType === 'edit') {
      dispatch(resetCreateServiceForm(serviceID));
      if (setServiceViewInViewMode) { setServiceViewInViewMode(); }
    }
  };

  const onAddService = async () => {

    // upload file if wasn't uploaded
    const currentFile = currentService.fileAttachments && currentService.fileAttachments.length > 0
      ? cloneDeep(currentService.fileAttachments[0])
      : null;

    if (currentFile && (! currentFile.fileUuid) && uploadedFile) {
      const file = await dispatch(uploadFileToAzure({
        file: uploadedFile,
        description: currentFile.description,
        blobContainer: UploadBlob.moduleService,
        profilePageID: currentlyLoadedProfileID!,
        requesterID,
      }));

      if (! file) {
        // eslint-disable-next-line no-console
        console.log('Module service upload - no file uuid returned');
        return;
      }

      // @ts-ignore
      const { id } = file.payload.data;

      currentFile.fileUuid = id;

      // update to set file UUID for duplicated form
      dispatch(updateCreateServiceFile({ serviceID, value: currentFile }));
    }

    const payload = {
      requesterID,
      profileID: currentlyLoadedProfileID,
      service: {
        ...currentService,
        fileAttachments: currentFile ? [currentFile] : [],
      },
    };

    if (formType === 'create') {
      await createService(payload);
    }

    if (formType === 'edit') {
      await updateServiceRequest(payload);

      if (setServiceViewInViewMode) { setServiceViewInViewMode(); }
    }

    // duplicate form
    dispatch(displayCreateServiceForm({
      showForm: duplicateForm,
      service: currentService, // set data in new form
    }));
  };

  const preparedTags = currentService.coreTags
    ? currentService.coreTags
    : [];

  return (
    <div className="mt-4 flex flex-col items-center">

      {/* Service name */}
      <div className="w-3/5 mb-4">
        <ServiceNameField
          value={currentService.coreServiceId}
          onChange={(v) => dispatch(updateCreateServiceCoreServiceID({ serviceID, value: v ? v.id : 1 }))}
        />
      </div>

      {/* Description */}
      <div className="w-3/5 mb-4">
        <Textarea
          value={currentService.description ? currentService.description : ''}
          placeholder="Start typing..."
          onChange={(e) => dispatch(updateCreateServiceDescription({ value: e.target.value, serviceID }))}
        />
      </div>

      {/* Territory */}
      <div className="w-3/5 mb-4">
        <div className="border rounded-md p-2 mt-2 bg-neutral-25">
          <p className="pb-2 text-neutral-700 text-xs font-semibold">
            Territories
          </p>
          <MarketsSelection
            onChange={(t: MultiValue<FlattenTerritory>) => dispatch(onServiceTerritorySelection({ serviceID, value: t }))}
            value={currentService.territories.map((t) => ({
              ...t,
              nestingLevel: 0, // will be set in the component
              value: t.id, // duplicate id
              label: t.territoryName, // duplicate territoryName
            }))}
          />
        </div>
      </div>

      {/* Link */}
      <div className="w-3/5 mb-4">
        <ServiceLinkField
          value={currentService.externalLinks}
          onChange={(link) => dispatch(updateCreateServiceLink({ serviceID, value: link }))}
        />
      </div>

      <div className="w-3/5 mb-4">
        <ServiceFileField
          value={currentService.fileAttachments}
          onChange={(file) => dispatch(updateCreateServiceFile({ serviceID, value: file }))}
          setLocalFile={(file) => setUploadedFile(file)}
        />
      </div>

      {/* Tags */}
      <div className="w-3/5 mb-4">
        <div className="border rounded-md p-2 bg-neutral-25">
          <p className="pb-2 text-neutral-700 text-xs font-semibold">
            Tags
          </p>
          <TagSelection
            placeholder="Tags"
            value={preparedTags}
            onChange={(t) => dispatch(updateCreateServiceTags({ value: t, serviceID }))}
          />
        </div>
      </div>

      <div className="w-3/5 mt-6">
        <DuplicateForm
          duplicateFormFlag={duplicateForm}
          productID={serviceID}
          setDuplicateForm={(flag) => setDuplicateForm(flag)}
        />
      </div>

      {/* Action buttons */}
      <div className="w-3/5 mt-4 mb-6 flex justify-end">
        <div className="pr-2">
          <Button
            onClick={onCancelForm}
            size="medium"
            inverted
            description="Cancel"
            disabled={isCreatingService || isUpdatingService}
          />
        </div>
        <Button
          onClick={onAddService}
          size="medium"
          description={formType === 'create' ? 'Add a Service' : 'Save changes'}
          isLoading={isCreatingService || isUpdatingService}
        />
      </div>
      <ModuleInfoSupportMessage />
    </div>
  );
}
