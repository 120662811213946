import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

export default function FeedLoader() {
  return (
    <div className="flex w-full justify-center items-center animate-pulse">
      <ArrowPathIcon className="h-4 w-4 text-neutral-600 mr-2.5 animate-spin" />
      <span className="font-semibold text-neutral-600">Loading...</span>
    </div>
  );
}
