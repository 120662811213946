import React, { ReactElement } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { isArray } from 'lodash';

interface FaqEntryProps {
  title: string,
  content: string | ReactElement | string[] | ReactElement[],
  showBorder?: boolean,
}

export default function FaqEntry(props: FaqEntryProps) {

  const { title, content, showBorder = true } = props;

  let displayedContent = content;
  if (typeof content === 'string') {
    displayedContent = (
      <p className="text-base text-neutral-800">
        {content}
      </p>
    );
  }
  if (isArray(content) && content.length > 0) {
    displayedContent = (
      <div>
        {
          // @ts-ignore
          content.map((row: string | ReactElement): ReactElement => (
            <p className="text-base text-neutral-800 pb-2">
              {row}
            </p>
          ))
        }
      </div>
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-1 flex flex-row justify-between items-center w-full px-4">
            <h4 className="font-bold text-neutral-700 text-xl font-semibold py-4 max-w-[85%] text-left">
              { title }
            </h4>

            {open ? (
              <MinusIcon className="h-6 w-6" aria-hidden="true" />
            ) : (
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
            )}
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="max-w-4xl px-4 pb-6">
              {displayedContent}
            </Disclosure.Panel>
          </Transition>

          { showBorder && <div className="border-b border-neutral-200" /> }
        </>
      )}
    </Disclosure>
  );
}
