import React, { useEffect, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import PostTextarea from '../../../../post/create-post/PostTextarea';
import PostOnBehalfMessage from '../../../../post/create-post/PostOnBehalfMessage';
import Button from '../../../../../elements/Button';
import { Answer } from '../../../forum-types';
import { useEditAnswerMutation } from '../answersAPI';
import { RootState } from '../../../../../app/store';
import { useAppSelector } from '../../../../../app/hooks';
import { useFormValidation } from '../../../../../helpers/validation';
import { rulesForumAnswerForm, validationErrorState, ValidationKeys } from '../../../answerValidationRules';
import { getCanUpdateForumAnswers } from '../../../../../app/permissionsSchema';

interface AnswerEditFormProps {
  answer: Answer,
  setViewMode: () => void,
}

export default function AnswerEditForm(props: AnswerEditFormProps) {
  const { answer, setViewMode } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answerText, setAnswerText] = useState('');

  const [editAnswerRequest] = useEditAnswerMutation();

  const { currentProfile } = useSelector((state: RootState) => state.account);
  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);
  const canUpdateForumAnswers = useAppSelector(getCanUpdateForumAnswers);

  useEffect(() => {
    setAnswerText(answer.content);
  }, [answer]);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForumAnswerForm,
    validationErrorState,
    {
      answerText,
    },
  );

  const onSubmit = async () => {
    // TODO verify: "&& ! canUpdateForumAnswers" looks like potential bug that won't allow users to update answers
    if (! currentProfile || (! currentProfile.profileID && ! canUpdateForumAnswers)) return;

    const val = validate();

    if (! val.allValid) return;

    setIsSubmitting(true);

    await editAnswerRequest({
      answerID: answer.id,
      requesterID: profileID,
      form: { answerText },
    });

    setIsSubmitting(false);

    setViewMode();
  };

  return (
    <div>
      <PostTextarea
        text={answerText}
        onChange={(e) => {
          setAnswerText(e.target.value);
          onValidationInputChange('answerText');
        }}
        placeholder="Type your answer..."
        minHeight="h-24"
        validationMessage={validationState.answerText.errorMessage}
      />

      <PostOnBehalfMessage />

      <div className="flex justify-end gap-4 mt-4">
        <Button
          onClick={onSubmit}
          size="x-medium"
          description="Update answer"
          disabled={isSubmitting}
          iconLeft={<PencilSquareIcon className="w-4 h-4 mr-2" />}
        />
      </div>
    </div>
  );
}
