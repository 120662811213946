import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clone } from 'lodash';
import { IFocus } from './focus-types';

interface FocusModuleSlice {
  deleteFocusModalData: {
    focus: IFocus | null,
    showModal: boolean,
  },

  createFocusViewDisplay: boolean,

  createFocusFormData: {
    [key: number]: IFocus,
  }
}

const initialState: FocusModuleSlice = {
  deleteFocusModalData: {
    focus: null,
    showModal: false,
  },

  createFocusViewDisplay: false,

  createFocusFormData: {
    0: {
      id: 0,
      coreFocusCategoryId: 0,
      focusCategoryName: '',
      coreTags: [],
      externalLinks: [],
      fileAttachments: [],
      parentFocusCategoriesNames: '',
      territories: [],
    },
  },
};

export const focusModuleSlice = createSlice({
  name: 'focusModule',
  initialState,
  reducers: {
    setDeleteFocusModalData: (state, action) => {
      state.deleteFocusModalData = action.payload;
    },

    // on focus edit
    setCreateFocusForm: (state, action: PayloadAction<IFocus>) => {
      state.createFocusFormData[action.payload.coreFocusCategoryId] = clone(action.payload);
    },

    // called on edit cancel
    resetCreateFocusForm: (state, action: PayloadAction<number>) => {
      const newFormData = clone(state.createFocusFormData);
      delete newFormData[action.payload];

      state.createFocusFormData = newFormData;
    },

    displayCreateFocusForm: (state, action: PayloadAction<{ showForm: boolean, focus?: IFocus }>) => {
      state.createFocusViewDisplay = action.payload.showForm;

      state.createFocusFormData = {
        ...state.createFocusFormData,
        0: {
          ...initialState.createFocusFormData[0], // reset data
        },
      };

      if (action.payload.focus) {
        state.createFocusFormData = {
          ...state.createFocusFormData,
          0: {
            ...action.payload.focus, // set data
            id: 0, // reset id
          },
        };
      }
    },

    updateCreateFocusCoreFocusID: (state, { payload: { focusID, value } }) => { // core focus ID
      state.createFocusFormData[focusID].coreFocusCategoryId = value;
    },

    updateCreateFocusLink: (state, { payload: { focusID, value } }) => { // description
      state.createFocusFormData[focusID].externalLinks = [value];
    },

    updateCreateFocusFile: (state, { payload: { focusID, value } }) => { // description
      state.createFocusFormData[focusID].fileAttachments = [value];
    },

    onFocusTerritorySelection: (state, { payload: { focusID, value } }) => { // territory
      state.createFocusFormData[focusID].territories = value;
    },

    updateCreateFocusTags: (state, { payload: { focusID, value } }) => {
      state.createFocusFormData[focusID].coreTags = value;
    },
  },
});

export const {
  setDeleteFocusModalData,
  setCreateFocusForm,
  resetCreateFocusForm,
  displayCreateFocusForm,

  onFocusTerritorySelection,
  updateCreateFocusTags,

  updateCreateFocusCoreFocusID,
  updateCreateFocusLink,
  updateCreateFocusFile,

} = focusModuleSlice.actions;

export default focusModuleSlice.reducer;
