import { SenderProfilePageSummary } from '../contact-list/contact-list.types';

export interface UpdateRequestResponse {
  acceptedRequestProfilePageSummary: SenderProfilePageSummary,
}

export enum RequestType {
  contact = 'contact',
  messaging = 'messaging',
}
