import React, { useEffect } from 'react';
import { htmlToU } from './Nucleo/TermsofUse_gonucleo_EN_2210201913.docx';
import { useDocumentTitle } from '../../../helpers/hooks';
import { BRANDING } from '../../../helpers/branding';

export default function TermsOfUsePage() {

  useDocumentTitle('Terms of Use');

  useEffect(() => {
    if (BRANDING === 'IPLS') {
      window.location.href = 'https://ipls.online/terms-conditions';
    }
  }, []);

  if (BRANDING === 'NUCLEO') {
    return <iframe title="Terms of Use" style={{ width: '100%', height: '100vh' }} srcDoc={htmlToU} />;
  }

  return null;
}
