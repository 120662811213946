import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import LandingNavigation from './LandingNavigation';
import landingBackground from './assets/images/bg-main.jpg';

import nucleoLogo from './assets/images/logoMainScreen.svg';

import './styles.scss';
import FeaturesPromoSection from './FeaturesPromoSection';
import Footer from './Footer';
import { useDocumentTitle } from '../../helpers/hooks';
import BlogWidget from '../blog/BlogWidget';

export default function LandingPage() {

  useDocumentTitle('Main');

  return (
    <main className="landing-page relative bg-white overflow-hidden">
      <section id="main-screen" className="main-screen-container relative flex justify-center pb-12 md:pb-0">
        <LandingNavigation />
        <img
          src={landingBackground}
          className="object-cover w-full h-full absolute z-0 fixed"
          style={{ minHeight: 300 }}
          alt="Nucleo background"
        />

        <div className="z-10 w-full flex xl:justify-end max-w-6xl w-full px-4 md:px-6 xl:px-0">
          <div className="relative mt-24 flex md:justify-between flex-col w-auto md:w-full xl:w-auto md:flex-row xl:flex-col xl:items-end">
            <Fade left>
              <div className="md:max-w-[40%] xl:max-w-[100%] xl:mr-4">
                <div className="backdrop-blur-md bg-black/30 px-8 py-12 rounded-md xl:p-0 xl:bg-transparent xl:backdrop-filter-none">
                  {/* <p className="text-white font-bold ">This is</p> */}
                  <img src={nucleoLogo} alt="Nucleo" />
                </div>

                {/* <RegisterEventTile /> */}
              </div>
            </Fade>

            {/* <RegistrationForm /> */}
          </div>
        </div>
      </section>

      <section className="flex justify-center">
        <BlogWidget />
      </section>

      <section id="what-is-nucleo" className="bg-neutral-75 min-h-screen flex justify-center">
        <FeaturesPromoSection />
      </section>

      <Footer />
    </main>
  );
}
