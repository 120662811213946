import React, { useState } from 'react';
import Input from '../../elements/Input';
import Textarea from '../../elements/Textarea';
import Button from '../../elements/Button';
import SuccessBlock from '../../elements/SuccessBlock';
import { useSendSupportMessageMutation } from './contactUsAPI';
import { useFormValidation } from '../../helpers/validation';
import { rulesContactUsForm, validationErrorState, ValidationKeys } from './validationRules';
import { BRANDING, EMAIL_SUPPORT } from '../../helpers/branding';

export default function ContactUsForm() {

  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [requestInProgress, seRequestInProgress] = useState(false);

  const [showSuccessBlock, setShowSuccessBlock] = useState(false);

  const [messageRequest] = useSendSupportMessageMutation();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesContactUsForm,
    validationErrorState,
    {
      topic,
      message,
    },
  );

  const onSend = async () => {
    if (requestInProgress) return;

    const val = validate();

    if (! val.allValid) { return; }

    seRequestInProgress(true);

    const response = await messageRequest({ message, topic });

    seRequestInProgress(false);

    if (! Object.prototype.hasOwnProperty.call(response, 'error')) { // on success
      setMessage('');
      setTopic('');
      setShowSuccessBlock(true);
    }
  };

  const mailtoLink = `mailto:${EMAIL_SUPPORT}`;

  return (
    <div className="p-12 mt-2 bg-white rounded-md shadow-sm">
      <h2 className="text-3xl font-bold text-neutral-800 mb-4">
        Still have questions?
      </h2>
      {
        (! showSuccessBlock) && (
          <>
            <p className="text-sm">
              Send us your questions, doubts, suggestions, using the form below or by email to:&nbsp;
              <a href={mailtoLink} className="font-semibold text-main-600">
                { EMAIL_SUPPORT }
              </a>
              . We will be happy to reply as soon as possible
            </p>

            <div className="mt-4">
              <Input
                value={topic}
                id="contact-us-topic"
                label="Topic"
                placeholder="Please describe the purpose of your message"
                onChange={(e) => {
                  setTopic(e.target.value);
                  onValidationInputChange('topic');
                }}
                validationMessage={validationState.topic.errorMessage}
              />
            </div>
            <div className="mt-4">
              <Textarea
                value={message}
                placeholder="Start typing..."
                onChange={(e) => {
                  setMessage(e.target.value);
                  onValidationInputChange('message');
                }}
                validationMessage={validationState.message.errorMessage}
              />
            </div>
            <div className={`mt-4 flex justify-end ${requestInProgress && 'animate-pulse'}`}>
              <Button
                onClick={onSend}
                size="small"
                description="Send"
              />
            </div>
          </>
        )
      }

      {
        showSuccessBlock && (
          <SuccessBlock
            onClick={() => setShowSuccessBlock(false)}
            title="Message sent"
            description="We will review it and comeback to you as soon as possible. Thank you for contacting us."
            successful
          />
        )
      }
    </div>
  );
}
