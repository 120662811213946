import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowPathIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../helpers/styling';
import Button from '../../../elements/Button';
import ErrorModal from '../../../elements/ErrorModal';
import UserAvatar from '../../../elements/UserAvatar';
import {
  useDeleteMemberAccountMutation,
  useAssignCorporationMemberRoleMutation,
  useResetMemberPasswordMutation,
} from '../settingsAPI';
import { ProfilePageType } from '../../../app/account';
import { CorporationMemberRole } from '../setings-types';
import { useBrandingLocale } from '../../../helpers/hooks';

// todo: use CorporationMember type
interface MemberCardProps {
  name: string,
  // eslint-disable-next-line react/no-unused-prop-types
  position: string,
  email: string,
  avatarUrl?: string,
  isAdmin: boolean,
  accountID: number,
  corporationID?: number,
  profilePageId: number,
  isUniqueAdministrator: boolean,
}

export default function MemberCard(props: MemberCardProps) {

  const {
    name, email, avatarUrl, isAdmin, accountID, corporationID, profilePageId, isUniqueAdministrator,
  } = props;

  const [assignAdminRightsModalOpen, setAssignAdminRightsModalOpen] = useState(false);
  const [revokeAdminRightsModalOpen, setRevokeAdminRightsModalOpen] = useState(false);
  const [openUniqueAdminModal, setOpenUniqueAdminModal] = useState(false);
  const [openDeletingAdminMemberModal, setOpenDeletingAdminMemberModal] = useState(false);
  const [isDeleteState, setIsDeleteState] = useState(false);
  const [isResetPasswordState, setIsResetPasswordState] = useState(false);

  const [resetMemberPasswordQuery, { isLoading: resetPasswordInProgress }] = useResetMemberPasswordMutation();
  const [deleteMemberAccountQuery, { isLoading: deletionInProgress }] = useDeleteMemberAccountMutation();
  const [assignCorporationMemberRoleQuery] = useAssignCorporationMemberRoleMutation();

  const { t, tk } = useBrandingLocale();

  const onChangeMemberRole = async () => {
    if (isUniqueAdministrator) {
      setOpenUniqueAdminModal(true);
      return;
    }

    if (isAdmin) {
      setRevokeAdminRightsModalOpen(true);
    } else {
      setAssignAdminRightsModalOpen(true);
    }
  };

  const onClickDeleteMember = async () => {
    if (isAdmin) {
      setOpenDeletingAdminMemberModal(true);
      return;
    }

    setIsDeleteState(true);
  };

  const assignAdminRights = async () => {
    if (! corporationID) return;

    await assignCorporationMemberRoleQuery({ corporationID, memberAccountID: accountID, role: CorporationMemberRole.admin });
    setAssignAdminRightsModalOpen(false);
    window.location.reload();
  };

  const revokeAdminRights = async () => {
    if (! corporationID) return;
    if (isUniqueAdministrator) return;

    await assignCorporationMemberRoleQuery({ corporationID, memberAccountID: accountID, role: CorporationMemberRole.member });
    setRevokeAdminRightsModalOpen(false);
    window.location.reload();
  };

  return (
    <>
      <div className={classNames(
        `${isDeleteState ? 'bg-error-600' : ''}`,
        'p-4 flex flex-row items-center border mb-2 rounded-md justify-between',
      )}
      >
        {/* Left block */}
        <div className="flex flex-row items-center ">

          <Link
            to={`/profile/${profilePageId}`}
          >
            <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={avatarUrl} size="big" />
          </Link>

          <div className="pl-4">

            <Link
              to={`/profile/${profilePageId}`}
            >
              <span className={`font-semibold text-2xl  ${isDeleteState && 'text-white'}`}>
                {name}
              </span>
            </Link>

            {/* <p className={`font-semibold text-base pb-1  ${isDeleteState && 'text-white'}`}> */}
            {/*  {position} */}
            {/* </p> */}
            <p className={`text-sm font-semibold pt-1 ${isDeleteState ? 'text-white' : 'text-main-600'}`}>
              {email}
            </p>
          </div>
        </div>

        {/* Right block */}
        {
          (! isDeleteState && ! isResetPasswordState) && (
            <div>
              <div className="border rounded-md flex flex-row items-center p-4 hover:bg-main-50 cursor-pointer">
                <input
                  type="radio"
                  onClick={() => {
                    onChangeMemberRole();
                  }}
                  onChange={() => {}}
                  checked={isAdmin}
                  id={`admin-${name}`}
                  className={classNames(
                    // settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                    // settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                    isAdmin ? 'text-main-600 bg-main-50 border-main-200 z-10' : 'border-neutral-200',
                    'relative border flex cursor-pointer focus:outline-none p-2',
                  )}
                />
                <label
                  htmlFor={`admin-${name}`}
                  className="ml-4 font-semibold text-xs pr-16"
                >
                  Administrator
                </label>
              </div>

              <div className="pt-4 grid grid-cols-2 gap-4">
                <Button
                  onClick={() => setIsResetPasswordState(true)}
                  size="small"
                  inverted
                  roundedFull
                  disabled={isAdmin}
                  description="Reset password"
                />
                <Button
                  onClick={() => onClickDeleteMember()}
                  size="small"
                  // disabled={isAdmin}
                  redText
                  inverted
                  roundedFull
                  description="Delete member"
                />
              </div>
            </div>
          )
        }
        {/* Right block delete confirmation */}
        {
          isDeleteState && (
            <div className="bg-white p-4 rounded-md shadow-md">
              <div className="py-2 flex flex-row items-center">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-error-25">
                  {
                    deletionInProgress
                      ? <ArrowPathIcon className="h-4 w-4 text-error-600 animate-spin" />
                      : <ExclamationTriangleIcon className="h-4 w-4 text-error-600" aria-hidden="true" />
                  }
                </div>
                <span className="pl-4 font-bold">Delete member?</span>
              </div>
              <p style={{ maxWidth: 400 }} className="font-semibold text-sm">
                { 
                  tk('Delete_Corporation_Member_Message', {
                    name,
                  })
                }
              </p>
              <div className="flex flex-row justify-end mt-2">
                <Button
                  onClick={() => setIsDeleteState(false)}
                  roundedFull
                  size="small"
                  inverted
                  description="Cancel"
                  disabled={deletionInProgress}
                />
                <div className="pl-2">
                  <Button
                    onClick={async () => {
                      if (! corporationID) return;

                      await deleteMemberAccountQuery({ corporationID, memberAccountID: accountID });
                      setIsDeleteState(false);
                    }}
                    size="small"
                    roundedFull
                    fullRed
                    inverted
                    isLoading={deletionInProgress}
                    description="Yes, Delete Member"
                  />
                </div>
              </div>
            </div>
          )
        }
        {/* Right block password reset */}
        {
          isResetPasswordState && (
            <div className="bg-white p-4 border border-main-600 rounded-md shadow-md">
              <div className="py-2 flex flex-row items-center">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-main-25">
                  {
                    resetPasswordInProgress
                      ? <ArrowPathIcon className="h-4 w-4 text-main-600 animate-spin" />
                      : <QuestionMarkCircleIcon className="h-4 w-4 text-main-600" aria-hidden="true" />
                  }
                </div>
                <span style={{ maxWidth: 280 }} className="pl-4 font-bold">Do you want to reset the users password?</span>
              </div>

              <p style={{ maxWidth: 400 }} className="text-sm">
                The user will get an email with the new password.
              </p>
              <div className="flex flex-row justify-end mt-5">
                <Button
                  onClick={() => setIsResetPasswordState(false)}
                  roundedFull
                  size="small"
                  inverted
                  description="Cancel"
                  disabled={resetPasswordInProgress}
                />
                <div className="pl-2">
                  <Button
                    onClick={async () => {
                      if (! corporationID) return;
                      await resetMemberPasswordQuery({ corporationID, memberAccountID: accountID });
                      setIsResetPasswordState(false);
                    }}
                    isLoading={resetPasswordInProgress}
                    size="small"
                    roundedFull
                    description="Yes, reset password"
                  />
                </div>
              </div>
            </div>
          )
        }
      </div>

      { /* ASSIGN ADMIN RIGHTS */ }
      <ErrorModal
        withErrorBorder={false}
        show={assignAdminRightsModalOpen}
        setOpen={setAssignAdminRightsModalOpen}
        onActionClick={assignAdminRights}
        actionButtonText="Assign Administrator rights"
        imageSrc={avatarUrl}
        title={`Assign Administrator rights to ${name}?`}
        description="After this action is done, the page will be reloaded."
      />

      { /* REVOKE ADMIN RIGHTS */ }
      <ErrorModal
        withErrorBorder={false}
        show={revokeAdminRightsModalOpen}
        setOpen={setRevokeAdminRightsModalOpen}
        onActionClick={revokeAdminRights}
        actionButtonText="Revoke Administrator rights"
        imageSrc={avatarUrl}
        title={`Revoke Administrator rights to ${name}?`}
        description="After this action is done, the page will be reloaded."
      />

      { /* SIGNLE ADMINISTRATOR ASSIGN ROLE NOTICE */ }
      <ErrorModal
        show={openUniqueAdminModal}
        setOpen={setOpenUniqueAdminModal}
        actionButtonText=""
        title="Revoke Administrator rights"
        description={`Oops! It seems you are about to revoke the administrator rights of ${name}. Before proceeding, please ensure that another user has been assigned administrator rights. If you require any assistance or have questions, don't hesitate to reach out to our support team. Thank you for your cooperation!`}
      />

      { /* ADMINISTRATOR MEMBER NOTICE WHEN DELETING MEMBER */ }
      <ErrorModal
        show={openDeletingAdminMemberModal}
        setOpen={setOpenDeletingAdminMemberModal}
        actionButtonText=""
        title="Delete Member"
        description={`Oops! It seems you are about to delete member ${name}. Before proceeding, please ensure that administrator rights are revoked for ${name}. If you require any assistance or have questions, don't hesitate to reach out to our support team. Thank you for your cooperation!`}
      />
    </>
  );
}
