import React from 'react';
import {
  components, MultiValue, MultiValueGenericProps, OptionProps,
} from 'react-select';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { ISearchFilter, ISearchFilterItem } from '../../search-types';
import AppSelect from '../../../../elements/AppSelect';
import { useAppDispatch } from '../../../../app/hooks';
import { setFilterSelected } from '../../searchSlice';

interface SearchFilterProps {
  filter: ISearchFilter;
}

export default function SearchFilter(componentProps: SearchFilterProps) {

  const { filter } = componentProps;

  const dispatch = useAppDispatch();

  // todo: extract
  function transformToDefaultValues(values?: ISearchFilterItem[]) {
    if (! values || values.length === 0) return [];

    return values.map((item) => ({
      value: item.name,
      label: `${item.name} (${item.associatedProfilePageIds.length})`,
      parentName: item.parentName,
      iconUrl: item.iconUrl,
    }));
  }

  // todo: refactor with ServiceNameField
  // @ts-ignore
  const Option = ({ children, ...props }: OptionProps & { data: any }) => {

    // optimization
    // eslint-disable-next-line no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    const { data } = newProps;

    return (
      <>
        <components.Option
          {...newProps}
          className={`react-select-option
           weight nesting-${data.nestingLevel} flex-row items-center`}
        >

          <div className="flex flex-row justify-center items-center">

            <div className="w-4 h-4 bg-main-75 font-semibold text-2xs text-main-600 rounded-full flex justify-center items-center">
              {
                (data.iconUrl) && (
                  <img
                    alt=""
                    className="w-4 h-4"
                    src={data.iconUrl}
                  />
                )
              }
              {
                (! data.iconUrl) && (
                  <span>{ data.label[0] }</span>
                )
              }
            </div>

            <div className="flex-col pl-2">
              <p className="text-2xs text-neutral-600">
                { data.parentName }
              </p>
              {' '}
              <p className="text-sm">
                {data.label}
              </p>
            </div>
          </div>

        </components.Option>
      </>
    );
  };

  // todo: extract
  const MultiValueLabel = ({ children, ...props }: MultiValueGenericProps) => (
    <components.MultiValueLabel {...props}>
      <div className="flex flex-row items-center">
        <FunnelIcon style={{ minWidth: 12 }} className="w-3.5 h-3.5 text-neutral-600 mr-1" />
        {' '}
        <div className="">
          <p className="text-2xs text-neutral-600">
            { props.data.parentName }
          </p>
          <span>{children}</span>
        </div>
      </div>
    </components.MultiValueLabel>
  );

  const onTagSelection = (newValues: MultiValue<any>) => {
    dispatch(setFilterSelected({
      type: filter.filterType,
      filterNames: newValues.map((v) => v.value),
    }));
  };

  const options = transformToDefaultValues(filter.items);

  return (
    <AppSelect
      isMulti
      placeholder={filter.displayName}
      // defaultValue={}
      value={transformToDefaultValues(filter.items.filter((f) => f.isSelected))}
      onChange={onTagSelection}
      // filterOption={createFilter({ ignoreAccents: false })} // optimization
      options={options}
      // @ts-ignore
      components={{ MultiValueLabel, Option }}
    />
  );
}
