import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { usePrevious } from '../../app/hooks';
import { SliceChatMessage, UserIsTyping } from './messages-types';

export function useSubscribeToChatMessageReceived(
  onEffect: (e: SliceChatMessage | null) => void,
  chatMessageReceivedObservable: SliceChatMessage | null,
) {
  const previousChatMessageReceivedObservable = usePrevious(chatMessageReceivedObservable);

  useEffect(() => {
    if (! isEqual(chatMessageReceivedObservable, previousChatMessageReceivedObservable)) {
      onEffect(chatMessageReceivedObservable);
    }

  // eslint-disable-next-line
  }, [chatMessageReceivedObservable, previousChatMessageReceivedObservable]);
}

// export function useSubscribeToUserIsTyping(
//   onEffect: () => void,
//   isTyping: UserIsTyping, // must be object
// ) {
//   const previousIsTyping = usePrevious(isTyping);
//
//   useEffect(() => {
//     if (! isEqual(isTyping, previousIsTyping)) {
//       onEffect();
//     }
//
//     // eslint-disable-next-line
//   }, [isTyping, previousIsTyping]);
// }
