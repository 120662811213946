// true if not set or if set to true; otherwise false
const getFeatureFlag = (flag?: string): boolean => {
  if (typeof flag === 'undefined') return true;

  return flag === 'true';
};

export const FEATURE_LOGIN_ENABLED = getFeatureFlag(process.env.REACT_APP_FEATURE_LOGIN_ENABLED);
export const FEATURE_REGISTER_ENABLED = getFeatureFlag(process.env.REACT_APP_FEATURE_REGISTER_ENABLED);
export const FEATURE_SEARCH_FILTER_CORPORATES_ENABLED = getFeatureFlag(process.env.REACT_APP_FEATURE_SEARCH_FILTER_CORPORATES_ENABLED);
