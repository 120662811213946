import React from 'react';
import { components, MultiValue, MultiValueGenericProps } from 'react-select';
import { TagIcon } from '@heroicons/react/20/solid';
import AppSelect from '../../../elements/AppSelect';
import InputLoader from '../../../elements/InputLoader';
import { useGetTagsQuery } from '../../tag/tagAPI';
import { CoreTag } from '../../tag/tag-types';

interface TagSelectionProps {
  onChange: (newValue: CoreTag[]) => void,
  defaultValues?: CoreTag[],
  value?: CoreTag[],
  placeholder?: string,
}

function transformToDefaultValues(values?: CoreTag[]) {
  if (! values || values.length === 0) return [];

  return values.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));
}

export const MultiValueLabelTag = ({ children, ...props }: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props}>
    <div className="flex flex-row items-center">
      <TagIcon className="w-3.5 h-3.5 text-neutral-600 mr-1" />
      {' '}
      <span>{children}</span>
    </div>
  </components.MultiValueLabel>
);

export default function TagSelection(componentProps: TagSelectionProps) {

  const {
    onChange, defaultValues, value, placeholder,
  } = componentProps;

  const { data: tags, isFetching, isLoading } = useGetTagsQuery(null);

  if (isFetching || isLoading) {
    return <InputLoader label="Tags" />;
  }

  const options = transformToDefaultValues(tags);

  const onTagSelection = (newValue: MultiValue<any>) => {
    if (! onChange) return;

    onChange(newValue.map((v: { value: number, label: string }) => ({
      id: v.value,
      name: v.label,
    })));
  };

  return (
    <AppSelect
      isMulti
      placeholder={placeholder || 'Start typing'}
      defaultValue={transformToDefaultValues(defaultValues)}
      value={transformToDefaultValues(value)}
      onChange={onTagSelection}
      // filterOption={createFilter({ ignoreAccents: false })} // optimization
      options={options}
      // @ts-ignore
      components={{ MultiValueLabel: MultiValueLabelTag }}
    />
  );
}
