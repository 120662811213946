import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../dashboard-layout/DashboardLayout';

import FeedRightSidebar from './right-sidebar/FeedRightSidebar';
import Post from '../post/Post';

import FilterPosts from './filter-posts/FilterPosts';
import ConnectRequestDisplay from './connect-request/ConnectRequest';

import CreatePost from '../post/create-post/CreatePost';
import { RootState } from '../../app/store';

import './index.scss';
import { useGetFeedContentQuery } from './feedAPI';
import {
  ActivityFeedEventTypeEnum,
  IContactRequestAccepted,
  IFeedContactRequest,
  IFeedMessageRequest,
  IFeedPostResponse,
  IFeedFollowedProfilePageResponse,
  IFeedProfilePageModulesUpdated,
  NewForumPostData,
} from './feed-types';
import MemberRemovedNotification from './MemberRemovedNotification';
import { useAppDispatch } from '../../app/hooks';
import {
  addFeedListItems, setFeedListPage, setHasMoreItems,
} from './feedSlice';
import FeedLoader from './FeedLoader';
import ConnectRequest from '../contact-list/components/ConnectRequest';
import ChatInviteRequest from '../messages/ChatInviteRequest';
import { ChatTypes } from '../messages/messages-types';
import { useDocumentTitle } from '../../helpers/hooks';
import AuthorMetadata from './AuthorMetadata';
import NewForumQuestionCreated from './NewForumQuestionCreated';

// todo: use feed entry IDs as keys
// todo: file display in post
// todo: link display in post
export default function FeedPage() {

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const { profileID: userIdToLoad } = useParams<{ profileID: string }>();
  const isAbleToCreatePost = ! userIdToLoad;
  const isAbleToFilterPosts = ! userIdToLoad;
  const showAuthorsData = Boolean(userIdToLoad);

  const {
    onlyMyPosts, pagination, feedListItems,
  } = useSelector((state: RootState) => state.feed.list);

  const dispatch = useAppDispatch();

  // todo: if invalidated -> clear list
  const { data, isLoading, isFetching } = useGetFeedContentQuery({
    profileID, PageNumber: pagination.pageNumber, PageSize: pagination.pageSize, onlyMyPosts, userIdToLoad,
  }, { skip: ! profileID });

  useDocumentTitle('Feed');

  useEffect(() => {
    if (! data || data.data.length === 0) {
      return;
    }

    dispatch(addFeedListItems(data));

    dispatch(setHasMoreItems(data.totalPages !== pagination.pageNumber)); // has more if current page isn't last page

    // eslint-disable-next-line
  }, [data]);

  let content = null;

  // other loader will show up if there is a data
  // shown on "show only my posts" as well
  if ((isLoading || isFetching) && (! data || ! data.data)) {
    content = (
      <>
        <FeedLoader />
      </>
    );
  }

  if (feedListItems.length === 0 && ! isLoading && ! isFetching) {
    content = (
      <div className="flex w-full justify-center">
        <span className="font-semibold text-neutral-600">No posts yet...</span>
      </div>
    );
  }

  if (data && data.data) {
    content = (
      <>
        { (isLoading || isFetching) && feedListItems.length === 0 && (
        <div className="pb-4">
          <FeedLoader />
        </div>
        )}
        <InfiniteScroll
          dataLength={feedListItems.length}
          next={() => dispatch(setFeedListPage(pagination.pageNumber + 1))}
          hasMore={pagination.hasMore}
          loader={<></>}
          // loader={(
          //   <div className="mt-4">
          //     <FeedLoader />
          //   </div>
          //   )}
        >
          {
            // todo: transform response and store iFeedPost in slice (so new post can be pushed into list)
            feedListItems.map((feedEntry: any) => {
              if (feedEntry.type === ActivityFeedEventTypeEnum.activityFeedPost) {
                const entry: IFeedPostResponse = feedEntry;

                return (
                  <Post
                    key={entry.data.id}
                    postID={entry.data.id}
                    user={{
                      isCorporateUser: entry.data.creator.profilePageAccountType === 'corporation',
                      avatarURL: entry.data.creator.profileImageUrl,
                      name: entry.data.creator.name,
                      profilePageId: entry.data.creator.profilePageId,
                      relatedCorporationName: entry.data.creator.relatedCorporationProfilePageSummary?.name,
                      relatedCorporationProfilePageId: entry.data.creator.relatedCorporationProfilePageSummary?.profilePageId,
                      showCompany: entry.data.creator.showCompany,
                      company: entry.data.creator.company,
                      showJobPosition: entry.data.creator.showJobPosition,
                      jobPosition: entry.data.creator.jobPosition,
                    }}
                    timestamp={`${entry.createdAtUtc}+00:00`}
                    text={entry.data.content}
                    links={entry.data.externalLinks ? entry.data.externalLinks.map((link) => ({
                      id: link.id,
                      linkURL: link.link,
                      title: link.description,
                      inEditMode: false,
                    })) : []}
                    fileAttachmentsFromBackend={entry.data.fileAttachments}
                    PDFs={[]}
                    images={entry.data.imageAttachments ? entry.data.imageAttachments.map((image) => ({
                      id: image.fileUuid,
                      guid: image.fileUuid,
                      linkURL: '',
                      blobURL: '',
                      title: image.description,
                      inEditMode: false,
                    })) : []}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.contactRequestAccepted) {
                const entry: IContactRequestAccepted = feedEntry;
                return (
                  <ConnectRequestDisplay
                    key={entry.eventId}
                    type="connectionSuccessful"
                    profilePage={entry.data.contactRequestAccepterProfilePageSummary}
                    timestamp={`${entry.createdAtUtc}+00:00`}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.memberRemovedFromCorporation) {
                return (
                  <MemberRemovedNotification
                    key={feedEntry.eventId}
                    timestamp={`${feedEntry.createdAtUtc}+00:00`}
                    data={feedEntry.data}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.contactRequest) {
                const entry: IFeedContactRequest = feedEntry;
                return (
                  <ConnectRequest
                    key={entry.eventId}
                    request={{
                      createdDateUtc: entry.createdAtUtc,
                      message: entry.data.message,
                      senderProfilePageSummary: entry.data.contactRequestSenderProfilePageSummary,
                    }}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.messagingRequest) {
                const entry: IFeedMessageRequest = feedEntry;
                return (
                  <ChatInviteRequest
                    key={entry.eventId}
                    chatRequest={{
                      message: entry.data.message,
                      chatType: ChatTypes.oneToOneChat,
                      senderProfilePageSummary: entry.data.messagingRequestSenderProfilePageSummary,
                      createdDateUtc: entry.createdAtUtc,
                    }}
                    size="big"
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.followedProfilePage) {
                const entry: IFeedFollowedProfilePageResponse = feedEntry;
                return (
                  <ConnectRequestDisplay
                    key={entry.eventId}
                    type="followedProfilePage"
                    profilePage={entry.data.followerProfilePageSummary}
                    timestamp={`${entry.createdAtUtc}+00:00`}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.profilePageModulesUpdated) {
                const entry: IFeedProfilePageModulesUpdated = feedEntry;
                return (
                  <ConnectRequestDisplay
                    key={entry.eventId}
                    type="profilePageModulesUpdated"
                    profilePage={entry.data.updatedProfilePageSummary}
                    timestamp={`${entry.createdAtUtc}+00:00`}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.corporationRemovedNotifyMember) {
                return (
                  <MemberRemovedNotification
                    key={feedEntry.eventId}
                    timestamp={`${feedEntry.createdAtUtc}+00:00`}
                  />
                );
              }

              if (feedEntry.type === ActivityFeedEventTypeEnum.newCreatedForumQuestion) {
                const entry: NewForumPostData = feedEntry;
                return (
                  <NewForumQuestionCreated
                    key={feedEntry.eventId}
                    timestamp={`${feedEntry.createdAtUtc}+00:00`}
                    data={entry.data.forumQuestion}
                  />
                );
              }

              return null;
            })
          }
        </InfiniteScroll>
        { (isLoading || isFetching) && feedListItems.length > 0 && (
        <div className="pt-4">
          <FeedLoader />
        </div>
        )}
      </>
    );
  }

  return (
    <DashboardLayout>
      <div>

        {/* Content */}
        <div className="min-h-screen">
          <div className="pt-4">
            <div className="feed-wrapper mx-auto grid grid-cols-12 gap-4">
              <main className="col-span-12 md:col-span-9">
                { showAuthorsData && <AuthorMetadata profileID={Number.parseInt(userIdToLoad, 10)} />}
                { isAbleToCreatePost && <CreatePost />}
                { isAbleToFilterPosts && <FilterPosts />}

                { content }

                {/* Won't be used for now */}
                {/* <InternalLinkPost */}
                {/*  type="reply" */}
                {/*  link="/feed" */}
                {/*  isCorporateUser={false} */}
                {/*  avatarURL="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" */}
                {/*  userName="Mark Izu" */}
                {/*  timestamp="1 day ago" */}
                {/* /> */}

              </main>
              <aside className="hidden md:block md:col-span-3">
                <FeedRightSidebar />
              </aside>
            </div>
          </div>
        </div>

      </div>
    </DashboardLayout>
  );
}
