import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { FaqQuestionEntry } from './contact-us-types';

interface FaqQuestionProps {
  question: FaqQuestionEntry,
}

export default function FaqQuestion(props: FaqQuestionProps) {
  const { question } = props;
  const [isVisible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <div className="mb-4">
      <div className="mt-4">
        <button onClick={toggleVisibility} type="button">
          <div className="flex items-center">
            <h3 className="font-semibold text-lg">{question.questionTitle}</h3>
            {isVisible ? (
              <ChevronUpIcon
                className="ml-2 h-6 w-6 text-gray-500 cursor-pointer"
              />
            ) : (
              <ChevronDownIcon
                className="ml-2 h-6 w-6 text-gray-500 cursor-pointer"
              />
            )}
          </div>
        </button>
        {isVisible && (
          <div className="mt-4 mb-8">
            {question.answer.topLines && question.answer.topLines.map((topLine) => (
              <p className="text-sm leading-6 mt-2" key={`${question.questionTitle}-topLine-${topLine}`}>{topLine}</p>
            ))}
            {question.answer.steps && question.answer.steps.length > 0 && (
              <ul className="mt-3 mb-1 list-disc list-inside text-sm pl-4">
                {question.answer.steps.map((step) => (
                  <li key={`${question.questionTitle}-step-${step}`} className="mb-2">{step}</li>
                ))}
              </ul>
            )}
            {question.answer.bottomLines && question.answer.bottomLines.map((bottomLine) => (
              <p className="text-sm leading-6" key={`${question.questionTitle}-bottomLine-${bottomLine}`}>{bottomLine}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
