/* eslint-disable import/no-named-default */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { default as common }     from './locales/en/common.json';
import { default as commonIPLS } from './locales/en/common-ipls.json';

import { default as commonUa }   from './locales/ua/common.json';
import { default as commonUaIPLS }   from './locales/ua/common-ipls.json';
import { BRANDING } from './helpers/branding';

const DEBUG_FLAG = process.env.REACT_APP_I18N_DEBUG === 'true' || false;

/* NOTE: i18n is recursive, so can significantly slow compilation */
export const defaultNS = 'common';
export const resources = {
  en: {
    common,
  },
  ua: {
    common: commonUa,
  },
} as const;

export const resourcesIPLS = {
  en: {
    common: commonIPLS,
  },
  ua: {
    common: commonUaIPLS,
  },
} as const;

export const i18nConfig = {
  lng: 'en',
  ns: ['common'],
  defaultNS,
  resources: BRANDING === 'IPLS' ? resourcesIPLS : resources,

  debug: DEBUG_FLAG,

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
  */
};

i18n.use(initReactI18next).init(i18nConfig);
