import React, { useState } from 'react';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import { useDocumentTitle } from '../../helpers/hooks';
import SectionHeader from '../../elements/SectionHeader';
import FeedModerationFilters from './FeedModerationFilters';
import FeedModerationList from './FeedModerationList';
import Switch from '../../elements/Switch';

export default function FeedModeration() {

  const [wideMode, setWideMode] = useState(false);

  useDocumentTitle('Feed Moderation');

  return (
    <DashboardLayout>
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4 grid grid-cols-12 gap-4">
        <div className={`col-span-12 ${wideMode ? '' : 'md:col-span-9'}`}>
          <SectionHeader title="Feed Moderation">
            <Switch
              checked={wideMode}
              onChange={() => setWideMode(! wideMode)}
              label="Wide mode"
              size="sm"
            />
          </SectionHeader>

          <FeedModerationFilters />

          <FeedModerationList />
        </div>
      </main>
    </DashboardLayout>
  );
}
