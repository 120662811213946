import React, {
  useState, Fragment, useCallback,
} from 'react';
import { QrCodeIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import QRCode from 'qrcode';
import { useSelector } from 'react-redux';
import Button from '../../../elements/Button';
import { RootState } from '../../../app/store';

export default function ShowQRButton() {

  const [open, setOpen] = useState(false);

  const url = window.location.href;

  const { name } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);

  const handleCanvasMount = useCallback((node) => {
    if (! node) return;

    QRCode.toCanvas(node, url, () => {});
  }, [url]);

  const onOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={onOpenModal}
        className="inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none "
      >
        <QrCodeIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>Show QR</span>
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <p className="font-semibold text-left pb-4">
                  {name}
                  &apos;s profile page link:
                </p>

                <div className="flex items-center justify-center">
                  <canvas ref={handleCanvasMount} />
                </div>

                <div className="pt-1 flex justify-end">
                  <Button
                    onClick={() => setOpen(false)}
                    size="small"
                    description="Close"
                  />
                </div>
              </div>

            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
