/* eslint-disable react/no-array-index-key */

import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Presentation as PresentationType } from '../../products-module.types';
import Button from '../../../../../../elements/Button';
import { addPresentation } from '../../productsModuleSlice';
import { useAppDispatch } from '../../../../../../app/hooks';
import PresentationFieldsGroup from './fields/PresentationFieldsGroup';

interface PresentationProps {
  productID: number,
  presentations?: PresentationType[],
}

export default function Presentation(props: PresentationProps) {

  const { productID, presentations } = props;

  const dispatch = useAppDispatch();

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        Dosage form / Strength / Presentation
      </p>

      {
        (presentations && presentations.length > 0) && presentations.map((presentation, idx) => (
          <PresentationFieldsGroup key={idx} presentation={presentation} idx={idx} productID={productID} />
        ))
      }

      <div className="flex flex-row justify-end mt-4">
        <Button
          onClick={() => dispatch(addPresentation({ productID }))}
          size="small"
          description="Add Presentation"
          iconLeft={<PlusIcon className="w-4 h-4" />}
          roundedFull
          inverted
        />
      </div>

    </div>
  );
}
