import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import Input from '../../../../../elements/Input';
import Button from '../../../../../elements/Button';
import { IFeedFileResponse } from '../../../../feed/feed-types';
import { EMPTY_FILE } from '../../about-module/helpers';
import FileUpload from '../../../../../elements/files/FileUpload';

interface ServiceFileFieldProps {
  value: IFeedFileResponse[] | null,
  onChange: (file: IFeedFileResponse) => void,
  setLocalFile: (f: File | null) => void,
}

export default function ServiceFileField(props: ServiceFileFieldProps) {

  const { value, onChange, setLocalFile } = props;

  const file: IFeedFileResponse = value && value.length > 0 ? value[0] : EMPTY_FILE;

  const [deleteModeFile, setDeleteModeFile] = useState(false);

  const disabled = Boolean(file.fileUuid);

  return (
    <div className="p-2 mt-2 bg-neutral-25 border rounded-md">
      <p className="p-2 text-xs text-neutral-700">Attach a PDF</p>

      <div className="mt-2">
        <Input
          value={file.description}
          disabled={disabled}
          id="df-key-3"
          label="Title of the PDF"
          placeholder="Title"
          onChange={(e) => onChange({ ...file, description: e.target.value })}
        />
      </div>

      <FileUpload
        file={{
          ...file,
          title: file.description,
        }}
        onFileRemoval={() => onChange(EMPTY_FILE)}
        onFileDrop={(objectURL, localFile) => {
          setLocalFile(localFile);

          onChange({
            ...file,
            fileUrl: objectURL,
            originalFilename: localFile.name,
            fileSizeBytes: localFile.size,
          });
        }}
        showDeleteButton={false}
      />

      {/* { */}
      {/*  disabled && ( */}
      {/*    <InfoMessage message="You cannot edit uploaded file" size="tiny" /> */}
      {/*  ) */}
      {/* } */}

      <div className="flex flex-row mt-2">
        <div className="max-w-max mr-2">
          <Button
            onClick={() => setDeleteModeFile(true)}
            size="small"
            description=""
            roundedFull
            inverted={! deleteModeFile}
            fullRed
            iconLeft={<TrashIcon className="w-4 h-4" />}
          />
        </div>

        {
          deleteModeFile && (
            <>
              <Button
                onClick={() => setDeleteModeFile(false)}
                size="small"
                description="Cancel"
                roundedFull
                fullRed
              />
              <div className="ml-2">
                <Button
                  onClick={() => {
                    setDeleteModeFile(false);
                    setLocalFile(null);
                    onChange(EMPTY_FILE);
                  }}
                  size="small"
                  description="Delete"
                  redText
                  roundedFull
                  inverted
                />
              </div>
            </>
          )
        }
      </div>

    </div>
  );
}
