import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Licence } from '../../../products-module.types';
import Input from '../../../../../../../elements/Input';
import { useAppDispatch } from '../../../../../../../app/hooks';
import Button from '../../../../../../../elements/Button';
import { deleteLicenceNumber, editLicenceNumber } from '../../../productsModuleSlice';

interface LicenceNumberFieldProps {
  licence: Licence,
  productID: number,
  idx: number,
}

export default function LicenceNumberField(props: LicenceNumberFieldProps) {

  const { licence, productID, idx } = props;

  const [deleteMode, setDeleteMode] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className="mt-2 border rounded-md p-2 bg-neutral-50">
      <Input
        value={licence.licenceNumber}
        placeholder="Number"
        id={`${productID}-${idx}-l-num`}
        label="Licence Number"
        onChange={(e) => dispatch(editLicenceNumber({ productID, idx, value: e.target.value }))}
      />

      <div className="pt-2 flex flex-row justify-between">
        <div className="flex flex-row">
          <div className="max-w-max mr-2">
            <Button
              onClick={() => setDeleteMode(true)}
              size="small"
              description=""
              roundedFull
              inverted={! deleteMode}
              fullRed
              iconLeft={<TrashIcon className="w-4 h-4" />}
            />
          </div>

          {
            deleteMode && (
              <>
                <Button
                  onClick={() => setDeleteMode(false)}
                  size="small"
                  description="Cancel"
                  roundedFull
                  fullRed
                />
                <div className="ml-2">
                  <Button
                    onClick={() => {
                      setDeleteMode(false);
                      dispatch(deleteLicenceNumber({ productID, idx }));
                    }}
                    size="small"
                    description="Delete"
                    redText
                    roundedFull
                    inverted
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
