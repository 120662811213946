import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { clone, orderBy } from 'lodash';
import { CorporationMemberType, TeamModuleGet } from './team-module-types';
import { CorporationMember } from '../../../settings/setings-types';

const EMPTY_MEMBER_CARD: CorporationMemberType = {
  id: undefined,
  localID: nanoid(),
  accountId: undefined,
  fullName: '',
  jobPosition: '',
  profileImageUrl: '',
  email: '',
  displayEmail: false,
  messagingAllowed: false,
  memberOrder: 999,

  profileImageUuid: '',
  profilePageId: undefined,
};

interface TeamModuleSlice {
  description: string,
  teamMembersList: CorporationMemberType[],
  teamMembersToSelect: CorporationMemberType[]
}

const initialState: TeamModuleSlice = {
  description: '',
  teamMembersList: [],
  teamMembersToSelect: [],
};

export const teamModuleSlice = createSlice({
  name: 'teamModule',
  initialState,
  reducers: {

    updateDescription: (state, action) => {
      state.description = action.payload;
    },

    updateMemberCard: (state, action: PayloadAction<CorporationMemberType>) => {
      state.teamMembersList = state.teamMembersList.map((member) => {
        if (
          (action.payload.id && action.payload.id === member.id)
          || (action.payload.localID && action.payload.localID === member.localID)
        ) {
          return action.payload;
        }

        return member;
      });

    },

    addMemberCard: (state) => {
      state.teamMembersList = [
        ...state.teamMembersList,
        {
          ...EMPTY_MEMBER_CARD,
          localID: nanoid(), // potential key duplicate ?
          memberOrder: state.teamMembersList.length + 1,
        },
      ];
    },

    // todo: can be reverted back to O(n) operation (just filter)
    deleteMemberCard: (state, action: PayloadAction<CorporationMemberType>) => {
      state.teamMembersList = orderBy(state.teamMembersList, ['memberOrder'])
        .filter((member) => (action.payload.id && action.payload.id !== member.id)
          || (action.payload.localID && action.payload.localID !== member.localID))
        .map((member, idx) => ({
          ...member,
          memberOrder: idx + 1,
        }));
    },

    initiateTeamModule: (state, action: PayloadAction<{ teamModuleData: TeamModuleGet, listOfMembers: CorporationMember[] }>) => {

      state.description = action.payload.teamModuleData.description ? action.payload.teamModuleData.description : '';
      state.teamMembersList = action.payload.teamModuleData.moduleCorporationTeamMembers;
      state.teamMembersToSelect = action.payload.listOfMembers.map((m) => ({
        id: undefined,
        localID: nanoid(),
        accountId: m.accountId,
        fullName: `${m.firstname || ''} ${m.surname || ''}`.trim(),
        jobPosition: '',
        profileImageUrl: m.profileImageUrl,
        email: m.email,
        displayEmail: false,
        messagingAllowed: false,
        memberOrder: 999, // todo: calculate last ?

        profileImageUuid: m.profileImageUuid,
        profilePageId: m.individualProfilePageId,
      }));
    },

    reorder: (state, action) => {

      const { startIndex, endIndex } = action.payload;

      let result = Array.from(state.teamMembersList);
      const [removed] = result.splice(startIndex, 1);

      const copy = removed;
      copy.memberOrder = endIndex + 1;

      // ordered array
      result.splice(endIndex, 0, copy);

      // update counter for every field
      result = result.map((r, index) => {
        const clonedRes = clone(r);
        clonedRes.memberOrder = index + 1;
        return clonedRes;
      });

      state.teamMembersList = result;
    },
  },
});

export const {
  initiateTeamModule,
  updateDescription,
  reorder,
  updateMemberCard,
  deleteMemberCard,
  addMemberCard,
} = teamModuleSlice.actions;

export default teamModuleSlice.reducer;
