import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../../dashboard-layout/DashboardLayout';
import UserAvatar from '../../../elements/UserAvatar';
import PostTextarea from '../../post/create-post/PostTextarea';
import PostOnBehalfMessage from '../../post/create-post/PostOnBehalfMessage';
import { RootState } from '../../../app/store';
import Input from '../../../elements/Input';
import SectionHeader from '../../../elements/SectionHeader';
import ForumTagsInput from './ForumTagsInput';
import Button from '../../../elements/Button';
import { useAppDispatch } from '../../../app/hooks';
import {
  resetCreateQuestionForm,
  setCreateQuestionDescription,
  setCreateQuestionTitle,
  setCreateQuestionTags,
} from './postQuestionSlice';
import { usePostQuestionMutation } from '../forumAPI';
import { useDocumentTitle } from '../../../helpers/hooks';
import { useFormValidation } from '../../../helpers/validation';
import { rulesForumQuestionForm, validationErrorState, ValidationKeys } from '../questionValidationRules';

export default function PostQuestion() {
  useDocumentTitle('Post a Question');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentProfile } = useSelector((state: RootState) => state.account);
  const { description, tags, title } = useSelector((state: RootState) => state.forum.postQuestion.createQuestionForm);

  const [postQuery] = usePostQuestionMutation();

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForumQuestionForm,
    validationErrorState,
    {
      title,
      description,
      tags,
    },
  );

  const onSubmit = async () => {
    if (! currentProfile || ! currentProfile.profileID) return;

    const val = validate();

    if (! val.allValid) return;

    setIsSubmitting(true);

    const response = await postQuery({ creatorProfileID: currentProfile.profileID, form: { description, tags, title } });

    // @ts-ignore
    if (response.data && response.data.id) {
      // @ts-ignore
      history.push(`/forum/question/${response.data.id}`);
    }

    setIsSubmitting(false);
  };

  return (
    <DashboardLayout>
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">

        <SectionHeader title="Post a Question">
          <Button
            onClick={() => history.push('/forum')}
            size="small"
            inverted
            description="Back to questions"
            iconLeft={<ArrowLeftIcon className="w-4 h-4 mr-2" />}
            roundedFull
          />
        </SectionHeader>

        <div className="relative bg-white pt-1 px-6 pb-6 mb-2 shadow shadow-lg rounded rounded-lg flex flex-row mt-4">
          <div className="r-5 pt-4 mr-4">
            <UserAvatar
              profilePageType={currentProfile.profilePageType}
              profileImageUrl={currentProfile.profileImageUrl}
              size="medium"
            />
          </div>

          {/* Right side */}
          <div className="w-full pt-4">

            <Input
              value={title}
              placeholder="e.g. Is there a chemical N to produce X?"
              onChange={(e) => {
                dispatch(setCreateQuestionTitle(e.target.value));
                onValidationInputChange('title');
              }}
              label="Title"
              id="ask-new-forum-question"
              validationMessage={validationState.title.errorMessage}
            />

            <div className="mt-4">
              <PostTextarea
                text={description}
                onChange={(e) => {
                  dispatch(setCreateQuestionDescription(e.target.value));
                  onValidationInputChange('description');
                }}
                placeholder="Describe your question"
                minHeight="h-32"
                validationMessage={validationState.description.errorMessage}
              />
            </div>

            <div className="mt-4">
              <ForumTagsInput
                values={tags}
                onChange={(newTagsMV) => {
                  const newTags = newTagsMV.map((mv) => mv);
                  dispatch(setCreateQuestionTags(newTags));
                  onValidationInputChange('tags');
                }}
                validationMessage={validationState.tags.errorMessage}
              />
            </div>

            <PostOnBehalfMessage />

            <div className="flex justify-end gap-4 mt-6">
              <Button
                onClick={() => dispatch(resetCreateQuestionForm())}
                size="x-medium"
                description="Clear form"
                inverted
                redText
                iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
              />
              <Button
                onClick={onSubmit}
                size="x-medium"
                description="Post Question"
                disabled={isSubmitting}
                iconLeft={<PencilSquareIcon className="w-4 h-4 mr-2" />}
              />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}
