import React from 'react';
import { useSelector } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/20/solid';
import FilterInput from '../contact-list/components/FilterInput';
import { RootState } from '../../app/store';
import ForumTagsInput from './post-question/ForumTagsInput';
import { useAppDispatch } from '../../app/hooks';
import {
  setForumFilterProfile,
  setForumFiltersTags,
  setForumSearch,
} from './questions-list/forumQuestionsSlice';
import { QuestionSortFilters } from './forum-types';
import { useGetProfileByIdQuery } from '../profile-page/profilePageAPI';

// TODO: reset filters
export default function ForumFilters() {

  const { profileID } = useSelector((state: RootState) => state.account.currentProfile);
  const { searchInput } = useSelector((state: RootState) => state.forum.questionsList.getListWithSearchRequest);
  const { getListRequest: { filters } } = useSelector((state: RootState) => state.forum.questionsList);

  const dispatch = useAppDispatch();

  const { data: filterProfile } = useGetProfileByIdQuery({ id: filters.filter.creatorProfilePageId || 0, requesterID: profileID || 0 }, {
    skip: (! filters.filter.creatorProfilePageId) || (! profileID),
  });

  return (
    <div className={`border border-1 mt-4 rounded rounded-lg transition-colors ${searchInput ? 'bg-neutral-50' : 'bg-white'}`}>
      <p className="text-xs px-4 py-2.5 font-bold">
        Filters
        {' '}
        <span className="text-neutral-400">{ searchInput ? '(not applied)' : ''}</span>
      </p>

      <div className="px-4">
        <ForumTagsInput
          values={filters.filter.tags}
          onChange={(newTagsMV) => {
            const newTags = newTagsMV.map((mv) => mv);

            dispatch(setForumFiltersTags(newTags));
          }}
        />
      </div>

      <div className="my-2 flex flex-row justify-between items-center w-full">

        <div className="ml-4">
          {
            filterProfile && filters.filter.creatorProfilePageId && (
              <div className="text-xs text-white rounded-full bg-neutral-700 pl-2.5 flex flex-row items-center">
                <span className="font-semibold">Questions by:</span>
                <span className="ml-1">{filterProfile.name}</span>
                <button
                  type="button"
                  className="ml-2 transition-transform hover:scale-110"
                  onClick={() => dispatch(setForumFilterProfile(null))}
                >
                  <XCircleIcon className="w-6 h-6" />
                </button>
              </div>
            )
          }
        </div>
        <div className="flex flex-row flex-wrap">
          <FilterInput
            onChange={() => dispatch(setForumSearch(QuestionSortFilters.hottest))}
            checked={filters.sorting === QuestionSortFilters.hottest}
            inputID="forum-sort-hottest"
            labelName="Hottest first"
            type="radio"
          />
          <FilterInput
            onChange={() => dispatch(setForumSearch(QuestionSortFilters.newestFirst))}
            checked={filters.sorting === QuestionSortFilters.newestFirst}
            inputID="forum-sort-newest"
            labelName="Newest first"
            type="radio"
          />
          <FilterInput
            onChange={() => dispatch(setForumSearch(QuestionSortFilters.oldestFirst))}
            checked={filters.sorting === QuestionSortFilters.oldestFirst}
            inputID="forum-sort-oldest"
            labelName="Oldest first"
            type="radio"
          />
          <FilterInput
            onChange={() => dispatch(setForumSearch(QuestionSortFilters.unanswered))}
            checked={filters.sorting === QuestionSortFilters.unanswered}
            inputID="forum-sort-unanswered"
            labelName="Unanswered"
            type="radio"
          />
        </div>
      </div>
    </div>
  );
}
