import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import { useAcceptContactRequestMutation, useGetContactsListRequestsQuery } from '../contact-list/contactListAPI';
import { RootState } from '../../app/store';
import { useDocumentTitle } from '../../helpers/hooks';
import { RequestType } from './request-types';
import { useAcceptMessageRequestMutation } from '../messages/messagesAPI';
import { SenderProfilePageSummary } from '../contact-list/contact-list.types';
import UserAvatar from '../../elements/UserAvatar';

export default function AcceptRequestPage() {

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  // @ts-ignore
  const { senderProfileID } = useParams();
  // @ts-ignore
  const { receiverProfileID } = useParams();
  // @ts-ignore
  const { requestType } = useParams();

  const [acceptContactRequestQuery, { isLoading: isAcceptingContactInProgress, data: acceptContactData }] = useAcceptContactRequestMutation();
  const [acceptMessagingRequestQuery, { isLoading: isAcceptingMessagingInProgress, data: acceptMessageRequestData }] = useAcceptMessageRequestMutation();

  useEffect(() => {
    if (requestType === RequestType.contact) {
      const payload = {
        receiverProfilePageId: receiverProfileID,
        senderProfilePageId: senderProfileID,
      };
      acceptContactRequestQuery(payload);
    } else if (requestType === RequestType.messaging) {
      const payload = {
        recipientProfilePageId: receiverProfileID,
        senderProfilePageId: senderProfileID,
      };
      acceptMessagingRequestQuery(payload);
    }
    
  }, [acceptContactRequestQuery, acceptMessagingRequestQuery, requestType, senderProfileID, receiverProfileID]);

  let contactSummary: SenderProfilePageSummary | undefined;
  let requestTypeName = '';
  if (requestType === RequestType.contact) {
    contactSummary = acceptContactData?.acceptedRequestProfilePageSummary;
    requestTypeName = 'Contact';
  } else if (requestType === RequestType.messaging) {
    contactSummary = acceptMessageRequestData?.acceptedRequestProfilePageSummary;
    requestTypeName = 'Messaging';
  }

  useDocumentTitle(`${requestTypeName} Request`);

  function getAcceptedRequestContent() {
    if (contactSummary == null) {
      return (
        <div>
          <h3 className="font-bold text-md">
            { `Oops! It seems there was an error accepting the ${requestTypeName} request. If you require any assistance or have questions, feel free to contact our support team. Thank you for your cooperation!` }
          </h3>
        </div>
      );
    }
  
    return (
      <div>
        <h3 className="font-bold text-lg text-center">
          {`${requestTypeName} request accepted! You are now connected with ${contactSummary.name}.`}
        </h3>
        <div className="flex flex-row justify-center py-4 px-8 mt-4">
          <UserAvatar
            profilePageType={contactSummary.profilePageAccountType}
            profileImageUrl={contactSummary.profileImageUrl}
            size="big"
            profileID={contactSummary.profilePageId}
          />
          <div className="flex flex-col pl-4 justify-center">
            <a className="max-w-fit" href={`/profile/${contactSummary.profilePageId}`}>
              <p className="font-bold text-base text-neutral-800">{contactSummary.name}</p>
            </a>
            { /* show company set in dynamic fields */ }
            {contactSummary.showCompany && contactSummary.company && (
              <p className="text-sm font-semibold text-neutral-600 table-cell">
                {contactSummary.company}
              </p>
            )}
            { /* show job position set in dynamic fields if company is also set */ }
            {contactSummary.showCompany && contactSummary.company && contactSummary.showJobPosition && contactSummary.jobPosition && (
              <p className="text-xs font-semibold text-neutral-600">
                {contactSummary.jobPosition}
              </p>
            )}
            { /* show related corporation name */ }
            {!contactSummary.showCompany && contactSummary.relatedCorporationProfilePageSummary && (
              <a href={`/profile/${contactSummary.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                <p className="font-semibold text-xs text-neutral-800">{contactSummary.relatedCorporationProfilePageSummary.name}</p>
              </a>
            )}
          </div>
        </div>
        <Link
          to={`/profile/${contactSummary.profilePageId}`}
          className="mt-5 mb-4 block mx-auto w-max px-5 py-2 border border-neutral-800 text-xs font-semibold rounded-3xl text-white bg-neutral-800 hover:border-main-700 hover:bg-main-700 hover:text-white focus:outline-none transition-colors duration-300"
        >
          Go to profile
        </Link>
      </div>
    );
  }

  function getLoadingContent() {
    return (
      <div>
        <h3 className="font-bold text-lg text-center">
          {`Accepting ${requestTypeName} request...`}
        </h3>
        <div className="w-full mx-auto mt-4">
          <div className="flex animate-pulse flex-row h-full space-x-5 pb-4">
            <div className="w-full flex flex-col space-y-3">
              <div className="w-96 bg-neutral-400 h-4 rounded-md " />
              <div className="w-96 bg-neutral-300 h-4 rounded-md " />
              <div className="w-96 bg-neutral-300 h-4 rounded-md " />
              <div className="w-96 bg-neutral-300 h-4 rounded-md " />
              <div className="w-96 bg-neutral-200 h-4 rounded-md " />
              <div className="w-96 bg-neutral-200 h-4 rounded-md " />
              <div className="w-96 bg-neutral-200 h-4 rounded-md " />
            </div>
          </div>
        </div>
      </div>
    ); 
  }

  return (
    <DashboardLayout>
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white pt-8 mt-10 shadow shadow-md rounded rounded-md">
          <div className="flex flex-row justify-center items-center pb-6 px-10">
            {
              isAcceptingContactInProgress || isAcceptingMessagingInProgress ? (
                getLoadingContent()
              ) : (
                getAcceptedRequestContent()
              )
            }
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}
