/* eslint no-template-curly-in-string: 0 */
export default {
  'What is Nucleo?': 'What is Nucleo?',
  'Corporation': 'Corporation',
  'Module_Corporate_Team_Title': 'Team',
  'Delete_Corporation_Message': 'All information linked to {{corporationName}} will be erased permanently, without any'
      + ' chance of recovery. Accounts belonging to {{corporationName}} will be converted to Individual accounts.',
  'Delete_Corporation_Member_Message': 'When {{name}} member is removed, they will no longer hold their status as a'
    + ' corporation member, and their account will be switched to an individual one.',

  // used for testing, refactor in future so custom locales can be passed to useBrandingLocale()
  'Unit_Test_Key': 'String {{keyOne}} test {{keyTwo}} for {{keyOne}}',
};
