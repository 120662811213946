import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TrashIcon } from '@heroicons/react/20/solid';
import Input from '../../../../elements/Input';
import { RootState } from '../../../../app/store';
import { useAppDispatch } from '../../../../app/hooks';
import {
  updateFile,
  updateLink,
} from './aboutModuleSlice';
import Button from '../../../../elements/Button';
import FileUpload from '../../../../elements/files/FileUpload';
import { EMPTY_FILE } from './helpers';
import { AboutModuleExternalLink, AboutModuleFile } from './aboutModuleTypes';

interface AboutModuleLinkFileUploadProps {
  setFile: (f: File) => void,
}

// todo: validate link
// todo: validate file
export default function AboutModuleLinkFileUpload(props: AboutModuleLinkFileUploadProps) {

  const { setFile } = props;

  const { externalLinks } = useSelector((state: RootState) => state.profileModules.aboutModule);
  const link: AboutModuleExternalLink = externalLinks[0];

  const { fileAttachments } = useSelector((state: RootState) => state.profileModules.aboutModule);
  const file: AboutModuleFile = fileAttachments[0];

  const dispatch = useAppDispatch();

  const [deleteModeLink, setDeleteModeLink] = useState(false);
  const [deleteModeFile, setDeleteModeFile] = useState(false);

  return (
    <div className="w-full p-2 bg-neutral-25 border rounded-md">
      <p className="p-2 text-xs text-neutral-700">Attachment and link</p>

      {/* Link */}
      <div className="p-2 mt-2 bg-neutral-25 border rounded-md">
        <p className="p-2 text-xs text-neutral-700">Add a link</p>

        <div className="mt-2">
          <Input
            value={link.description}
            id="df-key-1"
            label="Title of the link"
            placeholder="Title"
            onChange={(e) => dispatch(updateLink({ ...link, description: e.target.value }))}
          />
        </div>
        <div className="mt-2">
          <Input
            value={link.link}
            id="df-key-2"
            label="URL"
            placeholder="https://..."
            onChange={(e) => dispatch(updateLink({ ...link, link: e.target.value }))}
          />
        </div>

        <div className="flex flex-row mt-2">
          <div className="max-w-max mr-2">
            <Button
              onClick={() => setDeleteModeLink(true)}
              size="small"
              description=""
              roundedFull
              inverted={! deleteModeLink}
              fullRed
              iconLeft={<TrashIcon className="w-4 h-4" />}
            />
          </div>

          {
            deleteModeLink && (
              <>
                <Button
                  onClick={() => setDeleteModeLink(false)}
                  size="small"
                  description="Cancel"
                  roundedFull
                  fullRed
                />
                <div className="ml-2">
                  <Button
                    onClick={() => {
                      setDeleteModeLink(false);
                      dispatch(updateLink({ ...link, link: '', description: '' }));
                    }}
                    size="small"
                    description="Delete"
                    redText
                    roundedFull
                    inverted
                  />
                </div>
              </>
            )
          }
        </div>
      </div>

      {/* File */}
      <div className="p-2 mt-2 bg-neutral-25 border rounded-md">
        <p className="p-2 text-xs text-neutral-700">Attach a PDF</p>

        <div className="mt-2">
          <Input
            value={file.description}
            disabled={Boolean(file.fileUuid)}
            id="df-key-3"
            label="Title of the PDF"
            placeholder="Title"
            onChange={(e) => dispatch(updateFile({ ...file, title: e.target.value, description: e.target.value }))}
          />
        </div>

        {/* TODO: test edit */}
        <FileUpload
          file={file}
          onFileRemoval={() => dispatch(updateFile(EMPTY_FILE))}
          onFileDrop={(objectURL, localFile) => {
            setFile(localFile);

            dispatch(updateFile({
              ...file,
              fileUrl: objectURL,
              originalFilename: localFile.name,
              fileSizeBytes: localFile.size,
            }));
          }}
          showDeleteButton={false}
        />

        <div className="flex flex-row mt-2">
          <div className="max-w-max mr-2">
            <Button
              onClick={() => setDeleteModeFile(true)}
              size="small"
              description=""
              roundedFull
              inverted={! deleteModeFile}
              fullRed
              iconLeft={<TrashIcon className="w-4 h-4" />}
            />
          </div>

          {
          deleteModeFile && (
            <>
              <Button
                onClick={() => setDeleteModeFile(false)}
                size="small"
                description="Cancel"
                roundedFull
                fullRed
              />
              <div className="ml-2">
                <Button
                  onClick={() => {
                    setDeleteModeFile(false);
                    dispatch(updateFile(EMPTY_FILE));
                  }}
                  size="small"
                  description="Delete"
                  redText
                  roundedFull
                  inverted
                />
              </div>
            </>
          )
        }
        </div>

      </div>
    </div>
  );
}
