import React, { useEffect } from 'react';
import { ChatClient } from '@azure/communication-chat';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { RootState } from '../../app/store';
import { useGetUserThreadsQuery } from './messagesAPI';
import ChatPreviewRow from './ChatPreviewRow';
import { useAppDispatch } from '../../app/hooks';
import { setThreads } from './messagesSlice';
import { MessageSliceThread } from './messages-types';

interface ChatPreviewListProps {
  chatClient: ChatClient,
}

export default function ChatPreviewList(props: ChatPreviewListProps) {

  const { chatClient } = props;

  const dispatch = useAppDispatch();

  const {
    currentProfile: { profileID },
  } = useSelector((state: RootState) => state.account);

  const { threads, threadIDs } = useSelector((state: RootState) => state.chatMessages);

  const { data, isLoading, isFetching } = useGetUserThreadsQuery({ profileID });

  const chatsWereUpdatedTrigger = JSON.stringify(data?.chats);

  useEffect(() => {
    if (! data) return;

    dispatch(setThreads(data));
  }, [data, chatsWereUpdatedTrigger, dispatch]);

  if (isLoading || isFetching) {
    return (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-sm font-semibold">
          Loading...
        </span>
      </div>
    );
  }

  if (! data || (! data.azureCommunicationIdentity) || data.chats.length === 0) {
    return (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-sm font-semibold">
          No chats yet
        </span>
      </div>
    );
  }

  // sort by time last message received. New on top, old to the bottom
  const threadsList: Array<MessageSliceThread & { lastMessageMs: number }> = threadIDs.map((threadID) => {
    let lastMessageMs = 0;
    if (threads[threadID].lastMessageReceivedOnUtc) {
      // @ts-ignore todo: investigate why
      lastMessageMs = new Date(threads[threadID].lastMessageReceivedOnUtc).getTime();
    }
    if (threads[threadID].lastMessage?.createdOn && threads[threadID].lastMessage?.createdOn) {
      // @ts-ignore todo: investigate why
      lastMessageMs = new Date(threads[threadID].lastMessage.createdOn).getTime();
    }

    return {
      ...threads[threadID],
      lastMessageMs,
    };
  });
  const orderedThreadsList: Array<MessageSliceThread & { lastMessageMs: number }> = orderBy(
    threadsList,
    'lastMessageMs',
    'desc',
  );

  return (
    <div className="pb-2 chat-max-height overflow-y-scroll">
      {
        orderedThreadsList.map((thread) => (
          <ChatPreviewRow
            key={thread.threadID}
            profilePageSummary={thread.chatMember.profilePageSummary}
            lastMessageDateUtc={thread.lastMessageReceivedOnUtc}
            currentProfileAzureCommunicationID={data?.azureCommunicationIdentity || ''}
            receiverProfileAzureCommunicationID={thread.chatMember.azureCommunicationIdentity}
            chatClient={chatClient}
            threadID={thread.threadID}
            threadMemberStatus={thread.threadMemberStatus}
          />
        ))
      }
    </div>
  );
}
