import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { removeFlashMessage } from './flashMessagesSlice';
import { RootState } from '../../app/store';
import SystemWarning from './SystemWarning';

export default function FlashMessages() {

  const { flashMessagesQueue } = useSelector((state: RootState) => state.flashMessages);

  const dispatch = useAppDispatch();

  const onClose = (messageID: string) => dispatch(removeFlashMessage(messageID));

  return (
    <>
      {/* Global notification live region top right */}
      <div
        aria-live="assertive"
        className="fixed top-0 mt-4 pointer-events-none flex items-end justify-end flex-col z-50 w-full px-5"
      >
        <div>
          {
            flashMessagesQueue
              .filter((message) => message.topRight)
              .map((message) => (
                <SystemWarning key={message.id} flashMessage={message} onCloseCb={onClose} />
              ))
          }
        </div>
      </div>

      {/* Global notification live region bottom */}
      <div
        aria-live="assertive"
        className="fixed inset-0 pointer-events-none flex items-center justify-end flex-col z-50 w-full max-w-5xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        {
          flashMessagesQueue
            .filter((message) => ! message.topRight)
            .map((message) => (
              <SystemWarning key={message.id} flashMessage={message} onCloseCb={onClose} />
            ))
        }
      </div>
    </>
  );
}
