import React, { ChangeEvent, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';

interface PostTextareaProps {
  text: string,
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
  placeholder: string,
  minHeight?: string,
  validationMessage?: string,
}

export default function PostTextarea(props: PostTextareaProps) {

  const {
    text, onChange, placeholder, minHeight = 'h-14', validationMessage,
  } = props;

  const [inFocus, setInFocus] = useState(false);
  const dispatch = useAppDispatch();

  // todo: debounce ?
  return (
    <div>
      <textarea
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus(false)}
        value={text}
        onChange={onChange}
        placeholder={placeholder}
        className={
          `textarea-height ${inFocus ? 'h-64' : minHeight} shadow-sm block w-full focus:ring-main-500 focus:border-main-500 sm:text-sm border border-neutral-300 rounded-md`
        }
      />
      {
        validationMessage && (
          <div className="bg-error-50 p-1 rounded-sm mt-1">
            <p className="text-xs text-error-600">
              {validationMessage}
            </p>
          </div>
        )
      }
    </div>
  );
}
