import React from 'react';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { ChatClient } from '@azure/communication-chat';
import { RootState } from '../../app/store';
import ChatWindow from './ChatWindow';
import ChatPreviewList from './ChatPreviewList';

import './index.scss';
import { resetCurrentChatWindow, setDisclosureIsOpen } from './messagesSlice';
import { useAppDispatch } from '../../app/hooks';
import ChatInvitations from './ChatInvitations';

interface MessagesProps {
  chatClient: ChatClient,
}

// DOCS API https://docs.microsoft.com/en-us/rest/api/communication/
// events https://docs.microsoft.com/en-us/azure/communication-services/concepts/chat/concepts
export default function Messages(props: MessagesProps) {

  const { chatClient } = props;

  const dispatch = useAppDispatch();

  const {
    currentChatWindow, currentChatWindow: { threadId }, threads, threadIDs, disclosureIsOpen, messageRequestsCount,
  } = useSelector((state: RootState) => state.chatMessages);

  let totalUnreadCount: string | number = threadIDs.reduce((acc, id) => acc + threads[id].unreadCount, 0);
  totalUnreadCount += messageRequestsCount;
  if (totalUnreadCount > 99) totalUnreadCount = '99+';

  return (
    <div className="chat-window fixed bottom-0 right-0 mr-6 shadow-lg bg-white rounded-t-lg z-10">
      <div>
        <>
          <div
            role="none"
            onClick={() => dispatch(setDisclosureIsOpen(! disclosureIsOpen))}
            className={`cursor-pointer id-chat-messages-disclosure w-full ${threadId ? 'bg-neutral-75' : ''}`}
          >
            <div
              className="flex flex-row justify-between items-center"
            >
              {
                threadId ? (
                  <div
                    role="none"
                    className="rounded-full bg-white flex flex-row items-center px-5 py-1 ml-4 hover:bg-main-75 transition-colors"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(resetCurrentChatWindow());
                    }}
                  >
                    <ArrowLeftIcon className="h-3.5 text-main-600" />
                    <span className="font-semibold text-xs text-main-600 ml-1">Back to messages</span>
                  </div>
                ) : (
                  <div className="flex flex-row items-center pt-2 pb-2">
                    <span className="text-base font-semibold p-2 pl-4">Messages</span>
                    {
                      Boolean(totalUnreadCount) && (
                        <span className="rounded-full h-6 w-6 flex items-center justify-center bg-error-600 text-white font-bold text-2xs">
                          {totalUnreadCount}
                        </span>
                      )
                    }
                  </div>
                )
              }
              <div className={`flex justify-center items-center ${threadId ? 'bg-white' : 'bg-neutral-75'} p-3 rounded-full mr-2 my-2`}>
                <ChevronDownIcon
                  className={`${! disclosureIsOpen ? 'transform rotate-180' : ''} w-4 h-4 text-main-500 transition-transform`}
                />
              </div>
            </div>
          </div>

          <Transition
            show={disclosureIsOpen}
            unmount={false}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div>
              <div className={currentChatWindow.threadId ? 'hidden' : ''}>
                <ChatInvitations />
                <ChatPreviewList chatClient={chatClient} />
              </div>

              {
                currentChatWindow.threadId && (
                  <ChatWindow key={currentChatWindow.threadId} chatClient={chatClient} threadID={currentChatWindow.threadId} />
                )
              }
            </div>
          </Transition>
        </>
      </div>

    </div>
  );
}
