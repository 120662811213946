// ex. getKeyValue(object)(key);

export const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) => obj[key];

export const getFirstElement = <T extends object>(obj: T) => {
  const key = Object.keys(obj)[0];
  // @ts-ignore
  return obj[key];
};

export const getLinkRootDomain = (link: string) => {

  const match = link.match(/^(?:https?:)?(?:\/\/)?([^/?]+)/i);

  if (match && match[1]) {
    return match[1];
  }

  return '';
};

export const generateRandomID = () => Math.floor(Math.random() * 1000 * 1000 * 100);
