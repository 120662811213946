import React, { useState, cloneElement, useEffect } from 'react';
import {
  EyeIcon, EyeSlashIcon, Bars3Icon, PencilIcon, TrashIcon, Squares2X2Icon,
} from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import Button from '../../../elements/Button';
import { RootState } from '../../../app/store';
import { MetadataBase } from '../profileTypes';
import { useAppDispatch } from '../../../app/hooks';
import { updateModulesDisplay } from '../profilePageSlice';

interface ModuleLayoutProps {
  dragHandleProps?: DraggableProvidedDragHandleProps
  viewComponent: React.ReactElement,
  editComponent: React.ReactElement | null,
  onReset: () => void,
  header: string,
  moduleMetadata?: MetadataBase,
  allowEditModuleMetadata: boolean,
}

export default function ModuleLayout(props: ModuleLayoutProps) {
  const {
    header, viewComponent, editComponent, onReset, dragHandleProps, moduleMetadata, allowEditModuleMetadata,
  } = props;

  const { allowPageEdit, currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [allowPageEdit]);

  const [renderEditState, setRenderEditState] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => { // back to view on profile switch
    setRenderEditState(false);
  }, [currentlyLoadedProfileID]);

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
      <div className="bg-white shadow rounded-md">
        {/* Header */}
        <div className="pl-4 pb-5 pt-6 pr-6 flex flex-row justify-between border-b">
          <h3 className="font-bold text-lg">{header}</h3>

          {
            allowPageEdit && (
              <div className="flex flex-row">
                {
                  renderEditState && (
                    <div className="mr-2.5">
                      <Button
                        onClick={() => {
                          onReset();
                          setRenderEditState(!renderEditState);
                        }}
                        size="small"
                        inverted
                        redText
                        roundedFull
                        // disabled
                        iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
                        description="Reset"
                      />
                    </div>
                  )
                }
                <div className="mr-2.5">
                  <Button
                    onClick={() => setRenderEditState(!renderEditState)}
                    size="small"
                    inverted
                    roundedFull
                    iconLeft={renderEditState
                      ? <Squares2X2Icon className="w-4 h-4 mr-2" />
                      : <PencilIcon className="w-4 h-4 mr-2" />}
                    description={renderEditState ? 'View' : 'Edit'}
                  />
                </div>
                {
                  allowEditModuleMetadata && (
                    <>
                      <div
                        data-tip={`${moduleMetadata && moduleMetadata.displayModule ? '' : 'DON’T'} DISPLAY THIS MODULE IN PUBLIC PAGE`}
                        data-class="tooltip-global-styles shadow-md"
                        data-arrow-color="transparent"
                        className="mr-2.5"
                      >
                        <Button
                          onClick={() => dispatch(updateModulesDisplay(moduleMetadata))}
                          size="small"
                          inverted
                          roundedFull
                          iconLeft={moduleMetadata && moduleMetadata.displayModule
                            ? <EyeIcon className="w-4 h-4" />
                            : <EyeSlashIcon className="w-4 h-4 text-neutral-500" />}
                          description=""
                        />
                      </div>
                      <div
                        data-tip="MOVE THE MODULE"
                        data-class="tooltip-global-styles shadow-md"
                        data-arrow-color="transparent"
                        className=""
                      >
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <div {...dragHandleProps} className="p-2 rounded-full border hover:bg-neutral-50 transition-colors">
                          <Bars3Icon className="w-4 h-4 text-neutral-500" />
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            )
          }
        </div>
        {
          renderEditState && editComponent
            ? cloneElement(editComponent, {
              setViewMode: () => setRenderEditState(false),
            })
            : cloneElement(viewComponent, {
              setEditMode: () => setRenderEditState(true),
            })
        }
      </div>
      <ReactTooltip />
    </div>
  );
}
