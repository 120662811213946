import { createSlice } from '@reduxjs/toolkit';
import { CoreTag } from '../../../tag/tag-types';

export interface AreaOfInterestsModuleSlice {
  description: string,
  tags: CoreTag[],
}

const initialState: AreaOfInterestsModuleSlice = {
  description: '',
  tags: [],
};

export const areaOfInterestsModuleSlice = createSlice({
  name: 'areaOfInterestsModule',
  initialState,
  reducers: {
    initiateModule: (state, { payload }) => {
      state.description = payload.description ? payload.description : '';
      state.tags = payload.coreTags ? payload.coreTags : [];
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    updateTags: (state, action) => {
      state.tags = action.payload;
    },
  },
});

export const {
  updateDescription,
  updateTags,
  initiateModule,

} = areaOfInterestsModuleSlice.actions;

export default areaOfInterestsModuleSlice.reducer;
