import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { SliceChatMessage } from './messages-types';
import UserAvatar from '../../elements/UserAvatar';
import { SenderProfilePageSummary } from '../contact-list/contact-list.types';
import { RootState } from '../../app/store';

interface MessagePreviewProps {
  message: SliceChatMessage,
  profilePageSummary: SenderProfilePageSummary | null,
}

export default function MessagePreview(props: MessagePreviewProps) {

  const { message, profilePageSummary } = props;

  const {
    currentProfile: { profileImageUrl, accountType, profilePageType },
  } = useSelector((state: RootState) => state.account);

  const {
    currentChatWindow: { currentProfileAzureCommunicationID },
  } = useSelector((state: RootState) => state.chatMessages);

  if (message.type === 'participantAdded') {
    return (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-sm font-semibold">
          Chat started -
          {' '}
          {DateTime.fromISO(message.createdOn).toRelative() || '-'}
        </span>
      </div>
    );
  }

  if (message.type === 'topicUpdated') {
    return (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-2xs font-semibold">
          topic updated
        </span>
      </div>
    );
  }

  if (message.type === 'participantRemoved') {
    return (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-2xs font-semibold">
          participant removed
        </span>
      </div>
    );
  }

  let userAvatar = profilePageSummary && (
    <UserAvatar
      profilePageType={profilePageSummary.profilePageAccountType}
      profileImageUrl={profilePageSummary.profileImageUrl}
    />
  );
  // @ts-ignore
  if (message.sender.communicationUserId === currentProfileAzureCommunicationID) {
    userAvatar = profilePageSummary && (
      <UserAvatar
        profilePageType={profilePageType}
        profileImageUrl={profileImageUrl}
      />
    );
  }

  if (message.type === 'text') {
    return (
      <div className="py-3 px-1 flex flex-row gap-2 w-full">
        {
          userAvatar
        }
        <div className="w-full">
          <div className="flex flex-row justify-between text-sm">
            <span className="font-bold text-2xs text-main-600">
              {message.senderDisplayName}
            </span>
            <span className="text-2xs text-neutral-400 font-semibold">
              {DateTime.fromISO(message.createdOn).toRelative() || '-'}
            </span>
          </div>
          <p className="whitespace-pre-line text-xs mt-1 text-neutral-800">
            {message.content?.message}
          </p>
        </div>
      </div>
    );
  }

  return null;
}
