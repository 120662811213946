import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ChatBubbleOvalLeftEllipsisIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import UserAvatar from '../../elements/UserAvatar';
import Button from '../../elements/Button';
import { useAcceptMessageRequestMutation, useRejectMessageRequestMutation } from './messagesAPI';
import { ChatInvitation } from './messages-types';
import { RootState } from '../../app/store';

export interface ChatInviteRequestProps {
  chatRequest: ChatInvitation,
  size?: 'small' | 'big',
}

export default function ChatInviteRequest(props: ChatInviteRequestProps) {

  const { chatRequest, chatRequest: { senderProfilePageSummary }, size = 'small' } = props;

  const clampedText = useRef(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [textWideOpen, setTextWideOpen] = useState(false);

  const [acceptRequest, { isLoading: isAcceptInProgress }] = useAcceptMessageRequestMutation();
  const [rejectRequest, { isLoading: isRejectInProgress }] = useRejectMessageRequestMutation();

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  useEffect(() => {
    if (! clampedText || ! clampedText.current) {
      return;
    }

    // @ts-ignore
    if (clampedText.current.scrollHeight > clampedText.current.clientHeight) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, []);

  const onDismiss = () => {
    rejectRequest({
      senderProfilePageId: senderProfilePageSummary.profilePageId,
      recipientProfilePageId: profileID,
    });
  };

  const onAccept = () => {
    acceptRequest({
      senderProfilePageId: senderProfilePageSummary.profilePageId,
      recipientProfilePageId: profileID,
    });
  };

  return (
    <div className={`bg-white ${size === 'small' ? 'py-5 pl-6 pr-4' : 'py-6 px-8 mb-2 shadow shadow-lg rounded rounded-lg'} `}>
      <div className="flex flex-row gap-2.5">
        <div className={`relative ${size === 'small' ? 'w-10 h-10' : 'min-w-32'}`}>
          <UserAvatar
            profilePageType={senderProfilePageSummary.profilePageAccountType}
            profileImageUrl={senderProfilePageSummary.profileImageUrl}
            size={size}
            profileID={senderProfilePageSummary.profilePageId}
          />
          {
            size === 'small' && (
              <div className="absolute flex flex-row items-center justify-center bg-error-500 rounded-full w-5 h-5 bottom-0 right-0">
                <div className="bg-white w-2 h-2 rounded-full" />
              </div>
            )
          }
        </div>
        {
          size === 'small' ? (
            <div className="w-5/6">
              <p className="font-semibold text-2xs text-neutral600">
                {DateTime.fromISO(`${chatRequest.createdDateUtc}+00:00`).toRelative() || '-'}
              </p>
              <div className="flex flex-row items-center">
                <ChatBubbleOvalLeftEllipsisIcon className="w-5 h-5 pl-1 text-main-600" />
                <Link to={`/profile/${senderProfilePageSummary.profilePageId}`} className="max-w-fit">
                  <p className="font-semibold text-xs text-main-600">

                    {senderProfilePageSummary.name}
                    {' '}
                    would like to chat
                  </p>
                </Link>
              </div>
              <p className="mt-1 text-xs text-neutral-800 line-clamp-3" title={chatRequest.message}>
                {chatRequest.message}
              </p>
            </div>
          ) : (
            <div className="flex flex-col">
              <p className="text-xs text-neutral-400 pb-1">
                {DateTime.fromISO(`${chatRequest.createdDateUtc}+00:00`).toRelative() || '-'}
              </p>
              <Link to={`/profile/${senderProfilePageSummary.profilePageId}`} className="max-w-fit">
                <p className="text-sm font-bold pb-2">{ senderProfilePageSummary.name }</p>
              </Link>
              { /* show company set in dynamic fields */ }
              {
                senderProfilePageSummary.showCompany && senderProfilePageSummary.company && (
                  <p className="text-sm font-semibold text-neutral-600 table-cell">
                    {senderProfilePageSummary.company}
                  </p>
                )
              }
              { /* show job position set in dynamic fields if company is also set */ }
              {
                senderProfilePageSummary.showCompany && senderProfilePageSummary.company
                  && senderProfilePageSummary.showJobPosition && senderProfilePageSummary.jobPosition && (
                  <p className="text-xs font-semibold text-neutral-600">
                    {senderProfilePageSummary.jobPosition}
                  </p>
                )
              }
              { /* show related corporation name */ }
              {
                !senderProfilePageSummary.showCompany && senderProfilePageSummary.relatedCorporationProfilePageSummary && (
                  <Link to={`/profile/${senderProfilePageSummary.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                    <p className="text-xs text-neutral-500">{senderProfilePageSummary.relatedCorporationProfilePageSummary.name}</p>
                  </Link>
                )
              }
              <div className="pt-4">
                <p className="text-main-500 flex flex-row items-center">
                  <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />
                  <span className="pl-2.5 text-sm font-semibold">
                    Would like to chat
                  </span>
                </p>
              </div>
              {/* Content descriptionMessage */}
              {
                chatRequest.message ? (
                  <p
                    ref={clampedText}
                    className={`${textWideOpen ? '' : 'line-clamp-2'} whitespace-pre-line p-2 pt-5 text-sm whitespace-pre-line`}
                  >
                    &quot;
                    { chatRequest.message }
                    &quot;
                  </p>
                ) : null
              }
            </div>
          )
        }
      </div>
      <div className="flex justify-end">
        {
          (clampedText.current && showMoreButton && ! textWideOpen)
            ? (
              <span
                tabIndex={0}
                role="button"
                className="text-sm font-bold text-main-600 cursor-pointer"
                onKeyDown={() => setTextWideOpen(true)}
                onClick={() => setTextWideOpen(true)}
              >
                show full message
              </span>
            ) : null
        }
      </div>
      <div className="flex flex-row justify-end gap-2 mt-3">
        <Button
          onClick={onDismiss}
          size="small"
          description="Dismiss"
          disabled={isAcceptInProgress}
          isLoading={isRejectInProgress}
          roundedFull
          inverted
        />
        <Button
          onClick={onAccept}
          size="small"
          description="Accept"
          disabled={isRejectInProgress}
          isLoading={isAcceptInProgress}
          roundedFull
        />
      </div>
    </div>
  );
}
