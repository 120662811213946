// ...

import { ProfilePageType, RelatedCorporationSummary } from '../../app/account';
import { ChatRequestStatus } from '../messages/messages-types';

export enum FollowStatus {
  notFollowing = 'notFollowing',
  following = 'following',
}

export enum ContactStatus {
  none = 'none', // no requests exists and they are not contacts
  pending = 'pending', // requester has sent a contact request
  accepted = 'accepted', // profile pages are contacts
  rejected = 'rejected', // only for sending to the backend
  received = 'received', // requester has received a contact request
}

export interface MetadataBase {
  id: number,
  moduleOrder: number,
  displayModule: boolean,
  hasContent: boolean,
  moduleID: string,
}

export interface ProfilePageMetadataType {
  IDs: string[],
  moduleAbout: MetadataBase | null,
  moduleCorporationTeam: MetadataBase | null,
  moduleService: MetadataBase | null,
  moduleProduct: MetadataBase | null,
  moduleInterest: MetadataBase | null,
  moduleBusinessMarkets: MetadataBase | null,
  moduleFocusCategory: MetadataBase | null,
}

export interface ProfilePageResponse {
  name: string,

  accountType: ProfilePageType,
  corporationId?: number,
  coverImageUrl: string,
  followersCount: number,
  individualAccountId: number,
  modulesMetadata: ProfilePageMetadataType,
  profileImageUrl: string,
  profilePageId: number,
  relatedCorporationProfilePageSummary?: RelatedCorporationSummary,
  requesterHasEditRights: boolean,
  parentAssociationName: string,

  requesterContactStatus: ContactStatus,
  requesterFollowStatus: FollowStatus,
  requesterMessagingStatus: ChatRequestStatus,

  badges: ProfilePageBadge[],

  fullShowcaseEnabled: boolean,

  showCompany: boolean,
  company: string,

  showJobPosition: boolean,
  jobPosition: string,

  showRequestPdfProfileButton: boolean,
}

export interface ProfilePageBadge {
  badgeType: BadgeType,
}

export enum BadgeType {
  firstHundredCorporations = 'firstHundredCorporation',
}
