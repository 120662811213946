import React from 'react';

interface InputLoaderProps {
  label?: string,
}

export default function InputLoader(props: InputLoaderProps) {

  const { label } = props;

  return (
    <div className="relative bg-white border border-gray-300 rounded-md px-4 py-3 animate-pulse">
      <span className="block w-full text-xs font-medium text-gray-700">
        { label }
      </span>
      <div className="w-full bg-neutral-100 h-5 rounded-md mt-2" />
    </div>
  );
}
