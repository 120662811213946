import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { createAccount } from '../../app/account';
import { claimCorporation, setWasCorporationClaimed } from './registrationSlice';
import { useAppDispatch } from '../../app/hooks';
import Button from '../../elements/Button';
// import { claimCorporation } from './registrationSlice';

interface FoundCorporationProps {
  setNextStep: () => void,
  onCancel: () => void,
}

// todo: preview image
export default function FoundCorporation(props: FoundCorporationProps) {

  const { setNextStep, onCancel } = props;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    foundCorporation, legalName,
  } = useSelector((state: RootState) => state.registration);

  const onCorporationClaim = async (claim: boolean) => {

    setIsLoading(true);

    const payload: any = {
      accountType: 1, // individual to claim
    };

    if (! claim) {
      payload.accountType = 2;
      payload.corporation = {
        name: legalName,
      };
    } else {
      dispatch(setWasCorporationClaimed(true));
    }

    try {
      const data = await dispatch(createAccount(payload));

      if (claim && data && data.payload && data.payload.payload) {
        await dispatch(claimCorporation({
          corporationID: foundCorporation ? foundCorporation.profilePageId : 0,
          claimerEmail: data.payload.payload.email, // store is not updated in time
        }));
      }

      setIsLoading(false);
      setNextStep();
    } catch (e) {
      setIsLoading(false);
    }
  };

  if (! foundCorporation) {
    return null;
  }

  return (
    <div className="bg-white shadow-sm p-6 rounded-md">
      <div style={{ maxWidth: 496 }}>
        <h3 className="p-4 font-bold text-xl">
          We have found this page in our platform. Is the corporation you represent?
        </h3>

        <div className="p-6 border border-neutral-300 rounded-md flex flex-row">
          {
            foundCorporation.profileImageUrl ? (
              <img
                className="inline-block h-14 avatar-min w-14 min-w-full border border-neutral-200 rounded-md"
                src={foundCorporation.profileImageUrl}
                alt="Avatar"
              />
            ) : (
              <div className="h-14 w-14 border border-neutral-300 bg-neutral-25 rounded-md flex justify-center items-center">
                <span className="font-extrabold text-2xl text-main-600">
                  {foundCorporation.name[0]}
                </span>
              </div>
            )
          }

          <div className="pl-4">
            <p className="font-bold text-xl">
              { foundCorporation.name }
            </p>
            <p className="pt-1 text-base text-neutral-500">
              {foundCorporation.followersCount}
              {' '}
              followers
            </p>
          </div>
        </div>

        <div className="pt-6 flex justify-end w-full grid-cols-2 gap-4">
          <Button
            onClick={() => onCorporationClaim(false)}
            size="big"
            inverted
            disabled={isLoading}
            description="No"
          />
          <Button
            onClick={() => onCorporationClaim(true)}
            size="big"
            isLoading={isLoading}
            description="Yes"
          />
        </div>
      </div>
    </div>
  );
}
