import React from 'react';

interface DuplicateFormProps {
  productID: number,
  duplicateFormFlag: boolean,
  isService?: boolean,
  setDuplicateForm: (flag: boolean) => void,
}

export default function DuplicateForm(props: DuplicateFormProps) {

  const {
    setDuplicateForm, productID, duplicateFormFlag, isService = false,
  } = props;

  return (
    <div className="border w-full rounded-md">
      <div className="py-3 px-4 flex flex-row items-center">
        <input
          id={`duplicate-form-${productID}-${isService ? 'service' : 'product'}`}
          type="checkbox"
          checked={duplicateFormFlag}
          onChange={(e) => {
            setDuplicateForm(e.target.checked);
          }}
          className="h-4 w-4 rounded text-main-600 focus:outline-none border-neutral-300"
        />
        <label
          htmlFor={`duplicate-form-${productID}`}
          className="pl-4 text-neutral-800 text-sm"
        >
          Duplicate form
        </label>
      </div>
    </div>
  );
}
