import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetMembersInvitationsRequest } from '../setings-types';

interface MembersInvitationsSlice {
  getMembersInvitationsListRequest: GetMembersInvitationsRequest,
}

export const initialState: MembersInvitationsSlice = {
  getMembersInvitationsListRequest: {
    pageNumber: 1,
    pageSize: 5,
    corporationID: 0,
  },
};

export const membersInvitationsSlice = createSlice({
  name: 'corporationMembersInvitations',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.getMembersInvitationsListRequest.pageNumber = action.payload;
    },
  },
});

export const {
  setPage,
} = membersInvitationsSlice.actions;

export default membersInvitationsSlice;
