import React from 'react';
import { Link } from 'react-router-dom';
import UserAvatar from '../../../elements/UserAvatar';
import { ProfilePageType } from '../../../app/account';
import { SenderProfilePageSummary } from '../../contact-list/contact-list.types';

interface QuestionPreviewProfileProps {
  creatorProfile: SenderProfilePageSummary | null,
}

export default function QuestionPreviewProfile(props: QuestionPreviewProfileProps) {

  const { creatorProfile } = props;

  const linkContent = (
    <div className="flex flex-col items-center">
      <UserAvatar
        profileImageUrl={creatorProfile?.profileImageUrl}
        profilePageType={creatorProfile?.profilePageAccountType || ProfilePageType.individual}
        size="medium"
      />
      <span className="text-sm font-bold text-main-600 mt-1 text-center">
        {creatorProfile?.name || 'DELETED'}
      </span>
    </div>
  );

  return (
    <div className="flex flex-col items-center">

      {
        creatorProfile ? (
          <Link
            to={`/profile/${creatorProfile?.profilePageId}`}
          >
            { linkContent }
          </Link>
        ) : linkContent
      }

      {
        (creatorProfile?.relatedCorporationProfilePageSummary 
          || (creatorProfile?.showCompany && creatorProfile?.company)) && (
            <div className="border-b border-neutral-200 h-1 w-[70%]" />
        )
      }

      <div className="mt-2 text-center">
        { /* show company set in dynamic fields */ }
        {
          creatorProfile && creatorProfile.showCompany && creatorProfile.company && (
            <p className="text-sm font-semibold text-neutral-600 text-center">
              {creatorProfile.company}
            </p>
          )
        }
        { /* show job position set in dynamic fields if company is also set */ }
        {
          creatorProfile && creatorProfile.showCompany && creatorProfile.company 
            && creatorProfile.showJobPosition && creatorProfile.jobPosition && (
            <p className="text-xs font-semibold text-neutral-600 text-center">                    
              {creatorProfile.jobPosition}
            </p>
          )
        }        
        { /* show related corporation name */ }
        {
          creatorProfile && !creatorProfile.showCompany && creatorProfile.relatedCorporationProfilePageSummary && (
            <Link to={`/profile/${creatorProfile.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
              <p className="text-xs text-neutral-500 text-center">
                {creatorProfile.relatedCorporationProfilePageSummary.name}
              </p>
            </Link>
          )
        }
      </div>
    </div>
  );
}
