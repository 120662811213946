import React from 'react';
import ModuleLayout from './components/ModuleLayout';
import ModuleLoader from './components/ModuleLoader';

export default function ProfilePageLoading() {
  return (
    <>
      <ModuleLayout
        viewComponent={<ModuleLoader />}
        editComponent={null}
        onReset={() => {}}
        header="Module"
        allowEditModuleMetadata={false}
      />
      <ModuleLayout
        viewComponent={<ModuleLoader />}
        editComponent={null}
        onReset={() => {}}
        header="Module"
        allowEditModuleMetadata={false}
      />
      <ModuleLayout
        viewComponent={<ModuleLoader />}
        editComponent={null}
        onReset={() => {}}
        header="Module"
        allowEditModuleMetadata={false}
      />
      <ModuleLayout
        viewComponent={<ModuleLoader />}
        editComponent={null}
        onReset={() => {}}
        header="Module"
        allowEditModuleMetadata={false}
      />
      <ModuleLayout
        viewComponent={<ModuleLoader />}
        editComponent={null}
        onReset={() => {}}
        header="Module"
        allowEditModuleMetadata={false}
      />
    </>
  );
}
