import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { RootState } from '../../app/store';
import { ISearchFilter } from './search-types';
import SearchFilter from './application-search/elements/SearchFilter';
import Button from '../../elements/Button';
import { useAppDispatch } from '../../app/hooks';
import { applyFilters, clearAllFilters } from './searchSlice';

interface SearchFiltersProps {
  filters: ISearchFilter[],
  showFilters: boolean,
}

export default function SearchFilters(props: SearchFiltersProps) {

  const { filters, showFilters } = props;

  const dispatch = useAppDispatch();

  const {
    currentSearchString, appliedFilters, filtersHaveChanged,
  } = useSelector((state: RootState) => state.search);

  useEffect(() => {
    dispatch(applyFilters());
  }, [dispatch, filtersHaveChanged]);

  if (! currentSearchString || (! showFilters) || (filters.length === 0)) return null;

  return (
    <div className="bg-gray-50 px-4 py-6 mb-6">
      <h6 className="mb-3 font-semibold">Filters</h6>
      <div className="grid grid-cols-12 gap-4">
        {
          filters.map((filter) => (
            <div
              key={filter.filterType}
              className="col-span-12 md:col-span-6 lg:col-span-4"
            >
              <SearchFilter filter={filter} />
            </div>
          ))
        }
      </div>

      <div className="flex flex-end justify-end mt-6">
        <Button
          onClick={() => dispatch(clearAllFilters())}
          size="medium"
          disabled={appliedFilters.length === 0}
          inverted
          iconLeft={<XCircleIcon className="w-4 h-4 mr-2" />}
          description="Clear all"
        />
      </div>
    </div>
  );
}
