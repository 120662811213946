import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Routes from './Routes';

import './App.css';
import './tailwind-extend.scss';

// todo: tailwind remove transform, filter, and backdrop-filter (upgrade to 3.0.0)
function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
