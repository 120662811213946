import React from 'react';

import './index.scss';

interface YoutubeVideoProps {
  url: string,
}

export default function YoutubeVideo(props: YoutubeVideoProps) {

  const { url } = props;

  if (! url) {
    return null;
  }

  return (
    <div className="mt-4 video-container">
      <iframe
        className="w-full h-auto min-h-full"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
