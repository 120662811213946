/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Presentation } from '../../../products-module.types';
import Textarea from '../../../../../../../elements/Textarea';
import Input from '../../../../../../../elements/Input';
import { useAppDispatch } from '../../../../../../../app/hooks';
import Button from '../../../../../../../elements/Button';
import {
  addDosageForm,
  deletePresentation,
  editPresentation,
} from '../../../productsModuleSlice';
import DosagesGroupField from './DosagesGroupField';

interface PresentationFieldsGroupProps {
  presentation: Presentation,
  idx: number,
  productID: number,
}

export default function PresentationFieldsGroup(props: PresentationFieldsGroupProps) {

  const { presentation, idx, productID } = props;

  const [deleteMode, setDeleteMode] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className="p-2 border rounded-md bg-neutral-50 mt-2">
      <p className="py-1 pl-1 text-xs text-neutral-700 font-semibold">
        Presentation #
        {idx + 1}
      </p>

      <div className="mt-2">
        <Textarea
          value={presentation.presentation}
          placeholder="Presentation (text)"
          height="small"
          onChange={(e) => dispatch(editPresentation({
            productID,
            idx,
            value: {
              ...presentation,
              presentation: e.target.value,
            },
          }))}
        />
      </div>

      {
        (presentation.dossages && presentation.dossages.length > 0) && presentation.dossages.map((dosage, index) => (
          <DosagesGroupField key={index} dosage={dosage} presentationIdx={idx} dosageIdx={index} productID={productID} />
        ))
      }

      {/* Delete button */}
      <div className="pt-2 flex flex-row justify-between">
        <div className="flex flex-row">
          <div className="max-w-max mr-2">
            <Button
              onClick={() => setDeleteMode(true)}
              size="small"
              description=""
              roundedFull
              inverted={! deleteMode}
              fullRed
              iconLeft={<TrashIcon className="w-4 h-4" />}
            />
          </div>

          {
            deleteMode && (
              <>
                <Button
                  onClick={() => setDeleteMode(false)}
                  size="small"
                  description="Cancel"
                  roundedFull
                  fullRed
                />
                <div className="ml-2">
                  <Button
                    onClick={() => {
                      setDeleteMode(false);
                      dispatch(deletePresentation({ productID, idx }));
                    }}
                    size="small"
                    description="Delete presentation"
                    redText
                    roundedFull
                    inverted
                  />
                </div>
              </>
            )
          }
        </div>

        <Button
          onClick={() => dispatch(addDosageForm({ productID, presentationIdx: idx }))}
          size="small"
          description="Add Form"
          iconLeft={<PlusIcon className="w-4 h-4" />}
          roundedFull
          inverted
        />
      </div>

    </div>
  );
}
