import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclosure, Transition } from '@headlessui/react';
import { Product } from '../products-module.types';
import { useLazyGetProductQuery } from '../productsModuleAPI';
import { RootState } from '../../../../../app/store';
import ModuleLoader from '../../../components/ModuleLoader';
import ProductDetailsView from './ProductDetailsView';
import ProductViewHeader from './ProductViewHeader';
import ProductCreateEditForm from './ProductCreateEditForm';
import { useAppDispatch } from '../../../../../app/hooks';
import { setCreateProductForm } from '../productsModuleSlice';

interface ProductViewProps {
  product: Product,
  inEditMode: boolean,
}
export default function ProductView(props: ProductViewProps) {
  const { product: productSimplified, inEditMode } = props;

  const { currentlyLoadedProfileID, allowPageEdit } = useSelector((state: RootState) => state.profilePage);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [productViewInEditMode, setProductViewInEditMode] = useState(false);

  const dispatch = useAppDispatch();

  const [fetchProduct, product] = useLazyGetProductQuery({
    selectFromResult: (result) => result.data,
  });

  const loadProduct = async () => {
    setIsLoadingDetails(true);

    const response = await fetchProduct({ profileID: currentlyLoadedProfileID, productID: productSimplified.id }, true);

    setIsLoadingDetails(false);

    return response;
  };

  // loading product details
  useEffect(() => {
    if (! isProductOpen) return;

    loadProduct();

    // eslint-disable-next-line
  }, [currentlyLoadedProfileID, productSimplified.id, fetchProduct, isProductOpen]);

  let content: ReactElement | null = null;

  if (isLoadingDetails) {
    content = (
      <ModuleLoader />
    );
  }

  // todo: productViewInEditMode
  if (product && ! productViewInEditMode) {
    content = (
      <ProductDetailsView product={product} />
    );
  }
  if (product && productViewInEditMode) {
    content = (
      <ProductCreateEditForm
        formType="edit"
        productID={product.id}
        setProductViewInViewMode={() => setProductViewInEditMode(false)}
      />
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full border-b bg-neutral-50">
            <div
              role="none"
              className="w-full flex flex-row justify-between items-center"
              onClick={() => setIsProductOpen(! open)}
            >
              <ProductViewHeader
                productViewInEditMode={productViewInEditMode}
                setProductViewInEditMode={async (flag) => {
                  setProductViewInEditMode(flag);

                  let p: any = product;
                  if (flag) {
                    if (! product) { // load product id not loaded
                      const resp = await loadProduct();
                      if (resp && resp.data) {
                        p = resp.data;
                      }
                    }

                    dispatch(setCreateProductForm(p));
                  }
                }}
                productName={productSimplified.productName}
                productID={productSimplified.id}
                open={open}
                inEditMode={inEditMode}
              />
            </div>
          </Disclosure.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              {content}
              <div className="w-full border-t" />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
