import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import TagManager from 'react-gtm-module';
import { getCookieConsentValue } from 'react-cookie-consent';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { tagManagerArgs } from './config/base';

import './i18n';

// MSAL imports
import { msalConfig } from './authConfig';

import './index.css';
import App from './App';

const cookieConsent = getCookieConsentValue('ConsentCookie') === 'true';

if (tagManagerArgs.gtmId && cookieConsent) {
  TagManager.initialize(tagManagerArgs);
} else {
  // eslint-disable-next-line no-console
  console.warn('GTag cannot be initialized: no GTAG or no consent provided');
}

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Docs: https://create-react-app.dev/docs/making-a-progressive-web-app/
// serviceWorker.unregister();
serviceWorker.unregister();
