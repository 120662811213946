import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AnswersState {
  deleteAnswerModalData: {
    answerID: number,
    answerText: string,
    showModal: boolean,
  },
}

export const initialState: AnswersState = {
  deleteAnswerModalData: {
    answerID: 0,
    answerText: '',
    showModal: false,
  },
};

export const answersSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {

    setDeleteAnswerModalData: (state, action: PayloadAction<AnswersState['deleteAnswerModalData']>) => {
      state.deleteAnswerModalData = action.payload;
    },
    resetDeleteAnswerModalData: (state) => {
      state.deleteAnswerModalData = initialState.deleteAnswerModalData;
    },
  },
});

export const {
  setDeleteAnswerModalData,
  resetDeleteAnswerModalData,
} = answersSlice.actions;

export default answersSlice;
