import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { ContactRequest } from '../contact-list.types';
import UserAvatar from '../../../elements/UserAvatar';
import Button from '../../../elements/Button';
import { useAcceptContactRequestMutation, useRejectContactRequestMutation } from '../contactListAPI';
import { RootState } from '../../../app/store';

interface ConnectRequestProps {
  request: ContactRequest
}

export default function ConnectRequest(props: ConnectRequestProps) {

  const { request, request: { senderProfilePageSummary } } = props;

  const clampedText = useRef(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [textWideOpen, setTextWideOpen] = useState(false);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const [acceptRequestQuery, { isLoading: isAcceptingInProgress }] = useAcceptContactRequestMutation();
  const [rejectRequestQuery, { isLoading: isRejectingInProgress }] = useRejectContactRequestMutation();

  useEffect(() => {
    if (! clampedText || ! clampedText.current) {
      return;
    }

    // @ts-ignore
    if (clampedText.current.scrollHeight > clampedText.current.clientHeight) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, []);

  const onActionSelect = async (type: 'accept' | 'reject') => {

    const payload = {
      receiverProfilePageId: profileID,
      senderProfilePageId: senderProfilePageSummary.profilePageId,
    };

    if (type === 'accept') {
      await acceptRequestQuery(payload);
    } else if (type === 'reject') {
      await rejectRequestQuery(payload);
    }
  };

  return (
    <div className="bg-white py-6 px-8 mb-2 shadow shadow-lg rounded rounded-lg">
      <div className="flex flex-row">

        {/* Avatar */}
        <div className="min-w-32">
          <UserAvatar
            profilePageType={senderProfilePageSummary.profilePageAccountType}
            profileImageUrl={senderProfilePageSummary.profileImageUrl}
            size="big"
            profileID={senderProfilePageSummary.profilePageId}
          />
        </div>
        {/* <img */}
        {/*  className={`inline-block h-32 w-32 border border-neutral-200 ${isCorporateUser ? 'rounded-lg' : 'rounded-full'}`} */}
        {/*  src={imageURL} */}
        {/*  alt="Avatar" */}
        {/* /> */}

        {/* Header */}
        <div className="flex flex-col pl-4 w-full">
          <p className="text-xs text-neutral-400 pb-1">
            {DateTime.fromISO(`${request.createdDateUtc}+00:00`).toRelative() || '-'}
          </p>
          <div className="mb-0.5">
            <Link to={`/profile/${senderProfilePageSummary.profilePageId}`} className="max-w-fit">
              <p className="text-ms font-bold">{ senderProfilePageSummary.name }</p>
            </Link>
          </div>
          { /* show company set in dynamic fields */ }
          {
            senderProfilePageSummary.showCompany && senderProfilePageSummary.company && (
              <p className="text-sm font-semibold text-neutral-600 table-cell">
                {senderProfilePageSummary.company}
              </p>
            )
          }
          { /* show job position set in dynamic fields if company is also set */ }
          {
            senderProfilePageSummary.showCompany && senderProfilePageSummary.company 
              && senderProfilePageSummary.showJobPosition && senderProfilePageSummary.jobPosition && (
              <p className="text-xs font-semibold text-neutral-600">                    
                {senderProfilePageSummary.jobPosition}
              </p>
            )
          }          
          { /* show related corporation name */ }
          {
            !senderProfilePageSummary.showCompany && senderProfilePageSummary.relatedCorporationProfilePageSummary && (
              <Link to={`/profile/${senderProfilePageSummary.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                <p className="text-xs text-neutral-500">{senderProfilePageSummary.relatedCorporationProfilePageSummary.name}</p>
              </Link>
            )
          }
          <div className="pt-4">
            <p className="text-main-500 flex flex-row items-center">
              <PlusIcon className="w-4 h-4" />
              <span className="pl-2.5 text-sm font-semibold">
                Would like to be your contact
              </span>
            </p>
          </div>

          {/* Content descriptionMessage */}
          {
             request.message ? (
               <p
                 ref={clampedText}
                 className={`${textWideOpen ? '' : 'line-clamp-2'} whitespace-pre-line p-2 pt-5 text-sm whitespace-pre-line`}
               >
                 &quot;
                 { request.message }
                 &quot;
               </p>
             ) : null
           }

          <div className="flex justify-end">
            {
              (showMoreButton && ! textWideOpen)
                ? (
                  <span
                    tabIndex={0}
                    role="button"
                    className="text-sm font-bold text-main-600 cursor-pointer"
                    onKeyDown={() => setTextWideOpen(true)}
                    onClick={() => setTextWideOpen(true)}
                  >
                    show full message
                  </span>
                ) : null
            }
          </div>

          {/* Buttons */}
          <div className="flex flex-row gap-2 justify-end pt-4">
            <Button
              onClick={() => onActionSelect('reject')}
              size="small"
              inverted
              roundedFull
              description="Dismiss"
              disabled={isAcceptingInProgress}
              isLoading={isRejectingInProgress}
            />
            <Button
              onClick={() => onActionSelect('accept')}
              size="small"
              roundedFull
              description="Accept"
              disabled={isRejectingInProgress}
              isLoading={isAcceptingInProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
