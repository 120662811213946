import React from 'react';
import { find } from 'lodash';
import { AnswersSortFilters } from '../../forum-types';
import AppSelect from '../../../../elements/AppSelect';

interface AnswersSortingProps {
  setSorting: (newValue: AnswersSortFilters) => void,
  sorting: AnswersSortFilters,
}

const ANSWERS_SORTING_OPTIONS = [
  {
    value: AnswersSortFilters.mostVoted,
    label: 'Most voted',
  },
  {
    value: AnswersSortFilters.lessVoted,
    label: 'Lowest rating',
  },
  {
    value: AnswersSortFilters.newestFirst,
    label: 'Most recent',
  },
  {
    value: AnswersSortFilters.oldestFirst,
    label: 'Oldest first',
  },
];

export default function AnswersSorting(props: AnswersSortingProps) {

  const { setSorting, sorting } = props;

  const value = find(ANSWERS_SORTING_OPTIONS, { value: sorting });

  return (
    <div style={{ width: 200 }}>
      <AppSelect
        placeholder="Sorting options"
        value={value}
        onChange={(newValue) => {
          if (! newValue) return;
          setSorting(newValue.value);
        }}
        options={ANSWERS_SORTING_OPTIONS}
      />
    </div>
  );
}
