import React from 'react';
import Switch from '../../../elements/Switch';
import { 
  useGetAccountEmailNotificationsSettingsQuery, 
  useUpdateAccountEmailNotificationsMutation,
} from '../settingsAPI';

export default function EmailNotifications() {
  const { data, isLoading } = useGetAccountEmailNotificationsSettingsQuery();
  const [updateEmailNotificationsQuery] = useUpdateAccountEmailNotificationsMutation();

  const onUpdateContactRequest = async () => {
    await updateEmailNotificationsQuery({ 
      contactRequest: !data?.contactRequest, 
      messagingRequest: !data?.contactRequest,
    });
  };

  const onUpdateUnreadMessages = async () => {
    await updateEmailNotificationsQuery({ 
      unreadMessages: !data?.unreadMessages,
    });
  };

  const onUpdateForumNews = async () => {
    await updateEmailNotificationsQuery({ 
      forumNews: !data?.forumNews,
    });
  };

  const onUpdateForumPostReply = async () => {
    await updateEmailNotificationsQuery({ 
      forumPostReply: !data?.forumPostReply,
    });
  };

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        Email Notifications
      </h2>
      <div>
        <div className="flex flex-row items-center pt-4">
          {
            isLoading && (
              <div className="flex animate-pulse flex-row h-full space-x-5">
                <div className="w-full flex flex-col space-y-3">
                  <div className="w-72 bg-neutral-400 h-6 rounded-md " />
                  <div className="w-72 bg-neutral-300 h-6 rounded-md " />
                </div>
              </div>
            )
          }
          {
            data && (
              <>
                <div className="w-full border border-neutral-200 rounded-md p-4">
                  <div className="mt-2">
                    <Switch
                      checked={data.contactRequest && data.messagingRequest}
                      onChange={onUpdateContactRequest}
                      label="Contact and Messaging requests"
                    />
                  </div>
                  <div className="mt-6">
                    <Switch
                      checked={data.unreadMessages}
                      onChange={onUpdateUnreadMessages}
                      label="Unread Messages"
                    />
                  </div>
                  <div className="mt-6">
                    <Switch
                      checked={data.forumNews}
                      onChange={onUpdateForumNews}
                      label="Trending Forum posts updates"
                    />
                  </div>
                  <div className="mt-6">
                    <Switch
                      checked={data.forumPostReply}
                      onChange={onUpdateForumPostReply}
                      label="Replies to my Forum posts"
                    />
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
