/* eslint-disable react/require-default-props */
import React, { 
  useCallback, 
  Fragment, 
  useRef, 
  useState,
  useEffect,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Dialog, Transition } from '@headlessui/react';
import { 
  DocumentPlusIcon, 
  XMarkIcon,
  UserIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '../../../helpers/styling';
import Button from '../../../elements/Button';
import { uploadProfileImage, ProfilePageImageType, deleteProfileImage } from '../profilePageSlice';
import { useAppDispatch } from '../../../app/hooks';

interface ImageUploadModalProps {
  show: boolean,
  setOpen: (flag: boolean) => void,
  isLoading?: boolean,
  profilePageId: number,
  requesterProfilePageId: number,
  onClose: (imageData: string, imageType: ProfilePageImageType) => void,
  imageType: ProfilePageImageType,
  currentUsedImage: string,
  profilePageType: string | undefined,
}

const fileToDataUri = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    if (! event.target) {
      reject(Error);
    }
    resolve(event.target);
  };
  reader.readAsDataURL(file);
});

export default function ImageUploadModal(props: ImageUploadModalProps) {
  const {
    show, 
    setOpen,
    profilePageId, 
    requesterProfilePageId,
    onClose,
    imageType,
    currentUsedImage,
    profilePageType,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const cancelButtonRef = useRef(null);

  const dispatch = useAppDispatch();

  const selectedFileRef = useRef(null);

  const [usedImagePreview, setUsedImagePreview] = useState('');

  const imageChanged = useRef(false);

  const stylesHeaderImage = { width: '45rem', height: '13rem' };

  const onProfileImageUpload = async (files: any, type: ProfilePageImageType.profile | ProfilePageImageType.cover) => {

    if (!files) return;

    await dispatch(uploadProfileImage({
      file: files,
      profilePageID: profilePageId,
      requesterID: requesterProfilePageId,
      imageType: type,
    }));

    const target: any = await fileToDataUri(files);
    onClose(target.result, imageType);
  };

  const onProfileImageRemoved = async (type: ProfilePageImageType.profile | ProfilePageImageType.cover) => {
    
    await dispatch(deleteProfileImage({
      profilePageID: profilePageId,
      requesterID: requesterProfilePageId,
      imageType: type,
    }));

    onClose('', imageType);    
  };

  const onSaveChanges = async () => {
    
    if (isLoading) return;

    setIsLoading(true);

    if (imageChanged.current && selectedFileRef.current) {
      await onProfileImageUpload(selectedFileRef.current, imageType);
    } else if (imageChanged.current) {
      await onProfileImageRemoved(imageType);
    }
    
    setIsLoading(false);
    setOpen(false);
  };

  const onCancelSelectedImage = async () => {
    setUsedImagePreview('');
    selectedFileRef.current = null;
    imageChanged.current = true;
  };

  const onDrop = useCallback((acceptedFiles) => {

    const file = acceptedFiles[0];
    selectedFileRef.current = file;
    imageChanged.current = true;
    try {
      const objectURL = URL.createObjectURL(file);
      setUsedImagePreview(objectURL);
    } catch (e) {
      // console.log('Error', e);
    }

  }, [selectedFileRef]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ accept: 'image/*', onDrop });  

  useEffect(() => {
    if (show) {
      imageChanged.current = false;
      if (currentUsedImage) {
        setUsedImagePreview(currentUsedImage); 
      } else {
        setUsedImagePreview('');      
      }      
    }

  }, [show, currentUsedImage]);

  const renderImage = () => {

    const imageStyle = imageType === ProfilePageImageType.cover ? stylesHeaderImage : {};

    /* IMAGE CSS Classes and styles */    
    let imageClassName = '';
    if (imageType === ProfilePageImageType.profile) {
      if (profilePageType === 'corporation') {
        imageClassName = 'flex h-24 w-24 rounded-lg object-cover ring-4 ring-white sm:h-36 sm:w-36 bg-main-75';
      } else {
        imageClassName = 'h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-36 sm:w-36';
      }
    } else {
      imageClassName = 'h-96 w-full object-cover rounded-md bg-neutral-200';
    }

    /* NO IMAGE ELEMENT CSS Classes and styles */
    let noImageClassName = '';
    if (imageType === ProfilePageImageType.profile) {
      noImageClassName = profilePageType === 'corporation' 
        ? 'flex h-24 w-24 rounded-lg object-cover ring-4 ring-white sm:h-36 sm:w-36 bg-main-75' 
        : 'flex h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-36 sm:w-36 bg-main-75 p-4';
    } else {
      noImageClassName = 'object-contain rounded-md bg-neutral-200';
    }
    let emptyProfileImageElement = null;
    if (imageType === ProfilePageImageType.profile) {
      if (profilePageType === 'corporation') {
        emptyProfileImageElement = <BuildingOfficeIcon className="text-main-400" />;
      } else {
        emptyProfileImageElement = <UserIcon className="text-main-400" />;
      }
      
    }

    return (
      <div className="mt-6 flex items-center justify-center">
        <div className="relative">
          {
            usedImagePreview ? (                      
              <img 
                alt="Preview" 
                src={usedImagePreview} 
                className={imageClassName}
                style={imageStyle}
              />
            ) : (
              <div className={noImageClassName} style={imageStyle}>
                {emptyProfileImageElement}
              </div>
            )
          }
          {
            usedImagePreview && (
              <button
                type="button"
                onClick={() => onCancelSelectedImage()}                    
                className="shrink-0 absolute top-6 right-2 cursor-pointer bg-transparent mr-4 text-white rounded-full ring-4 ring-offset-0 ring-neutral-900 focus:outline-none"
                style={imageType === ProfilePageImageType.profile
                  ? { right: '-0.5rem', top: '-0.5rem' } : {}}
              >
                <span className="sr-only">Remove file</span>
                <XMarkIcon className="h-4 w-4 text-white bg-neutral-900" />
              </button>
            )
          }
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={classNames(
              'fixed inset-0',
            )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block relative z-20 align-bottom bg-white rounded-lg p-12 text-left overflow-hidden shadow-lg transition-all sm:align-middle">
              <div className="sm:items-start">
                
                {/* TITLE */}
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" style={{ maxWidth: 450 }} className="text-xl leading-6 py-3 font-bold text-neutral-800">
                    {`Manage ${imageType === ProfilePageImageType.profile ? 'Profile' : 'Header'} Image`}
                  </Dialog.Title>
                </div>

                {/* IMAGE */}
                {
                  renderImage()
                }

                {/* UPLOAD IMAGE BOX */}
                <div className="bg-neutral-75 mt-8 rounded-lg flex flex-row">
                  <div className="w-full">
                    <div className="p-3 w-full flex flex-col">
                      <div className={
                        classNames('container border bg-white p-5 border-dashed')
                      }
                      >
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input
                            {...getInputProps()}
                          />
                          <div className="flex items-center ">
                            <DocumentPlusIcon className="text-neutral-400 w-5 h-5" />
                            <p className="text-xs pl-2">

                              Drag and drop the file or
                              <span className="text-main-500"> click here </span>
                              to upload
                              <span className="text-neutral-400"> (up to 5MB)</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* BUTTONS */}
              <div className="mt-6 flex justify-end">
                <div className="pr-2">
                  <Button
                    disabled={isLoading}
                    onClick={() => setOpen(false)}
                    size="medium"
                    inverted
                    description="Cancel"
                  />
                </div>
                <Button
                  size="medium"
                  description="Save changes"
                  onClick={() => {     
                    onSaveChanges();
                  }}
                  disabled={!imageChanged.current}
                  isLoading={isLoading}
                /> 
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
