import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../authConfig';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { createAccount } from '../../app/account';

interface FoundCorporation {
  followersCount: number,
  name: string,
  profileImageUrl: string,
  accountType: 'Corporation' | 'Individual'
  profilePageId: number,
}

interface RegistrationSliceState {
  foundCorporation: null | FoundCorporation,
  legalName: '',
  isRegistrationSuccessful: boolean,
  wasCorporationClaimed: boolean, // flag to display proper message
  profileIdToRedirect: number | null,
}

const initialState: RegistrationSliceState = {
  foundCorporation: null,
  legalName: '',
  isRegistrationSuccessful: true,
  wasCorporationClaimed: false,
  profileIdToRedirect: null,
};

export const claimCorporation = createAsyncThunk(
  'registration/claimCorporation',
  async (args: { corporationID: number, claimerEmail: string }) => {
    const { data } = await axios.post(`${API_URL}/Corporation/${args.corporationID}/claim`, {
      claimerEmail: args.claimerEmail,
    });

    return data;
  },
);

export const findCorporation = createAsyncThunk(
  'registration/findCorporation',
  async (name: string, { dispatch }) => {
    try {
      const { data } = await axios.get(`${API_URL}/Search/corporations`, {
        params: {
          PageNumber: 1,
          PageSize: 1,
          onlyCreatedByAppAdmin: true,
          name,
        },
      });

      return data;
    } catch (e) {
      dispatch<any>(addFlashMessage('error', 'Corporation cannot be validated'));
      throw e;
    }
  },
);

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setLegalName: (state, action) => {
      state.legalName = action.payload;
    },
    setWasCorporationClaimed: (state, action) => {
      state.wasCorporationClaimed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // todo: handle error
      .addCase(findCorporation.fulfilled, (state, action) => {

        if (! action.payload.data || action.payload.data.length === 0) { return; } // todo: check if may cause an error (return void)

        const {
          followersCount, name, profileImageUrl, profilePageAccountType, profilePageId,
        } = action.payload.data[0];

        state.foundCorporation = {
          followersCount,
          name,
          profileImageUrl,
          accountType: profilePageAccountType,
          profilePageId,
        };
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.isRegistrationSuccessful = true;
        state.profileIdToRedirect = action.payload.payload.individualProfilePageId;
      })
      .addCase(createAccount.rejected, (state) => {
        state.isRegistrationSuccessful = false;
      })
      .addCase(claimCorporation.fulfilled, (state) => {
        state.isRegistrationSuccessful = true;
      })
      .addCase(claimCorporation.rejected, (state) => {
        state.isRegistrationSuccessful = false;
      });
  },
});

export const { setLegalName, setWasCorporationClaimed } = registrationSlice.actions;
export default registrationSlice.reducer;
