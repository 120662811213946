import React, {
  Dispatch, Fragment, SetStateAction,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import Post, { PostProps } from '../Post';

import './common.scss';
import { RootState } from '../../../app/store';

interface PreviewProps {
  openPreview: boolean,
  setOpenPreview: Dispatch<SetStateAction<boolean>>,
  post: PostProps
}

export default function Preview(props: PreviewProps) {

  const {
    openPreview, setOpenPreview, post: {
      timestamp, images, text, PDFs, links, youtubeURL,
    },

  } = props;

  const { currentProfile } = useSelector((state: RootState) => state.account);
  const { corporationDetails } = useSelector((state: RootState) => state.account);
  const { individualProfileShowCompany } = useSelector((state: RootState) => state.account);
  const { individualProfileCompany } = useSelector((state: RootState) => state.account);

  const { individualProfileShowJobPosition } = useSelector((state: RootState) => state.account);
  const { individualProfileJobPosition } = useSelector((state: RootState) => state.account);

  return (
    <Transition.Root show={openPreview} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpenPreview}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="preview-content inline-block align-bottom bg-neutral-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <Post
                user={{
                  isCorporateUser: currentProfile.accountType === 'corporation',
                  name: currentProfile.name,
                  avatarURL: currentProfile.profileImageUrl,
                  profilePageId: currentProfile.profileID,
                  relatedCorporationName: corporationDetails?.name,
                  relatedCorporationProfilePageId: corporationDetails?.corporationProfilePageId,
                  showCompany: currentProfile.profilePageType === 'corporation' ? false : individualProfileShowCompany,
                  company: currentProfile.profilePageType === 'corporation' ? '' : individualProfileCompany,
                  showJobPosition: currentProfile.profilePageType === 'corporation' ? false : individualProfileShowJobPosition,
                  jobPosition: currentProfile.profilePageType === 'corporation' ? '' : individualProfileJobPosition,
                }}
                timestamp={DateTime.fromMillis(Date.now()).toISO()}
                text={text}
                links={links}
                PDFs={PDFs}
                images={images}
                youtubeURL={youtubeURL}
              />
            </div>

          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
