import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import PostTextarea from './PostTextarea';

import {
  resetPost,
  createLink, deleteLink, editLink,
  createPDF,
  createImage, editImage, deleteImage, updateText,
} from './createPostSlice';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import PostLink, { Link } from '../post-link/PostLink';
import { classNames } from '../../../helpers/styling';
import PostPDF from './post-pdf/PostPDF';
import PostImage, { IPostImage } from './post-image/PostImage';
import Preview from './Preview';
import UserAvatar from '../../../elements/UserAvatar';
import { UploadBlob, uploadFileToAzure } from '../../../app/filesSlice';
import { useCreatePostMutation } from './createPostAPI';
import { setFeedListPage } from '../../feed/feedSlice';
import PostOnBehalfMessage from './PostOnBehalfMessage';
import { ValidationKeys, rulesFeedPost, validationErrorState } from './postValidationRules';
import { useFormValidation } from '../../../helpers/validation';

export default function CreatePost() {

  const [isCancelState, setIsCancelState] = useState(false);
  const [openPreview, setOpenPreview]     = useState(false);
  const [uploadedFile, setUploadedFile]   = useState<File | undefined>(undefined);
  const [uploadedImage, setUploadedImage] = useState<File | undefined>(undefined);

  const [shareInProgress, setShareInProgress] = useState(false);

  const [createPostRequest] = useCreatePostMutation();

  const {
    currentProfile,
    corporationDetails,
    currentProfile: { profileID: requesterID },
    individualProfileShowCompany,
    individualProfileCompany,
    individualProfileShowJobPosition,
    individualProfileJobPosition,
  } = useSelector((state: RootState) => state.account);

  const {
    links, PDFs, images, text,
  } = useSelector((state: RootState) => state.feed.createPost);
  const { createPost } = useSelector((state: RootState) => state.feed);

  const dispatch = useAppDispatch();

  const showDismissButton = Boolean(text) || links.length > 0 || PDFs.length > 0 || images.length > 0;

  // const {
  //   data, error, isLoading, isFetching,
  // } = useGetParseUrlQuery('https://www.youtube.com/watch?v=I_AX4R-d29o');

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesFeedPost,
    validationErrorState,
    {
      text,
    },
  );

  const onPostShare = () => {
    (async () => {

      if (! currentProfile.profileID) return;

      const val = validate();
      if (! val.allValid) return;

      setShareInProgress(true);

      /* upload files */
      const fileIDs = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const pdf of createPost.PDFs) {
        const currentFile = cloneDeep(pdf.file); // todo: use file from Slice

        // eslint-disable-next-line no-await-in-loop
        const file = await dispatch(uploadFileToAzure({
          file: uploadedFile,
          description: currentFile.description,
          blobContainer: UploadBlob.activityFeed,
          // TODO: check, something might be wrong as IDs will be always the same
          profilePageID: currentProfile.profileID,
          requesterID,
        }));

        // @ts-ignore
        fileIDs.push(file.payload.data.id);
      }

      // upload images
      const imageIDs = [];
      // eslint-disable-next-line no-restricted-syntax,no-unused-vars,@typescript-eslint/no-unused-vars
      for (const image of createPost.images) {

        // eslint-disable-next-line no-await-in-loop
        const file = await dispatch(uploadFileToAzure({
          file: uploadedImage, // todo: use file from Slice if possible to store in slice
          description: 'Feed Post Image',
          blobContainer: UploadBlob.activityFeed,
          // TODO: check, something might be wrong as IDs will be always the same
          profilePageID: currentProfile.profileID,
          requesterID,
        }));

        // @ts-ignore
        imageIDs.push(file.payload.data.id);
      }

      dispatch(setFeedListPage(1)); // hack for reset

      // create post
      await createPostRequest({
        profilePage: currentProfile.profileID,
        payload: {
          id: 0,
          fileAttachments: fileIDs.map((file) => ({ attachmentGuid: file })),
          imageAttachments: imageIDs.map((image) => ({ attachmentGuid: image })),
          content: createPost.text,
          externalLinks: createPost.links.map((link) => ({
            id: 0,
            description: link.title,
            link: link.linkURL,
            embedded: true,
          })),
        },
      });

      //
      // dispatch(addPost(createPost));
      dispatch(resetPost());

      setShareInProgress(false);
    })();
  };

  return (
    <>
      <div className="relative bg-white pt-1 px-6 pb-6 mb-2 shadow shadow-lg rounded rounded-lg flex flex-row">
        <div className="r-5 pt-3 mr-4">
          <UserAvatar
            profilePageType={currentProfile.profilePageType}
            profileImageUrl={currentProfile.profileImageUrl}
            size="medium"
          />
        </div>

        {/* Right side */}
        <div className="w-full">

          <div className="mt-3">
            <PostTextarea
              text={text}
              onChange={(e) => {
                dispatch(updateText(e.target.value));
                onValidationInputChange('text');
              }}
              placeholder="Share a post"
              validationMessage={validationState.text.errorMessage}
            />
          </div>

          <PostOnBehalfMessage />

          {/* Actions */}
          <div className="bg-neutral-75 px-2 py-4 mt-2 rounded-lg">
            <button
              type="button"
              disabled={links.length === 1}
              onClick={() => {
                dispatch(createLink());
              }}
              className={
                classNames(
                  links.length === 1 // disabled
                    ? 'text-neutral-400 cursor-not-allowed' : 'text-main-600 hover:text-main-800',
                  'mr-2 inline-flex items-center px-5 py-1 border border-transparent text-xs font-semibold rounded-full bg-white  focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-main-500 transition-colors',
                )
              }
            >
              Add a link
            </button>

            <button
              type="button"
              disabled={images.length === 1}
              onClick={() => {
                dispatch(createImage());
              }}
              className={
                classNames(
                  images.length === 1 // disabled
                    ? 'text-neutral-400 cursor-not-allowed' : 'text-main-600 hover:text-main-800',
                  'mr-2 inline-flex items-center px-5 py-1 border border-transparent text-xs font-semibold rounded-full bg-white  focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-main-500 transition-colors',
                )
              }
            >
              Add an image
            </button>

            <button
              type="button"
              disabled={PDFs.length === 1}
              onClick={() => {
                dispatch(createPDF());
              }}
              className={
                classNames(
                  PDFs.length === 1 // disabled
                    ? 'text-neutral-400 cursor-not-allowed' : 'text-main-600 hover:text-main-800',
                  'mr-2 inline-flex items-center px-5 py-1 border border-transparent text-xs font-semibold rounded-full bg-white  focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-main-500 transition-colors',
                )
              }
            >
              Attach a PDF
            </button>
          </div>

          {
            links.map((link) => (
              <PostLink
                allowEdit
                onDelete={() => dispatch(deleteLink(link.id))}
                onEdit={(l: Link) => dispatch(editLink(l))}
                key={link.id}
                id={link.id}
                linkURL={link.linkURL}
                title={link.title}
                inEditMode={link.inEditMode}
              />
            ))
          }

          {
            images.map((image) => (
              <PostImage
                onDelete={() => dispatch(deleteImage(image.id))}
                onEdit={(i: IPostImage) => {
                  dispatch(editImage({
                    id: i.id,
                    blobURL: i.blobURL,
                    inEditMode: i.inEditMode,
                  }));
                  setUploadedImage(i.file);
                }}
                key={image.id}
                id={image.id}
                blobURL={image.blobURL}
                inEditMode={image.inEditMode}
              />
            ))
          }

          {
            PDFs.map((pdf) => (
              <PostPDF
                setFile={(file) => setUploadedFile(file)}
                key={pdf.id}
                id={pdf.id}
                title={pdf.title}
                file={pdf.file}
                inEditMode={pdf.inEditMode}
              />
            ))
          }

          {/* Save / Cancel */}
          <div className="mt-4 px-2 flex justify-between">
            <div>
              {
                showDismissButton && (
                  <button
                    type="button"
                    onClick={() => setIsCancelState(true)}
                    className="mr-2 inline-flex items-center px-5 py-2 border border-neutral-200 text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 focus:outline-none transition-colors duration-300"
                  >
                    Dismiss
                  </button>
                )
              }
            </div>
            <div>
              <button
                type="button"
                disabled={! showDismissButton}
                className={`
                mr-2 inline-flex items-center px-5 py-2 border border-neutral-200 text-xs font-semibold
                ${! showDismissButton && ' opacity-50 '}
                 rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 focus:outline-none transition-colors duration-300
                `}
                onClick={() => setOpenPreview(true)}
              >
                Preview
              </button>
              <button
                type="button"
                disabled={shareInProgress || ! showDismissButton}
                onClick={onPostShare}
                className={
                  `inline-flex items-center px-5 py-2 border border-neutral-800 text-xs font-semibold
                  ${! showDismissButton && ' opacity-50 '}
                  ${shareInProgress && ' animate-pulse '}
                   rounded-3xl text-white bg-neutral-800 hover:border-main-700 hover:bg-main-700
                    hover:text-white focus:outline-none transition-colors duration-300`
                }
              >
                Share
              </button>
            </div>
          </div>
        </div>

        {/* Cancel Overlay */}
        {
          isCancelState && (
            <div className="absolute flex grid grid-rows-6 bg-white w-full h-full top-0 left-0 rounded rounded-lg border-4 border-error-400">
              <div className="flex w-full row-span-4 items-center justify-center">
                <span style={{ maxWidth: 300 }} className="font-extrabold text-2xl text-center">
                  Are you sure do you want to cancel the post?
                </span>
              </div>

              <div className="w-full row-span-2 p-6 pt-0 flex justify-end items-end">
                <button
                  type="button"
                  onClick={() => setIsCancelState(false)}
                  className="mr-2 inline-flex items-center px-5 py-1 border border-white text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 hover:border-neutral-100 focus:outline-none transition-colors duration-300"
                >
                  Back to post
                </button>
                <button
                  type="button"
                  // todo: clear post
                  onClick={() => {
                    setIsCancelState(false);
                    dispatch(resetPost());
                  }}
                  className="inline-flex items-center px-5 py-1 border border-error-600 text-xs font-semibold rounded-3xl text-white bg-error-600 hover:border-error-700 hover:bg-error-700 focus:outline-none transition-colors duration-300"
                >
                  Yes, cancel
                </button>
              </div>
            </div>
          )
        }
      </div>
      <Preview
        post={{
          ...createPost,
          ...{
            user: {
              isCorporateUser: currentProfile.accountType === 'corporation',
              name: currentProfile.name,
              avatarURL: currentProfile.profileImageUrl,
              profilePageId: currentProfile.profileID,
              relatedCorporationName: corporationDetails?.name,
              relatedCorporationProfilePageId: corporationDetails?.corporationProfilePageId,
              showCompany: individualProfileShowCompany,
              company: individualProfileCompany,
              showJobPosition: individualProfileShowJobPosition,
              jobPosition: individualProfileJobPosition,
            },
          },
        }}
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
      />
    </>
  );
}
