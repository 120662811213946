import React from 'react';
import { useCupidoLogin } from '../../helpers/hooks';

export default function LoginButton() {

  const { instance, handleLogin } = useCupidoLogin();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          handleLogin(instance);
        }}
        className="inline-flex justify-center items-center text-main-900 font-semibold px-4 py-2 bg-white rounded-md"
      >
        Login
      </button>
    </>
  );
}
