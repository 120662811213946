import React from 'react';

interface FilterInputProps {
  inputID: string,
  labelName: string
  onChange: (v: boolean) => void,
  type?: 'radio' | 'checkbox',
  checked?: boolean,
}

export default function FilterInput(props: FilterInputProps) {

  const {
    inputID, labelName, onChange, type = 'checkbox', checked,
  } = props;

  return (
    <div className="py-2.5 px-4 flex flex-row">
      <input
        id={inputID}
        checked={checked}
        onChange={(e) => {
          const v = Boolean(e.target.checked);
          onChange(v);
        }}
        type={type}
        className="h-4 w-4 rounded text-main-600 focus:outline-none border-neutral-300"
      />
      <label
        htmlFor={inputID}
        className="pl-2 text-main-600 font-bold text-xs"
      >
        {labelName}
      </label>
    </div>
  );
}
