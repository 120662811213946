import { useLocation } from 'react-router-dom';
import { PropsWithRef, useLayoutEffect } from 'react';

export default function ScrollToTopRouteWrap(props: PropsWithRef<any>) {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return props.children;
}
