import React, { useState } from 'react';
// @ts-expect-error
import Fade from '@stahl.luke/react-reveal/Fade';
import Input from '../../../elements/Input';
import Button from '../../../elements/Button';
import SuccessBlock from '../../../elements/SuccessBlock';
import { usePreRegistrationMutation } from '../landingPageAPI';
import { useFormValidation } from '../../../helpers/validation';
import {
  rulesForFields,
  validationErrorState,
  ValidationKeys,
} from './validationRules';
import MarketsSelection
  from '../../profile-page/modules/business-markets-module/elements/MarketsSelection';
import {
  FlattenTerritory,
} from '../../profile-page/modules/business-markets-module/business-markets-module-types';
import FocusNameField from '../../profile-page/modules/focus-module/components/FocusNameField';
import Switch from '../../../elements/Switch';

export default function QuizForm() {

  const [emailInput, setEmailInput] = useState('');
  const [name, setName] = useState('');
  const [surnameInput, setSurnameInput] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [country, setCountry] = useState<FlattenTerritory | undefined | null>(undefined);
  const [focusID, setFocusID] = useState(-1);
  const [focusName, setFocusName] = useState('');
  const [prizeConsent, setPrizeConsent] = useState(false);
  const [policyConsent, setPolicyConsent] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);

  const [showSuccessBlock, setShowSuccessBlock] = useState(false);
  const [requestInProgress, seRequestInProgress] = useState(false);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      email: emailInput,
      surname: surnameInput,
      name,
      phoneNumber,
      jobTitle,
      companyName,
      country: country ? country.territoryName : null,
      focusArea: focusID,
      prizeConsent,
      policyConsent,
      subscribedToNewsletter,
    },
  );

  const [preRegistrationRequest] = usePreRegistrationMutation();

  // todo: success / error user flow
  // todo: send form
  // todo: test all
  // todo: privacy policy etc
  const onRegister = async () => {
    if (requestInProgress) return;

    const val = validate();

    if (! val.allValid) { return; }

    seRequestInProgress(true);

    // todo: don't forget country and other
    const response = await preRegistrationRequest({
      name,
      surname: surnameInput,
      email: emailInput,
      country: country?.territoryName,
      companyName,
      jobTitle,
      phoneNumber,
      focusAreas: [focusName],
      origin: 'cphi',
      prizeConsent,
      policyConsent,
      subscribedToNewsletter,
    });

    // todo: don't forget country other fields -- extract reset
    if (! Object.prototype.hasOwnProperty.call(response, 'error')) { // on success
      setName('');
      setEmailInput('');
      setSurnameInput('');
      setCountry(undefined);
      setPhoneNumber('');
      setJobTitle('');
      setCompanyName('');
      setFocusID(-1);
      setFocusName('');
      setPrizeConsent(false);
      setPolicyConsent(false);
      setSubscribedToNewsletter(false);

      setShowSuccessBlock(true);
    }

    seRequestInProgress(false);
  };

  const onMarketSelection = (selectedTerritory: FlattenTerritory) => {
    setCountry(selectedTerritory);
    onValidationInputChange('country');
  };

  return (
    <Fade right>
      <div className="registration-form mt-4 md:mt-0 xl:mt-16 bg-white rounded-md shadow-md px-8 py-12 xl:mr-4 min-w-[40%] relative right-0 xl:relative">
        <p className="font-bold text-main-900 text-2xl">
          CPHI Registration
        </p>
        {
          ! showSuccessBlock && (
            <>
              <div className="bg-main-50 mt-4 w-full rounded-md flex items-center flex-col">
                <p className="py-4 px-8 text-main-600 font-bold text-xl md:text-2xl text-center">
                  Please Register Below
                  <br />
                </p>
                {/* <p className="pb-4 pt-2 text-main-600 font-bold text-xs"> */}
                {/*  (details described in the quiz T&C) */}
                {/* </p> */}
              </div>

              <div>
                <div className="mt-4">
                  <Input
                    value={emailInput}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                      onValidationInputChange('email');
                    }}
                    id="quiz-email"
                    label="Email"
                    placeholder="Email"
                    validationMessage={validationState.email.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      onValidationInputChange('name');
                    }}
                    id="quiz-name"
                    label="Name"
                    placeholder="Name"
                    validationMessage={validationState.name.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    value={surnameInput}
                    onChange={(e) => {
                      setSurnameInput(e.target.value);
                      onValidationInputChange('surname');
                    }}
                    id="quiz-surname"
                    label="Surname"
                    placeholder="Surname"
                    validationMessage={validationState.surname.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      onValidationInputChange('phoneNumber');
                    }}
                    id="quiz-phone"
                    label="Phone number"
                    placeholder="988..."
                    validationMessage={validationState.phoneNumber.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    value={jobTitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                      onValidationInputChange('jobTitle');
                    }}
                    id="quiz-job"
                    label="Job Title"
                    placeholder="CEO/CTO/..."
                    validationMessage={validationState.jobTitle.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      onValidationInputChange('companyName');
                    }}
                    id="quiz-companyName"
                    label="Company Name"
                    placeholder="Tesla"
                    validationMessage={validationState.companyName.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <MarketsSelection
                    onChange={onMarketSelection}
                    isMulti={false}
                    value={country}
                    validationMessage={validationState.country.errorMessage}
                  />
                </div>
                <div className="mt-4">
                  <FocusNameField
                    value={focusID}
                    addEmptyValue
                    onChange={(v) => {
                      setFocusID(v ? v.id : 1);
                      setFocusName(v ? v.name : '');
                    }}
                  />
                </div>

                <div className="mt-6">
                  <Switch
                    checked={policyConsent}
                    onChange={(v) => {
                      setPolicyConsent(v);
                      onValidationInputChange('policyConsent');
                    }}
                    validationMessage={validationState.policyConsent.errorMessage}
                    labelHTML={(
                      <span className="font-semibold text-neutral-800 pl-3">
                        I acknowledge the
                        {' '}
                        <a
                          className="underline"
                          href="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        {' and '}
                        <a
                          className="underline"
                          href="/terms-of-use"
                          target="_blank"
                        >
                          Terms of Use
                        </a>
                      </span>
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Switch
                    checked={prizeConsent}
                    onChange={(v) => {
                      setPrizeConsent(v);
                      onValidationInputChange('prizeConsent');
                    }}
                    validationMessage={validationState.prizeConsent.errorMessage}
                    labelHTML={(
                      <span className="font-semibold text-neutral-800 pl-3">
                        I agree with my participation in the prize contest under the
                        {' '}
                        <a
                          className="underline"
                          href="/contest-terms"
                          target="_blank"
                        >
                          Contest Rules
                        </a>
                      </span>
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Switch
                    checked={subscribedToNewsletter}
                    onChange={(v) => {
                      setSubscribedToNewsletter(v);
                      onValidationInputChange('subscribedToNewsletter');
                    }}
                    validationMessage={validationState.subscribedToNewsletter.errorMessage}
                    labelHTML={(
                      <span className="font-semibold text-neutral-800 pl-3">
                        I consent to accept updates and newsletter in line with the
                        {' '}
                        <a
                          className="underline"
                          href="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    )}
                  />
                </div>

                <div className={`flex justify-end mt-8 ${requestInProgress && 'animate-pulse'}`}>
                  <Button
                    size="big"
                    // disabled={requestInProgress}
                    onClick={onRegister}
                    description="Register"
                  />
                </div>
              </div>
            </>
          )
        }

        {
          showSuccessBlock && (
            <SuccessBlock
              onClick={() => setShowSuccessBlock(false)}
              title="Prize Draw Registration Complete"
              description="We will review your form and notify about the results. Thank you!"
              successful
              buttonToRight
            />
          )
        }
      </div>
    </Fade>
  );
}
