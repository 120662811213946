import React, { useState } from 'react';
import { createAccount } from '../../app/account';
import { findCorporation, setLegalName as setReduxName } from './registrationSlice';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import { useAppDispatch } from '../../app/hooks';

interface CorporateDetailsProps {
  setNextStep: (number: 3 | 4) => void, // 4 - found existing corp. | 5 - successful registration
  onCancel: () => void,
}

export default function CorporateDetails(props: CorporateDetailsProps) {

  const { setNextStep, onCancel } = props;

  const [legalName, setLegalName] = useState('');
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="bg-white shadow-sm p-6 rounded-md">
      <h3 className="p-4 font-bold text-xl">
        Corporation details
      </h3>

      <div style={{ minWidth: 496 }}>
        <Input
          value={legalName}
          id="job-title"
          label="Legal name"
          placeholder="Please enter the name of the corporation you represent"
          onChange={(e) => setLegalName(e.target.value)}
        />
      </div>

      <div className="pt-6 flex justify-end w-full grid-cols-2 gap-4">
        <Button
          onClick={onCancel}
          size="big"
          inverted
          disabled={isLoading}
          description="Cancel"
        />
        <Button
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);

            try {
              const { payload } = await dispatch(findCorporation(legalName));

              if (! payload) {
                return;
              }

              if (payload && payload.data && payload.data.length > 0) { // if found account
                setIsLoading(false);
                dispatch(setReduxName(legalName));
                setNextStep(3);
                return;
              }

              await dispatch(createAccount({
                accountType: 2,
                corporation: {
                  name: legalName,
                },
              }));

              setIsLoading(false);
              setNextStep(4);
            } catch (e) {
              setIsLoading(false);
            }
          }}
          disabled={legalName.length < 2}
          size="big"
          description="Send"
        />
      </div>
    </div>
  );
}
