import React, { ReactElement } from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

interface InfoMessageProps {
  message: string | ReactElement,
  size?: 'tiny' | 'medium',
}

export default function InfoMessage(props: InfoMessageProps) {
  const { message, size = 'medium' } = props;

  return (
    <div className="w-full text-2xs">
      <div className={`${size === 'medium' && 'py-1'} rounded-md px-4 bg-main-25 text-main-500 flex flex-row items-center`}>
        <InformationCircleIcon style={{ minWidth: 24 }} className="h-7 w-7 pr-2.5" />
        <span className={`${size === 'tiny' ? 'text-2xs' : 'text-xs'}`}>{ message }</span>
      </div>
    </div>
  );
}
