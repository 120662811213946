import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { RootState } from './store';
import { API_URL } from '../authConfig';

export const fetchBaseQueryApp = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).account;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
