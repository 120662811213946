import { emptySplitApi } from '../../../app/api';
import { addFlashMessage } from '../../flash-messages/flashMessagesSlice';
import { CreatePostPayload } from './create-post.types';

export const postAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    createPost: builder.mutation<null, { profilePage: number, payload: CreatePostPayload }>({
      query({ profilePage, payload }) {
        return {
          url: `/ActivityFeed/post/profile-page/${profilePage}`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: () => ['FeedPosts'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Your post has been successfully shared!'));
        } catch {
          dispatch(addFlashMessage(
            'error',
            'Please fix the issues in your post and try again',
            false,
            {
              text: 'Go to Post',
              action: 'scrollToTop',
            },
          ));
        }
      },
    }),
  }),
});

export const {
  useCreatePostMutation,
} = postAPI;
