import { RulesForFieldsType, RuleTypes, ValidationError } from '../../../helpers/validation';

export type ValidationKeys = 'name' | 'surname' | 'email'
| 'phoneNumber' | 'jobTitle' | 'companyName'
| 'country' | 'focusArea' | 'prizeConsent' | 'policyConsent' | 'subscribedToNewsletter';

export const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  name: {
    userFriendlyFieldName: 'Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
  },
  surname: {
    userFriendlyFieldName: 'Surname',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
  },
  email: {
    userFriendlyFieldName: 'Email',
    ruleType: RuleTypes.email,
    required: true,
  },
  phoneNumber: {
    userFriendlyFieldName: 'Phone',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 7,
  },
  jobTitle: {
    userFriendlyFieldName: 'Job title',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 3,
  },
  companyName: {
    userFriendlyFieldName: 'Company Name',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 3,
  },
  country: {
    userFriendlyFieldName: 'Country',
    ruleType: RuleTypes.text,
    required: false,
  },
  focusArea: {
    userFriendlyFieldName: 'Focus Area',
    ruleType: RuleTypes.number,
    required: false,
  },
  prizeConsent: {
    userFriendlyFieldName: 'Prize consent',
    ruleType: RuleTypes.flag,
    required: false,
  },
  policyConsent: {
    userFriendlyFieldName: 'Policy consent',
    ruleType: RuleTypes.flag,
    required: false,
  },
  subscribedToNewsletter: {
    userFriendlyFieldName: 'Subscribe to newsletter',
    ruleType: RuleTypes.flag,
    required: false,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  email: { errorMessage: '' },
  surname: { errorMessage: '' },
  name: { errorMessage: '' },
  phoneNumber: { errorMessage: '' },
  jobTitle: { errorMessage: '' },
  companyName: { errorMessage: '' },
  country: { errorMessage: '' },
  focusArea: { errorMessage: '' },
  prizeConsent: { errorMessage: '' },
  policyConsent: { errorMessage: '' },
  subscribedToNewsletter: { errorMessage: '' },
};
