import React from 'react';
import { Link } from 'react-router-dom';
import { 
  UserPlusIcon, CheckIcon, ChatBubbleOvalLeftEllipsisIcon, EyeIcon, UserIcon,
} from '@heroicons/react/20/solid';
import { DateTime } from 'luxon';
import UserAvatar from '../../../elements/UserAvatar';
import { SenderProfilePageSummary } from '../../contact-list/contact-list.types';

interface ConnectRequestProps {
  timestamp: string,
  profilePage: SenderProfilePageSummary,
  descriptionMessage?: string,

  // todo: handle other types
  type: 'connectRequest' | 'connectionSuccessful' | 'chatRequest' | 'successfulChatRequest' | 'followedProfilePage' | 'profilePageModulesUpdated'
}

// todo: handle type
export default function ConnectRequest(props: ConnectRequestProps) {

  const {
    timestamp, type, profilePage, descriptionMessage,
  } = props;

  // TODO: extract into utils
  let message = '';
  if (type === 'connectRequest') {
    message = 'Would like to be your contact';
  }
  if (type === 'connectionSuccessful') {
    message = 'Accepted contact request';
  }
  if (type === 'chatRequest') {
    message = 'Would like to chat';
  }
  if (type === 'successfulChatRequest') {
    message = 'Accepted chat request';
  }
  if (type === 'followedProfilePage') {
    message = 'Is following you';
  }
  if (type === 'profilePageModulesUpdated') {
    message = 'Has updated their Profile Page';
  }

  let successButtonMessage = '';
  if (type === 'connectRequest') {
    successButtonMessage = 'Add as a contact';
  }
  if (type === 'connectionSuccessful' || type === 'successfulChatRequest' || type === 'chatRequest' || type === 'followedProfilePage' || type === 'profilePageModulesUpdated') {
    successButtonMessage = 'Go to profile';
  }

  let icon = null;
  if (type === 'connectRequest' || type === 'connectionSuccessful') {
    icon = <UserPlusIcon className="w-4 h-4" />;
  }
  if (type === 'successfulChatRequest') {
    icon = <CheckIcon className="w-4 h-4" />;
  }
  if (type === 'chatRequest') {
    icon = <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />;
  }
  if (type === 'followedProfilePage') {
    icon = <EyeIcon className="w-4 h-4" />;
  }
  if (type === 'profilePageModulesUpdated') {
    icon = <UserIcon className="w-4 h-4" />;
  }  

  return (
    <div className="bg-white pt-6 px-8 pb-4 mb-2 shadow shadow-lg rounded rounded-lg">
      <div className="flex flex-row">

        <UserAvatar profilePageType={profilePage.profilePageAccountType} profileImageUrl={profilePage.profileImageUrl} size="big" profileID={profilePage.profilePageId} />

        {/* Header */}
        <div className="flex flex-col pl-4 w-full">
          <p className="text-xs text-neutral-400 pb-1">
            {DateTime.fromISO(timestamp).toRelative() || '-'}
          </p>
          <div className="mb-0.5">
            <Link to={`/profile/${profilePage.profilePageId}`} className="max-w-fit">
              <p className="text-sm font-bold">{ profilePage.name }</p>
            </Link>
          </div>
          { /* show company set in dynamic fields */ }
          {
            profilePage.showCompany && profilePage.company && (
              <p className="text-sm font-semibold text-neutral-600 table-cell">
                {profilePage.company}
              </p>
            )
          }
          { /* show job position set in dynamic fields if company is also set */ }
          {
            profilePage.showCompany && profilePage.company && profilePage.showJobPosition && profilePage.jobPosition && (
              <p className="text-xs font-semibold text-neutral-600">                    
                {profilePage.jobPosition}
              </p>
            )
          }          
          { /* show related corporation name */ }          
          {
            !profilePage.showCompany && profilePage.relatedCorporationProfilePageSummary && (
              <Link to={`/profile/${profilePage.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                <p className="text-xs text-neutral-500">{profilePage.relatedCorporationProfilePageSummary.name}</p>
              </Link>
            )
          }          
          <p className="text-main-500 flex flex-row items-center pt-4">
            { icon }
            <span className="pl-2.5 text-sm font-semibold">
              { message }
            </span>
          </p>

          {/* Content */}
          {
            descriptionMessage ? (
              <p className="p-2 pt-5 text-sm">
                { descriptionMessage }
              </p>
            ) : null
          }

          {/* Buttons */}
          <div className="flex justify-between pt-4">
            <div>
              <div className={`${(type === 'connectionSuccessful' || type === 'successfulChatRequest' || type === 'followedProfilePage' || type === 'profilePageModulesUpdated') ? 'hidden' : ''}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-2 border border-white text-xs font-semibold rounded-3xl text-error-600 bg-white hover:bg-error-600 hover:text-white focus:outline-none transition-colors duration-300"
                >
                  Block / Report
                </button>
              </div>
            </div>
            <div>
              {
                (type === 'connectRequest' || type === 'chatRequest') ? (
                  <button
                    type="button"
                    className="mr-2 inline-flex items-center px-5 py-2 border border-neutral-200 text-xs font-semibold rounded-3xl text-neutral-800 bg-white hover:bg-neutral-100 focus:outline-none transition-colors duration-300"
                  >
                    Dismiss
                  </button>
                ) : null
              }

              <Link
                to={`/profile/${profilePage.profilePageId}`}
                className="inline-flex items-center px-5 py-2 border border-neutral-800 text-xs font-semibold rounded-3xl text-white bg-neutral-800 hover:border-main-700 hover:bg-main-700 hover:text-white focus:outline-none transition-colors duration-300"
              >
                { successButtonMessage }
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConnectRequest.defaultProps = {
  descriptionMessage: '',
};
