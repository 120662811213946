import React, { ReactElement } from 'react';
import { classNames } from '../helpers/styling';

interface SectionTitleProps {
  title: string,
  children?: ReactElement,
  size?: 'large' | 'medium'
}

export default function SectionHeader(props: SectionTitleProps) {
  const { title, children, size = 'large' } = props;

  return (
    <div className={classNames(
      size === 'large' ? 'p-6' : '',
      size === 'medium' ? 'px-6 py-4' : '',
      'bg-white shadow shadow-md rounded rounded-md flex justify-between items-center align-middle',
    )}
    >
      <h2 className={classNames(
        size === 'large' ? 'text-3xl' : '',
        size === 'medium' ? 'text-lg' : '',
        ' font-bold text-neutral-800 max-w-[75%]',
      )}
      >
        { title }
      </h2>

      { children && children }
    </div>
  );
}
