import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { ProductTypes } from '../../products-module.types';

interface FormCreateHeaderProps {
  productID: number,
}

export default function FormCreateHeader(props: FormCreateHeaderProps) {

  const { productID } = props;

  const {
    createProductFormData,
  } = useSelector((state: RootState) => state.profileModules.productsModule);

  return (
    <div className="pt-6 pl-8 pb-4">
      <h6 className="text-lg text-neutral-800 font-bold pb-4">
        Add a
        {' '}
        {
          createProductFormData[productID].productType === ProductTypes.customizedProduct ? 'custom' : 'standard'
        }
        {' '}
        product
      </h6>
      <span className="text-sm">
        Use Standard product is you want to add “this” kind of product
        <br />
        Use Customised product if you want to add a different product than a Standard product
      </span>
    </div>
  );
}
