import { RuleTypes, RulesForFieldsType, ValidationError } from '../../../helpers/validation';

export type ValidationKeys = 'localTitle';

export const rulesFeedPostLink: RulesForFieldsType<ValidationKeys> = {
  localTitle: {
    userFriendlyFieldName: 'text',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 255,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  localTitle: { errorMessage: '' },
};
