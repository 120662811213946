import React, { useState } from 'react';
import {
  ArrowLeftIcon, PencilIcon, Squares2X2Icon, TrashIcon,
} from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../dashboard-layout/DashboardLayout';
import SectionHeader from '../../../elements/SectionHeader';
import Button from '../../../elements/Button';
import { forumAPI, useDeleteQuestionMutation, useGetForumQuestionQuery } from '../forumAPI';
import { RootState } from '../../../app/store';
import ModuleLoader from '../../profile-page/components/ModuleLoader';
import ClumpedContent from './ClumpedContent';
import Tag from '../../../elements/Tag';
import ProfileLink from '../post-question/ProfileLink';
import AnswersList from './answers/AnswersList';
import ForumVoting from './ForumVoting';
import MetadataRow from './MetadataRow';
import EditQuestionForm from './EditQuestionForm';
import ErrorModal from '../../../elements/ErrorModal';
import { useVoteForQuestionMutation } from './questionAPI';
import { VoteOperation } from '../forum-types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import calculatedCachedVote from '../helpers/calculatedCachedVote';
import { useDocumentTitle } from '../../../helpers/hooks';
import { setForumFiltersTags } from '../questions-list/forumQuestionsSlice';
import { getCanDeleteForumQuestions, getCanUpdateForumAnswers } from '../../../app/permissionsSchema';

export default function QuestionPage() {

  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const history  = useHistory();
  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);
  const canDeleteForumQuestions = useAppSelector(getCanDeleteForumQuestions);

  // @ts-ignore
  const { id } = useParams();

  const getQuestionParams = {
    questionID: parseInt(id, 10),
    requesterProfilePageId: profileID,
  };
  const { data, isLoading, isFetching } = useGetForumQuestionQuery(getQuestionParams, { skip: (! id) || (! profileID) });

  useDocumentTitle(`Question | ${data ? data.title : ''}`);

  const [deleteQuestionQuery] = useDeleteQuestionMutation();
  const [voteForQuestionRequest] = useVoteForQuestionMutation();

  const dispatch = useAppDispatch();

  const onQuestionDelete = async () => {
    if (! data) return;

    await deleteQuestionQuery({
      questionID: data.id,
      requesterID: profileID,
    });

    history.push('/forum');
  };

  // todo: on vote reset list of questions
  const onVote = async (operation: VoteOperation) => {
    if (! data) return;

    let voteOperation = operation;
    if (data.requesterVote === operation) {
      voteOperation = VoteOperation.removeVote;
    }

    dispatch(forumAPI.util.updateQueryData('getForumQuestion', getQuestionParams, (question) => ({
      ...question,
      requesterVote: voteOperation === VoteOperation.removeVote ? null : voteOperation,
      votesCounter: calculatedCachedVote(question.requesterVote, voteOperation, question.votesCounter),
    })));

    await voteForQuestionRequest({
      questionID: data.id,
      requesterID: profileID,
      voteOperation,
    });
  };

  let content = null;
  if (isLoading || isFetching) {
    content = (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-4 p-6">
        <ModuleLoader />
      </div>
    );
  }

  if (!isLoading && data == null) {
    history.push('/page404');
  }

  if (data) {

    content = (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-4 p-6">

        <div className="grid gap-0 grid-cols-12">
          <div className="col-span-1 pr-4 flex flex-col items-center justify-between">

            <ForumVoting
              disabled={data.creatorProfilePageSummary?.profilePageId === profileID}
              currentVote={data.requesterVote}
              votesCounter={data.votesCounter}
              onUpVote={() => onVote(VoteOperation.upVote)}
              onDownVote={() => onVote(VoteOperation.downVote)}
            />

          </div>
          <div className="col-span-11">
            { editMode ? (
              <EditQuestionForm
                data={data}
                setViewState={() => setEditMode(false)}
              />
            ) : <ClumpedContent key={data.content} text={data.content} />}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8  mt-8">
          <div className="flex flex-row flex-wrap gap-2 col-span-1">
            {
              (! editMode) && data.tags.map((tag) => (
                <Tag
                  key={tag.id}
                  name={tag.name}
                  onClick={() => {
                    dispatch(setForumFiltersTags([{
                      ...tag,
                      value: tag.id,
                      label: tag.name,
                    }]));

                    history.push('/forum');
                  }}
                />
              ))
            }
          </div>

          <div className="col-span-1">
            <ProfileLink creator={data.creatorProfilePageSummary} />
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <MetadataRow
            createdAtUtc={data.createdAtUtc}
            updatedAtUtc={data.updatedAtUtc}
            edited={data.edited}
            views={data.views}
            createdLabel="Asked"
          />
        </div>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <SectionHeader title={data ? data.title : 'Title'}>
          <Button
            onClick={() => history.push('/forum')}
            size="small"
            inverted
            description="Back to questions"
            iconLeft={<ArrowLeftIcon className="w-4 h-4 mr-2" />}
            roundedFull
          />
        </SectionHeader>

        {
          // todo: extract
          data && (data.createdByRequester || canDeleteForumQuestions) && (
            <div className="mt-4">
              <SectionHeader size="medium" title="Actions">
                <div className="flex flex-row gap-2">
                  <Button
                    onClick={() => setShowDeleteModal(true)}
                    size="small"
                    inverted
                    roundedFull
                    redText
                    // disabled
                    iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
                    description="Delete question"
                  />
                  <Button
                    onClick={() => setEditMode(! editMode)}
                    size="small"
                    inverted
                    roundedFull
                    // disabled
                    iconLeft={editMode ? <Squares2X2Icon className="w-4 h-4 mr-2" /> : <PencilIcon className="w-4 h-4 mr-2" />}
                    description={editMode ? 'View mode' :  'Edit'}
                  />
                </div>
              </SectionHeader>
            </div>
          )
        }

        { content }

        {
          data && <AnswersList question={data} />
        }

        <ErrorModal
          withErrorBorder={false}
          show={showDeleteModal}
          // timeout for animation to pass
          setOpen={setShowDeleteModal}
          transparentBackground
          onActionClick={onQuestionDelete}
          actionButtonText="Delete question"
          title="Delete question?"
          description="Your question will be deleted and this action cannot be undone."
        />
      </main>
    </DashboardLayout>
  );
}
