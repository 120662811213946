import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clone } from 'lodash';
import { IService } from './services-types';

interface ServicesModuleSlice {
  deleteServiceModalData: {
    service: IService | null,
    showModal: boolean,
  },

  createServiceViewDisplay: boolean,

  createServiceFormData: {
    [key: number]: IService,
  }
}

const initialState: ServicesModuleSlice = {
  deleteServiceModalData: {
    service: null,
    showModal: false,
  },

  createServiceViewDisplay: false,

  createServiceFormData: {
    0: {
      id: 0,
      coreServiceId: 0,
      coreServiceName: '',
      description: '',
      coreTags: [],
      externalLinks: [],
      fileAttachments: [],
      parentCoreServiceNames: '',
      territories: [],
    },
  },
};

export const servicesModuleSlice = createSlice({
  name: 'servicesModule',
  initialState,
  reducers: {
    setDeleteServiceModalData: (state, action) => {
      state.deleteServiceModalData = action.payload;
    },

    // on service edit
    setCreateServiceForm: (state, action: PayloadAction<IService>) => {
      state.createServiceFormData[action.payload.coreServiceId] = clone(action.payload);
    },

    // called on edit cancel
    resetCreateServiceForm: (state, action: PayloadAction<number>) => {
      const newFormData = clone(state.createServiceFormData);
      delete newFormData[action.payload];

      state.createServiceFormData = newFormData;
    },

    displayCreateServiceForm: (state, action: PayloadAction<{ showForm: boolean, service?: IService }>) => {
      state.createServiceViewDisplay = action.payload.showForm;

      state.createServiceFormData = {
        ...state.createServiceFormData,
        0: {
          ...initialState.createServiceFormData[0], // reset data
        },
      };

      if (action.payload.service) {
        state.createServiceFormData = {
          ...state.createServiceFormData,
          0: {
            ...action.payload.service, // set data
            id: 0, // reset id
          },
        };
      }
    },

    updateCreateServiceCoreServiceID: (state, { payload: { serviceID, value } }) => { // core service ID
      state.createServiceFormData[serviceID].coreServiceId = value;
    },

    updateCreateServiceDescription: (state, { payload: { serviceID, value } }) => { // description
      state.createServiceFormData[serviceID].description = value;
    },

    updateCreateServiceLink: (state, { payload: { serviceID, value } }) => { // description
      state.createServiceFormData[serviceID].externalLinks = [value];
    },

    updateCreateServiceFile: (state, { payload: { serviceID, value } }) => { // description
      state.createServiceFormData[serviceID].fileAttachments = [value];
    },

    onServiceTerritorySelection: (state, { payload: { serviceID, value } }) => { // territory
      state.createServiceFormData[serviceID].territories = value;
    },

    updateCreateServiceTags: (state, { payload: { serviceID, value } }) => {
      state.createServiceFormData[serviceID].coreTags = value;
    },
  },
});

export const {
  setDeleteServiceModalData,
  setCreateServiceForm,
  resetCreateServiceForm,
  displayCreateServiceForm,

  updateCreateServiceDescription,
  onServiceTerritorySelection,
  updateCreateServiceTags,

  updateCreateServiceCoreServiceID,
  updateCreateServiceLink,
  updateCreateServiceFile,

} = servicesModuleSlice.actions;

export default servicesModuleSlice.reducer;
