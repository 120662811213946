import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../authConfig';
import { RootState } from './store';
import { settingsAPI } from '../features/settings/settingsAPI';
import { addFlashMessage } from '../features/flash-messages/flashMessagesSlice';
import { emptySplitApi } from './api';
import { permissionsSchema } from './permissionsSchema';
import { setAdminPermissions } from './helpers';

export interface Corporation {
  name: string,
  corporationId: number,
  corporationProfilePageId: number,
  memberRole: 'member' | 'admin',
  profileImageUrl: string,
  memberIsUniqueAdministrator: boolean,
}

// Used for the account type of the user
export enum AccountType {
  individual = 'individual',
  corporation = 'corporation', // applies to corporation members and corporation admins
  applicationAdministrator = 'applicationAdministrator',
}

// Indicates the profile page type
export enum ProfilePageType {
  individual = 'individual',
  corporation = 'corporation',
}

export interface RelatedCorporationSummary {
  profilePageId: number,
  followersCount: number,
  name: string,
  profileImageUrl: string,
  profilePageAccountType: AccountType,
}

export interface SwapProfile {
  profileID: number,
  profileImageUrl: string,
  name: string,
  accountType: AccountType,
  profilePageType: ProfilePageType,
}

export interface AccountState {
  token?: string;
  showLoader: boolean,
  accountLoadStatus: 'none' | 'success' | 'error', // system flag for rendering conditions

  currentProfile: SwapProfile, // use only this one
  swapProfile?: SwapProfile,

  accountType: AccountType, //  Individual = 1, Corporation = 2
  profileImageUrl: string,
  accountId: number,
  individualProfilePageId: number,
  isRegistered: boolean,
  firstName: string,
  surname: string,
  email: string,

  corporationDetails?: Corporation,

  individualProfileShowCompany: boolean,
  individualProfileCompany: string,

  individualProfileShowJobPosition: boolean,
  individualProfileJobPosition: string,

  showRegisterModal: boolean,
  adminPermissions: typeof permissionsSchema,
}

export interface IndividualUserCreate {
  accountType: 1,
}

export interface CorporateUserCreate {
  accountType: 2,
  corporation: {
    name: string
  }
}

const initialState: AccountState = {
  token: '',
  showLoader: false,
  accountLoadStatus: 'none',

  currentProfile: { // todo: notifications count
    profileID: 0,
    profileImageUrl: '',
    name: '',
    accountType: AccountType.individual,
    profilePageType: ProfilePageType.individual,
  },
  swapProfile: undefined,

  accountType: AccountType.individual,
  accountId: 0,
  individualProfilePageId: 0,
  isRegistered: false,
  firstName: '',
  surname: '',
  email: '',
  profileImageUrl: '',

  corporationDetails: undefined,

  individualProfileShowCompany: false,
  individualProfileCompany: '',

  individualProfileShowJobPosition: false,
  individualProfileJobPosition: '',

  showRegisterModal: false,

  adminPermissions: { ...permissionsSchema },
};

export const fetchAccount = createAsyncThunk(
  'account/fetchAccount',
  async () => {
    const response = await axios.get(`${API_URL}/account`);

    return response.data;
  },
);

// todo: remove axios if won't be used across the project
export const createAccount = createAsyncThunk(
  'account/createAccount',
  async (input: IndividualUserCreate | CorporateUserCreate, { dispatch }): Promise<any> => {

    try {
      await axios.post(`${API_URL}/account`, input);
      const account = await dispatch(fetchAccount());

      return account;
    } catch (e) {
      // @ts-ignore
      dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
      return null;
    }
  },
);

export const updateAccountDetails = createAsyncThunk(
  'account/updateAccount',
  async (data: { name: string, email: string, surname: string }, { dispatch, rejectWithValue }) => {
    try {
      await axios.post(`${API_URL}/account/settings/details`, {
        email: data.email,
        firstName: data.name,
        lastName: data.surname,
      });

      dispatch(emptySplitApi.util.invalidateTags(['ProfilePage']));

      // @ts-ignore
      dispatch(addFlashMessage('success', 'Details are successfully updated'));
      return data;

    } catch (e) {
      // @ts-ignore
      dispatch(addFlashMessage('error', 'Something went wrong. Please try again'));
    }

    return rejectWithValue(null);
  },
);

export const deleteAccount = createAsyncThunk(
  'account/deleteAccount',
  // @ts-ignore
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { accountId } = (getState() as RootState).account;

    try {
      await axios.delete(`${API_URL}/account/${accountId}`);
    } catch (e) {
      // @ts-ignore
      dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
      return rejectWithValue({
        message: 'Something went wrong. Please try again.',
      });
    }

    return data;
  },
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;

      axios.interceptors.request.use((config) => {
        if (config && config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${action.payload}`;
        }

        axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
        return config;
      });
    },
    updateCorpName: (state, action) => {
      if (! state.corporationDetails) return;

      state.corporationDetails.name = action.payload;
    },
    swapProfiles: (state) => {
      if (! state.swapProfile) return;

      const swap = state.currentProfile;
      state.currentProfile = state.swapProfile;
      state.swapProfile = swap;

    },
    setShowRegisterModalFlag: (state, action: PayloadAction<boolean>) => {
      state.showRegisterModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.pending, (state) => {
        state.showLoader = true;
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {

        state.accountLoadStatus = 'success';

        const {
          accountType, accountId, individualProfilePageId,
          isRegistered, firstName, surname, email, profileImageUrl,
          individualProfileShowCompany, individualProfileCompany,
          individualProfileShowJobPosition, individualProfileJobPosition,
        } = action.payload;

        // eslint-disable-next-line
        const corporationDetails: Corporation = action.payload.corporationDetails;

        state.currentProfile.profileID = individualProfilePageId;
        state.currentProfile.profileImageUrl = profileImageUrl;
        state.currentProfile.name = `${firstName || ''} ${surname || ''}`;
        state.currentProfile.accountType = accountType;
        state.currentProfile.profilePageType = ProfilePageType.individual;

        if (corporationDetails && corporationDetails.memberRole === 'admin') {
          state.swapProfile = {
            profileID: corporationDetails.corporationProfilePageId,
            name: corporationDetails.name,
            profileImageUrl: corporationDetails.profileImageUrl,
            accountType: AccountType.corporation,
            profilePageType: ProfilePageType.corporation,
          };
        }

        state.accountType = accountType;
        state.accountId = accountId;
        state.individualProfilePageId = individualProfilePageId;
        state.isRegistered = isRegistered;
        state.firstName = firstName;
        state.surname = surname;
        state.email = email;
        state.profileImageUrl = profileImageUrl;
        state.corporationDetails = corporationDetails;

        state.individualProfileShowCompany = individualProfileShowCompany;
        state.individualProfileCompany = individualProfileCompany;

        state.individualProfileShowJobPosition = individualProfileShowJobPosition;
        state.individualProfileJobPosition = individualProfileJobPosition;

        if (accountType === AccountType.applicationAdministrator) {
          state.adminPermissions = setAdminPermissions(permissionsSchema);
        }

        state.showLoader = false;
      })
      .addCase(fetchAccount.rejected, (state) => {
        state.showLoader = false;
        state.accountLoadStatus = 'error';
      })

      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        const { name, email, surname } = action.payload;
        state.email = email;
        state.firstName = name;
        state.surname = surname;

        if ((! state.corporationDetails) || (state.corporationDetails.memberRole !== 'admin')) {
          state.currentProfile.name = `${name || ''} ${surname || ''}`;
        } else if (state.swapProfile) { // if there is corporation
          if (state.corporationDetails.corporationProfilePageId !== state.currentProfile.profileID) { // current profile is NOT the corporate one
            state.currentProfile.name = `${name || ''} ${surname || ''}`;
          } else {
            state.swapProfile.name = `${name || ''} ${surname || ''}`;
          }

        }
      })
      .addMatcher(settingsAPI.endpoints.updateCorporateDetails.matchFulfilled, (state, action) => {

        if (! state.corporationDetails || ! state.swapProfile) return;

        if (state.corporationDetails.corporationProfilePageId !== state.currentProfile.profileID) { // current profile is NOT the corporate one
          state.swapProfile.name = action.meta.arg.originalArgs.name;
        } else {
          state.currentProfile.name = action.meta.arg.originalArgs.name;
        }
      });
  },
});

export const {
  setShowRegisterModalFlag,
  setToken,
  swapProfiles,
  updateCorpName,
} = accountSlice.actions;

export default accountSlice.reducer;
