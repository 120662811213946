import React from 'react';
import { DateTime } from 'luxon';

interface MetadataRowProps {
  createdAtUtc: string,
  updatedAtUtc: string,
  views?: number,
  edited: boolean,
  createdLabel: string
}

export default function MetadataRow(props: MetadataRowProps) {

  const {
    views, updatedAtUtc, createdAtUtc, edited, createdLabel,
  } = props;

  return (
    <div className="flex flex-row gap-2">
      <span className="text-sm text-neutral-900">
        <time dateTime={`${createdAtUtc}+00:00`}>
          <span className="text-neutral-500">{createdLabel}</span>
          {' '}
          { DateTime.fromISO(`${createdAtUtc}+00:00`).toRelative() }
        </time>
      </span>

      {
        edited && (
          <span className="text-sm text-neutral-700">
            <time className="text-neutral-900" dateTime={`${updatedAtUtc}+00:00`}>
              <span className="text-neutral-500">Updated</span>
              {' '}
              { DateTime.fromISO(`${updatedAtUtc}+00:00`).toRelative() }
            </time>
          </span>
        )
      }

      {
        (views != null) && (
          <span className="text-sm text-neutral-900">
            <span className="text-neutral-500">Viewed</span>
            {' '}
            { views }
            {' '}
            times
          </span>
        )
      }
    </div>
  );
}
