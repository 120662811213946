import { clone } from 'lodash';
import {
  ICoreService, ICoreServiceFlatten, IService, IServicePost,
} from './services-types';

export default function prepareServicePayload(service: IService, isCreate = false): IServicePost {
  const payload: IServicePost = {
    id: service.id,
    coreServiceId: service.coreServiceId,
    description: service.description,
    coreTagIds: service.coreTags.map((t) => t.id),
    externalLinks: service.externalLinks ? service.externalLinks : [],

    fileAttachments: service.fileAttachments
      ? service.fileAttachments
        .map((f) => f.fileUuid)
        .filter((f) => Boolean(f))
      : [],

    territoryIds: service.territories.map((t) => t.id),
  };

  // prepare here

  if (isCreate) {
    delete payload.id;
  }

  return payload;
}

export function flattenCoreServicesList(services?: ICoreService[], nestingLevel = 1, parent?: ICoreServiceFlatten) {

  const flattenTree: ICoreServiceFlatten[] = [];

  if (! services || services.length === 0) {
    return flattenTree;
  }

  services.forEach((s) => {
    const service: ICoreServiceFlatten = {
      ...clone(s),
      value: s.id,
      label: s.name,
      parentNames: parent ? [...parent.parentNames, parent.name] : [],
      nestingLevel,
    };

    const children = s.childCoreServices;
    service.childCoreServices = null;

    service.value = service.id;

    flattenTree.push(service);

    if (children && children.length > 0) {
      const flattenChildren = flattenCoreServicesList(children, nestingLevel + 1, service);
      flattenTree.push(...flattenChildren);
    }
  });

  return flattenTree;
}
