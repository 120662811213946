/* eslint-disable react/no-array-index-key */

import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { HolderOwner } from '../../products-module.types';
import { useAppDispatch } from '../../../../../../app/hooks';
import Button from '../../../../../../elements/Button';
import { addAuthHolderOwner } from '../../productsModuleSlice';
import MarketingHolderField from './fields/MarketingHolderField';

interface MarketingHoldersProps {
  productID: number,
  holders?: HolderOwner[]
}

export default function MarketingHolders(props: MarketingHoldersProps) {

  const { productID, holders } = props;

  const dispatch = useAppDispatch();

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        Marketing Authorisation Holder / Product Owner
      </p>

      <div className="my-2.5">
        <div>
          {
            (holders && holders.length > 0) && holders.map((holder, idx) => (
              <MarketingHolderField key={idx} holderOwner={holder} idx={idx} productID={productID} />
            ))
          }
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button
            onClick={() => dispatch(addAuthHolderOwner({ productID }))}
            size="small"
            description="Add Holder"
            iconLeft={<PlusIcon className="w-4 h-4" />}
            roundedFull
            inverted
          />
        </div>
      </div>
    </div>
  );
}
