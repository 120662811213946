import { BRANDING } from '../../helpers/branding';
import { useBrandingLocale } from '../../helpers/hooks';
import { FaqQuestionEntry } from './contact-us-types';

const useFaqEntries = () => {
  const { t } = useBrandingLocale();

  // Account
  const faqAccountEntries: FaqQuestionEntry[] = [];
  faqAccountEntries.push(
    {
      questionTitle: 'How can I change my email address?',
      answer: {
        topLines: [
          'To change your email address, follow these steps:',
        ],
        steps: [
          'Navigate to account settings from the top right menu.', 
          'In the personal details section, locate the email address field and edit it.', 
          'Click "Update Details" to submit the changes.',
        ],
        bottomLines: [
          'After completing these steps, your new email address will be used for sign-in and to receive notifications.',
        ],
      },
    },
  );
  faqAccountEntries.push(
    {
      questionTitle: 'How can I change my name or surname?',
      answer: {
        topLines: [
          'To change your name or surname, follow these steps:',
        ],
        steps: [
          'Navigate to account settings from the top right menu.', 
          'In the personal details section, locate the name and surname fields and edit them.', 
          'Click "Update Details" to submit the changes.',
        ],
      },
    },
  );
  faqAccountEntries.push(
    {
      questionTitle: 'How can I change my password?',
      answer: {
        topLines: [
          'To change your password, follow these steps:',
        ],
        steps: [
          'Navigate to account settings from the top right menu.', 
          'Click on the "Change Password" button.', 
          'You will be redirected to a page where you need to insert your current password and the new one.',
        ],
      },
    },
  );
  faqAccountEntries.push(
    {
      questionTitle: 'How can I enable/disable email notifications?',
      answer: {
        topLines: [
          'To enable/disable email notifications, follow these steps:',
        ],
        steps: [
          'Navigate to account settings from the top right menu.', 
          'Go to the Email Notifications section.', 
          'From there, you can enable or disable each specific notification according to your preferences.',
        ],
      },
    },
  );
  faqAccountEntries.push(
    {
      questionTitle: 'How can I delete my account?',
      answer: {
        topLines: [
          'To delete your account, follow these steps:',
        ],
        steps: [
          'Navigate to account settings from the top right menu.', 
          'Click on the "Delete Account" button and confirm your decision in the pop-up window.', 
        ],
        bottomLines: [
          'Please be aware that once you delete your account, all data associated with it, including your profile information and activity feed posts, will be permanently removed and cannot be recovered. Although forum posts will remain, they will no longer be linked to your profile and will appear as if posted by a deleted user.',
        ],
      },
    },
  );
  faqAccountEntries.push(
    {
      questionTitle: 'How can I get help?',
      answer: {
        topLines: [
          'To get help, follow these steps:', 
        ],
        steps: [
          'Navigate to Contact Us / Help from the top right menu.',
        ],
        bottomLines: [
          'In this section, you will find a FAQ that answers the most common questions and a questionnaire to get in touch with the application administrators for further assistance.',
        ],
      },
    },
  );

  // Profile
  const faqProfileEntries: FaqQuestionEntry[] = [];
  faqProfileEntries.push(
    {
      questionTitle: 'How can I edit my profile?',
      answer: {
        topLines: [
          'You can edit your profile by accessing your profile page and making changes to the sections you want to update.', 
        ],
      },
    },
  );
  faqProfileEntries.push(
    {
      questionTitle: 'Who can see my profile?',
      answer: {
        topLines: [
          'Your profile is visible to all users.', 
        ],
      },
    },
  );

  // User Interaction
  const faqUserInteractionEntries: FaqQuestionEntry[] = [];
  faqUserInteractionEntries.push(
    {
      questionTitle: 'How can I add a user as a Contact?',
      answer: {
        topLines: [
          'To add a user as a contact, follow these steps:',
        ],
        steps: [
          'Access their profile page.',
          'Send a contact request using the "Add Contacts" button at the top of the profile page.',
        ],
        bottomLines: [
          'Once accepted, you\'ll both appear as contacts, enabling you to chat.',
          'If you need to cancel a request, it can be done from the user\'s profile page.',
        ],
      },
    },
  );
  faqUserInteractionEntries.push(
    {
      questionTitle: 'How can I follow a user?',
      answer: {
        topLines: [
          'To follow a user, follow these steps:',
        ],
        steps: [
          'Access their profile page.',
          'Follow the user using the "Follow" button at the top of the profile page.',
        ],
        bottomLines: [
          'Unlike adding a contact, this action doesn’t require approval from the user being followed.', 
          'When you follow a user, all their activity feed posts will appear in your own activity feed.',
          'If you want to unfollow a user, it can be done from the user\'s profile page.',
        ],
      },
    },
  );
  faqUserInteractionEntries.push(
    {
      questionTitle: 'How can I message a user?',
      answer: {
        topLines: [
          'If you don\'t want to add a user as a contact but wish to chat with them, you can do the following:',
        ],
        steps: [
          'Access their profile page.',
          'Send a messaging request using the "Message" button at the top of the profile page.',
        ],
        bottomLines: [
          'Once accepted, you\'ll both appear as contacts, enabling you to chat.',
          'Once accepted, you\'ll be able to chat with the user.',
        ],
      },
    },
  );

  // Activity Feed
  const faqActivityFeedEntries: FaqQuestionEntry[] = [];
  faqActivityFeedEntries.push(
    {
      questionTitle: 'How can I create an Activity Feed post?',
      answer: {
        topLines: [
          'To create an activity feed post, follow these steps:',
        ],
        steps: [
          'Navigate to your activity feed page.',
          'Use the form at the top of the page to create your post.',
        ],
      },
    },
  );
  faqActivityFeedEntries.push(
    {
      questionTitle: 'What can I post in the Activity Feed?',
      answer: {
        topLines: [
          'You can post a variety of content including text, images, links, and PDF files.',
          'At least one of these elements is required for a post.',
        ],
      },
    },
  );
  if (BRANDING === 'IPLS') {
    faqActivityFeedEntries.push(
      {
        questionTitle: 'What will I see in my Activity Feed?',
        answer: {
          topLines: [
            'In your feed, you\'ll find:',
          ],
          steps: [
            'Posts created by IPLS Groups.',
            'Your own posts.',
            'Posts from users you follow.',
            'App notifications relevant to you.',
          ],
        },
      },
    );  
  } else {
    faqActivityFeedEntries.push(
      {
        questionTitle: 'What will I see in my Activity Feed?',
        answer: {
          topLines: [
            'In your feed, you\'ll find:',
          ],
          steps: [
            'Your own posts.',
            'Posts from users you follow.',
            'App notifications relevant to you.',
          ],
        },
      },
    ); 
  }
  faqActivityFeedEntries.push(
    {
      questionTitle: 'Who can see what I post in the Activity Feed?',
      answer: {
        topLines: [
          'Everyone who is following you can see what you post in the Activity Feed.',
        ],
      },
    },
  );
  faqActivityFeedEntries.push(
    {
      questionTitle: 'How can I remove a post from the Activity Feed?',
      answer: {
        topLines: [
          'To remove an activity feed post, follow these steps:',
        ],
        steps: [
          'Navigate to your activity feed page.',
          'Tap the menu icon (three lines) on the post you want to delete and select "Delete Post.',
        ],
        bottomLines: [
          'To easily find your posts, you can use the "Show Only My Posts" switch at the top of the page.',
        ],
      },
    },
  );
  faqActivityFeedEntries.push(
    {
      questionTitle: 'How can I edit a post I created in the Activity Feed?',
      answer: {
        topLines: [
          'Unfortunately, editing a post you created in the Activity Feed is not currently possible.',
          'To make changes, you\'ll need to delete the post and create a new one.',
        ],
      },
    },
  );

  // Forum
  const faqForumEntries: FaqQuestionEntry[] = [];
  faqForumEntries.push(
    {
      questionTitle: 'Who can see what I post in the Forum?',
      answer: {
        topLines: [
          'All users of the application can view the questions or answers you posted in the Forum.',
        ],
      },
    },
  );
  faqForumEntries.push(
    {
      questionTitle: 'How can I delete a question or answer I created in the Forum?',
      answer: {
        topLines: [
          'To delete a question or answer you created in the Forum, access the post page that includes the question or answer and use the delete button provided.',
        ],
      },
    },
  );
  faqForumEntries.push(
    {
      questionTitle: 'How can I edit a question or answer I created in the Forum?',
      answer: {
        topLines: [
          'To edit a question or answer you created in the Forum, access the post page that includes the question or answer and use the delete button provided.',
        ],
      },
    },
  );
  faqForumEntries.push(
    {
      questionTitle: 'How can I search in the Forum?',
      answer: {
        topLines: [
          'You can search in the Forum by using the specific search bar dedicated to the Forum.',
          'You can also filter questions by associated tags.',
        ],
      },
    },
  );
  faqForumEntries.push(
    {
      questionTitle: 'How can I sort questions on the Forum questions page?',
      answer: {
        topLines: [
          'On the Forum questions page, you can sort questions by the following criteria:',
        ],
        steps: [
          'Hottest first.',
          'Newest first.',
          'Oldest first.',
          'Unanswered.',
        ],
      },
    },
  );

  // Messaging
  const faqMessagingEntries: FaqQuestionEntry[] = [];
  faqMessagingEntries.push(
    {
      questionTitle: 'How can I block a user in the chat?',
      answer: {
        topLines: [
          'To block a user in the chat:',
        ],
        steps: [
          'Open the chat window with the user you want to block.',
          'In the profile menu, select the "Block User" option.',
        ],
        bottomLines: [
          'Once blocked, you will no longer receive messages from the user, and they will be unable to contact you through the chat.',
          'If you decide to unblock the user, you will receive the messages sent during the block.',
        ],
      },
    },
  );

  // Search
  const faqSearchEntries: FaqQuestionEntry[] = [];
  if (BRANDING === 'IPLS') {
    faqSearchEntries.push(
      {
        questionTitle: 'How can I find other users\' profile pages?',
        answer: {
          topLines: [
            'To locate other users\' profile pages:',
          ],
          steps: [
            'Utilize the global search bar in the application.',
            'Enter the name or relevant keywords of the user you\'re seeking in the search bar.',
            'Browse through the search results to discover the desired profile page.',
            'For a more refined search, utilize the filtering options available, such as by countries or the user\'s associated company.',
          ],
        },
      },
    );
  } else {
    faqSearchEntries.push(
      {
        questionTitle: 'How can I find other users\' or corporations\' profile pages?',
        answer: {
          topLines: [
            'To locate other users\' or corporations\' profile pages:',
          ],
          steps: [
            'Utilize the global search bar in the application.',
            'Enter the name or relevant keywords of the user or corporation you\'re seeking in the search bar.',
            'Browse through the search results to discover the desired profile page.',
            'For a more refined search, utilize the filtering options available. Use tabs to filter by Individual or Corporation profile pages.',
          ],
        },
      },
    );
  }
  // Privacy
  const faqPrivacyEntries: FaqQuestionEntry[] = [];
  if (BRANDING === 'IPLS') {
    faqPrivacyEntries.push(
      {
        questionTitle: 'Who can access the application?',
        answer: {
          topLines: [
            'Only signed-up users have access to the application.',
            'However, to sign up, approval from the IPLS Group administrators is required.',
          ],
        },
      },
    );
  } else {
    faqPrivacyEntries.push(
      {
        questionTitle: 'Who can access the application?',
        answer: {
          topLines: [
            'Only signed-up users have access to the application.',
          ],
        },
      },
    );
  }
  // Content Reporting
  const faqContentReportingEntries: FaqQuestionEntry[] = [];
  faqContentReportingEntries.push(
    {
      questionTitle: 'How can I report inappropriate content?',
      answer: {
        topLines: [
          'You can report inappropriate content by using the "Contact Us" section from the top right menu.',
          'Our team will handle your report promptly.',
        ],
      },
    },
  );

  // Corporation Administration
  const faqCorporationAdministrationEntries: FaqQuestionEntry[] = [];
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I alternate between the IPLS Group profile and my individual profile?',
        answer: {
          topLines: [
            'To transition between the IPLS Group profile and your individual profile, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Select the "Switch Profile" option.',
          ],
          bottomLines: [
            'Depending on the chosen profile, all activities performed within the app (such as creating feed posts, forum posts, managing contacts, following users, messaging, etc.) will be executed either on behalf of the IPLS Group or your personal profile.',
            'Note that only IPLS Group administrators are able to switch between profiles; IPLS Group members can only act as their individual profile.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I alternate between the Corporation profile and my Individual profile?',
        answer: {
          topLines: [
            'To transition between the Corporation profile and your Individual profile, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Select the "Switch Profile" option.',
          ],
          bottomLines: [
            'Depending on the chosen profile, all activities performed within the app (such as creating feed posts, forum posts, managing contacts, following users, messaging, etc.) will be executed either on behalf of the Corporation or your Individual profile.',
            'Note that only corporation administrators are able to switch between profiles; Corporation members can only act as their Individual profile.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the name of the IPLS Group?',
        answer: {
          topLines: [
            'To edit the name of the IPLS Group, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Access the settings section.',
            'Navigate to the IPLS Group details section.',
            'Change the name as desired.',
            'Click "Update" to save the changes.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the name of the Corporation?',
        answer: {
          topLines: [
            'To edit the name of the Corporation, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Access the settings section.',
            'Navigate to the Corporation details section.',
            'Change the name as desired.',
            'Click "Update" to save the changes.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I add a member to my IPLS Group?',
        answer: {
          topLines: [
            'To add a member to your IPLS Group, follow these steps:',
          ],
          steps: [
            'Access the top-right menu.',
            'Go to the settings section.',
            'In the "Add New Members" panel, click the "Add Member" button.',
            'Fill in the required details, and the member will receive an invitation.',
          ],
          bottomLines: [
            'Once the invitation is accepted, the member will have access to the platform and will appear in the members list.',
            'In this section, you can view sent invitations and resend emails or cancel invitations as needed.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I add a member to my Corporation?',
        answer: {
          topLines: [
            'To add a member to your Corporation, follow these steps:',
          ],
          steps: [
            'Access the top-right menu.',
            'Go to the settings section.',
            'In the "Add New Members" panel, click the "Add Member" button.',
            'Fill in the required details, and the member will receive an invitation.',
          ],
          bottomLines: [
            'Once the invitation is accepted, the member will have access to the platform and will appear in the members list.',
            'In this section, you can view sent invitations and resend emails or cancel invitations as needed.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I remove a member from my IPLS Group?',
        answer: {
          topLines: [
            'To remove a member from your IPLS Group, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the settings section.',
            'In the list of existing members, locate the member you want to remove.',
            'Use the delete button next to their name and confirm the action.',
          ],
          bottomLines: [
            'Once removed, their account will be permanently deleted from the application without the option to recover it.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I remove a member from my Corporation?',
        answer: {
          topLines: [
            'To remove a member from your Corporation, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the settings section.',
            'In the list of existing members, locate the member you want to remove.',
            'Use the delete button next to their name and confirm the action.',
          ],
          bottomLines: [
            'Once removed, their account will be switched to an Individual account.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How do I assign or remove IPLS Group administrator rights for members?',
        answer: {
          topLines: [
            'To assign or revoke IPLS Group administrator rights for members, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the settings section.',
            'In the members list section, you can grant or revoke administrator rights.',
          ],
          bottomLines: [
            'Note: Each IPLS Group must have at least one administrator.',
            'If an account is the sole administrator of the IPLS Group, they must assign administrator rights to another user before their own account can be removed.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How do I assign or remove Corporation administrator rights for members?',
        answer: {
          topLines: [
            'To assign or revoke Corporation administrator rights for members, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the settings section.',
            'In the members list section, you can grant or revoke administrator rights.',
          ],
          bottomLines: [
            'Note: Each Corporation must have at least one administrator.',
            'If an account is the sole administrator of the Corporation, they must assign administrator rights to another user before their own account can be removed.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How do I know when a member of my IPLS Group edits their profile?',
        answer: {
          topLines: [
            'As an IPLS Group administrator, you will receive a notification in your Group\'s Activity Feed page when a member edits their profile.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How do I know when a member of my Corporation edits their profile?',
        answer: {
          topLines: [
            'As a Corporation administrator, you will receive a notification in your Corporation\'s Activity Feed page when a member edits their profile.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the profile of the members of my IPLS Group?',
        answer: {
          topLines: [
            'As an IPLS Group administrator, you can edit the profile of members by accessing their profile page.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the profile of the members of my Corporation?',
        answer: {
          topLines: [
            'As a Corporation administrator, you can edit the profile of members by accessing their profile page.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'Who can see what I post in my IPLS Group\'s Activity Feed page?',
        answer: {
          topLines: [
            'Any content posted in the IPLS Group\'s Activity Feed will be visible to all users in their individual feeds.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'Who can see what I post in my Corporation\'s Activity Feed page?',
        answer: {
          topLines: [
            'Any content posted in the Coropration\'s Activity Feed will be visible to all users following the Corporation.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I view the user activity of the members of my IPLS Group?',
        answer: {
          topLines: [
            'To view the user activity of your IPLS Group members, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the statistics section.',
          ],
          bottomLines: [
            'In this section, you will find a graph displaying the number of registered users and the number of active users over a specific period of time, exclusively for users in your IPLS Group.',
          ],
        },
      },
    );
  } else {
    faqCorporationAdministrationEntries.push(
      {
        questionTitle: 'How can I view the user activity of the members of my Corporation?',
        answer: {
          topLines: [
            'To view the user activity of your Corporation members, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the statistics section.',
          ],
          bottomLines: [
            'In this section, you will find a graph displaying the number of registered users and the number of active users over a specific period of time, exclusively for users in your Corporation.',
          ],
        },
      },
    );
  }
  // Global Administration
  const faqGlobalAdministrationEntries: FaqQuestionEntry[] = [];
  faqGlobalAdministrationEntries.push(
    {
      questionTitle: 'How can I moderate content in the Forum?',
      answer: {
        topLines: [
          'As a super administrator, you can edit or remove any question or answer in the forum.',
          'To do so, access the specific post page where you will see the edit and delete buttons available for you.',
        ],
      },
    },
  );
  faqGlobalAdministrationEntries.push(
    {
      questionTitle: 'How can I delete Feed posts?',
      answer: {
        topLines: [
          'As a super administrator, you can delete any Feed posts by following these steps:',
        ],
        steps: [
          'Access the top right menu.',
          'Go to the "Feed Moderation" section.',
        ],
        bottomLines: [
          'Once there, you will see all created posts and have the option to remove them.',
          'You can also filter the posts by profile page ID and date.',
        ],
      },
    },
  );
  if (BRANDING === 'IPLS') {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the profiles of IPLS Groups and Users?',
        answer: {
          topLines: [
            'As a super administrator, you can edit any profile page within the app. To do so, simply access the profile page of the IPLS Group or user you wish to edit.',
          ],
        },
      },
    );
  } else {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I edit the profiles of Corporations and Individuals?',
        answer: {
          topLines: [
            'As a super administrator, you can edit any profile page within the app. To do so, simply access the profile page of the Corporation or Individual you wish to edit.',
          ],
        },
      },
    );
  }
  if (BRANDING === 'IPLS') {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I manage the settings of an IPLS Group?',
        answer: {
          topLines: [
            'As a super administrator, you can manage the settings of an IPLS Group by following these steps:',
          ],
          steps: [
            'Access the IPLS Group Profile Page.',
            'Use the "Manage IPLS Group" button at the top of the profile page to access the settings.',
          ],
          bottomLines: [
            'From there, you can manage all the settings seamlessly.',
          ],
        },
      },   
    );
  } else {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I manage the settings of a Corporation?',
        answer: {
          topLines: [
            'As a super administrator, you can manage the settings of a Corporation by following these steps:',
          ],
          steps: [
            'Access the Corporation Profile Page.',
            'Use the "Manage Corporation" button at the top of the profile page to access the settings.',
          ],
          bottomLines: [
            'From there, you can manage all the settings seamlessly.',
          ],
        },
      },   
    );
  }
  faqGlobalAdministrationEntries.push(
    {
      questionTitle: 'How can I manage application tags?',
      answer: {
        topLines: [
          'As a super administrator, you can add, edit, or delete tags by following these steps:',
        ],
        steps: [
          'Access the top right menu.',
          'Go to the "Tags Management" section.',
          'From there, you can manage tags as needed.',
        ],
      },
    },
  );
  if (BRANDING === 'IPLS') {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I see the usage statistics of the application?',
        answer: {
          topLines: [
            'As a super administrator, you can view the user activity for all users in the app and filter by a specific IPLS Group. To do this, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the statistics section.',
          ],
          bottomLines: [
            'In this section, you will find a graph displaying the number of registered users and the number of active users over a specific period of time.',
            'You can filter this data to focus on a particular IPLS Group or view it for all users in the app.',
          ],
        },
      },
    );  
  } else {
    faqGlobalAdministrationEntries.push(
      {
        questionTitle: 'How can I see the usage statistics of the application?',
        answer: {
          topLines: [
            'As a super administrator, you can view the user activity for all users in the app and filter by a specific Corporation. To do this, follow these steps:',
          ],
          steps: [
            'Access the top right menu.',
            'Navigate to the statistics section.',
          ],
          bottomLines: [
            'In this section, you will find a graph displaying the number of registered users and the number of active users over a specific period of time.',
            'You can filter this data to focus on a particular Coropration or view it for all users in the app.',
          ],
        },
      },
    ); 
  }

  return { 
    faqAccountEntries, 
    faqProfileEntries, 
    faqUserInteractionEntries, 
    faqActivityFeedEntries,
    faqForumEntries,
    faqMessagingEntries,
    faqSearchEntries,
    faqPrivacyEntries,
    faqContentReportingEntries,
    faqCorporationAdministrationEntries,
    faqGlobalAdministrationEntries,
  };
};

export default useFaqEntries;
