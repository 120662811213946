import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import ModuleLoader from '../../components/ModuleLoader';
import {  useGetServicesModuleQuery } from './servicesAPI';
import ServiceView from './components/ServiceView';

interface ServicesModuleViewProps {
  // eslint-disable-next-line react/no-unused-prop-types
  setEditMode?: () => void,
  inEditMode?: boolean,
}

export default function ServicesModuleView(props: ServicesModuleViewProps) {

  const { inEditMode = false } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const { data: servicesResponse, isLoading, isFetching } = useGetServicesModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: !currentlyLoadedProfileID },
  );

  if (isLoading || isFetching || (! servicesResponse)) {
    return (
      <ModuleLoader />
    );
  }

  return (
    <>
      {
        servicesResponse.services.map((service) => (
          <ServiceView key={service.id} inEditMode={inEditMode} service={service} />
        ))
      }

      <div className="w-full pt-4 border-t" />
    </>
  );
}
