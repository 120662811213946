/* eslint-disable react/destructuring-assignment */

import React, { useCallback, useMemo } from 'react';
import Select, {
  components, GroupBase, InputProps, Props,
} from 'react-select';
import Creatable from 'react-select/creatable';
import { Theme } from 'react-select/dist/declarations/src/types';
import { appColors } from '../tailwind-config-copy';

interface AppSelectProps {
  isCreatable?: boolean,
  isClearable?: boolean,
}

export default function AppSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  >(props: Props<Option, IsMulti, Group> & AppSelectProps) {

  const { isCreatable = false, isClearable } = props;

  const themeCb = (theme: Theme) => ({
    ...theme,
    borderRadius: 0,
    // todo: get colors from conf https://react-select.com/styles#the-styles-prop
    colors: {
      ...theme.colors,
      primary25: appColors.main[200],
      primary50: appColors.main[300],
      primary75: appColors.main[400],
      primary: appColors.main[600],
    },
  });

  const customStyles = {
    // input: (provided: any, state: any) => ({ // styles div wrap, not input
    //   ...provided,
    //   // outline: 'none',
    //   // outline: state.isFocused ? 'none' : '',
    //   boxShadow: 'none !important',
    //   '&:focus': {
    //     boxShadow: 'none !important',
    //   },
    // }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: '0.375rem',
    }),
  };

  const Input = useCallback((inputProps: InputProps<Option, IsMulti, Group>) => <components.Input className="app-select-input" {...inputProps} />, []);

  const componentsMerged = useMemo(() => ({ Input, ...props.components }), [props.components, Input]);

  if (isCreatable) {
    return (
      <Creatable
        {...props}
        components={componentsMerged}
        theme={themeCb}
        styles={customStyles}
      />
    );
  }

  return (
    <Select
      {...props}
      components={componentsMerged}
      theme={themeCb}
      styles={customStyles}
      isClearable={isClearable}
    />
  );
}
