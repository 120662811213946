import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';
import FlashMessages from '../flash-messages/FlashMessages';
import Input from '../../elements/Input';
import {
  RulesForFieldsType, RuleTypes, useFormValidation, ValidationError,
} from '../../helpers/validation';
import { useCancelPreRegisterMutation } from './landingPageAPI';
import { useDocumentTitle } from '../../helpers/hooks';
import Logo from '../../elements/Logo';

type ValidationKeys = 'email';

const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  email: {
    userFriendlyFieldName: 'Email',
    ruleType: RuleTypes.email,
    required: true,
  },
};

const validationErrorState: ValidationError<ValidationKeys> = {
  email: { errorMessage: '' },
};

export default function CancelPreRegister() {

  useDocumentTitle('Cancel pre-Registration');

  const [requestInProgress, seRequestInProgress] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  const history = useHistory();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      email: emailInput,
    },
  );

  const [cancelPreRegistrationRequest] = useCancelPreRegisterMutation();

  const onFormSubmit = async () => {
    if (requestInProgress) return;

    const val = validate();

    if (! val.allValid) { return; }

    seRequestInProgress(true);

    const response = await cancelPreRegistrationRequest({
      email: emailInput,
    });

    if (! Object.prototype.hasOwnProperty.call(response, 'error')) { // on success
      setEmailInput('');
      setSuccessState(true);
    }

    seRequestInProgress(false);

  };

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    const email = params.get('email');

    if (email) {
      setEmailInput(email);
    }
  }, []);

  let content = null;
  if (! successState) {
    content = (

      <>
        <h3 className="p-4 font-bold text-xl">
          Do you wish to cancel your pre-registration? You won&apos;t receive any updates anymore
        </h3>

        <Input
          id="cancel-pre-register-email"
          value={emailInput}
          label="Email"
          placeholder="example@example.com"
          onChange={(e) => {
            setEmailInput(e.target.value);
            onValidationInputChange('email');
          }}
          validationMessage={validationState.email.errorMessage}
        />

        <div className="pt-6 flex justify-end w-full grid-cols-2 gap-4">
          <button
            type="button"
            onClick={() => history.push('/')}
            className="w-full text-base font-semibold p-4 rounded-md border border-main-600 bg-main-600 text-white"
          >
            No
          </button>
          <button
            type="button"
            onClick={onFormSubmit}
            className="w-full text-base text-main-600 font-semibold p-4 rounded-md border border-neutral-200"
          >
            Yes
          </button>
        </div>
      </>
    );
  }

  if (successState) {
    content = (
      <div>
        <p className="p-4 font-bold text-xl">
          You have been unsubscribed.
        </p>
        <div className="pt-6 flex justify-end w-full">
          <Link
            to="/"
            className="inline-flex items-center text-base font-semibold p-4 rounded-md border border-main-600 bg-main-600 text-white"
          >
            <HomeIcon className="w-4 h-4 mr-1" />
            {' '}
            Go back home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="inline-block align-bottom bg-white overflow-hidden transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">

      <div className="mt-12">
        <div className="flex flex-col justify-center items-center">
          <div className="h-20 mb-16">
            <Logo />
          </div>

          <div className="bg-white shadow-sm p-6 rounded-md">
            <div style={{ maxWidth: 495 }}>
              { content }
            </div>
          </div>
        </div>
      </div>

      <FlashMessages />
    </div>
  );
}
