import { RootState } from './store';

export const permissionsSchema = {
  general: {
    canEditCorporateSettings: false,
    canAccessGlobalStatistics: false,
    canModerateFeed: false,
    canManageTags: false,
  },
  forum: {
    canEditQuestions: false,
    canDeleteQuestions: false,

    canUpdateAnswer: false,
    canDeleteAnswer: false,
  },
};

export const getCanEditCorporateSettings = (state: RootState) => state.account.adminPermissions.general.canEditCorporateSettings;
export const getCanAccessGlobalStatistics = (state: RootState) => state.account.adminPermissions.general.canAccessGlobalStatistics;
export const getCanModerateFeed = (state: RootState) => state.account.adminPermissions.general.canModerateFeed;
export const getCanManageTags = (state: RootState) => state.account.adminPermissions.general.canManageTags;

export const getCanEditForumQuestions = (state: RootState) => state.account.adminPermissions.forum.canEditQuestions;
export const getCanDeleteForumQuestions = (state: RootState) => state.account.adminPermissions.forum.canDeleteQuestions;
export const getCanUpdateForumAnswers = (state: RootState) => state.account.adminPermissions.forum.canUpdateAnswer;
export const getCanDeleteForumAnswers = (state: RootState) => state.account.adminPermissions.forum.canDeleteAnswer;
