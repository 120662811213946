import React from 'react';
import { FunnelIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import Button from '../../elements/Button';
import { emptySplitApi } from '../../app/api';
import { useAppDispatch } from '../../app/hooks';
import Input from '../../elements/Input';
import { RootState } from '../../app/store';
import {
  initialState,
  resetFeedModerationFilters,
  setCreatorProfilePageID,
  setEndDate,
  setStartDate,
} from './feedModerationSlice';

export default function FeedModerationFilters() {
  const dispatch = useAppDispatch();

  const {
    getListRequest,
    getListRequest: {
      creatorProfilePageId,
      startDateUtc,
      endDateUtc,
    },
  } = useSelector((state: RootState) => state.feedModeration);

  return (
    <div className="bg-white p-6 shadow rounded-md mt-4">
      <div className="border border-1 px-6 pb-6 mt-4 rounded-lg transition-colors bg-neutral-50">
        <p className="text-xs py-4 font-bold">
          Filters
        </p>
        <div>
          <Input
            value={creatorProfilePageId || ''}
            placeholder="Numeric profile page ID"
            id="moderation-feed-filter-creator-profile-page-id"
            label="Creator profile page ID"
            onChange={(e) => dispatch(setCreatorProfilePageID(e.target.value))}
          />
        </div>
        <div className="mt-4 flex justify-between gap-4">
          <div className="w-full">
            <Input
              value={startDateUtc || ''}
              type="datetime-local"
              placeholder=""
              id="moderation-feed-filter-start-date"
              label="Start date"
              onChange={(e) => dispatch(setStartDate(e.target.value))}
            />
          </div>
          <div className="w-full">
            <Input
              value={endDateUtc || ''}
              type="datetime-local"
              placeholder=""
              id="moderation-feed-filter-end-date"
              label="End date"
              onChange={(e) => dispatch(setEndDate(e.target.value))}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex justify-end gap-2">
        <Button
          onClick={() => dispatch(emptySplitApi.util.invalidateTags(['FeedModerationList']))}
          size="small"
          inverted
          roundedFull
          neutralText
          iconLeft={<ArrowDownCircleIcon className="w-4 h-4 mr-1.5" />}
          description="update posts list"
        />
        <Button
          onClick={() => dispatch(resetFeedModerationFilters())}
          size="small"
          inverted
          roundedFull
          neutralText
          disabled={JSON.stringify(initialState.getListRequest) === JSON.stringify(getListRequest)}
          iconLeft={<FunnelIcon className="w-4 h-4 mr-1.5" />}
          description="clear filters"
        />
      </div>
    </div>
  );
}
