import React, { ReactElement, useState } from 'react';
import { createFilter, MultiValue } from 'react-select';
import { useGetForumTagsQuery } from '../forumAPI';
import { ForumTagResponse } from '../forum-types';
import InputLoader from '../../../elements/InputLoader';
import AppSelect from '../../../elements/AppSelect';
import { MultiValueLabelTag } from '../../profile-page/components/TagSelection';

interface ForumTagsInputProps {
  values: ForumTagResponse[],
  onChange: (newValue: MultiValue<ForumTagResponse>) => void,
  validationMessage?: string,
}

export default function ForumTagsInput(props: ForumTagsInputProps) {
  const [searchTagString, setSearchTagString] = useState('');

  const { values, onChange, validationMessage } = props;

  const { data: tags, isLoading: isLoadingTags } = useGetForumTagsQuery(null);

  const onTagChange = (newValue: MultiValue<ForumTagResponse>) => { // wrapper for extra logic
    onChange(newValue);
  };

  let tagsInput: ReactElement | null = null;
  if (! tags || isLoadingTags) {
    tagsInput = <InputLoader />;
  }

  if (tags && tags.length > 0) {
    tagsInput = (
      <AppSelect
        isMulti
        onInputChange={(input) => setSearchTagString(input)}
        // defaultValue={defaultValues}
        value={values}
        onChange={onTagChange}
        options={tags}
        filterOption={createFilter({ ignoreAccents: false })} // optimization
        placeholder="Start typing..."
        // @ts-ignore
        components={{ MultiValueLabel: MultiValueLabelTag }}
      />
    );
  }

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="px-2 mb-2 text-xs font-semibold text-neutral-700">
        Tags
      </p>

      { tagsInput }

      {
          (tags && validationMessage) && (
          <div className="bg-error-50 p-1 mt-2 rounded-sm">
            <p className="text-xs text-error-600">
              {validationMessage}
            </p>
          </div>
          )
      }
    </div>
  );
}
