import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

export default function PostOnBehalfMessage() {
  const { currentProfile } = useSelector((state: RootState) => state.account);

  if (currentProfile.accountType !== 'corporation') return null;

  return (
    <div className="w-full flex justify-end text-2xs mt-2">
      <p className="rounded-md py-1 px-4 bg-main-25 text-main-500">
        <span className="font-semibold">Remember: </span>
        <span>
          you are posting on behalf of
          {' '}
          {currentProfile.name}
        </span>
      </p>
    </div>
  );
}
