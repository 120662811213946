import React, { MouseEvent } from 'react';
import {
  ChevronDownIcon, PencilIcon, TrashIcon, XMarkIcon,
} from '@heroicons/react/20/solid';
import Button from '../../../../../elements/Button';
import { useAppDispatch } from '../../../../../app/hooks';
import { IService } from '../services-types';
import { resetCreateServiceForm, setDeleteServiceModalData } from '../servicesModuleSlice';

interface ProductViewHeaderProps {
  serviceViewInEditMode: boolean,
  setServiceViewInEditMode: (flag: boolean) => void,
  service: IService,
  inEditMode: boolean,
  open: boolean, // disclosureIsOpen
}

export default function ServiceViewHeader(props: ProductViewHeaderProps) {

  const {
    open, inEditMode,
    service,
    serviceViewInEditMode, setServiceViewInEditMode,
  } = props;

  const dispatch = useAppDispatch();

  const displayName = service.coreServiceName;

  const onDelete = (e: MouseEvent) => {
    e.stopPropagation();

    dispatch(setDeleteServiceModalData({
      service,
      showModal: true,
    }));
  };

  const onEdit = (e: MouseEvent) => {
    if (open) { //  - allow open/close of a tab
      e.stopPropagation();
    }

    setServiceViewInEditMode(true);
  };

  const onCancelEdit = (e: MouseEvent) => {
    if (! open) { //  - allow open/close of a tab
      e.stopPropagation();
    }

    setServiceViewInEditMode(false);
    dispatch(resetCreateServiceForm(service.coreServiceId));
  };

  return (
    <>
      <div className="flex flex-col px-4 py-4 items-start">
        <span className="text-xs text-neutral-700">
          Service:
          {' '}
          {service.parentCoreServiceNames}
        </span>
        <span className="font-semibold text-base text-neutral-800">
          {displayName}
        </span>
      </div>
      <div className="flex flex-row items-center">
        {
          inEditMode && (
            <div className="mr-2.5 flex flex-row">
              <Button
                onClick={onDelete}
                size="small"
                inverted
                redText
                roundedFull
                // disabled
                iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
                description="Delete"
              />
              <div className="ml-2">
                {
                  serviceViewInEditMode ? (
                    <Button
                      onClick={onCancelEdit}
                      size="small"
                      inverted
                      roundedFull
                      // disabled
                      iconLeft={<XMarkIcon className="w-4 h-4 mr-2" />}
                      description="Cancel"
                    />
                  ) : (
                    <Button
                      onClick={onEdit}
                      size="small"
                      inverted
                      roundedFull
                      // disabled
                      iconLeft={<PencilIcon className="w-4 h-4 mr-2" />}
                      description="Edit"
                    />
                  )
                }
              </div>
            </div>
          )
        }
        <ChevronDownIcon
          className={`${open ? 'transform rotate-180' : ''} w-4 h-4 mr-5 text-main-400`}
        />
      </div>
    </>
  );
}
