import { createSlice } from '@reduxjs/toolkit';
import { clone, findIndex, pullAt } from 'lodash';
import {
  Dosage,
  Product, ProductTypes, RegStatusInformation,
} from './products-module.types';
import { FlattenTerritory } from '../business-markets-module/business-markets-module-types';
import { CoreTag } from '../../../tag/tag-types';

export interface ProductsModuleSlice {
  deleteProductModalData: {
    productID: number,
    productName: string,
    showModal: boolean,
  },
  deleteGroupModalData: {
    groupID: number,
    groupName: string,
    showModal: boolean,
    // deleteProducts: boolean,
    productIDs: number[],
  },

  createProductView: {
    showForm: boolean,
  },

  // todo: remove unused items
  createProductFormData: {
    [key: number]: Product,
  },
}

const EMPTY_PRESENTATION = {
  dossages: [],
  presentation: '',
};

const EMPTY_DOSAGE: Dosage = {
  coreDosageForm: {
    dosageFormCode: 0,
    id: 0,
    name: '',
    coreDosageFormClass: undefined,
    coreDosageFormShortname: undefined,
    coreDosageFormStatus: undefined,
  },
  coreStrengthUnit: {
    id: 0,
    name: '',
  },
  moduleProductInnStrengths: [],
};

const initialState: ProductsModuleSlice = {
  deleteProductModalData: {
    productID: 0,
    productName: '',
    showModal: false,
  },
  deleteGroupModalData: {
    groupID: 0,
    groupName: '',
    productIDs: [],
    // deleteProducts: false,
    showModal: false,
  },

  createProductView: {
    showForm: false,
  },

  // used for product creation and edit
  createProductFormData: {
    0: {
      id: 0,
      productName: '',

      activeIngredients: undefined,
      atcGroup: undefined,
      coreAtcCodeId: undefined,
      coreTags: undefined, // = just tags
      description: '',
      holderOwners: [{ authHolderProdOwner: '' }],
      licences: [{ licenceNumber: '' }],
      presentations: [clone(EMPTY_PRESENTATION)],
      productCategories: undefined,

      productGroup: undefined,
      productGroupId: undefined,
      projectName: '',
      regStatusInformation: [],

      productType: ProductTypes.customizedProduct,
    },
  },
};

export const productsModuleSlice = createSlice({
  name: 'productsModule',
  initialState,
  reducers: {
    setDeleteProductModalData: (state, action) => {
      state.deleteProductModalData = action.payload;
    },
    setDeleteGroupModalData: (state, action) => {
      state.deleteGroupModalData = action.payload;
    },
    hideDeleteGroupModalData: (state) => {
      state.deleteGroupModalData.showModal = false;
    },
    resetDeleteGroupModalData: (state) => {
      state.deleteGroupModalData = initialState.deleteGroupModalData;
    },

    // pass productType to create brand new form
    // pass product to create form with data
    displayCreateProductForm: (state, action) => {
      state.createProductView.showForm = action.payload.showForm;

      if (action.payload.productType) {
        state.createProductFormData = {
          ...state.createProductFormData,
          0: {
            ...initialState.createProductFormData[0], // reset data
            productType: action.payload.productType, // set type
          },
        };
      }

      if (action.payload.product) {
        state.createProductFormData = {
          ...state.createProductFormData,
          0: {
            ...action.payload.product, // set data
            id: 0, // reset id
          },
        };
      }
    },

    // called on product edit
    setCreateProductForm: (state, action) => {

      const newForm = clone(action.payload);

      if (! newForm.licences || newForm.licences.length === 0) { // add 1 row for default input
        newForm.licences = [{ licenceNumber: '' }];
      }

      if (! newForm.presentations || newForm.presentations.length === 0) { // add 1 row for default input
        newForm.presentations = [clone(EMPTY_PRESENTATION)];
      }

      if (! newForm.holderOwners || newForm.holderOwners.length === 0) { // add 1 row for default input
        newForm.holderOwners = [{ authHolderProdOwner: '' }];
      }

      state.createProductFormData[action.payload.id] = newForm;
    },

    // called on edit cancel
    resetCreateProductForm: (state, action) => {
      const newFormData = clone(state.createProductFormData);
      delete newFormData[action.payload];

      state.createProductFormData = newFormData;
    },

    /* Form update reducers START */
    updateCreateProductName: (state, { payload: { productID, value } }) => { // productName
      state.createProductFormData[productID].productName = value;
    },
    updateCreateProductDescription: (state, { payload: { productID, value } }) => { // description
      state.createProductFormData[productID].description = value;
    },
    updateCreateProductGroup: (state, { payload: { productID, value } }) => { // groupName + id
      state.createProductFormData[productID].productGroup = {
        productGroupName: value.groupName,
      };

      state.createProductFormData[productID].productGroupId = value.groupID;
    },
    updateCreateProductTags: (state, { payload: { productID, value } }) => {
      state.createProductFormData[productID].coreTags = value.map((tag: CoreTag) => ({
        coreTagId: tag.id,
        coreTag: tag,
      }));
    },

    /* Standard product fields reducers START */
    updateCreateProductProjectName: (state, { payload: { productID, value } }) => { // project name
      state.createProductFormData[productID].projectName = value;
    },
    updateCreateProductRegStatusInfo: (state, { payload: { productID, value: { selectedTerritories, regStatusInfo } } }) => { // reg status info
      const foundRegInfoIndex = findIndex(
        state.createProductFormData[productID].regStatusInformation,
        { statusInfoType: regStatusInfo },
      );

      const newState = state.createProductFormData[productID].regStatusInformation
        .map((info: RegStatusInformation) => {

          if (foundRegInfoIndex !== -1 && info.statusInfoType === regStatusInfo) {
            return {
              statusInfoType: regStatusInfo,
              countries: selectedTerritories.map((t: FlattenTerritory) => ({
                countryId: t.id,
                coreTerritory: {
                  id: t.id,
                  isoAlpha3Code: t.isoAlpha3Code,
                  name: t.territoryName,
                },
              })),
            };
          }

          return info;
        });

      if (foundRegInfoIndex === -1) { // add if doesn't exist
        newState.push({
          statusInfoType: regStatusInfo,
          countries: selectedTerritories.map((t: FlattenTerritory) => ({
            countryId: t.id,
            coreTerritory: {
              id: t.id,
              isoAlpha3Code: t.isoAlpha3Code,
              name: t.territoryName,
            },
          })),
        });
      }

      state.createProductFormData[productID].regStatusInformation = newState;
    },
    updateCreateProductActiveIngredients: (state, { payload: { productID, value } }) => { // active ingredients
      state.createProductFormData[productID].activeIngredients = value;
    },
    updateCreateProductAtcGroup: (state, { payload: { productID, value } }) => { // ATC Group
      state.createProductFormData[productID].atcGroup = value;
      state.createProductFormData[productID].coreAtcCodeId = value.id;
    },

    addAuthHolderOwner: (state, { payload: { productID } }) => { // Add Marketing Holder
      if (state.createProductFormData[productID].holderOwners) {
        state.createProductFormData[productID].holderOwners = [
          // @ts-ignore
          ...state.createProductFormData[productID].holderOwners,
          { authHolderProdOwner: '' },
        ];
      }
    },
    editAuthHolderOwner: (state, { payload: { productID, idx, value } }) => {  // Edit Marketing Holder Owner
      if (state.createProductFormData[productID].holderOwners) {
        // @ts-ignore
        state.createProductFormData[productID].holderOwners[idx] = {
          authHolderProdOwner: value,
        };
      }
    },
    deleteAuthHolderOwner: (state, { payload: { productID, idx } }) => {  // Delete Marketing Holder Owner
      if (state.createProductFormData[productID].holderOwners) {
        const newHoldersList = state.createProductFormData[productID].holderOwners; // will be mutated

        // @ts-ignore
        pullAt(newHoldersList, [idx]);

        state.createProductFormData[productID].holderOwners = newHoldersList;
      }
    },

    // todo: rewrite / delete
    addDosageForm: (state, { payload: { productID, presentationIdx } }) => { // Add Dosage Form
      // @ts-ignore
      if (! state.createProductFormData[productID].presentations[presentationIdx].dossages) {
        // @ts-ignore
        state.createProductFormData[productID].presentations[presentationIdx].dossages = []; // nulls still may cause issues, need to test
      }

      // @ts-ignore
      state.createProductFormData[productID].presentations[presentationIdx].dossages = [
        // @ts-ignore
        ...state.createProductFormData[productID].presentations[presentationIdx].dossages,
        clone(EMPTY_DOSAGE),
      ];
    },
    editDosageForm: (state, { // Add Dosage Form
      payload: {
        productID, presentationIdx, dosageIdx, newValue,
      },
    }) => {
      // @ts-ignore
      state.createProductFormData[productID].presentations[presentationIdx].dossages[dosageIdx] = newValue;
    },
    deleteDosageForm: (state, { payload: { productID, presentationIdx, dosageIdx } }) => { // Delete Dosage Form

      // @ts-ignore
      const newDosagesList = state.createProductFormData[productID].presentations[presentationIdx].dossages; // will be mutated

      // @ts-ignore
      pullAt(newDosagesList, [dosageIdx]);

      // @ts-ignore
      state.createProductFormData[productID].presentations[presentationIdx].dossages = newDosagesList;
    },

    addPresentation: (state, { payload: { productID } }) => { // Add Presentation
      if (state.createProductFormData[productID].presentations) {
        state.createProductFormData[productID].presentations = [
          // @ts-ignore
          ...state.createProductFormData[productID].presentations,
          clone(EMPTY_PRESENTATION),
        ];
      }
    },
    editPresentation: (state, { payload: { productID, idx, value } }) => {  // Edit Presentation
      if (state.createProductFormData[productID].presentations) {
        // @ts-ignore
        state.createProductFormData[productID].presentations[idx] = value;
      }
    },
    deletePresentation: (state, { payload: { productID, idx } }) => {  // Delete Presentation
      if (state.createProductFormData[productID].presentations) {
        const newPresentationsList = state.createProductFormData[productID].presentations; // will be mutated

        // @ts-ignore
        pullAt(newPresentationsList, [idx]);

        state.createProductFormData[productID].presentations = newPresentationsList;
      }
    },

    addLicenceNumber: (state, { payload: { productID } }) => { // Add Licence Number
      if (state.createProductFormData[productID].licences) {
        state.createProductFormData[productID].licences = [
          // @ts-ignore
          ...state.createProductFormData[productID].licences,
          { licenceNumber: '' },
        ];
      }
    },
    editLicenceNumber: (state, { payload: { productID, idx, value } }) => {  // Edit Licence Number
      if (state.createProductFormData[productID].licences) {
        // @ts-ignore
        state.createProductFormData[productID].licences[idx] = {
          licenceNumber: value,
        };
      }
    },
    deleteLicenceNumber: (state, { payload: { productID, idx } }) => {  // Delete Licence Number
      if (state.createProductFormData[productID].licences) {
        const newLicences = state.createProductFormData[productID].licences; // will be mutated

        // @ts-ignore
        pullAt(newLicences, [idx]);

        state.createProductFormData[productID].licences = newLicences;
      }
    },
    updateCreateProductCategories: (state, { payload: { productID, value } }) => { // product categories
      state.createProductFormData[productID].productCategories = value;
    },
    /* Standard product fields reducers END */

    /* Form update reducers END */
  },
});

export const {
  setDeleteProductModalData,

  setDeleteGroupModalData,
  resetDeleteGroupModalData,
  hideDeleteGroupModalData,

  displayCreateProductForm,
  setCreateProductForm,
  resetCreateProductForm,

  updateCreateProductName,
  updateCreateProductDescription,
  updateCreateProductGroup,
  updateCreateProductTags,
  updateCreateProductRegStatusInfo,

  updateCreateProductProjectName,
  updateCreateProductActiveIngredients,
  updateCreateProductAtcGroup,
  updateCreateProductCategories,

  addAuthHolderOwner,
  editAuthHolderOwner,
  deleteAuthHolderOwner,

  addDosageForm,
  editDosageForm,
  deleteDosageForm,
  addPresentation,
  editPresentation,
  deletePresentation,

  addLicenceNumber,
  editLicenceNumber,
  deleteLicenceNumber,

} = productsModuleSlice.actions;

export default productsModuleSlice.reducer;
