import { useState } from 'react';

export enum RuleTypes {
  email = 'email',
  text = 'text',
  number = 'number',
  flag = 'flag',
  array = 'array',
}

const rulesByType = {
  [RuleTypes.text]: {
    regex: /([^\\s]*)/,
    message: (fieldName: string) => `${fieldName} must be a text`,
  },
  [RuleTypes.number]: {
    regex: /^-?\d+\.?\d*$/,
    message: (fieldName: string) => `${fieldName} must be a number`,
  },
  [RuleTypes.email]: {
    regex: /@/i,
    message: (fieldName: string) => `${fieldName} must be a valid email`,
  },
  [RuleTypes.flag]: {
    // regex: /true|false/gi,
    regex: /^true$/gi,
    message: (fieldName: string) => `${fieldName} must be set`,
  },
  [RuleTypes.array]: {
    regex: null,
    message: (fieldName: string) => `${fieldName} must be set`,
  },
};

// NOTE: keys are field names
type ValidateFormItems<T extends string> = {
  [key in T]: string | number | boolean | object | null
};

export type RulesForFieldsType<T extends string> = {
  [key in T]: {
    userFriendlyFieldName: string,
    ruleType: RuleTypes,
    required: boolean,
    minLength?: number,
    maxLength?: number,
  }
};

export type ValidationError<T extends string> = {
  [key in T]: { errorMessage: string }
};

type ValidateReturnType<KeysType extends string> = {
  allValid: boolean,
  validationErrors: ValidationError<KeysType>,
};

type HookValidateCallback<KeysType extends string> = () => ValidateReturnType<KeysType>;
type OnValidationInputChange<KeysType extends string> = (fieldKey: KeysType) => void;

export const validateForm = <KeysType extends string>(
  items: ValidateFormItems<KeysType>,
  rules: RulesForFieldsType<KeysType>,
): ValidateReturnType<KeysType> => {
  const itemNames = Object.keys(items) as KeysType[];

  const validationErrors = {} as ValidationError<KeysType>;
  for (let idx = 0; idx < itemNames.length; idx += 1) { // set all keys
    const fieldName = itemNames[idx];
    validationErrors[fieldName] = { errorMessage: '' };
  }

  let allValid = true;
  for (let idx = 0; idx < itemNames.length; idx += 1) {
    const fieldName = itemNames[idx];
    const ruleForValue = rules[fieldName];

    const value = items[fieldName];
    const validateByRule = rulesByType[ruleForValue.ruleType];

    // handle boolean separately cuz causes some bugs
    // may  be removed in the future (use last if() entry)
    // issue is that regex for boolean doesn't work properly - todo: investigate
    if (ruleForValue.ruleType === RuleTypes.flag) {

      if (ruleForValue.required && ! value) { // value is boolean; regex temporary not used
        allValid = false;

        validationErrors[fieldName] = {
          errorMessage: validateByRule.message(ruleForValue.userFriendlyFieldName),
        };
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    if (ruleForValue.ruleType === RuleTypes.array) {
      if (ruleForValue.required && (! Array.isArray(value) || value.length <= 0)) {
        allValid = false;

        validationErrors[fieldName] = {
          errorMessage: validateByRule.message(ruleForValue.userFriendlyFieldName),
        };
      }

      // eslint-disable-next-line no-continue
      continue;
    }

    if (value && ruleForValue.minLength && value.toString().length < ruleForValue.minLength) {
      allValid = false;

      validationErrors[fieldName] = {
        errorMessage: `Minimum ${ruleForValue.minLength} characters`,
      };

      // eslint-disable-next-line no-continue
      continue;
    }

    if (value && ruleForValue.maxLength && value.toString().length > ruleForValue.maxLength) {
      allValid = false;

      validationErrors[fieldName] = {
        errorMessage: `Maximum ${ruleForValue.maxLength} characters`,
      };

      // eslint-disable-next-line no-continue
      continue;
    }

    if (ruleForValue.required && ! value) {
      allValid = false;

      validationErrors[fieldName] = {
        errorMessage: 'This information is required',
      };

      // eslint-disable-next-line no-continue
      continue;
    }

    if (value && validateByRule.regex && ! validateByRule.regex.test(value.toString())) {
      allValid = false;

      validationErrors[fieldName] = {
        errorMessage: validateByRule.message(ruleForValue.userFriendlyFieldName),
      };
    }
  }

  return {
    allValid,
    validationErrors,
  };
};

/**
 * Used for simple "one-level" forms without nesting dependencies
 *
 * TODO: optimize with useMemo()
 */
export const useFormValidation = <KeysType extends string>(
  rules: RulesForFieldsType<KeysType>,
  validationState: ValidationError<KeysType>,
  values: ValidateFormItems<KeysType>,
): [ValidationError<KeysType>, HookValidateCallback<KeysType>, OnValidationInputChange<KeysType>] => {
  const [validationCurrentState, setValidation] = useState<ValidationError<KeysType>>(validationState);

  if (! rules || ! validationState || ! values) {
    console.warn('useFormValidation() not enough parameters');

    return [
      validationCurrentState,
      () => validateForm({} as ValidateFormItems<any>, {}),
      (fieldKey: KeysType) => {},
    ];
  }

  const validateFunc = () => {
    const val = validateForm<KeysType>(values, rules);
    setValidation(val.validationErrors);

    return val;
  };

  const onValidationInputChange = (fieldKey: KeysType) => {
    const v = validationCurrentState;
    v[fieldKey] = { errorMessage: '' };

    setValidation(v);
  };

  return [
    validationCurrentState,
    validateFunc,
    onValidationInputChange,
  ];
};
