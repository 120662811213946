import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAvatar from '../../elements/UserAvatar';
import { ProfilePageType } from '../../app/account';
import PostMenu from './PostMenu';
import PostDeleteOverlay from './PostDeleteOverlay';
import { RootState } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { getCanModerateFeed } from '../../app/permissionsSchema';
import RePostOverlay from './RePostOverlay';

export interface PostWrapPropsGeneric {
  postID?: number,
  accountType: ProfilePageType,
  avatarURL: string,
  userName:  string,
  timestamp: string,
  profilePageId: number,
  relatedCorporationName: string | null | undefined,
  relatedCorporationProfilePageId: number | null | undefined,
  showCompany: boolean,
  company: string,
  showJobPosition: boolean,
  jobPosition: string,
}

interface PostWrapProps extends PostWrapPropsGeneric {
  children: React.ReactNode,
}

export default function PostWrap(props: PostWrapProps) {

  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const canModerateFeed = useAppSelector(getCanModerateFeed);

  const {
    postID,
    children,
    avatarURL,
    userName,
    timestamp,
    accountType,
    profilePageId,
    relatedCorporationName,
    relatedCorporationProfilePageId,
    showCompany,
    company,
    showJobPosition,
    jobPosition,
  } = props;

  const [deleteMode, setDeleteMode] = useState(false);
  const [repostMode, setRepostMode] = useState(false);

  const repostAllowed = (accountType === ProfilePageType.corporation) && (profilePageId === requesterID);

  return (
    <div className="relative overflow-hidden mb-2">
      { postID && <PostDeleteOverlay postID={postID} isDeleteMode={deleteMode} setDeleteMode={setDeleteMode} /> }
      { postID && <RePostOverlay postID={postID} isRePosteMode={repostMode} setRePostMode={setRepostMode} /> }
      
      <div className="bg-white pt-12 pl-9 pb-5 pr-12 shadow shadow-lg rounded rounded-lg">
        <div className="flex flex-row w-full">

          {/* Avatar */}
          <UserAvatar
            profilePageType={accountType}
            profileImageUrl={avatarURL}
            profileID={profilePageId}
          />

          {/* Post */}
          <div className="flex flex-col pl-2 w-full">
            <div className="flex justify-between">
              <div>
                <span className="text-xs text-neutral-400 pb-1">{DateTime.fromISO(timestamp).toRelative()}</span>
                <div className="mb-0.5">
                  <Link to={`/profile/${profilePageId}`} className="max-w-fit">
                    <span className="text-sm font-bold hover:underline">{userName}</span>
                  </Link>
                </div>
                { /* show company set in dynamic fields */ }
                {
                  showCompany && company && (
                    <p className="text-sm font-semibold text-neutral-600 table-cell">
                      {company}
                    </p>
                  )
                }
                { /* show job position set in dynamic fields if company is also set */ }
                {
                  showCompany && company && showJobPosition && jobPosition && (
                    <p className="text-xs font-semibold text-neutral-600">
                      {jobPosition}
                    </p>
                  )
                }
                { /* show related corporation name */ }
                {
                    !showCompany && accountType === ProfilePageType.individual && relatedCorporationName && (
                    <Link to={`/profile/${relatedCorporationProfilePageId}`} className="max-w-fit">
                      <p className="text-xs text-neutral-500 hover:underline">{relatedCorporationName}</p>
                    </Link>
                    )
                }
              </div>
              { /* POST MENU OPTIONS */ } 
              {
                postID && ((requesterID === profilePageId) || canModerateFeed) && (
                  <PostMenu 
                    setDeleteMode={setDeleteMode}
                    setRepostMode={setRepostMode}
                    repostAllowed={repostAllowed}
                  />
                )
              }
            </div>
            {/* Min height for delete modal look nice */}
            <div style={{ minHeight: 100 }} className="pt-4">
              { children }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PostWrap.propTypes = {
  children: PropTypes.element,
};

PostWrap.defaultProps = {
  children: null,
};
