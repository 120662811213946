import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'luxon';
import FullscreenPopup from '../../elements/FullscreenPopup';
import { useGetArticleQuery } from './blogAPI';
import ModuleLoader from '../profile-page/components/ModuleLoader';

interface ArticleProps {
  modalIsOpen: boolean,
  setModalIsOpen: (flag: boolean) => void,
  articleID: number,
}

export default function Article(props: ArticleProps) {

  const { setModalIsOpen, modalIsOpen, articleID } = props;

  const { data, isLoading, isFetching } = useGetArticleQuery({ articleID });

  let content = null;
  if (! data) {
    content = (
      <div className="flex flex-row justify-center pb-4 w-full">
        <span className="text-neutral-300 text-5xl uppercase font-semibold mt-8">
          No content to display
        </span>
      </div>
    );
  }

  if (isLoading || isFetching) {
    content = <ModuleLoader />;
  }

  if (data) {
    const date = `${data.createdAtUtc}+00:00`;

    content = (
      <div className="relative z-20">
        <div className="flex flex-col items-center">
          <p className="text-sm text-gray-500">
            <time dateTime={date}>{ DateTime.fromISO(date).toRelative() }</time>
          </p>

          <h3 className="text-4xl font-bold text-neutral-700 max-w-xl">
            {data.title}
          </h3>
        </div>
        <div
          className="text-left mt-20"
          style={{}}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    );
  }

  return (
    <FullscreenPopup
      open={modalIsOpen}
      setOpen={(flag) => setModalIsOpen(flag)}
    >
      <div className="inline-block align-bottom bg-white overflow-x-hidden overflow-y-scroll transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">
        <div className="block absolute top-0 left-0 pt-12 pl-12">
          <button
            type="button"
            className="text-neutral-800 hover:text-neutral-600 focus:outline-none"
            onClick={() => setModalIsOpen(false)}
          >
            <span className="sr-only">Close</span>
            <div className="flex flex-row items-center">
              <ArrowLeftIcon className="h-6 w-6 " aria-hidden="true" />
              <span className="pl-3 font-bold text-xs">
                Back
              </span>
            </div>
          </button>
        </div>

        <div className="mt-12 flex justify-center">
          <div className="max-w-3xl" style={{ minWidth: '70vw' }}>
            { content }
          </div>

          {/* <PatternBackground /> */}
        </div>
      </div>
    </FullscreenPopup>
  );
}
