import { Configuration, RedirectRequest } from '@azure/msal-browser';

export const API_URL = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1` : '';

const BASE_URL = process.env.REACT_APP_BASE_AUTH_URL
  ? process.env.REACT_APP_BASE_AUTH_URL
  : window.location.origin;

// B2C Authority
const AUTHORITY_URL         = process.env.REACT_APP_AUTHORITY_URL || '';
const FORGOT_PASS_AUTHORITY = process.env.REACT_APP_FORGOT_PASS_AUTHORITY || '';

const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || '';

if (! CLIENT_ID) {
  // eslint-disable-next-line no-console
  console.warn('Client ID for AUTH must be set');
}

const knownAuthority = process.env.REACT_APP_KNOWN_AUTHORITY || '';
// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY_URL,

    redirectUri: BASE_URL,
    postLogoutRedirectUri: BASE_URL,

    knownAuthorities: [
      knownAuthority,
    ],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const loginScope = process.env.REACT_APP_AUTH_SCOPE || '';
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  // https://devadb2ctime2pharma.onmicrosoft.com/time2pharma_dev/dev.read
  scopes: ['openid', 'offline_access', loginScope],

};

const forgotPassScope: RedirectRequest = {
  // scopes: ['openid', 'offline_access'],
  scopes: [],

};

export const forgotPasswordRequest: RedirectRequest = { authority: FORGOT_PASS_AUTHORITY, ...forgotPassScope };

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};
