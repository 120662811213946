/* eslint-disable react/require-default-props */
import React, { ReactElement, MouseEvent } from 'react';

interface ButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void,
  inverted?: boolean,
  fullRed?: boolean,
  redText?: boolean,
  neutralText?: boolean,
  roundedFull?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  styles?: string,
  size: 'big' | 'medium' | 'x-medium' | 'small'
  description: string,
  iconLeft?: ReactElement,
  iconRight?: ReactElement,
}

export default function Button(props: ButtonProps) {

  const {
    size, onClick, description,
    inverted = false,
    redText = false,
    neutralText = false,
    roundedFull = false,
    disabled,
    iconLeft,
    iconRight,
    fullRed = false,
    isLoading = false,
    styles,
  } = props;

  if (! description && ! iconLeft) {
    console.warn('Button component: No content');
    return null;
  }

  const containerClass  = size === 'big' ? 'w-60' : '';

  let buttonSizeClass = (size === 'big' || size === 'medium') ? 'p-4 text-base' : 'px-4 p-2 text-xs';
  if (size === 'x-medium') {
    buttonSizeClass = 'px-4 p-2 text-sm';
  }

  buttonSizeClass = description ? buttonSizeClass : 'p-2'; // icon only button

  let styleClasses = inverted ? 'border-neutral-300 text-main-600 bg-white hover:bg-neutral-25' : 'border-main-600 bg-main-600 text-white';
  if (fullRed) {
    styleClasses = inverted ? 'border-error-25 bg-error-25 text-error-600' : 'border-error-600 bg-error-600 text-white';
  }

  styleClasses = disabled ? `pointer-events-none opacity-50 ${styleClasses}` : styleClasses;

  styleClasses = isLoading ? `pointer-events-none animate-pulse ${styleClasses}` : styleClasses;

  styleClasses = redText ? `${styleClasses} text-error-600` : styleClasses;
  styleClasses = neutralText ? `${styleClasses} text-neutral-600` : styleClasses;
  // styleClasses = roundedFull ? `${styleClasses} rounded-full` : styleClasses;
  // styleClasses = roundedFull ? `${styleClasses} rounded-full` : styleClasses;

  // add animation
  styleClasses = `${styleClasses} transition-colors`;
  styleClasses = `${styleClasses} ${styles}`;

  return (
    <div className={containerClass}>
      <button
        type="button"
        onClick={onClick}
        className={`w-full font-semibold ${roundedFull ? 'rounded-full' : 'rounded-md'} border flex flex-row items-center justify-center ${buttonSizeClass} ${styleClasses}`}
      >
        {
          iconLeft && iconLeft
        }
        { description }
        {
          iconRight && (
            <div className="pl-1">
              { iconRight }
            </div>
          )
        }
      </button>
    </div>
  );
}
