import React, { KeyboardEvent, useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from '../../app/hooks';
import {
  initialState,
  resetForumFilters,
  resetForumSearch,
  setSearchString,
} from './questions-list/forumQuestionsSlice';
import EnterIcon from '../../elements/icons/EnterIcon';
import { RootState } from '../../app/store';
import ForumFilters from './ForumFilters';
import Button from '../../elements/Button';

export default function ForumSearch() {
  const dispatch = useAppDispatch();

  const [focused, setFocused] = useState(false);

  const {
    getListRequest,
    getListWithSearchRequest: { searchInput },
  } = useSelector((state: RootState) => state.forum.questionsList);

  const [localSearchString, setLocalSearchString] = useState(searchInput);

  const onSearchSubmit = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;
    if (localSearchString === searchInput) return;

    dispatch(setSearchString(localSearchString));
  };

  return (
    <div className="bg-white p-6 shadow shadow-md rounded rounded-md mt-4">

      <div className="relative">
        <input
          className={`text-xs p-3.5 pl-10 w-full rounded-full focus:outline-none border border-neutral-300 transition-colors ${focused ? 'bg-white' : 'bg-neutral-50'}`}
          id="forum-search-input"
          placeholder="Search"
          onChange={(e) => setLocalSearchString(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={localSearchString}
          onKeyUp={onSearchSubmit}
        />
        <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center">
          {
            focused
              ? <EnterIcon className="h-4 w-4 text-main-600" aria-hidden="true" />
              : <MagnifyingGlassIcon className="w-4 h-4 text-main-600" />
          }
        </div>
      </div>

      <ForumFilters />

      <div className="mt-2 flex justify-end gap-2">
        <Button
          onClick={() => {
            dispatch(resetForumSearch());
            setLocalSearchString('');
          }}
          size="small"
          inverted
          roundedFull
          neutralText
          disabled={(! localSearchString) && (! searchInput)}
          iconLeft={<MagnifyingGlassIcon className="w-4 h-4 mr-1.5" />}
          description="clear search"
        />
        <Button
          onClick={() => dispatch(resetForumFilters())}
          size="small"
          inverted
          roundedFull
          neutralText
          disabled={JSON.stringify(initialState.getListRequest) === JSON.stringify(getListRequest)}
          iconLeft={<FunnelIcon className="w-4 h-4 mr-1.5" />}
          description="clear filters"
        />
      </div>
    </div>
  );
}
