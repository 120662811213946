import React, { useEffect } from 'react';
import { html } from './Nucleo/PrivacyPolicy_gonucleo_and_GA_EN';
import { useDocumentTitle } from '../../../helpers/hooks';
import { BRANDING } from '../../../helpers/branding';
import { privacyPolicyHtmlIplsEn } from './IPLS/PrivacyPolicy_IPLS_EN';

export default function PrivacyPolicyPage() {
  useDocumentTitle('Privacy Policy');

  useEffect(() => {
    if (BRANDING === 'IPLS') {
      window.location.href = 'https://ipls.online/privacy-policy';
    }
  }, []);

  if (BRANDING === 'NUCLEO') {
    return <iframe title="Privacy Policy" style={{ width: '100%', height: '100vh' }} srcDoc={html} />;
  }
  
  return null;
}
