import React from 'react';
import { Link } from 'react-router-dom';
import { ChatBubbleOvalLeftEllipsisIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

interface ChatButtonProps {
  profilePageID?: number,
}

export default function ChatButton(props: ChatButtonProps) {

  const { profilePageID } = props;

  if (! profilePageID) {
    return (
      <span className="text-neutral-400 hover:text-neutral-500 p-3">
        <span className="sr-only">Chat with team member disabled</span>
        <ArrowTopRightOnSquareIcon
          className="h-5 w-5 text-neutral-300"
        />
      </span>
    );
  }

  return (
    <Link
      to={`/profile/${profilePageID}`}
      className="p-3 inline"
    >
      <span className="text-main-600 hover:text-neutral-500">
        <span className="sr-only">Chat with team member</span>
        <ArrowTopRightOnSquareIcon
          className="h-5 w-5 text-main-600"
        />
      </span>
    </Link>
  );

}
