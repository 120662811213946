import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclosure, Transition } from '@headlessui/react';
import { IService } from '../services-types';
import { RootState } from '../../../../../app/store';
import { useAppDispatch } from '../../../../../app/hooks';
import ServiceDetailsView from './ServiceDetailsView';
import ServiceViewHeader from './ServiceViewHeader';
import { setCreateServiceForm } from '../servicesModuleSlice';
import ServiceCreateEditForm from './ServiceCreateEditForm';

interface ServiceViewProps {
  service: IService
  inEditMode: boolean,
}

export default function ServiceView(props: ServiceViewProps) {
  const { service, inEditMode } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const [isServiceOpen, setIsServiceOpen] = useState(false);

  const [serviceViewInEditMode, setServiceViewInEditMode] = useState(false);

  const dispatch = useAppDispatch();

  let content: ReactElement | null = (
    <ServiceDetailsView service={service} />
  );

  if (serviceViewInEditMode) {
    content = (
      <ServiceCreateEditForm
        formType="edit"
        serviceID={service.coreServiceId}
        setServiceViewInViewMode={() => setServiceViewInEditMode(false)}
      />
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full border-b bg-neutral-50">
            <div
              role="none"
              className="w-full flex flex-row justify-between items-center"
              onClick={() => setIsServiceOpen(! open)}
            >
              <ServiceViewHeader
                serviceViewInEditMode={serviceViewInEditMode}
                setServiceViewInEditMode={async (flag) => {
                  setServiceViewInEditMode(flag);

                  if (flag) {

                    dispatch(setCreateServiceForm(service));
                  }
                }}
                service={service}
                open={open}
                inEditMode={inEditMode}
              />
            </div>
          </Disclosure.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              {content}
              <div className="w-full border-t" />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
