import React from 'react';

import { useSelector } from 'react-redux';
import ChatPreviewList from './ChatPreviewList';
import { ChatClientContext } from '../dashboard-layout/DashboardLayout';
import { RootState } from '../../app/store';

export default function MessagesWidget() {

  const { threads, threadIDs, messageRequestsCount } = useSelector((state: RootState) => state.chatMessages);

  let totalUnreadCount: string | number = threadIDs.reduce((acc, id) => acc + threads[id].unreadCount, 0);
  totalUnreadCount += messageRequestsCount;
  if (totalUnreadCount > 99) totalUnreadCount = '99+';

  return (
    <ChatClientContext.Consumer>
      {
        (chatClient) => (
          <>
            {
              chatClient ? (
                <div className="shadow-sm bg-white rounded-md w-full">
                  <div className="flex flex-row items-center pt-2 pb-2">
                    <span className="text-sm font-bold p-2 pl-4">Messages</span>
                    {
                      Boolean(totalUnreadCount) && (
                        <span className="rounded-full h-5 w-5 flex items-center justify-center bg-error-600 text-white font-semibold text-3xs">
                          {totalUnreadCount}
                        </span>
                      )
                    }
                  </div>
                  <div className="scrollbar-wrapper max-h-96 overflow-y-auto">
                    <ChatPreviewList chatClient={chatClient} />
                  </div>
                  <div className="py-2" />
                </div>
              ) : null
            }
          </>
        )
      }
    </ChatClientContext.Consumer>
  );
}
