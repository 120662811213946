import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { useGetFeedListQuery } from './feedModerationAPI';
import ModuleLoader from '../profile-page/components/ModuleLoader';
import { emptyResponse } from './helpers';
import { RootState } from '../../app/store';
import Pagination from '../../elements/pagination/Pagination';
import { useAppDispatch } from '../../app/hooks';
import { setPage } from './feedModerationSlice';
import Post from '../post/Post';

export default function FeedModerationList() {
  const { getListRequest } = useSelector((state: RootState) => state.feedModeration);

  const { data = emptyResponse, isLoading, isFetching } = useGetFeedListQuery(getListRequest);

  const dispatch = useAppDispatch();

  if (isLoading) {
    return (
      <div className="bg-white shadow shadow-md rounded rounded-md mt-4 p-6">
        <ModuleLoader />
      </div>
    );
  }

  if (! data || data.data.length === 0) {
    return (
      <div className="flex w-full justify-center mt-8">
        <span className="font-semibold text-neutral-600">No posts with given filters</span>
      </div>
    );
  }

  return (
    <div className="relative mt-4">
      {
          isFetching && (
          <div className="flex justify-center mb-4">
            <ArrowPathIcon className="w-6 h-6 animate-spin text-neutral-600" />
          </div>
          )
        }
      {
          data.data.map((post) => (
            <div className="shadow-sm" key={post.data.id}>
              <Post
                postID={post.data.id}
                user={{
                  isCorporateUser: post.data.creator.profilePageAccountType === 'corporation',
                  avatarURL: post.data.creator.profileImageUrl,
                  name: post.data.creator.name,
                  profilePageId: post.data.creator.profilePageId,
                  relatedCorporationName: post.data.creator.relatedCorporationProfilePageSummary?.name,
                  relatedCorporationProfilePageId: post.data.creator.relatedCorporationProfilePageSummary?.profilePageId,
                  showCompany: post.data.creator.showCompany,
                  company: post.data.creator.company,
                  showJobPosition: post.data.creator.showCompany,
                  jobPosition: post.data.creator.jobPosition,
                }}
                timestamp={`${post.createdAtUtc}+00:00`}
                text={post.data.content}
                links={post.data.externalLinks ? post.data.externalLinks.map((link) => ({
                  id: link.id,
                  linkURL: link.link,
                  title: link.description,
                  inEditMode: false,
                })) : []}
                fileAttachmentsFromBackend={post.data.fileAttachments}
                PDFs={[]}
                images={post.data.imageAttachments ? post.data.imageAttachments.map((image) => ({
                  id: image.fileUuid,
                  guid: image.fileUuid,
                  linkURL: '',
                  blobURL: '',
                  title: image.description,
                  inEditMode: false,
                })) : []}
              />
            </div>
          ))
        }

      <div className="rounded-md bg-white p-4 mt-4 shadow-sm">
        <Pagination
          pageSize={data.pageSize}
          currentPage={data.pageNumber}
          totalPages={data.totalPages}
          totalRecords={data.totalRecords}
          onPageChange={(pageNumber) => {
            dispatch(setPage(pageNumber));
            window.scrollTo({
              left: 0,
              top: 0,
              behavior: 'smooth',
            });
          }}
          label="Posts"
        />
      </div>
    </div>
  );
}
