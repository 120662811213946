import React from 'react';

interface ShowingPagesTextProps {
  showingStartCount: number,
  showingEndCount: number,
  totalRecords: number,
  label?: string,
}

export default function ShowingPagesText(props: ShowingPagesTextProps) {
  
  const { 
    showingStartCount, 
    showingEndCount, 
    totalRecords, 
    label = 'results', 
  } = props;
  
  return (
    <div className="text-sm text-neutral-700">
      Showing
      {' '}
      <span className="font-medium">{showingStartCount}</span>
      {' '}
      to
      {' '}
      <span className="font-medium">{showingEndCount}</span>
      {' '}
      of
      {' '}
      <span className="font-medium">{totalRecords}</span>
      {` ${label}`}
    </div>
  );
}
