import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclosure, Transition } from '@headlessui/react';
import { RootState } from '../../../../../app/store';
import { useAppDispatch } from '../../../../../app/hooks';
import { IFocus } from '../focus-types';
import FocusDetailedView from './FocusDetailedView';
import FocusViewHeader from './FocusViewHeader';
import FocusCreateEditForm from './FocusCreateEditForm';
import { setCreateFocusForm } from '../focusModuleSlice';

interface FocusViewProps {
  focus: IFocus
  inEditMode: boolean,
}

export default function FocusView(props: FocusViewProps) {
  const { focus, inEditMode } = props;

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const [isFocusOpen, setIsFocusOpen] = useState(false);

  const [focusViewInEditMode, setFocusViewInEditMode] = useState(false);

  const dispatch = useAppDispatch();

  let content: ReactElement | null = (
    <FocusDetailedView focus={focus} />
  );

  if (focusViewInEditMode) {
    content = (
      <FocusCreateEditForm
        formType="edit"
        focusID={focus.coreFocusCategoryId}
        setFocusViewInViewMode={() => setFocusViewInEditMode(false)}
      />
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full border-b bg-neutral-50">
            <div
              role="none"
              className="w-full flex flex-row justify-between items-center"
              onClick={() => setIsFocusOpen(! open)}
            >
              <FocusViewHeader
                focusViewInEditMode={focusViewInEditMode}
                setFocusViewInEditMode={async (flag) => {
                  setFocusViewInEditMode(flag);

                  if (flag) {
                    dispatch(setCreateFocusForm(focus));
                  }
                }}
                focus={focus}
                open={open}
                inEditMode={inEditMode}
              />
            </div>
          </Disclosure.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              {content}
              <div className="w-full border-t" />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
