import React from 'react';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import ProductsModuleView from './ProductsModuleView';
import Button from '../../../../elements/Button';
import ErrorModal from '../../../../elements/ErrorModal';
import { RootState } from '../../../../app/store';
import { useAppDispatch } from '../../../../app/hooks';
import { displayCreateProductForm, setDeleteProductModalData } from './productsModuleSlice';
import GroupDeleteModal from './components/GroupDeleteModal';
import { ProductTypes } from './products-module.types';
import ProductCreateEditForm from './components/ProductCreateEditForm';
import { useDeleteProductMutation } from './productsModuleAPI';

interface ProductModuleEditProps {
  setEditMode?: () => void,
}

export default function ProductModuleEdit(props: ProductModuleEditProps) {
  const { setEditMode } = props;

  const {
    deleteProductModalData, createProductView,
  } = useSelector((state: RootState) => state.profileModules.productsModule);
  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const [deleteProductRequest, { isLoading: isDeletingProduct }] = useDeleteProductMutation();

  const dispatch = useAppDispatch();

  const productDeleteModalClose = () => {
    dispatch(setDeleteProductModalData({
      productID: 0,
      productName: '',
      showModal: false,
    }));
  };

  const onProductDelete = async () => {
    await deleteProductRequest({
      requesterID,
      profileID: currentlyLoadedProfileID,
      productID: deleteProductModalData.productID,
    });

    productDeleteModalClose();
  };

  return (
    <>
      <div className={`px-2 py-2 flex-row items-center ${createProductView.showForm ? '' : 'bg-neutral-25'}`}>
        {
          createProductView.showForm ? (
            <ProductCreateEditForm formType="create" productID={0} />
          ) : (
            <div className="w-max flex flex-row gap-2">
              <Button
                onClick={() => dispatch(displayCreateProductForm({
                  showForm: true,
                  productType: ProductTypes.standardProduct,
                }))}
                size="small"
                description="Add a standard product"
                inverted
                roundedFull
                iconLeft={<PlusSmallIcon className="w-4 h-4" />}
              />
              <Button
                onClick={() => dispatch(displayCreateProductForm({
                  showForm: true,
                  productType: ProductTypes.customizedProduct,
                }))}
                size="small"
                description="Add a customized product"
                inverted
                roundedFull
                iconLeft={<PlusSmallIcon className="w-4 h-4" />}
              />
            </div>
          )
        }
      </div>
      <div className="w-full border-t" />

      <ProductsModuleView setEditMode={setEditMode} inEditMode />

      <GroupDeleteModal />

      <ErrorModal
        withErrorBorder={false}
        show={deleteProductModalData.showModal}
        setOpen={() => productDeleteModalClose()}
        transparentBackground
        onActionClick={onProductDelete}
        actionButtonText="Delete"
        // imageSrc={avatarUrl}
        title="Delete product?"
        description={`Deleting "${deleteProductModalData.productName}" product is an action that can’t be undone.`}
        isLoading={isDeletingProduct}
      />
    </>
  );
}
