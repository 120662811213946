import React, { useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import ChooseProfile from './ChooseProfile';
import CorporateDetails from './CorporateDetails';
import FoundCorporation from './FoundCorporation';
import SuccessfulRegistration from './SuccessfulRegistration';
import FlashMessages from '../flash-messages/FlashMessages';
import { useDocumentTitle } from '../../helpers/hooks';
import Logo from '../../elements/Logo';

export default function Registration() {
  const [step, setStep] = useState(1);

  // todo: maybe different title for every step?
  useDocumentTitle('Registration');

  return (
    <div className="inline-block align-bottom bg-white overflow-hidden transform transition-all h-screen p-12 bg-neutral-50 sm:align-middle w-full">
      {
        (step > 1 && step !== 4) && (
          <div className="hidden sm:block absolute top-0 left-0 pt-12 pl-12">
            <button
              type="button"
              className="text-neutral-800 hover:text-neutral-600 focus:outline-none"
              onClick={() => setStep(step - 1)}
            >
              <span className="sr-only">Close</span>
              <div className="flex flex-row items-center">
                <ArrowLeftIcon className="h-6 w-6 " aria-hidden="true" />
                <span className="pl-3 font-bold text-xs">
                  Back
                </span>
              </div>
            </button>
          </div>
        )
      }

      <div className="mt-12">
        <div className="flex flex-col justify-center items-center">
          <div className="h-20 mb-16">
            <Logo />
          </div>

          {
            (step === 1) && (<ChooseProfile setNextStep={() => setStep(2)} onCancel={() => {}} />)
          }
          {
            (step === 2) && (<CorporateDetails setNextStep={(s: 3 | 4) => setStep(s)} onCancel={() => setStep(step - 1)} />)
          }
          {
            (step === 3) && (<FoundCorporation setNextStep={() => setStep(4)} onCancel={() => setStep(step - 1)} />)
          }
          {
            (step === 4) && (<SuccessfulRegistration />)
          }
        </div>
      </div>

      <FlashMessages />
    </div>
  );
}
