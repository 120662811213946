import React from 'react';
import { MultiValue } from 'react-select';
import { CoreProductCategory } from '../../products-module.types';
import { useGetProductCategoriesQuery } from '../../productsModuleAPI';
import { useAppDispatch } from '../../../../../../app/hooks';
import InputLoader from '../../../../../../elements/InputLoader';
import AppSelect from '../../../../../../elements/AppSelect';
import { updateCreateProductCategories } from '../../productsModuleSlice';

interface ProductCategoriesProps {
  productID: number,
  productCategories?: CoreProductCategory[],
}

export default function ProductCategories(props: ProductCategoriesProps) {

  const { productCategories, productID } = props;

  const { data, isLoading, isFetching } = useGetProductCategoriesQuery(null);

  const dispatch = useAppDispatch();

  const onChange = (newValue: MultiValue<CoreProductCategory>) => {
    dispatch(updateCreateProductCategories({
      productID,
      value: newValue,
    }));
  };

  const setValues = (): CoreProductCategory[] => {
    if (! productCategories) return [];

    return productCategories.map((c: CoreProductCategory) => ({
      ...c,
      value: c.coreProductCategory.id,
      label: c.coreProductCategory.name,
    }));
  };

  let content = null;
  if (! data || isLoading || isFetching) {
    content = (
      <InputLoader />
    );
  }

  if (data && data.length > 0) {
    content = (
      <AppSelect
        isMulti
        // defaultValue={defaultValues}
        value={setValues()}
        onChange={onChange}
        // filterOption={createFilter({ ignoreAccents: false })} // optimization
        options={data}
        placeholder="Start typing..."
      />
    );
  }

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        Product Categories
      </p>

      <div className="my-2.5">
        { content }
      </div>
    </div>
  );

}
