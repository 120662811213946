import { cloneDeep, isBoolean } from 'lodash';

/**
 * Will set all keys to true
 *
 */
export function setAdminPermissions<PS extends object>(permissionsSchema: PS) {

  const objToSet = cloneDeep(permissionsSchema);

  function setPropsRecursive<T extends object>(branchToWorkWith: T): T {
    const objectToReturn = {} as T;

    if (! branchToWorkWith) return objectToReturn as T;

    const keys: string[] = Object.keys(branchToWorkWith);

    for (let idx = 0; idx < keys.length; idx += 1) {
      const key = keys[idx] as keyof T;

      if (isBoolean(branchToWorkWith[key])) {

        // @ts-ignore
        objectToReturn[key] = true;
        // eslint-disable-next-line no-continue
        continue;
      }

      const branch = branchToWorkWith[key];

      // @ts-ignore
      objectToReturn[key] = setPropsRecursive<T[keyof T]>(branch);
    }

    return objectToReturn;
  }

  return setPropsRecursive<PS>(objToSet);
}
