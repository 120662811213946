import React from 'react';
import DashboardLayout from '../dashboard-layout/DashboardLayout';
import SearchResults from './SearchResults';
import { useDocumentTitle } from '../../helpers/hooks';

export default function SearchResultsPage() {

  useDocumentTitle('Search');

  return (
    <DashboardLayout>
      <SearchResults />
    </DashboardLayout>
  );
}
