import { isNull } from 'lodash';
import { GetFeedModerationListRequest, GetFeedModerationListResponse } from './types';

export const emptyResponse: GetFeedModerationListResponse = {
  data: [],
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalRecords: 0,
};

export const filterNullParams = <T extends object>(requestBody: T): Partial<T> => {

  const keys = Object.keys(requestBody) as Array<keyof typeof requestBody>;

  return keys
    .filter((key) => ! isNull(requestBody[key]))
    .reduce((acc: T, key) => {
      // eslint-disable-next-line no-param-reassign
      acc[key] = requestBody[key];
      return acc;
    }, {} as T);
};
