import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'luxon';
import { MemberRemovedData } from './feed-types';

/* This component is used to indicate that a corporation account has switched to individual account */

interface MemberRemovedNotificationProps {
  timestamp: string,
  data?: MemberRemovedData,
}

export default function MemberRemovedNotification(props: MemberRemovedNotificationProps) {

  const { timestamp, data } = props;

  return (
    <div className="bg-white py-6 px-8 mb-2 shadow shadow-lg rounded rounded-lg">
      <div className="flex flex-row gap-4">

        <ExclamationTriangleIcon className="w-32 h-32 text-warning-400" />

        <div className="flex flex-col">
          <span className="text-neutral-400 text-xs">
            {DateTime.fromISO(timestamp).toRelative() || '-'}
          </span>
          <p className="text-neutral-800 text-sm pt-2.5 font-bold">
            Important information
          </p>
          <p className="pt-4">
            {
              data && (
                <>
                  You no longer belong to the
                  {' '}
                  <Link
                    className="text-main-600 hover:text-main-800 hover:underline font-semibold"
                    to={`/profile/${data.corporationProfilePageId}`}
                  >
                    {data.corporationName}
                    {' '}
                    corporation.
                    {' '}
                  </Link>
                </>
              )
            }
            Your account type was set to individual.
          </p>
        </div>
      </div>
    </div>
  );
}
