/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { cloneDeep } from 'lodash';
import { RootState } from '../../../../app/store';
import {
  addDynamicField, initiateModule, reorder, updateDescription, updateTerritory, updateLinkedin,
} from './aboutModuleSlice';
import { useAppDispatch } from '../../../../app/hooks';
import Textarea from '../../../../elements/Textarea';
import AboutModuleDynamicField from './AboutModuleDynamicField';
import Button from '../../../../elements/Button';
import { useGetAboutModuleQuery, useUpdateAboutModuleMutation } from './aboutModuleAPI';
import AboutModuleLinkFileUpload from './AboutModuleLinkFileUpload';
import { UploadBlob, uploadFileToAzure } from '../../../../app/filesSlice';
import ModuleInfoSupportMessage from '../../components/ModuleInfoSupportMessage';
import MarketsSelection from '../business-markets-module/elements/MarketsSelection';
import { FlattenTerritory } from '../business-markets-module/business-markets-module-types';
import Input from '../../../../elements/Input';
import { useFormValidation } from '../../../../helpers/validation';
import { ValidationKeys, aboutModuleValidationRules, validationErrorState } from './aboutModuleValidationRules';

interface AboutModuleEditProps {
  setViewMode?: () => void
}

export default function AboutModuleEdit(props: AboutModuleEditProps) {

  const { setViewMode } = props;

  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const {
    moduleAboutDynamicFields,
    description,
    fileAttachments,
    externalLinks,
    staticModuleAboutFields,
    showModuleAboutExtraFields,
    territory,
    linkedin,
  } = useSelector((state: RootState) => state.profileModules.aboutModule);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const { data } = useGetAboutModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (! currentlyLoadedProfileID) || (! requesterID) },
  );

  const [updateModuleData] = useUpdateAboutModuleMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (! data) return;

    dispatch(initiateModule(data));
  }, [currentlyLoadedProfileID, dispatch, data]);

  const onDragEnd = (result: any) => {
    if (! result.destination) {
      return;
    }

    dispatch(reorder({
      startIndex: result.source.index,
      endIndex: result.destination.index,
    }));
  };

  const onMarketSelection = (selectedTerritory: FlattenTerritory) => {
    dispatch(updateTerritory(selectedTerritory));
  };

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    aboutModuleValidationRules,
    validationErrorState,
    {
      linkedin,
      description,
    },
  );

  return (
    <>
      { /* DESCRIPTION */ }
      <div className="p-4 flex flex-col items-center justify-center">
        <div className="w-3/5 mt-2">
          <div className="w-full p-2 bg-neutral-25 border rounded-md">
            <p className="p-2 text-xs text-neutral-700">Description</p>
            <div className="mt-2">
              <Textarea
                // todo debounce 300-500ms
                value={description}
                placeholder="Start typing..."
                onChange={(e) => {
                  dispatch(updateDescription(e.target.value));
                  onValidationInputChange('description');
                }}
                validationMessage={validationState.description.errorMessage}                
              />
            </div>
          </div>
        </div>
        { /* EXTRA FIELDS (territory) */ }
        {
          showModuleAboutExtraFields && (
            <div className="w-3/5 mt-2">
              <div className="w-full p-2 bg-neutral-25 border rounded-md">
                <p className="p-2 text-xs text-neutral-700">Country</p>
                <div className="mt-2">
                  <MarketsSelection
                    onChange={onMarketSelection}
                    isMulti={false}
                    value={territory}
                    placeholder="Select a country..."
                    isClearable
                  />
                </div>
              </div>
            </div>
          )
        }
        { /* DYNAMIC/STATIC FIELDS */ }
        <div className="w-3/5 mt-2">
          <div className="w-full p-2 bg-neutral-25 border rounded-md">
            <p className="p-2 text-xs text-neutral-700">Other Fields</p>
            <div className="mt-2">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {[...moduleAboutDynamicFields].map((field, index) => (
                        <Draggable
                          key={field.id}
                          draggableId={field.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="mt-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <AboutModuleDynamicField
                                field={field}
                                dragHandleProps={provided.dragHandleProps}
                                isStatic={staticModuleAboutFields}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {
                !staticModuleAboutFields && (
                  <div className="mt-2 flex flex-row justify-end">
                    <div className="max-w-max">
                      <Button
                        onClick={() => dispatch(addDynamicField())}
                        size="small"
                        iconLeft={<PlusSmallIcon className="w-4 h-4" />}
                        inverted
                        description="Add more info"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {/* LINKEDIN ACCOUNT */}
        {
          showModuleAboutExtraFields && (
            <div className="w-3/5 mt-2">
              <div className="w-full p-2 bg-neutral-25 border rounded-md">
                <p className="p-2 text-xs text-neutral-700">LinkedIn Profile</p>
                <div className="mt-2">
                  <Input
                    value={linkedin}
                    placeholder="https://www.linkedin.com/profile..."
                    onChange={(e) => {
                      dispatch(updateLinkedin(e.target.value));
                      onValidationInputChange('linkedin');
                    }}
                    validationMessage={validationState.linkedin.errorMessage}
                  />
                </div>
              </div>
            </div>
          )
        }
        { /* FILE UPLOAD & LINK */ }
        <div className="w-3/5 mt-2">
          <AboutModuleLinkFileUpload setFile={(file) => setUploadedFile(file)} />
        </div>

        <div className="w-3/5 mt-8 mb-6 flex justify-end">
          <div className="pr-2">
            <Button
              onClick={() => setViewMode && setViewMode()}
              size="medium"
              inverted
              description="Cancel"
              disabled={isLoading}
            />
          </div>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              if (! currentlyLoadedProfileID) return;

              const val = validate();
              if (! val.allValid) return;

              setIsLoading(true);

              // upload file if wasn't uploaded
              const currentFile = cloneDeep(fileAttachments[0]);
              if (! currentFile.fileUuid && uploadedFile) {
                const file = await dispatch(uploadFileToAzure({
                  file: uploadedFile,
                  description: currentFile.description,
                  blobContainer: UploadBlob.moduleAbout,
                  profilePageID: currentlyLoadedProfileID,
                  requesterID,
                }));

                if (! file) {
                  // eslint-disable-next-line no-console
                  console.log('About module upload - no file uuid returned');
                  setIsLoading(false);
                  return;
                }

                // @ts-ignore
                const { id } = file.payload.data;

                currentFile.fileUuid = id;
              }

              let extraFields = null;
              if (showModuleAboutExtraFields) {
                extraFields = {
                  TerritoryId: territory?.id,
                  linkedInAccount: linkedin,
                };
              }

              await updateModuleData({
                id: currentlyLoadedProfileID,
                requesterID,
                body: {
                  description,
                  // @ts-ignore
                  moduleAboutDynamicFields: moduleAboutDynamicFields.map((f) => {
                    const { id, ...rest } = f; // will always create new entries
                    return rest;
                  }),
                  fileAttachments: [currentFile],
                  externalLinks: externalLinks.map((f) => {
                    const { id, ...rest } = f; // will always create new entries
                    return rest;
                  }),
                  extraFields,
                },
              });

              setIsLoading(false);

              if (setViewMode) setViewMode();
            }}
            size="medium"
            description="Save changes"
          />
        </div>
        <ModuleInfoSupportMessage />
      </div>
      <div className="w-full pt-4 border-t" />
    </>
  );
}
