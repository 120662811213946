import React, { useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import ErrorModal from '../../../elements/ErrorModal';
import { useAppDispatch } from '../../../app/hooks';
import Button from '../../../elements/Button';
import { deleteCorporation } from './corporation';
import { useBrandingLocale } from '../../../helpers/hooks';

interface CorporationDeleteProps {
  corporationID: number,
}

export default function CorporationDelete(props: CorporationDeleteProps) {

  const { corporationID } = props;

  const [openModal, setOpenModal] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const { corporationDetails } = useSelector((state: RootState) => state.account);

  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const { t, tk } = useBrandingLocale();

  function handleLogout() {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  const openDeleteAccountModal: () => void = () => {
    setOpenModal(true);
  };

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        {t('Corporation')}
      </h2>

      <div className="mt-4 font-semibold text-sm">
        <p>
          { 
            tk('Delete_Corporation_Message', {
              corporationName: corporationDetails?.name,
            })
          }
        </p>
      </div>

      <div className="flex justify-end mt-4">
        <Button
          onClick={() => openDeleteAccountModal()}
          size="medium"
          inverted
          iconLeft={<ExclamationTriangleIcon className="h-4 w-4 text-error-600 mr-2.5" />}
          description={`Delete ${t('Corporation')}`}
          styles="py-4 px-6 font-semibold text-error-600"
        />
      </div>

      { /* REMOVE CORPORATION CONFIRMATION MODAL */ }
      <ErrorModal
        show={openModal}
        setOpen={setOpenModal}
        actionButtonText={`Delete ${t('Corporation')}`}
        title={`Delete ${t('Corporation')}`}
        isLoading={deletionInProgress}
        onActionClick={async () => {
          setDeletionInProgress(true);

          const resp = await dispatch(deleteCorporation({ corporationID }));

          setDeletionInProgress(false);

          if (resp.type.includes('rejected')) { // error
            return;
          }

          setOpenModal(false);
          handleLogout();
        }}
        description={tk('Delete_Corporation_Message', {
          corporationName: corporationDetails?.name,
        })}
      />
    </div>
  );
}
