import {
  createSlice, PayloadAction, nanoid,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { AppThunk } from '../../app/store';

export interface FlashMessagesState {
  flashMessagesQueue: Array<FlashMessage>
}

export interface FlashMessage {
  type: 'success' | 'warning' | 'error' | 'info' | 'neutral',
  message: string,
  autoDismiss: boolean,
  topRight: boolean,
  callToAction?: {
    text: string,
    action: 'scrollToTop',
  },
  show: boolean, // used for animation (render -> animate)
  id: string
}

const initialState: FlashMessagesState = {
  flashMessagesQueue: [],
};

export const flashMessagesSlice = createSlice({
  name: 'flashMessages',
  initialState,
  reducers: {
    deleteFlashMessage: (state, action: PayloadAction<string>) => {
      state.flashMessagesQueue = state.flashMessagesQueue.filter(({ id }) => id !== action.payload);
    },
    displayMessage: (state, action: PayloadAction<{ id: string, show: boolean }>) => {
      state.flashMessagesQueue = state.flashMessagesQueue.map((flashMessage) => {
        if (flashMessage.id !== action.payload.id) return flashMessage;

        const copy = flashMessage;
        copy.show = action.payload.show;

        return copy;
      });
    },
  },
  extraReducers: {
    'flashMessages/add': (state, action: PayloadAction<FlashMessage>) => {
      state.flashMessagesQueue.push(action.payload);
    },
  },
});

export const { displayMessage, deleteFlashMessage } = flashMessagesSlice.actions;

export const addFlashMessage = (type = 'success', message = '', autoDismiss = true, callToAction: any = undefined, topRight = false): AppThunk => (
  dispatch,
) => {

  const id = nanoid();

  dispatch({
    type: 'flashMessages/add',
    payload: {
      show: false,
      type,
      message,
      autoDismiss,
      callToAction,
      id,
      topRight,
    },
  });

  setTimeout(() => { // for animation; TODO: find better way to wait for render
    dispatch(displayMessage({
      id,
      show: true,
    }));
  }, 300);
};

export const removeFlashMessage = (id: string): AppThunk => (
  dispatch,
) => {

  dispatch(
    displayMessage({
      id,
      show: false,
    }),
  );

  setTimeout(() => {
    dispatch(deleteFlashMessage(id));
  }, 500);

};

export default flashMessagesSlice.reducer;
