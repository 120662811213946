import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import Zoom from 'react-medium-image-zoom';
import securityImg from './assets/security-img.png';

export default function FeatureSecurity() {
  return (
    <div className="z-10 w-full flex flex-col xl:flex-row xl:flex-row-reverse max-w-6xl w-full px-4 md:px-6 xl:px-0 -mt-2">
      <Fade right>
        <div
          className="min-w-fit pr-0 md:pr-10 pl-6 xl:pl-0 my-6 xl:my-0 pt-0 xl:pt-20 xl:ml-8"
        >
          <h3 className="text-main-900 text-5.5xl font-extrabold" style={{ maxWidth: 530 }}>
            Security and privacy
          </h3>
          <p className="text-neutral-700 text-base font-medium mt-6" style={{ maxWidth: 530 }}>
            Trust and rely on Azure’s cloud solution to protect the privacy and confidentiality of your data and communications.
          </p>
        </div>
      </Fade>

      <Fade left>
        <Zoom>
          <img
            src={securityImg}
            className="feature-image"
            alt="Security example"
          />
        </Zoom>
      </Fade>
    </div>
  );
}
