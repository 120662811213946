import React, { KeyboardEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  resetMembersSearch,
  setSearchString,
} from './membersManagementSlice';
import EnterIcon from '../../../elements/icons/EnterIcon';

interface MembersManagementSearchProps {
  scrollOffset: number | null,
}
export default function MembersManagementSearch(props: MembersManagementSearchProps) {

  const { scrollOffset } = props;

  const dispatch = useAppDispatch();
  const [focused, setFocused] = useState(false);
  const [localSearchString, setLocalSearchString] = useState('');

  const {
    getMembersListRequest: { searchInput },
  } = useSelector((state: RootState) => state.corporationMembers);

  const onSearchSubmit = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    if (scrollOffset && ! Number.isNaN(scrollOffset)) {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: scrollOffset,
      });
    }

    if (localSearchString === searchInput) return;

    dispatch(setSearchString(localSearchString));
  };

  return (
    <div className="bg-white py-6">

      <div className="relative">
        <input
          className={`text-xs p-3.5 pl-10 w-full rounded-full focus:outline-none border border-neutral-300 transition-colors ${focused ? 'bg-white' : 'bg-neutral-50'}`}
          id="forum-search-input"
          placeholder="Search"
          onChange={(e) => setLocalSearchString(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={localSearchString}
          onKeyUp={onSearchSubmit}
        />
        <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center">
          {
            focused
              ? <EnterIcon className="h-4 w-4 text-main-600" aria-hidden="true" />
              : <MagnifyingGlassIcon className="w-4 h-4 text-main-600" />
          }
        </div>
        <div className="absolute inset-y-0 right-2.5 flex items-center">
          {
                localSearchString && (
                <button
                  className="bg-neutral-100 hover:bg-neutral-200 transition-colors rounded-full p-1"
                  type="button"
                  onClick={() => {
                    dispatch(resetMembersSearch());
                    setLocalSearchString('');
                  }}
                  title="clear search"
                >
                  <XMarkIcon className="w-4 h-4" />
                </button>
                )
            }
        </div>
      </div>
    </div>
  );

}
