import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useGetContactsListQuery } from '../contactListAPI';
import ModuleLoader from '../../profile-page/components/ModuleLoader';
import { ContactListEntry as ContactListEntryType } from '../contact-list.types';
import ContactListEntry from './ContactListEntry';
import { filterContacts } from '../helpers';

interface ContactListProps {
  searchString: string,
  contactsOnly: boolean,
  followersOnly: boolean,
  followingOnly: boolean,
}

export default function ContactList(props: ContactListProps) {

  const {
    contactsOnly, followingOnly, followersOnly, searchString,
  } = props;

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const { data, isLoading } = useGetContactsListQuery({ profilePageID: profileID }, {
    skip: !profileID,
    pollingInterval: 60 * 1000, // every min
  });

  const filteredData = filterContacts(data, searchString, contactsOnly, followersOnly, followingOnly);

  let content = null;
  if (isLoading) {
    content = <ModuleLoader />;
  }

  if (! data) {
    content = (
      <div className="flex flex-row justify-center items-center pb-4">
        <span className="text-neutral-700 text-sm font-semibold">
          No contacts
        </span>
      </div>
    );
  }

  if (filteredData) {
    content = (
      <>
        {/* Results */}
        <div className="py-2 px-4 pb-4 font-semibold text-sm border-b">
          <span>
            {filteredData.totalRecords}
            {' '}
            Result
            {filteredData.totalRecords !== 1 ? 's' : ''}
          </span>
        </div>

        {
          filteredData.letterGroupKeys.map((letter) => (
            <Disclosure key={letter}>
              {({ open }) => (
                <div className="border-b">
                  {/* row header */}
                  <Disclosure.Button className="w-full">
                    <div className={`w-full text-sm p-4 flex flex-row justify-between items-center ${open && 'border-b'} cursor-pointer`}>
                      <span className="font-semibold">{letter}</span>
                      <ChevronDownIcon
                        className={`${open ? 'rotate-180' : ''} w-4 h-4 mr-5 text-main-500`}
                      />
                    </div>
                  </Disclosure.Button>

                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="scale-95 opacity-0"
                    enterTo="scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="scale-100 opacity-100"
                    leaveTo="scale-95 opacity-0"
                  >
                    <Disclosure.Panel static>
                      <>
                        {/* row item */}
                        {
                          // @ts-ignore
                          filteredData.letterGroups[letter].contacts.map((contact: ContactListEntryType) => (
                            <ContactListEntry key={contact.profilePageSummary.profilePageId} contact={contact} />
                          ))
                        }
                      </>
                    </Disclosure.Panel>
                  </Transition>

                </div>
              )}
            </Disclosure>
          ))
        }
      </>
    );
  }

  return (
    <div className="bg-white pt-4 mt-6 shadow shadow-md rounded rounded-md">
      { content }
    </div>
  );
}
