import React, { useState } from 'react';
import { DocumentArrowDownIcon } from '@heroicons/react/20/solid';
// import { IPostPDF } from '../features/post/create-post/post-pdf/PostPDF';
import axios from 'axios';
import { formatBytesToString } from '../features/post/create-post/helpers';
import { API_URL } from '../authConfig';
import { AboutModuleFile } from '../features/profile-page/modules/about-module/aboutModuleTypes';

interface FileDownloadPreviewProps {
  file: AboutModuleFile // | IPostPDF
}

export default function FileDownloadPreview(props: FileDownloadPreviewProps) {

  const { file } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    if (file.fileUrl && ! file.isPrivate) {

      const windowReference = window.open(); // done this way to force opening a new tab in Safari
      // @ts-expect-error
      windowReference.location = file.fileUrl;
      return;
    }

    if (isLoading) return;

    setIsLoading(true);

    if (! file.fileUuid) return;

    const response = await axios({ // todo: extract
      url: `${API_URL}/AzureStorage/attachment/${file.fileUuid}`,
      method: 'GET',
      responseType: 'blob', // important
    });

    const url = URL.createObjectURL(new Blob([response.data], { type: 'octet/stream' }));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.originalFilename);
    document.body.appendChild(link);
    link.click();

    setIsLoading(false);

    setTimeout(() => { // cleanup memory
      URL.revokeObjectURL(url);
    }, 1000);
  };

  return (
    <button type="button" onClick={onClick} className="w-full">
      <div className="flex flex-row shadow-md rounded-lg mt-1 p-2 bg-white">

        <div
          className="shrink-0 bg-transparent mr-4 text-gray-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none m-2 "
        >
          <span className="sr-only">Download file</span>
          <DocumentArrowDownIcon className="h-6 w-6 text-main-600 bg-main-50" />
        </div>
        <div className="flex flex-col overflow-hidden">
          <p className="text-left text-main-600 text-xs">
            Download (
            <span>{formatBytesToString(file.fileSizeBytes)}</span>
            )
          </p>
          <p className="text-base font-semibold text-left truncate">
            {file.description || file.title || file.originalFilename}
          </p>
        </div>
      </div>
    </button>
  );
}
