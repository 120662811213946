/* eslint-disable react/require-default-props */
import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { UsersIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../helpers/styling';
// import { classNames } from '../helpers/styling';

interface SwitchProfileModalProps {
  show: boolean,
  setOpen: (flag: boolean) => void,
  onActionClick?: () => void,
  transparentBackground?: boolean,
  imageSrc?: string,
  isLoading?: boolean,
  currentProfileName: string | undefined,
  swapProfileName: string | undefined,
}

export default function SwitchProfileModal(props: SwitchProfileModalProps) {
  const {
    show, setOpen, imageSrc,
    onActionClick,
    transparentBackground = false,
    isLoading = false,
    currentProfileName,
    swapProfileName,
  } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={classNames(
              'fixed inset-0',
              transparentBackground ? 'bg-neutral-600 opacity-80' : 'bg-neutral-50',
            )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block relative z-20 align-bottom bg-white rounded-lg p-12 text-left overflow-hidden shadow-lg transition-all sm:align-middle">
              <div className="sm:flex sm:items-start">
                {
                  imageSrc ? (
                    <img alt="avatar" src={imageSrc} className="h-24 w-24 rounded-full" />
                  ) : (
                    <div className="mx-auto shrink-0 flex items-center justify-center h-24 w-24 rounded-full bg-main-25">
                      <UsersIcon className="h-12 w-12 text-main-600" aria-hidden="true" />
                    </div>
                  )
                }
                <div className="mt-3 ml-10 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" style={{ maxWidth: 450 }} className="text-xl leading-6 py-3 font-bold text-neutral-800">
                    Confirm Profile Change
                  </Dialog.Title>
                  <div className="mt-2 font-medium text-lg max-w-md">
                    <span>
                      You are about to switch to
                      {' '} 
                      <span className="font-semibold text-main-600">{swapProfileName}</span> 
                      {' '}
                      profile.
                    </span>
                    <br />
                    <p className="mt-3">
                      Are you sure you want to proceed?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="button"
                  className={`
                    inline-flex justify-center rounded-md border border-neutral-300 py-4 px-6 bg-white text-base font-medium text-main-600 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-500
                    ${isLoading && 'pointer-events-none'}
                  `}
                  disabled={isLoading}
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
                {
                  onActionClick && (
                    <button
                      type="button"
                      className={`
                        inline-flex items-center py-4 px-6 rounded-md border border-transparent bg-main-600 text-base font-semibold text-white hover:bg-main-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-600 ml-4
                        ${isLoading && 'pointer-events-none animate-pulse'}
                      `}
                      disabled={isLoading}
                      onClick={onActionClick}
                    >
                      <span>
                        Change
                      </span>
                    </button>
                  )
                }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
