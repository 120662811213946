import React, { useState } from 'react';
import { PencilIcon, Squares2X2Icon, TrashIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { Answer, QuestionAnswerRequest, VoteOperation } from '../../../forum-types';
import { classNames } from '../../../../../helpers/styling';
import ForumVoting from '../../ForumVoting';
import ClumpedContent from '../../ClumpedContent';
import ProfileLink from '../../../post-question/ProfileLink';
import MetadataRow from '../../MetadataRow';
import Button from '../../../../../elements/Button';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { setDeleteAnswerModalData } from '../answersSlice';
import AnswerEditForm from './AnswerEditForm';
import calculatedCachedVote from '../../../helpers/calculatedCachedVote';
import { answersAPI, useVoteForAnswerMutation } from '../answersAPI';
import { RootState } from '../../../../../app/store';
import { getCanDeleteForumAnswers, getCanUpdateForumAnswers } from '../../../../../app/permissionsSchema';

interface AnswerEntryProps {
  answer: Answer,
  answersCacheKeys: QuestionAnswerRequest,
  first?: boolean,
  last?: boolean,
}

// todo: check if all data from response is used
export default function AnswerEntry(props: AnswerEntryProps) {
  const {
    answer, first, last, answersCacheKeys,
  } = props;

  const [editMode, setEditMode] = useState(false);

  const [voteForAnswerRequest] = useVoteForAnswerMutation();

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);
  const canDeleteForumAnswers = useAppSelector(getCanDeleteForumAnswers);
  const canUpdateForumAnswers = useAppSelector(getCanUpdateForumAnswers);

  const dispatch = useAppDispatch();

  const onVote = async (operation: VoteOperation) => {
    let voteOperation = operation;
    if (answer.requesterVote === operation) {
      voteOperation = VoteOperation.removeVote;
    }

    dispatch(answersAPI.util.updateQueryData('getQuestionAnswers', answersCacheKeys, (answersList) => ({
      ...answersList,
      data: answersList.data.map((ans: Answer) => {
        if (ans.id !== answer.id) return ans;

        return {
          ...ans,
          requesterVote: voteOperation === VoteOperation.removeVote ? null : operation,
          votesCounter: calculatedCachedVote(answer.requesterVote, voteOperation, answer.votesCounter),
        };
      }),
    })));

    voteForAnswerRequest({
      answerID: answer.id,
      requesterID: profileID,
      voteOperation,
      answersCacheKeys,
    });
  };

  return (
    <div className={classNames(
      'pt-4 pb-8',
      first ? '' : 'mt-4',
      last ? '' : 'border-b border-neutral-200',
    )}
    >
      <div className="grid gap-0 grid-cols-12">
        <div className="col-span-1 pr-4 flex flex-col items-center justify-between">
          <ForumVoting
            disabled={answer.creatorProfilePageSummary?.profilePageId === profileID}
            currentVote={answer.requesterVote}
            votesCounter={answer.votesCounter}
            onUpVote={() => onVote(VoteOperation.upVote)}
            onDownVote={() => onVote(VoteOperation.downVote)}
          />
        </div>

        <div className="col-span-11">
          {
            editMode
              ? <AnswerEditForm answer={answer} setViewMode={() => setEditMode(false)} />
              : <ClumpedContent text={answer.content} lineClamp="line-clamp-12" />
          }
        </div>
      </div>

      <div className="mt-8 flex justify-between">
        <div className="flex flex-col justify-end">
          <MetadataRow
            createdAtUtc={answer.createdAtUtc}
            updatedAtUtc={answer.updatedAtUtc}
            edited={answer.edited}
            createdLabel="Answered"
          />

          <div className="flex flex-row gap-1 mt-1">
            {
                (answer.createdByRequester || canDeleteForumAnswers) && (
                <Button
                  onClick={() => {
                    dispatch(setDeleteAnswerModalData({
                      answerID: answer.id,
                      showModal: true,
                      answerText: `${answer.content.slice(0, 20)}...`,
                    }));
                  }}
                  size="small"
                  inverted
                  roundedFull
                  neutralText
                  styles="transition-colors hover:text-error-600"
                  iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
                  description="Delete"
                />
                )
            }
            {
                (answer.createdByRequester || canUpdateForumAnswers) && (
                <Button
                  onClick={() => setEditMode(! editMode)}
                  size="small"
                  inverted
                  neutralText
                  roundedFull
                  styles="transition-colors hover:text-main-600"
                  iconLeft={editMode ? <Squares2X2Icon className="w-4 h-4 mr-2" /> : <PencilIcon className="w-4 h-4 mr-2" />}
                  description={editMode ? 'View mode' :  'Edit'}
                />
                )
            }
          </div>
        </div>

        <ProfileLink creator={answer.creatorProfilePageSummary} />
      </div>
    </div>
  );
}
