import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { getLinkRootDomain } from '../helpers/functions';

interface ExternalLinkViewProps {
  link: string,
  description: string,
}

export default function ExternalLinkView(props: ExternalLinkViewProps) {

  const { link, description } = props;

  const url = link.match(/^http[s]?:\/\//) ? link : `http://${link}`;

  return (
    <a
      target="_blank"
      href={url}
      rel="noreferrer"
      className="flex flex-row shadow-md rounded-lg mt-1 p-2"
    >

      <div
        className="shrink-0 height-fit-content bg-transparent mr-4 text-gray-400 rounded-full ring-8 ring-offset-0 ring-main-50 focus:outline-none m-2 "
      >
        <span className="sr-only">
          Go to
          {description}
        </span>
        <ArrowTopRightOnSquareIcon className="h-6 w-6 text-main-600 bg-main-25" />
      </div>
      <div>
        <p className="text-main-600 text-xs">
          Link
          {' - '}
          {getLinkRootDomain(link)}
        </p>
        <p className="text-base font-semibold">{description}</p>
      </div>
    </a>
  );
}
