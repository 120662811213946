import React from 'react';
import { TagIcon } from '@heroicons/react/20/solid';

interface TagProps {
  name: string,
  onClick?: () => void,
}

export default function Tag(props: TagProps) {
  const { name, onClick } = props;

  if (! onClick) {
    return (
      <div className="text-xs py-1 px-2 h-6 rounded-md border border-main-200 bg-main-25 flex flex-row items-center">
        <TagIcon className="w-3 h-3 text-main-300 mr-1" />
        <span className="py-1">{ name }</span>
      </div>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className="text-xs py-1 px-2 h-6 rounded-md border border-main-200 bg-main-25 flex flex-row items-center"
    >
      <TagIcon className="w-3 h-3 text-main-300 mr-1" />
      <span className="py-1">{ name }</span>
    </button>
  );
}
