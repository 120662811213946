export type GroupedProducts = {
  [key in string | number]: Group;
} & {
  noGroupProducts: Group;
  groupIDs: number[];
  groups: GroupWithoutProducts[];
};

export interface GroupWithoutProducts {
  groupId: number,
  groupName: string,
}

export interface Group {
  groupID: number,
  groupName: string,
  products: Product[],
}

export enum ProductTypes {
  standardProduct = 'standardProduct',
  customizedProduct = 'customizedProduct',
}

export enum RegStatusInfoEnum {
  underDevelopment = 'underDevelopment',
  underRegistration = 'underRegistration',
  approved = 'approved',
  marketed = 'marketed',
}

export interface Product {
  id: number,
  productName: string,

  activeIngredients?: CoreInnProduct[],
  atcGroup?: AtcGroup,
  coreAtcCodeId?: number,
  coreTags?: CoreTag[],
  description?: string,
  holderOwners?: HolderOwner[],
  licences?: Licence[],
  presentations?: Presentation[],
  productCategories?: CoreProductCategory[],

  productGroup?: {
    productGroupName: string,
  },
  productGroupId?: number,
  projectName: string,
  regStatusInformation: RegStatusInformation[]

  productType: ProductTypes,

  createdAtUtc?: string,
  updatedAtUtc?: string,
}

/* Product types */
export interface RegStatusInformation {
  statusInfoType: RegStatusInfoEnum,
  countries: ProductCountry[],
}

export interface ProductCountry {
  coreTerritory: {
    id: number,
    isoAlpha3Code?: string,
    name: string,
  }
}

export interface CoreInnProduct {
  coreInnProductId: number,
  coreInnProduct: {
    id: number,
    name: string,
  }
}

export interface AtcGroup {
  id: number,
  atcCodeName: string,
  atcProductGroup: {
    id: number,
    name: string,
  },
}

export interface CoreTag {
  coreTag: {
    id: number,
    name: string,
  }
}

export interface HolderOwner {
  authHolderProdOwner: string,
}

export interface Licence {
  licenceNumber: string,
}

export interface Presentation {
  presentation: string,
  dossages: Dosage[]
}

export interface StrengthUnit {
  id: number,
  name: string,
  coreStrengthUnitId?: number,
}

export interface StrengthUnitFromQuery {
  value: number,
  label: string,

  valueForStore: {
    coreStrengthUnit: {
      id: number,
      name: string,
    },
    coreStrengthUnitId: number,
  }
}

export interface DosageStrength {
  coreInnProductId: number,
  strength: number | string, // allow string for values like .02 or 2. (floats)
}

export interface Dosage {
  coreDosageForm: {
    dosageFormCode: number,
    id: number,
    name: string

    coreDosageFormClass?: {
      id: number,
      name: string,
    }
    coreDosageFormShortname?: {
      id: number,
      name: string,
    }
    coreDosageFormStatus?: {
      id: number,
      name: string,
    }
  }
  coreStrengthUnit?: StrengthUnit
  moduleProductInnStrengths: DosageStrength[],
}

export interface CoreProductCategory {
  coreProductCategory: {
    id: number,
    name: string,
  }
}

/* Product types END */
