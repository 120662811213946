import React, { useEffect, useRef, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Bars3Icon, PhotoIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { ActionMeta, OnChangeValue } from 'react-select';
import { CorporationMemberType } from './team-module-types';
import Button from '../../../../elements/Button';
import { useAppDispatch } from '../../../../app/hooks';
import UserAvatar from '../../../../elements/UserAvatar';
import { ProfilePageType } from '../../../../app/account';
import Input from '../../../../elements/Input';
import { UploadBlob, uploadFileToAzure } from '../../../../app/filesSlice';
import { deleteMemberCard, updateMemberCard } from './teamModuleSlice';
import AppSelect from '../../../../elements/AppSelect';
import { RootState } from '../../../../app/store';
import InfoMessage from '../../../../elements/InfoMessage';

interface MemberEditCardProps {
  teamMember: CorporationMemberType,
  dragHandleProps?: DraggableProvidedDragHandleProps
}

export default function MemberEditCard(props: MemberEditCardProps) {

  const { teamMember, dragHandleProps } = props;

  const [deleteMode, setDeleteMode] = useState(false);

  const [options, setOptions] = useState<{ value: number, label: string, data: CorporationMemberType }[]>([]);

  const avatarUpload = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const { teamMembersToSelect } = useSelector((state: RootState) => state.profileModules.teamModule);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  useEffect(() => {
    setOptions(teamMembersToSelect.map((m) => ({
      value: m.profilePageId ? m.profilePageId : 0,
      label: m.fullName,
      data: m,
    })));
  }, [teamMembersToSelect]);

  // todo: upload only when changes are saved
  const onAvatarUpload = async (files: any) => {

    if (!files || !files[0]) {
      return;
    }

    const file = await dispatch(uploadFileToAzure({
      file: files[0],
      description: '',
      blobContainer: UploadBlob.moduleTeamMemberAvatar,
      profilePageID: currentlyLoadedProfileID!,
      requesterID,
    }));

    // @ts-ignore
    const { id, fileUrl } = file.payload.data;

    dispatch(updateMemberCard({
      ...teamMember,
      profileImageUuid: id,
      profileImageUrl: fileUrl,
    }));
  };

  const onSelectMember = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {

    if (actionMeta.action === 'create-option') { // add new option to the list
      dispatch(updateMemberCard({
        ...teamMember,
        fullName: newValue.value,
      }));

      return;
    }

    dispatch(updateMemberCard({
      ...teamMember,
      ...newValue.data,
      id: undefined,
      localID: teamMember.localID,
      memberOrder: teamMember.memberOrder,
    }));
  };

  return (
    <div className="rounded-md border bg-white">
      {/* Header */}
      <div className="p-2 flex flex-row justify-end bg-neutral-75 rounded-t-md">
        <div
          tabIndex={0}
          onKeyDown={() => {}}
          role="button"
          onClick={() => {}}
          className="font-semibold rounded-full border flex flex-row items-center justify-center border-neutral-300 text-main-600 bg-white px-4 p-2 text-xs"
          {...dragHandleProps}
        >
          Organise
          <div className="pl-1">
            <Bars3Icon className="w-4 h-4 text-neutral-800" />
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="p-4">
        <div className="flex flex-row gap-4">
          <div style={{ minWidth: 128 }} className="relative flex justify-center">
            <UserAvatar profileImageUrl={teamMember.profileImageUrl} profilePageType={ProfilePageType.individual} size="big" />
            <div className="absolute top-20">
              <Button
                onClick={() => {
                  if (! avatarUpload || ! avatarUpload.current) return;
                  avatarUpload.current.click();
                }}
                size="small"
                inverted
                roundedFull
                iconLeft={<PhotoIcon className="h-4 w-4 text-main-600" aria-hidden="true" />}
                description=""
              />
            </div>
          </div>

          <div className="flex flex-col w-full gap-2">
            {
              teamMember.profilePageId || teamMember.id || teamMember.fullName ? (
                <Input
                  id={`tm-name-${teamMember.id || teamMember.localID}`}
                  label="Name and lastname"
                  placeholder="Full name"
                  value={teamMember.fullName}
                  onChange={(e) => dispatch(updateMemberCard({
                    ...teamMember,
                    fullName: e.target.value,
                  }))}
                />
              ) : (
                <>
                  <AppSelect
                    isClearable
                    isCreatable
                    placeholder="Start typing name or select a member"
                    value={teamMember.fullName ? { value: teamMember.profilePageId || 0, label: teamMember.fullName } : undefined}
                    onChange={onSelectMember}
                    options={options}
                  />
                  <InfoMessage
                    message="Add members by select from the dropdown or enter name manually (You can add members that are not part of Nucleo)"
                  />
                </>
              )
            }

            <Input
              id={`tm-job-${teamMember.id || teamMember.localID}`}
              label="Job position"
              placeholder="Job"
              value={teamMember.jobPosition}
              onChange={(e) => dispatch(updateMemberCard({
                ...teamMember,
                jobPosition: e.target.value,
              }))}
            />

            <Input
              id={`tm-email-${teamMember.id || teamMember.localID}`}
              label="Email"
              placeholder="Email"
              value={teamMember.email}
              onChange={(e) => dispatch(updateMemberCard({
                ...teamMember,
                email: e.target.value,
              }))}
            />
            <div className="border border-neutral-300 w-full rounded-md">
              <div className="py-3 px-4 flex flex-row items-center">
                <input
                  id={`tm-allow-mail-${teamMember.id || teamMember.localID}`}
                  type="checkbox"
                  checked={teamMember.displayEmail}
                  onChange={(e) => dispatch(updateMemberCard({
                    ...teamMember,
                    displayEmail: e.target.checked,
                  }))}
                  className="h-4 w-4 rounded text-main-600 focus:outline-none border-neutral-300"
                />
                <label
                  htmlFor={`tm-allow-mail-${teamMember.id || teamMember.localID}`}
                  className="pl-4 text-neutral-800 text-sm"
                >
                  Display email
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-2 flex flex-row justify-between">
          <div className="flex flex-row">
            <div className="max-w-max mr-2">
              <Button
                onClick={() => setDeleteMode(true)}
                size="small"
                description=""
                roundedFull
                inverted={! deleteMode}
                fullRed
                iconLeft={<TrashIcon className="w-4 h-4" />}
              />
            </div>

            {
              deleteMode && (
                <>
                  <Button
                    onClick={() => setDeleteMode(false)}
                    size="small"
                    description="Cancel"
                    roundedFull
                    fullRed
                  />
                  <div className="ml-2">
                    <Button
                      onClick={() => {
                        setDeleteMode(false);
                        dispatch(deleteMemberCard(teamMember));
                      }}
                      size="small"
                      description="Delete"
                      redText
                      roundedFull
                      inverted
                    />
                  </div>
                </>
              )
            }
          </div>

        </div>
      </div>

      <input
        ref={avatarUpload}
        type="file"
        name="profile_image"
        className="hidden"
        accept="image/png, image/gif, image/jpeg"
        onChange={(event) => onAvatarUpload(event.target.files)}
      />
    </div>
  );
}
