import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../../helpers/validation';
  
export type ValidationKeys = 'name' | 'surname' | 'email';

export const rulesAddCorporationMember: RulesForFieldsType<ValidationKeys> = {
  name: {
    userFriendlyFieldName: 'Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 1,
    maxLength: 255,
  },
  surname: {
    userFriendlyFieldName: 'Surname',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 1,
    maxLength: 255,
  },
  email: {
    userFriendlyFieldName: 'Email',
    ruleType: RuleTypes.email,
    required: true,
    minLength: 3,
    maxLength: 255,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  name: { errorMessage: '' },
  surname: { errorMessage: '' },
  email: { errorMessage: '' },
};
