import React from 'react';
import { ReactComponent as LogoIPLS } from '../logo-ipls.svg';
import { ReactComponent as LogoNucleo } from '../logo.svg';
import { BRANDING } from '../helpers/branding';

// Note: find a way to build only with one svg asset
export default function Logo() {
  if (BRANDING === 'IPLS') {
    return <LogoIPLS />;
  }

  return <LogoNucleo />;
}
