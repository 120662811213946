import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../app/store';

// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples/typescript-sample/src/ui-components
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react
export default function LogoutButton() {
  // function handleLogout(instance: any) {
  //   instance.logoutPopup({
  //     mainWindowRedirectUri: '/',
  //   });
  // }

  const history  = useHistory();
  const { currentProfile } = useSelector((state: RootState) => state.account);

  const goToProfile = () => {
    history.push(`/profile/${currentProfile.profileID}`);
  };

  const disabled = ! currentProfile || ! currentProfile.profileID;

  return (
    <div className="inline-flex flex-col items-center">
      <button
        disabled={disabled}
        type="button"
        // onClick={() => handleLogout(instance)}
        onClick={goToProfile}
        className={`inline-flex justify-center items-center text-main-900 font-semibold px-4 py-2 ${disabled ? 'bg-gray-300' : 'bg-white'} rounded-md`}
      >
        To Profile
      </button>

      {/* { */}
      {/*  currentProfile.profileID && ( */}
      {/*    <div className="pr-2 text-sm text-main-600 font-semibold"> */}
      {/*      <Link to={`/profile/${currentProfile.profileID}`}> */}
      {/*        <span>Go to your profile</span> */}
      {/*      </Link> */}
      {/*    </div> */}
      {/*  ) */}
      {/* } */}
    </div>
  );
}
