import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { CoreTag } from './tag-types';

export const tagAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getTags: builder.query<CoreTag[], null>({
      query: () => ({
        url: '/Tag',
      }),
      providesTags: ['CoreTags'],
    }),

    addOrUpdateTag: builder.mutation<null, { tagId?: number, tagName: string }>({
      query(data) {
        const { tagId, tagName } = data;

        return {
          url: '/Tag',
          method: 'POST',
          body: {
            tagId,
            tagName,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const operation = args.tagId != null ? 'updated' : 'added';
        try {
          await queryFulfilled;          
          dispatch(addFlashMessage('success', `Tag was ${operation} successfully`));          
        } catch {
          dispatch(addFlashMessage('error', `There was an error while ${operation} the tag`));
        }
      },

      invalidatesTags: () => ['CoreTags'],
    }),

    deleteTag: builder.mutation<null, { tagId?: number }>({
      query(data) {
        const { tagId } = data;

        return {
          url: `/Tag/${tagId}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const operation = args.tagId != null ? 'updated' : 'added';
        try {
          await queryFulfilled;          
          dispatch(addFlashMessage('success', 'Tag was successfully removed'));          
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while removing the tag'));
        }
      },

      invalidatesTags: () => ['CoreTags'],
    }),

  }),
});

export const {
  useGetTagsQuery,
  useAddOrUpdateTagMutation,
  useDeleteTagMutation,
} = tagAPI;
