import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../../../helpers/styling';

interface ClumpedContentProps {
  text: string,
  lineClamp?: string,
  showMoreText?: string,
  showLessText?: string,
}

export default function ClumpedContent(props: ClumpedContentProps) {

  const { 
    text, 
    lineClamp = 'line-clamp-20',
    showMoreText = 'show full content',
    showLessText = 'show less',
  } = props;

  const clampedText = useRef(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [textWideOpen, setTextWideOpen] = useState(false);

  useEffect(() => {
    if (! clampedText || ! clampedText.current) {
      return;
    }

    // @ts-expect-error
    setShowMoreButton(clampedText.current.scrollHeight > clampedText.current.clientHeight);
  }, []);

  return (
    <div>
      <div
        ref={clampedText}
        className={classNames(
          textWideOpen ? '' : lineClamp,
          'font-normal text-neutral-800 whitespace-pre-line text-sm whitespace-pre-line',
        )}
      >
        { text }
      </div>

      <div className="flex justify-end">
        {
          showMoreButton && (
            <span
              tabIndex={0}
              role="button"
              className="text-sm font-bold text-main-600 cursor-pointer"
              onKeyDown={() => setTextWideOpen(! textWideOpen)}
              onClick={() => setTextWideOpen(! textWideOpen)}
            >
              { textWideOpen ? showLessText : showMoreText }
            </span>
          )
        }
      </div>
    </div>
  );

}
