import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import { Link, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import CookieConsent from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import nucleoLogoWhite from './assets/images/logo-white.svg';
import { landingNavigation } from './LandingNavigation';
import ContactUsForm from './ContactUsForm';
import bgFooterFirst from './assets/images/bg-footer-1-logo.svg';
import bgFooterSecond from './assets/images/bg-footer-2-logo.svg';
import FlashMessages from '../flash-messages/FlashMessages';
import { useCupidoLogin } from '../../helpers/hooks';
import { FEATURE_LOGIN_ENABLED } from '../../config/features';
import { tagManagerArgs } from '../../config/base';

export default function Footer() {
  function handleLogout(instance: any) {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  const isAuthenticated = useIsAuthenticated();
  const { instance, handleLogin } = useCupidoLogin();

  const location = useLocation();

  // todo: connect modal to GTM
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Enable All"
        declineButtonText="Reject Optional"
        enableDeclineButton
        containerClasses=""
        buttonClasses="inline-flex justify-center items-center text-main-900 font-semibold px-6 py-4 bg-white rounded-md"
        buttonWrapperClasses="flex gap-4"
        declineButtonClasses="inline-flex justify-center items-center text-white font-semibold px-6 py-4 bg-error-400 rounded-md"
        cookieName="ConsentCookie"
        style={{
          backgroundColor: '#111827',
          // background: 'linear-gradient(90deg, #1E3A8A 0%, #1E3A8A 80%, #93C5FD 100%)',
          padding: 26,
        }} // from ts-conf
        overlay={false} // conflicts with existing styles, works but with warnings
        disableButtonStyles
        expires={150} // in days
        onAccept={() => {
          if (tagManagerArgs.gtmId) {
            TagManager.initialize(tagManagerArgs);
          } else {
            // eslint-disable-next-line no-console
            console.warn('GTag cannot be initialized: no GTAG');
          }
        }}
      >
        <p className="text-base max-w-3xl pr-4">
          <span>
            Yes, even our website uses cookies. Some of them are necessary for the operation of the website,
            while others you can decide for yourself. By enabling cookies, we will be able to give you the
            full experience of using our services without extra clicks. You can find out more about the
            processing of personal data through cookies in our
            {' '}
          </span>
          <a
            href="/cookie-policy"
            target="_blank"
            className="underline font-bold"
          >
            Cookie Policy
          </a>
        </p>
      </CookieConsent>

      <section id="about-us" className="bg-main-900 flex justify-center pb-64">
        <div className="pt-12 max-w-6xl w-full px-4 md:px-6 xl:px-0 flex flex-col md:flex-row justify-between">

          <div style={{ maxWidth: 536 }} className="mr-4 z-10">
            <Fade left>
              <div className="flex flex-row items-center pl-4 py-3">
                <img src={nucleoLogoWhite} alt="Nucleo logo in white" />
                <span className="text-neutral-400 text-xs ml-6 md:ml-10 lg:ml-36 pt-1.5">© 2022 Nucleo, Inc. All rights reserved.</span>
              </div>
            </Fade>
            <div className="pt-4">
              <Fade left>
                <nav className="flex flex-wrap">
                  {
                      landingNavigation.map((item) => (
                        <Link key={item.href} to={item.href} className="py-2 px-4 text-neutral-400 font-semibold text-sm">
                          {item.name}
                        </Link>
                      ))
                    }
                  <Link
                    to="/pre-registration/cancel"
                    className="py-2 px-4 text-neutral-400 font-semibold text-sm"
                  >
                    Cancel pre-registration
                  </Link>
                  {
                    FEATURE_LOGIN_ENABLED && (
                      <>
                        {
                          isAuthenticated ? (
                            <button
                              type="button"
                              onClick={() => {
                                handleLogout(instance);
                              }}
                              className="py-2 px-4 text-neutral-400 font-semibold text-sm"
                            >
                              Logout
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                handleLogin(instance);
                              }}
                              className="py-2 px-4 text-neutral-400 font-semibold text-sm"
                            >
                              Login
                            </button>
                          )
                        }
                      </>
                    )
                  }
                </nav>
                <div className="w-full h-0.5 bg-gray-500 mx-4 mt-4 mb-2 max-w-[50%]" />
                <nav className="">
                  <a
                    href="/terms-of-use"
                    target="_blank"
                    className="py-2 px-4 text-neutral-400 font-semibold text-xs"
                  >
                    Terms of use
                  </a>
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="py-2 px-4 text-neutral-400 font-semibold text-xs"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="/cookie-policy"
                    target="_blank"
                    className="py-2 px-4 text-neutral-400 font-semibold text-xs"
                  >
                    Cookie Policy
                  </a>
                  {
                    location.pathname.includes('cphi') && (
                      <a
                        target="_blank"
                        href="/contest-terms"
                        className="py-2 px-4 text-neutral-400 font-semibold text-xs"
                      >
                        Contest rules
                      </a>
                    )
                  }
                </nav>
              </Fade>
            </div>

          </div>

          <ContactUsForm />

          <img
            src={bgFooterFirst}
            alt="Background right"
            className="absolute bottom-0 left-10 hidden lg:block z-0 "
          />
          <img
            src={bgFooterSecond}
            alt="Background left"
            className="absolute bottom-0 sm:-bottom-20 md:-bottom-80 lg:-bottom-60 xl:bottom-0 right-0 z-0"
          />

        </div>
      </section>

      <FlashMessages />
    </>
  );
}
