import React, { Fragment, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { colorByType, iconByType } from './utils';

import { FlashMessage } from './flashMessagesSlice';

const DESTROY_AFTER_MS = 5000;

interface SystemWarningProps {
  flashMessage: FlashMessage,
  onCloseCb: (id: string) => void
}

export default function SystemWarning(props: SystemWarningProps) {

  const { flashMessage, onCloseCb } = props;
  // const [closingTimeoutID, setClosingTimeoutID] = useState<TimeoutId | null>(null);

  const {
    show, type, message, autoDismiss, callToAction,
  } = flashMessage;

  useEffect(() => {

    if (autoDismiss) {
      setTimeout(() => {
        onCloseCb(flashMessage.id);
      }, DESTROY_AFTER_MS);
    }

  // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* To add classes to compiled styles */}
      <div className="hidden bg-success-400 bg-warning-400 bg-neutral-500 bg-error-400 bg-main-500" />
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={`bg-${colorByType(type)} pointer-events-auto py-4 mb-2 rounded-lg flex flex-row justify-between w-full max-w-xl mx-auto`}>

          <div className="flex flex-row items-center pl-4 max-w-[80%] lg:max-w-[90%]">
            <div style={{ minWidth: 20 }}>
              { iconByType(type, 'w-5 h-5 text-white') }
            </div>

            <p className="text-white font-semibold text-base pl-4 overflow-x-hidden">
              { message }
            </p>
          </div>

          <div className="flex flex-row items-center">
            {
              callToAction && (
                <button
                  type="button"
                  onClick={() => {
                    if (callToAction.action === 'scrollToTop') {
                      window.scrollTo(0, 0);
                    }

                    onCloseCb(flashMessage.id);
                  }}
                  className={`bg-white px-4 py-2 font-bold text-xs rounded-md text-${colorByType(type)}`}
                >
                  {callToAction.text}
                </button>
              )
            }
            <button
              type="button"
              className="pr-5 pl-6"
              onClick={() => {
                onCloseCb(flashMessage.id);
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </Transition>
    </>
  );
}
