import { ProfilePageMetadataType, ProfilePageResponse } from './profileTypes';
import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';

export const profilePageAPI = emptySplitApi.injectEndpoints({

  endpoints: (builder) => ({
    getProfileById: builder.query<ProfilePageResponse, { id: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/profilePage/${id}/requester-page/${requesterID}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'ProfilePage', profileID: arg.id, requesterID: arg.requesterID }],

      // keepUnusedDataFor: 5,

      transformResponse: (response: ProfilePageResponse) => {

        const notNullable = Object.keys(response.modulesMetadata)
          .map((moduleID: string) => moduleID)
          // @ts-ignore
          .filter((mId) => Boolean(response.modulesMetadata[mId]));

        // @ts-ignore
        const prepared: ProfilePageMetadataType = {};
        for (let idx = 0; idx < notNullable.length;) {
          const key = notNullable[idx];

          // @ts-ignore
          prepared[key] = {
            // @ts-ignore
            ...response.modulesMetadata[key],
            moduleID: key,
          };

          idx += 1;
        }

        return {
          ...response,
          modulesMetadata: {
            // remove keys without values
            ...prepared,

            // prepare list of IDs
            IDs: notNullable
              .sort((a, b) => {
                // @ts-ignore
                if (response.modulesMetadata[a].moduleOrder < response.modulesMetadata[b].moduleOrder) {
                  return -1;
                }

                return 1;
              }),
          },
        };
      },
    }),
    updateProfileMetadata: builder.mutation<ProfilePageMetadataType & { id: number, requesterID: number }, any>({

      query(data) {
        const { id, requesterID, ...body } = data;

        return {
          url: `/profilePage/${id}/requester-profile-page/${requesterID}/modules/metadata`,
          method: 'PUT',
          body,
        };
      },
    }),

    /* Contact & Follow */
    addContact: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number, message?: string }>({
      query(data) {
        return {
          url: '/Contact/request',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact request was sent'));
        } catch {
          dispatch(addFlashMessage('error', 'Contact request cannot be sent. Please try again.'));
        }
      },
      invalidatesTags: (result, error, arg) => ['ContactList', {
        type: 'ContactList',
        profileID: arg.senderProfilePageId,
      }],
    }),

    // accept/reject lives in contactListAPI
    cancelContactRequest: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Contact/request/cancelled',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact request was cancelled'));
        } catch {
          dispatch(addFlashMessage('error', 'Contact request cannot be cancelled. Please try again.'));
        }
      },
    }),
    removeContact: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Contact',
          method: 'DELETE',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Contact was removed'));
        } catch {
          dispatch(addFlashMessage('error', 'Contact cannot be removed. Please try again.'));
        }
      },
      invalidatesTags: (result, error, arg) => ['ContactList', {
        type: 'ContactList',
        profileID: arg.senderProfilePageId,
      }],
    }),
    followProfile: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Follow',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => ['ContactList', {
        type: 'ContactList',
        profileID: arg.senderProfilePageId,
      }],
    }),
    unfollowProfile: builder.mutation<null, { senderProfilePageId: number, receiverProfilePageId: number }>({
      query(data) {
        return {
          url: '/Follow',
          method: 'DELETE',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => ['ContactList', {
        type: 'ContactList',
        profileID: arg.senderProfilePageId,
      }],
    }),
    /* Contact & Follow END */

    downloadProfile: builder.mutation<null, { profilePageID: number }>({
      query(data) {
        return {
          url: `/Document/pdf/profile-page-id/${data.profilePageID}`,
          method: 'POST',
          body: {
            moduleProductDataIds: [],
            moduleServiceDataIds: [],
            moduleFocusCategoryDataIds: [],
            useAllProfilePageData: true,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Data sent to email'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },
    }),
  }),
});

export const {
  useGetProfileByIdQuery,
  useUpdateProfileMetadataMutation,

  useAddContactMutation,
  useCancelContactRequestMutation,
  useRemoveContactMutation,
  useFollowProfileMutation,
  useUnfollowProfileMutation,

  useDownloadProfileMutation,
} = profilePageAPI;
