import { emptySplitApi } from '../../../../app/api';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';
import {
  QuestionAnswerRequest,
  QuestionAnswerResponse, VoteOperation,
} from '../../forum-types';

// todo: move here all APIs related to answers
export const answersAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionAnswers: builder.query<QuestionAnswerResponse, QuestionAnswerRequest>({
      query: (data) => {
        const {
          questionID, profileID, pageSize, pageNumber, sorting,
        } = data;

        return {
          url: `/forum/question/${questionID}/answers/requester-profile-page/${profileID}?PageNumber=${pageNumber}&PageSize=${pageSize}&sorting=${sorting}`,
        };
      },
      providesTags: (result, error, arg) => [{
        type: 'ForumQuestionAnswers',
        ...arg, // all entries will be used
      }],
    }),

    // TODO: type response
    postQuestionAnswer: builder.mutation<any, { creatorProfileID: number, questionID: number, form: { answerText: string } }>({
      query(data) {
        const { creatorProfileID, questionID, form } = data;

        return {
          url: `/Forum/question/${questionID}/answer/creator-profile-page/${creatorProfileID}`,
          method: 'POST',
          body: {
            content: form.answerText,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Answer is posted created successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      // can be more specific with all params of ForumQuestionAnswers
      invalidatesTags: () => ['ForumQuestionAnswers'],
    }),

    editAnswer: builder.mutation<any, { answerID: number, requesterID?: number, form: { answerText: string } }>({
      query(data) {
        const { requesterID, answerID, form } = data;

        return {
          url: `/Forum/answer/${answerID}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: {
            content: form.answerText,
          },
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Answer is updated successfully'));
          // dispatch(resetCreateQuestionForm());
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      invalidatesTags: ['ForumQuestionAnswers'],
    }),

    deleteAnswer: builder.mutation<any, { profileID: number, answerID: number }>({

      query(data) {

        const { profileID, answerID } = data;

        return {
          url: `/forum/answer/${answerID}/requester-profile-page/${profileID}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Answer was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Answer cannot be deleted. Please try again.'));
        }
      },

      // todo: pass question ID
      // todo: see in redux that only this question cache was deleted
      invalidatesTags: () => ['ForumQuestionAnswers'],
    }),

    voteForAnswer: builder.mutation<any, { answerID: number, requesterID?: number, voteOperation: VoteOperation, answersCacheKeys: QuestionAnswerRequest }>({
      query: (data) => ({
        url: `/Forum/answer/${data.answerID}/vote/requester-profile-page/${data.requesterID}`,
        method: 'PUT',
        body: {
          voteOperation: data.voteOperation,
        },
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Vote is updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [
        {
          type: 'ForumQuestionAnswers',
          ...arg.answersCacheKeys,
        },
      ],
    }),
  }),
});

export const {
  useGetQuestionAnswersQuery,
  usePostQuestionAnswerMutation,
  useEditAnswerMutation,
  useDeleteAnswerMutation,
  useVoteForAnswerMutation,
} = answersAPI;
