import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Link } from '../post-link/PostLink';
import { emptyImage, emptyLink, emptyPDF  } from './helpers';
import { IPostPDF } from './post-pdf/PostPDF';
import { IPostImage } from './post-image/PostImage';

export interface CreatePostState {
  timestamp: string,

  text: string,

  links: Array<Link>,
  PDFs: IPostPDF[],
  images: Array<IPostImage>,

  // fileAttachments: AboutModuleFile[],
}

const initialState: CreatePostState = {

  timestamp: '1 sec ago',

  text: '',

  links: [],
  PDFs: [],
  images: [],
};

export const createPostSlice = createSlice({
  name: 'createPost',
  initialState,

  reducers: {
    updateText: (state, action) => {
      state.text = action.payload;
    },

    resetPost: () => initialState,

    /* Link */
    createLink: (state) => {
      const id = nanoid(); // todo

      const copyEmptyLink = cloneDeep(emptyLink);
      copyEmptyLink.id = id;
      state.links.push(copyEmptyLink);
    },
    deleteLink: (state, action) => {
      state.links = state.links.filter(({ id }) => id !== action.payload);
    },
    editLink: (state, action) => {
      // const linkToEdit = state.links.filter(({ id }) => id === action.payload.id);

      // if (! linkToEdit || ! linkToEdit[0]) {
      //   return;
      // }

      state.links = [
        ...state.links.filter(({ id }) => id !== action.payload.id), action.payload,
      ];
    },

    /* PDF */
    createPDF: (state) => {
      const id = nanoid();

      const copyEmptyPDF = cloneDeep(emptyPDF);
      copyEmptyPDF.id = id;
      state.PDFs.push(copyEmptyPDF);
    },

    deletePDF: (state, action) => {
      state.PDFs = state.PDFs.filter(({ id }) => id !== action.payload);
    },
    editPDF: (state, action:PayloadAction<IPostPDF>) => {
      state.PDFs = [
        ...state.PDFs.filter(({ id }) => id !== action.payload.id), action.payload,
      ];
    },

    /* Image */
    createImage: (state) => {
      const id = nanoid();

      const copyEmptyImage = cloneDeep(emptyImage);
      copyEmptyImage.id = id;
      state.images.push(copyEmptyImage);
    },
    deleteImage: (state, action) => {
      state.images = state.images.filter(({ id }) => id !== action.payload);
    },
    editImage: (state, action) => {
      state.images = [
        ...state.images.filter(({ id }) => id !== action.payload.id), action.payload,
      ];
    },
  },

  extraReducers: () => {

  },
});

export const {
  updateText, resetPost,
  createLink, deleteLink, editLink,
  createPDF, deletePDF, editPDF,
  createImage, editImage, deleteImage,
} = createPostSlice.actions;

export default createPostSlice.reducer;
