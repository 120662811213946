import React from 'react';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import ModuleLayout from '../../components/ModuleLayout';
import ServicesModuleView from './ServicesModuleView';
import ServiceModuleEdit from './ServiceModuleEdit';

interface ServicesModuleProps extends ModuleLayoutBaseProps {

}

export default function ServicesModule(props: ServicesModuleProps) {
  const { dragHandleProps, moduleMetadata, allowEditMetadataModule } = props;

  return (
    <ModuleLayout
      header="Services"
      onReset={() => {}}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      viewComponent={<ServicesModuleView />}
      editComponent={<ServiceModuleEdit />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
