import React from 'react';
import { TERRITORY_IMAGE_URL } from '../../../../../helpers/config';

interface TerritoryFlagProps {
  isoAlpha3Code?: string,
  territoryName: string,
  small?: boolean,
}

export default function TerritoryFlag(props: TerritoryFlagProps) {

  const { territoryName, isoAlpha3Code, small = false } = props;

  if (isoAlpha3Code) {
    return (
      <img
        alt=""
        className={`${small ? 'w-4 h-4' : ''}`}
        // alt={`${territoryName} flag`}
        src={`${TERRITORY_IMAGE_URL}/${isoAlpha3Code}.svg`}
      />
    );
  }

  return (
    <div className={`bg-main-50 rounded-full ${small ? 'w-4 h-4' : 'w-6 h-6'} text-center flex justify-center items-center`}>
      <span className={`${small ? 'text-2xs' : 'text-xs'} font-bold text-main-600`}>
        {territoryName[0]}
      </span>
    </div>
  );
}
