import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../../helpers/styling';
import ClumpedContent from '../forum/question-page/ClumpedContent';

interface ModuleItemsDisplayProps {
  title: string,
  items: string[],
}

export default function ModuleItemsDisplay(props: ModuleItemsDisplayProps) {

  const { title, items } = props;

  if (!items || items.length === 0 || !title) {
    return null;
  }

  const formattedProductNames = `${items.join(', ')}.`;
  const smallCapsTitle = title.toLowerCase();
  const showMoreText = `show all ${smallCapsTitle}`;
  const showLessText = `show less ${smallCapsTitle}`;

  return (
    <div className="w-full flex flex-col mt-2">
      <span className="block w-full text-sm font-medium text-gray-700">
        {title}
      </span>
      <ClumpedContent text={formattedProductNames} lineClamp="line-clamp-3" showMoreText={showMoreText} showLessText={showLessText} />
    </div>
  );
}
