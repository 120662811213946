import React from 'react';

import { useSelector } from 'react-redux';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../../app/store';
import { useGetBusinessMarketsModuleQuery } from './businessMarketsModuleAPI';
import ModuleLoader from '../../components/ModuleLoader';
import Button from '../../../../elements/Button';
import TerritoryRow from './TerritoryRow';

interface BusinessMarketsModuleViewProps {
  setEditMode?: () => void
}

export default function BusinessMarketsModuleView(props: BusinessMarketsModuleViewProps) {

  const { setEditMode } = props;

  const { currentlyLoadedProfileID, allowPageEdit } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const { data, isLoading, isFetching } = useGetBusinessMarketsModuleQuery(
    { id: currentlyLoadedProfileID, requesterID },
    { skip: (! currentlyLoadedProfileID) || (! requesterID) },
  );

  if (isLoading || isFetching || (! data)) {
    return (
      <ModuleLoader />
    );
  }

  const noDataFlag = allowPageEdit
    && (! data
      || (data && (! data.description && data.territories.length === 0))
    );

  // todo: extract view !!! AND refactor other components
  if (noDataFlag) { // empty state
    return (
      <>
        <div className="px-2 py-2 bg-neutral-25 flex-row items-center">
          <div className="w-max">
            <Button
              onClick={() => {
                if (! setEditMode) return;
                setEditMode();
              }}
              size="small"
              description="Edit this module"
              inverted
              roundedFull
              iconLeft={<PlusSmallIcon className="w-4 h-4" />}
            />
          </div>
        </div>
        <div className="w-full pt-4 border-t" />
      </>
    );
  }

  let gridCols = 4;
  if (data.territories.length < 4 && data.territories.length > 0) {
    gridCols = data.territories.length;
  }

  return (
    <div>
      <p className="text-sm pt-6 px-10 font-semibold whitespace-pre-line">
        {data.description}
      </p>

      {
        data.territories.length > 0 && (
          <div className={`grid grid-cols-1 md:grid-cols-${gridCols} gap-2 px-8 py-4`}>
            {
              data.territories.map((country) => (
                <TerritoryRow country={country} />
              ))
            }
          </div>
        )
      }

      <div className="w-full pt-4 border-t" />
    </div>
  );
}
