import { emptySplitApi } from '../../app/api';
import { NewsletterStatus } from './newsletter-types';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';

// todo: consider merging 4 endpoints into 2, as they do the same thing
export const newsletterAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    // Endpoint used from account settings (user needs to be signed in)
    getAccountNewsletterStatus: builder.query<NewsletterStatus, void>({
      query: () => ({
        url: '/Newsletter/settings/status',
      }),
      providesTags: ['NewsletterStatus'],
    }),

    // Endpoint used for the link from the bottom of the email (users do not need to be signed in)
    getNewsletterStatus: builder.query<NewsletterStatus, { id: string }>({
      query: (params) => ({
        url: '/Newsletter/status',
        params,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while fetching newsletter status'));
        }
      },
    }),

    // Endpoint used from account settings (user needs to be signed in)
    accountUpdateNewsletter: builder.mutation<any, { newsletterSubscribed: boolean }>({
      query(data) {
        const { newsletterSubscribed } = data;
        return {
          url: '/Newsletter/settings/status',
          method: 'POST',
          body: {
            newsletterSubscribed,
          },
        };
      },

      invalidatesTags: () => ['NewsletterStatus'],

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(
          newsletterAPI.util.updateQueryData('getAccountNewsletterStatus', undefined, (draft) => ({
            ...draft,
            newsletterSubscribed: args.newsletterSubscribed,
          })),
        );

        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Newsletter subscription successfully updated'));
        } catch {
          dispatch(newsletterAPI.util.invalidateTags(['NewsletterStatus']));
          dispatch(addFlashMessage('error', 'There was an error while updating Newsletter subscription'));
        }
      },
    }),

    // Endpoint used for link from bottom of the email (users does not need to be signed in)
    updateNewsletterStatus: builder.mutation<any, { 
      id: string,
      newsletterSubscribed: boolean,
      contactAndMessagingRequestsSubscribed: boolean,
      unreadMessagesSubscribed: boolean,
      forumNewsSubscribed: boolean,
      forumPostReplySubscribed: boolean,
    }>({
      query(data) {
        const { 
          id,
          newsletterSubscribed,
          contactAndMessagingRequestsSubscribed, 
          unreadMessagesSubscribed, 
          forumNewsSubscribed,
          forumPostReplySubscribed,
        } = data;
        return {
          url: '/Newsletter/status',
          method: 'POST',
          body: {
            id,
            newsletterSubscribed,
            contactAndMessagingRequestsSubscribed,
            unreadMessagesSubscribed,
            forumNewsSubscribed,
            forumPostReplySubscribed,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Newsletter subscription successfully updated'));
        } catch {
          dispatch(addFlashMessage('error', 'There was an error while updating Newsletter subscription'));
        }
      },
    }),
  }),
});

export const {
  useGetAccountNewsletterStatusQuery,
  useGetNewsletterStatusQuery,
  useAccountUpdateNewsletterMutation,
  useUpdateNewsletterStatusMutation,
} = newsletterAPI;
