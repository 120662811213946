import { SenderProfilePageSummary } from '../contact-list/contact-list.types';

export interface ForumTag {
  id: number,
  name: string,
  usage: number
}

export interface ForumTagResponse {
  id: number,
  value: number,
  label: string,
  usage: number
}

export interface ForumQuestionCreate {
  title: string,
  description: string,
  tags: ForumTagResponse[]
}

export enum QuestionSortFilters {
  newestFirst = 'newestFirst',
  oldestFirst = 'oldestFirst',
  hottest = 'hottest',
  unanswered = 'unanswered',
}

export enum AnswersSortFilters {
  newestFirst = 'newestFirst',
  oldestFirst = 'oldestFirst',
  mostVoted = 'mostVoted',
  lessVoted = 'lessVoted',
}

export interface GetQuestionsRequest { // todo: use after API updated
  pageNumber: number,
  pageSize: number,
  filters: {
    filter: {
      tagIds: number[],
      creatorProfilePageId: number | null,
    }
    sorting: QuestionSortFilters,
  }
}

export interface GetQuestionsSearchRequest {
  pageNumber: number,
  pageSize: number,
  searchInput: string,
  searchIdentifier: string, // uuid
}

export interface QuestionEntry {
  id: number,
  content: string,
  createdAtUtc: string,
  updatedAtUtc: string,
  creatorProfilePageSummary: SenderProfilePageSummary | null,
  edited: boolean,
  tags: ForumTag[],
  votesCounter: number,
  title: string,
  views: number,
  totalAnswers: number
}

export interface GetQuestionsResponse {
  id?: string, // search ID
  pageNumber: number,
  pageSize: number,
  totalRecords: number,
  totalPages: number,
  data: QuestionEntry[],
}

export interface GetQuestionResponse {
  id: number,
  content: string,
  createdByRequester: boolean,
  creatorProfilePageSummary: SenderProfilePageSummary | null,
  edited: boolean,
  requesterVote: VoteOperation | null,
  tags: ForumTag[],
  title: string,
  views: number,
  votesCounter: number,

  createdAtUtc: string,
  updatedAtUtc: string,
}

export interface QuestionAnswerRequest {
  questionID: number,
  profileID: number,
  pageNumber: number,
  pageSize: number,
  sorting: AnswersSortFilters,
}

export interface Answer {
  id: number,
  createdAtUtc: string,
  updatedAtUtc: string,
  content: string,
  createdByRequester: boolean,
  requesterVote: VoteOperation | null,
  creatorProfilePageSummary: SenderProfilePageSummary | null,
  edited: boolean,
  votesCounter: number,
}

export interface QuestionAnswerResponse {
  pageNumber: number,
  pageSize: number,
  totalRecords: number,
  totalPages: number,
  data: Answer[],
}

export enum VoteOperation {
  upVote = 'upvote',
  downVote = 'downvote',
  removeVote = 'removevote',
}
