import React, { useState } from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import Input from '../../elements/Input';
import Textarea from '../../elements/Textarea';
import Button from '../../elements/Button';
import { useContactFormMutation } from './landingPageAPI';
import SuccessBlock from '../../elements/SuccessBlock';
import {
  RulesForFieldsType, RuleTypes, useFormValidation, ValidationError,
} from '../../helpers/validation';

type ValidationKeys = 'name' | 'message' | 'email';

const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  name: {
    userFriendlyFieldName: 'Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 255,
  },
  message: {
    userFriendlyFieldName: 'Message',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 10,
    maxLength: 1000,
  },
  email: {
    userFriendlyFieldName: 'Email',
    ruleType: RuleTypes.email,
    required: true,
    maxLength: 200,
  },
};

const validationErrorState: ValidationError<ValidationKeys> = {
  name: { errorMessage: '' },
  message: { errorMessage: '' },
  email: { errorMessage: '' },
};

export default function ContactUsForm() {

  const [emailInput, setEmailInput] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const [showSuccessBlock, setShowSuccessBlock] = useState(false);
  const [requestInProgress, seRequestInProgress] = useState(false);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      email: emailInput,
      message,
      name,
    },
  );

  const [contactFormRequest] = useContactFormMutation();

  const onFormSubmit = async () => {
    if (requestInProgress) return;

    const val = validate();

    if (! val.allValid) { return; }

    seRequestInProgress(true);

    const response = await contactFormRequest({
      name,
      message,
      email: emailInput,
    });

    if (! Object.prototype.hasOwnProperty.call(response, 'error')) { // on success
      setName('');
      setEmailInput('');
      setMessage('');
      setShowSuccessBlock(true);
    }

    seRequestInProgress(false);
  };

  return (
    <Fade right>
      <div className="bg-main-900 shadow-md rounded-md pt-8 px-8 pb-12 min-w-[40%] z-10 mt-4 sm:mt-10 md:mt-0" style={{ maxWidth: 441 }}>
        <p className="pt-5 pb-1 font-bold text-white text-xl">Contact us</p>

        {
          ! showSuccessBlock && (
            <>
              <div className="mt-4">
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    onValidationInputChange('name');
                  }}
                  validationMessage={validationState.name.errorMessage}
                  id="landing-contact-name"
                  label="Name"
                  placeholder="Name"
                />
              </div>

              <div className="mt-4">
                <Input
                  value={emailInput}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                    onValidationInputChange('email');
                  }}
                  validationMessage={validationState.email.errorMessage}
                  id="landing-contact-email"
                  label="Email"
                  placeholder="Email"
                />
              </div>

              <div className="mt-4">
                <Textarea
                  value={message}
                  placeholder="Type your message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                    onValidationInputChange('message');
                  }}
                  validationMessage={validationState.message.errorMessage}
                />
              </div>

              <div className="flex justify-between mt-4 items-center">
                <a href="mailto:Contact@go-Nucleo.com" className="pr-2.5 lg:pr-12 font-semibold text-main-400 text-sm underline">
                  Contact@go-Nucleo.com
                </a>
                <div className={`${requestInProgress && 'animate-pulse'}`}>
                  <Button
                    size="small"
                    inverted
                    onClick={onFormSubmit}
                    description="Submit"
                  />
                </div>
              </div>
            </>
          )
        }

        {
          showSuccessBlock && (
            <SuccessBlock
              onClick={() => setShowSuccessBlock(false)}
              title="Message sent"
              description="We will review your request and come back to you as soon as possible. Thank you!"
              successful
              buttonToRight
              buttonSize="medium"
              inverted
            />
          )
        }

      </div>
    </Fade>
  );
}
