import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../helpers/styling';
import { emptySplitApi } from '../../app/api';
import { resetFeedList } from '../feed/feedSlice';
import { useAppDispatch } from '../../app/hooks';
import { COMPANY_NAME } from '../../helpers/branding';
import Logo from '../../elements/Logo';

interface MobileNavigationProps {
  menuOptions: any[],
  isOpen: boolean,
  setOpen: (flag: boolean) => void
}

// TODO: use redux / context
const footerLinks = [
  {
    name: 'Privacy Policy',
    link: '/privacy-policy',

  },
  {
    name: 'Terms of Use',
    link: '/terms-of-use',

  },
  {
    name: '',
    link: '/',

  },
];

export default function MobileNavigation(props: MobileNavigationProps) {

  const { menuOptions, isOpen, setOpen } = props;

  const dispatch = useAppDispatch();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="absolute inset-0 bg-opacity-100 transition-opacity"
            />
          </Transition.Child>
          <div className="fixed inset-y-0 left-0 -pr-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-500"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-500"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-screen max-w-xs">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -ml-8 py-6 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-neutral-300 hover:text-black focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6 block h-8 shrink-0 flex">
                    <Logo />
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        {menuOptions.map((option) => (
                          <NavLink
                            key={option.name}
                            onClick={() => {
                              if (option.onClick) option.onClick(dispatch);
                            }}
                            to={option.link}
                            activeClassName="bg-main-50 border-main-600 text-main-600"
                            className={classNames(
                              'mt-2 rounded-md border-transparent text-neutral-600 hover:bg-neutral-50 hover:text-neutral-900',
                              'group flex items-center px-3 py-2 text-sm font-medium border-l-4',
                            )}
                          >
                            <option.icon
                              className={classNames(
                                option.current ? 'text-main-500' : 'text-neutral-400 group-hover:text-neutral-500',
                                'mr-3 shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            {option.name}
                          </NavLink>
                        ))}
                      </div>
                      <ul className="mt-4 space-y-4">
                        {
                          footerLinks.map((link) => (
                            <li key={link.name}>
                              <a
                                href={link.link}
                                target="_blank"
                                rel="noreferrer"
                                className="text-sm text-neutral-500 hover:text-neutral-900"
                              >
                                {link.name}
                              </a>
                            </li>
                          ))
                        }

                        <li key="corp">
                          <span className="text-sm text-neutral-500 hover:text-neutral-900">
                            {COMPANY_NAME}
                            {' '}
                            ©
                            {new Date().getFullYear()}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
