import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import Input from '../../../elements/Input';
import Button from '../../../elements/Button';
import { forgotPasswordRequest } from '../../../authConfig';
import { RootState } from '../../../app/store';
import { useAppDispatch } from '../../../app/hooks';
import { updateAccountDetails } from '../../../app/account';
import {
  RulesForFieldsType, RuleTypes, useFormValidation, ValidationError,
} from '../../../helpers/validation';

type ValidationKeys = 'name' | 'surname' | 'email';

const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  name: {
    userFriendlyFieldName: 'Name',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 1,
    maxLength: 100,
  },
  surname: {
    userFriendlyFieldName: 'Surname',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 1,
    maxLength: 100,
  },
  email: {
    userFriendlyFieldName: 'Email',
    ruleType: RuleTypes.email,
    required: true,
    maxLength: 200,
  },
};

const validationErrorState: ValidationError<ValidationKeys> = {
  email: { errorMessage: '' },
  name: { errorMessage: '' },
  surname: { errorMessage: '' },
};

export default function PersonalDetails() {

  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  // todo: set on mount vs
  const [emailInput, setEmailInput] = useState('');
  const [name, setName] = useState('');
  const [surnameInput, setSurnameInput] = useState('');

  const {
    firstName, surname, email,
  } = useSelector((state: RootState) => state.account);

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      email: emailInput,
      name,
      surname: surnameInput,
    },
  );

  useEffect(() => {
    setEmailInput(email);
    setName(firstName);
    setSurnameInput(surname);
  }, [email, firstName, surname]);

  const [updatingDetails, setUpdatingDetails] = useState(false);

  const onUpdate = async () => {

    const val = validate();

    if (! val.allValid) { return; }
    
    setUpdatingDetails(true);
    
    try {
      await dispatch(updateAccountDetails({ name, email: emailInput, surname: surnameInput }));
    } catch (e) {
      // nothing thrown
    }

    setUpdatingDetails(false);
  };

  return (
    <div className="bg-white px-12 py-6 mt-2 rounded-md shadow-sm">
      <h2 className="text-2xl font-semibold py-3 text-neutral-800">
        Personal Details
      </h2>

      <div>
        <Input
          value={emailInput}
          onChange={(e) => {
            setEmailInput(e.target.value);
            onValidationInputChange('email');
          }}
          validationMessage={validationState.email.errorMessage}
          id="settings-email"
          label="Email"
          placeholder="Email"
        />
      </div>
      {/* <div className="mt-4"> */}
      {/*  <Input */}
      {/*    value={email} */}
      {/*    onChange={(e) => setEmail(e.target.value)} */}
      {/*    id="settings-email" */}
      {/*    label="Alternative Email" */}
      {/*    placeholder="Alternative Email" */}
      {/*  /> */}
      {/* </div> */}

      <div className="mt-4">
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            onValidationInputChange('name');
          }}
          validationMessage={validationState.name.errorMessage}
          id="settings-name"
          label="Name"
          placeholder="Name"
        />
      </div>
      <div className="mt-4">
        <Input
          value={surnameInput}
          onChange={(e) => {
            setSurnameInput(e.target.value);
            onValidationInputChange('surname');
          }}
          validationMessage={validationState.surname.errorMessage}
          id="settings-surname"
          label="Surname"
          placeholder="Surname"
        />
      </div>
      <div className="flex justify-end mt-4">
        <Button
          size="big"
          onClick={onUpdate}
          description="Update details"
          isLoading={updatingDetails}
        />
      </div>

      {/* Password section */}
      <div className="border border-neutral-200 rounded-md mt-4">
        <div className="p-4">
          <Button
            inverted
            size="big"
            onClick={async () => {
              // await instance.logout();

              await instance.loginRedirect(forgotPasswordRequest);
            }}
            description="Change password"
          />
        </div>
      </div>

    </div>
  );
}
