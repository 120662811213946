import React, { useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import UserAvatar from '../../../../elements/UserAvatar';
import PostTextarea from '../../../post/create-post/PostTextarea';
import PostOnBehalfMessage from '../../../post/create-post/PostOnBehalfMessage';
import Button from '../../../../elements/Button';
import { RootState } from '../../../../app/store';
import { GetQuestionResponse } from '../../forum-types';
import { usePostQuestionAnswerMutation } from './answersAPI';
import { useFormValidation } from '../../../../helpers/validation';
import { rulesForumAnswerForm, validationErrorState, ValidationKeys } from '../../answerValidationRules';

interface PostAnswerProps {
  question?: GetQuestionResponse
}

export default function PostAnswer(props: PostAnswerProps) {

  const { question } = props;

  const [answerText, setAnswerText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentProfile } = useSelector((state: RootState) => state.account);

  const [postAnswerQuery] = usePostQuestionAnswerMutation();

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForumAnswerForm,
    validationErrorState,
    {
      answerText,
    },
  );

  const onSubmit = async () => {
    if (! currentProfile || ! currentProfile.profileID || ! question) return;

    const val = validate();

    if (! val.allValid) return;

    setIsSubmitting(true);

    const response = await postAnswerQuery({
      creatorProfileID: currentProfile.profileID,
      questionID: question.id,
      form: { answerText },
    });

    setAnswerText('');

    setIsSubmitting(false);
  };

  return (
    <div className="relative bg-white pt-1 px-6 pb-6 mb-2 shadow shadow-lg rounded rounded-lg flex flex-row mt-4">
      <div className="r-5 pt-4 mr-4">
        <UserAvatar
          profilePageType={currentProfile.profilePageType}
          profileImageUrl={currentProfile.profileImageUrl}
          size="medium"
        />
      </div>

      {/* Right side */}
      <div className="w-full pt-4">

        <PostTextarea
          text={answerText}
          onChange={(e) => {
            setAnswerText(e.target.value);
            onValidationInputChange('answerText');
          }}
          placeholder="Type your answer..."
          minHeight="h-24"
          validationMessage={validationState.answerText.errorMessage}
        />

        <PostOnBehalfMessage />

        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setAnswerText('')}
            size="x-medium"
            description="Clear"
            inverted
            redText
            iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
          />
          <Button
            onClick={onSubmit}
            size="x-medium"
            description="Post Answer"
            disabled={isSubmitting}
            iconLeft={<PencilSquareIcon className="w-4 h-4 mr-2" />}
          />
        </div>
      </div>
    </div>
  );
}
