/* eslint-disable react/no-array-index-key */

import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Licence } from '../../products-module.types';
import Button from '../../../../../../elements/Button';
import { useAppDispatch } from '../../../../../../app/hooks';
import { addLicenceNumber } from '../../productsModuleSlice';
import LicenceNumberField from './fields/LicenceNumberField';

interface LicenceNumberProps {
  productID: number,
  licences?: Licence[],
}
export default function LicenceNumber(props: LicenceNumberProps) {

  const  { productID, licences } = props;

  const dispatch = useAppDispatch();

  return (
    <div className="p-2 border rounded-md bg-neutral-25">
      <p className="p-2 text-xs text-neutral-700">
        Licence Numbers
      </p>

      <div className="my-2.5">
        <div>
          {
            (licences && licences.length > 0) && licences.map((licence, idx) => (
              <LicenceNumberField key={idx} licence={licence} idx={idx} productID={productID} />
            ))
          }
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button
            onClick={() => dispatch(addLicenceNumber({ productID }))}
            size="small"
            description="Add Licence"
            iconLeft={<PlusIcon className="w-4 h-4" />}
            roundedFull
            inverted
          />
        </div>
      </div>
    </div>
  );
}
