import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import Zoom from 'react-medium-image-zoom';
import messagingImg from './assets/messaging-img.png';

export default function FeatureMessaging() {

  return (
    <div className="z-10 w-full flex flex-col xl:flex-row max-w-6xl w-full px-4 md:px-6 xl:px-0 -mt-2">
      <Fade left>
        <div
          className="min-w-fit pr-0 md:pr-10 pl-6 xl:pl-0 my-6 xl:my-0 pt-0 xl:pt-20"
        >
          <h3 className="text-main-900 text-5.5xl font-extrabold">
            Messaging & Contacts
          </h3>
          <p className="text-neutral-700 text-base font-medium mt-6" style={{ maxWidth: 530 }}>
            Directly message and contact real life sciences professionals and
            businesses relevant to your business needs on the Nucleo platform
            without the need to enter into back-and-forth e-mail exchanges until you are ready.
          </p>
        </div>
      </Fade>

      <Fade right>
        <Zoom>
          <div className="w-full h-full flex items-center justify-center">
            <img
              className="feature-image"
              src={messagingImg}
              alt="Messaging example"
            />
          </div>
        </Zoom>
      </Fade>
    </div>
  );
}
