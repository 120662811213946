import React, { useEffect } from 'react';
import { ChatClient } from '@azure/communication-chat';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import Messages from './Messages';
import { setChatMessageReceivedObservable, setUserIsTyping } from './messagesSlice';
import { useAppDispatch } from '../../app/hooks';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { emptySplitApi } from '../../app/api';
import { RootState } from '../../app/store';

interface MessagesWrapProps {
  chatClient?: ChatClient,
}

export default function MessagesWrap(props: MessagesWrapProps) {

  const { chatClient } = props;

  const dispatch = useAppDispatch();

  const { currentProfileAzureCommunicationID } = useSelector((state: RootState) => state.chatMessages);

  // subscribe to events on render
  // @ts-ignore
  useEffect(() => {

    if (! chatClient || ! currentProfileAzureCommunicationID) return;

    (async () => {

      await chatClient.startRealtimeNotifications();

      // console.log('SUBSCRIBE TO CHAT EVENTS');

      // typingIndicatorReceived
      chatClient.on('typingIndicatorReceived', (e) => {
        dispatch(setUserIsTyping({
          // @ts-ignore - bug with Azure types
          senderID: e.sender.communicationUserId,
          threadID: e.threadId,
        }));
      });

      // chatMessageReceived
      chatClient.on('chatMessageReceived', (e) => {

        dispatch(setChatMessageReceivedObservable({
          ...e,
          createdOn: DateTime.fromJSDate(e.createdOn).toISO(),
        }));

        // @ts-ignore Azure types bug
        const { sender: { communicationUserId } } = e;

        if (communicationUserId !== currentProfileAzureCommunicationID) {
          dispatch(addFlashMessage(
            'info',
            `New message from ${e.senderDisplayName}`,
            true,
            undefined,
            true,
          ));
        }
      });

      chatClient.on('chatThreadDeleted', () => {
        dispatch(emptySplitApi.util.invalidateTags(['ChatThreadsList']));
      });
      chatClient.on('chatThreadCreated', () => {
        dispatch(emptySplitApi.util.invalidateTags(['ChatThreadsList']));
      });

    })();

    // todo: unsubscribe when chat client (profile ID has changed?)
    // eslint-disable-next-line consistent-return
    return () => (async () => {
      // console.log('Unsubscribe chat events');
      await chatClient.stopRealtimeNotifications();
    })();

    // eslint-disable-next-line
  }, [chatClient, currentProfileAzureCommunicationID]); // subscribe only once OR when chat client has changes

  if (chatClient) {
    return <Messages chatClient={chatClient} />;
  }

  return null;
}
