import React, {
  useState, Fragment, useMemo,
} from 'react';
import { ChatBubbleOvalLeftEllipsisIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { RootState } from '../../../app/store';
import Textarea from '../../../elements/Textarea';
import Button from '../../../elements/Button';
import {
  useAcceptMessageRequestMutation,
  useCancelMessageRequestMutation,
  useGetUserThreadsQuery,
  useRejectMessageRequestMutation,
  useSendChatRequestMutation,
} from '../../messages/messagesAPI';
import { useAppDispatch } from '../../../app/hooks';
import { setCurrentChatWindow, setThreadUnreadCounter } from '../../messages/messagesSlice';
import {
  RulesForFieldsType, RuleTypes, useFormValidation, ValidationError,
} from '../../../helpers/validation';
import { ChatRequestStatus } from '../../messages/messages-types';

type ValidationKeys = 'message';

const rulesForFields: RulesForFieldsType<ValidationKeys> = {
  message: {
    userFriendlyFieldName: 'Message',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 10,
    maxLength: 500,
  },
};

const validationErrorState: ValidationError<ValidationKeys> = {
  message: { errorMessage: '' },
};

export default function MessageButton() {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const [validationState, validate, onValidationInputChange] = useFormValidation<ValidationKeys>(
    rulesForFields,
    validationErrorState,
    {
      message,
    },
  );

  const {
    allowPageEdit,
    profileOfCurrentUser: { name, requesterMessagingStatus },
    currentlyLoadedProfileID,
  } = useSelector((state: RootState) => state.profilePage);

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);
  const { disclosureIsOpen } = useSelector((state: RootState) => state.chatMessages);

  const dispatch = useAppDispatch();

  const [sendChatRequest, { isLoading: isSendingChatRequest }] = useSendChatRequestMutation();

  const { data } = useGetUserThreadsQuery({ profileID });

  const threadData = useMemo(() => {
    if (! data) return null;

    return find(data.chats, { chatMember: { profilePageSummary: { profilePageId: currentlyLoadedProfileID } } });
  }, [data, currentlyLoadedProfileID]);

  const [acceptRequest] = useAcceptMessageRequestMutation();
  const [cancelRequest] = useCancelMessageRequestMutation();

  const openChat = () => {

    if (threadData && data && data.azureCommunicationIdentity) {
      dispatch(setCurrentChatWindow({
        threadId: threadData.azureThreadId,
        profilePageSummary: threadData.chatMember.profilePageSummary,
        // @ts-ignore
        currentProfileAzureCommunicationID: data.azureCommunicationIdentity,
        userIsTyping: {
          flag: false,
          nanoid: '',
        },
        threadMemberStatus: threadData.threadMemberStatus,
      }));

      dispatch(setThreadUnreadCounter({ threadID: threadData.azureThreadId, unreadCount: 0 })); // reset as user opened chat

      if (! disclosureIsOpen) { // trigger opening disclosure
        // @ts-ignore
        document.querySelector('.id-chat-messages-disclosure')?.click();
      }
    }
  };

  const onMessageButtonClick = () => {

    if (requesterMessagingStatus === ChatRequestStatus.pending && currentlyLoadedProfileID) {
      cancelRequest({
        senderProfilePageId: profileID,
        recipientProfilePageId: currentlyLoadedProfileID,
      });
      return;
    }

    if (requesterMessagingStatus === ChatRequestStatus.received && currentlyLoadedProfileID) {
      acceptRequest({
        senderProfilePageId: currentlyLoadedProfileID,
        recipientProfilePageId: profileID,
      });
      return;
    }

    if (! threadData || ! data || ! data.azureCommunicationIdentity) {
      setOpen(true);
      return;
    }

    openChat();
  };

  if (allowPageEdit) {
    return null;
  }

  let text = 'Message';
  if (requesterMessagingStatus === ChatRequestStatus.pending) {
    text = 'Cancel message request';
  }
  if (requesterMessagingStatus === ChatRequestStatus.received) {
    text = 'Accept message request';
  }

  return (
    <>
      <button
        type="button"
        onClick={onMessageButtonClick}
        className="inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none "
      >
        <ChatBubbleOvalLeftEllipsisIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>{text}</span>
      </button>

      { /* SEND REQUEST DIALOG */ }
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <p className="font-semibold text-left pb-4">
                  Send message request to
                  {' '}
                  {name}
                  :
                </p>
                <p className="text-xs font-semibold text-neutral-600 pl-2 mb-1">
                  Message:
                </p>
                <Textarea
                  value={message}
                  placeholder="Type your message..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                    // NOTE: good example why TS is needed (for Miquel)
                    onValidationInputChange('message');
                  }}
                  validationMessage={validationState.message.errorMessage}
                />

                <div className="pt-4 flex justify-end">
                  <Button
                    onClick={async () => {
                      if (! currentlyLoadedProfileID || ! profileID) return;

                      const val = validate();

                      if (! val.allValid) { return; }

                      await sendChatRequest({
                        message,
                        recipientProfilePageId: currentlyLoadedProfileID,
                        senderProfilePageId: profileID,
                      });

                      setMessage('');
                      setOpen(false);
                    }}
                    size="medium"
                    iconLeft={<PaperAirplaneIcon className="w-4 h-4 transform rotate-45 mr-2 hover:rotate-90 transition-transform" />}
                    description="Send request"
                    isLoading={isSendingChatRequest}
                  />
                </div>
              </div>

            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
