import React from 'react';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import Button from '../../../../elements/Button';
import { RootState } from '../../../../app/store';
import { useGetTeamModuleQuery } from './teamModuleAPI';
import ChatButton from './components/ChatButton';
import ModuleLoader from '../../components/ModuleLoader';
import MailButton from './components/MailButton';
import UserAvatar from '../../../../elements/UserAvatar';
import { ProfilePageType } from '../../../../app/account';

interface TeamModuleViewInterface {
  setEditMode?: () => void
}

// todo: if no members yet - display "Edit this module" button
export default function TeamModuleView(props: TeamModuleViewInterface) {

  const { setEditMode } = props;

  const { allowPageEdit, currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);

  const { data, isLoading, isFetching } = useGetTeamModuleQuery({ id: currentlyLoadedProfileID }, { skip: ! currentlyLoadedProfileID });

  if (! data || isLoading || isFetching) {
    return (
      <ModuleLoader />
    );
  }

  const noDataFlag = allowPageEdit
    && (! data
      || (data && (! data.description && data.moduleCorporationTeamMembers.length === 0))
    );

  if (noDataFlag) { // empty state
    return (
      <>
        <div className="px-2 py-2 bg-neutral-25 flex-row items-center">
          <div className="w-max">
            <Button
              onClick={() => {
                if (! setEditMode) return;
                setEditMode();
              }}
              size="small"
              description="Edit this module"
              inverted
              roundedFull
              iconLeft={<PlusSmallIcon className="w-4 h-4" />}
            />
          </div>
        </div>
        <div className="w-full pt-4 border-t" />
      </>
    );
  }

  return (
    <>
      <div className="p-6">
        <p className="text-sm font-semibold pt-4 pl-4">
          {
            data && data.description
          }
        </p>

        <ul
          className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-2 md:grid-cols-4 pt-12"
        >
          {data.moduleCorporationTeamMembers.map((person, idx) => (
            <li key={person.id}>
              <div
                style={{ height: 320, zIndex: 1 + idx }}
                className="relative space-y-2 overflow-hidden
                shadow hover:shadow-lg transition-shadow rounded-lg p-4"
              >
                <div className="w-full flex justify-center">
                  <UserAvatar profilePageType={ProfilePageType.individual} profileImageUrl={person.profileImageUrl} size="big" />
                </div>
                {/* <img className="mx-auto h-32 w-32 rounded-full" src={l} alt="" /> */}
                <div className="pt-1 h-40 flex flex-col justify-between">
                  <div className="text-lg leading-6 text-base py-4 text-center">
                    <h3 className="font-semibold">{person.fullName}</h3>
                    <p className="text-sm">{person.jobPosition}</p>
                  </div>

                  <div className="flex flex-row justify-center pb-2">
                    <MailButton email={person.email} displayEmail={person.displayEmail} />

                    <ChatButton profilePageID={person.profilePageId} />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="w-full pt-4 border-t" />
    </>
  );
}
