import React, { useEffect, useState } from 'react';
import { createFilter, OnChangeValue, SingleValue } from 'react-select';
import { TrashIcon } from '@heroicons/react/20/solid';
import { clone, find } from 'lodash';
import { Dosage, StrengthUnitFromQuery } from '../../../products-module.types';
import { useGetDosageFormsQuery, useGetStrengthUnitsQuery } from '../../../productsModuleAPI';
import InputLoader from '../../../../../../../elements/InputLoader';
import { deleteDosageForm, editDosageForm } from '../../../productsModuleSlice';
import { useAppDispatch } from '../../../../../../../app/hooks';
import AppSelect from '../../../../../../../elements/AppSelect';
import Button from '../../../../../../../elements/Button';
import StrengthByIngredient from './StrengthByIngredient';

interface DosagesGroupFieldProps {
  dosage: Dosage,
  dosageIdx: number,
  productID: number,
  presentationIdx: number,
}

export default function DosagesGroupField(props: DosagesGroupFieldProps) {

  const {
    dosage, productID, presentationIdx, dosageIdx,
  } = props;

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetDosageFormsQuery(null);
  const { data: strengthUnits, isLoading: strengthUnitsAreLoading } = useGetStrengthUnitsQuery(null);

  const [deleteMode, setDeleteMode] = useState(false);

  // set default strength unit
  useEffect(() => {
    if (! strengthUnits) return;
    const newDosage = clone(dosage);

    if (! newDosage.coreStrengthUnit || newDosage.coreStrengthUnit.id === 0) {
      newDosage.coreStrengthUnit = strengthUnits[0].valueForStore.coreStrengthUnit;
    }

    dispatch(editDosageForm({
      productID,
      presentationIdx,
      dosageIdx,
      newValue: newDosage,
    }));
  // eslint-disable-next-line
  }, [strengthUnits]); // do not add dosage as dependency

  const setValue = (): Dosage & { value: number, label: string } => {
    if (! dosage) return dosage;

    return {
      ...dosage,
      value: dosage.coreDosageForm.id,
      label: dosage.coreDosageForm.name,
    };
  };

  const strengthUnitValue = ((): StrengthUnitFromQuery | undefined => {
    if (! strengthUnits) return undefined;

    if (! dosage.coreStrengthUnit?.id) return strengthUnits[0];

    const searchResult: StrengthUnitFromQuery | undefined = find(strengthUnits, { value: dosage.coreStrengthUnit?.id });

    return searchResult || strengthUnits[0];
  })();

  // todo: edit dosage form
  const onChangeDosage = (newValue: SingleValue<Dosage>) => {

    if (! newValue) return; // ts bug

    const newDosage = clone(newValue);

    // pass current values so they won't be overwritten
    newDosage.coreStrengthUnit = dosage.coreStrengthUnit;
    newDosage.moduleProductInnStrengths = dosage.moduleProductInnStrengths;

    dispatch(editDosageForm({
      productID,
      presentationIdx,
      dosageIdx,
      newValue: newDosage,
    }));
  };

  let content = null;
  if (! data || isLoading || ! strengthUnits || strengthUnitsAreLoading) {
    content = (
      <InputLoader />
    );
  }

  if (data && data.length > 0 && strengthUnits && strengthUnits.length > 0) {

    content = (
      <>
        <AppSelect
          value={setValue()}
          onChange={onChangeDosage}
          filterOption={createFilter({ ignoreAccents: false })} // optimization
          options={data}
          placeholder="Start typing..."
        />

        <div className="mt-2">

          <AppSelect
            value={strengthUnitValue}
            options={strengthUnits}
            onChange={(newValue: OnChangeValue<StrengthUnitFromQuery, false>) => {
              const newDosage = clone(dosage);

              if (newValue) {
                const coreStrengthUnit = find(strengthUnits, { value: parseInt(newValue.value.toString(), 10) });

                if (coreStrengthUnit) {
                  newDosage.coreStrengthUnit = {
                    ...coreStrengthUnit.valueForStore.coreStrengthUnit,
                    coreStrengthUnitId: coreStrengthUnit.valueForStore.coreStrengthUnitId,
                  };
                }
              }

              dispatch(editDosageForm({
                productID,
                presentationIdx,
                dosageIdx,
                newValue: newDosage,
              }));
            }}
            placeholder="Strength unit"
          />

          <StrengthByIngredient {...props} />
        </div>
      </>
    );
  }

  return (
    <div className="p-2 border rounded-md bg-neutral-75 mt-2">
      <p className="py-1 pl-1 text-xs text-neutral-700 font-semibold">
        Form #
        {dosageIdx + 1}
      </p>

      { content }

      {/* Delete button */}
      <div className="pt-2 flex flex-row justify-between">
        <div className="flex flex-row">
          <div className="max-w-max mr-2">
            <Button
              onClick={() => setDeleteMode(true)}
              size="small"
              description=""
              roundedFull
              inverted={! deleteMode}
              fullRed
              iconLeft={<TrashIcon className="w-4 h-4" />}
            />
          </div>

          {
            deleteMode && (
              <>
                <Button
                  onClick={() => setDeleteMode(false)}
                  size="small"
                  description="Cancel"
                  roundedFull
                  fullRed
                />
                <div className="ml-2">
                  <Button
                    onClick={() => {
                      setDeleteMode(false);
                      dispatch(deleteDosageForm({ productID, presentationIdx, dosageIdx }));
                    }}
                    size="small"
                    description="Delete dosage"
                    redText
                    roundedFull
                    inverted
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
