import { RefObject, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { RulesForFieldsType } from './validation';
import { COMPANY_NAME } from './branding';
import { i18nConfig } from '../i18n';

type AnyEvent = MouseEvent | TouchEvent;

export function useDetectOutsideClick<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export function useCupidoLogin() {
  const { instance, inProgress } = useMsal();

  // console.log('useCupidoLogin()');

  function handleLogin(MsalInstance: any) {
    MsalInstance.loginRedirect(loginRequest);
  }

  return {
    instance, handleLogin, inProgress,
  };
}

export const useDocumentTitle = (title: string) => {

  useEffect(() => {
    document.title = `${COMPANY_NAME}: ${title}`;
  }, [title]);

  return null;
};

/**
 * API mimics i18n-next to be compatible and easy to replace in future
 *
 * t() function is now more or less typesafe (you can't pass the key that doesn't exist)
 *
 * TODO: fix types
 */
export const useBrandingLocale = () => {

  // @ts-expect-error
  const namespace: keyof typeof i18nConfig.resources.en = i18nConfig.defaultNS;
  // @ts-expect-error
  const language: keyof typeof i18nConfig.resources = i18nConfig.lng;

  return {
    t: (key: keyof typeof i18nConfig.resources.en.common) => {
      // @ts-expect-error
      const brandedString = i18nConfig.resources[language][namespace][key];
      if (! brandedString) return key;

      return brandedString;
    },

    tk: <T extends object>(key: keyof typeof i18nConfig.resources.en.common, replaceables: T) => {

      const replaceablesKeys = Object.keys(replaceables);

      // @ts-expect-error
      let stringToReplace = i18nConfig.resources[language][namespace][key];

      if (! stringToReplace) return key;

      for (let idx = 0; idx < replaceablesKeys.length; idx += 1) {
        const replaceablesKey = replaceablesKeys[idx] as keyof T;

        const replaceWith = replaceables[replaceablesKey] ?? '';

        stringToReplace = stringToReplace.replaceAll(`{{${replaceablesKey}}}`, replaceWith);
      }

      return stringToReplace;
    },
  };
};
