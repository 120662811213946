import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../app/store';
import { useGetCorporationMembersQuery } from '../settingsAPI';
import { useAppDispatch } from '../../../app/hooks';
import { setPage } from './membersManagementSlice';
import ModuleLoader from '../../profile-page/components/ModuleLoader';
import MemberCard from './MemberCard';
import Pagination from '../../../elements/pagination/Pagination';

interface MembersManagementListProps {
  corporationID: number,
  scrollOffset: number | null,
}

export default function MembersManagementList(props: MembersManagementListProps) {

  const { corporationID, scrollOffset } = props;

  const dispatch = useAppDispatch();

  const { getMembersListRequest } = useSelector((state: RootState) => state.corporationMembers);

  const { data: membersResponse, isFetching, isLoading } = useGetCorporationMembersQuery({
    ...getMembersListRequest,
    corporationID,
  }, { pollingInterval: 60 * 1000 /* every min */ });

  if (isLoading) {
    return (
      <ModuleLoader />
    );
  }

  if (! membersResponse) {
    return (
      <div className="flex w-full justify-center mt-8">
        <span className="font-semibold text-neutral-600">
          Something went wrong. Please try again.
        </span>
      </div>
    );
  }

  return (
    <>
      {
          isFetching && (
          <div className="flex justify-center pb-8 pt-4">
            <ArrowPathIcon className="w-6 h-6 animate-spin text-neutral-600" />
          </div>
          )
      }
      {
        (!isFetching) && membersResponse.data.length === 0 && (
        <div className="flex w-full justify-center pb-8 pt-4">
          <span className="font-semibold text-neutral-600">
            No members
            {' '}
            { getMembersListRequest.searchInput && (
            <span>
              with given search
              {' '}
              <span className="font-bold text-neutral-800">
                &quot;
                {getMembersListRequest.searchInput}
                &quot;
              </span>
            </span>
            )}
            {' '}
            to display
          </span>
        </div>
        )
      }
      {
        membersResponse.data.map((member) => (
          <MemberCard
            key={member.accountId}
            name={`${member.firstname || ''} ${member.surname || ''}`}
            avatarUrl={member.profileImageUrl}
            isAdmin={member.role === 'admin'}
            email={member.email}
            accountID={member.accountId}
            corporationID={corporationID}
            position=""
            profilePageId={member.individualProfilePageId}
            isUniqueAdministrator={member.isUniqueAdministrator}
          />
        ))
      }
      {
        membersResponse.data.length > 0 && (
        <div className="border-t py-4 mt-4 ">
          <Pagination
            pageSize={membersResponse.pageSize}
            currentPage={membersResponse.pageNumber}
            totalPages={membersResponse.totalPages}
            totalRecords={membersResponse.totalRecords}
            onPageChange={(pageNumber) => {
              dispatch(setPage(pageNumber));

              if (! scrollOffset || Number.isNaN(scrollOffset)) {
                return;
              }

              window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: scrollOffset,
              });
            }}
            label="Members"
          />
        </div>
        )
      }
    </>
  );
}
