import React from 'react';
import ModuleLayout from '../../components/ModuleLayout';
import { ModuleLayoutBaseProps } from '../modulesTypes';
import AreaOfInterestModuleView from './AreaOfInterestModuleView';
import AreaOfInterestModuleEdit from './AreaOfInterestModuleEdit';

interface AreaOfInterestModuleProps extends ModuleLayoutBaseProps {

}

export default function AreaOfInterestModule(props: AreaOfInterestModuleProps) {
  const { dragHandleProps, moduleMetadata, allowEditMetadataModule } = props;

  return (
    <ModuleLayout
      header="Area of Interest"
      onReset={() => {}}
      moduleMetadata={moduleMetadata}
      dragHandleProps={dragHandleProps}
      viewComponent={<AreaOfInterestModuleView />}
      editComponent={<AreaOfInterestModuleEdit />}
      allowEditModuleMetadata={allowEditMetadataModule}
    />
  );
}
