import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { profilePageAPI } from './profilePageAPI';
import { contactListAPI } from '../contact-list/contactListAPI';
import { 
  ContactStatus, 
  FollowStatus,
  ProfilePageResponse,
} from './profileTypes';
import { API_URL } from '../../authConfig';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { MAX_IMAGE_SIZE } from '../../helpers/config';
import { UploadBlob } from '../../app/filesSlice';
import { ChatRequestStatus } from '../messages/messages-types';
import { messagesAPI } from '../messages/messagesAPI';
import { ProfilePageType } from '../../app/account';

export interface ProfilePageState {
  profileOfCurrentUser: ProfilePageResponse,
  allowPageEdit: boolean; // match between profile ID in URL and current used profile ID
  currentlyLoadedProfileID?: number;
}

export enum ProfilePageImageType {
  cover = 'cover',
  profile = 'profile',
}

const initialState: ProfilePageState = {
  profileOfCurrentUser: { // this page will be displayed currently
    accountType: ProfilePageType.individual,
    corporationId: 0,
    coverImageUrl: '',
    followersCount: 0,
    individualAccountId: 0,
    modulesMetadata: {
      IDs: [],
      moduleAbout: null,
      moduleBusinessMarkets: null,
      moduleCorporationTeam: null,
      moduleInterest: null,
      moduleProduct: null,
      moduleService: null,
      moduleFocusCategory: null,
    },
    profileImageUrl: '',
    profilePageId: 0,
    relatedCorporationProfilePageSummary: undefined,
    requesterHasEditRights: false,
    name: '',
    parentAssociationName: '',
    requesterFollowStatus: FollowStatus.notFollowing,
    requesterContactStatus: ContactStatus.none,
    requesterMessagingStatus: ChatRequestStatus.none,
    badges: [],
    fullShowcaseEnabled: false,
    showCompany: false,
    company: '',
    showJobPosition: false,
    jobPosition: '',
    showRequestPdfProfileButton: false,
  },
  allowPageEdit: false,
  currentlyLoadedProfileID: undefined,
};

export const profilePageSlice = createSlice({
  name: 'profilePage',
  initialState,
  reducers: {
    setAllowPageEdit: (state, action) => {
      state.allowPageEdit = action.payload;
    },

    setProfileOCurrentUser: (state, action) => {
      state.profileOfCurrentUser = action.payload;
    },
    setCurrentlyLoadedProfileID: (state, action) => {
      state.currentlyLoadedProfileID = action.payload;
    },

    updateModulesOrder: (state, { payload }) => {
      state.profileOfCurrentUser.modulesMetadata.IDs = payload;
      payload.forEach((key: any, index: number) => {
        // @ts-ignore
        state.profileOfCurrentUser.modulesMetadata[key] = {
          // @ts-ignore
          ...state.profileOfCurrentUser.modulesMetadata[key],
          moduleOrder: index + 1,
        };
      });
    },

    updateModulesDisplay: (state, { payload }) => {
      state.profileOfCurrentUser.modulesMetadata = {
        ...state.profileOfCurrentUser.modulesMetadata,
        [payload.moduleID]: {
          // @ts-ignore
          ...state.profileOfCurrentUser.modulesMetadata[payload.moduleID],
          displayModule: ! payload.displayModule,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /* Contact & Follow */
      .addMatcher(profilePageAPI.endpoints.addContact.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterContactStatus = ContactStatus.pending;
        })
      .addMatcher(contactListAPI.endpoints.acceptContactRequest.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterContactStatus = ContactStatus.received;
        })
      .addMatcher(profilePageAPI.endpoints.cancelContactRequest.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterContactStatus = ContactStatus.none;
        })
      .addMatcher(profilePageAPI.endpoints.removeContact.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterContactStatus = ContactStatus.none;
          state.profileOfCurrentUser.requesterFollowStatus = FollowStatus.notFollowing;
        })
      .addMatcher(profilePageAPI.endpoints.followProfile.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterFollowStatus = FollowStatus.following;
        })
      .addMatcher(profilePageAPI.endpoints.unfollowProfile.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterFollowStatus = FollowStatus.notFollowing;
        })
      /* Messaging */
      .addMatcher(messagesAPI.endpoints.cancelMessageRequest.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterMessagingStatus = ChatRequestStatus.none;
        })
      .addMatcher(messagesAPI.endpoints.sendChatRequest.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterMessagingStatus = ChatRequestStatus.pending;
        })        
      .addMatcher(messagesAPI.endpoints.acceptMessageRequest.matchFulfilled,
        (state) => {
          state.profileOfCurrentUser.requesterMessagingStatus = ChatRequestStatus.accepted;
        });
  },
});

export const {
  updateModulesDisplay, setAllowPageEdit, updateModulesOrder,
  setCurrentlyLoadedProfileID, setProfileOCurrentUser,
} = profilePageSlice.actions;

// todo: use File upload service filesSlice
interface UploadProfileImageParams {
  file: File,
  profilePageID: number,
  requesterID: number,
  imageType: ProfilePageImageType.cover | ProfilePageImageType.profile,
}

interface DeleteProfileImageParams {
  profilePageID: number,
  requesterID: number,
  imageType: ProfilePageImageType.cover | ProfilePageImageType.profile,
}

export const uploadProfileImage = createAsyncThunk(
  'profilePage/uploadProfileImage',
  async ({
    file, profilePageID, requesterID, imageType,
  }: UploadProfileImageParams, { dispatch }) => {

    try {

      let blobContainer: string = UploadBlob.profilePageAvatar;
      if (imageType === ProfilePageImageType.cover) {
        blobContainer = UploadBlob.profilePageCover;
      }

      if (file.size > MAX_IMAGE_SIZE) {
        dispatch<any>(addFlashMessage('error', 'Maximum allowed file size is 5Mb'));
        return;
      }

      const formData = new FormData();
      formData.append('AttachmentFile', file);
      formData.append('blobContainer', blobContainer);

      const postImageURL = `${API_URL}/AzureStorage/profile-page/${profilePageID}/requester-profile-page/${requesterID}/attachment/upload`;
      const response: any = await axios.post(postImageURL, formData);

      // no response, only status
      await axios.put(`${API_URL}/profilePage/${profilePageID}/requester-profile-page/${requesterID}/image`, {
        imageType, // duplicate
        profilePictureGuid: response.data.id,
      });

      dispatch<any>(addFlashMessage('success', 'Image was updated'));
    } catch (e) {
      dispatch<any>(addFlashMessage('error', 'Image update has failed'));
      throw e;
    }
  },
);

export const deleteProfileImage = createAsyncThunk(
  'profilePage/deleteProfileImage',
  async ({
    profilePageID, requesterID, imageType,
  }: DeleteProfileImageParams, { dispatch }) => {

    try {

      const postImageURL = `${API_URL}/ProfilePage/${profilePageID}/requester-profile-page/${requesterID}/image/${imageType}`;
      const response: any = await axios.delete(postImageURL);

      dispatch<any>(addFlashMessage('success', 'Image was updated'));
    } catch (e) {
      dispatch<any>(addFlashMessage('error', 'Image update has failed'));
      throw e;
    }
  },
);

export default profilePageSlice.reducer;
