import React from 'react';
import { useSelector } from 'react-redux';
import {
  useGetChatInvitationsQuery,
} from './messagesAPI';
import { RootState } from '../../app/store';
import ChatInviteRequest from './ChatInviteRequest';

export default function ChatInvitations() {

  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const { data, isLoading } = useGetChatInvitationsQuery({ profileID }, {
    skip: !profileID,
    pollingInterval: 60 * 1000, // every min
  });

  if (! data || data.length === 0 || isLoading) return null;

  const requestToDisplay = data[0];

  return (
    <ChatInviteRequest chatRequest={requestToDisplay} />
  );
}
