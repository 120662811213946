import { VoteOperation } from '../forum-types';

export default function calculatedCachedVote(
  previousVote: VoteOperation | null,
  newVote: VoteOperation,
  votesCounter: number,
) {

  if (newVote === VoteOperation.removeVote) {
    if (previousVote === VoteOperation.upVote) return votesCounter - 1;
    if (previousVote === VoteOperation.downVote) return votesCounter + 1;
  }

  let multiplier = 1;
  if (previousVote) { // if user already voted
    multiplier = 2;
  }

  if (newVote === VoteOperation.upVote) return votesCounter + multiplier;
  if (newVote === VoteOperation.downVote) return votesCounter - multiplier;

  return votesCounter;
}
