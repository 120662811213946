import { emptySplitApi } from '../../../../app/api';
import {
  BusinessMarketsModuleGet,
  UpdateBusinessMarketsRequest,
} from './business-markets-module-types';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';

export const businessMarketsModuleAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessMarketsModule: builder.query<BusinessMarketsModuleGet, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/BusinessMarket/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),
      providesTags: (result, error, arg) => [{
        type: 'BusinessMarketsModule',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],

    }),

    // todo: extract to separate common modules API ?
    getTerritories: builder.query<any, null>({ // todo type
      query: () => ({
        url: '/Territory',
      }),
    }),

    updateBusinessMarketsModule: builder.mutation<any, UpdateBusinessMarketsRequest>({

      query(data) {
        const { id, requesterID, ...body } = data;

        return {
          url: `/modules/BusinessMarket/profile-page/${id}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Business markets module details are successfully updated'));
        } catch {
          dispatch(addFlashMessage('error', 'Update went wrong. Please try again.'));
        }
      },

      invalidatesTags: (result, error, arg) => [{
        type: 'BusinessMarketsModule',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
    }),
  }),
});

export const {
  useGetBusinessMarketsModuleQuery,
  useGetTerritoriesQuery,
  useUpdateBusinessMarketsModuleMutation,
} = businessMarketsModuleAPI;
