import React, { MouseEvent, useState } from 'react';
import {
  ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon, XMarkIcon,
} from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import Button from '../../../../../elements/Button';
import { useAppDispatch } from '../../../../../app/hooks';
import Input from '../../../../../elements/Input';
import { setDeleteGroupModalData } from '../productsModuleSlice';
import { useUpdateGroupNameMutation } from '../productsModuleAPI';
import { RootState } from '../../../../../app/store';

interface GroupViewHeaderProps {
  groupName: string,
  groupID: number,
  open: boolean,
  inEditMode: boolean,
  productIDs: number[],
}

export default function GroupViewHeader(props: GroupViewHeaderProps) {

  const {
    groupID, groupName, inEditMode, open, productIDs,
  } = props;

  const [groupInEditMode, setGroupInEditMode] = useState(false);
  const [groupNameForEdit, setGroupNameForEdit] = useState(groupName);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  
  const { currentProfile: { profileID } } = useSelector((state: RootState) => state.account);

  const [updateGroup] = useUpdateGroupNameMutation();

  const dispatch = useAppDispatch();

  const onGroupEdit = (e: MouseEvent) => {
    e.stopPropagation();

    setGroupInEditMode(true);
  };

  const onGroupNameSave = (e: MouseEvent) => {
    e.stopPropagation();

    if (! currentlyLoadedProfileID) return;

    updateGroup({
      groupID,
      profileID: currentlyLoadedProfileID,
      productGroupName: groupNameForEdit,
      requesterID: profileID,
    });

    setGroupInEditMode(false);
  };

  const onGroupDelete = (e: MouseEvent) => {
    e.stopPropagation();

    dispatch(setDeleteGroupModalData({
      groupID,
      groupName: groupNameForEdit,
      productIDs,
      showModal: true,
    }));
  };

  return (
    <div className={`${groupInEditMode ? 'bg-main-25' : ''} w-full flex flex-row justify-between`}>
      {
        groupInEditMode ? (
          <div className="px-4 py-4 w-1/2">
            <Input
              value={groupNameForEdit}
              placeholder="Placeholder"
              onChange={(e) => {
                e.stopPropagation();
                setGroupNameForEdit(e.target.value);
              }}
            />
          </div>
        ) : (
          <span className="font-bold text-base px-4 py-6 text-neutral-800">
            {groupNameForEdit}
          </span>
        )
      }
      <div className="flex flex-row items-center">
        {
          inEditMode && (
            <div className="mr-2.5 flex flex-row gap-2">
              <Button
                onClick={onGroupDelete}
                size="small"
                inverted
                redText
                roundedFull
                // disabled
                iconLeft={<TrashIcon className="w-4 h-4 mr-2" />}
                description="Delete"
              />
              {
                groupInEditMode ? (
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setGroupNameForEdit(groupName); // reset group name
                        setGroupInEditMode(false);
                      }}
                      size="small"
                      inverted
                      roundedFull
                      // disabled
                      iconLeft={<XMarkIcon className="w-4 h-4 mr-2" />}
                      description="Cancel"
                    />
                    <Button
                      onClick={onGroupNameSave}
                      size="small"
                      roundedFull
                      // disabled
                      iconLeft={<PlusIcon className="w-4 h-4 mr-2" />}
                      description="Save"
                    />
                  </>
                ) : (
                  <Button
                    onClick={onGroupEdit}
                    size="small"
                    inverted
                    roundedFull
                    // disabled
                    iconLeft={<PencilIcon className="w-4 h-4 mr-2" />}
                    description="Edit"
                  />
                )
              }
            </div>
          )
        }
        <ChevronDownIcon
          className={`${open ? 'transform rotate-180' : ''} w-4 h-4 mr-5 text-main-400`}
        />
      </div>
    </div>
  );
}
