import { RuleTypes, RulesForFieldsType, ValidationError } from '../../../../helpers/validation';

export type ValidationKeys = 'title';

export const rulesPostPdf: RulesForFieldsType<ValidationKeys> = {
  title: {
    userFriendlyFieldName: 'text',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 255,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  title: { errorMessage: '' },
};
