import React, {
  useEffect, useMemo, useRef, useState,
  KeyboardEvent,
} from 'react';
import { Link } from 'react-router-dom';
import { isEqual, reverse } from 'lodash';
import { ChatClient } from '@azure/communication-chat';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { NoSymbolIcon, PencilIcon } from '@heroicons/react/20/solid';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import Textarea from '../../elements/Textarea';
import Button from '../../elements/Button';
import EnterIcon from '../../elements/icons/EnterIcon';
import { RootState } from '../../app/store';
import UserAvatar from '../../elements/UserAvatar';
import MessagePreview from './MessagePreview';
import { CommunicationThreadMembersStatus, SliceChatMessage } from './messages-types';
import { useSubscribeToChatMessageReceived } from './hooks';
import { useAppDispatch, usePrevious } from '../../app/hooks';
import { setUserIsNotTyping } from './messagesSlice';
import { TYPING_INTERVAL_MS } from './config';
import { useBlockProfileMutation, useUnblockProfileMutation } from './messagesAPI';

interface ChatWindowProps {
  chatClient: ChatClient,
  threadID: string, // todo: thread ID must come from the store
}

const PER_PAGE = 20;

export default function ChatWindow(props: ChatWindowProps) {

  const { chatClient, threadID } = props;
  const [messages, setMessages] = useState<SliceChatMessage[]>([]);
  const [hasOlderMessages, setHasOlderMessages] = useState(true);

  const [messageText, setMessageText] = useState('');
  const [inputInFocus, setInputInFocus] = useState(false);
  const [typingInterval, setTypingInterval] = useState<TimeoutId | null>(null);
  const [cancelTypingTimeout, setCancelTypingTimeout] = useState<TimeoutId | null>(null);

  const messagesContainerRef = useRef(null);

  // Is there a better way to re-render the chat window once the unblock actions is done?
  const [componentKey, setComponentKey] = useState(0);

  const dispatch = useAppDispatch();

  const scrollToBottom = () => {
    if (! messagesContainerRef.current) return;

    // @ts-ignore
    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  };

  // const dispatch = useAppDispatch();

  const {
    currentProfile: { name, profileID },
  } = useSelector((state: RootState) => state.account);

  const {
    currentChatWindow,
    chatMessageReceivedObservable,
  } = useSelector((state: RootState) => state.chatMessages);

  const previousIsTyping = usePrevious(currentChatWindow.userIsTyping);

  const chatThreadClient = useMemo(() => {
    if (! threadID) return null;

    return chatClient.getChatThreadClient(threadID);
  }, [threadID, chatClient]);

  useSubscribeToChatMessageReceived((e) => {
    if (! e) return;

    setMessages([
      ...messages,
      e,
    ]);

    dispatch(setUserIsNotTyping({ threadID: currentChatWindow.threadId }));

    // -> if message from a different user and window is opened - send read receipt
    // @ts-ignore - bug with Azure types
    // if (currentChatWindow.currentProfileAzureCommunicationID !== e.sender.communicationUserId) {}

    // upd: chat read must be send every time message received
    chatThreadClient?.sendReadReceipt({ chatMessageId: e.id });

    // scroll to bottom only if message was send by the user himself
    setTimeout(() => {
      // @ts-ignore - bug with Azure types
      if (currentChatWindow.currentProfileAzureCommunicationID === e.sender.communicationUserId) {
        scrollToBottom();
      }
    }, 50);
  }, chatMessageReceivedObservable);

  const history = useMemo(() => {
    if (! chatThreadClient) return null;

    return chatThreadClient.listMessages({
      maxPageSize: PER_PAGE,
    });
  }, [chatThreadClient]);

  const setMessagesList = async function () {

    if (! history) return;

    const arrayOfMessages: SliceChatMessage[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (let idx = 0; idx < PER_PAGE; idx += 1) {
      // eslint-disable-next-line no-await-in-loop
      const historyItem = await history.next();

      if (historyItem.done) {
        setHasOlderMessages(false);
        break;
      }

      arrayOfMessages.push({
        ...historyItem.value,
        createdOn: DateTime.fromJSDate(historyItem.value.createdOn).toISO(),
      });
    }

    const arrayToSet = reverse(arrayOfMessages);
    const mergedArray = [...arrayToSet, ...messages];
    setMessages(mergedArray);

    // eslint-disable-next-line consistent-return
    if (mergedArray.length === 0) return null;

    // eslint-disable-next-line consistent-return
    return mergedArray[mergedArray.length - 1]; // return last message
  };

  const [isBlocked, setIsBlocked] = useState(currentChatWindow.threadMemberStatus === CommunicationThreadMembersStatus.blocker);
  const [blockContact, { isLoading: isBlocking }] = useBlockProfileMutation();
  const [unblockContact, { isLoading: isUnblocking }] = useUnblockProfileMutation();

  const handleBlock = async function () {

    const payload = {
      blockerProfilePageId: profileID,
      blockedProfilePageId: currentChatWindow.profilePageSummary?.profilePageId,
    };

    if (!isBlocked) {
      await blockContact(payload).unwrap().then(() => {
        setIsBlocked(true);
      });
      
    } else {
      await unblockContact(payload).unwrap().then(() => {
        setIsBlocked(false);
        setComponentKey((prevKey) => prevKey + 1);
      });
    }
  };

  // initial list set
  useEffect(() => {
    setMessagesList().then((lastMessage) => {
      scrollToBottom();

      // send read receipt on initial load
      if (! lastMessage) return;
      chatThreadClient?.sendReadReceipt({ chatMessageId: lastMessage.id });
    });
  // eslint-disable-next-line
  }, [componentKey]);

  // start typing
  const sendTypingNotification = () => {

    if ((! messageText || ! inputInFocus) && typingInterval) {
      clearInterval(typingInterval);
      setTypingInterval(null);
      return;
    }

    if (! messageText || typingInterval || (! inputInFocus)) { return; }

    chatThreadClient?.sendTypingNotification({ // first call
      senderDisplayName: name,
    });

    const intervalID = setInterval(() => {
      chatThreadClient?.sendTypingNotification({
        senderDisplayName: name,
      });
    }, TYPING_INTERVAL_MS);

    setTypingInterval(intervalID);
  };
  useEffect(sendTypingNotification, [messageText, chatThreadClient, name, typingInterval, inputInFocus]);

  // dismiss user typing
  // start countdown the moment event is triggered and reset countdown on every successful proof
  useEffect(() => {
    if (currentChatWindow.userIsTyping.flag === false) { return; }

    const isEqualValues = isEqual(previousIsTyping, currentChatWindow.userIsTyping);
    if (cancelTypingTimeout && isEqualValues) {
      return;
    }

    if (! isEqualValues) {
      if (cancelTypingTimeout) clearTimeout(cancelTypingTimeout);

      const cancelInterval = setTimeout(() => {
        dispatch(setUserIsNotTyping({ threadID }));
      }, TYPING_INTERVAL_MS * 1.5);

      setCancelTypingTimeout(cancelInterval);
    }
  }, [previousIsTyping, cancelTypingTimeout, threadID, dispatch, currentChatWindow.userIsTyping]);

  const sendMessage = (e?: KeyboardEvent<HTMLTextAreaElement>) => {

    if (e) e.preventDefault();
    if (! messageText) return;

    (async () => {
      if (! chatThreadClient) return;

      const sendMessageRequest = {
        content: messageText,
      };

      const sendMessageOptions = {
        senderDisplayName: name,
      };

      await chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions); // = const sendChatMessageResult

      setMessageText('');

      // todo: send read receipt
      // await chatThreadClient.sendReadReceipt({
      //   chatMessageId: '1641805779535',
      // });
    })();
  };

  return (
    <div className="w-full" key={componentKey}>
      <div className="px-4 py-3.5 flex flex-row items-center border-b">
        {
          currentChatWindow.profilePageSummary && (
            <>
              <UserAvatar
                profilePageType={currentChatWindow.profilePageSummary.profilePageAccountType}
                profileImageUrl={currentChatWindow.profilePageSummary.profileImageUrl}
                profileID={currentChatWindow.profilePageSummary.profilePageId}
              />
              <Link to={`/profile/${currentChatWindow.profilePageSummary.profilePageId}`} className="max-w-fit ml-2">
                <span className="font-semibold text-neutral-800">
                  { currentChatWindow.profilePageSummary.name }
                </span>
              </Link>
              <div className="ml-auto">
                <Button
                  inverted
                  size="small"
                  onClick={() => handleBlock()}
                  description={isBlocked ? 'Unblock' : 'Block'}
                  disabled={isBlocking || isUnblocking}
                  styles={`py-1 px-3 ${isBlocked ? 'font-semibold text-error-600' : ''}`}
                  iconLeft={<NoSymbolIcon className="h-4 w-4 mr-1" />}
                />
              </div>
            </>
          )
        }
      </div>
      <div ref={messagesContainerRef} className="flex flex-col w-full chat-max-height overflow-y-scroll p-4">
        {
          hasOlderMessages && (
            <div className="flex flex-row mb-4 justify-center">
              <Button
                size="small"
                onClick={() => setMessagesList()}
                inverted
                description="Load older messages"
              />
            </div>
          )
        }
        {
          messages.map((message) => (
            <MessagePreview
              key={message.id}
              message={message}
              profilePageSummary={currentChatWindow.profilePageSummary}
            />
          ))
        }
      </div>
      <div className="bg-neutral-75 py-2 px-4 items-center">
        {
          currentChatWindow.userIsTyping.flag && currentChatWindow.profilePageSummary && (
            <p className="italic text-xs p-2 text-neutral-600 flex flex-row animate-pulse">
              <PencilIcon className="w-4 h-4" />
              { `${currentChatWindow.profilePageSummary.name} is typing...` }
            </p>
          )
        }
        <div className="flex flex-row items-center justify-between gap-2.5">
          <div className="w-full">
            <Textarea
              value={messageText}
              placeholder="Type..."
              height="small"
              onFocus={() => setInputInFocus(true)}
              onBlur={() => setInputInFocus(false)}
              onEnter={(e) => sendMessage(e)}
              onChange={(e) => setMessageText(e.target.value)}
              disabled={isBlocked}
            />
          </div>
          <Button
            size="small"
            description=""
            iconLeft={<EnterIcon className="w-4 h-4" />}
            roundedFull
            onClick={() => sendMessage()}
            disabled={isBlocked}
          />
        </div>
      </div>
    </div>
  );
}
