import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'title' | 'description' | 'tags';

export const rulesForumQuestionForm: RulesForFieldsType<ValidationKeys> = {
  title: {
    userFriendlyFieldName: 'Title',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 150,
  },
  description: {
    userFriendlyFieldName: 'Message',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 10000,
  },
  tags: {
    userFriendlyFieldName: 'Tags',
    ruleType: RuleTypes.array,
    required: false,
    minLength: 1,
  },  
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  title: { errorMessage: '' },
  description: { errorMessage: '' },
  tags: { errorMessage: '' },
};
