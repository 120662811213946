import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

interface TextareaProps {
  value: string,
  placeholder: string,
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
  onEnter?: (e: KeyboardEvent<HTMLTextAreaElement>) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  height?: 'small' | 'medium',
  validationMessage?: string,
  // defaultHeight?: number,
  disabled?: boolean,
}

export default function Textarea(props: TextareaProps) {

  const {
    placeholder, onChange, value, height = 'medium', validationMessage,
    onEnter, onBlur, onFocus, disabled,
  } = props;

  const [inFocus, setInFocus] = useState(false);

  let heightDefault = 28;
  let heightFocus = 64;
  if (height === 'small') {
    heightDefault = 16;
    heightFocus = 28;
  }

  return (
    <div className="relative">
      <textarea
        onFocus={() => {
          setInFocus(true);
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          setInFocus(false);
          if (onBlur) onBlur();
        }}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) { return; }

          if (e.key === 'Enter' && onEnter) { onEnter(e); }
        }}
        placeholder={placeholder}
        className={
          `disabled:opacity-50 textarea-height ${inFocus ? `h-${heightFocus}` : `h-${heightDefault}`} shadow-sm block w-full focus:ring-main-500 focus:border-main-500 sm:text-sm border border-neutral-300 rounded-md`
        }
        disabled={disabled}
      />
      {
        validationMessage && (
          <div className="absolute w-full bottom-2 px-3">
            <div className="bg-error-50 p-1">
              <p className="text-xs text-error-600">
                {validationMessage}
              </p>
            </div>
          </div>
        )
      }
    </div>
  );
}
