import React, {
  ReactNode, useEffect, useRef,
} from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { RootState } from '../../app/store';
// import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import './index.scss';
import { loginRequest } from '../../authConfig';

interface GuardedRouteProps {
  children: ReactNode,
  [rest: string]: any;
}

// TODO: check if registered / not
export default function GuardedRoute(props: GuardedRouteProps) {

  const { children, ...rest } = props;

  const location = useLocation();
  const inProgressRef = useRef('none');
  const { inProgress, accounts, instance } = useMsal();

  const { accountLoadStatus, accountId } = useSelector((state: RootState) => state.account);

  useEffect(() => {

    (async () => {
      if (inProgressRef.current === 'handleRedirect' && inProgress === 'none') {

        // user logged out
        if (accounts.length === 0) {
          localStorage.setItem('AUTH_REDIRECT_URI', location.pathname);
          await instance.acquireTokenRedirect(loginRequest);
        }
      }

      inProgressRef.current = inProgress;
    })();

  // eslint-disable-next-line
  }, [inProgress, accounts]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      <AuthenticatedTemplate>
        {
          // render after account ID is present
          (accountLoadStatus !== 'none') && children
        }
      </AuthenticatedTemplate>
    </Route>
  );
}

GuardedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
