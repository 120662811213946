import { emptySplitApi } from '../../../../app/api';
import { addFlashMessage } from '../../../flash-messages/flashMessagesSlice';
import { ICoreService, IService } from './services-types';
import prepareServicePayload from './helpers';

export const servicesAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getServicesModule: builder.query<{ servicesIDs: number[], services: IService[] }, { id?: number, requesterID: number }>({
      query: ({ id, requesterID }) => ({
        url: `/modules/Service/profile-page/${id}/requester-profile-page/${requesterID}`,
      }),
      providesTags: (result, error, arg) => [{
        type: 'ServicesModule',
        profileID: arg.id,
        requesterID: arg.requesterID,
      }],
      transformResponse: (response: IService[]) => ({
        servicesIDs: response.map((s) => s.coreServiceId),
        services: response,
      }),
    }),

    getCoreServicesList: builder.query<ICoreService[], null>({
      query: () => ({
        url: '/CoreService',
      }),
      providesTags: ['CoreServicesList'],

    }),

    /* create service */
    createService: builder.mutation<any, { profileID?: number, requesterID: number, service: IService }>({

      query(data) {

        const { profileID, requesterID, service } = data;

        return {
          url: `/modules/Service/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'POST',
          body: prepareServicePayload(service, true),
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Service is created successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Service cannot be added. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{
        type: 'ServicesModule',
        profileID: arg.profileID,
        requesterID: arg.requesterID,
      }],
    }),

    /* update service */
    updateService: builder.mutation<any, { profileID?: number, requesterID: number, service: IService }>({

      query(data) {

        const { profileID, requesterID, service } = data;

        return {
          url: `/modules/Service/profile-page/${profileID}/requester-profile-page/${requesterID}`,
          method: 'PUT',
          body: prepareServicePayload(service, false),
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Service is updated successfully'));
        } catch {
          dispatch(addFlashMessage('error', 'Service cannot be updated. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{
        type: 'ServicesModule',
        profileID: arg.profileID,
        requesterID: arg.requesterID,
      }],
    }),

    deleteService: builder.mutation<any, { profileID?: number, requesterID: number, serviceID: number }>({

      query(data) {

        const { profileID, requesterID, serviceID } = data;

        return {
          url: `/modules/Service/profile-page/${profileID}/requester-profile-page/${requesterID}/service/${serviceID}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addFlashMessage('success', 'Service was deleted'));
        } catch {
          dispatch(addFlashMessage('error', 'Service cannot be deleted. Please try again.'));
        }
      },

      invalidatesTags: (res, err, arg) => [{
        type: 'ServicesModule',
        profileID: arg.profileID,
        requesterID: arg.requesterID,
      }],
    }),
  }),
});

export const {
  useGetServicesModuleQuery,
  useGetCoreServicesListQuery,

  useDeleteServiceMutation,
  useCreateServiceMutation,
  useUpdateServiceMutation,
} = servicesAPI;
