import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'answerText';

export const rulesForumAnswerForm: RulesForFieldsType<ValidationKeys> = {
  answerText: {
    userFriendlyFieldName: 'AnswerText',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 10000,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  answerText: { errorMessage: '' },
};
