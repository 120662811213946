import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetProfileByIdQuery } from '../profile-page/profilePageAPI';
import { RootState } from '../../app/store';
import InfoMessage from '../../elements/InfoMessage';
import UserAvatar from '../../elements/UserAvatar';

export default function AuthorMetadata(props: { profileID: number }) {

  const { profileID } = props;

  const { currentProfile: { profileID: requesterID } } = useSelector((state: RootState) => state.account);

  const { data, isLoading } = useGetProfileByIdQuery({ id: profileID, requesterID }, {
    skip: !profileID,
  });

  if (! profileID) return null;

  return (
    <div className="mb-4 bg-white py-6 shadow-sm rounded rounded-lg px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold">Feed Posts</h1>
      <div className="mt-6">
        { data && (
          <div className="flex flex-row items-center">
            <Link to={`/profile/${profileID}`}>
              <UserAvatar profilePageType={data.accountType} profileImageUrl={data.profileImageUrl} size="big" />
            </Link>
            <div className="pl-4">
              <Link
                to={`/profile/${profileID}`}
              >
                <span className="font-semibold text-2xl">
                  {data.name}
                </span>
              </Link>
              { /* show company set in dynamic fields */ }
              {
                data.showCompany && data.company && (
                  <p className="text-sm font-semibold text-neutral-600 table-cell">
                    {data.company}
                  </p>
                )
              }
              { /* show job position set in dynamic fields if company is also set */ }
              {
                data && data.showCompany && data.company && data.showJobPosition && data.jobPosition && (
                  <p className="text-xs font-semibold text-neutral-600">                    
                    {data.jobPosition}
                  </p>
                )
              }              
              { /* show related corporation name */ }
              {
                !data.showCompany && data.relatedCorporationProfilePageSummary && (
                  <Link to={`/profile/${data.relatedCorporationProfilePageSummary.profilePageId}`} className="max-w-fit">
                    <p className="text-sm text-neutral-500">{data.relatedCorporationProfilePageSummary.name}</p>
                  </Link>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
