import React from 'react';
import { CogIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setForumFilterProfile } from '../../forum/questions-list/forumQuestionsSlice';
import { RootState } from '../../../app/store';
import { getCanEditCorporateSettings } from '../../../app/permissionsSchema';
import { useBrandingLocale } from '../../../helpers/hooks';

export default function AdminManageCorporation() {

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useBrandingLocale();

  const { corporationId } = useSelector((state: RootState) => state.profilePage.profileOfCurrentUser);
  const canEditCorporateSettings = useAppSelector(getCanEditCorporateSettings);

  if (! canEditCorporateSettings || !corporationId) return null;

  return (
    <>
      <button
        type="button"
        onClick={() => {
          history.push(`/corporation-settings/${corporationId}`);
        }}
        className="inline-flex items-center justify-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none "
      >
        <CogIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>
          { `Manage ${t('Corporation')}` }
        </span>
      </button>
    </>
  );
}
