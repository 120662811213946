import React from 'react';
// @ts-ignore
import Fade from '@stahl.luke/react-reveal/Fade';
import { Tab } from '@headlessui/react';
import LandingNavigation from '../LandingNavigation';
import mainScreenBg from '../what-is-nucleo/assets/logoPart.svg';
import Footer from '../Footer';
import MeetTheTeamTab from './MeetTheTeamTab';
import { useDocumentTitle } from '../../../helpers/hooks';

export default function AboutNucleo() {

  useDocumentTitle('About');

  return (
    <main className="what-is-nucleo-page bg-neutral-50 relative bg-white">
      <section style={{ minHeight: 1000 }} className="relative flex justify-center pb-12 md:pb-0 2xl:pb-60 overflow-hidden">
        <LandingNavigation />

        <img
          alt="Background"
          src={mainScreenBg}
          className="absolute top-0 -right-44 md:right-0 transform 2xl:scale-150"
        />

        {/* left section */}
        <Fade left>
          <div className="mt-24 z-10 w-full flex main-screen-container items-center max-w-6xl w-full px-4 md:px-6 xl:px-0">
            <div className="mb-32 backdrop-blur-md w-full bg-white/90 md:w-auto px-8 py-12 rounded-md xl:p-0 xl:bg-transparent xl:backdrop-filter-none">
              <h1 className="text-main-900 text-5.5xl font-extrabold">About Nucleo</h1>

              <div className="mt-6">
                <Tab.Group>
                  <Tab.List className="flex flex-col sm:flex-row gap-4">
                    <Tab className={({ selected }) => `text-main-900 p-4 rounded-md ${selected ? 'font-bold bg-white shadow-md' : 'bg-main-50'}`}>
                      Why are we doing this?
                    </Tab>
                    <Tab className={({ selected }) => `text-main-900 p-4 rounded-md ${selected ? 'font-bold bg-white shadow-md' : 'bg-main-50'}`}>
                      The story
                    </Tab>
                    <Tab className={({ selected }) => `text-main-900 p-4 rounded-md ${selected ? 'font-bold bg-white shadow-md' : 'bg-main-50'}`}>
                      Meet the Team
                    </Tab>
                  </Tab.List>

                  <Tab.Panels>
                    <Tab.Panel style={{ maxWidth: 560 }}>
                      <h2 className="mt-6 text-3xl text-main-900 font-extrabold">Why are we doing this?</h2>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I get to see the full product landscape for in-licensing opportunities in my markets?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I find the right regulatory agent in the Middle East for my company?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I find a new manufacturer of soft gel capsules with EU GMP approval?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I connect with the right person in an organization?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I talk to my peers facing the same issues in the life science industry?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        How do I build a relevant and credible network in this industry?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Where do I go for real-time data from the company itself as opposed to outdated or inaccurate information somewhere on the internet?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        We could not easily find answers to these questions; this is why we developed Nucleo.
                      </p>
                    </Tab.Panel>

                    <Tab.Panel style={{ maxWidth: 560 }}>
                      <h2 className="mt-6 text-3xl text-main-900 font-extrabold">The story</h2>
                      <p className="text-neutral-700 font-medium mt-6">
                        In spring 2020, in the middle of the first Covid wave, I was asked by a young pharma professional how I had built my network. How did I know who to go to in the life sciences industry for the right product for a market, for the right expert in a particular field?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        The simple answer was over the last 30 years the majority of my time was spent attending conferences, and networking with business colleagues to seek out the right contact.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        While this has often worked, it’s far from perfect. Nobody’s network is exhaustive. I know what I know and, more importantly, who I know but that’s it. I don’t know what or who I don’t know.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        The life sciences landscape is certainly not infinite, yet organisations are out there just looking to network with the right partner. We spend precious time using existing databases or sending unsolicited messages hoping to make a connection and potentially land a deal.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        We’re all attending conferences trying to find that elusive product or service, and none of this is secret information. If we are offering products and services in life sciences, we want the world to know it.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Don’t get me wrong, face-to-face meetings are still the best way to build partnerships. But most of us are using valuable time and money attending conferences and fairs hoping to find potential contacts.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Wouldn’t it be better to know who we wanted to meet before we got there?
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Sadly, even with the advancements and innovation in product development, the life science industry continues to do business like we are living in 1976 (and yes, I remember living in 1976!).
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        It’s time to evolve. In order to do that, we must bring efficiency to business development and make connections more quickly. Let’s get to the fun part and do the deals. Let’s skip the trailer and go directly to the feature film.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Nucleo is at the centre of what’s new and exciting. We are the answer to what’s missing in life sciences. We are here to inject energy into the world of Pharma and make prosperous connections that matter; now and beyond.
                      </p>
                      <p className="text-neutral-700 font-medium mt-6">
                        Ewan Livesey, Co-Founder/CEO Nucleo
                      </p>
                    </Tab.Panel>

                    <MeetTheTeamTab />
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </Fade>
      </section>

      <div className="relative overflow-hidden">
        <Footer />
      </div>
    </main>
  );
}
