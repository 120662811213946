import React from 'react';
import { Link } from 'react-router-dom';
import { ISearchRecord } from './search-types';
import UserAvatar from '../../elements/UserAvatar';
import { ProfilePageType } from '../../app/account';
import ClumpedContent from '../forum/question-page/ClumpedContent';
import ModuleItemsDisplay from './ModuleItemsDisplay';

interface SearchResultRowProps {
  record: ISearchRecord,
  isIndividual: boolean,
}

export default function SearchResultRow(props: SearchResultRowProps) {

  const { record, isIndividual } = props;

  return (
    <div>
      <div className="w-full mt-6">
        <div className="flex flex-row h-full">

          <Link to={`/profile/${record.id}`}>
            <UserAvatar
              profilePageType={isIndividual ? ProfilePageType.individual : ProfilePageType.corporation}
              size="medium"
              profileImageUrl={record.profileImageUrl}
            />
          </Link>

          <div className="w-full flex flex-col pl-4">
            <Link to={`/profile/${record.id}`}>
              <h4 className="pb-1 text-main-600 font-semibold">
                {record.name}
              </h4>
            </Link>
            { /* show company set in dynamic fields */ }
            {
              record.showCompany && record.company && (
                <p className="text-sm font-semibold text-neutral-600 table-cell">
                  {record.company}
                </p>
              )
            }
            { /* show job position set in dynamic fields if company is also set */ }
            {
              record.showCompany && record.company && record.showJobPosition && record.jobPosition && (
                <p className="text-xs font-semibold text-neutral-600">                    
                  {record.jobPosition}
                </p>
              )
            }            
            { /* show related corporation name */ }
            {
              !record.showCompany && record.membershipCorporation?.name && (
                <Link to={`/profile/${record.membershipCorporation.profilePageId}`} className="max-w-fit">
                  <p className="text-xs font-semibold text-neutral-600 table-cell">
                    {record.membershipCorporation?.name}
                  </p>
                </Link>
              )
            }

            <ClumpedContent text={record.description} lineClamp="line-clamp-5 mt-2" />
            <ModuleItemsDisplay title="Products" items={record.productNames} />
            <ModuleItemsDisplay title="Services" items={record.serviceNames} />
          </div>
        </div>
      </div>

      <div className="w-full h-px bg-neutral-300 mt-4" />
    </div>
  );
}
