import React from 'react';

import { StarIcon } from '@heroicons/react/20/solid';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetSavedSearchesQuery } from './searchAPI';
import { useAppDispatch } from '../../app/hooks';
import { setSearchString } from './searchSlice';

export default function SavedSearchesWidget() {

  const { data, isLoading } = useGetSavedSearchesQuery(null);

  let content = null;

  const dispatch = useAppDispatch();
  const history  = useHistory();
  const location = useLocation();

  const submitSearch = (input: string) => { // same in SavedSearchesWidget
    // setLocalSearchString(input);
    dispatch(setSearchString(input));

    if (! location.pathname.includes('search-results')) {
      history.push('/search-results');
    }
  };

  if (isLoading) {
    content = (
      <div className="pl-4 pb-4">
        <span className="text-xs text-neutral-400">Loading...</span>
      </div>
    );
  }

  if (! content) {
    content = (! data || data.length === 0) ? (
      <div className="pl-4 pb-4">
        <span className="text-xs text-neutral-400">No saved searches yet</span>
      </div>
    ) : (
      <div className="scrollbar-wrapper max-h-96 overflow-y-auto">
        {
          data.map((search) => (
            <button
              type="button"
              key={search.id}
              className="w-full"
              onClick={() => submitSearch(search.input)}
            >

              <div className="grid grid-cols-8 pl-2 py-2 rounded-md hover:shadow-sm cursor-pointer">
                {/* Left side */}
                <div className="col-span-1 flex justify-center items-top pt-3 pl-2">
                  <StarIcon className="text-main-700 w-4 h-4" />
                </div>

                {/* Right side */}
                <div className="pl-2 pr-4 col-span-7 text-left">
                  <p className="text-2xs text-neutral-300 font-semibold">
                    {search.searchCreationDateUtc}
                  </p>

                  <p className="text-xs line-clamp-4">
                    {search.input}
                  </p>
                </div>
              </div>
            </button>
          ))
        }
      </div>
    );
  }

  return (
    <div className="shadow-sm bg-white rounded-md w-full mt-2">
      <div className="flex flex-row items-center pt-2 pb-2">
        <span className="text-sm font-bold p-2 pl-4">Saved searches</span>
      </div>

      {content}

    </div>
  );
}
