import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { RootState } from '../../../../app/store';
import { useAppDispatch } from '../../../../app/hooks';
import { displayCreateFocusForm, setDeleteFocusModalData } from './focusModuleSlice';
import FocusModuleView from './FocusModuleView';
import ErrorModal from '../../../../elements/ErrorModal';
import Button from '../../../../elements/Button';
import FocusCreateEditForm from './components/FocusCreateEditForm';
import { useDeleteFocusMutation } from './focusAPI';

interface FocusModuleEditProps {
  setEditMode?: () => void,
}

export default function FocusModuleEdit(props: FocusModuleEditProps) {
  const { setEditMode } = props;

  const {
    deleteFocusModalData, createFocusViewDisplay,
  } = useSelector((state: RootState) => state.profileModules.focusModule);

  const { currentlyLoadedProfileID } = useSelector((state: RootState) => state.profilePage);
  const { profileID: requesterID } = useSelector((state: RootState) => state.account.currentProfile);

  const [deleteFocusRequest, { isLoading: isDeletingFocus }] = useDeleteFocusMutation();

  const dispatch = useAppDispatch();

  const onFocusDeleteModalClose = () => {
    dispatch(setDeleteFocusModalData({
      coreFocus: null,
      showModal: false,
    }));
  };

  const onFocusDelete = async () => {
    if (! deleteFocusModalData.focus || ! deleteFocusModalData.focus.id) return;

    await deleteFocusRequest({
      requesterID,
      profileID: currentlyLoadedProfileID,
      focusID: deleteFocusModalData.focus.id,
    });

    onFocusDeleteModalClose();
  };

  return (
    <>
      <div className={`px-2 py-2 flex-row items-center ${createFocusViewDisplay ? '' : 'bg-neutral-25'}`}>
        {
          createFocusViewDisplay ? (
            <FocusCreateEditForm formType="create" focusID={0} />
          ) : (
            <div className="w-max flex flex-row gap-2">
              <Button
                onClick={() => dispatch(displayCreateFocusForm({
                  showForm: true,
                }))}
                size="small"
                description="Add a New Focus"
                inverted
                roundedFull
                iconLeft={<PlusSmallIcon className="w-4 h-4" />}
              />
            </div>
          )
        }
      </div>
      <div className="w-full border-t" />

      <FocusModuleView setEditMode={setEditMode} inEditMode />

      <ErrorModal
        withErrorBorder={false}
        show={deleteFocusModalData.showModal}
        setOpen={() => onFocusDeleteModalClose()}
        transparentBackground
        onActionClick={onFocusDelete}
        actionButtonText="Delete"
        title="Delete focus?"
        description={`Deleting "${deleteFocusModalData.focus?.focusCategoryName}" focus is an action that can’t be undone.`}
        isLoading={isDeletingFocus}
      />
    </>
  );
}
